import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { Paper, Button, Typography, Tabs, Tab, Tooltip } from '@material-ui/core';
import routePaths from '../../routePaths';
import { actions } from '../../actions/termsAndConditionsActions';
import './TermsAndConditionsPage.scss';
import { VersionStatus, SupportedLanguageCodes } from '../../models/termsAndConditions/TermsAndConditionsModel';
import './TermsAndConditionsEditPageStyles.scss';
import ConfirmationButton from '../common/ConfirmationButton';
import TermsAndConditionsContentEditor from './TermsAndConditionsContentEditor';

class TermsAndConditionsEditPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contents: (props.details ? props.details.contents : null) || {},
      additionalImageFiles: {},
      additionalImageFilesToDelete: {},
      language: SupportedLanguageCodes.pl.value,
      submitting: false,
    };

    this.handleContentChange = this.handleContentChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handlePublish = this.handlePublish.bind(this);
    this.handleLanguageChange = this.handleLanguageChange.bind(this);
    this.handleAdditionalImageChange = this.handleAdditionalImageChange.bind(this);
  }

  componentDidMount() {
    if (this.props.details == null) {
      this.props.actions.load();
    }
  }

  handleContentChange(value) {
    const newContent = Object.assign({}, this.state.contents);
    newContent[this.state.language] = value;

    this.setState({
      contents: newContent,
    });
  }

  handleAdditionalImageChange(value) {
    const newAdditionalImagesFiles = Object.assign({}, this.state.additionalImageFiles);
    const newAdditionalImageFilesToDelete = Object.assign({}, this.state.additionalImageFilesToDelete);

    newAdditionalImagesFiles[this.state.language] = value;
    newAdditionalImageFilesToDelete[this.state.language] =
      this.props.details.additionalImages[this.state.language] != null && value === null;

    this.setState({
      additionalImageFiles: newAdditionalImagesFiles,
      additionalImageFilesToDelete: newAdditionalImageFilesToDelete,
    });
  }

  handleSave() {
    this.setState({ submitting: true });
    this.props.actions
      .update(
        this.props.id,
        this.state.contents[this.state.language],
        this.state.additionalImageFiles[this.state.language],
        this.state.additionalImageFilesToDelete[this.state.language] === true,
        this.state.language)
      .finally(() => this.setState({ submitting: false }));
  }

  handlePublish() {
    this.setState({ submitting: true });
    this.props.actions
      .updateAndPublish(
        this.props.id,
        this.state.contents[this.state.language],
        this.state.additionalImageFiles[this.state.language],
        this.state.additionalImageFilesToDelete[this.state.language] === true,
        this.state.language)
      .finally(() => this.setState({ submitting: false }));
  }

  handleLanguageChange(event, language) {
    this.setState({ language });
  }

  render() {
    const { details } = this.props;
    const { additionalImageFilesToDelete, language, submitting } = this.state;
    if (details == null) return null;
    return (
      <div className="page padder">
        <Typography variant="h5" gutterBottom>Wersja {details.version}</Typography>
        <Paper elevation={3}>
          <Tabs
            value={this.state.language}
            indicatorColor="secondary"
            textColor="secondary"
            onChange={this.handleLanguageChange}
          >
            <Tab value={SupportedLanguageCodes.pl.value} label={<Tooltip title="polski"><span>PL</span></Tooltip>} />
            <Tab value={SupportedLanguageCodes.en.value} label={<Tooltip title="angielski"><span>EN</span></Tooltip>} />
            <Tab value={SupportedLanguageCodes.de.value} label={<Tooltip title="niemiecki"><span>DE</span></Tooltip>} />
            <Tab value={SupportedLanguageCodes.ru.value} label={<Tooltip title="rosyjski"><span>RU</span></Tooltip>} />
            <Tab value={SupportedLanguageCodes.cs.value} label={<Tooltip title="czeski"><span>CS</span></Tooltip>} />
            <Tab value={SupportedLanguageCodes.lt.value} label={<Tooltip title="litewski"><span>LT</span></Tooltip>} />
            <Tab value={SupportedLanguageCodes.uk.value} label={<Tooltip title="ukraiński"><span>UK</span></Tooltip>} />
          </Tabs>
          {this.state.language === SupportedLanguageCodes.pl.value &&
            <TermsAndConditionsContentEditor
              content={this.state.contents.pl || ''}
              onContentChange={this.handleContentChange}
              additionalImageDefault={additionalImageFilesToDelete[language] ? null : details.additionalImages[language]}
              onAdditionalImagePicked={this.handleAdditionalImageChange}
            />
          }
          {this.state.language === SupportedLanguageCodes.en.value &&
            <TermsAndConditionsContentEditor
              content={this.state.contents.en || ''}
              onContentChange={this.handleContentChange}
              additionalImageDefault={additionalImageFilesToDelete[language] ? null : details.additionalImages[language]}
              onAdditionalImagePicked={this.handleAdditionalImageChange}
            />
          }
          {this.state.language === SupportedLanguageCodes.de.value &&
            <TermsAndConditionsContentEditor
              content={this.state.contents.de || ''}
              onContentChange={this.handleContentChange}
              additionalImageDefault={additionalImageFilesToDelete[language] ? null : details.additionalImages[language]}
              onAdditionalImagePicked={this.handleAdditionalImageChange}
            />
          }
          {this.state.language === SupportedLanguageCodes.ru.value &&
            <TermsAndConditionsContentEditor
              content={this.state.contents.ru || ''}
              onContentChange={this.handleContentChange}
              additionalImageDefault={additionalImageFilesToDelete[language] ? null : details.additionalImages[language]}
              onAdditionalImagePicked={this.handleAdditionalImageChange}
            />
          }
          {this.state.language === SupportedLanguageCodes.cs.value &&
            <TermsAndConditionsContentEditor
              content={this.state.contents.cs || ''}
              onContentChange={this.handleContentChange}
              additionalImageDefault={additionalImageFilesToDelete[language] ? null : details.additionalImages[language]}
              onAdditionalImagePicked={this.handleAdditionalImageChange}
            />
          }
          {this.state.language === SupportedLanguageCodes.lt.value &&
            <TermsAndConditionsContentEditor
              content={this.state.contents.lt || ''}
              onContentChange={this.handleContentChange}
              additionalImageDefault={additionalImageFilesToDelete[language] ? null : details.additionalImages[language]}
              onAdditionalImagePicked={this.handleAdditionalImageChange}
            />
          }
          {this.state.language === SupportedLanguageCodes.uk.value &&
            <TermsAndConditionsContentEditor
              content={this.state.contents.uk || ''}
              onContentChange={this.handleContentChange}
              additionalImageDefault={additionalImageFilesToDelete[language] ? null : details.additionalImages[language]}
              onAdditionalImagePicked={this.handleAdditionalImageChange}
            />
          }
        </Paper>
        <div className="actions">
          <Button style={{ marginRight: 8 }} component={Link} to={routePaths.termsAndConditions}>
            Anuluj
          </Button>
          <ConfirmationButton
            disabled={submitting}
            variant="contained"
            color="secondary"
            style={{ marginRight: 8 }}
            onConfirm={this.handlePublish}
            confirmationTitle="Potwierdź publikację regulaminów"
            confirmationText="Upewnij się, że uzupełnione zostały wszystkie wersje językowe regulaminu. Nowi oraz aktualni klienci będą musieli zaakceptować regulamin przed rozpoczęciem przejazdu lub rezerwacji.
              Wszyscy dotychczasowi klienci otrzymają powiadomienie push informujące o konieczności akceptacji nowej wersji regulaminu."
          >
            Zapisz i opublikuj
          </ConfirmationButton>
          <Button disabled={submitting} variant="contained" color="primary" onClick={this.handleSave}>Zapisz zmiany ({language.toUpperCase()})</Button>
        </div>
      </div>
    );
  }
}

TermsAndConditionsEditPage.propTypes = {
  history: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  id: PropTypes.string,
  details: PropTypes.object,
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

function mapStateToProps(state) {
  return {
    id: state.termsAndConditions.id,
    details: state.termsAndConditions.versions.find(t => t.status === VersionStatus.draft.value),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TermsAndConditionsEditPage);
