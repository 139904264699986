import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {CircularProgress, Typography, Tabs, Tab, Paper, Button} from '@material-ui/core';
import { actions } from '../../actions/cardsActions';
import CardDetailsForm from './CardDetailsForm';
import CardClients from './CardClients';
import {Link} from "react-router-dom";
import routePaths from "../../routePaths";

class CardDetailsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: 'details',
    };

    this.handleTabChange = this.handleTabChange.bind(this);
  }

  componentDidMount() {
    this.props.actions.load();
  }

  handleTabChange(event, tab) {
    this.setState({ tab });
  }

  render() {
    const { actions, fetching, card } = this.props;

    return (
      <div className="page">
        <div style={{ margin: 40 }}>
          {fetching ? <div style={{ width: '100%', textAlign: 'center' }}><CircularProgress size={50} /></div> :
            <React.Fragment>
              <Typography variant="h5" gutterBottom>{card.cardProviderName}</Typography>
              <div style={{ textAlign: 'right', marginBottom: 16 }}>
                <Button variant="contained" style={{ marginLeft: 8 }} component={Link} color="primary" to={routePaths.cardDetailsPricing.replace(':id', card.id)}>Cennik</Button>
              </div>
              <Paper>
                <Tabs
                  value={this.state.tab}
                  indicatorColor="secondary"
                  textColor="secondary"
                  onChange={this.handleTabChange}
                >
                  <Tab value="details" label="Szczegóły" />
                  <Tab value="clients" label="Klienci" />
                </Tabs>
                <div style={{ padding: 16 }}>
                  {this.state.tab === "details" && <CardDetailsForm
                    initialValues={{
                      ...card,
                      minimumUserBalanceEnabled: card.minimumUserBalance !== null,
                      initialUserBalanceEnabled: card.initialUserBalance !== null
                    }}
                    onSubmit={data => actions.update(data.id, data)} />}
                  {this.state.tab === "clients" && <CardClients cardId={card.id} />}
                </div>
              </Paper>
            </React.Fragment>}
        </div>
      </div>
    );
  }
}

CardDetailsPage.propTypes = {
  actions: PropTypes.object.isRequired,
  fetching: PropTypes.bool,
  card: PropTypes.object,
  params: PropTypes.object,
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

function mapStateToProps(state, ownProps) {
  return {
    fetching: state.cards.fetching,
    card: state.cards.data && state.cards.data.configured ? state.cards.data.configured.find(c => c.id === ownProps.match.params.id) : null,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CardDetailsPage);
