import initialState from '../../initialState';
import Immutable from 'seamless-immutable';
import { types } from './actions';
import { ClientRecordModel } from './models';
import { ReportTableModel } from '../../../models/ReportTableModel';

export default function deniedClientsReducer(state = Immutable(initialState.reports.clientsDenyList), action) {
  switch (action.type) {
    case types.LOAD_DENIED_CLIENTS_REPORT: {
      return Immutable.merge(state, {
        loading: true,
        pagedData: ReportTableModel.pageRequested(state.pagedData, action.pageNumber, action.pageSize, action.filterBy, action.sortBy),
      });
    }
    case types.LOAD_DENIED_FRESH_CLIENTS_REPORT_SUCCESS: {
      const result = action.result;
      return Immutable.merge(state, {
        loading: false,
        pagedData: ReportTableModel.pageReady(state.pagedData, result.totalDataCount, result.data.map(r => new ClientRecordModel(r))),
      });
    }
    case types.LOAD_DENIED_NEXT_CLIENTS_REPORT: {
      return Immutable.merge(state, {
        pagedData: ReportTableModel.nextPageRequested(state.pagedData, action.pageNumber, action.pageSize),
      });
    }
    case types.LOAD_DENIED_NEXT_CLIENTS_REPORT_SUCCESS: {
      const result = action.result;
      return Immutable.merge(state, {
        pagedData: ReportTableModel.pageReady(state.pagedData, result.totalDataCount, result.data.map(r => new ClientRecordModel(r))),
      });
    }
    default:
      return state;
  }
}
