import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { actions } from '../../../actions/relocationActions';
import { Paper, Typography, Button, CircularProgress } from '@material-ui/core';
import RelocationReportTable from './RelocationReportTable';
import { Link } from 'react-router-dom';
import routePaths from '../../../routePaths';
import DownloadIcon from '@material-ui/icons/GetApp';
import RelocationHistoryReportDialog from "./RelocationHistoryReportDialog";
import {hasPermission, permissions} from "../../../utils/authorization";

class RelocationReportPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      spreadsheetExportInProgress: false,
      historyReportDialogShow: false
    };

    this.handleExportToSpreadsheet = this.handleExportToSpreadsheet.bind(this);
    this.handleImportHistoryReportDialogShow = this.handleImportHistoryReportDialogShow.bind(this);
  }

  componentDidMount() {
    this.props.actions.load();
  }

  handleExportToSpreadsheet() {
    this.setState({ spreadsheetExportInProgress: true });
    this.props.actions
      .requestExcelExport()
      .finally(() => this.setState({ spreadsheetExportInProgress: false }));
  }

  handleImportHistoryReportDialogShow(show) {
    this.setState({historyReportDialogShow: show})
  }


  render() {
    const hasConfigurationAccessPermission = true;
    return (
      <div className="page">
        <div style={{ margin: 40 }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <Typography variant="subtitle1" gutterBottom>Podsumowanie stref preferowanego zwrotu</Typography>
              <Typography variant="caption" gutterBottom>Data wygenerowania {moment().format('DD.MM.YYYY HH:mm')}</Typography>
            </div>
            <div>
              {hasPermission(this.props.userPermissions, permissions.relocationHistory) &&
                <Button onClick={() => {
                this.handleImportHistoryReportDialogShow(true)
              }}>Historia</Button>}
              {hasConfigurationAccessPermission ?
                <Button component={Link} to={routePaths.relocationConfiguration}>Konfiguracja</Button>
                : null}
              <Button variant='outlined' color='secondary' disabled={this.state.spreadsheetExportInProgress}
                style={{ marginLeft: "8px" }}
                onClick={() => this.handleExportToSpreadsheet()}>
                {this.state.spreadsheetExportInProgress && < CircularProgress size={20} color="secondary" />}
                {!this.state.spreadsheetExportInProgress && <DownloadIcon />}
                Eksportuj
              </Button>
            </div>
          </div>
          <Paper elevation={3}>
            {this.props.relocationData && <RelocationReportTable relocationData={this.props.relocationData} />}
          </Paper>
          <RelocationHistoryReportDialog
            open={this.state.historyReportDialogShow}
            onCancel={() => this.handleImportHistoryReportDialogShow(false)}
          />
        </div>
      </div>
    );
  }
}
//RelocationHistoryReportDialog
RelocationReportPage.propTypes = {
  actions: PropTypes.object.isRequired,
  relocationData: PropTypes.object,
  userPermissions: PropTypes.object.isRequired
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

function mapStateToProps(state) {
  return {
    relocationData: state.reports.relocation,
    userPermissions: state.auth.permissions
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RelocationReportPage);
