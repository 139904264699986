import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'moment-duration-format';
import ReportTable from '../common/reportTable/ReportTable';
import { actions } from '../../actions/cardsActions';
import { getColumns } from './CardClientsColumns';
import CardClientsFiltersPanel from './CardClientsFiltersPanel';

class CardClients extends Component {
  constructor(props) {
    super(props);

    this.handleFiltersChange = this.handleFiltersChange.bind(this);
    this.handleSortChange = this.handleSortChange.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
  }

  componentDidMount() {
    const transactions = this.props.clients;
    this.props.actions.loadCardClients(this.props.cardId, 1, transactions.paging.pageSize, transactions.sortedBy, transactions.filteredBy);
  }

  handleSortChange(sortBy) {
    const transactions = this.props.clients;
    this.props.actions.loadCardClients(this.props.cardId, 1, transactions.paging.pageSize, sortBy, transactions.filteredBy);
  }

  handleFiltersChange(filterBy) {
    const transactions = this.props.clients;
    this.props.actions.loadCardClients(this.props.cardId, 1, transactions.paging.pageSize, transactions.sortedBy, filterBy.filter(f => f.value));
  }

  handlePageChange(nextPage) {
    const transactions = this.props.clients;
    this.props.actions.loadCardClients(this.props.cardId, nextPage, transactions.paging.pageSize, transactions.sortedBy, transactions.filteredBy);
  }

  handlePageSizeChange(nextPageSize) {
    const transactions = this.props.clients;
    this.props.actions.loadCardClients(this.props.cardId, 1, nextPageSize, transactions.sortedBy, transactions.filteredBy);
  }

  handleRemove(cardId) {
    this.props.actions.removeClientCard(cardId);
  }

  render() {
    const { loading, clients } = this.props;
    return (
      <div>
        <CardClientsFiltersPanel filteredBy={clients.filteredBy} onFilteredChange={this.handleFiltersChange} />
        <ReportTable
          style={{ height: "calc(100vh - 200px)" }}
          columns={getColumns(cardId => this.handleRemove(cardId))}
          data={clients.records}
          filteredBy={clients.filteredBy}
          sortedBy={clients.sortedBy}
          paging={clients.paging}
          onFilteredChange={this.handleFiltersChange}
          onPageChange={this.handlePageChange}
          onPageSizeChange={this.handlePageSizeChange}
          onSortedChange={this.handleSortChange}
          loading={loading}
        />
      </div>
    );
  }
}

CardClients.propTypes = {
  actions: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  cardId: PropTypes.string.isRequired,
  clients: PropTypes.object,
};

CardClients.defaultProps = {
  loading: false,
  clients: {},
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

function mapStateToProps(state) {
  return {
    clients: state.reports.cardClients.pagedData,
    loading: state.reports.cardClients.loading,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CardClients);
