import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Field, formValueSelector, reduxForm, change } from "redux-form";
import FormTextField from "../../form/redux-form/FormTextField";
import FormSelect from "../../form/redux-form/FormSelect";
import { connect, useDispatch } from "react-redux";

const resultOfFareIssue = [
  {
    name: "Uznana",
    value: 1,
  },
  {
    name: "Odrzucona",
    value: 2,
  },
];

const resultCorrectionReasonFareIssue = [
  {
    name: "Korekta opłaty",
    value: "feeAdjustment",
  },
  {
    name: "Rezygnacja z wypożyczenia",
    value: "rentalCancellation",
  },
  {
    name: "Rower zwrócony do Strefy Parkowania",
    value: "bikeReturnedToParkingZone",
  },
  {
    name: "Rower pozostawiony w Obszarze Operatora",
    value: "bikeLeftInOperatorArea",
  },
  {
    name: "Błąd GPS",
    value: "gPSFailure",
  },
  {
    name: "Usterka roweru",
    value: "bikeFault",
  },
  {
    name: "Inne",
    value: "other",
  },
];

const rejectCorrectionReasonFareIssue = [
  {
    name: "Brak zidentyfikowanych problemów",
    value: "noFoundIssue",
  },
];

let resolveFareIssueFormValidation = (values) => {
  const errors = {};

  if (!values.result) {
    errors.result = "Pole jest wymagane";
  }

  if (values.result && values.result === 1) {
    if (!values.resultCorrectionReasonFareIssue) {
      errors.resultCorrectionReasonFareIssue = "Pole jest wymagane";
    }
  }

  if (!values.resolutionComments) {
    errors.resolutionComments = "Pole jest wymagane";
  }

  return errors;
};

let ResolveFareIssueForm = ({ handleSubmit, result }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (result === 2) {
      dispatch(
        change(
          "resolveFareIssue",
          "resultCorrectionReasonFareIssue",
          "noFoundIssue",
        ),
      );
    }
    if (result === 1) {
      dispatch(
        change("resolveFareIssue", "resultCorrectionReasonFareIssue", null),
      );
    }
  }, [result]);
  return (
    <form onSubmit={handleSubmit}>
      <div>
        <Field
          name="result"
          component={FormSelect}
          style={{ width: 400 }}
          values={resultOfFareIssue}
          label="Wybierz status reklamacji"
        />
        <Field
          name="resultCorrectionReasonFareIssue"
          component={FormSelect}
          style={{ width: 400 }}
          values={
            result === 2
              ? rejectCorrectionReasonFareIssue
              : resultCorrectionReasonFareIssue
          }
          label="Wybierz powód"
          forceReset={result === 2}
          readonly={result === 2}
        />
        <Field
          name="resolutionComments"
          component={FormTextField}
          label="Notatka"
          fullWidth
          multiline
          rows={8}
          style={{ width: 400 }}
        />
      </div>
    </form>
  );
};

ResolveFareIssueForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  result: PropTypes.number,
};

ResolveFareIssueForm = reduxForm({
  form: "resolveFareIssue",
  validate: resolveFareIssueFormValidation,
})(ResolveFareIssueForm);

const selector = formValueSelector("resolveFareIssue"); //
ResolveFareIssueForm = connect((state) => {
  const result = selector(state, "result");
  return {
    result,
  };
})(ResolveFareIssueForm);

export default ResolveFareIssueForm;
