import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { InputAdornment } from '@material-ui/core';

const FormTextField = ({
  input,
  label,
  readonly,
  endAdornmentText,
  meta: { touched, error },
  ...custom
}) => (
    <TextField
      label={label}
      error={touched && error ? true : false}
      helperText={touched && error}
      disabled={readonly === true}
      margin="dense"
      InputProps={endAdornmentText ? { endAdornment: <InputAdornment position="end">{endAdornmentText}</InputAdornment> } : undefined}
      {...input}
      {...custom}
    />
  );

FormTextField.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  meta: PropTypes.object,
  readonly: PropTypes.bool,
  endAdornmentText: PropTypes.string,
};

export default FormTextField;
