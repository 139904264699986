import { push } from 'connected-react-router';
import termsAndConditionsApi from '../api/termsAndConditionsApi';
import { actions as notificationActions } from './notificationActions';
import * as logger from '../utils/logger';
import routePaths from '../routePaths';

export const types = {
  LOAD_TERMS_SUCCESS: 'LOAD_TERMS_SUCCESS',
  LOAD_TERMS_FAILURE: 'LOAD_TERMS_FAILURE',
  UPDATE_TERMS_SUCCESS: 'UPDATE_TERMS_SUCCESS',
  UPDATE_TERMS_FAILURE: 'UPDATE_TERMS_FAILURE',
  UPDATE_PERSONAL_DATA_PROCESSING_CLAUSE_SUCCESS: 'UPDATE_PERSONAL_DATA_PROCESSING_CLAUSE_SUCCESS',
  UPDATE_PERSONAL_DATA_PROCESSING_CLAUSE_FAILURE: 'UPDATE_PERSONAL_DATA_PROCESSING_CLAUSE_FAILURE',
  CREATE_TERMS_VERSION_SUCCESS: 'CREATE_TERMS_VERSION_SUCCESS',
  CREATE_TERMS_VERSION_FAILURE: 'CREATE_TERMS_VERSION_FAILURE',
  PUBLISH_TERMS_SUCCESS: 'PUBLISH_TERMS_SUCCESS',
  PUBLISH_TERMS_FAILURE: 'PUBLISH_TERMS_FAILURE',
};

export const actions = {
  load: () => dispatch => {
    return termsAndConditionsApi
      .get()
      .then(response => {
        if (response.ok) {
          return dispatch({ type: types.LOAD_TERMS_SUCCESS, termsAndConditions: response.data });
        } else {
          dispatch({ type: types.LOAD_TERMS_FAILURE });
          dispatch(notificationActions.showError('Wystąpił problem podczas pobierania regulaminu'));

          return logger.error(response.data);
        }
      });
  },
  createNewVersion: id => dispatch => {
    return termsAndConditionsApi
      .createVersion(id ? { id } : {})
      .then(response => {
        if (response.ok && response.data.success !== false) {
          dispatch({ type: types.CREATE_TERMS_VERSION_SUCCESS, draft: { version: response.data.version, id: response.data.id } });
          dispatch(notificationActions.showSuccess('Utworzono nową wersję regulaminu'));
        } else {
          dispatch({ type: types.CREATE_TERMS_VERSION_FAILURE });
          dispatch(notificationActions.showError('Wystąpił problem podczas tworzenia nowej wersji regulaminu'));
          return logger.error(response.data);
        }
      });
  },
  update: (id, content, additionalImage, additionalImageDelete, languageCode) => dispatch => {
    return termsAndConditionsApi
      .update(id, content, additionalImage, additionalImageDelete, languageCode)
      .then(response => {
        if (response.ok && response.data.success !== false) {
          dispatch({ type: types.UPDATE_TERMS_SUCCESS, content, languageCode, additionalImageUrl: response.data.additionalImageUrl });
          dispatch(notificationActions.showSuccess('Zaktualizowano wersję roboczą regulaminu'));
        } else {
          dispatch({ type: types.UPDATE_TERMS_FAILURE });
          dispatch(notificationActions.showError('Aktualizacja regulaminu nie powiodła się'));
          return logger.error(response.data);
        }
      });
  },
  updateClause: (id, content, languageCode) => dispatch => {
    return termsAndConditionsApi
      .updateClause(id, content, languageCode)
      .then(response => {
        if (response.ok && response.data.success !== false) {
          dispatch({ type: types.UPDATE_PERSONAL_DATA_PROCESSING_CLAUSE_SUCCESS, content });
          dispatch(notificationActions.showSuccess('Zaktualizowano klauzulę wyrażenia zgody na przetwarzanie danych'));
          return dispatch(push(routePaths.termsAndConditions));
        } else {
          dispatch({ type: types.UPDATE_PERSONAL_DATA_PROCESSING_CLAUSE_FAILURE });
          dispatch(notificationActions.showError('Aktualizacja klauzuli wyrażenia zgody na przetwarzanie danych nie powiodła się'));
          return logger.error(response.data);
        }
      });
  },
  updateAndPublish: (id, content, additionalImage, additionalImageDelete, languageCode) => dispatch => {
    return termsAndConditionsApi
      .update(id, content, additionalImage, additionalImageDelete, languageCode)
      .then(response => {
        if (response.ok && response.data.success !== false) {
          termsAndConditionsApi.publish(id)
            .then(response => {
              if (response.ok && response.data.success !== false) {
                dispatch({ type: types.PUBLISH_TERMS_SUCCESS });
                dispatch(notificationActions.showSuccess('Opublikowano nową wersję regulaminu'));
                return dispatch(push(routePaths.termsAndConditions));
              } else {
                dispatch({ type: types.PUBLISH_TERMS_FAILURE });
                const message = response.data.failure && response.data.failure.message ? response.data.failure.message : 'Wystąpił problem podczas publikowania regulaminu';
                dispatch(notificationActions.showError(message));
                return logger.error(response.data);
              }
            });
        } else {
          dispatch(notificationActions.showError('Aktualizacja regulaminu nie powiodła się'));
          return logger.error(response.data);
        }
      });
  },
};
