import React from 'react'
import PropTypes from "prop-types";
import { Form, Field } from 'react-final-form'
import Button from "@material-ui/core/Button";
import FormTextField from "../../form/final-form/FormTextField";
import useStyles from "./TOPT2FACodeFormStyle";

const TOPT2FACodeForm = ({onSubmit}) => {

  const validation = values => {
    const errors = {};

    if (!values.TOPTCode) {
      errors.TOPTCode = 'Pole wymagane';
    }
    if (!values.password) {
      errors.password = 'Pole wymagane';
    }
    return errors;
  };

  const classes = useStyles();

  return (
    <Form
      onSubmit={onSubmit}
      validate={validation}
      render={({handleSubmit, submitting, errors, touched}) => (
        <form onSubmit={handleSubmit}>
          <div>
            <div>
              <div>
                <Field
                  error={errors.TOPTCode && touched.TOPTCode}
                  name="TOPTCode"
                  id="TOPTCode"
                  variant="outlined"
                  component={FormTextField}
                  label="Wpisz kod"
                  helperText={errors.TOPTCode && touched.TOPTCode && "Pole wymagane"}
                  className={classes.textField}
                />
              </div>
              <div>
                <Field
                  error={errors.password && touched.password}
                  name="password"
                  id="password"
                  type="password"
                  variant="outlined"
                  component={FormTextField}
                  label="Wpisz hasło"
                  helperText={errors.password && touched.password && "Pole wymagane"}
                  className={classes.textField}
                />
              </div>
            </div>
            <Button
              variant="contained"
              type="submit"
              disabled={submitting}
              color="primary"
              className={classes.submitButton}
            >
              Włącz weryfikację dwuskładnikową
            </Button>
          </div>
        </form>)}
    />
  );

}

TOPT2FACodeForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default TOPT2FACodeForm
