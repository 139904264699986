import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { ZoneAreaTypes } from '../../models/zones/ZoneModel';
import { TextField } from '@material-ui/core';

class ZoneEditAreaDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      radius: props.zone.area.radius,
    };

    this.handleRadiusChange = this.handleRadiusChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.zone.area.radius !== this.state.radius) {
      this.setState({ radius: nextProps.zone.area.radius });
    }
  }

  handleRadiusChange(e) {
    this.setState({ radius: Number(e.target.value) });
  }

  render() {
    const { zone, onSave, onCancel } = this.props;
    return (
      <div style={{position: 'absolute', zIndex: 4, marginTop: 10, marginLeft: 'auto', marginRight: 'auto', left: 0, right: 300, width: 250}}>
        <Paper style={{padding: 10}}>
          Edycja obszaru <strong>{zone.name}</strong>
          {zone.areaType === ZoneAreaTypes.circle
            && <TextField label="Promień strefy" value={this.state.radius} type="number" onChange={this.handleRadiusChange} />}
          <div className="actions">
            <Button size="medium" onClick={onCancel}>Anuluj</Button>
            <Button color="primary" size="medium" onClick={() => onSave(this.state.radius)}>Zapisz</Button>
          </div>
        </Paper>
      </div>
    );
  }
}

ZoneEditAreaDetails.propTypes = {
  zone: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ZoneEditAreaDetails;
