import { push } from 'connected-react-router';
import bikesApi from '../api/bikesApi';
import routePaths from '../routePaths';
import { actions as notificationActions } from './notificationActions';
import * as logger from '../utils/logger';

export const types = {
  DECOMMISSION_BIKE_REQUEST: 'DECOMMISSION_BIKE_REQUEST',
  DECOMMISSION_BIKE_REQUEST_SUCCESS: 'DECOMMISSION_BIKE_REQUEST_SUCCESS',
  DECOMMISSION_BIKE_REQUEST_FAILURE: 'DECOMMISSION_BIKE_REQUEST_FAILURE',
  CONFIRM_BIKE_SERVICE: 'CONFIRM_BIKE_SERVICE',
  CONFIRM_BIKE_SERVICE_SUCCESS: 'CONFIRM_BIKE_SERVICE_SUCCESS',
  CONFIRM_BIKE_SERVICE_FAILURE: 'CONFIRM_BIKE_SERVICE_FAILURE',
  REGISTER_NEW_BIKE_REQUEST_SUBMIT: 'REGISTER_NEW_BIKE_REQUEST_SUBMIT',
  REGISTER_NEW_BIKE_REQUEST_SUCCESS: 'REGISTER_NEW_BIKE_REQUEST_SUCCESS',
  REGISTER_NEW_BIKE_REQUEST_FAILURE: 'REGISTER_NEW_BIKE_REQUEST_FAILURE',
  MODIFY_BIKE_REQUEST_SUBMIT: 'MODIFY_BIKE_REQUEST_SUBMIT',
  MODIFY_BIKE_REQUEST_SUCCESS: 'MODIFY_BIKE_REQUEST_SUCCESS',
  MODIFY_BIKE_REQUEST_FAILURE: 'MODIFY_BIKE_REQUEST_FAILURE',
  GET_BIKE_DETAILS_REQUEST: 'GET_BIKE_DETAILS_REQUEST',
  GET_BIKE_DETAILS_REQUEST_SUCCESS: 'GET_BIKE_DETAILS_REQUEST_SUCCESS',
  GET_BIKE_DETAILS_REQUEST_FAILURE: 'GET_BIKE_DETAILS_REQUEST_FAILURE',
  START_BIKE_CREATION: 'START_BIKE_CREATION',
  PUT_BIKE_OUT_OF_SERVICE_SUBMIT: 'PUT_BIKE_OUT_OF_SERVICE_SUBMIT',
  PUT_BIKE_OUT_OF_SERVICE_SUCCESS: 'PUT_BIKE_OUT_OF_SERVICE_SUCCESS',
  PUT_BIKE_OUT_OF_SERVICE_FAILURE: 'PUT_BIKE_OUT_OF_SERVICE_FAILURE',
  ADD_BIKE_NOTE: 'ADD_BIKE_NOTE',
  ADD_BIKE_NOTE_SUCCESS: 'ADD_BIKE_NOTE_SUCCESS',
  ADD_BIKE_NOTE_FAILURE: 'ADD_BIKE_NOTE_FAILURE',
  PUT_BIKE_IN_SERVICE_SUBMIT: 'PUT_BIKE_IN_SERVICE_SUBMIT',
  PUT_BIKE_IN_SERVICE_SUCCESS: 'PUT_BIKE_IN_SERVICE_SUCCESS',
  PUT_BIKE_IN_SERVICE_FAILURE: 'PUT_BIKE_IN_SERVICE_FAILURE',
  DEACTIVATE_ALARM: 'DEACTIVATE_ALARM',
  DEACTIVATE_ALARM_SUCCESS: 'DEACTIVATE_ALARM_SUCCESS',

  GET_BIKE_ALERTS: 'GET_BIKE_ALERTS',
  GET_BIKE_ALERTS_SUCCESS: 'GET_BIKE_ALERTS_SUCCESS',
  GET_BIKE_ALERTS_FAILURE: 'GET_BIKE_ALERTS_FAILURE',

  GET_BIKE_HISTORY: 'GET_BIKE_HISTORY',
  GET_BIKE_HISTORY_SUCCESS: 'GET_BIKE_HISTORY_SUCCESS',
  GET_BIKE_HISTORY_FAILURE: 'GET_BIKE_HISTORY_FAILURE',

  GET_BIKE_SERVICE_HISTORY: 'GET_BIKE_SERVICE_HISTORY',
  GET_BIKE_SERVICE_HISTORY_SUCCESS: 'GET_BIKE_SERVICE_HISTORY_SUCCESS',
  GET_BIKE_SERVICE_HISTORY_FAILURE: 'GET_BIKE_SERVICE_HISTORY_FAILURE',

  EXPORT_BIKE_HISTORY: 'EXPORT_BIKE_HISTORY',
  EXPORT_BIKE_HISTORY_SUCCESS: 'EXPORT_BIKE_HISTORY_SUCCESS',
  EXPORT_BIKE_HISTORY_FAILURE: 'EXPORT_BIKE_HISTORY_FAILURE',
};

function storeBlobAs(blob, filename) {
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  setTimeout(() => URL.revokeObjectURL(url), 60000);
  if (typeof link.download === 'string') {
    document.body.appendChild(link);
    link.download = filename;
    link.href = url;
    link.click();
    document.body.removeChild(link);
  } else {
    location.replace(url);
  }
}

export const actions = {
  loadBikeDetails: bikeId => dispatch => {
    dispatch({ type: types.GET_BIKE_DETAILS_REQUEST });
    return bikesApi.getBikesDetails(bikeId).then(res => {
      if (res.ok) {
        dispatch({ type: types.GET_BIKE_DETAILS_REQUEST_SUCCESS, bikeManagementDetails: res.data });
      } else {
        dispatch({ type: types.GET_BIKE_DETAILS_REQUEST_FAILURE });
        dispatch(notificationActions.showError('Nie można pobrać szczegółów roweru'));
        return logger.error(res.data);
      }
    });
  },

  loadBikeAlerts: bikeId => dispatch => {
    dispatch({ type: types.GET_BIKE_ALERTS });
    return bikesApi.getBikeAlerts(bikeId).then(res => {
      if (res.ok) {
        dispatch({ type: types.GET_BIKE_ALERTS_SUCCESS, data: res.data });
      } else {
        dispatch({ type: types.GET_BIKE_ALERTS_FAILURE });
        dispatch(notificationActions.showError('Nie udało się pobrać alertów roweru'));
        return logger.error(res.data);
      }
    });
  },

  loadBikeServiceHistory: bikeId => dispatch => {
    dispatch({ type: types.GET_BIKE_SERVICE_HISTORY });
    return bikesApi.getBikeServiceHistory(bikeId).then(res => {
      if (res.ok) {
        dispatch({ type: types.GET_BIKE_SERVICE_HISTORY_SUCCESS, data: res.data });
      } else {
        dispatch({ type: types.GET_BIKE_SERVICE_HISTORY_FAILURE });
        dispatch(notificationActions.showError('Nie udało się pobrać historii serwisu'));
        return logger.error(res.data);
      }
    });
  },

  loadBikeHistory: bikeId => dispatch => {
    dispatch({ type: types.GET_BIKE_SERVICE_HISTORY });
    return bikesApi.getBikeHistory(bikeId).then(res => {
      if (res.ok) {
        dispatch({ type: types.GET_BIKE_HISTORY_SUCCESS, data: res.data });
      } else {
        dispatch({ type: types.GET_BIKE_HISTORY_FAILURE });
        dispatch(notificationActions.showError('Nie udało się pobrać historii roweru'));
        return logger.error(res.data);
      }
    });
  },

  exportBikeHistory: bikeId => dispatch => {
    dispatch({ type: types.EXPORT_BIKE_HISTORY, bikeId });
    return bikesApi
      .getBikeHistoryReport(bikeId)
      .then(response => {
        if (response.ok && response.data.success !== false) {
          dispatch({ type: types.EXPORT_BIKE_HISTORY_SUCCESS, bikeId });
          const disposition = response.headers['content-disposition'];
          const fileName = disposition ? disposition.match(/filename="(.+)"/)[1] : 'eksport.xlsx';
          storeBlobAs(response.data, fileName);
          return true;
        } else {
          dispatch({ type: types.EXPORT_BIKE_HISTORY_FAILURE, bikeId });
          dispatch(notificationActions.showError('Wystąpił problem podczas eksportu'));
          logger.error(response.data);
          return false;
        }
      });
  },

  registerNew: bike => dispatch => {
    dispatch({ type: types.REGISTER_NEW_BIKE_REQUEST_SUBMIT });
    return bikesApi
      .registerNewBike(bike)
      .then(response => {
        if (response.ok && response.data.success !== false) {
          dispatch({ type: types.REGISTER_NEW_BIKE_REQUEST_SUCCESS, bike: Object.assign({}, bike, { id: response.data, isAvailableForRental: true }) });
          dispatch(notificationActions.showSuccess('Zapisano rower'));
          return dispatch(push(routePaths.bikes));
        } else {
          dispatch({ type: types.REGISTER_NEW_BIKE_REQUEST_FAILURE });
          dispatch(notificationActions.showError(`Zapis nie powiódł się ${response.data && response.data.failure ? response.data.failure.message : ''}`));

          return logger.error(response.data);
        }
      });
  },

  modifyBike: bike => dispatch => {
    dispatch({ type: types.MODIFY_BIKE_REQUEST_SUBMIT });
    return bikesApi
      .updateBikeDetails(bike.id, bike)
      .then(response => {
        if (response.ok && response.data.success !== false) {
          dispatch({ type: types.MODIFY_BIKE_REQUEST_SUCCESS });
          dispatch(notificationActions.showSuccess('Zapisano rower'));
          return dispatch(push(routePaths.bikes));
        } else {
          dispatch({ type: types.MODIFY_BIKE_REQUEST_FAILURE });
          dispatch(notificationActions.showError(`Zapis nie powiódł się ${response.data && response.data.failure ? response.data.failure.message : ''}`));

          return logger.error(response.data);
        }
      });
  },

  decommissionBike: bikeId => dispatch => {
    dispatch({ type: types.DECOMMISSION_BIKE_REQUEST });
    return bikesApi.decommissionBike(bikeId).then(response => {
      if (response.ok && response.data.success !== false) {
        dispatch({ type: types.DECOMMISSION_BIKE_REQUEST_SUCCESS, bikeId });
        dispatch(notificationActions.showSuccess('Rower został wycofany z eksploatacji'));
      } else {
        dispatch({ type: types.DECOMMISSION_BIKE_REQUEST_FAILURE, bikeId });
        dispatch(notificationActions.showError(`Wycofanie roweru z eksploatacji nie powiodło się ${response.data && response.data.failure ? response.data.failure.message : ''}`));

        return logger.error(response.data);
      }
    });
  },

  confirmService: (bikeId, note) => (dispatch, getState) => {
    const operator = getState().auth;
    const operatorName = `${operator.firstName} ${operator.lastName}`;
    dispatch({ type: types.CONFIRM_BIKE_SERVICE });
    return bikesApi.confirmService(bikeId, note).then(response => {
      if (response.ok && response.data.success !== false) {
        dispatch({
          type: types.CONFIRM_BIKE_SERVICE_SUCCESS,
          note,
          operatorName,
          bikeId,
          time: response.data.serviceTime,
          nextTime: response.data.nextServiceTime
        });
        dispatch(notificationActions.showSuccess('Zerejestrowano serwis'));
      } else {
        dispatch({ type: types.CONFIRM_BIKE_SERVICE_FAILURE, bikeId });
        dispatch(notificationActions.showError(`Nie udało się zerejestrować serwis u ${response.data && response.data.failure ? response.data.failure.message : ''}`));

        return logger.error(response.data);
      }
    });
  },

  sendLockClosedCommand: bikeId => dispatch => {
    return bikesApi
      .sendLockClosedCommand({ bikeId })
      .then(response => {
        if (response.ok && response.data.success !== false) {
          dispatch(notificationActions.showSuccess('Wysłano powiadomienie o zamknięciu zamka'));
        } else {
          dispatch(notificationActions.showError(`Zgłoszenie zamknięcia zamka nie powiodło się. ${response.data.failure ? response.data.failure.message : ''}`));

          return logger.error(response.data);
        }
      });
  },

  sendReportLockStatusCommand: bikeId => dispatch => {
    return bikesApi
      .sendReportLockStatusCommand({ bikeId })
      .then(response => {
        if (response.ok && response.data.success !== false) {
          dispatch(notificationActions.showSuccess('Wysłano żądanie odświeżenia danych'));
        } else {
          dispatch(notificationActions.showError(`Żądanie odświeżenia danych zamka nie powiodło się. ${response.data.failure ? response.data.failure.message : ''}`));

          return logger.error(response.data);
        }
      });
  },

  sendCustomLockCommand: (bikeId, command, lockDeviceType) => dispatch => {
    return bikesApi
      .sendCustomLockCommand({ bikeId, command, lockDeviceType })
      .then(response => {
        if (response.ok && response.data.success !== false) {
          dispatch(notificationActions.showSuccess('Wysłano komendę'));
        } else {
          dispatch(notificationActions.showError(`Wysyłanie komendy nie powiodło się. ${response.data.failure ? response.data.failure.message : ''}`));

          return logger.error(response.data);
        }
      });
  },

  addBikeNote: (bikeId, note) => (dispatch) => {
    dispatch({ type: types.ADD_BIKE_NOTE, bikeId, note });
    return bikesApi.addNote(bikeId, note).then(res => {
      if (res.ok && res.data.success !== false) {
        dispatch({ type: types.ADD_BIKE_NOTE_SUCCESS, bikeId, note });
        dispatch(notificationActions.showSuccess('Dodano notatkę'));
      } else {
        dispatch({ type: types.ADD_BIKE_NOTE_FAILURE, bikeId, note });
        dispatch(notificationActions.showError('Dodawanie notatki nie powiodło się'));
        return logger.error(res.data);
      }
    });
  },

  putBikeOutOfService: (bikeId, restrictionReason) => (dispatch) => {
    dispatch({ type: types.PUT_BIKE_OUT_OF_SERVICE_SUBMIT, bikeId, restrictionReason });
    return bikesApi.putOutOfService(bikeId, restrictionReason).then(res => {
      if (res.ok && res.data.success !== false) {
        dispatch({ type: types.PUT_BIKE_OUT_OF_SERVICE_SUCCESS, bikeId, restrictionReason });
        dispatch(notificationActions.showSuccess('Tymczasowo wycofano rower z użytku.'));
      } else {
        dispatch({ type: types.PUT_BIKE_OUT_OF_SERVICE_FAILURE, bikeId, restrictionReason });
        dispatch(notificationActions.showError('Tymczasowe wycofanie roweru z eksploatacji nie powiodło się.'));
        return logger.error(res.data);
      }
    });
  },

  putBikeInService: (bikeId) => (dispatch) => {
    dispatch({ type: types.PUT_BIKE_IN_SERVICE_SUBMIT, bikeId });
    return bikesApi.putInService(bikeId).then(res => {
      if (res.ok && res.data.success !== false) {
        dispatch({ type: types.PUT_BIKE_IN_SERVICE_SUCCESS, bikeId });
        dispatch(notificationActions.showSuccess('Rower przywrócony do użytkowania.'));
      } else {
        dispatch({ type: types.PUT_BIKE_IN_SERVICE_FAILURE, bikeId });
        dispatch(notificationActions.showError('Przywrócenie roweru do użytkowania nie powiodło się.'));
        return logger.error(res.data);
      }
    });
  },

  deactivateAlarm: bikeId => dispatch => {
    dispatch({ type: types.DEACTIVATE_ALARM });
    return bikesApi
      .deactivateAlarm(bikeId)
      .then(response => {
        if (response.ok && response.data.success !== false) {
          dispatch({ type: types.DEACTIVATE_ALARM_SUCCESS, bikeId });
          dispatch(notificationActions.showSuccess('Wyłączono alarm dla roweru'));
        } else {
          dispatch(notificationActions.showError(`Wyłączenie alarmu nie powiodło się. ${response.data.failure ? response.data.failure.message : ''}`));

          return logger.error(response.data);
        }
      });
  },

  startBikeCreation: () => dispatch => {
    dispatch({ type: types.START_BIKE_CREATION });
  },
};
