import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import FormTextField from '../form/redux-form/FormTextField';
import FormSelect from '../form/redux-form/FormSelect';
import FormCheckBox from '../form/redux-form/FormCheckBox';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import routePaths from '../../routePaths';
import { bikeDetailsFormValidation } from '../../models/bikesManagement/BikeDetailsValidation';
import { LockDeviceType } from '../../models/bikesManagement/BikeDetailsModel';
import './BikesManagementStyles.scss';

let BikeDataForm = ({
  handleSubmit,
  submitting,
  isEdit,
  bikeTypes }) => {
  return (
    <form onSubmit={handleSubmit}>
      <Typography variant="h5" gutterBottom>{isEdit ? 'Edycja danych roweru' : 'Nowy rower'}</Typography>

      <Typography variant="subtitle1" gutterBottom className="config-section-header">Szczegóły roweru</Typography>
      <Paper className="config-section" elevation={3}>
        <div>
          <Field
            name="bikeNumber"
            component={FormTextField}
            label="Numer roweru"
          />
        </div>
        <div>
          <Field
            name="bikeId"
            component={FormTextField}
            label="ID roweru"
          />
        </div>
        <div>
          <Field
            name="bikeSerialNumber"
            component={FormTextField}
            label="Numer seryjny roweru"
          />
        </div>
        <div>
          {bikeTypes.length > 0
            ? <Field
              name={'bikeTypeId'}
              component={FormSelect}
              label="Typ"
              style={{ width: 200 }}
              values={{ default: { value: null, name: '' }, ...bikeTypes.map(bt => ({ value: bt.id, name: bt.name })) }}
            />
            : null}
        </div>
      </Paper>

      <Typography variant="subtitle1" gutterBottom className="config-section-header">Szczegóły zamka</Typography>
      <Paper className="config-section" elevation={3}>
        <div>
          <Field
            name="lockType"
            component={FormSelect}
            label="Typ zamka"
            style={{ width: 200 }}
            values={LockDeviceType}
          />
        </div>
        <div>
          <Field
            name="lockImei"
            component={FormTextField}
            label="IMEI"
          />
        </div>
        <div>
          <Field
            name="lockPhoneNumber"
            component={FormTextField}
            label="Numer telefonu"
            type={'tel'}
          />
        </div>
        <div>
          <Field
            name="lockSimSerialNumber"
            component={FormTextField}
            label="Numer karty SIM"
          />
        </div>
        <div>
          <Field
            name="shackleStateNotificationsEnabled"
            component={FormCheckBox}
            label="Powiadomienia o błędach otwarcia zamka"
          />
        </div>
      </Paper>

      <br />
      <div className="actions">
        <Button style={{ marginRight: 8 }} component={Link} to={routePaths.bikes} disabled={submitting}>
          Anuluj
        </Button>
        <Button variant="contained" color="primary" type="submit" disabled={submitting}>
          {submitting ? 'Zapisywanie...' : 'Zapisz'}
        </Button>
      </div>
    </form>
  );
};

BikeDataForm.propTypes = {
  bikeTypes: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  isEdit: PropTypes.bool.isRequired,
};

BikeDataForm = reduxForm({
  form: 'bikeDetails',
  validate: bikeDetailsFormValidation,
  enableReinitialize: true
})(BikeDataForm);

BikeDataForm = connect(
  state => {
    return {
      initialValues: state.bikeManagementDetails,
      bikeTypes: state.bikeTypes.list,
    };
  }
)(BikeDataForm);

export default BikeDataForm;
