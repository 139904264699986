import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions as authActions } from '../../../actions/authActions';
import PasswordResetForm from './PasswordResetForm';
import AnonymousContentPanel from '../AnonymousContentPanel';
import queryString from 'query-string';

const PasswordResetPage = ({ authActions, token }) => (
  <AnonymousContentPanel>
    <p>Poprosiłeś o zmianę swojego hasła</p>
    <PasswordResetForm
      onSubmit={data => authActions.resetPassword(Object.assign({}, data, { token }))}
    />
  </AnonymousContentPanel>
);

PasswordResetPage.propTypes = {
  authActions: PropTypes.object.isRequired,
  token: PropTypes.string,
  submitting: PropTypes.bool,
};

function mapDispatchToProps(dispatch) {
  return {
    authActions: bindActionCreators(authActions, dispatch)
  };
}

function mapStateToProps(state, ownProps) {
  return {
    token: queryString.parse(ownProps.location.search).token,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordResetPage);
