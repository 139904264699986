import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from '../../actions/bikesManagementActions';
import { actions as actionsBikeTypes} from '../../actions/bikeTypesActions';
import BikeDataForm from './BikeDataForm';

const BikeDataView = ({bikeId, actions, bikeTypes, actionsBikeTypes }) => {

  const submitForm = (data) => {
    const submitAction = data.id
      ? actions.modifyBike
      : actions.registerNew;
    return submitAction(data);
  }

  useEffect(() => {
    if (!bikeId) {
      actions.startBikeCreation();
    }
    if(bikeTypes.list.length === 0){
      actionsBikeTypes.loadBikeTypes();
    }
  }, [])

  return(
    <BikeDataForm
      onSubmit={data => submitForm(data)}
      isEdit={bikeId != null}
    />
  )
}

BikeDataView.propTypes = {
  actions: PropTypes.object.isRequired,
  actionsBikeTypes: PropTypes.object.isRequired,
  submitting: PropTypes.bool,
  bikeId: PropTypes.string.isRequired,
  bikeManagementDetails: PropTypes.object,
  bikeTypes: PropTypes.object
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    actionsBikeTypes: bindActionCreators(actionsBikeTypes, dispatch)
  };
}

function mapStateToProps(state) {
  return {
    bikeManagementDetails: state.bikeManagementDetails,
    bikeTypes: state.bikeTypes,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BikeDataView);
