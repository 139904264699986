import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const FormCheckBox = ({
  input,
  label,
  readonly,

}) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={input.value ? true : false}
          disabled={readonly === true}
          onChange={e => {
            input.onChange(e, !input.value);
          }}
          value={input.name}
        />
      }
      label={label}
    />
  );
}

FormCheckBox.propTypes = {
  input: PropTypes.object,
  readonly: PropTypes.bool,
  label: PropTypes.string,
};

export default FormCheckBox;
