import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form'
import FormTextField from '../../form/redux-form/NativeFormTextField';
import { accountActivationFormValidation } from '../../../models/auth/AuthModelValidation';
import './PasswordRequireChangeFormStyles.scss';
import RenderErrorsFromFinalReduxFormState from "../../../utils/renderErrorsFromReduxFinalFormState";

const PasswordRequireChangeForm = ({ onSubmit }) => {
  return (
    <Form
      onSubmit={onSubmit}
      validate={accountActivationFormValidation}
      render={({ handleSubmit, errors, touched }) => (
        <form onSubmit={handleSubmit}>
            <div className="form-group">
              <div>
                <Field
                  id="newPassword"
                  name="newPassword"
                  type="password"
                  component={FormTextField}
                  label="Utwórz nowe hasło"
                  fullWidth
                  hiddenErrors
                />
              </div>
              <div style={{ marginTop: 20 }}>
                <Field
                  id="newPasswordRepeat"
                  name="newPasswordRepeat"
                  type="password"
                  component={FormTextField}
                  label="Powtórz hasło"
                  fullWidth
                  hiddenErrors
                />
              </div>
            </div>
          <RenderErrorsFromFinalReduxFormState
            errors={errors}
            touched={touched}
          />
          <button type="submit" className="btn btn-default">
            Zapisz
          </button>
        </form>
      )}
    />
  );
};

PasswordRequireChangeForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
};

export default PasswordRequireChangeForm;
