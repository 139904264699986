import React from 'react';
import TextFilter from '../../common/reportTable/filters/TextFilter';
import moment from 'moment';
import AccessTimeIcon from '@material-ui/icons/FindInPage';
import IconButton from '@material-ui/core/IconButton';
import { Link } from 'react-router-dom';
import routePaths from '../../../routePaths';
import { ActiveInactiveOptions, UserStatus } from '../../../reducers/reports/clients/models';
import SelectFilter from '../../common/reportTable/filters/SelectFilter';
import {ParseDedicatedPlansToSelect} from "../../../models/plans/PlanModel";

const styles = {
  deleted: {
    color: '#ccc',
  },
  locked: {
    color: '#ff8f00',
  },
};


/* eslint react/display-name: 0 */
/* eslint react/prop-types: 0 */
/* eslint react/no-multi-comp: 0 */
export const getColumns = (dedicatedPlans) => {
  return [
    {
      Header: '',
      id: 'show_details',
      Cell: row => (
        <Link to={`${routePaths.clientsReport}/${row.original.userId}/history`}>
          <IconButton style={{height: 24, width: 24, margin: "-3px 3px"}}>
            <AccessTimeIcon/>
          </IconButton>
        </Link>),
      width: 50,
      sortable: false,
      Filter: () => null,
    },
    {
      Header: 'Imię',
      id: 'firstName',
      accessor: a => <span style={styles[a.userStatus]}>{a.firstName}</span>,
      width: 160,
      Filter: ({filter, onChange}) => <TextFilter onChange={onChange} filter={filter} label="filtruj..."/>,
    },
    {
      Header: 'Nazwisko',
      id: 'lastName',
      accessor: a => <span style={styles[a.userStatus]}>{a.lastName}</span>,
      width: 160,
      Filter: ({filter, onChange}) => <TextFilter onChange={onChange} filter={filter} label="filtruj..."/>,
    },
    {
      Header: 'Nr telefonu',
      id: 'phoneNumber',
      accessor: a => <span style={styles[a.userStatus]}>{a.phoneNumber}</span>,
      width: 125,
      Filter: ({filter, onChange}) => <TextFilter onChange={onChange} filter={filter} label="filtruj..."/>,
    },
    {
      Header: 'Email',
      id: 'email',
      accessor: a => <span style={styles[a.userStatus]}>{a.email}</span>,
      width: 180,
      Filter: ({filter, onChange}) => <TextFilter onChange={onChange} filter={filter} label="filtruj..."/>,
    },
    {
      Header: 'Data rejestracji',
      id: 'registeredAt',
      accessor: d => <span
        style={styles[d.userStatus]}>{d.registeredAt ? moment(d.registeredAt).local().format('DD.MM.YYYY HH:mm') : null}</span>,
      width: 150,
      Filter: () => null,
    },
    {
      Header: 'Status konta',
      id: 'userStatus',
      accessor: a => <span style={styles[a.userStatus]}>{UserStatus[a.userStatus].name}</span>,
      width: 125,
      Filter: ({filter, onChange}) => <SelectFilter onChange={onChange} filter={filter} items={UserStatus}
                                                    label="filtruj..."/>,
    },
    {
      Header: 'Ostatnia aktywność',
      id: 'lastActive',
      accessor: a => moment(a.lastActive).local().format('DD.MM.YYYY HH:mm:ss'),
      width: 150,
      Filter: () => null,
    },
    {
      Header: 'Aktywność',
      id: 'activeClient',
      accessor: a => a.activeClient ? 'Aktywny' : 'Nieaktywny',
      width: 125,
      sortable: false,
      Filter: ({filter, onChange}) => <SelectFilter onChange={onChange} filter={filter} items={ActiveInactiveOptions}
                                                    label="filtruj..."/>,
    },
    {
      Header: 'Odrzucone usterki',
      id: 'rejectedDefectsCount',
      accessor: a => a.rejectedDefectsCount,
      width: 100,
      sortable: true,
      Filter: () => null,
    },
    {
      Header: 'Dedykowane plany',
      id: 'dedicatedPlans',
      accessor: (client) => {
        if (client.dedicatedPlans == null || client.dedicatedPlans.length === 0) return ""
        let plans = dedicatedPlans.filter(x => client.dedicatedPlans.includes(x.id))
        if (plans == null || plans.length === 0) return ""
        return plans.map(plan => plan.name).join(", ")
      },
      sortable: true,
      Filter: ({filter, onChange}) => <SelectFilter onChange={onChange} filter={filter}
                                                    items={ParseDedicatedPlansToSelect(dedicatedPlans)}
                                                    label="filtruj..."/>,
    }
  ];
}
