import { push } from 'connected-react-router';
import pricingApi from '../api/pricingApi';
import routePaths from '../routePaths';
import { PricingModel } from '../models/configuration/PricingModel';
import { actions as notificationActions } from './notificationActions';
import * as logger from '../utils/logger';

export const types = {
  START_PRICING_CREATION: 'START_PRICING_CREATION',
  LOAD_PRICINGS_SUBMIT: 'LOAD_PRICINGS_SUBMIT',
  LOAD_PRICINGS_SUCCESS: 'LOAD_PRICINGS_SUCCESS',
  LOAD_PRICINGS_FAILURE: 'LOAD_PRICINGS_FAILURE',
  LOAD_PRICING_SUBMIT: 'LOAD_PRICING_SUBMIT',
  LOAD_PRICING_SUCCESS: 'LOAD_PRICING_SUCCESS',
  LOAD_PRICING_FAILURE: 'LOAD_PRICING_FAILURE',
  DELETE_PRICING_SUBMIT: 'DELETE_PRICING_SUBMIT',
  DELETE_PRICING_SUCCESS: 'DELETE_PRICING_SUCCESS',
  DELETE_PRICING_FAILURE: 'DELETE_PRICING_FAILURE',
  UPDATE_PRICING_DETAILS_SUBMIT: 'UPDATE_PRICING_DETAILS_SUBMIT',
  UPDATE_PRICING_SUCCESS: 'UPDATE_PRICING_DETAILS_SUCCESS',
  UPDATE_PRICING_FAILURE: 'UPDATE_PRICING_DETAILS_FAILURE',
};

export const actions = {
  startPricingCreation: () => dispatch => {
    dispatch({ type: types.START_PRICING_CREATION });
  },
  loadAll: () => dispatch => {
    dispatch({ type: types.LOAD_PRICINGS_SUBMIT });

    return pricingApi
      .getAll()
      .then(response => {
        if (response.ok) {
          return dispatch({ type: types.LOAD_PRICINGS_SUCCESS, pricings: response.data });
        } else {
          dispatch({ type: types.LOAD_PRICINGS_FAILURE });
          dispatch(notificationActions.showError('Nie udało się załadować cenników'));

          return logger.error(response.data);
        }
      });
  },
  load: (id) => dispatch => {
    dispatch({ type: types.LOAD_PRICING_SUBMIT, id });

    return pricingApi
      .get(id)
      .then(response => {
        if (response.ok) {
          return dispatch({ type: types.LOAD_PRICING_SUCCESS, pricing: response.data });
        } else {
          dispatch({ type: types.LOAD_PRICING_FAILURE });
          dispatch(notificationActions.showError('Nie udało się załadować cennika'));

          return logger.error(response.data);
        }
      });
  },
  delete: id => dispatch => {
    dispatch({ type: types.DELETE_PRICING_SUBMIT, id });

    return pricingApi
      .delete(id)
      .then(response => {
        if (response.ok) {
          dispatch({ type: types.DELETE_PRICING_SUCCESS, id });
          dispatch(notificationActions.showError('Usunięto cennik'));
        } else {
          dispatch({ type: types.DELETE_PRICING_FAILURE });
          dispatch(notificationActions.showError('Nie udało się usunąć cennika'));
          return logger.error(response.data);
        }
      });
  },
  update: pricing => dispatch => {
    dispatch({ type: types.UPDATE_PRICING_DETAILS_SUBMIT });

    const pricingToUpdate = Object.assign(new PricingModel(), pricing);

    if (pricingToUpdate.rentalPreconditions && pricingToUpdate.rentalPreconditions.minimalBalance && !pricingToUpdate.rentalPreconditions.minimalBalance.enabled) delete pricingToUpdate.rentalPreconditions.minimalBalance;
    if (pricingToUpdate.deductions && pricingToUpdate.deductions.bikeLeftInZone && !pricingToUpdate.deductions.bikeLeftInZone.enabled) delete pricingToUpdate.deductions.bikeLeftInZone;
    if (pricingToUpdate.penalties && pricingToUpdate.penalties.bikeLeftOutOfPreferredZone && !pricingToUpdate.penalties.bikeLeftOutOfPreferredZone.enabled) delete pricingToUpdate.penalties.bikeLeftOutOfPreferredZone;
    if (pricingToUpdate.penalties && pricingToUpdate.penalties.bikeLeftOutOfOperatorZone && !pricingToUpdate.penalties.bikeLeftOutOfOperatorZone.enabled) delete pricingToUpdate.penalties.bikeLeftOutOfOperatorZone;

    return pricingApi
      .update(pricingToUpdate)
      .then(response => {
        if (response.ok && response.data.success !== false) {
          dispatch({ type: types.UPDATE_PRICING_SUCCESS, pricing });
          dispatch(notificationActions.showSuccess('Zaktualizowano cennik'));
          return dispatch(push(routePaths.pricings));
        } else {
          dispatch({ type: types.UPDATE_PRICING_FAILURE });
          dispatch(notificationActions.showError('Zapis nie powiódł się'));

          return logger.error(response.data);
        }
      });
  },
};
