
export const types = {
  SELECT_BIKE: 'SELECT_BIKE',
  DESELECT_BIKE: 'DESELECT_BIKE',
};

export const actions = {

  selectBike: (bike) => ({ type: types.SELECT_BIKE, bike }),
  deselectBike: (bike) => ({ type: types.DESELECT_BIKE, bike })

};