import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Table, TableBody, TableCell, TableRow, TableFooter, withStyles } from '@material-ui/core';
import _ from 'lodash';
import EnhancedTableHead from '../../enhencedTableHead/EnhancedTableHead';
import { ColumnModel } from '../../../models/ColumnModel';

const styles = theme => ({
  rowWithError: {
    backgroundColor: theme.palette.error.light,
  },
  normalRow: {
    backgroundColor: 'white',
  }
});

const columns = [
  new ColumnModel("name", false, false, "Strefa"),
  new ColumnModel("enabledBikesCount", true, false, "Liczba włączonych rowerów"),
  new ColumnModel("disabledBikesCount", true, false, "Liczba wyłączonych rowerów"),
  new ColumnModel("minimum", true, false, "Minimum"),
  new ColumnModel("bikesCount", true, false, "Razem"),
  new ColumnModel("maksimum", true, false, "Maksimum"),
];

class RelocationReportTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      order: "asc",
      orderBy: "name",
      filteredZones: props.relocationData.zones,
    };

    this.handleRequestSort = this.handleRequestSort.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ filteredZones: nextProps.relocationData.zones });
  }

  handleRequestSort(event, property) {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    const data = _.orderBy(this.state.filteredZones, [orderBy], [order]);

    this.setState({ order, orderBy, filteredZones: data });
  }

  render() {
    const { relocationData, classes } = this.props;
    const { filteredZones } = this.state;

    return (
      <Table>
        <EnhancedTableHead
          order={this.state.order}
          orderBy={this.state.orderBy}
          onRequestSort={this.handleRequestSort}
          headCells={columns}
        />
        <TableBody>
          {filteredZones && filteredZones.map(item => {
            return (
              <TableRow
                key={item.id}
                className={classNames(item.minimum != null && item.maximum != null && (item.bikesCount < item.minimum || item.bikesCount > item.maximum) ? classes.rowWithError : classes.normalRow)}>
                <TableCell>{item.name}</TableCell>
                <TableCell align="right">{item.enabledBikesCount}</TableCell>
                <TableCell align="right">{item.disabledBikesCount}</TableCell>
                <TableCell align="right">{item.minimum}</TableCell>
                <TableCell align="right">{item.bikesCount}</TableCell>
                <TableCell align="right">{item.maximum}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell><strong>Poza strefą</strong></TableCell>
            <TableCell align="right">{relocationData.bikesOutsideZonesCount - relocationData.disabledBikesOutsideZonesCount}</TableCell>
            <TableCell align="right">{relocationData.disabledBikesOutsideZonesCount}</TableCell>
            <TableCell align="right">{null}</TableCell>
            <TableCell align="right">{relocationData.bikesOutsideZonesCount}</TableCell>
            <TableCell align="right">{null}</TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    );
  }
}

RelocationReportTable.propTypes = {
  classes: PropTypes.object.isRequired,
  relocationData: PropTypes.object.isRequired,
};

export default withStyles(styles)(RelocationReportTable);
