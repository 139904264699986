import { AppBar, Paper, Tab, Tabs, Typography } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { actions } from '../../actions/bikesManagementActions';
import { hasPermission, permissions } from '../../utils/authorization';
import BikeAlertsView from './BikeAlertsView';
import BikeDataView from './BikeDataView';
import BikeHistoryView from './BikeHistoryView';
import BikeServiceHistoryView from './BikeServiceHistoryView';

const tabs = {
  details: 'details',
  alerts: 'alerts',
  service: 'service',
  log: 'log',
};

const BikeDetailsPage = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const bikeId = match.params.bikeId;
  const dispatch = useDispatch();
  const userPermissions = useSelector(s => s.auth.permissions);
  const canAccessLog = useMemo(() => hasPermission(userPermissions, permissions.bikes.log), [userPermissions]);
  const canAccessAlerts = useMemo(() => hasPermission(userPermissions, permissions.bikes.alerts), [userPermissions]);
  const canAccessService = useMemo(() => hasPermission(userPermissions, permissions.bikes.service.access), [userPermissions]);
  const canAccessDetails = useMemo(() => hasPermission(userPermissions, permissions.bikes.edit), [userPermissions]);
  const bike = useSelector(s => s.bikeManagementDetails);
  useEffect(() => { if (bikeId) dispatch(actions.loadBikeDetails(bikeId)) }, [bikeId]);
  const allowedTabs = [];
  if (canAccessDetails) allowedTabs.push(tabs.details);
  if (canAccessAlerts) allowedTabs.push(tabs.alerts);
  if (canAccessService) allowedTabs.push(tabs.service);
  if (canAccessLog) allowedTabs.push(tabs.history);

  const [activeTab, setActiveTab] = useState(history.location.hash.substring(1) || allowedTabs[0]);

  const handleChangeTab = (_, nextTab) => {
    setActiveTab(nextTab);
    history.replace(`#${nextTab}`);
  };

  return (
    <div className="page">
      <div style={{ margin: 16 }}>
        <Paper>
          {
            bikeId && bike &&
            <>
              <Typography>Numer roweru: <strong>{bike.bikeNumber}</strong></Typography>
              <Typography>ID roweru: <strong>{bike.bikeId}</strong></Typography>
            </>
          }
          <AppBar position="static" color="default" elevation={0}>
            <Tabs
              value={activeTab}
              onChange={handleChangeTab}
              scrollButtons="auto">
              {canAccessDetails && <Tab value={tabs.details} label={'Dane roweru'} />}
              {canAccessAlerts && bikeId && <Tab value={tabs.alerts} label={'Alarmy'} />}
              {canAccessService && bikeId && <Tab value={tabs.service} label={'Serwis'} />}
              {canAccessLog && bikeId && <Tab value={tabs.log} label={'Historia'} />}
            </Tabs>
          </AppBar>
          <div style={{ padding: 16 }}>
            {canAccessDetails && activeTab === tabs.details && <BikeDataView bikeId={bikeId} />}
            {canAccessAlerts && activeTab === tabs.alerts && bikeId && <BikeAlertsView bikeId={bikeId} />}
            {canAccessService && activeTab === tabs.service && bikeId && <BikeServiceHistoryView bikeId={bikeId} />}
            {canAccessLog && activeTab === tabs.log && bikeId && <BikeHistoryView bikeId={bikeId} />}
          </div>
        </Paper>
      </div>
    </div>
  );
}

export default BikeDetailsPage;
