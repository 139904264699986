import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions as bikeActions } from '../../actions/bikesActions';
import { actions as bikeTypesActions } from '../../actions/bikeTypesActions';
import { actions as tenantActions } from '../../actions/tenantActions';
import Button from '@material-ui/core/Button';
import ConfirmationIconButton from '../common/ConfirmationIconButton';
import Paper from '@material-ui/core/Paper';
import BikesList from './BikesList';
import PowerIcon from '@material-ui/icons/PowerSettingsNew';
import { Link } from 'react-router-dom';
import routePaths from '../../routePaths';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import grey from '@material-ui/core/colors/grey';
import { permissions, hasPermission } from '../../utils/authorization';
import queryString from 'query-string';
import DownloadIcon from "@material-ui/icons/GetApp";

class BikesManagementPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reportGenerating: false,
      filter: props.filter || '',
      bikeRentalsRestrictionInProgress: false,
    };

    this.handleRestrictBikeRentals = this.handleRestrictBikeRentals.bind(this);
    this.handleAllowBikeRentals = this.handleAllowBikeRentals.bind(this);
    this.onHandleExportBikeList = this.onHandleExportBikeList.bind(this);
  }

  componentDidMount() {
    this.props.bikeActions.loadAllBikes();
    this.props.bikeTypeActions.loadBikeTypes();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.filter !== nextProps.filter) {
      this.setState({ filter: nextProps.filter || '' });
    }
  }

  handleRestrictBikeRentals() {
    this.setState({ bikeRentalsRestrictionInProgress: true });
    this.props.tenantActions.restrictBikeRentals()
      .finally(() => this.setState({ bikeRentalsRestrictionInProgress: false }));
  }

  handleAllowBikeRentals() {
    this.setState({ bikeRentalsRestrictionInProgress: true });
    this.props.tenantActions.allowBikeRentals()
      .finally(() => this.setState({ bikeRentalsRestrictionInProgress: false }));
  }

  onHandleExportBikeList = async () => {
    this.setState({
      reportGenerating: true,
    });
    try {
      await this.props.bikeActions.exportBikeListReport();
    } finally {
      this.setState({ reportGenerating: false });
    }
  }

  render() {
    const { bikeRentalsRestrictionInProgress } = this.state;

    return (
      <div className="page padder">
        <div style={{ marginBottom: 16, display: 'flex', justifyContent: 'space-between' }}>
          {
            hasPermission(this.props.userPermissions, permissions.bikes.disableBikeRentals)
              ?
              (!this.props.areBikeRentalsRestricted
                ?
                <ConfirmationIconButton
                  confirmationTitle="Wyłączanie możliwości wypożyczenia rowerów"
                  confirmationText={`Czy chcesz wyłączyć możliwość wypożyczania rowerów oraz ukryć wszystkie rowery przed użytkownikami końcowymi?`}
                  onConfirm={this.handleRestrictBikeRentals}
                  onCancel={() => { }}
                  aria-label="Wyłącz możliwość wypożyczenia rowerów"
                  tooltip="Wyłącz możliwość wypożyczenia rowerów"
                  isDisabled={bikeRentalsRestrictionInProgress}
                >
                  <PowerIcon style={{ color: bikeRentalsRestrictionInProgress ? grey[500] : red[500] }} />
                </ConfirmationIconButton>
                :
                <ConfirmationIconButton
                  confirmationTitle="Przywracanie możliwości wypożyczenia rowerów"
                  confirmationText={`Czy chcesz przywrócić możliwość wypożyczania rowerów?`}
                  onConfirm={this.handleAllowBikeRentals}
                  onCancel={() => { }}
                  aria-label="Przywróć możliwość wypożyczenia rowerów"
                  tooltip="Przywróć możliwość wypożyczenia rowerów"
                  isDisabled={bikeRentalsRestrictionInProgress}
                >
                  <PowerIcon style={{ color: bikeRentalsRestrictionInProgress ? grey[500] : green[500] }} />
                </ConfirmationIconButton>)
              : null
          }
          <div>
            {hasPermission(this.props.userPermissions, permissions.bikes.types)
              ? <Button variant="contained" onClick={() => this.onHandleExportBikeList()} ><>
                <DownloadIcon /> Eksport listy rowerów </>
            </Button>
              : null
            }
            {hasPermission(this.props.userPermissions, permissions.bikes.types)
              ? <Button component={Link} to={routePaths.bikeTypes}>Typy rowerów</Button>
              : null
            }
            {hasPermission(this.props.userPermissions, permissions.bikes.add)
              ? <Button style={{ marginLeft: "8px" }} variant="contained" component={Link} color="primary" to={routePaths.createBike}>Dodaj rower</Button>
              : null
            }
          </div>
        </div>
        <Paper elevation={3} className="list-panel">
          {this.props.bikes.length ?
            <BikesList
              style={{ width: '100%', height: '100%', padding: 0 }}
              bikes={this.props.bikes}
              history={this.props.history}
              filter={this.state.filter}
              selected={[]}
            /> : null}
        </Paper>
      </div>
    );
  }
}

BikesManagementPage.propTypes = {
  bikeActions: PropTypes.object.isRequired,
  bikeTypeActions: PropTypes.object.isRequired,
  tenantActions: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  filter: PropTypes.string,
  bikes: PropTypes.array.isRequired,
  areBikeRentalsRestricted: PropTypes.bool,
  userPermissions: PropTypes.array.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    bikeActions: bindActionCreators(bikeActions, dispatch),
    bikeTypeActions: bindActionCreators(bikeTypesActions, dispatch),
    tenantActions: bindActionCreators(tenantActions, dispatch),
  };
}

function mapStateToProps(state, ownProps) {
  return {
    bikes: state.bikes,
    areBikeRentalsRestricted: state.tenant.areBikeRentalsRestricted,
    userPermissions: state.auth.permissions,
    filter: queryString.parse(ownProps.location.search).filter,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BikesManagementPage);
