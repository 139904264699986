import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Button from '@material-ui/core/Button';
import ConfirmationIconButton from '../common/ConfirmationIconButton';
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { actions } from '../../actions/operatorsActions';
import { connect } from 'react-redux';
import moment from 'moment';
import routePaths from '../../routePaths';
import './OperatorsListPageStyles.scss';
import * as auth from '../../models/auth/AuthModel';
import { hasPermission, permissions } from '../../utils/authorization';

class OperatorsListPage extends React.Component {

  constructor(props) {
    super(props);
    this._createOperatorListItem = this._createOperatorListItem.bind(this);
  }

  componentDidMount() {
    this.props.actions.loadAll();
  }

  _navigateToDetails(id) {
    this.props.history.push(`${routePaths.operators}/${id}`);
  }

  _deleteOperator(id) {
    this.props.actions.remove(id);
  }

  _createOperatorListItem(operator, index) {
    return (
      <ListItem
        key={index}
        button
        onClick={() => this._navigateToDetails(operator.id)}>
        <ListItemText
          primary={`${operator.firstName} ${operator.lastName}`}
          secondary={`${operator.email}, ${operator.accountActivated
            ? `aktywowano dnia: ${moment(operator.accountActivationDate).format('D MMM YYYY')}`
            : 'nieaktywowane'}, uprawnienia: ${auth.UserRoles[operator.role].name},
             2FA: ${operator.is2FAEnable ? "włączone" : "wyłączone"}`}
        />
        <ListItemSecondaryAction>
          <ConfirmationIconButton
            confirmationTitle="Usuwanie konta"
            confirmationText={`Czy na pewno chcesz usunąć konto operatora ${operator.firstName} ${operator.lastName}?`}
            onConfirm={() => this._deleteOperator(operator.id)}
            onCancel={() => { }}
            tooltip="Usuń konto"
            aria-label="Usuń konto"
          >
            <DeleteIcon />
          </ConfirmationIconButton>
        </ListItemSecondaryAction>
      </ListItem>
    );
  }

  render() {
    const { operators, userPermissions } = this.props;
    return (
      <div className="page">
        <div style={{ margin: 40 }}>
          <div style={{ textAlign: 'right', marginBottom: 16 }}>
            {hasPermission(userPermissions, permissions.autoLogoutSettings) && <Button component={Link} to={routePaths.autoLogoutSettings}>Ustawienia wylogowywania</Button>}
            <Button style={{ marginLeft: 10 }} variant="contained" component={Link} color="primary" to={routePaths.createOperator}>Dodaj operatora</Button>
          </div>
          <Paper elevation={3} className="list-panel">
            <List>
              {operators.map(this._createOperatorListItem)}
            </List>
          </Paper>
        </div>
      </div>
    );
  }
}

OperatorsListPage.propTypes = {
  actions: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  operators: PropTypes.array.isRequired,
  userPermissions: PropTypes.array.isRequired,
};


function mapStateToProps(state) {
  return {
    operators: state.operators.list,
    userPermissions: state.auth.permissions,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OperatorsListPage);
