import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import {Field, reduxForm} from 'redux-form';
import FormTextField from '../../form/redux-form/NativeFormTextField';
import { accountActivationFormValidation } from '../../../models/auth/AuthModelValidation';
import './PasswordResetFormStyles.scss';
import RenderErrorsFromReduxFormState from "../../../utils/renderErrorsFromReduxFormState";

let PasswordResetForm = ({ handleSubmit, submitting, passwordResetFormState }) => {

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <div>
          <Field
            name="newPassword"
            type="password"
            component={FormTextField}
            label="Utwórz hasło"
            hiddenErrors
          />
        </div>
        <div style={{ marginTop: 20 }}>
          <Field
            id="newPasswordRepeat"
            name="newPasswordRepeat"
            type="password"
            component={FormTextField}
            label="Powtórz hasło"
            fullWidth
            hiddenErrors
          />
        </div>
      </div>
      <RenderErrorsFromReduxFormState stateReduxForm={passwordResetFormState} />
      <button type="submit" className="btn btn-default" disabled={submitting}>
        Zapisz
      </button>
    </form>
  );
};

PasswordResetForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  passwordResetFormState: PropTypes.object.isRequired
};

PasswordResetForm = reduxForm({
  form: 'passwordResetForm',
  validate: accountActivationFormValidation,
})(PasswordResetForm);

PasswordResetForm = connect(state => {
  return {
    passwordResetFormState: state.form.passwordResetForm,
  }
})(PasswordResetForm)

export default PasswordResetForm;
