import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import FormTextField from '../../form/redux-form/NativeFormTextField';
import { signInFormValidation } from '../../../models/auth/AuthModelValidation';
import './SignInFormStyles.scss';

let SignInForm = ({ handleSubmit, submitting }) => {
  return (
    <>
      <h2>Logowanie</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <Field
            name="email"
            type="email"
            component={FormTextField}
            label="Email"
          />
        </div>
        <div className="form-group">
          <Field
            name="password"
            type="password"
            component={FormTextField}
            label="Hasło"
          />
        </div>
        <button type="submit" className="btn btn-default" disabled={submitting}>
          {submitting ? 'Logowanie...' : 'Zaloguj'}
        </button>
        <hr />
      </form>
    </>
  );
};

SignInForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
};

SignInForm = reduxForm({
  form: 'signIn',
  validate: signInFormValidation,
})(SignInForm);

export default SignInForm;
