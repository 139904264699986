import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import FormTextField from '../form/redux-form/FormTextField';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/AddBox';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

const PriceList = ({ fields, readonly }) => (
  <Grid container spacing={0}>
    <Grid container style={{ marginLeft: 4, marginBottom: 4 }}>
      <Grid item xs={9}>
        <Typography variant="caption">Opis</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="caption" style={{ paddingLeft: 8 }}>Kwota</Typography>
      </Grid>
    </Grid>
    {fields.map((priceRow, index) =>
      <Grid container key={index} style={{ marginLeft: 4, marginTop: 4 }}>
        <Grid item xs={9}>
          <Field
            name={`${priceRow}.description`}
            type="text"
            readonly={readonly}
            component={FormTextField}
            margin="dense"
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <Field
            name={`${priceRow}.amount`}
            type="number"
            component={FormTextField}
            readonly={readonly}
            margin="dense"
            fullWidth
            style={{ paddingLeft: 8 }}
            parse={Number}
          />
        </Grid>
        <Grid item xs={1} style={{ textAlign: 'right' }}>
          {!readonly &&
            <Tooltip title="Usuń">
              <IconButton onClick={() => fields.remove(index)} >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          }
        </Grid>
      </Grid>
    )}
    {!readonly &&
      <Grid container>
        <Grid item xs={12} style={{ marginTop: 16 }}>
          <Button size="small" color="secondary" onClick={() => fields.push({})}>
            <AddIcon /> Dodaj pozycję
        </Button>
        </Grid>
      </Grid>
    }
  </Grid>
);

PriceList.propTypes = {
  fields: PropTypes.object,
  readonly: PropTypes.bool,
  meta: PropTypes.object,
};

export default PriceList;
