import React, {useMemo, useState} from "react";
import PropTypes from "prop-types";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import SendIcon from "@material-ui/icons/Telegram";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  ListItemIcon,
  Typography,
} from "@material-ui/core";
import { LockDeviceType } from "../../models/bikesManagement/BikeDetailsModel";

const rooveeLockCommands = [
  { value: "CMD90110000", description: "Włączanie świateł" },
  { value: "ALARMON,10", description: "Włączanie alarmu dźwiękowego" },
  { value: "CMD40000000", description: "Domknięcie locka" },
  {
    value: "CMD10000000",
    description: "Otwarcie blokady",
    confirmation:
      "Czy jesteś pewien, że chcesz otworzyć blokadę w tym rowerze?",
  },
  { value: "WDTRST", description: "Reset procesora" },
  { value: "GPSP2F", description: "Odpalenie GPS" },
  { value: "AGPS", description: "AGPS" },
  { value: "BQRESET", description: "Reset ładowania" },
];

const omniLockCommands = [
  { value: "ALARMON,10", description: "Włączanie alarmu dźwiękowego" },
  {
    value: "UNLOCK,,",
    description: "Otwarcie blokady",
    confirmation:
      "Czy jesteś pewien, że chcesz otworzyć blokadę w tym rowerze?",
  },
  { value: "RESTART", description: "Restart zamka" },
  {
    value: "SHUTDOWN",
    description: "Wyłączenie zamka",
    confirmation:
      "Aby wykonać tę komendę zamek musi być otwarty, w przeciwnym razie zamiast wyłączenia zamka nastapi tylko jego restart. Czy potwierdasz wykonanie komendy?",
  },
];

const ITEM_HEIGHT = 48;

const LockCommandsButton = ({ onSelect, onBeforeShow, lockDeviceType }) => {

  const commands = useMemo(() => {
    return lockDeviceType === LockDeviceType.omni.value
      ? omniLockCommands
      : rooveeLockCommands
    },
    [lockDeviceType]
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [confirmedCommand, setConfirmedCommand] = useState(null);
  const handleClick = (event) => {
    onBeforeShow();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);
  const handleSelectCommand = (command) => {
    if (command.confirmation) {
      setConfirmedCommand(command);
    } else {
      onSelect(command.value);
      setAnchorEl(null);
    }
  };

  const handleConfirmCommand = () => {
    const cmd = confirmedCommand;
    setConfirmedCommand(null);
    onSelect(cmd.value);
    setAnchorEl(null);
  };

  const handleCancelCommand = () => {
    setConfirmedCommand(null);
    setAnchorEl(null);
  };

  return (
    <>
      <>
        <MenuItem
          onClick={handleClick}
          aria-label="more"
          aria-controls="commands-menu"
          aria-haspopup="true"
        >
          <ListItemIcon>
            <SendIcon />
          </ListItemIcon>
          <Typography variant="inherit">Wyślij komendę</Typography>
        </MenuItem>
      </>

      <Dialog open={confirmedCommand != null} onClose={handleCancelCommand}>
        {confirmedCommand != null && (
          <>
            <DialogTitle>{confirmedCommand.description}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {confirmedCommand.confirmation}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancelCommand}>NIE</Button>
              <Button
                variant="contained"
                onClick={handleConfirmCommand}
                color="primary"
              >
                TAK
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>

      <Menu
        id="commands-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 8,
          },
        }}
      >
        {commands.map((command) => (
          <MenuItem
            key={command.value}
            onClick={() => handleSelectCommand(command)}
          >
            <div>
              <Typography display="block">{command.description}</Typography>
            </div>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

LockCommandsButton.propTypes = {
  onBeforeShow: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  lockDeviceType: PropTypes.string.isRequired,
};

export default LockCommandsButton;
