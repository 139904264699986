import React from "react"
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles({
    container: {
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: "#cbcbcb",
      maxWidth: 370,
      borderRadius: 5,
      padding: "0 15px 15px 15px",
    },
    text: {
      textAlign: "justify"
    },
    backgroundText: {
      padding: "2px 4px",
      color: "#1f1e24",
      backgroundColor: "#ececef",
      borderRadius: 4,
      maxWidth: "fit-content",
      wordBreak: "break-all",
    },
    accountContainer: {
      marginTop: 5,
      marginBottom: 5,
    }
});

const TOPT2FACodeView = ({secretKey, account}) => {
  const classes = useStyles();
  return(
    <div className={classes.container}>
      <h3>Nie możesz zeskanować kodu?</h3>
      <div className={classes.text}>Aby dodać wpis ręcznie, podaj następujące dane do aplikacji w telefonie.</div>
      <div className={classes.accountContainer}>Konto: <div className={classes.backgroundText}>{account}</div></div>
      <div>Klucz: <div className={classes.backgroundText}>{secretKey}</div> </div>
    </div>
  )
}

TOPT2FACodeView.propTypes = {
  secretKey: PropTypes.string.isRequired,
  account: PropTypes.string.isRequired
}

export default TOPT2FACodeView;
