import { push } from "connected-react-router";
import cardsApi from "../api/cardsApi";
import { actions as notificationActions } from "./notificationActions";
import routePaths from "../routePaths";
import * as logger from "../utils/logger";

export const types = {
  GET_CARDS: "GET_CARDS",
  GET_CARDS_SUCCESS: "GET_CARDS_SUCCESS",
  CREATE_CARD_SUCCESS: "CREATE_CARD_SUCCESS",
  CREATE_CARD_FAILURE: "CREATE_CARD_FAILURE",
  UPDATE_CARD_SUCCESS: "UPDATE_CARD_SUCCESS",
  UPDATE_CARD_FAILURE: "UPDATE_CARD_FAILURE",
  LOAD_CARD_CLIENTS: "LOAD_CARD_CLIENTS",
  LOAD_CARD_CLIENTS_SUCCESS: "LOAD_CARD_CLIENTS_SUCCESS",
  REMOVE_CLIENT_CARD: "REMOVE_CLIENT_CARD",
  REMOVE_CLIENT_CARD_SUCCESS: "REMOVE_CLIENT_CARD_SUCCESS",
  LOAD_CLIENT_CARDS: "LOAD_CLIENT_CARDS",
  LOAD_CLIENT_CARDS_SUCCESS: "LOAD_CLIENT_CARDS_SUCCESS",
  ASSIGN_CLIENT_CARD: "ASSIGN_CLIENT_CARD",
  ASSIGN_CLIENT_CARD_SUCCESS: "ASSIGN_CLIENT_CARD_SUCCESS",
  ASSIGN_CLIENT_CARD_FAILURE: "ASSIGN_CLIENT_CARD_FAILURE",
};

export const actions = {
  load: () => (dispatch) => {
    dispatch({ type: types.GET_CARDS });
    return cardsApi.getAll().then((response) => {
      if (response.ok) {
        dispatch({ type: types.GET_CARDS_SUCCESS, data: response.data });
      }
    });
  },
  create: (data) => (dispatch) => {
    return cardsApi.create(data).then((response) => {
      if (response.ok && response.data.success !== false) {
        dispatch({ type: types.CREATE_CARD_SUCCESS, data });
        dispatch(
          notificationActions.showSuccess("Włączono obsługę nowej karty")
        );
        return dispatch(
          push(routePaths.cardDetails.replace(":id", response.data.id))
        );
      } else {
        dispatch({ type: types.CREATE_CARD_FAILURE });
        dispatch(notificationActions.showError("Zapis nie powiódł się"));

        return logger.error(response.data);
      }
    });
  },
  update: (id, dataRaw) => (dispatch) => {
    const data = {
      ...dataRaw,
      minimumUserBalance: dataRaw.minimumUserBalanceEnabled
        ? parseFloat(dataRaw.minimumUserBalance)
        : null,
      initialUserBalance: dataRaw.initialUserBalanceEnabled
        ? parseFloat(dataRaw.initialUserBalance)
        : null,
    };
    return cardsApi.update(id, data).then((response) => {
      if (response.ok && response.data.success !== false) {
        dispatch({ type: types.UPDATE_CARD_SUCCESS, data });
        dispatch(
          notificationActions.showSuccess("Zaktualizowano szczegóły karty")
        );
        return dispatch(push(routePaths.cards));
      } else {
        dispatch({ type: types.UPDATE_CARD_FAILURE });
        dispatch(notificationActions.showError("Zapis nie powiódł się"));

        return logger.error(response.data);
      }
    });
  },
  loadCardClients:
    (cardId, pageNumber, pageSize, sortBy, filterBy) => (dispatch) => {
      dispatch({
        type: types.LOAD_CARD_CLIENTS,
        pageNumber,
        pageSize,
        sortBy,
        filterBy,
      });
      return cardsApi
        .getCardClients(cardId, pageNumber, pageSize, sortBy, filterBy)
        .then((res) => {
          if (res.ok) {
            dispatch({
              type: types.LOAD_CARD_CLIENTS_SUCCESS,
              result: res.data || {},
            });
          }
        });
    },
  removeClientCard: (cardId) => (dispatch) => {
    dispatch({ type: types.REMOVE_CLIENT_CARD, cardId });
    return cardsApi.removeClientCard(cardId).then((res) => {
      if (res.ok) {
        dispatch({ type: types.REMOVE_CLIENT_CARD_SUCCESS, cardId });
      } else {
        dispatch(
          notificationActions.showError(
            "Wystąpił problem podczas usuwania karty"
          )
        );
      }
    });
  },
  assignCardToClient: (userId, cardNumber, cardProviderId) => (dispatch) => {
    dispatch({
      type: types.ASSIGN_CLIENT_CARD,
      userId,
      cardNumber,
      cardProviderId,
    });
    return cardsApi
      .assignCardToClient(userId, cardNumber, cardProviderId)
      .then((res) => {
        if (res.ok && res.data.success !== false) {
          dispatch({
            type: types.ASSIGN_CLIENT_CARD_SUCCESS,
            userId,
            cardNumber,
            cardProviderId,
          });
          dispatch(
            notificationActions.showSuccess("Pomyślnie przypisano kartę")
          );
          return true;
        } else {
          dispatch({
            type: types.ASSIGN_CLIENT_CARD_FAILURE,
            userId,
            cardNumber,
            cardProviderId,
          });
          if (
            res.data &&
            res.data.failure.errorCode ===
              "errorCode.userManagement.cards.cardInUseByAnotherUser"
          ) {
            dispatch(notificationActions.showError(res.data.failure.message));
          } else {
            dispatch(
              notificationActions.showError(
                "Wystąpił problem podczas przypisywania karty"
              )
            );
          }
          return false;
        }
      })
      .catch(() => false);
  },
  loadClientCards:
    (userId, pageNumber, pageSize, sortBy, filterBy) => (dispatch) => {
      dispatch({
        type: types.LOAD_CLIENT_CARDS,
        pageNumber,
        pageSize,
        sortBy,
        filterBy,
      });
      return cardsApi
        .getClientCards(userId, pageNumber, pageSize, sortBy, filterBy)
        .then((res) => {
          if (res.ok) {
            dispatch({
              type: types.LOAD_CLIENT_CARDS_SUCCESS,
              result: res.data || {},
            });
          }
        });
    },
};
