import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "moment-duration-format";
import ReportTable from "../../common/reportTable/ReportTable";
import { actions } from "../../../actions/cardsActions";
import { columns } from "./ClientCardsColumns";
import { Button } from "@material-ui/core";
import NfcIcon from "@material-ui/icons/Nfc";
import { hasPermission, permissions } from "../../../utils/authorization";
import AssignRFIDCardDialog from "./AssignRFIDCardDialog";

class ClientCardsDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assignRFIDCardDialogVisible: false,
      assignRFIDCardSubmitting: false,
    };

    this.handleSortChange = this.handleSortChange.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
    this.handleAssignRFIDCard = this.handleAssignRFIDCard.bind(this);
    this.handleAssignRFIDCardCancel =
      this.handleAssignRFIDCardCancel.bind(this);
    this.handleShowAssignRFIDCardDialog =
      this.handleShowAssignRFIDCardDialog.bind(this);
    this.handleLoadCards = this.handleLoadCards.bind(this);
    this.handleLoadCardProviders = this.handleLoadCardProviders.bind(this);
  }

  componentDidMount() {
    this.handleLoadCardProviders();
    this.handleLoadCards();
  }

  handleLoadCardProviders() {
    this.props.actions.load();
  }

  handleLoadCards() {
    const transactions = this.props.data;
    this.props.actions.loadClientCards(
      this.props.userId,
      1,
      transactions.paging.pageSize,
      transactions.sortedBy,
      transactions.filteredBy
    );
  }

  handleSortChange(sortBy) {
    const transactions = this.props.data;
    this.props.actions.loadClientCards(
      this.props.userId,
      1,
      transactions.paging.pageSize,
      sortBy,
      transactions.filteredBy
    );
  }

  handlePageChange(nextPage) {
    const transactions = this.props.data;
    this.props.actions.loadClientCards(
      this.props.userId,
      nextPage,
      transactions.paging.pageSize,
      transactions.sortedBy,
      transactions.filteredBy
    );
  }

  handlePageSizeChange(nextPageSize) {
    const transactions = this.props.data;
    this.props.actions.loadClientCards(
      this.props.userId,
      1,
      nextPageSize,
      transactions.sortedBy,
      transactions.filteredBy
    );
  }

  handleAssignRFIDCardCancel() {
    this.setState({ assignRFIDCardDialogVisible: false });
  }

  handleAssignRFIDCard(data) {
    this.setState({ assignRFIDCardSubmitting: true });
    this.props.actions
      .assignCardToClient(this.props.userId, data.cardNumber, "NFC_ROOVEE")
      .then((ok) => {
        this.setState({ assignRFIDCardSubmitting: false });
        if (ok) {
          setTimeout(() => this.handleLoadCards(), 2000);
          this.setState({ assignRFIDCardDialogVisible: false });
        }
      });
  }

  handleShowAssignRFIDCardDialog() {
    this.setState({ assignRFIDCardDialogVisible: true });
  }

  render() {
    const { data, userDeleted, operatorPermissions, cardProviders } =
      this.props;
    return (
      <div>
        {cardProviders &&
          cardProviders.configured.find(
            (cp) => cp.enabled && cp.cardProviderId === "NFC_ROOVEE"
          ) &&
          hasPermission(operatorPermissions, permissions.manageCards) && (
            <div className="panel-right">
              <Button
                size="small"
                variant="outlined"
                color="secondary"
                disabled={
                  (data != null &&
                    data.records != null &&
                    data.records.find(
                      (x) => x.cardProviderId === "NFC_ROOVEE"
                    )) ||
                  userDeleted
                }
                onClick={this.handleShowAssignRFIDCardDialog}
              >
                <NfcIcon style={{ fontSize: 20 }} />
                Przypisz kartę RFID Roovee
              </Button>
              <AssignRFIDCardDialog
                open={this.state.assignRFIDCardDialogVisible}
                onSave={this.handleAssignRFIDCard}
                onCancel={this.handleAssignRFIDCardCancel}
                submitting={this.state.assignRFIDCardSubmitting}
              />
            </div>
          )}
        <ReportTable
          style={{ height: "calc(100vh - 200px)" }}
          columns={columns}
          data={data.records}
          filteredBy={data.filteredBy}
          sortedBy={data.sortedBy}
          paging={data.paging}
          onFilteredChange={this.handleFiltersChange}
          onPageChange={this.handlePageChange}
          onPageSizeChange={this.handlePageSizeChange}
          onSortedChange={this.handleSortChange}
          loading={data.loading}
        />
      </div>
    );
  }
}

ClientCardsDetails.propTypes = {
  actions: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  data: PropTypes.object,
  userId: PropTypes.string.isRequired,
  operatorPermissions: PropTypes.array.isRequired,
  userDeleted: PropTypes.bool.isRequired,
  cardProviders: PropTypes.object.isRequired,
};

ClientCardsDetails.defaultProps = {
  loading: false,
  transactions: {},
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    data: state.reports.clientCards.pagedData,
    loading: state.reports.clientCards.loading,
    operatorPermissions: state.auth.permissions,
    cardProviders: state.cards.data,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientCardsDetails);
