import { CircularProgress, Fab, makeStyles } from '@material-ui/core';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import { Circle, GoogleMap, Marker, Polygon, useJsApiLoader } from '@react-google-maps/api';
import config from '../../../config';
import {useDispatch, useSelector} from 'react-redux';
import BikeMarker from './BikeMarker';
import { useWindowSize } from '@react-hook/window-size'
import LocationIcon from '@material-ui/icons/MyLocation';
import BikeDetailsCard from './BikeDetailsCard';
import { ZoneAreaTypes } from '../../../models/zones/ZoneModel';
import { geolocated } from 'react-geolocated';
import { object } from 'prop-types';
import { RegionModel } from '../../../models/RegionModel';
import { blue } from '@material-ui/core/colors';
import BikesSidebarList from '../../bikesList/BikesSidebarList';
import {actions as bikesActions} from "../../../actions/bikesActions";

const libs = [];

const useStyles = makeStyles(theme => ({
  searchBox: {
    boxSizing: `border-box`,
    border: `1px solid transparent`,
    width: `240px`,
    height: `32px`,
    padding: `0 12px`,
    borderRadius: `3px`,
    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
    fontSize: `14px`,
    outline: `none`,
    textOverflow: `ellipses`,
    position: "absolute",
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  relative: {
    position: 'relative'
  },
  locationFab: {
    position: 'absolute',
    bottom: 24,
    right: 60,
    background: '#fafafa',
  }
}));

const userLocationIcon = {
  path: 'M12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm8.94 3c-.46-4.17-3.77-7.48-7.94-7.94V1h-2v2.06C6.83 3.52 3.52 6.83 3.06 11H1v2h2.06c.46 4.17 3.77 7.48 7.94 7.94V23h2v-2.06c4.17-.46 7.48-3.77 7.94-7.94H23v-2h-2.06zM12 19c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z',
  fillColor: blue[600],
  fillOpacity: 1,
  strokeWeight: 0.4,
  strokeColor: blue[300],
  anchor: { x: 12, y: 24 }
};

const shapeOptions = {
  operationsZone: {
    strokeColor: "#000",
    strokeOpacity: 0.8,
    strokeWeight: 4,
    fillColor: "#607D8B",
    fillOpacity: 0.3,
    clickable: false,
    zIndex: 1,
  },
  preferredBikeReturnZone: {
    strokeColor: "#e9e623",
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: "#e9e623",
    fillOpacity: 0.5,
    clickable: false,
    zIndex: 2,
  },
};

const ServiceMap = ({ coords }) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: config.GOOGLE_MAPS_API_KEY,
    libraries: libs,
  });

  const dispatch = useDispatch();

  const mapRef = useRef(null);
  const classes = useStyles();
  const [windowWidth, windowHeight] = useWindowSize();

  const [selectedBike, setSelectedBike] = useState(null);
  const [searchResultMarker, setSearchResultMarker] = useState(null);

  const initialZoom = useSelector(s => s.configuration.initialZoom);
  const initialLocation = useSelector(s => s.configuration.initialLocation);
  const bikes = useSelector(s => s.bikes);
  const zones = useSelector(s => s.zones);

  useEffect(() => {
    dispatch(bikesActions.loadAllBikes({hideBikeAfterLoseGPS: true}));
  }, [])

  const bikesWithAlerts = useMemo(() => bikes.filter(b => b.alerts.length > 0), [bikes]);
  const listWidth = useMemo(() => windowWidth < 768 ? 0 : 'auto', [windowWidth]);

  const handleMapClicked = useCallback(() => {
    setSearchResultMarker(null);
    setSelectedBike(null);
  }, []);

  const handleSelectBike = useCallback(bike => setSelectedBike(bike), []);
  const handleGoToMyLocation = useCallback(() => mapRef.current.fitBounds(new RegionModel(coords).toLatLngBounds()), [coords]);

  const renderMap = () => (
    <div className="page flex-row relative" style={{ overflow: 'hidden' }}>
      <div className="relative" style={{ width: '100%' }}>
        <GoogleMap
          onLoad={mapInstance => mapRef.current = mapInstance}
          options={{
            streetViewControl: false,
            mapTypeControl: true,
            clickableIcons: false,
            scaleControl: true,
            /* eslint-disable no-undef */
            mapTypeControlOptions: { position: google.maps.ControlPosition.TOP_RIGHT },
          }}
          mapContainerStyle={{ width: '100%', height: windowHeight - 64 }}
          center={initialLocation}
          zoom={initialZoom}
          onClick={handleMapClicked}
        >
          {searchResultMarker && <Marker position={searchResultMarker} />}
          {zones ? zones.filter(zone => zone.areaType === ZoneAreaTypes.polygon).map(zone => (
            <Polygon
              key={zone.id}
              path={zone.area}
              options={shapeOptions[zone.type]}
            />)) : null}
          {zones ? zones.filter(zone => zone.areaType === ZoneAreaTypes.circle).map(zone => (
            <Circle
              key={zone.id}
              center={zone.area.center}
              radius={zone.area.radius}
              options={shapeOptions[zone.type]}
            />)) : null}
          {zones ? zones.filter(z => z.marker).map(zone => (
            <Marker
              key={zone.id}
              icon={zone.marker.icon ? { url: zone.marker.icon, scaledSize: new google.maps.Size(32, 32) } : null}
              position={zone.marker}
            />)) : null}

          {bikes && bikes.map((bike) => <BikeMarker
            key={bike.id}
            bike={bike}
            onSelect={handleSelectBike}
          />)}

          {selectedBike && <BikeDetailsCard bike={selectedBike} />}

          {coords &&
            <>
              <Marker icon={userLocationIcon} position={{ lat: coords.latitude, lng: coords.longitude }} />
              <Fab size="small" className={classes.locationFab} onClick={handleGoToMyLocation}>
                <LocationIcon />
              </Fab>
            </>
          }

        </GoogleMap>
      </div>
      <BikesSidebarList
        style={{ width: listWidth, height: windowHeight - 64, padding: 0, overflowY: 'auto' }}
        bikes={bikesWithAlerts}
        onBikeSelect={handleSelectBike}
        selectedBike={selectedBike}
      />
    </div>
  );

  if (isLoaded) return renderMap();
  return (
    <div style={{ width: '100%', textAlign: 'center' }}>
      <CircularProgress size={50} />
    </div>
  );
}

ServiceMap.propTypes = {
  coords: object,
}

export default geolocated({
  positionOptions: {
    enableHighAccuracy: true,
    maximumAge: 0,
    timeout: Infinity,
  },
  userDecisionTimeout: 5000,
})(ServiceMap);
