import React, { useState } from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import moment from "moment";
import ResolveDefectDialog from "./ResolveDefectDialog";
import { DefectStatus } from "../../../models/bikeDefects/DefectStatus";
import durationFormatter from "../../../utils/durationFormatter";
import RejectDefectDialog from "./RejectDefectDialog";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../actions/bikeDefectsActions";
import ResolveDefectWithPartsDialog from "./ResolveDefectWithPartsDialog";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CheckIcon from "@material-ui/icons/Check";
import BuildIcon from "@material-ui/icons/Build";

const OperationsPanel = ({
  details,
  resolveDefect,
  rejectDefect,
  resolveDefectWithParts,
}) => {
  const [resolveDefectDialogVisible, setResolveDefectDialogVisible] =
    useState(false);
  const [
    resolveDefectWithPartsDialogVisible,
    setResolveDefectWithPartsDialogVisible,
  ] = useState(false);
  const [rejectDefectDialogVisible, setRejectDefectDialogVisible] =
    useState(false);
  const [submitting, setSubmitting] = useState(false);

  const dispatch = useDispatch();

  const parts = useSelector((x) => x.bikeDefects.serviceParts);

  const handleShowResolveDialog = () => setResolveDefectDialogVisible(true);

  const handleShowResolveWithPartsDialog = async () => {
    await dispatch(actions.getServicePartsList());
    setResolveDefectWithPartsDialogVisible(true);
  };

  const handleResolveDefect = (data) => {
    setSubmitting(true);
    resolveDefect(details.id, data).then(() => {
      setSubmitting(false);
      setResolveDefectDialogVisible(false);
    });
  };

  const handleResolveDefectCancel = () => setResolveDefectDialogVisible(false);

  const handleShowRejectDialog = () => setRejectDefectDialogVisible(true);

  const handleRejectDefect = () => {
    setSubmitting(true);
    return rejectDefect(details.id).then(() => {
      setSubmitting(false);
      setRejectDefectDialogVisible(false);
    });
  };

  const handleResolveDefectWithParts = (data) => {
    setSubmitting(true);
    const payload = {
      resolutionComments: data.resolutionComments,
      partsList: Object.values(data.parts)
        .filter((p) => p.selected)
        .map((p) => ({ partId: p.id, partName: p.name, count: p.count })),
    };

    return resolveDefectWithParts(details.id, payload).then(() => {
      setSubmitting(false);
      setResolveDefectWithPartsDialogVisible(false);
    });
  };

  const handleResolveDefectWithPartsCancel = () =>
    setResolveDefectWithPartsDialogVisible(false);

  const handleRejectDefectCancel = () => setRejectDefectDialogVisible(false);

  const renderResolutionDetails = (details) => {
    return (
      <Grid
        container
        spacing={3}
        alignItems="stretch"
        direction="row"
        justify="flex-start"
      >
        <Grid item xs={6} md={2}>
          <Typography color="textSecondary">Status</Typography>
          <Typography>{details.status.name}</Typography>
        </Grid>
        <Grid item xs={6} md={2}>
          <Typography color="textSecondary">Data zamknięcia</Typography>
          <Typography>
            {moment(details.resolvedAt).local().format("DD.MM.YYYY HH:mm")}
          </Typography>
        </Grid>
        {details.resolvedAfter ? (
          <Grid item xs={6} md={2}>
            <Typography color="textSecondary">Czas zamknięcia</Typography>
            <Typography>
              {durationFormatter.format(details.resolvedAfter)}
            </Typography>
          </Grid>
        ) : null}
        <Grid item xs={6} md={2}>
          <Typography color="textSecondary">Imię i nazwisko</Typography>
          <Typography>
            {details.resolvedBy.firstName} {details.resolvedBy.lastName}
          </Typography>
        </Grid>
        {details.resolutionComments ? (
          <Grid item xs={6} md={2}>
            <Typography color="textSecondary">Notatka</Typography>
            <Typography>{details.resolutionComments}</Typography>
          </Grid>
        ) : null}
        {details.status.value === DefectStatus.resolvedWithParts.value ? (
          <Grid item xs={6} md={2}>
            <Typography color="textSecondary">Wymienione części</Typography>
            <Typography>
              <ul>
                {details.partsList.map((p, i) => (
                  <li key={i}>
                    {p.partName}:&nbsp;{p.count}
                  </li>
                ))}
              </ul>
            </Typography>
          </Grid>
        ) : null}
      </Grid>
    );
  };

  const renderResolveDefectOptions = () => {
    return (
      <div>
        <ResolveDefectDialog
          open={resolveDefectDialogVisible}
          onSave={handleResolveDefect}
          onCancel={handleResolveDefectCancel}
          submitting={submitting}
        />
        <RejectDefectDialog
          open={rejectDefectDialogVisible}
          onSave={handleRejectDefect}
          onCancel={handleRejectDefectCancel}
          submitting={submitting}
        />
        <ResolveDefectWithPartsDialog
          open={resolveDefectWithPartsDialogVisible}
          parts={parts}
          onSave={handleResolveDefectWithParts}
          onCancel={handleResolveDefectWithPartsCancel}
          submitting={submitting}
        />
        <Button
          style={{ marginRight: 8 }}
          variant="contained"
          size="small"
          color="secondary"
          onClick={handleShowRejectDialog}
        >
          <HighlightOffIcon />
          Odrzuć zgłoszenie
        </Button>
        <Button
          style={{ marginRight: 8 }}
          variant="contained"
          size="small"
          color="primary"
          onClick={handleShowResolveDialog}
        >
          <CheckIcon />
          Zamknij zgłoszenie
        </Button>
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={handleShowResolveWithPartsDialog}
        >
          <BuildIcon />
          Zamknij z wymianą części
        </Button>
      </div>
    );
  };

  return (
    <div>
      <Typography variant="subtitle1" gutterBottom>
        Operacje
      </Typography>
      {[
        DefectStatus.resolved.value,
        DefectStatus.rejected.value,
        DefectStatus.resolvedWithParts.value,
      ].some((x) => details.status.value === x)
        ? renderResolutionDetails(details)
        : renderResolveDefectOptions()}
    </div>
  );
};

OperationsPanel.propTypes = {
  details: PropTypes.object.isRequired,
  resolveDefect: PropTypes.func.isRequired,
  rejectDefect: PropTypes.func.isRequired,
  resolveDefectWithParts: PropTypes.func.isRequired,
};

export default OperationsPanel;
