import { Paper, Typography } from '@material-ui/core';
import { push } from 'connected-react-router';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../../actions/tenantActions';
import routePaths from '../../../routePaths';
import AutoLogoutSettingsForm from './AutoLogoutSettingsForm';

const AutoLogoutSettingsPage = () => {
  const dispatch = useDispatch();
  const autoLogoutTimeout = useSelector(x => x.tenant.operatorAutoLogoutTimeoutSeconds);
  const handleSubmit = (data) => {
    dispatch(actions.updateAutoLogoutSettings(data.enabled ? data.timeout : null))
      .then(r => {
        if (r) dispatch(push(routePaths.operators));
      });
  };

  return (
    <div className="page" >
      <div style={{ margin: 40 }}>
        <Typography variant="h5" gutterBottom>Konfiguracja</Typography>
        <Paper style={{ padding: 16 }}>
          <AutoLogoutSettingsForm
            initialValues={{ enabled: autoLogoutTimeout != null, timeout: autoLogoutTimeout }}
            onSubmit={handleSubmit}
          />
        </Paper>
      </div>
    </div>
  );
}

export default AutoLogoutSettingsPage;
