import React, {useEffect} from 'react';
import List from '@material-ui/core/List';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector} from 'react-redux';
import EmptyState from "../../common/EmptyState";
import routePaths from "../../../routePaths";
import CustomReportItemList from "./CustomReportItemList";
import { actions } from '../../../actions/reportsActions';
import Typography from "@material-ui/core/Typography";


const CustomReportsListPage = () => {

  const dispatch = useDispatch();
  const isFetching = useSelector(s => s.reports.list.loadingCustomReportList, null);
  const customReportList = useSelector(s => s.reports.list.customReportList, null);


  useEffect(() => {
    dispatch(actions.getCustomReportList())
  },[])

  const onRemoveReportHandler = async (id) => {
    const result = await dispatch(actions.removeCustomReport(id));
    if (result) {
      dispatch(actions.getCustomReportList())
    }
  }

  return (
    <div className="page">
      <div style={{ margin: 40 }}>
        <Typography variant="h5" gutterBottom>Szablony raportów</Typography>
        <div style={{ textAlign: 'right', marginBottom: 16 }}>
          <Button variant="contained" component={Link} color="primary" to={routePaths.reportsCustomCreate}>Dodaj nowy szablon raportu</Button>
        </div>
        <Paper elevation={3} className="list-panel">
          {!isFetching && <List>
            {Array.isArray(customReportList) && customReportList.length ? customReportList.map(item =>
              <CustomReportItemList
                key={`cr-${item.id}`}
                report={item}
                onRemoveHandler={onRemoveReportHandler}
              />
            ) : <EmptyState message={'Brak danych'}/>}
          </List>}
        </Paper>
      </div>
    </div>
  );

}

export default CustomReportsListPage;
