import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import TextField from '@material-ui/core/TextField';
import FormTextField from '../form/redux-form/FormTextField';
import FormDropDown from '../form/redux-form/FormDropDown';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/AddBox';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PriceFee from './PriceFee';
import { FeeTypes } from '../../models/configuration/PricingModel';
import { Tooltip } from '@material-ui/core';

const calculateThresholdStart = (fields, index) => {
  const previousItem = fields.get(index - 1);
  return previousItem ? (Number(previousItem.upTo) || 0) : 0;
};

const clearFee = (change, priceRow) => {
  change(`${priceRow}.fee.rate`, '');
  change(`${priceRow}.fee.interval`, '');
};

const PriceList = ({ fields, change, readonly }) => (
  <Grid container spacing={0}>
    <Grid container style={{ marginLeft: 4, marginBottom: 4 }}>
      <Grid item xs={4} md={2}>
        <Typography variant="caption">Czas przejazdu (min)</Typography>
      </Grid>
      <Grid item xs={3} md={2}>
        <Typography variant="caption" style={{ paddingLeft: 8 }}>Rodzaj opłaty</Typography>
      </Grid>
      <Grid item xs={4} md={8}>
        <Typography variant="caption">Sposób naliczania</Typography>
      </Grid>
    </Grid>
    {fields.map((priceRow, index) =>
      <Grid container key={index} style={{ marginLeft: 4, marginTop: 4 }}>
        <Grid item xs={6} md={2}>
          <TextField
            type="number"
            label="Od"
            margin="dense"
            disabled
            style={{ width: 50, fontWeight: 500 }}
            value={calculateThresholdStart(fields, index)}
          />
          <Field
            name={`${priceRow}.upTo`}
            type="number"
            component={FormTextField}
            readonly={readonly}
            label="Do"
            style={{ width: 50, marginLeft: 16 }}
          />
        </Grid>
        <Grid item xs={6} md={2}>
          <Field
            name={`${priceRow}.fee.type`}
            component={FormDropDown}
            readonly={readonly}
            label="Wybierz"
            values={FeeTypes}
            onChange={() => clearFee(change, priceRow)}
          />
        </Grid>
        <Grid item xs={11} md={7}>
          <PriceFee field={priceRow} fields={fields} index={index} readonly={readonly} />
        </Grid>
        <Grid item xs={1} md={1} style={{ textAlign: 'right' }}>
          {!readonly &&
            <Tooltip title="Usuń">
              <IconButton onClick={() => fields.remove(index)} >
                <DeleteIcon />
              </IconButton>
            </Tooltip>}
        </Grid>
      </Grid>
    )}
    {!readonly &&
      <Grid container>
        <Grid item xs={12} style={{ marginTop: 16 }}>
          <Button size="small" color="secondary" onClick={() => fields.push({})}>
            <AddIcon /> Dodaj próg cenowy
        </Button>
        </Grid>
      </Grid>}
  </Grid>
);

PriceList.propTypes = {
  fields: PropTypes.object,
  meta: PropTypes.object,
  readonly: PropTypes.bool.isRequired,
  change: PropTypes.func.isRequired,
};

export default PriceList;
