export class VendorBalanceModel {
  constructor() {
    this.availableFunds = 0.0;
    this.payouts = [];
  }
}

export class PayoutModel {
  constructor() {
    this.id = null;
    this.amount = 0.0;
    this.bankAccountNumber = '';
    this.receiverName = '';
    this.date = null;
  }
}