import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, FieldArray, reduxForm } from 'redux-form';
import Button from '@material-ui/core/Button';
import PriceList from './PriceList';
import './PricingPublicFormStyles.scss';

let PricingPublicForm = ({ handleSubmit, submitting, languageCode, readonly }) => (
  <form onSubmit={handleSubmit}>
    <Field component="input" type="text" name="languageCode" value={languageCode} style={{ display: 'none' }} />
    <FieldArray name="priceList" readonly={readonly} component={PriceList} />
    <div className="actions">
      {!readonly && <Button variant="contained" color="primary" type="submit" disabled={submitting}>
        {submitting ? 'Zapisywanie...' : `Zapisz (${languageCode.toUpperCase()})`}
      </Button>}
    </div>
  </form>
);

PricingPublicForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  languageCode: PropTypes.string.isRequired,
  submitting: PropTypes.bool,
  readonly: PropTypes.bool.isRequired,
};

PricingPublicForm = reduxForm({
  form: 'pricingPublic',
  enableReinitialize: true
})(PricingPublicForm);

PricingPublicForm = connect(
  (state, ownProps) => ({
    initialValues: { languageCode: ownProps.languageCode, priceList: state.tenant.pricings[ownProps.languageCode] },
  })
)(PricingPublicForm);

export default PricingPublicForm;
