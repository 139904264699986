import { actions as notificationActions } from '../../../actions/notificationActions';
import clientsApi from '../../../api/clientsApi';
import * as logger from '../../../utils/logger';

export const types = {
  LOAD_WALLET_SUMMARY: 'LOAD_WALLET_SUMMARY',
  LOAD_WALLET_SUMMARY_SUCCESS: 'LOAD_WALLET_SUMMARY_SUCCESS',
  LOAD_WALLET_TRANSACTIONS: 'LOAD_WALLET_TRANSACTIONS',
  LOAD_WALLET_TRANSACTIONS_SUCCESS: 'LOAD_WALLET_TRANSACTIONS_SUCCESS',
  LOAD_NEXT_WALLET_TRANSACTIONS: 'LOAD_NEXT_WALLET_TRANSACTIONS',
  LOAD_NEXT_WALLET_TRANSACTIONS_SUCCESS: 'LOAD_NEXT_WALLET_TRANSACTIONS_SUCCESS',
  TOP_UP: 'TOP_UP',
  TOP_UP_SUCCESS: 'TOP_UP_SUCCESS',
  TOP_UP_FAILURE: 'TOP_UP_FAILURE',
  REGISTER_EXTERNAL_TOP_UP: 'REGISTER_EXTERNAL_TOP_UP',
  REGISTER_EXTERNAL_TOP_UP_SUCCESS: 'REGISTER_EXTERNAL_TOP_UP_SUCCESS',
  REGISTER_EXTERNAL_TOP_UP_FAILURE: 'REGISTER_EXTERNAL_TOP_UP_FAILURE',
  WITHDRAW: 'WITHDRAW',
  WITHDRAW_SUCCESS: 'WITHDRAW_SUCCESS',
  WITHDRAW_FAILURE: 'WITHDRAW_FAILURE',
};

export const actions = {
  loadWalletSummary: userId => dispatch => {
    dispatch({ type: types.LOAD_WALLET_SUMMARY });
    return clientsApi.getWalletSummary(userId).then(res => {
      if (res.ok) {
        dispatch({ type: types.LOAD_WALLET_SUMMARY_SUCCESS, result: res.data || {} });
      }
    });
  },

  loadWalletTransactions: (userId, pageNumber, pageSize, sortBy, filterBy) => dispatch => {
    dispatch({ type: types.LOAD_WALLET_TRANSACTIONS, pageNumber, pageSize, sortBy, filterBy });
    return clientsApi.getWalletTransactions(userId, pageNumber, pageSize, sortBy, filterBy).then(res => {
      if (res.ok) {
        dispatch({ type: types.LOAD_WALLET_TRANSACTIONS_SUCCESS, result: res.data || {} });
      }
    });
  },

  topUp: (userId, data) => dispatch => {
    dispatch({ type: types.TOP_UP });
    return clientsApi.topUp(userId, data).then(response => {
      if (response.ok && response.data.success !== false) {
        dispatch({ type: types.TOP_UP_SUCCESS });
        window.location = response.data.paymentUrl;
      } else {
        dispatch({ type: types.TOP_UP_FAILURE });
        dispatch(notificationActions.showError('Wystąpił problem podczas generowania doładowania'));
        return logger.error(response.data);
      }
    });
  },

  registerExternalTopUp: (userId, data) => dispatch => {
    dispatch({ type: types.REGISTER_EXTERNAL_TOP_UP, data });
    return clientsApi.registerExternalTopUp(userId, data).then(response => {
      if (response.ok && response.data.success !== false) {
        dispatch({ type: types.REGISTER_EXTERNAL_TOP_UP_SUCCESS });
        dispatch(notificationActions.showSuccess('Zarejestrowano wpłatę zewnętrzną'));
        return true;
      } else {
        dispatch({ type: types.REGISTER_EXTERNAL_TOP_UP_FAILURE });
        dispatch(notificationActions.showError('Wystąpił problem podczas rejestrowania wpłaty'));
        logger.error(response.data);
        return false;
      }
    });
  },

  withdraw: userId => dispatch => {
    dispatch({ type: types.WITHDRAW });
    return clientsApi.withdraw(userId).then(response => {
      if (response.ok && (!response.data || response.data.success !== false)) {
        dispatch({ type: types.WITHDRAW_SUCCESS });
        location.reload();
      } else {
        dispatch({ type: types.WITHDRAW_FAILURE });
        dispatch(notificationActions.showError('Wystąpił problem podczas realizowania wypłaty'));
        return logger.error(response.data);
      }
    });
  },
};
