import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from '../../actions/pricingPublicActions';
import { Tabs, Tab, Paper, Typography, Tooltip } from '@material-ui/core';
import PricingPublicForm from './PricingPublicForm';
import { SupportedLanguageCodes } from '../../models/termsAndConditions/TermsAndConditionsModel';
import { permissions, hasPermission } from '../../utils/authorization';

class PricingPublicPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: SupportedLanguageCodes.pl.value,
    };

    this.handleLanguageChange = this.handleLanguageChange.bind(this);
  }

  handleLanguageChange(event, language) {
    this.setState({ language });
  }

  render() {
    const { actions, userPermissions } = this.props;
    const readonly = hasPermission(userPermissions, permissions.pricingConfiguration.access)
      && !hasPermission(userPermissions, permissions.pricingConfiguration.modify);
    return (
      <div className="page">
        <div style={{ margin: 40 }}>
          <Typography variant="subtitle1" gutterBottom className="config-section-header">Cennik publiczny</Typography>
          <Paper elevation={3}>
            <Tabs
              value={this.state.language}
              indicatorColor="secondary"
              textColor="secondary"
              onChange={this.handleLanguageChange}
            >
              <Tab value={SupportedLanguageCodes.pl.value} label={<Tooltip title="polski"><span>PL</span></Tooltip>} />
              <Tab value={SupportedLanguageCodes.en.value} label={<Tooltip title="angielski"><span>EN</span></Tooltip>} />
              <Tab value={SupportedLanguageCodes.de.value} label={<Tooltip title="niemiecki"><span>DE</span></Tooltip>} />
              <Tab value={SupportedLanguageCodes.ru.value} label={<Tooltip title="rosyjski"><span>RU</span></Tooltip>} />
              <Tab value={SupportedLanguageCodes.cs.value} label={<Tooltip title="czeski"><span>CS</span></Tooltip>} />
              <Tab value={SupportedLanguageCodes.lt.value} label={<Tooltip title="litewski"><span>LT</span></Tooltip>} />
              <Tab value={SupportedLanguageCodes.uk.value} label={<Tooltip title="ukraiński"><span>UK</span></Tooltip>} />
            </Tabs>
            <div style={{ padding: 16 }}>
              <PricingPublicForm readonly={readonly} languageCode={this.state.language} onSubmit={data => actions.update(data)} />
            </div>
          </Paper>
        </div>
      </div>
    );
  }
}

PricingPublicPage.propTypes = {
  actions: PropTypes.object.isRequired,
  userPermissions: PropTypes.array.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

function mapStateToProps(state) {
  return { userPermissions: state.auth.permissions };
}

export default connect(mapStateToProps, mapDispatchToProps)(PricingPublicPage);
