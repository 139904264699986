import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'moment-duration-format';
import { actions } from '../../../reducers/reports/rideDetails/actions';
import './styles.scss';
import { Button } from '@material-ui/core';
import FinishRideDialog from './FinishRideDialog';

class FinishRideDetails extends Component {
  constructor(props) {
    super(props);
    this.state = { validTelemetry: [] };

    this.state = {
      finishRideDialogVisible: false,
      submitting: false,
    };

    this.handleShowDialog = this.handleShowDialog.bind(this);
    this.handleFinishRide = this.handleFinishRide.bind(this);
    this.handleFinishRideCancel = this.handleFinishRideCancel.bind(this);
  }

  handleShowDialog() {
    this.setState({ finishRideDialogVisible: true });
  }

  handleFinishRide(data) {
    this.setState({ submitting: true });
    this.props.actions.finishRide(this.props.summary.rideId, data, this.props.currentUser)
      .then(() => this.setState({ finishRideDialogVisible: false, submitting: false }));
  }

  handleFinishRideCancel() {
    this.setState({ finishRideDialogVisible: false });
  }

  render() {
    const { summary, suggestedFinishTime, suggestedFinishLocation, zones } = this.props;
    return (
      <React.Fragment>
        <Button size="small" color="primary" variant="contained" onClick={this.handleShowDialog} disabled={this.state.submitting}>Zakończ</Button>
        <FinishRideDialog
          open={this.state.finishRideDialogVisible}
          onSave={this.handleFinishRide}
          onCancel={this.handleFinishRideCancel}
          submitting={this.state.submitting}
          rideSummary={summary}
          suggestedFinishTime={suggestedFinishTime}
          suggestedFinishLocation={suggestedFinishLocation}
          zones={zones}
        />
      </React.Fragment>
    );
  }
}

FinishRideDetails.propTypes = {
  actions: PropTypes.object.isRequired,
  summary: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  suggestedFinishTime: PropTypes.string,
  suggestedFinishLocation: PropTypes.object,
  zones: PropTypes.array,
};


function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(null, mapDispatchToProps)(FinishRideDetails);
