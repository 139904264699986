import React from 'react';
import PropTypes from 'prop-types';
import Lightbox, { Modal, ModalGateway } from 'react-images';

class BikeDefectPhotos extends React.Component {
  constructor() {
    super();

    this.state = { currentImage: 0 };
    this.closeLightbox = this.closeLightbox.bind(this);
    this.openLightbox = this.openLightbox.bind(this);
    this.gotoNext = this.gotoNext.bind(this);
    this.gotoPrevious = this.gotoPrevious.bind(this);
  }

  openLightbox(index) {
    this.setState({
      currentImage: index,
      lightboxIsOpen: true,
    });
  }

  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });
  }

  gotoPrevious() {
    this.setState({
      currentImage: this.state.currentImage - 1,
    });
  }

  gotoNext() {
    this.setState({
      currentImage: this.state.currentImage + 1,
    });
  }

  render() {
    const { photos } = this.props;
    const urls = photos.map(photo => ({ source: photo }));

    return (
      <div>
        {photos.map((photo, index) => (
          <a key={photo} onClick={() => this.openLightbox(index)} style={{ cursor: 'pointer' }}>
            <img
              src={photo}
              title="Powiększ"
              style={{ height: 40, width: 40, padding: 10, objectFit: 'cover' }}
            />
          </a>))}

        <ModalGateway>
          {this.state.lightboxIsOpen ? (
            <Modal onClose={this.closeLightbox}>
              <Lightbox
                views={urls}
                onClickPrev={this.gotoPrevious}
                onClickNext={this.gotoNext}
                currentImage={this.state.currentImage}
                closeButtonTitle="Zamknij (Esc)"
                imageCountSeparator=" z "
                leftArrowTitle="Poprzednie"
                rightArrowTitle="Następne"
                backdropClosesModal={true}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </div>
    );
  }
}

BikeDefectPhotos.propTypes = {
  photos: PropTypes.array.isRequired,
};

export default BikeDefectPhotos;
