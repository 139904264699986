import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Paper, List, ListItem, ListItemText, ListItemSecondaryAction } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { actions } from '../../actions/plansActions';
import routePaths from '../../routePaths';
import numberFormatter from '../../utils/numberFormatter';
import { PlanValidityTypes } from '../../models/plans/PlanModel';
import ConfirmationIconButton from '../common/ConfirmationIconButton';
import { permissions, hasPermission } from '../../utils/authorization';

class PlansPage extends Component {
  constructor(props) {
    super(props);

    this.renderListItem = this.renderListItem.bind(this);
  }

  componentDidMount() {
    this.props.actions.load();
  }

  navigateToDetails(id) {
    this.props.history.push(`${routePaths.planDetails}${id}`);
  }

  renderListItem(plan, index, readonly) {
    return (
      <ListItem
        key={index}
        button
        onClick={() => this.navigateToDetails(plan.id)}>
        <ListItemText
          primary={plan.name}
          secondary={`Okres ważności ${PlanValidityTypes[plan.validity].name} | Koszt ${numberFormatter.format(plan.price)} zł${plan.internalUseOnly ? ' | Plan testowy' : ''}${plan.dedicated ? ' | Plan dedykowany' : ''}`}
        />
        {!readonly &&
          <ListItemSecondaryAction>
            <ConfirmationIconButton
              confirmationTitle="Usuwanie planu"
              confirmationText={`Czy na pewno chcesz usunąć plan ${plan.name}?`}
              onConfirm={() => this.props.actions.disable(plan.id)}
              onCancel={() => { }}
              tooltip="Usuń plan"
              aria-label="Usuń plan"
            >
              <DeleteIcon />
            </ConfirmationIconButton>
          </ListItemSecondaryAction>}
      </ListItem>
    );
  }

  render() {
    const { plans, userPermissions } = this.props;
    const readonly = hasPermission(userPermissions, permissions.pricingConfiguration.access)
      && !hasPermission(userPermissions, permissions.pricingConfiguration.modify);

    return (
      <div className="page">
        <div style={{ margin: 40 }}>
          <div style={{ textAlign: 'right', marginBottom: 16 }}>
            {!readonly && <Button variant="contained" component={Link} color="primary" to={routePaths.createPlan}>Nowy plan</Button>}
          </div>
          <Paper elevation={3} className="list-panel">
            {!plans.length && <p style={{ textAlign: 'center' }}>Nie zdefiniowano jeszcze żadnego planu</p>}
            <List>
              {plans.map((item, index) => this.renderListItem(item, index, readonly))}
            </List>
          </Paper>
        </div>
      </div>
    );
  }
}

PlansPage.propTypes = {
  history: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  plans: PropTypes.array.isRequired,
  userPermissions: PropTypes.array.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

function mapStateToProps(state) {
  return {
    plans: state.plans,
    userPermissions: state.auth.permissions,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PlansPage);
