import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { actions as authActions} from '../../actions/authActions';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import routePaths from "../../routePaths";

class MyProfileMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchor: undefined,
      open: false,
    };

    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.handleMenuRequestClose = this.handleMenuRequestClose.bind(this);
    this.handleSignOut = this.handleSignOut.bind(this);
  }

  handleMenuClick(event) {
    this.setState({
      open: true,
      anchor: event.currentTarget,
    });
  }

  handleMenuRequestClose() {
    this.setState({
      open: false,
    });
  }

  handleSignOut() {
    this.props.authActions.signOut();
  }


  render() {
    return (
      <div>
        <IconButton
          color="default"
          onClick={this.handleMenuClick}
          aria-label="Open profile menu"
          aria-owns={this.state.open ? 'profile-menu' : null}
          aria-haspopup="true"
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="profile-menu"
          anchorEl={this.state.anchor}
          open={this.state.open}
          onClose={this.handleMenuRequestClose}
        >
          <MenuItem
            key="my-profile"
            component={Link}
            to={routePaths.account.me}
          >Mój profil</MenuItem>
          <MenuItem
            key="ok"
            component={Link}
            onClick={this.handleSignOut}
          >Wyloguj</MenuItem>
        </Menu>
      </div>
    );
  }
}

MyProfileMenu.propTypes = {
  authActions: PropTypes.object.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    authActions: bindActionCreators(authActions, dispatch)
  };
}

export default connect(null, mapDispatchToProps)(MyProfileMenu);
