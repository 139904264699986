import React from 'react';
import PropTypes from 'prop-types';
import Badge from '@material-ui/core/Badge';
import ServiceIcon from '@material-ui/icons/Build';
import PauseIcon from '@material-ui/icons/Pause';
import RideIcon from '@material-ui/icons/DirectionsBike';
import AlarmIcon from '@material-ui/icons/NotificationsActive';
import NotUprightIcon from '@material-ui/icons/Redo';
import ReservationIcon from '@material-ui/icons/Timelapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import { BikeModel } from '../../models/BikeModel';

/* eslint react/no-multi-comp: 0 */

const decorateIcon = (bike, iconElement) => {
  const color = bike.hasAlarmEnabled ? 'error' : 'default';
  if (bike.ongoingPause) return (
    <Badge color={color} badgeContent={<PauseIcon style={{ width: 16, height: 16 }} />}>
      {iconElement}
    </Badge>
  );
  if (bike.isRentalRestricted) return (
    <Badge color={color} badgeContent={<ServiceIcon style={{ width: 16, height: 16 }} />}>
      {iconElement}
    </Badge>
  );
  if (bike.isRented) return (
    <Badge color={color} badgeContent={<RideIcon style={{ width: 16, height: 16 }} />}>
      {iconElement}
    </Badge>
  );
  if (bike.isReserved) return (
    <Badge color={color} badgeContent={<ReservationIcon style={{ width: 16, height: 16 }} />}>
      {iconElement}
    </Badge>
  );
  if (bike.hasAlarmEnabled) return (
    <Badge color={color} badgeContent={<AlarmIcon style={{ width: 16, height: 16 }} />}>
      {iconElement}
    </Badge>
  );
  if (bike.isOrientedUpright === false) return (
    <Badge color={color} badgeContent={<NotUprightIcon style={{ width: 16, height: 16 }} />}>
      {iconElement}
    </Badge>
  );
  return iconElement;
};

const BikesSidebarList = ({ bikes, selectedBike, onBikeSelect, ...other }) => (
  <List dense {...other} >
    {bikes.map((bike) => {
      return (
        <ListItem
          key={bike.id}
          selected={selectedBike && selectedBike.id == bike.id}
          size="small"
          button
          onClick={() => onBikeSelect(bike)}
        >
          {decorateIcon(bike, <Avatar src={bike.bikeIcon} />)}
          <ListItemText
            primary={bike.bikeNumber}
            secondary={`${bike.lockBatteryLevel}%`}
            style={{ marginLeft: 16, marginRight: 8 }} />
        </ListItem>
      );
    }
    )}
  </List>
);

BikesSidebarList.propTypes = {
  bikes: PropTypes.arrayOf(PropTypes.instanceOf(BikeModel)).isRequired,
  selectedBike: PropTypes.object,
  onBikeSelect: PropTypes.func.isRequired,
};

export default BikesSidebarList;
