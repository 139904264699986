import apiBase from "./apiBase";

class PlansApi {
  static getAll() {
    return apiBase.create().get("/operations/plans");
  }

  static getDedicated() {
    return apiBase.create().get("/operations/dedicated/plans");
  }

  static getDedicatedForUser(userId) {
    return apiBase.create().get(`/operations/plans/dedicated/${userId}`);
  }

  static getUserDedicatedPlansConfig(userId) {
    return apiBase.create().get(`/operations/plans/${userId}`);
  }

  static setDedicatedPlansConfig(userId, plansIds) {
    return apiBase.create().post(`/operations/plans/dedicated/${userId}`, {planIds: plansIds})
  }

  static create(data) {
    return apiBase.create().post(`/operations/plans`, data);
  }

  static update(planId, data) {
    return apiBase.create().put(`/operations/plans/${planId}`, data);
  }

  static disable(planId) {
    return apiBase.create().post(`/operations/plans/${planId}/disable`);
  }
}

export default PlansApi;
