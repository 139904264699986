import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { DialogContentText } from "@material-ui/core";

const RejectDefectDialog = ({ open, onSave, onCancel, submitting }) => (
  <Dialog open={open}>
    <DialogTitle>Odrzucanie zgłoszenia</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Czy na pewno chcesz odrzucić zgłoszenie?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel} disabled={submitting}>
        Anuluj
      </Button>
      <Button
        onClick={onSave}
        disabled={submitting}
        variant="contained"
        color="primary"
      >
        Odrzuć
      </Button>
    </DialogActions>
  </Dialog>
);

RejectDefectDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  dispatch: PropTypes.func,
  submitting: PropTypes.bool.isRequired,
};

export default RejectDefectDialog;
