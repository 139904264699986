const validEmailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

export const signInFormValidation = values => {
  const errors = {};
  const requiredFields = [
    'email',
    'password',
  ];

  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Pole jest wymagane';
    }
  });

  if (values.email && !validEmailRegex.test(values.email)) {
    errors.email = 'Adres email jest nieprawidłowy';
  }
  if (!values.password) {
    errors.password = 'Hasło jest wymagane';
  }

  return errors;
};

export const accountActivationFormValidation = values => {
  const errors = {};
  const arrayErrors = []
  if (!values.newPassword) {
    arrayErrors.push('Hasło jest wymagane');
  }

  if (values.newPassword && values.newPassword.length < 12) {
    arrayErrors.push('Musi zawierać co najmniej 12 znaków');
  }
  if (values.newPassword && values.newPassword.length > 64) {
    arrayErrors.push('Musi zawierać co maksymalnie 64 znaki');
  }
  if (values.newPassword && !/[a-z]/.test(values.newPassword)) {
    arrayErrors.push('Musi zawierać co najmniej 1 małą literę');
  }
  if (values.newPassword && !/[A-Z]/.test(values.newPassword)) {
    arrayErrors.push('Musi zawierać co najmniej 1 dużą literę');
  }
  if (values.newPassword && !/[0-9]/.test(values.newPassword)) {
    arrayErrors.push('Musi zawierać co najmniej 1 cyfrę');
  }
  // eslint-disable-next-line no-useless-escape
  if (values.newPassword && !/[`!@#$%^&*()_+\-=\[\]{};':|,.<>\/?~]/.test(values.newPassword)) {
    arrayErrors.push('Musi zawierać co najmniej 1 znak specjalny');
  }

  if (values.newPasswordRepeat && values.newPasswordRepeat !== values.newPassword) {
    arrayErrors.push('Wprowadzono dwa różne hasła');
  }

  if (arrayErrors.length === 0) {
    if (!values.newPasswordRepeat) {
      arrayErrors.push('Powtórzenie hasła jest wymagane');
    }
  }
  if (arrayErrors.length !== 0) {
    errors.newPassword = arrayErrors
  }
  return errors;
};

export const passwordRecoveryFormValidation = values => {
  const errors = {};

  if (!values.email || !validEmailRegex.test(values.email)) {
    errors.email = 'Adres email jest nieprawidłowy';
  }

  return errors;
};
