import initialState from './initialState';
import { types } from '../actions/relocationActions';

export default function relocationConfigurationReducer(state = initialState.relocationConfiguration, action) {
  switch (action.type) {
    case types.GET_RELOCATION_CONFIGURATION: {
      return { loading: true, data: [] }
    }
    case types.GET_RELOCATION_CONFIGURATION_SUCCESS: {
      return { loading: false, data: action.data }
    }
    case types.GET_RELOCATION_CONFIGURATION_FAILURE: {
      return { loading: false, data: [] }
    }

    default:
      return state;
  }
}
