import React from 'react';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { Field, reduxForm } from 'redux-form';
import FormTextField from '../../form/redux-form/FormTextField';

export const formValidation = values => {
  const constraints = {
    amount: {
      presence: { message: 'Kwota jest wymagana' },
      numericality: {
        greaterThan: 0,
        message: 'Kwota musi być większa od 0',
      },
    },
  };

  return validate(values, constraints, { fullMessages: false });
};

let ExternalTopUpForm = ({ handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit}>
      <div>
        <Field
          name="amount"
          component={FormTextField}
          label="Kwota"
          type="number"
          parse={val => val ? Number.parseFloat(val) : ''}
          autoFocus
          endAdornmentText="zł"
        />
      </div>
    </form>
  );
};

ExternalTopUpForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
};

ExternalTopUpForm = reduxForm({
  form: 'externalTopUp',
  validate: formValidation,
})(ExternalTopUpForm);

export default ExternalTopUpForm;
