import initialState from './initialState';
import Immutable from 'seamless-immutable';
import { types } from '../actions/cardsActions';

export default function cardsReducer(state = Immutable(initialState.cards), action) {
  switch (action.type) {
    case types.GET_CARDS: {
      return state.merge({ fetching: true });
    }
    case types.GET_CARDS_SUCCESS: {
      const { data } = action;
      return state.merge({ fetching: false, data });
    }
    default:
      return state;
  }
}
