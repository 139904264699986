import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { FormControl, Select, MenuItem } from '@material-ui/core';
import { actions } from '../../../reducers/reports/clientHistory/actions';

const SubscribeCampaignNewsletterStatusPicker = ({ userId, underage }) => {
  const dispatch = useDispatch();

  return (
    <FormControl>
      <Select
        value={underage}
        onChange={event => dispatch(actions.changeSubscribeCampaignNewsletter(userId, event.target.value))}
        inputProps={{
          name: 'subscribeCampaignNewsletterStatusPicker',
          id: 'subscribeCampaignNewsletterStatusPicker-picker',
        }}
      >
        <MenuItem value={true}>TAK</MenuItem>
        <MenuItem value={false}>NIE</MenuItem>
      </Select>
    </FormControl>
  );
};

SubscribeCampaignNewsletterStatusPicker.propTypes = {
  userId: PropTypes.string.isRequired,
  underage: PropTypes.bool,
};

export default SubscribeCampaignNewsletterStatusPicker;
