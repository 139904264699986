import React from 'react';
import PropTypes from 'prop-types';
import { List, ListItem, ListItemText } from '@material-ui/core';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

class FormDraggableField extends React.Component {
  constructor(props) {
    super(props);
    if (props.input.value && props.input.value.length > 0) {
      this.state = {
        items: props.input.value.map(id => ({ id, ...props.values.find(v => v.id === id) })),
      };
    } else {
      this.state = {
        items: props.values,
      };
    }

    this.onDragEnd = this.onDragEnd.bind(this);
  }

  onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    );
    this.props.input.onChange(items.map(i => i.id));
    this.setState({ items });
  }

  render() {
    const { readonly } = this.props;
    const { items } = this.state;
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable" isDropDisabled={readonly}>
          {(provided) => (
            <div ref={provided.innerRef}>
              <List>
                {items.map((value, index) => (
                  <Draggable key={value.id} draggableId={value.id} index={index}>
                    {(provided) => (
                      <div
                        key={value.id}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <ListItem
                          divider
                          style={{ backgroundColor: '#fff' }}
                        >
                          <ListItemText primary={value.title} secondary={value.subtitle} />
                        </ListItem>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </List>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}
FormDraggableField.propTypes = {
  input: PropTypes.object,
  values: PropTypes.array,
  readonly: PropTypes.bool,
};

export default FormDraggableField;
