import { push, replace } from "connected-react-router";
import { actions as initActions } from "./initActions";
import moment from "moment";
import authenticationApi from "../api/authenticationApi";
import permissionsApi from "../api/permissionsApi";
import localStorageProvider from "../utils/localStorageProvider";
import routePaths from "../routePaths";
import { actions as notificationActions } from "./notificationActions";
import * as logger from "../utils/logger";

export const types = {
  SIGN_IN: "SIGN_IN",
  SIGN_IN_SUCCESS: "SIGN_IN_SUCCESS",
  SIGN_IN_FAILURE: "SIGN_IN_FAILURE",
  SIGNED_OUT: "SIGNED_OUT",
  ACCOUNT_ACTIVATION_SUBMIT: "ACCOUNT_ACTIVATION_SUBMIT",
  ACCOUNT_ACTIVATION_SUCCESS: "ACCOUNT_ACTIVATION_SUCCESS",
  ACCOUNT_ACTIVATION_FAILURE: "ACCOUNT_ACTIVATION_FAILURE",
  GET_PERMISSIONS_SUBMIT: "GET_PERMISSIONS_SUBMIT",
  GET_PERMISSIONS_SUCCESS: "GET_PERMISSIONS_SUCCESS",
  GET_PERMISSIONS_FAILURE: "GET_PERMISSIONS_FAILURE",
  CHECK_TENANT: "CHECK_TENANT",
  CHECK_TENANT_SUCCESS: "CHECK_TENANT_SUCCESS",
  CHECK_TENANT_CACHED_SUCCESS: "CHECK_TENANT_CACHED_SUCCESS",
  CURRENT_USER_DATA: "CURRENT_USER_DATA",
  CURRENT_USER_DATA_SUCCESS: "CURRENT_USER_DATA_SUCCESS",
  CURRENT_USER_DATA_FAILURE: "CURRENT_USER_DATA_FAILURE",
};

const authFailureReasons = Object.freeze({
  tokenExpired: "tokenExpired",
});

export const actions = {
  getCurrentUserData: () => async (dispatch) => {
    dispatch({ type: types.CURRENT_USER_DATA });
    const result = await authenticationApi.me();
    if (result.ok) {
      dispatch({ type: types.CURRENT_USER_DATA_SUCCESS, data: result.data });
    } else {
      dispatch({ type: types.CURRENT_USER_DATA_FAILURE });
    }
  },
  checkTenant: () => (dispatch, getState) => {
    dispatch({ type: types.CHECK_TENANT });
    const state = getState();
    if (
      state.auth.tenantValidity.lastChecked == null ||
      moment
        .utc()
        .diff(moment.utc(state.auth.tenantValidity.lastChecked), "minutes") > 60
    ) {
      return authenticationApi.checkTenant().then((response) => {
        if (response.ok) {
          dispatch({
            type: types.CHECK_TENANT_SUCCESS,
            checked: moment.utc().toJSON(),
          });
          return;
        }

        window.location.href = "https://roovee.eu/";
      });
    } else {
      dispatch({ type: types.CHECK_TENANT_CACHED_SUCCESS });
      return Promise.resolve();
    }
  },
  signIn: (signInData) => (dispatch) => {
    dispatch({ type: types.SIGN_IN });
    return authenticationApi
      .signIn(signInData)
      .then((authenticationResult) => {
        if (authenticationResult.ok) {
          if (
            !authenticationResult.data.success &&
            authenticationResult.data.failure
          ) {
            dispatch({ type: types.SIGN_IN_FAILURE });
            const pareTime = parseInt(
              authenticationResult.data.failure.message,
            );
            const date = new Date(pareTime);
            dispatch(
              notificationActions.showError(
                `Konto jest zablokowane do: ${date.getDate()}/${date.getUTCMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`,
              ),
            );
            logger.error(authenticationResult);
            return { result: false };
          }
          const token = authenticationResult.data.token;
          const isOneTimeToken = authenticationResult.data.isOneTimeToken;
          if (!isOneTimeToken) {
            localStorageProvider.setItem("rooveeAuthToken", token);
          }
          dispatch({ type: types.SIGN_IN_SUCCESS, token, isOneTimeToken });
          return { result: true, isOneTimeToken };
        }

        dispatch({ type: types.SIGN_IN_FAILURE });
        dispatch(notificationActions.showError("Logowanie nie powiodło się"));
        logger.error(authenticationResult);
        return { result: false };
      })
      .then((authOk) => {
        if (!authOk.result) return;
        if (authOk.isOneTimeToken) {
          dispatch(replace(routePaths.twoFactorAuth));
          return true;
        }
        return false;
      })
      .then(async (isOneTimeToken) => {
        if (isOneTimeToken || isOneTimeToken === undefined) return;
        dispatch({ type: types.GET_PERMISSIONS_SUBMIT });
        const permissionsResult = await permissionsApi.getPermissions();
        if (permissionsResult.ok && permissionsResult.data.success !== false) {
          const permissions = permissionsResult.data.permissions;
          localStorageProvider.setItem(
            "rooveePermissions",
            JSON.stringify(permissions),
          );
          dispatch({ type: types.GET_PERMISSIONS_SUCCESS, permissions });
          dispatch(initActions.preloadData());
          dispatch(replace(routePaths.home));
          return true;
        }
        dispatch({ type: types.GET_PERMISSIONS_FAILURE });
        dispatch(
          notificationActions.showError("Nie udało się pobrać uprawnień"),
        );
      });
  },
  signOutWithoutRedirect: () => (dispatch) => {
    localStorageProvider.removeItem("rooveeAuthToken");
    localStorageProvider.removeItem("rooveePermissions");
    dispatch({ type: types.SIGNED_OUT });
  },
  signOut: () => async (dispatch) => {
    await authenticationApi.logout();
    localStorageProvider.removeItem("rooveeAuthToken");
    localStorageProvider.removeItem("rooveePermissions");
    dispatch({ type: types.SIGNED_OUT });
    dispatch(replace(routePaths.signIn));
  },
  removeToken: () => async (dispatch) => {
    localStorageProvider.removeItem("rooveeAuthToken");
    localStorageProvider.removeItem("rooveePermissions");
    dispatch({ type: types.SIGNED_OUT });
    dispatch(replace(routePaths.signIn));
  },
  activateAccount: (activateAccountData) => (dispatch) => {
    dispatch({ type: types.ACCOUNT_ACTIVATION_SUBMIT });

    return authenticationApi
      .activateAccount(activateAccountData)
      .then((response) => {
        if (response.ok) {
          dispatch({ type: types.ACCOUNT_ACTIVATION_SUCCESS });
          dispatch(push(routePaths.home));
          return dispatch(
            notificationActions.showSuccess("Konto zostało aktywowane"),
          );
        }

        if (
          response.status === 401 &&
          response.data.failureReason === authFailureReasons.tokenExpired
        ) {
          dispatch({ type: types.ACCOUNT_ACTIVATION_FAILURE });
          return dispatch(
            notificationActions.showError("Link aktywacyjny utracił ważność"),
          );
        }

        dispatch({ type: types.ACCOUNT_ACTIVATION_FAILURE });
        dispatch(
          notificationActions.showError("Nie udało się aktywować konta"),
        );
        return logger.error(response);
      });
  },
  recoverPassword: (recoverPasswordData) => (dispatch) => {
    return authenticationApi
      .recoverPassword(recoverPasswordData)
      .then((response) => {
        if (response.ok) {
          dispatch(push(routePaths.home));
          return dispatch(
            notificationActions.showSuccess(
              "Link do resetu hasła został wysłany",
            ),
          );
        }

        dispatch(notificationActions.showError("Nie udało się odzyskać hasła"));
        return logger.error(response);
      });
  },
  resetPassword: (resetPasswordData) => (dispatch) => {
    return authenticationApi
      .resetPassword(resetPasswordData)
      .then((response) => {
        if (response.ok) {
          dispatch(push(routePaths.home));
          return dispatch(
            notificationActions.showSuccess("Hasło zostało zaktualizowane"),
          );
        }

        if (
          response.status === 401 &&
          response.data.failureReason === authFailureReasons.tokenExpired
        ) {
          return dispatch(
            notificationActions.showError(
              "Link do resetu hasła utracił ważność",
            ),
          );
        }

        dispatch(notificationActions.showError("Nie udało się ustalić hasła"));
        return logger.error(response);
      });
  },
  changePassword: (password) => (dispatch) => {
    return authenticationApi.changePassword(password).then((response) => {
      if (response.ok) {
        localStorageProvider.removeItem("rooveeAuthToken");
        localStorageProvider.removeItem("rooveePermissions");
        dispatch({ type: types.SIGNED_OUT });
        dispatch(replace(routePaths.signIn));
        return dispatch(
          notificationActions.showSuccess(
            "Hasło zostało zaktualizowane. Prosimy zalogować się ponownie",
          ),
        );
      }

      dispatch(notificationActions.showError("Nie udało się zmienić hasła"));
      return logger.error(response);
    });
  },
};
