export const FinishRideReasonType = Object.freeze({
  problemWithFinishRideInApp: {
    value: "problemWithFinishRideInApp",
    name: "Problem z zakończeniem przejazdu w aplikacji",
  },
  lockProblem: {
    value: "lockProblem",
    name: "Problem z blokadą",
  },
  pauseProblem: {
    value: "pauseProblem",
    name: "Problem z pauzą",
  },
  rentalCancellation: {
    value: "rentalCancellation",
    name: "Rezygnacja z wypożyczenia",
  },
  systemFinish: {
    value: "systemFinish",
    name: "Zakończenie systemowe",
  },
  bikeDefect: {
    value: "bikeDefect",
    name: "Usterka roweru",
  },
  systemFailure: {
    value: "systemFailure",
    name: "Awaria systemu",
  },
  other: {
    value: "other",
    name: "Inne",
  },
});
