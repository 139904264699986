import React from 'react';
import PropTypes from 'prop-types';
import ZoneTypeEditForm from './ZoneTypeEditForm';
import routePaths from '../../routePaths';
import { bindActionCreators } from 'redux';
import { actions } from '../../actions/zoneTypesActions';
import { connect } from 'react-redux';

class ZoneTypeEditPage extends React.Component {

  constructor(props) {
    super(props);
    this.handleSubmitForm = this.handleSubmitForm.bind(this);
  }

  componentDidMount() {
    this.props.actions.startZoneTypeCreation();
    if (this.props.match.params.zoneTypeId) {
      this.props.actions.loadZoneType(this.props.match.params.zoneTypeId);
    }
  }

  handleSubmitForm(data) {
    const submitAction = data.id
      ? this.props.actions.updateZoneType
      : this.props.actions.createZoneType;
    return submitAction(data)
      .then(success => {
        if (success) this.props.history.push(routePaths.zoneTypes);
      });
  }

  render() {
    return (
      <div className="page" >
        <div style={{ margin: 40 }}>
          <ZoneTypeEditForm
            onSubmit={this.handleSubmitForm}
            isEdit={this.props.match.params.zoneTypeId != null}
          />
        </div>
      </div>
    );
  }
}

ZoneTypeEditPage.propTypes = {
  actions: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  zoneType: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    zoneType: state.zoneTypes.current,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ZoneTypeEditPage);
