import { DefectStatus } from './DefectStatus';

export class BikeDefectDetailsModel {
  constructor(data) {
    this.id = data.id;
    this.reportedBy = data.reportedBy;
    this.bikeId = data.bikeId;
    this.bikeNumber = data.bikeNumber;
    this.brokenParts = data.brokenParts || [];
    this.description = data.description;
    this.rideId = data.rideId;
    this.reportedAtLocation = data.reportedAtLocation;
    this.bikeLocation = data.bikeLocation;
    this.reportedAt = data.reportedAt;
    this.resolvedAt = data.resolvedAt;
    this.resolvedBy = data.resolvedBy;
    this.resolvedAfter = data.resolvedAfter;
    this.resolutionComments = data.resolutionComments;
    this.status = DefectStatus[data.status];
    this.attachedPhotos = data.attachedPhotos;
    this.partsList = data.partsList;
    this.operatorLocation = data.operatorLocation;
    this.bikeLocationAtResolution = data.bikeLocationAtResolution;
  }
}
