import React, { useEffect } from "react";
import SummaryPanel from "./SummaryPanel";
import { actions } from "../../../reducers/reports/clientHistory/actions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import { hasPermission, permissions } from "../../../utils/authorization";
import routePaths from "../../../routePaths";

const ClientDetailsPage = () => {
  const params = useParams();

  const dispatch = useDispatch();
  const mobileUserState = useSelector((state) => state.reports.clientHistory);
  const stateAuth = useSelector((state) => state.auth);
  const history = useHistory();

  useEffect(() => {
    const allowAccess = hasPermission(
      stateAuth.permissions,
      permissions.globalClients,
    );
    if (!allowAccess) {
      history.replace(routePaths.home);
    }
  }, []);

  useEffect(() => {
    dispatch(actions.loadGlobalClientHistoryReport(params.id));
  }, [params.id]);

  const onConfirmHandler = (phoneNumber) => {
    return dispatch(actions.confirmAssignPhoneNumber(params.id, phoneNumber));
  };

  const onResendVerificationEmailHandler = async () => {
    return await dispatch(actions.resendVerificationEmail(params.id));
  };

  const onDeactivateUserAccountHandler = async () => {
    return await dispatch(actions.deactivateUserAccountHandler(params.id));
  };

  const onChangeUserPhoneNumber = (phoneNumber) => {
    return dispatch(actions.changeUserPhoneNumber(params.id, phoneNumber));
  };

  if (mobileUserState.loading || !mobileUserState.summary)
    return (
      <div
        style={{
          marginTop: 100,
          marginBottom: 20,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <CircularProgress size={70} />
      </div>
    );

  return (
    <Paper
      style={{
        marginTop: 100,
        marginLeft: 20,
        marginRight: 20,
        marginBottom: 20,
      }}
    >
      <SummaryPanel
        summary={mobileUserState.summary}
        operatorPermissions={stateAuth.permissions}
        confirmAssignPhoneNumber={onConfirmHandler}
        onResendVerificationEmail={onResendVerificationEmailHandler}
        onDeactivateUserAccount={onDeactivateUserAccountHandler}
        changeUserPhoneNumber={onChangeUserPhoneNumber}
      />
    </Paper>
  );
};

export default ClientDetailsPage;
