import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import NavigationIcon from '@material-ui/icons/NearMe';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import { makeStyles } from '@material-ui/core';
import routePaths from '../../../routePaths';
import { BikeModel } from '../../../models/BikeModel';
import { BikeAlertTypes } from '../../../models/bikesManagement/BikeAlertTypes';
import { permissions, useHasPermission } from '../../../utils/authorization';

const useStyles = makeStyles({
  cardContainer: {
    position: 'absolute',
    bottom: '0px',
    margin: '16px',
    zIndex: 10,
    minWidth: '260px',
  },
  card: {
    minWidth: 275,
  }
});

const getBikeStatusNode = (bike) => {
  const statusText = BikeModel.getBikeStatusDescription(bike);
  const statusNode = <b>{statusText}</b>;
  return (bike.isRented && bike.lastRideId)
    ? <Link to={`${routePaths.ridesReports}/${bike.lastRideId}`}>{statusNode}</Link>
    : statusNode;
}

const BikeDetailsCard = ({ bike }) => {
  const classes = useStyles();
  const hasAlarmsPermission = useHasPermission(permissions.bikes.alerts);
  return (
    <div className={classes.cardContainer}>
      <Card className={classes.card}>
        <CardContent>
          <div className="flex-row align-items-center justify-space-between">
            <Typography variant="h5" component="h2">
              <Link to={`${routePaths.service.bikeListFiltered}${bike.id}`}>{bike.bikeNumber}</Link>
            </Typography>
            <Button
              size="medium"
              color="secondary"
              target="_blank"
              href={`https://www.google.com/maps/dir/?api=1&destination=${bike.location.latitude},${bike.location.longitude}&travelmode=driving`}>
              Trasa
              <NavigationIcon style={{ marginLeft: 2, width: 16 }} />
            </Button>
          </div>

          <Typography>Status: {getBikeStatusNode(bike)}</Typography>
          {bike.ongoingPause && <Typography>Wstrzymano: <b>{moment(bike.ongoingPauseDate).format('DD.MM.YYYY HH:mm')}</b></Typography>}
          {bike.rentalRestrictionReason ? <Typography>Powód wycofania: <b>{bike.rentalRestrictionReason}</b></Typography> : null}
          <Typography>Bateria: <b>{bike.lockBatteryLevel} %</b></Typography>
          <Typography>Pozycja: <b>{bike.isOrientedUpright ? 'pionowa' : 'przewrócony'}</b></Typography>
          {bike.alerts.length > 0 && <Typography component={'div'}>
            {hasAlarmsPermission ? <Link to={`${routePaths.bikes}/${bike.id}#alerts`}>Alarmy:</Link> : <>Alarmy</>}
            <ul style={{ marginTop: 0, marginBottom: 0 }}>
              {bike.alerts.map((a, i) => <li key={`alert_${i}`}>{BikeAlertTypes[a.type].name}</li>)}
            </ul>
          </Typography>}
          {bike.openDefectsCount > 0 ? <Typography>Usterki: <b>{bike.openDefectsCount}</b><Link to={`${routePaths.bikeDefectsFiltered}${bike.bikeNumber}`}> (przejdź)</Link></Typography> : null}

        </CardContent>
      </Card>
    </div>
  );
};

BikeDetailsCard.propTypes = {
  bike: PropTypes.object.isRequired,
};

export default BikeDetailsCard;
