import apiBase from './apiBase';
import dataUriToBlob from '../utils/dataUriToBlob';

function createParentalConsentFormData(file) {
  const formData = new FormData();
  formData.append('parentalConsentDocument', dataUriToBlob(file));
  return formData;
}

class ClientsApi {
  static uploadParentalConsent(userId, file) {
    const config = { headers: { 'content-type': 'multipart/form-data' } };
    return apiBase.create().post(`/operations/clients/${userId}/parentalConsent`, createParentalConsentFormData(file), config);
  }

  static removeParentalConsent(userId) {
    return apiBase.create().delete(`/operations/clients/${userId}/parentalConsent`);
  }

  static verifyCallCenterCode(userId, code) {
    return apiBase.create().post(`operations/clients/${userId}/verifyCallCenterCode`, { code });
  }

  static startBikeRentalForClient(userId, bikeNumber) {
    return apiBase.create().post(`operations/clients/${userId}/startBikeRental`, { bikeNumber });
  }

  static remindCallCenterCode(userId) {
    return apiBase.create().post(`operations/clients/${userId}/remindCallCenterCode`);
  }

  static getRideStatus(rideId) {
    return apiBase.create().get(`/operations/rides/${rideId}/status`);
  }

  static getWalletSummary(userId) {
    return apiBase.create().get(`/operations/clients/${userId}/wallet`);
  }

  static getWalletTransactions(userId, pageNumber, pageSize, sortBy, filterBy) {
    const payload = {
      paging: {
        pageNumber,
        pageSize
      },
      filterBy: Object.assign({}, ...filterBy.map(x => ({ [x.id]: x.value }))),
      sortBy: Object.assign({}, ...sortBy.map(x => ({ [x.id]: x.value }))),
    };
    return apiBase.create().post(`/operations/clients/${userId}/wallet/transactions`, payload);
  }

  static topUp(userId, data) {
    return apiBase.create().post(`/operations/clients/${userId}/wallet/topup`, data);
  }

  static registerExternalTopUp(userId, data) {
    return apiBase.create().post(`/operations/clients/${userId}/wallet/register-external-topup`, data);
  }

  static withdraw(userId) {
    return apiBase.create().post(`/operations/clients/${userId}/wallet/withdraw`);
  }

  static changeUnderageStatus(userId, status) {
    return apiBase.create().put(`operations/clients/${userId}/underageStatus`, { status });
  }

  static changeSubscribeCampaignNewsletter(clientId, isSubscribe) {
    return apiBase.create().post(`operations/clients/subscribeCampaign`, {
      isSubscribe: isSubscribe,
      clientId: clientId
    });
  }
  static addToDenyList(userId, reason) {
    return apiBase.create().put(`operations/clients/${userId}/addToDenyList`, reason);
  }

  static removeFromDenyList(userId) {
    return apiBase.create().put(`operations/clients/${userId}/removeFromDenyList`);
  }
}

export default ClientsApi;
