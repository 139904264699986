import apiBase from './apiBase';

class PricingPublicApi {
  static getAll() {
    return apiBase.create().get('/operations/pricing/public');
  }

  static update(pricingData) {
    return apiBase.create().post('/operations/pricing/public', pricingData);
  }
}

export default PricingPublicApi;
