import React from 'react';
import PropTypes from 'prop-types';
import { Marker } from '@react-google-maps/api';
import { BikeModel } from '../../../models/BikeModel';

const getIcon = bikeIconUrl => {
  return {
    url: bikeIconUrl,
    /* eslint-disable no-undef */
    scaledSize: new google.maps.Size(32, 32),
  };
};

const getBikeLabel = bike => {
  let label = '';
  if (bike.alerts.length > 0) label = label + '⚠️';
  if (bike.openDefectsCount > 0) label = label + '🚳';
  if (label === '') return null;
  return { text: label, fontSize: '18px' }
};

const BikeMarker = ({ bike, onSelect, ...other }) => {
  return (
    <Marker
      icon={getIcon(bike.bikeIcon)}
      position={bike.location}
      title={`Rower: ${bike.bikeNumber}`}
      {...other}
      onClick={() => onSelect(bike)}
      animation={bike.alerts.length > 0 && window.google.maps.Animation.BOUNCE}
      opacity={bike.internalUseOnly || bike.isRentalRestricted ? 0.5 : 1}
      label={getBikeLabel(bike)}
      labelStyle={{ opacity: 1 }}
      zIndex={10}
    />
  );
};

BikeMarker.propTypes = {
  bike: PropTypes.instanceOf(BikeModel).isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default BikeMarker;
