import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import "./BikePartsStyles.scss";
import { BikePart } from "../../../models/bikeDefects/BikePart";
import basket from "../../../assets/bikeParts/basket.png";
import bell from "../../../assets/bikeParts/bell.png";
import brakes from "../../../assets/bikeParts/brakes.png";
import chain from "../../../assets/bikeParts/chain.png";
import drive from "../../../assets/bikeParts/drive.png";
import handlebar from "../../../assets/bikeParts/handlebar.png";
import lights from "../../../assets/bikeParts/lights.png";
import lock from "../../../assets/bikeParts/lock.png";
import qr from "../../../assets/bikeParts/qr.png";
import seat from "../../../assets/bikeParts/seat.png";
import wheel from "../../../assets/bikeParts/wheel.png";
import phoneHolder from "../../../assets/bikeParts/phoneHolder.png";
import unusable from "../../../assets/bikeParts/unusable.png";
import vandalism from "../../../assets/bikeParts/vandalism.png";
import kickstand from "../../../assets/bikeParts/kickstand.png";
import childSeat from "../../../assets/bikeParts/childSeat.png";
import bikeBattery from "../../../assets/bikeParts/bikeBattery.png";
import bikeScreen from "../../../assets/bikeParts/bikeScreen.png";
import cargoBox from "../../../assets/bikeParts/cargoBox.png";
import fender from "../../../assets/bikeParts/fender.png";
import solar from "../../../assets/bikeParts/solar.png";

const getIcon = (bikePart) => {
  switch (bikePart) {
    case BikePart.basket.value:
      return basket;
    case BikePart.bell.value:
      return bell;
    case BikePart.brakes.value:
      return brakes;
    case BikePart.chain.value:
      return chain;
    case BikePart.drive.value:
      return drive;
    case BikePart.handlebar.value:
      return handlebar;
    case BikePart.lights.value:
      return lights;
    case BikePart.lock.value:
      return lock;
    case BikePart.qr.value:
      return qr;
    case BikePart.seat.value:
      return seat;
    case BikePart.wheel.value:
      return wheel;
    case BikePart.phoneHolder.value:
      return phoneHolder;
    case BikePart.unusable.value:
      return unusable;
    case BikePart.vandalism.value:
      return vandalism;
    case BikePart.kickstand.value:
      return kickstand;
    case BikePart.childSeat.value:
      return childSeat;
    case BikePart.bikeBattery.value:
      return bikeBattery;
    case BikePart.bikeScreen.value:
      return bikeScreen;
    case BikePart.cargoBox.value:
      return cargoBox;
    case BikePart.fender.value:
      return fender;
    case BikePart.solar.value:
      return solar;

    default:
      return null;
  }
};

const BikeParts = ({ bikeParts, style }) => (
  <div className={style ? undefined : "bike-parts-container"}>
    {bikeParts
      .map((b) => BikePart[b])
      .map((b) => (
        <div key={b.value} className="bike-part-box">
          <img src={getIcon(b.value)} className="bike-part-icon" />
          <Typography variant="caption">{b.name}</Typography>
        </div>
      ))}
  </div>
);

BikeParts.propTypes = {
  bikeParts: PropTypes.array.isRequired,
  style: PropTypes.object,
};

export default BikeParts;
