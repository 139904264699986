import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import DedicatedPlansDialog from "./DedicatedPlansDialog";

class DedicatedPlansButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = { dialogVisible: false, submitting: false };
  }

  handleDialogCancel = () => this.setState({ dialogVisible: false });

  handleOpenDialogClick = () => this.setState({ dialogVisible: true });

  renderOpenDialogButton() {
    return (
      <Button
        onClick={this.handleOpenDialogClick}
        variant="outlined"
        size="small">
        Plany dedykowane
      </Button>
    );
  }

  handleSubmit = (data) => {
    let plansList = Object.keys(data)
    if (plansList.length !== 0) {
      plansList = plansList.filter(plan => data[plan] === true);
    }
    this.setState({ dialogVisible: true, submitting: true });
    this.props.setDedicatedPlans(this.props.userId, plansList).finally(() => this.setState({ dialogVisible: false, submitting: false }));
  }

  render() {
    return (
      <div>
        {this.renderOpenDialogButton()}
        <DedicatedPlansDialog
          submitting={this.state.submitting}
          open={this.state.dialogVisible}
          onCancel={this.handleDialogCancel}
          onSave={this.handleSubmit}
          dedicatedPlans={this.props.dedicatedPlans}
        />
      </div>
    );
  }
}

DedicatedPlansButton.propTypes = {
  userId: PropTypes.string.isRequired,
  setDedicatedPlans: PropTypes.func.isRequired,
  dedicatedPlans: PropTypes.array.isRequired,
};

export default DedicatedPlansButton;
