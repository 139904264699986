import initialState from './initialState';
import Immutable from 'seamless-immutable';
import _ from 'lodash';
import { types } from '../actions/bikeTypesActions';
import { BikeTypeModel } from '../models/bikeTypes/BikeTypeModel';

export default function bikeTypesReducer(state = Immutable(initialState.bikeTypes), action) {
  switch (action.type) {
    case types.GET_ALL_BIKETYPES_REQUEST_SUCCESS: {
      const { bikeTypes } = action;
      return Immutable.merge(state, {
        list: _.sortBy(bikeTypes, ['name']).map(bt => Immutable(new BikeTypeModel(
          bt.id, bt.name, bt.globalBikeTypeId, bt.availableBikeIconUri, bt.occupiedBikeIconUri, bt.internalUseOnly)))
      });
    }
    case types.GET_BIKETYPE_REQUEST_SUCCESS: {
      const { bikeType } = action;
      return Immutable.merge(
        state,
        {
          current: Immutable(new BikeTypeModel(
            bikeType.id, bikeType.name, bikeType.globalBikeTypeId, bikeType.availableBikeIconUri, bikeType.occupiedBikeIconUri, bikeType.internalUseOnly)),
        });
    }
    case types.START_BIKETYPE_CREATION: {
      return Immutable.merge(
        state,
        {
          current: Immutable(new BikeTypeModel()),
          list: state.list,
        });
    }
    case types.DELETE_BIKETYPE_REQUEST_SUCCESS: {
      const { bikeTypeId } = action;
      return Immutable.merge(
        state,
        {
          list: state.list.filter(bt => bt.id !== bikeTypeId),
        });
    }
    default:
      return state;
  }
}
