import React from "react"
import AccountSectionDetailsView from "./AccountSectionDetailsView";
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";
import routePaths from "../../routePaths";
import AccountSectionView from "./AccountSectionView";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";

const TwoFAView = ({isTOPT2FAEnable, isLoading}) => {
  return (
    <AccountSectionView>
      <AccountSectionDetailsView
        flex={1}
      >
        <div style={{fontSize: 21, fontWeight: "bold"}}>Weryfikacja dwuskładnikowa</div>
        <div style={{fontSize: 16, marginTop: 10}}> Zwiększ bezpieczeństwo swojego konta, włączając
          uwierzytelnianie dwuskładnikowe (2FA).
        </div>
      </AccountSectionDetailsView>
      <AccountSectionDetailsView
        flex={1}
      >
        <>
          {isLoading && <CircularProgress size={40}/>}
          {!isLoading && <>
            <div style={{fontSize: 16}}>Status:
              <span style={{
                color: isTOPT2FAEnable ? "green" : "red",
                paddingLeft: 5
              }}>{isTOPT2FAEnable ? "Włączone" : "Wyłączona"}</span>
            </div>
            <div style={{marginTop: 10}}>
              <Button variant="contained" component={Link} color="primary" to={routePaths.account.twoFA}>
                {isTOPT2FAEnable ? "Zarządzaj weryfikacją dwuskładnikową" : "Włącz weryfikację dwuskładnikową"}
              </Button>
            </div>
          </>}
        </>
      </AccountSectionDetailsView>
    </AccountSectionView>
  );

};

TwoFAView.propTypes = {
  isTOPT2FAEnable: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired
}

export default TwoFAView;
