import { FeeTypes } from "./PricingModel";

export const pricingFormValidation = (values) => {
  const errors = {
    rentalPreconditions: {
      minimalBalance: {},
    },
    deductions: {
      bikeLeftInZone: {},
    },
    penalties: {
      bikeLeftOutOfPreferredZone: {},
      bikeLeftOutOfOperatorZone: {},
    },
  };

  const isGreaterThan = (value, greaterThan) => value > greaterThan;
  const isGreaterThanOrEqualTo = (value, greaterThanOrEqualTo) =>
    value >= greaterThanOrEqualTo;
  const isLessThan = (value, lessThan) => value < lessThan;
  const maxAmount = Number.MAX_SAFE_INTEGER;

  if (values.freeMinutesPerDay && values.freeMinutesPerPeriod) {
    errors.freeMinutesPerDay =
      "Pole nie może być łączone z polem 'Darmowe minuty w całym okresie rozliczeniowyn'";
    errors.freeMinutesPerPeriod =
      "Pole nie może być łączone z polem 'Darmowy czas (dziennie)'";
  }

  if (values.rentalPreconditions && values.rentalPreconditions.minimalBalance) {
    if (
      values.rentalPreconditions.minimalBalance.enabled &&
      values.rentalPreconditions.minimalBalance.amount == null
    )
      errors.rentalPreconditions.minimalBalance.amount = "Pole jest wymagane";

    if (
      values.rentalPreconditions.minimalBalance.enabled &&
      values.rentalPreconditions.minimalBalance.amount != null &&
      !isGreaterThanOrEqualTo(
        values.rentalPreconditions.minimalBalance.amount,
        0,
      )
    )
      errors.rentalPreconditions.minimalBalance.amount =
        "Wartość musi być większa lub równa 0";

    if (
      values.rentalPreconditions.minimalBalance.enabled &&
      values.rentalPreconditions.minimalBalance.amount != null &&
      !isLessThan(values.rentalPreconditions.minimalBalance.amount, maxAmount)
    )
      errors.rentalPreconditions.minimalBalance.amount = `Wartość musi być mniejsza od ${maxAmount}`;
  }

  if (values.deductions && values.deductions.bikeLeftInZone) {
    if (
      values.deductions.bikeLeftInZone.enabled &&
      !values.deductions.bikeLeftInZone.amount
    )
      errors.deductions.bikeLeftInZone.amount = "Pole jest wymagane";

    if (
      values.deductions.bikeLeftInZone.enabled &&
      values.deductions.bikeLeftInZone.amount &&
      !isGreaterThan(values.deductions.bikeLeftInZone.amount, 0)
    )
      errors.deductions.bikeLeftInZone.amount = "Wartość musi być większa od 0";

    if (
      values.deductions.bikeLeftInZone.enabled &&
      values.deductions.bikeLeftInZone.amount &&
      !isLessThan(values.deductions.bikeLeftInZone.amount, maxAmount)
    )
      errors.deductions.bikeLeftInZone.amount = `Wartość musi być mniejsza od ${maxAmount}`;
  }

  if (values.penalties) {
    if (
      values.penalties.bikeLeftOutOfPreferredZone &&
      values.penalties.bikeLeftOutOfPreferredZone.enabled &&
      !values.penalties.bikeLeftOutOfPreferredZone.amount
    )
      errors.penalties.bikeLeftOutOfPreferredZone.amount = "Pole jest wymagane";

    if (
      values.penalties.bikeLeftOutOfPreferredZone &&
      values.penalties.bikeLeftOutOfPreferredZone.enabled &&
      !isGreaterThan(values.penalties.bikeLeftOutOfPreferredZone.amount, 0)
    )
      errors.penalties.bikeLeftOutOfPreferredZone.amount =
        "Wartość musi być większa od 0";

    if (
      values.penalties.bikeLeftOutOfPreferredZone &&
      values.penalties.bikeLeftOutOfPreferredZone.enabled &&
      !isLessThan(values.penalties.bikeLeftOutOfPreferredZone.amount, maxAmount)
    )
      errors.penalties.bikeLeftOutOfPreferredZone.amount = `Wartość musi być mniejsza od ${maxAmount}`;

    if (
      values.penalties.bikeLeftOutOfOperatorZone &&
      values.penalties.bikeLeftOutOfOperatorZone.enabled &&
      !values.penalties.bikeLeftOutOfOperatorZone.amount
    )
      errors.penalties.bikeLeftOutOfOperatorZone.amount = "Pole jest wymagane";

    if (
      values.penalties.bikeLeftOutOfOperatorZone &&
      values.penalties.bikeLeftOutOfOperatorZone.enabled &&
      !isGreaterThan(values.penalties.bikeLeftOutOfOperatorZone.amount, 0)
    )
      errors.penalties.bikeLeftOutOfOperatorZone.amount =
        "Wartość musi być większa od 0";

    if (
      values.penalties.bikeLeftOutOfOperatorZone &&
      values.penalties.bikeLeftOutOfOperatorZone.enabled &&
      !isLessThan(values.penalties.bikeLeftOutOfOperatorZone.amount, maxAmount)
    )
      errors.penalties.bikeLeftOutOfOperatorZone.amount = `Wartość musi być mniejsza od ${maxAmount}`;
  }

  if (!values.priceList || !values.priceList.length) {
    errors.priceList = { _error: "Musisz dodać co najmniej jeden wiersz" };
  } else {
    const priceListErrors = [];
    values.priceList.forEach((price, index) => {
      const priceErrors = {
        fee: {},
      };

      if ((!price || !price.upTo) && index !== values.priceList.length - 1) {
        priceErrors.upTo = "Wymagane";
      }
      if (price && price.upTo) {
        const previousItem = values.priceList[index - 1];
        const previousUpTo = previousItem ? previousItem.upTo : 0;
        if (!isGreaterThan(Number(price.upTo), Number(previousUpTo) || 0)) {
          priceErrors.upTo = `> ${previousUpTo}`;
        }
      }
      if (!price || !price.fee || !price.fee.type) {
        priceErrors.fee.type = "Wymagane";
      }
      if (
        price &&
        price.fee &&
        price.fee.type === FeeTypes.fixed.value &&
        (!isGreaterThan(price.fee.rate, 0) ||
          !isLessThan(price.fee.rate, maxAmount))
      ) {
        priceErrors.fee.rate = "Pole jest wymagane";
      }
      if (
        price &&
        price.fee &&
        price.fee.type === FeeTypes.perInterval.value &&
        (!isGreaterThan(price.fee.rate, 0) ||
          !isLessThan(price.fee.rate, maxAmount))
      ) {
        priceErrors.fee.rate = "Pole jest wymagane";
      }
      if (
        price &&
        price.fee &&
        price.fee.type === FeeTypes.perInterval.value &&
        (!isGreaterThan(price.fee.interval, 0) ||
          !isLessThan(price.fee.interval, maxAmount))
      ) {
        priceErrors.fee.interval = "Pole jest wymagane";
      }

      priceListErrors[index] = priceErrors;

      return priceErrors;
    });

    if (priceListErrors.length) {
      errors.priceList = priceListErrors;
    }
  }

  return errors;
};
