import React from 'react';
import PropTypes from 'prop-types';
import TextFilter from '../../../common/reportTable/filters/TextFilter';
import './styles.scss';
import SelectFilter from '../../../common/reportTable/filters/SelectFilter';
import { UserStatus } from '../../../../reducers/reports/clients/models';

class FiltersPanel extends React.Component {

  constructor(props) {
    super(props);
    this.handleDelete = this.handleDelete.bind(this);
  }

  getFilterValue(id) {
    return this.props.filteredBy.find(f => f.id === id);
  }

  handleDelete(deleteFunction) {
    this.props.onFilteredChange(deleteFunction(this.props.filteredBy));
  }

  render() {
    return (
      <div className="filters-panel flex-row flex-wrap" >
        <TextFilter filter={this.getFilterValue("userId")} onDelete={this.handleDelete} readonly label="Id" />
        <TextFilter filter={this.getFilterValue("firstName")} onDelete={this.handleDelete} readonly label="Imię" />
        <TextFilter filter={this.getFilterValue("lastName")} onDelete={this.handleDelete} readonly label="Nazwisko" />
        <TextFilter filter={this.getFilterValue("phoneNumber")} onDelete={this.handleDelete} readonly label="Nr telefonu" />
        <TextFilter filter={this.getFilterValue("email")} onDelete={this.handleDelete} readonly label="Email" />
        <SelectFilter filter={this.getFilterValue("userStatus")} onDelete={this.handleDelete} items={UserStatus} readonly label="Status" />
      </div>
    );
  }
}

FiltersPanel.propTypes = {
  onFilteredChange: PropTypes.func,
  filteredBy: PropTypes.array
};

export default FiltersPanel;
