import initialState from '../../initialState';
import Immutable from 'seamless-immutable';
import { types } from './actions';
import { RideRecordModel, RideReportsSummaryModel } from './models';
import { ReportTableModel } from '../../../models/ReportTableModel';

export default function ridesReportReducer(state = Immutable(initialState.reports.rides), action) {
  switch (action.type) {
    case types.LOAD_RIDES_REPORT: {
      return Immutable.merge(state, {
        loading: true,
        pagedData: ReportTableModel.pageRequested(state.pagedData, action.pageNumber, action.pageSize, action.filterBy, action.sortBy),
      });
    }
    case types.LOAD_FRESH_RIDES_REPORT_SUCCESS: {
      const result = action.result;
      return Immutable.merge(state, {
        loading: false,
        isSummaryOldData: true,
        pagedData: ReportTableModel.pageReady(state.pagedData, result.totalDataCount, result.data.map(r => new RideRecordModel(r))),
      });
    }
    case types.LOAD_RIDES_REPORT_SUMMARY: {
      return Immutable.merge(state, {
        loadingSummary: true,
        isSummaryOldData: true,
      });
    }
    case types.LOAD_FRESH_RIDES_REPORT_SUMMARY_SUCCESS: {
      const result = action.result;
      return Immutable.merge(state, {
        loadingSummary: false,
        isSummaryOldData: false,
        summary: new RideReportsSummaryModel(result),
      });
    }
    case types.LOAD_FRESH_RIDES_REPORT_SUMMARY_ERROR: {
      return Immutable.merge(state, {
        loadingSummary: false,
      });
    }
    case types.LOAD_NEXT_RIDES_REPORT: {
      return Immutable.merge(state, {
        pagedData: ReportTableModel.nextPageRequested(state.pagedData, action.pageNumber, action.pageSize),
      });
    }
    case types.LOAD_NEXT_RIDES_REPORT_SUCCESS: {
      const result = action.result;
      return Immutable.merge(state, {
        pagedData: ReportTableModel.pageReady(state.pagedData, result.totalDataCount, result.data.map(r => new RideRecordModel(r))),
      });
    }
    case types.LOAD_NOT_START_RIDES_REPORT: {
      return Immutable.merge(state, {
        loading: true,
        pagedData: ReportTableModel.pageRequested(state.pagedData, action.pageNumber, action.pageSize, action.filterBy, action.sortBy),
      });
    }
    case types.LOAD_NOT_START_FRESH_RIDES_REPORT_SUCCESS: {
      const result = action.result;
      return Immutable.merge(state, {
        loading: false,
        isSummaryOldData: true,
        pagedData: ReportTableModel.pageReady(state.pagedData, result.totalDataCount, result.data.map(r => new RideRecordModel(r))),
      });
    }
    case types.LOAD_NEXT_NOT_START_RIDES_REPORT: {
      return Immutable.merge(state, {
        pagedData: ReportTableModel.nextPageRequested(state.pagedData, action.pageNumber, action.pageSize),
      });
    }
    case types.LOAD_NEXT_NOT_START_RIDES_REPORT_SUCCESS: {
      const result = action.result;
      return Immutable.merge(state, {
        pagedData: ReportTableModel.pageReady(state.pagedData, result.totalDataCount, result.data.map(r => new RideRecordModel(r))),
      });
    }
    default:
      return state;
  }
}
