import React from 'react';
import AccessTimeIcon from '@material-ui/icons/FindInPage';
import IconButton from '@material-ui/core/IconButton';
import TextFilter from '../../common/reportTable/filters/TextFilter';
import DateTimeFilter from '../../common/reportTable/filters/DateTimeFilter';
import SelectFilter from '../../common/reportTable/filters/SelectFilter';
import { DefectStatus } from '../../../models/bikeDefects/DefectStatus';
import { BikePart } from '../../../models/bikeDefects/BikePart';
import moment from 'moment';
import { Link } from 'react-router-dom';
import routePaths from '../../../routePaths';

const getTimeLeftColor = timeLeft => {
  if (timeLeft >= 24) return '#238823';
  if (timeLeft >= 10 && timeLeft < 24) return '#FFBF00';
  if (timeLeft < 10) return '#D2222D';
  return 'default';
}

/* eslint react/display-name: 0 */
/* eslint react/prop-types: 0 */
/* eslint react/no-multi-comp: 0 */
export const getColumns = hasClientsDataPermission => {
  let columns = [
    {
      Header: '',
      id: 'show_details',
      Cell: row => (
        <Link to={`${routePaths.bikeDefectDetails}${row.original.id}`}>
          <IconButton style={{ height: 24, width: 24, margin: "-3px 3px" }}      >
            <AccessTimeIcon />
          </IconButton>
        </Link >),
      width: 50,
      sortable: false,
      Filter: () => null,
    },
    {
      Header: 'Data zgłoszenia',
      id: 'reportedAt',
      accessor: d => moment(d.reportedAt).local().format('DD.MM.YYYY HH:mm'),
      width: 130,
      Filter: ({ filter, onChange }) => <DateTimeFilter onChange={onChange} filter={filter} label="filtruj od..." />,
    },
    {
      Header: 'Czas na naprawę',
      id: 'timeLeft',
      Cell: row => row.original.timeLeft ? <span style={{ color: getTimeLeftColor(row.original.timeLeft) }}>{moment.duration(row.original.timeLeft, 'hours').format('HH:mm')}</span> : null,
      width: 110,
      filterable: false,
    },
    {
      Header: 'Rower',
      id: 'bikeNumber',
      accessor: d => d.bikeNumber,
      width: 90,
      Filter: ({ filter, onChange }) => <TextFilter onChange={onChange} filter={filter} label="filtruj..." />,
    },
    {
      Header: 'Uszkodzenia',
      id: 'brokenParts',
      accessor: d => d.brokenParts.map(b => BikePart[b].name).join(', '),
      width: 180,
      Filter: () => null,
    },
  ];

  if (hasClientsDataPermission) {
    columns = [...columns, {
      Header: 'Imię',
      id: 'reportedBy.firstName',
      accessor: d => d.reportedBy.firstName,
      width: 120,
      Filter: ({ filter, onChange }) => <TextFilter onChange={onChange} filter={filter} label="filtruj..." />,
    },
    {
      Header: 'Nazwisko',
      id: 'reportedBy.lastName',
      accessor: d => d.reportedBy.lastName,
      width: 120,
      Filter: ({ filter, onChange }) => <TextFilter onChange={onChange} filter={filter} label="filtruj..." />,
    },
    {
      Header: 'Nr telefonu',
      id: 'reportedBy.phoneNumber',
      accessor: d => d.reportedBy.phoneNumber,
      width: 110,
      Filter: ({ filter, onChange }) => <TextFilter onChange={onChange} filter={filter} label="filtruj..." />,
    },
    {
      Header: 'Email',
      id: 'reportedBy.email',
      accessor: d => d.reportedBy.email,
      width: 180,
      Filter: ({ filter, onChange }) => <TextFilter onChange={onChange} filter={filter} label="filtruj..." />,
    }];
  }

  columns.push({
    Header: 'Status',
    id: 'status',
    accessor: d => d.status.name,
    width: 100,
    Filter: ({ filter, onChange }) => <SelectFilter onChange={onChange} filter={filter} items={DefectStatus} label="filtruj..." />,
  });

  return columns;
}
