
import apiBase from './apiBase';
import dataUriToBlob from '../utils/dataUriToBlob';

function createBikeTypeFormData(bikeType) {
  const formData = new FormData();
  formData.append('name', bikeType.name);
  if (bikeType.globalBikeTypeId) formData.append('globalBikeTypeId', bikeType.globalBikeTypeId);
  formData.append('internalUseOnly', bikeType.internalUseOnly);
  formData.append('availableBikeIcon', dataUriToBlob(bikeType.availableBikeIconDataUri));
  formData.append('occupiedBikeIcon', dataUriToBlob(bikeType.occupiedBikeIconDataUri));
  return formData;
}

class BikeTypesApi {
  static getAllBikeTypes() {
    return apiBase.create().get('/operations/bikeTypes');
  }

  static getAllGlobalBikeTypes() {
    return apiBase.create().get('/operations/globalBikeTypes');
  }

  static getBikeType(bikeTypeId) {
    return apiBase.create().get(`/operations/bikeTypes/${bikeTypeId}`);
  }

  static createBikeType(bikeType) {
    const config = { headers: { 'content-type': 'multipart/form-data' } };
    return apiBase.create().post(`/operations/bikeTypes`, createBikeTypeFormData(bikeType), config);
  }

  static updateBikeType(bikeType) {
    const config = { headers: { 'content-type': 'multipart/form-data' } };
    return apiBase.create().put(`/operations/bikeTypes/${bikeType.id}`, createBikeTypeFormData(bikeType), config);
  }

  static deleteBikeType(bikeTypeId) {
    return apiBase.create().delete(`/operations/bikeTypes/${bikeTypeId}`);
  }
}

export default BikeTypesApi;
