import { func, object } from 'prop-types';
import React from 'react';
import { Form, Field } from 'react-final-form'
import { autoLogoutSettingsValidation } from '../../../models/operators/TenantSetttingsValidation';
import routePaths from '../../../routePaths';
import FormActions from '../../form/final-form/FormActions';
import FormSwitch from '../../form/final-form/FormSwitch';
import FormTextField from '../../form/final-form/FormTextField';

const AutoLogoutSettingsForm = ({ onSubmit, initialValues }) => (
  <Form
    onSubmit={onSubmit}
    initialValues={initialValues}
    validate={autoLogoutSettingsValidation}
    render={({ handleSubmit, values }) => (
      <form onSubmit={handleSubmit}>
        <div>
          <Field
            name="enabled"
            component={FormSwitch}
            type="checkbox"
            label="Automatycznie wylogowywanie włączone"
            fullWidth
          />
        </div>
        {
          values.enabled &&
          <div>
            <Field
              name="timeout"
              type="number"
              component={FormTextField}
              label="Dozwolony czas bez aktywności"
              fullWidth
              parse={value => parseInt(value, 10)}
              format={value => value == null ? '' : value}
              endAdornmentText="s."
            />
          </div>
        }
        <FormActions cancelRoutePath={routePaths.operators} />
      </form>
    )}
  />
)

AutoLogoutSettingsForm.propTypes = {
  initialValues: object.isRequired,
  onSubmit: func.isRequired,
};

export default AutoLogoutSettingsForm;
