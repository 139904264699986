import React from 'react';
import QRCode from "react-qr-code";
import TOPT2FACodeForm from "./TOPT2FACodeForm";
import useStyles from './TOPT2FAStyle'
import CircularProgress from "@material-ui/core/CircularProgress";
import TOPT2FACodeView from "./TOPT2FACodeView";
import AccountSectionDetailsView from "../AccountSectionDetailsView";
import AccountSectionView from "../AccountSectionView";
import PropTypes from "prop-types";

const TOPT2FAView = ({ loading, success, otpUrl, account, secretKey, onSubmitCode, is2FAEnable }) => {

  const classes = useStyles();


  const renderEnable2FA = () => {
    return(
      <div>
        <div className={classes.text}>
          Zalecamy korzystanie z aplikacji uwierzytelniającej umożliwiającej przywrócenie dostępu w przypadku
          utraty urządzenia.
        </div>
        <div>
          {loading &&
            <div style={{marginTop: 20, marginBottom: 20}}>
              <CircularProgress size={70}/>
            </div>
          }
          {!loading && success &&
            <>
              <div className={classes.codeContainer}>
                <div
                  className={classes.qrCode}
                >
                  <QRCode
                    size={200}
                    value={otpUrl}
                    viewBox={`0 0 256 256`}
                  />
                </div>
                <TOPT2FACodeView
                  account={account}
                  secretKey={secretKey}
                />
              </div>
              <div>
                <TOPT2FACodeForm
                  onSubmit={onSubmitCode}
                />
              </div>
            </>}
        </div>
      </div>
    )
  }

  const renderEnabled2FAInfo = () => {
    return(
      <div className={classes.text} style={{ maxWidth: 500, marginTop: 35 }}>
        Włączyłeś już uwierzytelnianie dwuskładnikowe za pomocą identyfikatorów haseł jednorazowych.
        Aby zarejestrować inne urządzenie, musisz najpierw wyłączyć uwierzytelnianie dwuskładnikowe
      </div>
    )
  }

  return (
    <AccountSectionView dividerBottom>
      <AccountSectionDetailsView
        flex={1}
      >
        <h2>Włączanie weryfikacji dwuskładnikowej</h2>
        <div className={classes.text}>
          Użyj urządzenia uwierzytelniającego z hasłem jednorazowym na urządzeniu mobilnym lub komputerze, aby
          włączyć uwierzytelnianie dwuskładnikowe (2FA).
        </div>
      </AccountSectionDetailsView>
      <AccountSectionDetailsView
        flex={2}
      >
        {!is2FAEnable && renderEnable2FA()}
        {is2FAEnable && renderEnabled2FAInfo()}
      </AccountSectionDetailsView>
    </AccountSectionView>
  );
};
TOPT2FAView.propTypes = {
  onSubmitCode: PropTypes.func.isRequired,
  secretKey: PropTypes.string,
  account: PropTypes.string,
  otpUrl: PropTypes.string,
  is2FAEnable: PropTypes.bool,
  success: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
}
export default TOPT2FAView;
