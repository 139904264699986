import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import routePaths from '../../../routePaths';
import AnonymousContentPanel from '../AnonymousContentPanel';
import {useDispatch, useSelector} from "react-redux";
import TwoFAForm from "./TwoFAForm";
import {actions as authActions} from "../../../actions/authActions";

const TwoFAPage = () => {

  const authState = useSelector(x => x.auth);
  const dispatch = useDispatch();
  const history = useHistory();

  const onSubmit = (value) => {
    dispatch(authActions.signIn({
      codePin: value.codePin,
      oneTimeToken: authState.oneTimeToken
    }))
  }

  useEffect(() => {
    if(authState.oneTimeToken === null && !authState.id){
      history.replace(routePaths.signIn);
    }
  }, [authState])

  return (
    <AnonymousContentPanel>
      <h2>Autoryzacja dwuskładnikowa</h2>
      <TwoFAForm
        onSubmit={onSubmit}
        submitting={false}
      />
    </AnonymousContentPanel>
  );
}


export default TwoFAPage;
