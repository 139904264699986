export class ZoneModel {
  constructor(id, name, type, area, areaType, areaCenter, bikesReturnZoneTypeId, marker) {
    this.id = id || null;
    this.name = name || '';
    this.type = type || '';
    this.area = area;
    this.areaType = areaType;
    this.areaCenter = areaCenter || null;
    this.bikesReturnZoneTypeId = bikesReturnZoneTypeId || null;
    this.marker = marker || null;
    this.areaPolygon = this.areaType === ZoneAreaTypes.polygon ? this.area : null;
    this.areaCircle = this.areaType === ZoneAreaTypes.circle ? this.area : null;
  }
}

export const ZoneTypes = Object.freeze({
  operationsZone: { value: 'operationsZone', name: 'Strefa działania' },
  preferredBikeReturnZone: { value: 'preferredBikeReturnZone', name: 'Strefa preferowanego zwrotu' }
});

export const ZoneAreaTypes = Object.freeze({
  polygon: 'polygon',
  circle: 'circle',
});
