import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from '../../actions/termsAndConditionsActions';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import './TermsAndConditionsPage.scss';
import ArchivedTermsAndConditionsList from './ArchivedTermsAndConditionsList';
import { VersionStatus } from '../../models/termsAndConditions/TermsAndConditionsModel';
import routePaths from '../../routePaths';
import moment from 'moment';
import { permissions, hasPermission } from '../../utils/authorization';

class TermsAndConditionsPage extends Component {
  constructor(props) {
    super(props);

    this.showContent = this.showContent.bind(this);
    this.editContent = this.editContent.bind(this);
    this.editClause = this.editClause.bind(this);
  }

  componentDidMount() {
    this.props.actions.load();
  }

  showContent(version) {
    this.props.history.push(`${routePaths.termsAndConditionsDetails}${version}`);
  }

  editContent() {
    this.props.history.push(routePaths.termsAndConditionsEdit);
  }

  editClause() {
    this.props.history.push(routePaths.termsAndConditionsEditClause);
  }

  render() {
    const hasModifyPermission = hasPermission(this.props.userPermissions, permissions.termsAndConditions.modify);
    return (
      <div className="page padder">
        <Paper elevation={3} className="header-panel">
          {this.props.current ?
            <div>
              <Typography variant="subtitle1">Aktualny regulamin - wersja {this.props.current.version}</Typography>
              <p>Opublikowany {moment(this.props.current.publishedAt).format('DD.MM.YYYY HH:mm')}</p>
            </div>
            : <Typography variant="subtitle1">Nie istnieje jeszcze opublikowana wersja regulaminu</Typography>
          }
          <div>
            {this.props.current && <Button size="medium" color="secondary" onClick={() => this.showContent(this.props.current.version)}>Podgląd</Button>}
            {!this.props.draft
              && hasModifyPermission
              && <Button
                size="medium" color="secondary" onClick={() => this.props.actions.createNewVersion(this.props.id)}>Przygotuj nową wersję</Button>}
          </div>
        </Paper>
        {this.props.draft &&
          <Paper elevation={3} className="header-panel">
            <Typography variant="subtitle1">Wersja robocza kolejnej wersji regulaminu - wersja {this.props.draft.version}</Typography>
            {hasModifyPermission && <Button size="medium" color="secondary" onClick={this.editContent}>Edycja</Button>}
          </Paper>
        }

        {(this.props.draft || this.props.current) &&
          <Paper elevation={3} className="header-panel">
            <div>
              <Typography variant="subtitle1">Klauzula wyrażenia zgody przetwarzania danych osobowych</Typography>
              <p>{this.props.personalDataProcessingClauses.pl || 'Brak'}</p>
            </div>
            {hasModifyPermission && <Button size="medium" color="secondary" onClick={this.editClause}>Edycja</Button>}
          </Paper>
        }

        {this.props.archived.length > 0 &&
          <div>
            <Typography variant="subtitle1" className="section-header" gutterBottom>Wersje archiwalne</Typography>
            <Paper elevation={3} className="list-panel">
              <ArchivedTermsAndConditionsList items={this.props.archived} onClick={version => this.showContent(version)} />
            </Paper>
          </div>
        }
      </div>
    );
  }
}

TermsAndConditionsPage.propTypes = {
  actions: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  id: PropTypes.string,
  personalDataProcessingClauses: PropTypes.object,
  current: PropTypes.object,
  draft: PropTypes.object,
  archived: PropTypes.array,
  userPermissions: PropTypes.array.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

function mapStateToProps(state) {
  return {
    id: state.termsAndConditions.id,
    personalDataProcessingClauses: state.termsAndConditions.personalDataProcessingClauses,
    current: state.termsAndConditions.versions.find(t => t.status === VersionStatus.published.value),
    draft: state.termsAndConditions.versions.find(t => t.status === VersionStatus.draft.value),
    archived: state.termsAndConditions.versions.filter(t => t.status === VersionStatus.archived.value),
    userPermissions: state.auth.permissions,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TermsAndConditionsPage);
