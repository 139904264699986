import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import DeleteIcon from "@material-ui/icons/Delete";
import DeactivateUserAccountDialog from "./DeactivateUserAccountDialog";

const DeactivateUserAccountButton = ({ onDeactivateUserAccount, balance }) => {
  const [showDialog, setShowDialog] = useState(false);
  const onHandleSave = async () => {
    const result = await onDeactivateUserAccount();
    if (result.success) {
      setShowDialog(false);
    }
  };

  const onHandleClose = async () => {
    setShowDialog(false);
  };

  return (
    <>
      <Button
        onClick={() => {
          setShowDialog(true);
        }}
        variant="outlined"
        size="small"
        disabled={balance !== 0}
      >
        <span style={{ marginLeft: 5 }}>Deaktywuj</span>
        <span style={{ marginLeft: 5 }}>
          <DeleteIcon />
        </span>
      </Button>
      {showDialog && (
        <DeactivateUserAccountDialog
          open={showDialog}
          onSave={onHandleSave}
          onCancel={onHandleClose}
        />
      )}
    </>
  );
};

DeactivateUserAccountButton.propTypes = {
  onDeactivateUserAccount: PropTypes.func.isRequired,
  balance: PropTypes.number.isRequired,
};

export default DeactivateUserAccountButton;
