import { create as createApi } from "apisauce";
import config from "../config";
import localStorageProvider from "../utils/localStorageProvider";
import { actions as authActions } from "../actions/authActions";

let syncedStore = { dispatch: () => {} };

export const syncApiWithStore = (store) => {
  syncedStore = store;
};

export default class ApiBase {
  static create() {
    const token = localStorageProvider.getItem("rooveeAuthToken");
    const api = createApi({
      baseURL: config.ROOVEE_API_URL,
      headers: {
        "Cache-Control": "no-cache",
        "Content-Type": "application/json",
        Authorization: token && `Bearer ${token}`,
      },
      timeout: 60000,
    });

    api.addMonitor((response) => {
      if (response.status === 401 || response.status === 403) {
        syncedStore.dispatch(authActions.removeToken());
      }
    });
    return api;
  }
}
