import apiBase from './apiBase';

class RelocationConfigurationApi {
  static getDesiredBikeCounts() {
    return apiBase.create().get('/relocation/configuration/desired-bike-counts');
  }

  static updateDesiredBikeCounts(zoneLevels) {
    return apiBase.create().put('/relocation/configuration/desired-bike-counts', { zoneLevels });
  }
}

export default RelocationConfigurationApi;
