import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'moment-duration-format';
import ReportTable from '../../common/reportTable/ReportTable';
import { actions } from '../../../reducers/reports/clientWallet/actions';
import { columns } from './ClientWalletColumns';
import ClientWalletFiltersPanel from './ClientWalletFiltersPanel';
import numberFormatter from '../../../utils/numberFormatter';
import { Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import ConfirmationButton from '../../common/ConfirmationButton';
import IdentityIcon from '@material-ui/icons/PermIdentity';
import TopUpDialog from './TopUpDialog';
import { hasPermission, permissions } from '../../../utils/authorization';
import ExternalTopUpDialog from './ExternalTopUpDialog';

class ClientWalletDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      topUpDialogVisible: false,
      topUpSubmitting: false,
      externalTopUpDialogVisible: false,
      externalTopUpSubmitting: false,
      withdrawalSubmitting: false,
      clientData: null,
      clientDataDialogVisible: false,
    };

    this.handleFiltersChange = this.handleFiltersChange.bind(this);
    this.handleSortChange = this.handleSortChange.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
    this.handleTopUp = this.handleTopUp.bind(this);
    this.handleTopUpCancel = this.handleTopUpCancel.bind(this);
    this.handleShowTopUpDialog = this.handleShowTopUpDialog.bind(this);
    this.handleExternalTopUp = this.handleExternalTopUp.bind(this);
    this.handleExternalTopUpCancel = this.handleExternalTopUpCancel.bind(this);
    this.handleShowExternalTopUpDialog = this.handleShowExternalTopUpDialog.bind(this);
    this.handleWithdrawal = this.handleWithdrawal.bind(this);
    this.handleOpenClientDataDialog = this.handleOpenClientDataDialog.bind(this);
    this.handleCloseClientDataDialog = this.handleCloseClientDataDialog.bind(this);
  }

  componentDidMount() {
    const transactions = this.props.transactions;
    this.props.actions.loadWalletSummary(this.props.userId);
    this.props.actions.loadWalletTransactions(this.props.userId, 1, transactions.paging.pageSize, transactions.sortedBy, transactions.filteredBy);
  }

  handleSortChange(sortBy) {
    const transactions = this.props.transactions;
    this.props.actions.loadWalletTransactions(this.props.userId, 1, transactions.paging.pageSize, sortBy, transactions.filteredBy);
  }

  handleFiltersChange(filterBy) {
    const transactions = this.props.transactions;
    this.props.actions.loadWalletTransactions(this.props.userId, 1, transactions.paging.pageSize, transactions.sortedBy, filterBy.filter(f => f.value));
  }

  handlePageChange(nextPage) {
    const transactions = this.props.transactions;
    this.props.actions.loadWalletTransactions(this.props.userId, nextPage, transactions.paging.pageSize, transactions.sortedBy, transactions.filteredBy);
  }

  handlePageSizeChange(nextPageSize) {
    const transactions = this.props.transactions;
    this.props.actions.loadWalletTransactions(this.props.userId, 1, nextPageSize, transactions.sortedBy, transactions.filteredBy);
  }

  handleShowTopUpDialog() {
    this.setState({ topUpDialogVisible: true });
  }

  handleTopUp(data) {
    this.setState({ topUpSubmitting: true });
    this.props.actions.topUp(this.props.userId, data);
  }

  handleShowExternalTopUpDialog() {
    this.setState({ externalTopUpDialogVisible: true });
  }

  handleExternalTopUp(data) {
    this.setState({ externalTopUpSubmitting: true });
    this.props.actions.registerExternalTopUp(this.props.userId, data)
      .then(ok => {
        this.setState({ externalTopUpSubmitting: false });
        if (ok) {
          this.setState({ externalTopUpDialogVisible: false });
          const transactions = this.props.transactions;
          this.props.actions.loadWalletSummary(this.props.userId);
          this.props.actions.loadWalletTransactions(this.props.userId, 1, transactions.paging.pageSize, transactions.sortedBy, transactions.filteredBy);
        }
      });
  }

  handleExternalTopUpCancel() {
    this.setState({ externalTopUpDialogVisible: false });
  }

  handleTopUpCancel() {
    this.setState({ topUpDialogVisible: false });
  }

  handleWithdrawal() {
    this.setState({ withdrawalSubmitting: true });
    this.props.actions.withdraw(this.props.userId)
      .finally(() => this.setState({ withdrawalSubmitting: { submitting: false } }));
  }

  handleOpenClientDataDialog(data) {
    this.setState({ clientData: data, clientDataDialogVisible: true });
  }

  handleCloseClientDataDialog() {
    this.setState({ clientData: null, clientDataDialogVisible: false });
  }

  renderClientDataDialog() {
    const { clientData, clientDataDialogVisible } = this.state;
    if (!clientDataDialogVisible) return null;
    return (<Dialog
      onClose={this.handleCloseClientDataDialog}
      aria-labelledby="customized-dialog-title"
      open={clientDataDialogVisible}
    >
      <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
        Dane adresowe
      </DialogTitle>
      <DialogContent dividers>
        <div style={{ display: "flex" }}>
          <Typography variant="subtitle1" gutterBottom>Imię</Typography>
          <Typography style={{ marginLeft: 10 }} variant="h6" gutterBottom> {clientData.firstName}</Typography>
        </div>
        <div style={{ display: "flex" }}>
          <Typography variant="subtitle1" gutterBottom>Nazwisko</Typography>
          <Typography style={{ marginLeft: 10 }} variant="h6" gutterBottom> {clientData.lastName}</Typography>
        </div>
        <div style={{ display: "flex" }}>
          <Typography variant="subtitle1" gutterBottom>Numer konta</Typography>
          <Typography style={{ marginLeft: 10 }} variant="h6" gutterBottom> {clientData.bankAccountNumber}</Typography>
        </div>
        <div style={{ display: "flex" }}>
          <Typography variant="subtitle1" gutterBottom>Ulica</Typography>
          <Typography style={{ marginLeft: 10 }} variant="h6" gutterBottom> {clientData.streetName}</Typography>
        </div>
        <div style={{ display: "flex" }}>
          <Typography variant="subtitle1" gutterBottom>Numer budynku</Typography>
          <Typography style={{ marginLeft: 10 }} variant="h6" gutterBottom> {clientData.streetHouseNumber}</Typography>
        </div>
        <div style={{ display: "flex" }}>
          <Typography variant="subtitle1" gutterBottom>Numer klatki</Typography>
          <Typography style={{ marginLeft: 10 }} variant="h6" gutterBottom> {clientData.streetStaircaseNumber}</Typography>
        </div>
        <div style={{ display: "flex" }}>
          <Typography variant="subtitle1" gutterBottom>Numer lokalu</Typography>
          <Typography style={{ marginLeft: 10 }} variant="h6" gutterBottom> {clientData.streetPremiseNumber}</Typography>
        </div>
        <div style={{ display: "flex" }}>
          <Typography variant="subtitle1" gutterBottom>Kod pocztowy</Typography>
          <Typography style={{ marginLeft: 10 }} variant="h6" gutterBottom> {clientData.postalCode}</Typography>
        </div>
        <div style={{ display: "flex" }}>
          <Typography variant="subtitle1" gutterBottom>Miejscowość</Typography>
          <Typography style={{ marginLeft: 10 }} variant="h6" gutterBottom> {clientData.city}</Typography>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={this.handleCloseClientDataDialog} color="secondary">
          Zamknij
        </Button>
      </DialogActions>
    </Dialog>);
  }

  render() {
    const { summary, transactions, operatorPermissions } = this.props;
    return (
      <div>
        {this.renderClientDataDialog()}
        {summary.loading ? null
          : <React.Fragment>
            <div className="flex-row flex-wrap">
              <div className="panel-right">
                <Typography color="textSecondary">Stan konta</Typography>
                <Typography>{numberFormatter.format(summary.balance || 0)} zł</Typography>
              </div>
              <div className="panel-right">
                <Typography color="textSecondary">Środki dostępne do wypłaty</Typography>
                <Typography>{numberFormatter.format(summary.availableToWithdraw || 0)} zł</Typography>
              </div>
              <div className="panel-right">
                <Button
                  size="small"
                  variant="outlined"
                  color="secondary"
                  disabled={!hasPermission(operatorPermissions, permissions.topupWallets) || this.props.userDeleted}
                  onClick={this.handleShowTopUpDialog}
                >
                  Doładuj konto przez BM
                </Button>
                <TopUpDialog
                  open={this.state.topUpDialogVisible}
                  onSave={this.handleTopUp}
                  onCancel={this.handleTopUpCancel}
                  submitting={this.state.topUpSubmitting}
                />
              </div>
              <div className="panel-right">
                <Button
                  size="small"
                  variant="outlined"
                  color="secondary"
                  disabled={!hasPermission(operatorPermissions, permissions.registerExternalTopUps) || this.props.userDeleted}
                  onClick={this.handleShowExternalTopUpDialog}
                >
                  Dodaj płatność zewnętrzną
                </Button>
                <ExternalTopUpDialog
                  open={this.state.externalTopUpDialogVisible}
                  onSave={this.handleExternalTopUp}
                  onCancel={this.handleExternalTopUpCancel}
                  submitting={this.state.externalTopUpSubmitting}
                />
              </div>
              <div className="panel-right">
                <ConfirmationButton
                  size="small"
                  variant="outlined"
                  confirmationTitle="Zwrot środków"
                  confirmationText="Środki zostaną zwrócone na konta, z których dokonano płatności. Po wykonaniu operacji kwota znika z portfela klienta, a czas realizacji zwrotu uzależniony jest od Bluemedia."
                  disabled={!summary.availableToWithdraw || this.state.withdrawalSubmitting || this.props.userDeleted}
                  onConfirm={this.handleWithdrawal}
                >
                  Zwróć środki
                </ConfirmationButton>
              </div>
              {summary.lastReceivedCustomerData && !this.props.userDeleted &&
                <div className="panel-right">
                  <Button
                    onClick={() => this.handleOpenClientDataDialog(summary.lastReceivedCustomerData)}
                    size="small"
                    variant="outlined">
                    <IdentityIcon style={{ fontSize: 20 }} />
                    Ostatnie dane adresowe
                  </Button>
                </div>
              }
            </div>
          </React.Fragment>}

        <div>
          <ClientWalletFiltersPanel filteredBy={transactions.filteredBy} onFilteredChange={this.handleFiltersChange} />
          <ReportTable
            style={{ height: "calc(100vh - 200px)" }}
            columns={columns(this.handleOpenClientDataDialog, this.props.userDeleted)}
            data={transactions.records}
            filteredBy={transactions.filteredBy}
            sortedBy={transactions.sortedBy}
            paging={transactions.paging}
            onFilteredChange={this.handleFiltersChange}
            onPageChange={this.handlePageChange}
            onPageSizeChange={this.handlePageSizeChange}
            onSortedChange={this.handleSortChange}
            loading={transactions.loading}
          />
        </div>
      </div>
    );
  }
}

ClientWalletDetails.propTypes = {
  actions: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  summary: PropTypes.object,
  transactions: PropTypes.object,
  userId: PropTypes.string.isRequired,
  userDeleted: PropTypes.bool.isRequired,
  operatorPermissions: PropTypes.array.isRequired,
};

ClientWalletDetails.defaultProps = {
  loading: false,
  summary: {},
  transactions: {},
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

function mapStateToProps(state) {
  return {
    summary: state.reports.clientWallet.summary,
    transactions: state.reports.clientWallet.pagedData,
    loading: state.reports.clientWallet.loading,
    operatorPermissions: state.auth.permissions,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientWalletDetails);
