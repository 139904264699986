import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import ResolveDefectForm from './ResolveDefectForm';

const ResolveDefectDialog = ({ open, onSave, onCancel, dispatch, submitting }) => (
  <Dialog open={open}>
    <DialogTitle>Zamykanie zgłoszenia</DialogTitle>
    <DialogContent>
      <ResolveDefectForm onSubmit={onSave} />
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel} disabled={submitting} >
        Anuluj
      </Button>
      <Button onClick={() => dispatch(submit('resolveDefect'))} disabled={submitting} variant='contained' color="primary">
        Zamknij zgłoszenie
      </Button>
    </DialogActions>
  </Dialog>
);

ResolveDefectDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  dispatch: PropTypes.func,
  submitting: PropTypes.bool.isRequired,
};

export default connect()(ResolveDefectDialog);
