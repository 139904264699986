import validate from 'validate.js';

export const finishRideFormValidation = values => {
  const constraints = {
    finishTime: {
      presence: { message: 'Data jest wymagana' },
    },
    comments: {
      presence: { message: 'Notatka jest wymagana' },
    },
  };
  
  return validate(values, constraints, { fullMessages: false });  
};
