import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import AttachFileIcon from '@material-ui/icons/Attachment';
import FileIcon from '@material-ui/icons/InsertDriveFile';
import ClearIcon from '@material-ui/icons/Clear';
import Button from '@material-ui/core/Button';
import { permissions, hasPermission } from '../../../utils/authorization';
import './styles.scss';

const smallIcon = { height: 16 };

class ParentalConsentButton extends React.Component {
  constructor(props) {
    super(props);
    this.handleUpload = this.handleUpload.bind(this);
    this.handleFilePickerOpen = this.handleFilePickerOpen.bind(this);
  }

  handleUpload({ target: { files } }) {
    const reader = new FileReader();
    reader.onload = ({ target: { result } }) => this.props.uploadParentalConsent(result);
    reader.readAsDataURL(files[0]);
  }

  handleFilePickerOpen() {
    this.fileInputRef.click();
  }

  handleRemove() {
    this.props.removeParentalConsent();
  }

  renderMissingFile() {
    if (!hasPermission(this.props.operatorPermissions, permissions.manageParentalConsent)) return null;
    return (
      <div>
        <Button className="parental-consent-button right" size="small" onClick={this.handleFilePickerOpen} >
          <AttachFileIcon color="action" style={smallIcon} />
          Dodaj
        </Button>
      </div>
    );
  }

  renderAttachedFile() {
    const { parentalConsentDocumentUrl, removeParentalConsent, operatorPermissions } = this.props;
    return (
      <div>
        <Button className="parental-consent-button right" size="small" href={parentalConsentDocumentUrl} target="_blank" >
          <FileIcon style={smallIcon} />
          Pokaż
        </Button>
        {hasPermission(operatorPermissions, permissions.manageParentalConsent)
          &&
          <Fragment>
            <span>|</span>
            <Button className="parental-consent-button left" size="small" onClick={removeParentalConsent} >
              Usuń
          <ClearIcon color="error" style={smallIcon} />
            </Button>
          </Fragment>
        }
      </div>
    );
  }

  render() {
    const { parentalConsentDocumentUrl } = this.props;
    return (
      <div>
        <input type="file" accept="image/*, application/pdf" ref={ref => this.fileInputRef = ref} onChange={this.handleUpload} style={{ display: 'none' }} />
        {parentalConsentDocumentUrl ? this.renderAttachedFile() : this.renderMissingFile()}
      </div>
    );
  }
}

ParentalConsentButton.propTypes = {
  parentalConsentDocumentUrl: PropTypes.string,
  uploadParentalConsent: PropTypes.func.isRequired,
  removeParentalConsent: PropTypes.func.isRequired,
  operatorPermissions: PropTypes.array.isRequired,
};

export default ParentalConsentButton;
