import initialState from './initialState';
import { types as zoneTypes } from '../actions/zonesActions';
import { ZoneTypes } from '../models/zones/ZoneModel';
import Immutable from 'seamless-immutable';

export default function configurationReducer(state = Immutable(initialState.configuration), action) {
   switch (action.type) {
    case zoneTypes.LOAD_ZONES_SUCCESS: {
      const { zones } = action;
      if (!zones) return state;

      const operationsZone = zones.find(z => z.type === ZoneTypes.operationsZone.value);
      return operationsZone ? state.merge({ initialLocation: operationsZone.areaCenter }) : state;
    }
    default:
      return state;
  }
}
