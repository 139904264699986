import React from 'react'
import PropTypes from "prop-types";
import { Form, Field } from 'react-final-form'
import FormTextField from '../../form/redux-form/NativeFormTextField';

const TwoFAForm = ({onSubmit}) => {

  const validation = values => {
    const errors = {};

    if (!values.codePin) {
      errors.codePin = 'Pole wymagane';
    }
    return errors;
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={validation}
      render={({handleSubmit, submitting, errors, touched}) => (
        <form onSubmit={handleSubmit}>
          <div>
            <div className="form-group">
              <Field
                error={errors.code && touched.code}
                name="codePin"
                id="codePin"
                component={FormTextField}
                label="Kod PIN"
                helperText={errors.code && touched.code && "Pole wymagane"}
              />
            </div>
            <button type="submit" className="btn btn-default" disabled={submitting}>
              {submitting ? 'Logowanie...' : 'Zaloguj'}
            </button>
          </div>
        </form>)}
    />
  );

}

TwoFAForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default TwoFAForm
