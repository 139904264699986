export const DefectSeverity = {
  none: {
    name: 'Brak',
    value: 'none',
  },
  minor: {
    name: 'Usterka nieistotna',
    value: 'minor',
  },
  major: {
    name: 'Usterka istotna',
    value: 'major',
  },
  vandalism: {
    name: 'Wandalizm',
    value: 'vandalism',
  },
}
