import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import List from '@material-ui/core/List';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import routePaths from '../../routePaths';
import ImportZoneDialog from './ImportZoneDialog';

class ZonesList extends Component {
  constructor() {
    super();
    this.state = { importZoneDialogVisible: false, importZoneDialogSubmitting: false, anchorEl: null };
    this.handleOpenImportZoneDialog = this.handleOpenImportZoneDialog.bind(this);
    this.handleImportZone = this.handleImportZone.bind(this);
    this.handleOpenAddZoneMenu = this.handleOpenAddZoneMenu.bind(this);
    this.handleCloseAddZoneMenu = this.handleCloseAddZoneMenu.bind(this);
  }

  handleImportZone(data) {
    this.setState({ submitting: true });
    this.props
      .onZoneImport(data.name, data.kmlFile.content)
      .finally(() => this.setState({ submitting: false, importZoneDialogVisible: false }));
  }

  handleOpenImportZoneDialog() {
    this.setState({ importZoneDialogVisible: true });
  }

  handleOpenAddZoneMenu(event) {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleCloseAddZoneMenu(action) {
    this.setState({ anchorEl: null });
    if(action) action();
  }

  renderImportZoneDialog() {
    const { operationsZones } = this.props;
    return (
      <Fragment>
        <ImportZoneDialog
          open={this.state.importZoneDialogVisible}
          onSave={this.handleImportZone}
          onCancel={() => this.setState({ importZoneDialogVisible: false })}
          submitting={this.state.importZoneDialogSubmitting}
        />
        {operationsZones.length === 0 && <Button fullWidth size="medium" onClick={this.handleOpenImportZoneDialog}><AttachFileIcon />Dodaj&nbsp;obszar&nbsp;działania&nbsp;(KML)</Button>}
      </Fragment>);
  }

  render() {
    const { anchorEl } = this.state;
    const { operationsZones, bikeReturnZones, onZoneCreate, onZoneCreateCircle, onZoneSelect, ...other } = this.props;
    return (<div {...other}>
      <List subheader={<ListSubheader style={{ textTransform: 'uppercase' }}>Obszar działania</ListSubheader>}>
        {operationsZones.map(zone =>
          <ListItem button dense onClick={() => onZoneSelect(zone)} value={zone.id} key={zone.id} >
            <ListItemText primary={zone.name} />
          </ListItem>
        )}
      </List>
      <List subheader={<ListSubheader style={{ textTransform: 'uppercase' }}>Strefy zwrotu</ListSubheader>}>
        {bikeReturnZones.map(zone =>
          <ListItem button dense onClick={() => onZoneSelect(zone)} value={zone.id} key={zone.id} >
            <ListItemText primary={zone.name} />
          </ListItem>
        )}
      </List>
      <div>
        <Button
          fullWidth size="medium"
          aria-owns={anchorEl ? 'add-zone-menu' : undefined}
          aria-haspopup="true"
          onClick={this.handleOpenAddZoneMenu}
        >
          <AddIcon />
          {operationsZones.length === 0 ? "Dodaj obszar działania" : "Dodaj strefę zwrotu"}
        </Button>
        <Menu
          id="add-zone-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleCloseAddZoneMenu}
        >
          <MenuItem size="medium" onClick={() => this.handleCloseAddZoneMenu(onZoneCreate)}>Wielokąt</MenuItem>
          <MenuItem size="medium" onClick={() => this.handleCloseAddZoneMenu(onZoneCreateCircle)}>Okrąg</MenuItem>
        </Menu>
        {this.renderImportZoneDialog()}
      </div>
      <div>
        <Button fullWidth size="medium" component={Link} to={routePaths.zoneTypes}>Typy stref</Button>
      </div>
    </div>);
  }
}

ZonesList.propTypes = {
  operationsZones: PropTypes.array.isRequired,
  bikeReturnZones: PropTypes.array.isRequired,
  onZoneCreate: PropTypes.func.isRequired,
  onZoneCreateCircle: PropTypes.func.isRequired,
  onZoneImport: PropTypes.func.isRequired,
  onZoneSelect: PropTypes.func.isRequired,
};

export default ZonesList;
