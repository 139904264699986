import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions as authActions } from '../../../actions/authActions';
import AccountActivationForm from './AccountActivationForm';
import AnonymousContentPanel from '../AnonymousContentPanel';
import queryString from 'query-string';

const AccountActivationPage = ({ authActions, submitting, token }) => (
  <AnonymousContentPanel>
    <p>W systemie zostało przygotowane dla Ciebie konto użytkownika, aby dokończyć rejestrację zdefiniuj swoje hasło</p>
    <AccountActivationForm
      onSubmit={data => authActions.activateAccount(Object.assign({}, data, { activationToken: token }))}
      submitting={submitting}
    />
  </AnonymousContentPanel>
);

AccountActivationPage.propTypes = {
  authActions: PropTypes.object.isRequired,
  token: PropTypes.string,
  submitting: PropTypes.bool,
};

function mapDispatchToProps(dispatch) {
  return {
    authActions: bindActionCreators(authActions, dispatch)
  };
}

function mapStateToProps(state, ownProps) {
  return {
    token: queryString.parse(ownProps.location.search).token,
    submitting: state.auth.submitting,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountActivationPage);
