import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import routePaths from '../../routePaths';
import { actions } from '../../actions/termsAndConditionsActions';
import { Paper, Button, Typography, TextField, Tabs, Tab, Tooltip } from '@material-ui/core';
import './TermsAndConditionsPage.scss';
import './TermsAndConditionsEditClausePageStyles.scss';
import { SupportedLanguageCodes } from '../../models/termsAndConditions/TermsAndConditionsModel';

class TermsAndConditionsEditClausePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: props.personalDataProcessingClauses,
      language: SupportedLanguageCodes.pl.value,
    };

    this.handleContentChange = this.handleContentChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleLanguageChange = this.handleLanguageChange.bind(this);
  }

  handleContentChange(value) {
    const newContent = Object.assign({}, this.state.content);
    newContent[this.state.language] = value;

    this.setState({
      content: newContent,
    });
  }

  handleSave() {
    this.props.actions.updateClause(this.props.id, this.state.content[this.state.language], this.state.language);
  }

  handleLanguageChange(event, language) {
    this.setState({ language });
  }

  render() {
    return (
      <div className="page padder">
        <Typography variant="h5" gutterBottom>Klauzula wyrażenia zgody przetwarzania danych osobowych</Typography>
        <Paper elevation={3}>
          <Tabs
            value={this.state.language}
            indicatorColor="secondary"
            textColor="secondary"
            onChange={this.handleLanguageChange}
          >
            <Tab value={SupportedLanguageCodes.pl.value} label={<Tooltip title="polski"><span>PL</span></Tooltip>} />
            <Tab value={SupportedLanguageCodes.en.value} label={<Tooltip title="angielski"><span>EN</span></Tooltip>} />
            <Tab value={SupportedLanguageCodes.de.value} label={<Tooltip title="niemiecki"><span>DE</span></Tooltip>} />
            <Tab value={SupportedLanguageCodes.ru.value} label={<Tooltip title="rosyjski"><span>RU</span></Tooltip>} />
            <Tab value={SupportedLanguageCodes.cs.value} label={<Tooltip title="czeski"><span>CS</span></Tooltip>} />
            <Tab value={SupportedLanguageCodes.lt.value} label={<Tooltip title="litewski"><span>LT</span></Tooltip>} />
            <Tab value={SupportedLanguageCodes.uk.value} label={<Tooltip title="ukraiński"><span>UK</span></Tooltip>} />
          </Tabs>
          <div style={{ padding: 16 }}>
            {this.state.language === SupportedLanguageCodes.pl.value &&
              <TextField
                id="content"
                label="Wprowadź treść"
                rows="8"
                multiline
                fullWidth
                value={this.state.content.pl}
                onChange={event => this.handleContentChange(event.target.value)}
              />}
            {this.state.language === SupportedLanguageCodes.en.value &&
              <TextField
                id="content"
                label="Wprowadź treść"
                rows="8"
                multiline
                fullWidth
                value={this.state.content.en}
                onChange={event => this.handleContentChange(event.target.value)}
              />}
            {this.state.language === SupportedLanguageCodes.de.value &&
              <TextField
                id="content"
                label="Wprowadź treść"
                rows="8"
                multiline
                fullWidth
                value={this.state.content.de}
                onChange={event => this.handleContentChange(event.target.value)}
              />}
            {this.state.language === SupportedLanguageCodes.ru.value &&
              <TextField
                id="content"
                label="Wprowadź treść"
                rows="8"
                multiline
                fullWidth
                value={this.state.content.ru}
                onChange={event => this.handleContentChange(event.target.value)}
              />}
            {this.state.language === SupportedLanguageCodes.cs.value &&
              <TextField
                id="content"
                label="Wprowadź treść"
                rows="8"
                multiline
                fullWidth
                value={this.state.content.cs}
                onChange={event => this.handleContentChange(event.target.value)}
              />}
            {this.state.language === SupportedLanguageCodes.lt.value &&
              <TextField
                id="content"
                label="Wprowadź treść"
                rows="8"
                multiline
                fullWidth
                value={this.state.content.lt}
                onChange={event => this.handleContentChange(event.target.value)}
              />}
            {this.state.language === SupportedLanguageCodes.uk.value &&
              <TextField
                id="content"
                label="Wprowadź treść"
                rows="8"
                multiline
                fullWidth
                value={this.state.content.uk}
                onChange={event => this.handleContentChange(event.target.value)}
              />}
          </div>
        </Paper>
        <div className="actions">
          <Button style={{ marginRight: 8 }} component={Link} to={routePaths.termsAndConditions}>
            Anuluj
          </Button>
          <Button variant="contained" color="primary" onClick={this.handleSave}>Zapisz zmiany</Button>
        </div>
      </div>
    );
  }
}

TermsAndConditionsEditClausePage.propTypes = {
  history: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  personalDataProcessingClauses: PropTypes.object,
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

function mapStateToProps(state) {
  return {
    id: state.termsAndConditions.id,
    personalDataProcessingClauses: state.termsAndConditions.personalDataProcessingClauses,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TermsAndConditionsEditClausePage);
