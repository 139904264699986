export class BikeDetailsModel {
  constructor(id, bikeNumber, bikeSerialNumber, bikeTypeId, bikeIcon, lock, bikeId) {
    const lockData = lock || { shackleStateNotificationsEnabled: true };

    this.id = id;
    this.bikeId = bikeId;
    this.bikeNumber = bikeNumber;
    this.bikeTypeId = bikeTypeId || null;
    this.bikeIcon = bikeIcon;
    this.bikeSerialNumber = bikeSerialNumber;
    this.lockImei = lockData.imei;
    this.lockPhoneNumber = lockData.phoneNumber || null;
    this.lockSimSerialNumber = lockData.simSerialNumber || null;
    this.lockType = lockData.type;
    this.shackleStateNotificationsEnabled = lockData.shackleStateNotificationsEnabled;
  }
}

export const LockDeviceType = Object.freeze({
  mark1: { value: 'mark1', name: 'Mark1' },
  mark2: { value: 'mark2', name: 'Mark2' },
  mark3: { value: 'mark3', name: 'Mark3' },
  omni: { value: 'omni', name: 'Omni' },
  mark4: { value: 'mark4', name: 'Mark4 (ROOVEE IoT)' },
});
