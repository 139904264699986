import apiBase from './apiBase';

class PricingApi {
  static getAll() {
    return apiBase.create().get('/operations/pricings');
  }

  static get(id) {
    return apiBase.create().get(`/operations/pricings/${id}`);
  }

  static update(pricingData) {
    return apiBase.create().post('/operations/pricings', pricingData);
  }

  static delete(id) {
    return apiBase.create().delete(`/operations/pricings/${id}`, id);
  }
}

export default PricingApi;
