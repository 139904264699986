import { ReportTableModel } from '../../../models/ReportTableModel';

export const Status = {
  created: { value: 'created', name: 'Rozpoczęte' },
  pending: { value: 'pending', name: 'Oczekuje' },
  success: { value: 'success', name: 'Potwierdzone' },
  failed: { value: 'failed', name: 'Nieudane' },
};

export const TransactionType = {
  topUp: { value: 'topUp', name: 'Doładowanie' },
  externalTopUp: { value: 'externalTopUp', name: 'Płatność zewnętrzna' },
  payment: { value: 'payment', name: 'Opłata' },
  withdrawal: { value: 'withdrawal', name: 'Wypłata środków', accountingOnlyName: 'Zwrot środków' },
  automaticWithdrawal: { value: 'automaticWithdrawal', name: 'Wypłata środków (Automatyczna)'},
  withdrawalRefundForm: { value: 'withdrawalRefundForm', name: 'Wypłata środków przez formularz', accountingOnlyName: 'Zwrot środków' },
};

export class ClientWalletTransaction {
  constructor(data) {
    this.date = data.date || null;
    this.amount = data.amount || null;
    this.status = data.status || null;
    this.type = data.type || null;
    this.accountingOnly = data.accountingOnly || null;
    this.ourTenant = data.ourTenant || null;
    this.toTenant = data.toTenant || null;
    this.paidForRideId = data.paidForRideId || null;
    this.paidForPlanId = data.paidForPlanId || null;
    this.bluemediaOrderId = data.bluemediaOrderId || null;
    this.customerData = data.customerData || null;
    this.createdByOperator = data.createdByOperator || null;
    this.withdrawnByOperator = data.withdrawnByOperator || null;
  }
}

export class ClientWalletSummary {
  constructor() {
    this.loading = false;
    this.balance = null;
    this.availableToWithdraw = null;
    this.lastReceivedCustomerData = null;
  }
}

export class ClientWalletModel {
  constructor() {
    this.pagedData = new ReportTableModel({ pageNumber: 1, pageSize: 100 }, [], []);
    this.summary = new ClientWalletSummary();
  }
}
