import { func, object } from 'prop-types';
import React from 'react';
import { Form, Field } from 'react-final-form'
import { maximumRentalsPerUserValidation } from '../../../models/operators/TenantSetttingsValidation';
import routePaths from '../../../routePaths';
import FormActions from '../../form/final-form/FormActions';
import FormTextField from '../../form/final-form/FormTextField';

const MaxRentalsPerUserSettingsForm = ({ onSubmit, initialValues }) => (
  <Form
    onSubmit={onSubmit}
    initialValues={initialValues}
    validate={maximumRentalsPerUserValidation}
    render={({ handleSubmit }) => (
      <form onSubmit={handleSubmit}>
        <div>
          <Field
            name="maximumRentalsPerUser"
            type="number"
            component={FormTextField}
            label="Maksymalna liczba rowerów do wypożyczenia przez użytkowników"
            fullWidth
            parse={value => parseInt(value, 10)}
            format={value => value == null ? '' : value}
          />
        </div>
        <div>
          <Field
            name="maximumRentalsPerTester"
            type="number"
            component={FormTextField}
            label="Maksymalna liczba rowerów do wypożyczenia przez testerów"
            fullWidth
            parse={value => parseInt(value, 10)}
            format={value => value == null ? '' : value}
          />
        </div>
        <FormActions cancelRoutePath={routePaths.pricings} />
      </form>
    )}
  />
)

MaxRentalsPerUserSettingsForm.propTypes = {
  initialValues: object.isRequired,
  onSubmit: func.isRequired,
};

export default MaxRentalsPerUserSettingsForm;
