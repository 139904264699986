import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Paper, List, ListItem, ListItemText, CircularProgress, Card, CardHeader, CardActions, Grid, Typography, ListItemAvatar, Avatar, CardMedia } from '@material-ui/core';
import { actions } from '../../actions/cardsActions';
import routePaths from '../../routePaths';

class CardsPage extends Component {
  constructor(props) {
    super(props);

    this.renderListItem = this.renderListItem.bind(this);
    this.handleEnableCardProvider = this.handleEnableCardProvider.bind(this);
  }

  componentDidMount() {
    this.props.actions.load();
  }

  navigateToDetails(id) {
    this.props.history.push(routePaths.cardDetails.replace(':id', id));
  }

  renderListItem(card, index) {
    return (
      <ListItem
        key={index}
        button
        onClick={() => this.navigateToDetails(card.id)}>
        <ListItemAvatar>
          <Avatar src={card.cardProviderImageUrl} />
        </ListItemAvatar>
        <ListItemText
          primary={card.publicName.pl || card.cardProviderName}
          secondary={`${card.cardProviderName} (${card.cardProviderId})`}
        />
      </ListItem>
    );
  }

  handleEnableCardProvider(cardProviderId) {
    this.props.actions.create({ cardProviderId, publicName: {
        pl: cardProviderId,
        en: cardProviderId,
        de: cardProviderId,
        cs: cardProviderId,
        lt: cardProviderId,
        uk: cardProviderId,
        ru: cardProviderId
      },
      maximumRentalsPerUser: 1 });
  }

  render() {
    const { fetching, cards } = this.props;

    return (
      <div className="page">
        <div style={{ margin: 40 }}>
          {fetching ? <div style={{ width: '100%', textAlign: 'center' }}><CircularProgress size={50} /></div> :
            <React.Fragment>
              {cards.configured.length > 0 && <Paper elevation={3} style={{ marginBottom: 16 }}>
                <List>
                  {cards.configured.map(this.renderListItem)}
                </List>
              </Paper>}
              {cards.available.length > 0 && <React.Fragment>
                <Typography variant="subtitle1" gutterBottom>Karty dostępne do konfiguracji</Typography>
                <Grid container>
                  {cards.available.map(card => (
                    <Grid key={card.cardProviderId} item xs={12} md={3}>
                      <Card>
                        <CardHeader title={card.cardProviderId} subheader={card.name} />
                        {card.imageUrl && <CardMedia image={card.imageUrl} style={{ height: 0, paddingTop: '56.25%' }} />}
                        <CardActions>
                          <Button color="secondary" onClick={() => this.handleEnableCardProvider(card.cardProviderId)}>Włącz obsługę</Button>
                        </CardActions>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </React.Fragment>}
            </React.Fragment>
          }
        </div>
      </div>
    );
  }
}

CardsPage.propTypes = {
  history: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  fetching: PropTypes.bool,
  cards: PropTypes.object,
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

function mapStateToProps(state) {
  return {
    fetching: state.cards.fetching,
    cards: state.cards.data,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CardsPage);
