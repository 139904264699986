import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

class ConfirmationDialog extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isOpen: false
    };
    this.handleAction = this.handleAction.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  handleAction() {
    if (this.props.onBeforeShow) this.props.onBeforeShow();
    this.setState({ isOpen: true });
  }

  handleConfirm() {
    this.setState({ isOpen: false });
    this.props.onConfirm();
  }

  handleCancel() {
    this.setState({ isOpen: false });
    if (this.props.onCancel) this.props.onCancel();
  }

  render() {
    return (
      <div style={{ display: 'inline' }}>
        <div style={{ display: 'inline' }} onClick={this.handleAction}>
          {this.props.children}
        </div>

        <Dialog open={this.state.isOpen} onClose={this.handleCancel}>
          <DialogTitle>{this.props.confirmationTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {this.props.confirmationText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCancel} autoFocus>
              {this.props.cancelLabel}
            </Button>
            <Button onClick={this.handleConfirm} color="primary" variant="contained">
              {this.props.confirmLabel}
            </Button>
          </DialogActions>
        </Dialog>
      </div >
    );
  }
}

ConfirmationDialog.propTypes = {
  children: PropTypes.node.isRequired,
  confirmLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  confirmationText: PropTypes.string.isRequired,
  confirmationTitle: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  tooltip: PropTypes.string,
  iconButtonStyle: PropTypes.object,
  onBeforeShow: PropTypes.func,
};

ConfirmationDialog.defaultProps = {
  confirmLabel: 'Ok',
  cancelLabel: 'Anuluj',
};

export default ConfirmationDialog;
