import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import FormTextField from '../../form/redux-form/NativeFormTextField';
import { accountActivationFormValidation } from '../../../models/auth/AuthModelValidation';
import './AccountActivationFormStyles.scss';
import {connect} from "react-redux";
import RenderErrorsFromReduxFormState from "../../../utils/renderErrorsFromReduxFormState";

let AccountActivationForm = ({ handleSubmit, submitting, accountActivationFormState }) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <div>
          <Field
            name="newPassword"
            type="password"
            component={FormTextField}
            label="Utwórz hasło"
            hiddenErrors
          />
        </div>
        <div style={{ marginTop: 20 }}>
          <Field
            id="newPasswordRepeat"
            name="newPasswordRepeat"
            type="password"
            component={FormTextField}
            label="Powtórz hasło"
            fullWidth
            hiddenErrors
          />
        </div>
      </div>
      <RenderErrorsFromReduxFormState stateReduxForm={accountActivationFormState} />
      <button type="submit" className="btn btn-default" disabled={submitting}>
        Aktywuj konto
      </button>
    </form>
  );
};

AccountActivationForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  accountActivationFormState: PropTypes.object.isRequired

};

AccountActivationForm = reduxForm({
  form: 'accountActivation',
  validate: accountActivationFormValidation,
})(AccountActivationForm);

AccountActivationForm = connect(state => {
  return {
    accountActivationFormState: state.form.accountActivation,
  }
})(AccountActivationForm)

export default AccountActivationForm;
