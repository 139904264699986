import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import TextFilter from '../common/reportTable/filters/TextFilter';
import AccessTimeIcon from '@material-ui/icons/FindInPage';
import RemoveIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import routePaths from '../../routePaths';
import { CardStatuses } from '../../models/cards/CardModel';
import ConfirmationIconButton from '../common/ConfirmationIconButton';

/* eslint react/display-name: 0 */
/* eslint react/prop-types: 0 */
/* eslint react/no-multi-comp: 0 */
export const getColumns = onRemove => ([
  {
    Header: '',
    id: 'show_details',
    Cell: row => (
      <Link to={`${routePaths.clientsReport}/${row.original.userId}/history`}>
        <IconButton style={{ height: 24, width: 24, margin: "-3px 3px" }}>
          <AccessTimeIcon />
        </IconButton>
      </Link>),
    width: 50,
    sortable: false,
    Filter: () => null,
  },
  {
    Header: 'Numer karty',
    id: 'cardNumber',
    accessor: 'cardNumber',
    width: 180,
    Filter: ({ filter, onChange }) => <TextFilter onChange={onChange} filter={filter} label="filtruj..." />,
  },
  {
    Header: 'Imię',
    id: 'firstName',
    accessor: 'firstName',
    width: 180,
    Filter: ({ filter, onChange }) => <TextFilter onChange={onChange} filter={filter} label="filtruj..." />,
  },
  {
    Header: 'Nazwisko',
    id: 'lastName',
    accessor: 'lastName',
    width: 180,
    Filter: ({ filter, onChange }) => <TextFilter onChange={onChange} filter={filter} label="filtruj..." />,
  },
  {
    Header: 'Status',
    id: 'status',
    Cell: row => (<div style={{color: CardStatuses[row.original.status].color}}>{CardStatuses[row.original.status].name}</div>),
    Filter: () => null,
    width: 180,
  },
  {
    Header: 'Data podpięcia',
    id: 'addedAt',
    accessor: x => moment(x.addedAt).local().format('DD.MM.YYYY HH:mm:ss'),
    width: 140,
    Filter: () => null,
  },
  {
    Header: 'Data aktywacji',
    id: 'activatedAt',
    accessor: x => x.activatedAt ? moment(x.activatedAt).local().format('DD.MM.YYYY HH:mm:ss') : null,
    width: 140,
    Filter: () => null,
  },
  {
    Header: 'Data deaktywacji',
    id: 'deactivatedAt',
    accessor: x => x.deactivatedAt  ? moment(x.deactivatedAt).local().format('DD.MM.YYYY HH:mm:ss') : null,
    width: 140,
    Filter: () => null,
  },
  {
    Header: '',
    id: 'remove_card',
    Cell: row => (
      <ConfirmationIconButton
        iconButtonStyle={{ height: 24, width: 24, margin: "-3px 3px" }}
        confirmationTitle="Odpinanie karty"
        confirmationText="Czy na pewno chcesz odpiąć kartę od wybranego konta?"
        onConfirm={() => onRemove(row.original.cardId)}
      >
        <RemoveIcon />
      </ConfirmationIconButton>),
    width: 50,
    sortable: false,
    Filter: () => null,
  },
]);
