import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import _ from 'lodash';
import './styles.scss';

class RestartRideDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getEmptyState();
    this.handleVerifyCode = this.handleVerifyCode.bind(this);
    this.handleRestartBikeRental = this.handleRestartBikeRental.bind(this);
    this.handlePendingRide = this.handlePendingRide.bind(this); //?
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSendSmsCode = this.handleSendSmsCode.bind(this);
    this.timer = null;
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  getEmptyState() {
    return {
      callCenterCode: '',
      codeIsValid: null,
      bikeNumber: '',
      submitting: false,
      bikeNotFound: false,
      rideId: '',
      rideRestarted: false,
      rideFailure: false,
      rideFailureMessage: '',
      ridePending: false,
    };
  }

  handleSendSmsCode() {
    this.props.clientActions.remindCallCenterCode(this.props.userId);
  }

  handleVerifyCode() {
    this.setState({ submitting: true, ridePending: false, rideRestarted: false, rideFailure: false, rideFailureMessage: '', rideId: '' });
    this.props.clientActions
      .verifyClientCallCenterCode(this.props.userId, this.state.callCenterCode)
      .then(codeIsValid => this.setState({ codeIsValid }))
      .finally(() => this.setState({ submitting: false }));
  }

  handleRestartBikeRental() {
    this.setState({ submitting: true, ridePending: false, rideRestarted: false, rideFailure: false, rideFailureMessage: '', rideId: '' });
    this.props.onSave(this.props.rideId)
      .then(data => {
        if (!data.failure) {
          this.handlePendingRide(data.rideId);
        } else {
          this.setState({ rideFailure: true, rideFailureMessage: (data.failure && data.failure.message || 'Nie udało się wznowić przejazdu') });
        }
      })
      .finally(() => this.setState({ submitting: false }));
  }

  handlePendingRide(rideId) {
    this.setState({ ridePending: true, rideFailure: false, rideFailureMessage: '', rideRestarted: false });
    this.props.actions.getRideStatus(rideId)
      .then(rideStatus => {
        switch (rideStatus) {
          case 'finished':
            this.timer = setTimeout(() => this.handlePendingRide(rideId), 1500);
            return;
          case 'started':
            return this.setState({ ridePending: false, rideRestarted: true, rideId });
          default:
            return this.setState({ ridePending: false, rideFailure: false });
        }
      });
  }

  handleCancel() {
    if (this.timer) clearTimeout(this.timer);
    this.timer = null;
    this.setState(this.getEmptyState());
    this.props.onCancel();
  }

  renderRideRestartingProgress() {
    const { ridePending, rideRestarted, rideFailure, rideFailureMessage } = this.state;
    return (
      <div className="statusContainer">
        {ridePending &&
          <div className="inProgress">
            <CircularProgress className="progressIndicator" size={32} />
            <span>Wznawianie przejazdu</span>
          </div>}
        {rideRestarted &&
          <div className="rideStarted">
            <CheckIcon className="rideStartedIcon" size={32} />
            <span>Wznowiono przejazd</span>
          </div>}
        {rideFailure &&
          <div className="rideFailure">
            <ErrorIcon className="rideCanceledIcon" size={32} />
            <span>{rideFailureMessage}</span>
          </div>}
      </div>
    );
  }

  renderCallCenterCodeSection() {
    const { submitting, callCenterCode, codeIsValid, ridePending } = this.state;
    return (
      <div>
        <div>
          <TextField
            disabled={submitting}
            label="Telekod"
            helperText="Wprowadź telekod podany przez użytkownika"
            margin="dense"
            onChange={e => this.setState({ callCenterCode: e.target.value })}
          />
          <Button disabled={submitting || ridePending || _.isEmpty(callCenterCode)} onClick={this.handleVerifyCode} className="dialogButton">Weryfikuj kod</Button>
        </div>
        {
          codeIsValid === false
            ? <div className="error">
              <p>Wprowadzony telekod jest niepoprawny.<br /><a onClick={this.handleSendSmsCode}>Wyślij sms z telekodem do użytkownika</a></p>
            </div>
            : null
        }
        {codeIsValid === true ? <p>Telekod poprawny. Wznów przejazd</p> : null}
      </div>);
  }

  render() {
    const { isOpened } = this.props;
    return (
      <Dialog open={isOpened}>
        <DialogTitle>Wznawianie przejazdu</DialogTitle>
        <DialogContent>
          {this.renderCallCenterCodeSection()}
          {this.renderRideRestartingProgress()}
        </DialogContent>
        <DialogActions>
          <Button size="small" color="primary" variant="contained" onClick={this.handleRestartBikeRental} disabled={this.state.submitting || !this.state.codeIsValid}>Wznów</Button>
          <Button onClick={this.handleCancel}>
            Zamknij
          </Button>
        </DialogActions>
      </Dialog >
    );
  }
}

RestartRideDialog.propTypes = {
  actions: PropTypes.object.isRequired,
  clientActions: PropTypes.object.isRequired,
  isOpened: PropTypes.bool.isRequired,
  userId: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  rideId: PropTypes.string.isRequired,
};

export default RestartRideDialog;
