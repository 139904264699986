import bikesApi from '../api/bikesApi';
import moment from "moment/moment";
import {actions as notificationActions} from "./notificationActions";
import * as logger from "../utils/logger";

export const types = {
  GET_ALL_BIKES_REQUEST: 'GET_ALL_BIKES_REQUEST',
  GET_ALL_BIKES_REQUEST_SUCCESS: 'GET_ALL_BIKES_REQUEST_SUCCESS',
  EXPORT_BIKE_LIST_REQUEST: 'EXPORT_BIKE_LIST_REQUEST',
  EXPORT_BIKE_LIST_SUCCESS: 'EXPORT_BIKE_LIST_SUCCESS',
  EXPORT_BIKE_LIST_FAILURE: 'EXPORT_BIKE_LIST_FAILURE',
};

function storeBlobAs(blob, filename) {

  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  setTimeout(() => URL.revokeObjectURL(url), 60000);
  if (typeof link.download === 'string') {
    document.body.appendChild(link);
    link.download = filename;
    link.href = url;
    link.click();
    document.body.removeChild(link);
  } else {
    location.replace(url);
  }
}

export const actions = {
  loadAllBikes: (params) => (dispatch) => {
    dispatch({ type: types.GET_ALL_BIKES_REQUEST });
    return bikesApi.getAllBikes(params).then(res => {
      if (res.ok) {
        dispatch({ type: types.GET_ALL_BIKES_REQUEST_SUCCESS, bikes: res.data });
      }
    });
  },

  exportBikeListReport: () => (dispatch) => {
    dispatch({ type: types.EXPORT_BIKE_LIST_REQUEST });
    return bikesApi
      .getBikesListReport()
      .then(response => {
        if (response.ok && response.data.success !== false) {
          dispatch({ type: types.EXPORT_BIKE_LIST_SUCCESS });
          storeBlobAs(response.data, `Lista_Rowerów_${moment().toISOString()}.xlsx`);
          return true;
        } else {
          dispatch(notificationActions.showError('Eksport nie powiódł się'));
          logger.error(response.data);
          return false;
        }
      });
  },
};
