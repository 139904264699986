
import apiBase from './apiBase';
import dataUriToBlob from '../utils/dataUriToBlob';

function createZoneTypeFormData(zoneType) {
  const formData = new FormData();
  formData.append('name', zoneType.name);
  formData.append('markerIcon', dataUriToBlob(zoneType.markerIconDataUri));
  return formData;
}

class ZoneTypesApi {
  static getAllZoneTypes() {
    return apiBase.create().get('/operations/zones/types');
  }

  static getZoneType(zoneTypeId) {
    return apiBase.create().get(`/operations/zones/types/${zoneTypeId}`);
  }

  static createZoneType(zoneType) {
    const config = { headers: { 'content-type': 'multipart/form-data' } };
    return apiBase.create().post(`/operations/zones/types`, createZoneTypeFormData(zoneType), config);
  }

  static updateZoneType(zoneType) {
    const config = { headers: { 'content-type': 'multipart/form-data' } };
    return apiBase.create().put(`/operations/zones/types/${zoneType.id}`, createZoneTypeFormData(zoneType), config);
  }

  static deleteZoneType(zoneTypeId) {
    return apiBase.create().delete(`/operations/zones/types/${zoneTypeId}`);
  }
}

export default ZoneTypesApi;
