import ClientStatus from "../clientHistory/ClientStatus";
import ClientActivationStatus from "../clientHistory/ClientActivationStatus";
import Typography from "@material-ui/core/Typography";
import {
  CanShowResendButtonByStatus,
  emailStatusParser,
} from "../../../reducers/reports/clients/models";
import moment from "moment/moment";
import PropTypes from "prop-types";
import React from "react";
import { hasPermission, permissions } from "../../../utils/authorization";
import ConfirmAssignPhoneNumberButton from "../clientHistory/ConfirmAssignPhoneNumberButton";
import numberFormatter from "../../../utils/numberFormatter";
import ResendVerificationEmailButton from "../clientHistory/ResendVerificationEmailButton";
import DeactivateUserAccountButton from "../clientHistory/DeactivateUserAccountButton";
import ChangeUserPhoneNumberButton from "../clientHistory/ChangeUserPhoneNumberButton";

const SummaryPanel = ({
  summary,
  confirmAssignPhoneNumber,
  operatorPermissions,
  onResendVerificationEmail,
  onDeactivateUserAccount,
  changeUserPhoneNumber,
}) => (
  <div>
    <div className="flex-row flex-wrap align-items-center">
      <h3 className="panel">{`${summary.firstName} ${summary.lastName}`}</h3>
      <ClientStatus status={summary.userStatus} style={{ marginRight: 10 }} />
      {!summary.isActive && (
        <ClientActivationStatus inactiveReason={summary.inactiveReason} />
      )}
    </div>
    <div className="flex-row flex-wrap">
      <div className="panel-right">
        <Typography color="textSecondary">Telefon</Typography>
        {!summary.toAssignPhoneNumber &&
          hasPermission(
            operatorPermissions,
            permissions.changeUserPhoneNumber,
          ) && (
            <Typography>
              {summary.phoneNumber ? summary.phoneNumber : "brak"}
              <ChangeUserPhoneNumberButton
                changePhoneNumber={changeUserPhoneNumber}
                label={summary.phoneNumber ? summary.phoneNumber : "brak"}
              />
            </Typography>
          )}
        {!hasPermission(
          operatorPermissions,
          permissions.changeUserPhoneNumber,
        ) &&
          summary.phoneNumber && <Typography>{summary.phoneNumber}</Typography>}
        {!hasPermission(
          operatorPermissions,
          permissions.changeUserPhoneNumber,
        ) &&
          !summary.phoneNumber &&
          (!summary.toAssignPhoneNumber ||
            !hasPermission(
              operatorPermissions,
              permissions.confirmAssignPhoneNumber,
            )) && <Typography>Brak</Typography>}
        {!summary.phoneNumber &&
          summary.toAssignPhoneNumber &&
          hasPermission(
            operatorPermissions,
            permissions.confirmAssignPhoneNumber,
          ) && (
            <Typography>
              <ConfirmAssignPhoneNumberButton
                phoneNumber={summary.toAssignPhoneNumber}
                confirmAssignPhoneNumber={confirmAssignPhoneNumber}
              />
            </Typography>
          )}
      </div>
      <div className="panel-right">
        <Typography color="textSecondary">Email</Typography>
        <Typography>{summary.email}</Typography>
      </div>
      <div className="panel-right">
        <Typography color="textSecondary">
          Status potwierdzenia emaila:{" "}
        </Typography>
        <Typography>
          {(!hasPermission(
            operatorPermissions,
            permissions.resendVerificationEmail,
          ) ||
            !CanShowResendButtonByStatus(summary.emailStatus)) && (
            <span>{emailStatusParser(summary.emailStatus)}</span>
          )}
          {hasPermission(
            operatorPermissions,
            permissions.resendVerificationEmail,
          ) &&
            CanShowResendButtonByStatus(summary.emailStatus) && (
              <ResendVerificationEmailButton
                status={emailStatusParser(summary.emailStatus)}
                email={summary.email}
                onResendVerificationEmail={onResendVerificationEmail}
              />
            )}
        </Typography>
      </div>
      <div className="panel-right">
        <Typography color="textSecondary">Zakończenie rejestracji</Typography>
        <Typography>
          {summary.finishedRegisterAt
            ? moment(summary.finishedRegisterAt)
                .local()
                .format("DD.MM.YYYY HH:mm")
            : "Brak danych"}
        </Typography>
      </div>
      <div className="panel-right">
        <Typography color="textSecondary">Środki</Typography>
        <Typography>
          {numberFormatter.format(summary.balance || 0)} zł
        </Typography>
      </div>
      <div className="panel-right">
        <Typography color="textSecondary">
          Środki dostępne do wypłacenia
        </Typography>
        <Typography>
          {numberFormatter.format(summary.availableToWithdraw || 0)} zł
        </Typography>
      </div>
      <div className="panel-right">
        <Typography color="textSecondary">Ostatnie logowanie</Typography>
        <Typography>
          {summary.lastLogin
            ? moment(summary.lastLogin).local().format("DD.MM.YYYY HH:mm")
            : "Brak danych"}
        </Typography>
      </div>
      {hasPermission(operatorPermissions, permissions.deactivateUserAccount) &&
        summary.userStatus !== "deleted" && (
          <div className="panel-right">
            <Typography color="textSecondary">Deaktywacja konta: </Typography>
            <Typography>
              <DeactivateUserAccountButton
                onDeactivateUserAccount={onDeactivateUserAccount}
                balance={summary.balance}
              />
            </Typography>
          </div>
        )}
    </div>
    <div className="flex-row flex-wrap">
      <div className="panel-right">
        <Typography color="textSecondary">
          Operatorzy, u których użytkownik zaakceptował regulamin:
        </Typography>
        {summary.tenants && (
          <>
            {summary.tenants.length === 0 && <Typography>Brak</Typography>}
            {summary.tenants.length !== 0 &&
              summary.tenants.map((x) => (
                <Typography
                  variant="body2"
                  key={x.tenant}
                  gutterBottom
                  color={x.accessDenied ? "error" : "textPrimary"}
                >
                  <a
                    href={`https://${x.tenant}.roovee.eu/reports/clients/${summary.userId}/history`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {x.tenant.toUpperCase()} (szczegóły)
                  </a>
                  {x.accessDenied ? "Konto na czarnej liście" : null}
                </Typography>
              ))}
          </>
        )}
      </div>
    </div>
  </div>
);

SummaryPanel.propTypes = {
  summary: PropTypes.object,
  confirmAssignPhoneNumber: PropTypes.func.isRequired,
  operatorPermissions: PropTypes.array.isRequired,
  onDeactivateUserAccount: PropTypes.func.isRequired,
  onResendVerificationEmail: PropTypes.func.isRequired,
  changeUserPhoneNumber: PropTypes.func.isRequired,
};

export default SummaryPanel;
