import React from 'react';
import PropTypes from 'prop-types';
import FormCheckBox from "../../form/final-form/FormCheckBox";
import { Form, Field } from 'react-final-form'
import FormActions from "../../form/final-form/FormActions";

const DedicatedPlansForm = ({ onSubmit, dedicatedPlans }) => {
  return (
    <Form
  onSubmit={onSubmit}
  render={({ handleSubmit }) => (
    <>
      <form onSubmit={handleSubmit}>
        {dedicatedPlans.map(item => {
            return <div key={item.id}>
              <Field
                name={item.id}
                type="checkbox"
                component={FormCheckBox}
                label={item.name}
                initialValue={item.available}
              />
            </div>
          }
        )}
        <FormActions/>
      </form>
      </>
  )}
    />
  )
};

DedicatedPlansForm.propTypes = {
  dedicatedPlans: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default DedicatedPlansForm;
