import React from 'react';
import PropTypes from 'prop-types';
import ContentEditor from '../../contentEditor/ContentEditor';
import { FormControl, FormHelperText } from '@material-ui/core';

const FormRichTextField = ({
  input,
  readonly,
  fullWidth,
  meta: { touched, error },
}) => (
  <FormControl margin="dense" variant="outlined" fullWidth={fullWidth}>
    <ContentEditor
      readonly={readonly}
      value={input.value}
      onChange={input.onChange}
      onBlur={input.onBlur}
    />
    {error && touched && <FormHelperText error>{error}</FormHelperText>}
  </FormControl>
);

FormRichTextField.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  meta: PropTypes.object,
  fullWidth: PropTypes.bool,
  readonly: PropTypes.bool,
  endAdornmentText: PropTypes.string,
};

export default FormRichTextField;
