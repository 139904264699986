import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import moment from 'moment';
import BikeParts from './BikeParts';
import BikeDefectPhotos from './BikeDefectPhotos';
import routePaths from '../../../routePaths';

const SummaryPanel = ({ summary, showClientData }) => (
  <div>
    <Typography variant="subtitle1" gutterBottom>
      <Link to={`${routePaths.bikesFiltered}${summary.bikeId}`}>Rower <strong>{summary.bikeNumber}</strong></Link>

    </Typography>
    <Grid
      container
      spacing={3}
      alignItems="stretch"
      direction="row"
      justify="flex-start"
    >
      <Grid item xs={6} md={2}>
        <Typography color="textSecondary">Data zgłoszenia</Typography>
        <Typography>{moment(summary.reportedAt).local().format('DD.MM.YYYY HH:mm')}</Typography>
      </Grid>
      {summary.attachedPhotos && <Grid item xs={6} md={2}>
        <Typography color="textSecondary">Zdjęcie uszkodzenia</Typography>
        <BikeDefectPhotos photos={summary.attachedPhotos} />
      </Grid>}
      <Grid item>
        <Typography color="textSecondary">Uszkodzenia</Typography>
        <BikeParts bikeParts={summary.brokenParts} />
      </Grid>
      <Grid item>
        <Typography color="textSecondary">Opis usterki</Typography>
        {summary.description && summary.description != 'null' ? summary.description : ''}
      </Grid>
    </Grid>
    {showClientData && <Grid
      container
      spacing={3}
      alignItems="stretch"
      direction="row"
      justify="flex-start"
    >
      <Grid item xs={6} md={2}>
        <Typography color="textSecondary">Imię i nazwisko użytkownika</Typography>
        <Typography>
          <Link to={`${routePaths.clientsReport}/${summary.reportedBy.id}/history`} >{summary.reportedBy.firstName} {summary.reportedBy.lastName}</Link>
        </Typography>
      </Grid>
      <Grid item xs={6} md={2}>
        <Typography color="textSecondary">Numer telefonu</Typography>
        <Typography>{summary.reportedBy.phoneNumber}</Typography>
      </Grid>
      <Grid item xs={6} md={2}>
        <Typography color="textSecondary">Email</Typography>
        <Typography>{summary.reportedBy.email}</Typography>
      </Grid>
    </Grid>
    }
  </div>
);

SummaryPanel.propTypes = {
  summary: PropTypes.object,
  showClientData: PropTypes.bool,
};

export default SummaryPanel;
