import initialState from './initialState';
import Immutable from 'seamless-immutable';
import { types } from '../actions/zonesActions';
import { ZoneModel, ZoneTypes, ZoneAreaTypes } from '../models/zones/ZoneModel';

export default function zonesReducer(state = Immutable(initialState.zones), action) {
  switch (action.type) {
    case types.LOAD_ZONES_SUCCESS: {
      const { zones } = action;
      return Immutable(zones.map(z => new ZoneModel(z.id, z.name, z.type, z.area, z.areaType, z.areaCenter, z.bikesReturnZoneTypeId, z.marker)));
    }
    case types.CREATE_ZONE_SUCCESS: {
      const { zone } = action;
      const areaCenter = zone.areaType === ZoneAreaTypes.polygon ? zone.area[0] : zone.area.center;
      return state.concat(Object.assign({}, zone, { areaCenter }));
    }
    case types.IMPORT_ZONE_SUCCESS: {
      const { zone } = action;
      return state
        .filter(z => z.type !== ZoneTypes.operationsZone.value)
        .concat(new ZoneModel(zone.id, zone.name, zone.type, zone.area, ZoneAreaTypes.polygon, zone.area[0]));
    }
    case types.DELETE_ZONE_SUCCESS: {
      const { id } = action;
      return state.filter(z => z.id !== id);
    }
    case types.UPDATE_ZONE_MARKER_SUCCESS: {
      const { id, marker } = action;

      const zoneIndex = state.findIndex(z => z.id === id);
      const zoneData = state[zoneIndex];

      return [
        ...state.slice(0, zoneIndex),
        new ZoneModel(zoneData.id, zoneData.name, zoneData.type, zoneData.area, zoneData.areaType, zoneData.areaCenter, zoneData.bikesReturnZoneTypeId, marker),
        ...state.slice(zoneIndex + 1, state.length)];
    }
    case types.UPDATE_ZONE_AREA_SUCCESS: {
      const { id, area } = action;

      const zoneIndex = state.findIndex(z => z.id === id);
      const zoneData = state[zoneIndex];
      const areaCenter = zoneData.areaType === ZoneAreaTypes.polygon ? area[0] : area.center;

      return [
        ...state.slice(0, zoneIndex),
        new ZoneModel(zoneData.id, zoneData.name, zoneData.type, area, zoneData.areaType, areaCenter, zoneData.bikesReturnZoneTypeId, zoneData.marker),
        ...state.slice(zoneIndex + 1, state.length)];
    }
    default:
      return state;
  }
}
