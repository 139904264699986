import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { actions } from "../../../reducers/reports/rideDetails/actions";
import numberFormatter from "../../../utils/numberFormatter";
import {
  FeeTypes,
  BonusTypes,
  PenaltyTypes,
  FareCategory,
  PaymentType,
  SanctionType,
  CorrectionReasonType,
  CorrectionReasonForViolationRulesType,
  CorrectionReasonForBikeDamageType,
} from "../../../models/configuration/PricingModel";
import moment from "moment";
import { permissions, useHasPermission } from "../../../utils/authorization";
import "./FareDetailsStyles.scss";
import FareAdjustmentDialog from "./fareAdjustments/FareAdjustmentDialog";
import FareSanctionAdjustmentDialog from "./fareSanctionAdjustments/FareSanctionAdjustmentDialog";
import FareAdjustmentConfirmDialog from "./fareAdjustments/FareAdjustmentConfirmDialog";

const FareDetails = ({ rideDetails, zones }) => {
  const [fareAdjustmentDialogVisible, setFareAdjustmentDialogVisible] =
    useState(false);
  const [
    fareSanctionAdjustmentDialogVisible,
    setFareSanctionAdjustmentDialogVisible,
  ] = useState(false);
  const [fareAdjustmentConfirmDialog, setFareAdjustmentConfirmDialog] =
    useState({
      data: null,
      visible: false,
    });
  const [
    fareSanctionAdjustmentConfirmDialog,
    setFareSanctionAdjustmentConfirmDialog,
  ] = useState({
    data: null,
    visible: false,
  });

  const zonesMemo = useMemo(() => {
    return zones.reduce((acc, item) => {
      acc[item.id] = { value: item.id, name: item.name };
      return acc;
    }, {});
  }, [zones]);

  const [submitting, setSubmitting] = useState(false);

  const dispatch = useDispatch();
  const canAdjustFare = useHasPermission(permissions.adjustFare);

  const correctionReasonTypeMemo = useMemo(() => {
    return {
      ...CorrectionReasonType,
      ...CorrectionReasonForViolationRulesType,
      ...CorrectionReasonForBikeDamageType,
    };
  }, []);
  const handleStartAdjustment = () => setFareAdjustmentDialogVisible(true);
  const handleStartSanctionAdjustment = () =>
    setFareSanctionAdjustmentDialogVisible(true);

  const handleSaveAdjustment = (paymentAdjustmentData) => {
    setSubmitting(true);
    dispatch(
      actions.adjustRideFare(
        rideDetails.rideId,
        Object.assign({}, paymentAdjustmentData, {
          category: FareCategory.payment.value,
        }),
      ),
    ).then(() => {
      setFareAdjustmentDialogVisible(false);
      setSubmitting(false);
      setFareAdjustmentConfirmDialog({
        data: null,
        visible: false,
      });
    });
  };

  const handleCheckConfirmAdjustment = (paymentAdjustmentData) => {
    if (paymentAdjustmentData.amount > 0) {
      setFareAdjustmentConfirmDialog({
        data: paymentAdjustmentData,
        visible: true,
      });
      return;
    }
    return handleSaveAdjustment(paymentAdjustmentData);
  };

  const handleCheckConfirmSanctionAdjustment = (paymentAdjustmentData) => {
    if (paymentAdjustmentData.amount > 0) {
      setFareSanctionAdjustmentConfirmDialog({
        data: paymentAdjustmentData,
        visible: true,
      });
      return;
    }
    return handleSaveSanctionAdjustment(paymentAdjustmentData);
  };

  const handleSaveSanctionAdjustment = (paymentSanctionAdjustmentData) => {
    setSubmitting(true);
    dispatch(
      actions.adjustRideFare(
        rideDetails.rideId,
        Object.assign({}, paymentSanctionAdjustmentData, {
          category: FareCategory.sanction.value,
        }),
      ),
    ).then(() => {
      setFareSanctionAdjustmentConfirmDialog({
        data: null,
        visible: false,
      });
      setFareSanctionAdjustmentDialogVisible(false);
      setSubmitting(false);
    });
  };

  const handleCancelAdjustment = () => setFareAdjustmentDialogVisible(false);
  const handleCancelSanctionAdjustment = () =>
    setFareSanctionAdjustmentDialogVisible(false);
  const handleCancelAdjustmentConfirm = () =>
    setFareAdjustmentConfirmDialog({
      data: null,
      visible: false,
    });
  const handleCancelSanctionAdjustmentConfirm = () =>
    setFareSanctionAdjustmentConfirmDialog({
      data: null,
      visible: false,
    });

  return (
    <React.Fragment>
      <Table style={{ width: "inherit" }}>
        <TableBody>
          {rideDetails.pricingCalculation &&
          rideDetails.pricingCalculation.freeMinutesConsumed ? (
            <TableRow hover>
              <TableCell size="small" />
              <TableCell size="small">
                Darmowe minuty (
                {rideDetails.pricingCalculation.freeMinutesConsumed} min.)
              </TableCell>
              <TableCell padding="none">
                {rideDetails.pricingCalculation.planName && (
                  <Typography variant="caption">
                    {rideDetails.pricingCalculation.planName}
                  </Typography>
                )}
              </TableCell>
              <TableCell size="small" align="right" className="amount-cell">
                {numberFormatter.format(0)} zł
              </TableCell>
              <TableCell size="small" />
            </TableRow>
          ) : null}
          {rideDetails.pricingCalculation &&
            rideDetails.pricingCalculation.thresholdFares.map(
              (threshold, index) => (
                <TableRow key={index} hover>
                  <TableCell size="small">
                    {FareCategory[threshold.threshold.category] && (
                      <Typography variant="caption">
                        {FareCategory[threshold.threshold.category].name}
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell size="small">
                    {FeeTypes[threshold.threshold.fee.type].name}{" "}
                    {threshold.threshold.thresholdUpperBound &&
                      `(do ${moment.duration(threshold.threshold.thresholdUpperBound).asMinutes()} min.)`}
                  </TableCell>
                  <TableCell padding="none">
                    {rideDetails.pricingCalculation.planName && (
                      <Typography variant="caption">
                        {rideDetails.pricingCalculation.planName}
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell size="small" align="right" className="amount-cell">
                    {numberFormatter.format(threshold.fare)} zł
                  </TableCell>
                  <TableCell size="small">
                    {threshold.threshold.category ===
                      FareCategory.payment.value && (
                      <Typography variant="caption">
                        {PaymentType[threshold.threshold.paymentType].name}
                      </Typography>
                    )}
                    {threshold.threshold.category ===
                      FareCategory.sanction.value && (
                      <Typography variant="caption">
                        {SanctionType[threshold.threshold.sanctionType].name}
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell size="small" />
                  <TableCell size="small" />
                  <TableCell size="small" />
                </TableRow>
              ),
            )}
          {rideDetails.pricingCalculation &&
            (rideDetails.pricingCalculation.cardBenefits || []).map(
              (benefit) => (
                <TableRow key={benefit.cardProviderId} hover>
                  <TableCell size="small">
                    <Typography variant="caption">
                      {FareCategory.payment.name}
                    </Typography>
                  </TableCell>
                  <TableCell size="small">
                    Zniżka {benefit.discountPercentage}%
                  </TableCell>
                  <TableCell padding="none">
                    <Typography variant="caption">
                      {benefit.cardName["pl"]}
                    </Typography>
                  </TableCell>
                  <TableCell
                    size="small"
                    align="right"
                    className="amount-cell bonus-cell"
                  >
                    {numberFormatter.format(benefit.discount)} zł
                  </TableCell>
                  <TableCell />
                  <TableCell size="small" />
                  <TableCell size="small" />
                </TableRow>
              ),
            )}
          {rideDetails.pricingCalculation &&
            rideDetails.pricingCalculation.appliedDeductions.map(
              (deduction) => (
                <TableRow key={deduction.name} hover>
                  <TableCell size="small">
                    {FareCategory[deduction.category] && (
                      <Typography variant="caption">
                        {FareCategory[deduction.category].name}
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell size="small">
                    {BonusTypes[deduction.name].name}
                  </TableCell>
                  <TableCell padding="none">
                    {rideDetails.pricingCalculation.planName && (
                      <Typography variant="caption">
                        {rideDetails.pricingCalculation.planName}
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell
                    size="small"
                    align="right"
                    className="amount-cell bonus-cell"
                  >
                    {numberFormatter.format(deduction.amount)} zł
                  </TableCell>
                  <TableCell size="small" />
                  <TableCell size="small" />
                  <TableCell size="small" />
                </TableRow>
              ),
            )}
          {rideDetails.pricingCalculation &&
            rideDetails.pricingCalculation.appliedPenalties.map((penalty) => (
              <TableRow key={penalty.name} hover>
                <TableCell size="small">
                  {FareCategory[penalty.category] && (
                    <Typography variant="caption">
                      {FareCategory[penalty.category].name}
                    </Typography>
                  )}
                </TableCell>
                <TableCell size="small">
                  {PenaltyTypes[penalty.name].name}
                </TableCell>
                <TableCell padding="none">
                  {rideDetails.pricingCalculation.planName && (
                    <Typography variant="caption">
                      {rideDetails.pricingCalculation.planName}
                    </Typography>
                  )}
                </TableCell>
                <TableCell
                  size="small"
                  align="right"
                  className="amount-cell penalty-cell"
                >
                  {numberFormatter.format(penalty.amount)} zł
                </TableCell>
                <TableCell size="small" />
                <TableCell size="small" />
                <TableCell size="small" />
                <TableCell size="small" />
              </TableRow>
            ))}
          {rideDetails.fareAdjustments &&
            rideDetails.fareAdjustments.map((adjustment) => (
              <TableRow key={adjustment.date} hover>
                <TableCell size="small">
                  {FareCategory[adjustment.category] && (
                    <Typography variant="caption">
                      {FareCategory[adjustment.category].name}
                    </Typography>
                  )}
                </TableCell>
                <TableCell size="small">
                  {!!adjustment.correctionReasonType &&
                    correctionReasonTypeMemo[adjustment.correctionReasonType]
                      .name}
                </TableCell>
                <TableCell padding="none">
                  <Typography variant="caption">
                    {moment(adjustment.date).format("DD.MM.YYYY HH:mm")}
                  </Typography>
                </TableCell>
                <TableCell size="small" align="right" className="amount-cell">
                  {numberFormatter.format(adjustment.amount)} zł
                </TableCell>
                <TableCell size="small">
                  {adjustment.category === FareCategory.payment.value && (
                    <Typography variant="caption">
                      {PaymentType[adjustment.paymentType].name}
                    </Typography>
                  )}
                  {adjustment.category === FareCategory.sanction.value && (
                    <Typography variant="caption">
                      {SanctionType[adjustment.sanctionType].name}
                    </Typography>
                  )}
                </TableCell>
                <TableCell size="small">
                  {!!adjustment.zoneInfo && adjustment.zoneInfo.name}
                </TableCell>
                <TableCell size="small">{adjustment.comment}</TableCell>
                <TableCell size="small">
                  {adjustment.adjustedBy && (
                    <Typography variant="caption">
                      {adjustment.adjustedBy.firstName}{" "}
                      {adjustment.adjustedBy.lastName}
                    </Typography>
                  )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell size="small" />
            <TableCell size="small">RAZEM</TableCell>
            <TableCell padding="none" />
            <TableCell size="small" align="right" className="amount-cell">
              <strong>
                {numberFormatter.format(rideDetails.finalFare)} zł
              </strong>
            </TableCell>
            <TableCell />
            <TableCell size="small" />
            <TableCell size="small" />
            <TableCell size="small" />
          </TableRow>
        </TableFooter>
      </Table>
      {canAdjustFare && (
        <>
          <Button
            style={{ marginTop: 8, marginRight: 8 }}
            variant="contained"
            size="small"
            onClick={handleStartAdjustment}
            disabled={!rideDetails.pricingCalculation}
          >
            Opłata
          </Button>
          <Button
            style={{ marginTop: 8 }}
            variant="contained"
            size="small"
            onClick={handleStartSanctionAdjustment}
            disabled={!rideDetails.pricingCalculation}
          >
            Kara/sankcja
          </Button>
        </>
      )}
      <FareAdjustmentDialog
        zones={zonesMemo}
        open={fareAdjustmentDialogVisible}
        rideDetails={rideDetails}
        onSave={handleCheckConfirmAdjustment}
        onCancel={handleCancelAdjustment}
        submitting={submitting}
      />
      <FareAdjustmentConfirmDialog
        zones={zonesMemo}
        open={fareAdjustmentConfirmDialog.visible}
        fareData={fareAdjustmentConfirmDialog.data}
        onSave={handleSaveAdjustment}
        onCancel={handleCancelAdjustmentConfirm}
        submitting={submitting}
      />
      <FareSanctionAdjustmentDialog
        open={fareSanctionAdjustmentDialogVisible}
        rideDetails={rideDetails}
        onSave={handleCheckConfirmSanctionAdjustment}
        onCancel={handleCancelSanctionAdjustment}
        submitting={submitting}
        zones={zonesMemo}
      />
      <FareAdjustmentConfirmDialog
        open={fareSanctionAdjustmentConfirmDialog.visible}
        fareData={fareSanctionAdjustmentConfirmDialog.data}
        onSave={handleSaveSanctionAdjustment}
        onCancel={handleCancelSanctionAdjustmentConfirm}
        submitting={submitting}
      />
    </React.Fragment>
  );
};

FareDetails.propTypes = {
  rideDetails: PropTypes.object.isRequired,
  zones: PropTypes.array.isRequired,
};

export default FareDetails;
