import React from 'react';
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles( {
    container: {
      display: "flex",
      padding: "10px 5px",
      columnGap: 30,
      flexWrap: "wrap"
    },

});


const AccountSectionView = ({children, style, dividerBottom}) => {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.container} style={style}>
        {children}
      </div>
      {dividerBottom && <Divider style={{margin: '0 40px'}}/>}
    </div>
  );

}
AccountSectionView.defaultProps = {
  style: {},
  dividerBottom: false
}

AccountSectionView.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
  dividerBottom: PropTypes.bool,
};


export default AccountSectionView;
