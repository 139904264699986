import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import FormTextField from '../form/redux-form/FormTextField';
import FormCheckBox from '../form/redux-form/FormCheckBox';
import PricingDetailsForm from '../pricing/PricingDetailsForm';
import { Typography, Paper } from '@material-ui/core';
import FormSelect from '../form/redux-form/FormSelect';
import { PlanValidityTypes } from '../../models/plans/PlanModel';
import { planFormValidation } from '../../models/plans/PlanValidation';

const PlanDetailsForm = ({ onSubmit, initialValues, readonly }) => (
  <PricingDetailsForm
    onSubmit={data => onSubmit(data)}
    fieldPrefix="pricingConfiguration."
    isPlan
    initialValues={initialValues}
    validate={planFormValidation}
    readonly={readonly}
  >
    <Typography variant="subtitle1" gutterBottom>Plan</Typography>
    <Paper className="config-section" elevation={3}>
      <div>
        <Field
          name="name"
          component={FormTextField}
          readonly={readonly}
          label="Nazwa"
          autoFocus
        />
      </div>
      <div>
        <Field
          name="description"
          component={FormTextField}
          readonly={readonly}
          label="Opis"
          fullWidth
        />
      </div>
      <div>
        <Field
          name="price"
          type="number"
          component={FormTextField}
          readonly={readonly}
          label="Cena"
          endAdornmentText="zł"
        />
      </div>
      <div>
        <Field
          name="validity"
          component={FormSelect}
          readonly={readonly}
          label="Okres ważności"
          style={{ width: 200 }}
          values={PlanValidityTypes}
        />
      </div>
      <div>
        <Field
          name="internalUseOnly"
          component={FormCheckBox}
          readonly={readonly}
          label="Plan testowy"
        />
        <Typography display="block" variant="caption">
          Wybierz, jeśli chcesz, aby plan był dostępny tylko testerów
        </Typography>
      </div>
      <div>
        <Field
          name="dedicated"
          component={FormCheckBox}
          readonly={readonly}
          label="Plan dedykowany"
        />
      </div>
    </Paper>
  </PricingDetailsForm>
);

PlanDetailsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  readonly: PropTypes.bool.isRequired,
};

export default PlanDetailsForm;
