import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'moment-duration-format';
import { actions } from '../../../reducers/reports/rideDetails/actions';
import { actions as clientActions } from '../../../reducers/reports/clientHistory/actions';

import './styles.scss';
import { Button } from '@material-ui/core';
import RestartRideDialog from "./RestartRideDialog";

class RestartRideDetails extends Component {
  constructor(props) {
    super(props);
    this.state = { validTelemetry: [] };

    this.state = {
      restartRideDialogVisible: false,
      submitting: false,
    };

    this.handleShowDialog = this.handleShowDialog.bind(this);
    this.handleRestartRide = this.handleRestartRide.bind(this);
    this.handleRestartRideCancel = this.handleRestartRideCancel.bind(this);
  }

  handleShowDialog() {
    this.setState({ restartRideDialogVisible: true });
  }

  handleRestartRide() {
    this.setState({ submitting: true });
    this.props.actions.restartRide(this.props.summary.rideId, this.props.currentUser)
      .finally(() => {
        this.setState({ restartRideDialogVisible: false, submitting: false })
      });
  }

  handleRestartRideCancel() {
    this.setState({ restartRideDialogVisible: false });
  }

  render() {
    return (
      <React.Fragment>
        <Button size="small" color="primary" variant="contained" onClick={this.handleShowDialog} disabled={this.state.submitting}>Wznów</Button>
        <RestartRideDialog
          isOpened={this.state.restartRideDialogVisible}
          onSave={this.handleRestartRide}
          onCancel={this.handleRestartRideCancel}
          submitting={this.state.submitting}
          rideId={this.props.summary.rideId}
          userId={this.props.summary.userId}
          actions={this.props.actions}
          clientActions={this.props.clientActions}
        />
      </React.Fragment>
    );
  }
}

RestartRideDetails.propTypes = {
  actions: PropTypes.object.isRequired,
  clientActions: PropTypes.object.isRequired,
  summary: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
};


function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    clientActions: bindActionCreators(clientActions, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(RestartRideDetails);
