import apiBase from "./apiBase";

class ReportsApi {
  static getRides(pageNumber, pageSize, sortBy, filterBy) {
    const payload = {
      paging: {
        pageNumber,
        pageSize,
      },
      filterBy: Object.assign(
        {},
        ...filterBy.map((x) => ({ [x.id]: x.value })),
      ),
      sortBy: Object.assign({}, ...sortBy.map((x) => ({ [x.id]: x.value }))),
    };
    return apiBase.create().post(`/reports/rides`, payload);
  }

  static getRidesSummary(filterBy) {
    const payload = {
      filterBy: Object.assign(
        {},
        ...filterBy.map((x) => ({ [x.id]: x.value })),
      ),
    };
    return apiBase.create().post(`/reports/rides/summary`, payload);
  }

  static exportRides(sortBy, filterBy) {
    const payload = {
      filterBy: Object.assign(
        {},
        ...filterBy.map((x) => ({ [x.id]: x.value })),
      ),
      sortBy: Object.assign({}, ...sortBy.map((x) => ({ [x.id]: x.value }))),
    };
    return apiBase.create().post(`/reports/rides/export`, payload, {
      responseType: "blob",
      timeout: 120000,
    });
  }

  static getHeatmapData(filterBy) {
    const payload = {
      filterBy: Object.assign(
        {},
        ...filterBy.map((x) => ({ [x.id]: x.value })),
      ),
    };
    return apiBase.create().post(`/reports/rides/heatmap`, payload);
  }

  static getHeatmapTracesData(filterBy) {
    const payload = {
      filterBy: Object.assign(
        {},
        ...filterBy.map((x) => ({ [x.id]: x.value })),
      ),
    };
    return apiBase.create().post(`/reports/rides/heatmap/traces`, payload);
  }

  static getRideDetails(rideId) {
    return apiBase.create().get(`/reports/rides/${rideId}`);
  }

  static getClients(pageNumber, pageSize, sortBy, filterBy) {
    const payload = {
      paging: {
        pageNumber,
        pageSize,
      },
      filterBy: Object.assign(
        {},
        ...filterBy.map((x) => ({ [x.id]: x.value })),
      ),
      sortBy: Object.assign({}, ...sortBy.map((x) => ({ [x.id]: x.value }))),
    };
    return apiBase.create().post(`/reports/clients`, payload);
  }

  static getGlobalClients(pageNumber, pageSize, sortBy, filterBy) {
    const payload = {
      paging: {
        pageNumber,
        pageSize,
      },
      filterBy: Object.assign(
        {},
        ...filterBy.map((x) => ({ [x.id]: x.value })),
      ),
      sortBy: Object.assign({}, ...sortBy.map((x) => ({ [x.id]: x.value }))),
    };
    return apiBase.create().post(`/operations/mobileUserAccounts`, payload);
  }

  static getClientHistory(userId, pageNumber, pageSize, sortBy, filterBy) {
    const payload = {
      paging: {
        pageNumber,
        pageSize,
      },
      filterBy: Object.assign(
        {},
        ...filterBy.map((x) => ({ [x.id]: x.value })),
      ),
      sortBy: Object.assign({}, ...sortBy.map((x) => ({ [x.id]: x.value }))),
    };
    return apiBase.create().post(`/reports/clients/${userId}/history`, payload);
  }

  static getGlobalClientHistory(userId) {
    return apiBase.create().get(`/operations/mobileUserAccounts/${userId}`);
  }

  static confirmAssignPhoneNumber(userId, data) {
    return apiBase
      .create()
      .post(
        `/operations/mobileUserAccounts/${userId}/confirmAssignPhoneNumber`,
        data,
      );
  }

  static changeUserPhoneNumber(userId, data) {
    return apiBase
      .create()
      .post(
        `/operations/mobileUserAccounts/${userId}/changeUserPhoneNumber`,
        data,
      );
  }

  static resendVerificationEmail(userId) {
    return apiBase
      .create()
      .post(
        `/operations/mobileUserAccounts/${userId}/resendVerificationEmail`,
        {},
      );
  }

  static deactivateUserAccount(userId) {
    return apiBase
      .create()
      .delete(`/operations/mobileUserAccounts/${userId}/deactivate`, {});
  }
  static getRelocationData() {
    return apiBase.create().get("/reports/relocation");
  }

  static exportRelocationData() {
    return apiBase
      .create()
      .post("/reports/relocation/export", null, { responseType: "blob" });
  }

  static exportGpxReport(rideId) {
    return apiBase.create().get(`/reports/rides/${rideId}/export/gpx`, null, {
      responseType: "blob",
    });
  }

  static exportGPSPosition(rideId) {
    return apiBase
      .create()
      .get(`/reports/rides/${rideId}/export/gpsPosition`, null, {
        responseType: "blob",
      });
  }
  static exportRelocationHistoryData(data) {
    return apiBase
      .create()
      .post("/reports/relocation/history", data, { responseType: "blob" });
  }

  static generateReport(data) {
    return apiBase.create().post("/operations/reports/generate", data);
  }

  static getReportsList(pageNumber, pageSize, sortBy, filterBy) {
    const payload = {
      paging: {
        pageNumber,
        pageSize,
      },
      filterBy: Object.assign(
        {},
        ...filterBy.map((x) => ({ [x.id]: x.value })),
      ),
      sortBy: Object.assign({}, ...sortBy.map((x) => ({ [x.id]: x.value }))),
    };

    return apiBase.create().post("/operations/reports/list", payload);
  }

  static downloadPdf(id) {
    return apiBase.create().get(`/operations/reports/${id}/download/pdf`);
  }

  static downloadXlsx(id) {
    return apiBase.create().get(`/operations/reports/${id}/download/xlsx`);
  }

  static async createCustomReport(payload) {
    return apiBase.create().post(`/operations/reports/custom/create`, payload);
  }

  static async getCustomReportList() {
    return apiBase.create().get(`/operations/reports/custom/list`);
  }

  static async getCustomReport(id) {
    return apiBase.create().get(`/operations/reports/custom/${id}`);
  }

  static async removeCustomReport(id) {
    return apiBase.create().delete(`/operations/reports/custom/${id}`);
  }

  static getNotStartedRides(pageNumber, pageSize, sortBy, filterBy) {
    const payload = {
      paging: {
        pageNumber,
        pageSize,
      },
      filterBy: Object.assign(
        {},
        ...filterBy.map((x) => ({ [x.id]: x.value })),
      ),
      sortBy: Object.assign({}, ...sortBy.map((x) => ({ [x.id]: x.value }))),
    };
    return apiBase.create().post(`/reports/notStaredRides`, payload);
  }
}

export default ReportsApi;
