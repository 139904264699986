import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import FormTextField from '../../form/redux-form/NativeFormTextField';
import { passwordRecoveryFormValidation } from '../../../models/auth/AuthModelValidation';
import './PasswordRecoveryFormStyles.scss';

let PasswordRecoveryForm = ({ handleSubmit, submitting }) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <Field
          name="email"
          type="email"
          component={FormTextField}
          label="Twój adres email"
        />
      </div>
      <button type="submit" className="btn btn-default" disabled={submitting}>
        Wyślij
      </button>
    </form>
  );
};

PasswordRecoveryForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
};

PasswordRecoveryForm = reduxForm({
  form: 'passwordRecovery',
  validate: passwordRecoveryFormValidation,
})(PasswordRecoveryForm);

export default PasswordRecoveryForm;
