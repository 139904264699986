import initialState from "../../initialState";
import Immutable from "seamless-immutable";
import { types } from "./actions";
import { ClientHistorySummary, ClientHistoryEvent } from "./models";
import { ReportTableModel } from "../../../models/ReportTableModel";

export default function clientHistoryReportReducer(
  state = Immutable(initialState.reports.clientHistory),
  action,
) {
  switch (action.type) {
    case types.LOAD_CLIENT_HISTORY_REPORT: {
      return Immutable.merge(state, {
        loading: true,
        pagedData: ReportTableModel.pageRequested(
          state.pagedData,
          action.pageNumber,
          action.pageSize,
          action.filterBy,
          action.sortBy,
        ),
      });
    }
    case types.LOAD_CLIENT_HISTORY_REPORT_SUCCESS: {
      const events = action.result.events;
      return Immutable.merge(state, {
        loading: false,
        summary: new ClientHistorySummary(action.result),
        pagedData: ReportTableModel.pageReady(
          state.pagedData,
          events.totalDataCount,
          events.data.map((r) => new ClientHistoryEvent(r)),
        ),
      });
    }
    case types.LOAD_GLOBAL_CLIENT_HISTORY_REPORT: {
      return Immutable.merge(state, {
        loading: true,
        summary: null,
      });
    }
    case types.LOAD_GLOBAL_CLIENT_HISTORY_REPORT_SUCCESS: {
      return Immutable.merge(state, {
        loading: false,
        summary: new ClientHistorySummary(action.result, true),
      });
    }
    case types.CONFIRM_ASSIGN_PHONE_NUMBER_SUCCESS: {
      return Immutable.merge(state, {
        summary: {
          ...state.summary,
          phoneNumber: action.phoneNumber,
          toAssignPhoneNumber: "",
        },
      });
    }
    case types.CHANGE_USER_PHONE_NUMBER_SUCCESS: {
      return Immutable.merge(state, {
        summary: {
          ...state.summary,
          toAssignPhoneNumber: "",
        },
      });
    }
    case types.LOAD_NEXT_CLIENT_HISTORY_EVENTS: {
      return Immutable.merge(state, {
        pagedData: ReportTableModel.nextPageRequested(
          state.pagedData,
          action.pageNumber,
        ),
      });
    }
    case types.LOAD_NEXT_CLIENT_HISTORY_EVENTS_SUCCESS: {
      const events = action.result.events;
      return Immutable.merge(state, {
        pagedData: ReportTableModel.pageReady(
          state.pagedData,
          events.totalDataCount,
          events.data.map((r) => new ClientHistoryEvent(r)),
        ),
      });
    }
    case types.UPLOAD_PARENTAL_CONSENT_FOR_JUVENILE: {
      return Immutable.merge(state, {
        loading: true,
      });
    }
    case types.UPLOAD_PARENTAL_CONSENT_FOR_JUVENILE_SUCCESS: {
      return Immutable.merge(state, {
        loading: false,
        summary: new ClientHistorySummary({
          ...state.summary,
          parentalConsentDocumentUrl: action.parentalConsentDocumentUrl,
        }),
      });
    }
    case types.REMOVE_PARENTAL_CONSENT_FOR_JUVENILE: {
      return Immutable.merge(state, {
        loading: true,
      });
    }
    case types.REMOVE_PARENTAL_CONSENT_FOR_JUVENILE_SUCCESS: {
      return Immutable.merge(state, {
        loading: false,
        summary: new ClientHistorySummary({
          ...state.summary,
          parentalConsentDocumentUrl: null,
        }),
      });
    }
    case types.CHANGE_UNDERAGE_STATUS_SUCCESS: {
      return Immutable.merge(state, {
        loading: false,
        summary: new ClientHistorySummary({
          ...state.summary,
          isJuvenile: action.status,
        }),
      });
    }
    case types.CHANGE_SUBSCRIBE_CAMPAIGN_SUCCESS: {
      return Immutable.merge(state, {
        loading: false,
        summary: new ClientHistorySummary({
          ...state.summary,
          isSubscribeCampaignNewsletter: action.isSubscribe,
        }),
      });
    }
    case types.ADD_TO_DENY_LIST_SUCCESS: {
      return Immutable.merge(state, {
        loading: false,
        summary: new ClientHistorySummary({
          ...state.summary,
          accessDenied: true,
        }),
      });
    }
    case types.REMOVE_FROM_DENY_LIST_SUCCESS: {
      return Immutable.merge(state, {
        loading: false,
        summary: new ClientHistorySummary({
          ...state.summary,
          accessDenied: false,
        }),
      });
    }
    default:
      return state;
  }
}
