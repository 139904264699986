
import React from 'react';
import moment from 'moment';
import ScheduleIcon from '@material-ui/icons/Schedule';
import IconButton from '@material-ui/core/IconButton';
import ErrorIcon from '@material-ui/icons/Error';
import PdfIcon from '@material-ui/icons/PictureAsPdf';
import SheetIcon from '@material-ui/icons/GridOn';
import { blue, red, green } from '@material-ui/core/colors'
import { ReportStatus } from '../../../models/reports/ReportStatus';
import ReportType from '../../../models/reports/ReportType';
import { Tooltip } from '@material-ui/core';
import {ReportDataFor} from "../../../models/reports/ReportDataFor";

/* eslint react/display-name: 0 */
/* eslint react/prop-types: 0 */
/* eslint react/no-multi-comp: 0 */
export const columns = (onDownloadPdf, onDownloadXlsx) => [
  {
    Header: '',
    id: 'actions',
    Cell: row => (
      <div style={{ textAlign: 'center' }}>
        {row.original.status === ReportStatus.queued.value
          || row.original.status === ReportStatus.fetchingData.value
          || row.original.status === ReportStatus.rendering.value
          ? <ScheduleIcon style={{ height: 24, width: 24, fill: blue[400] }} />
          : row.original.status === ReportStatus.failed.value
            ? <Tooltip title="Wystąpił błąd podczas generowania raportu">
              <ErrorIcon style={{ height: 24, width: 24, fill: red[400] }} />
            </Tooltip>
            : <>
            </>
        }

        {row.original.pdfAvailable && <Tooltip title="Pobierz raport w formacie PDF">
          <IconButton style={{ height: 24, width: 24 }} onClick={() => onDownloadPdf(row.original.id)}>
            <PdfIcon />
          </IconButton>
        </Tooltip>
        }

        {row.original.xlsxAvailable && <Tooltip title="Pobierz raport w formacie Excel">
          <IconButton style={{ height: 24, width: 24, marginLeft: 10 }} onClick={() => onDownloadXlsx(row.original.id)}>
            <SheetIcon style={{ fill: green[400] }} />
          </IconButton>
        </Tooltip>
        }
      </div >),
    width: 80,
    sortable: false,

    Filter: () => null,
  },
  {
    Header: 'Typ raportu',
    id: 'type',
    accessor: a => ReportType.customReportFromTemplate === ReportType[a.type] ? a.name : ReportType[a.type].name,
    width: 180,
    filterable: false
  },
  {
    Header: 'Dane Dla',
    id: 'generatedFor',
    accessor: a => ReportDataFor[a.generatedFor] ? ReportDataFor[a.generatedFor].name : a.generatedFor,
    width: 120,
    filterable: false
  },
  {
    Header: 'Od',
    id: 'dateFrom',
    accessor: d => moment(d.dateFrom).local().format('DD.MM.YYYY HH:mm'),
    width: 150,
    filterable: false
  },
  {
    Header: 'Do',
    id: 'dateTo',
    accessor: d => moment(d.dateTo).local().format('DD.MM.YYYY HH:mm'),
    width: 150,
    filterable: false
  },
  {
    Header: 'Data zakolejkowania',
    id: 'queuedAt',
    accessor: d => moment(d.queuedAt).local().format('DD.MM.YYYY HH:mm'),
    width: 150,
    filterable: false,
  },
  {
    Header: 'Uruchomiony przez',
    id: 'queuedBy',
    accessor: d => `${d.queuedBy.firstName} ${d.queuedBy.lastName}`,
    width: 220,
    filterable: false,
  },
  {
    Header: 'Status',
    id: 'status',
    accessor: a => ReportStatus[a.status].name,
    width: 150,
    filterable: false,
  },
];
