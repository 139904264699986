import React from 'react';
import PropTypes from 'prop-types';
import Fade from '@material-ui/core/Fade';

const FareAdjustmentInfo = ({ message }) => (
  <Fade in={true}><p style={{ backgroundColor: '#FFF9C4', padding: 8}}>{message}</p></Fade>
);

FareAdjustmentInfo.propTypes = {
  message: PropTypes.string.isRequired,
};

export default FareAdjustmentInfo;
