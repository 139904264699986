import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from '../../../reducers/reports/rides/actions';
import { actions as bikeTypesActions } from '../../../actions/bikeTypesActions';
import { actions as zonesActions } from '../../../actions/zonesActions';
import ReportTable from '../../common/reportTable/ReportTable';
import FiltersPanel from './FiltersPanel';
import { getColumns } from './Columns';
import './styles.scss';
import { permissions, hasPermission } from '../../../utils/authorization';

class NotStartRidesReportsPage extends Component {
  constructor(props) {
    super(props);
    this.state = { heatmapOpened: false };
    this.handleFiltersChange = this.handleFiltersChange.bind(this);
    this.handleSortChange = this.handleSortChange.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
  }

  componentDidMount() {
    const rides = this.props.rides;
    this.props.actions.loadFreshNotStartedRideReports(1, rides.paging.pageSize, rides.sortedBy, rides.filteredBy);
    this.props.bikeTypesActions.loadBikeTypes();
    this.props.zonesActions.load()
  }

  handleSortChange(sortBy) {
    const rides = this.props.rides;
    this.props.actions.loadFreshNotStartedRideReports(1, rides.paging.pageSize, sortBy, rides.filteredBy);
  }

  handleFiltersChange(filterBy) {
    const rides = this.props.rides;
    this.props.actions.loadFreshNotStartedRideReports(1, rides.paging.pageSize, rides.sortedBy, filterBy);
  }


  handlePageChange(nextPage) {
    const rides = this.props.rides;
    this.props.actions.loadNextNotStartedRideReports(nextPage,rides.filteredBy);
  }

  handlePageSizeChange(nextPageSize) {
    const rides = this.props.rides;
    this.props.actions.loadFreshNotStartedRideReports(1, nextPageSize, rides.sortedBy, rides.filteredBy);
  }

  render() {
    const { rides, bikeTypes, zones } = this.props;
    return (
      <div className="page">
        <div style={{ margin: 40, marginBottom: 0 }}>
          {this.state.heatmapOpened && this.renderHeatmapDialog()}
          <FiltersPanel filteredBy={rides.filteredBy} onFilteredChange={this.handleFiltersChange} bikeTypes={bikeTypes} zones={zones} />
          <Paper style={{ padding: 16 }}>
            <ReportTable
              style={{ height: "calc(100vh - 300px)" }}
              columns={getColumns(hasPermission(this.props.operatorPermissions, permissions.clients), bikeTypes, zones)}
              data={rides.records}
              filteredBy={rides.filteredBy}
              sortedBy={rides.sortedBy}
              paging={rides.paging}
              onFilteredChange={this.handleFiltersChange}
              onPageChange={this.handlePageChange}
              onPageSizeChange={this.handlePageSizeChange}
              onSortedChange={this.handleSortChange}
              showExportToSpreadsheet
              onExportToSpreadsheet={this.handleExportToSpreadsheet}
              additionalToolbarButtons={[]}
              loading={rides.loading}
              bikeTypes={bikeTypes}
            />
          </Paper>
        </div >
      </div >
    );
  }
}

NotStartRidesReportsPage.propTypes = {
  actions: PropTypes.object.isRequired,
  bikeTypesActions: PropTypes.object.isRequired,
  zonesActions: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  operatorPermissions: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  loadingSummary: PropTypes.bool,
  isSummaryOldData: PropTypes.bool,
  summary: PropTypes.object,
  rides: PropTypes.object,
  zones: PropTypes.array.isRequired,
  bikeTypes: PropTypes.array.isRequired,
};

NotStartRidesReportsPage.defaultProps = {
  loading: false,
  loadingSummary: false,
  isSummaryOldData: true,
  rides: {},
  summary: {},
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    bikeTypesActions: bindActionCreators(bikeTypesActions, dispatch),
    zonesActions: bindActionCreators(zonesActions, dispatch)
  };
}

function mapStateToProps(state) {
  const reportState = state.reports.rides;
  return {
    loading: reportState.loading,
    loadingSummary: reportState.loadingSummary,
    rides: reportState.pagedData,
    bikeTypes: state.bikeTypes.list,
    zones: state.zones,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NotStartRidesReportsPage);
