
import initialState from '../../initialState';
import Immutable from 'seamless-immutable';
import { types } from './actions';
import { ClientWalletSummary, ClientWalletTransaction } from './models';
import { ReportTableModel } from '../../../models/ReportTableModel';

export default function clientWalletReducer(state = Immutable(initialState.reports.clientWallet), action) {
  switch (action.type) {
    case types.LOAD_WALLET_SUMMARY: {
      return state;
    }
    case types.LOAD_WALLET_SUMMARY_SUCCESS: {
      return Immutable.merge(state, {
        summary: Object.assign(new ClientWalletSummary(), action.result),
      });
    }
    case types.LOAD_WALLET_TRANSACTIONS: {
      return Immutable.merge(state, {
        loading: true,
        pagedData: ReportTableModel.pageRequested(state.pagedData, action.pageNumber, action.pageSize, action.filterBy, action.sortBy),
      });
    }
    case types.LOAD_WALLET_TRANSACTIONS_SUCCESS: {
      const transactions = action.result;
      return Immutable.merge(state, {
        loading: false,
        pagedData: ReportTableModel.pageReady(state.pagedData, transactions.totalDataCount, transactions.data.map(r => new ClientWalletTransaction(r))),
      });
    }
    case types.LOAD_NEXT_WALLET_TRANSACTIONS: {
      return Immutable.merge(state, {
        pagedData: ReportTableModel.nextPageRequested(state.pagedData, action.pageNumber),
      });
    }
    case types.LOAD_NEXT_WALLET_TRANSACTIONS_SUCCESS: {
      const transactions = action.result;
      return Immutable.merge(state, {
        pagedData: ReportTableModel.pageReady(state.pagedData, transactions.totalDataCount, transactions.data.map(r => new ClientWalletTransaction(r))),
      });
    }
    default:
      return state;
  }
}
