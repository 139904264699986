import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import _ from 'lodash';
import './FilterBar.scss';

class FilterBar extends React.Component {
  constructor(props) {
    super(props);
    this.handleFilterDebounced = _.debounce(this.handleFilter.bind(this), 500);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.state = {
      keyword: props.keyword,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(this.props.keyword !== nextProps.keyword){
      this.setState({keyword: nextProps.keyword });
      this.runFiltering(this.props.itemsToFilter, nextProps.keyword);
    }
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (this.props.itemsToFilter !== nextProps.itemsToFilter) {
      this.runFiltering(nextProps.itemsToFilter, this.state.keyword);
    }
  }

  handleInputChange(event) {
    this.setState({keyword: event.target.value.toLowerCase()});
    this.handleFilterDebounced();
  }

  handleFilter() {
    this.runFiltering(this.props.itemsToFilter, this.state.keyword);
  }

  runFiltering(items, keyword) {
    const {filter, onFilter, onKeywordChange} = this.props;
    if (onFilter) onFilter(keyword !== null && keyword !== '' ? items.filter(item => filter(item, keyword)) : items);
    if (onKeywordChange) onKeywordChange(keyword);
  }

  render() {
    return (
      <div className="filter-bar">
        <SearchIcon color="action" />
        <TextField
          value={this.state.keyword}
          placeholder="Wyszukaj na liście"
          fullWidth={true}
          onChange={this.handleInputChange}
          className="filter-bar-input"
        />
      </div>
    );
  }
}

FilterBar.propTypes = {
  itemsToFilter: PropTypes.array.isRequired,
  filter: PropTypes.func,
  onFilter: PropTypes.func,
  keyword: PropTypes.string,
  onKeywordChange: PropTypes.func,
};

export default FilterBar;
