import reportsApi from '../api/reportsApi';
import { actions as notificationActions } from './notificationActions';
import * as logger from '../utils/logger';

export const types = {
  LOAD_REPORTS: 'LOAD_REPORTS',
  LOAD_REPORTS_SUCCESS: 'LOAD_REPORTS_SUCCESS',
  LOAD_REPORTS_FAILURE: 'LOAD_REPORTS_FAILURE',
  LOAD_NEXT_REPORTS: 'LOAD_NEXT_REPORTS',
  LOAD_NEXT_REPORTS_SUCCESS: 'LOAD_NEXT_REPORTS_SUCCESS',
  LOAD_NEXT_REPORTS_FAILURE: 'LOAD_NEXT_REPORTS_FAILURE',
  GENERATE_REPORT_SUCCESS: 'GENERATE_REPORT_SUCCESS',
  GENERATE_REPORT_FAILURE: 'GENERATE_REPORT_FAILURE',
  CREATE_CUSTOM_REPORT: 'CREATE_CUSTOM_REPORT',
  CREATE_CUSTOM_REPORT_SUCCESS: 'CREATE_CUSTOM_REPORT_SUCCESS',
  CREATE_CUSTOM_REPORT_FAILURE: 'CREATE_CUSTOM_REPORT_FAILURE',
  GET_CUSTOM_REPORT_LIST: 'GET_CUSTOM_REPORT_LIST',
  GET_CUSTOM_REPORT_LIST_SUCCESS: 'GET_CUSTOM_REPORT_LIST_SUCCESS',
  GET_CUSTOM_REPORT_LIST_FAILURE: 'GET_CUSTOM_REPORT_LIST_FAILURE',
  REMOVE_CUSTOM_REPORT_LIST: 'REMOVE_CUSTOM_REPORT_LIST',
  REMOVE_CUSTOM_REPORT_SUCCESS: 'REMOVE_CUSTOM_REPORT_SUCCESS',
  REMOVE_CUSTOM_REPORT_FAILURE: 'REMOVE_CUSTOM_REPORT_FAILURE',
  GET_CUSTOM_REPORT: 'GET_CUSTOM_REPORT_LIST',
  GET_CUSTOM_REPORT_SUCCESS: 'GET_CUSTOM_REPORT_SUCCESS',
  GET_CUSTOM_REPORT_FAILURE: 'GET_CUSTOM_REPORT_FAILURE',
};

export const actions = {

  removeCustomReport: (id) => async dispatch => {
    dispatch({ type: types.REMOVE_CUSTOM_REPORT_LIST });
    const response = await reportsApi.removeCustomReport(id)
    if(response.ok) {
      dispatch({ type: types.REMOVE_CUSTOM_REPORT_SUCCESS, result: response.data });
      dispatch(notificationActions.showSuccess('Usunięto szablon raportu'));
      return true;
    }
    dispatch(notificationActions.showError('Wystąpił błąd podczas usuwania szablonu raportu. Prosimy spróbowac później'));
    dispatch({ type: types.REMOVE_CUSTOM_REPORT_FAILURE, result: response.data });
    return false;
  },

  getCustomReportList: () => async dispatch => {
    dispatch({ type: types.GET_CUSTOM_REPORT_LIST });
    const response = await reportsApi.getCustomReportList()
    if(response.ok) {
      dispatch({ type: types.GET_CUSTOM_REPORT_LIST_SUCCESS, result: response.data });
      return;
    }
    dispatch({ type: types.GET_CUSTOM_REPORT_LIST_FAILURE, result: response.data });
  },

  getCustomReport: id => async dispatch => {
    dispatch({ type: types.GET_CUSTOM_REPORT });
    const response = await reportsApi.getCustomReport(id)
    if(response.ok) {
      dispatch({ type: types.GET_CUSTOM_REPORT_SUCCESS, result: response.data });
      return;
    }
    dispatch({ type: types.GET_CUSTOM_REPORT_FAILURE, result: response.data });
  },

  createCustomReport: (payload) => async dispatch => {
    dispatch({ type: types.CREATE_CUSTOM_REPORT });

    const response = await reportsApi.createCustomReport(payload)

    if(response.ok && response.data.success !== false){
      dispatch({ type: types.CREATE_CUSTOM_REPORT_SUCCESS });
      dispatch(notificationActions.showSuccess('Stworzono nowy szablon raportu'));
      return true;
    }
    dispatch({ type: types.CREATE_CUSTOM_REPORT_FAILURE });
    if(response.ok && response.data.success === false) {
      dispatch(notificationActions.showError(response.data.failure.message));
      return false;
    }
    dispatch(notificationActions.showError('Wystąpił błąd podczas tworzenia nowego szablonu raportu. Prosimy spróbowac później'));
    return false;
  },

  load: (pageNumber, pageSize, sortBy, filterBy) => dispatch => {
    dispatch({ type: types.LOAD_REPORTS, pageNumber, pageSize, sortBy, filterBy });
    return reportsApi
      .getReportsList(pageNumber, pageSize, sortBy, filterBy)
      .then(response => {
        if (response.ok) {
          return dispatch({ type: types.LOAD_REPORTS_SUCCESS, result: response.data || {} });
        } else {
          dispatch({ type: types.LOAD_REPORTS_FAILURE });
          dispatch(notificationActions.showError('Nie udało się załadować raportów'));

          return logger.error(response.data);
        }
      });
  },

  loadNext: pageNumber => (dispatch, getState) => {
    dispatch({ type: types.LOAD_NEXT_REPORTS, pageNumber });
    const sortBy = getState().reports.list.pagedData.sortedBy;
    const filterBy = getState().reports.list.pagedData.filteredBy;
    const pageSize = getState().reports.list.pagedData.paging.pageSize;
    return reportsApi
      .getReportsList(pageNumber, pageSize, sortBy, filterBy)
      .then(response => {
        if (response.ok) {
          return dispatch({ type: types.LOAD_NEXT_REPORTS_SUCCESS, result: response.data || {} });
        } else {
          dispatch({ type: types.LOAD_NEXT_REPORTS_FAILURE });
          dispatch(notificationActions.showError('Nie udało załadować raportów'));

          return logger.error(response.data);
        }
      });
  },

  generate: type => dispatch => {
    return reportsApi
      .generateReport(type)
      .then(response => {
        if (response.ok) {
          if (response.data.success === false){
            dispatch(notificationActions.showError(response.data.failure.message));
            return dispatch({ type: types.GENERATE_REPORT_FAILURE });
          }
          dispatch(notificationActions.showSuccess('Raport oczekuje na wygenerowanie'));
          return dispatch({ type: types.GENERATE_REPORT_SUCCESS });
        } else {
          dispatch({ type: types.GENERATE_REPORT_FAILURE });
          dispatch(notificationActions.showError('Wystąpił problem podczas generowania raportu'));

          return logger.error(response.data);
        }
      });
  },

  downloadPdf: id => dispatch => {
    return reportsApi
      .downloadPdf(id)
      .then(response => {
        if (response.ok) {
          let a = document.createElement('a');
          a.href = response.data;
          a.download = 'raport.pdf';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        } else {
          dispatch(notificationActions.showError('Wystąpił problem podczas pobierania raportu'));
          return logger.error(response.data);
        }
      });
  },

  downloadXlsx: id => dispatch => {
    return reportsApi
      .downloadXlsx(id)
      .then(response => {
        if (response.ok) {
          let a = document.createElement('a');
          a.href = response.data;
          a.download = 'raport.xlsx';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        } else {
          dispatch(notificationActions.showError('Wystąpił problem podczas pobierania raportu'));
          return logger.error(response.data);
        }
      });
  }
};
