import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {actions, excludeFiltersForRides} from '../../../reducers/reports/rides/actions';
import { actions as bikeTypesActions } from '../../../actions/bikeTypesActions';
import ReportTable from '../../common/reportTable/ReportTable';
import FiltersPanel from './FiltersPanel';
import { getColumns } from './Columns';
import './styles.scss';
import { permissions, hasPermission } from '../../../utils/authorization';

class RidesReportsPage extends Component {
  constructor(props) {
    super(props);
    this.handleFiltersChange = this.handleFiltersChange.bind(this);
    this.handleSortChange = this.handleSortChange.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
    this.handleExportToSpreadsheet = this.handleExportToSpreadsheet.bind(this);
    this.refreshSummaryHandler = this.refreshSummaryHandler.bind(this);
  }

  componentDidMount() {
    const rides = this.props.rides;
    this.props.bikeTypesActions.loadBikeTypes();
    this.props.actions.loadFreshRideReports(1, rides.paging.pageSize, rides.sortedBy, excludeFiltersForRides(rides.filteredBy));
  }


  handleSortChange(sortBy) {
    const rides = this.props.rides;
    this.props.actions.loadFreshRideReports(1, rides.paging.pageSize, sortBy, excludeFiltersForRides(rides.filteredBy));
  }

  handleFiltersChange(filterBy) {
    const rides = this.props.rides;
    this.props.actions.loadFreshRideReports(1, rides.paging.pageSize, rides.sortedBy, excludeFiltersForRides(filterBy.filter(f => f.value != null && f.value != '')));
  }

  handleExportToSpreadsheet() {
    const rides = this.props.rides;
    return this.props.actions.requestExcelExport(rides.sortedBy, excludeFiltersForRides(rides.filteredBy));
  }

  handlePageChange(nextPage) {
    const rides = this.props.rides;
    this.props.actions.loadNextRideReports(nextPage, excludeFiltersForRides(rides.filteredBy));
  }

  handlePageSizeChange(nextPageSize) {
    const rides = this.props.rides;
    this.props.actions.loadFreshRideReports(1, nextPageSize, rides.sortedBy, excludeFiltersForRides(rides.filteredBy));
  }

  refreshSummaryHandler() {
    const rides = this.props.rides;
    this.props.actions.loadFreshRideSummaryReports(excludeFiltersForRides(rides.filteredBy));
}

  render() {
    const { rides, operatorPermissions, bikeTypes } = this.props;
    return (
      <div className="page">
        <div style={{ margin: 40, marginBottom: 0 }}>
          <FiltersPanel filteredBy={rides.filteredBy} onFilteredChange={this.handleFiltersChange} bikeTypes={bikeTypes} />
          <Paper style={{ padding: 16 }}>
            <ReportTable
              style={{ height: "calc(100vh - 300px)" }}
              columns={getColumns(hasPermission(operatorPermissions, permissions.clients), bikeTypes)}
              data={rides.records}
              filteredBy={rides.filteredBy}
              sortedBy={rides.sortedBy}
              paging={rides.paging}
              onFilteredChange={this.handleFiltersChange}
              onPageChange={this.handlePageChange}
              onPageSizeChange={this.handlePageSizeChange}
              onSortedChange={this.handleSortChange}
              showExportToSpreadsheet
              onExportToSpreadsheet={this.handleExportToSpreadsheet}
              loading={rides.loading}
              bikeTypes={bikeTypes}
            />
          </Paper>
        </div >
      </div >
    );
  }
}

RidesReportsPage.propTypes = {
  actions: PropTypes.object.isRequired,
  bikeTypesActions: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  operatorPermissions: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  loadingSummary: PropTypes.bool,
  isSummaryOldData: PropTypes.bool,
  summary: PropTypes.object,
  rides: PropTypes.object,
  bikeTypes: PropTypes.array.isRequired,
};

RidesReportsPage.defaultProps = {
  loading: false,
  loadingSummary: false,
  isSummaryOldData: true,
  rides: {},
  summary: {},
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    bikeTypesActions: bindActionCreators(bikeTypesActions, dispatch)
  };
}

function mapStateToProps(state) {
  const reportState = state.reports.rides;
  return {
    loading: reportState.loading,
    loadingSummary: reportState.loadingSummary,
    isSummaryOldData: reportState.isSummaryOldData,
    summary: reportState.summary,
    rides: reportState.pagedData,
    operatorPermissions: state.auth.permissions,
    bikeTypes: state.bikeTypes.list,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RidesReportsPage);
