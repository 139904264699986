import initialState from './initialState';
import { types } from '../actions/pricingActions';
import { PricingModel } from '../models/configuration/PricingModel';

export default function pricingReducer(state = initialState.pricing, action) {
  switch (action.type) {
    case types.START_PRICING_CREATION: {
      return { details: new PricingModel(), list: state.list }
    }
    case types.LOAD_PRICINGS_SUCCESS: {
      const { pricings } = action;
      const list = pricings.map(p => Object.assign(new PricingModel(), p));
      return { details: state.details, list }
    }
    case types.LOAD_PRICING_SUCCESS: {
      const { pricing } = action;
      const details = Object.assign(new PricingModel(), pricing);
      return { details, list: state.list }
    }
    case types.DELETE_PRICING_SUCCESS: {
      const { id } = action;
      return { details: state.details, list: state.list.filter(p => p.id != id) };
    }
    case types.UPDATE_PRICING_SUCCESS: {
      const { pricing } = action;
      return { details: pricing, list: state.list }
    }
    default:
      return state;
  }
}
