import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import FormTextField from '../../form/redux-form/FormTextField';
import { validate } from 'validate.js';

const validation = values => validate(
  values,
  { resolutionComments: { presence: { allowEmpty: false, message: 'Wprowadzenie notatki jest wymagane' } } },
  { fullMessages: false });

let ResolveDefectForm = ({ handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit}>
      <div>
        <Field
          name="resolutionComments"
          component={FormTextField}
          label="Notatka"
          fullWidth
          multiline
          rows={8}
          style={{ width: 400 }}
        />
      </div>
    </form>
  );
};

ResolveDefectForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
};

ResolveDefectForm = reduxForm({
  form: 'resolveDefect',
  validate: validation,
})(ResolveDefectForm);

export default ResolveDefectForm;
