import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { Button, Typography } from '@material-ui/core';

const styles = () => ({
  actionsContainer: {
    marginTop: 16,
    display: 'flex',
    justifyContent: 'space-between',
  },
  submitButton: {
    marginLeft: 10,
  }
});

const FormActions = ({ classes, disabled, pristine, invalid, submitting, cancelRoutePath, children, displayValidationError }) => (
  <div className={classes.actionsContainer}>
    <div>{children}</div>
    <div>
      {displayValidationError && !pristine && invalid ? <Typography color="error" variant="caption" style={{ display: "inline" }}>Sprawdź czy wszystkie wymagane pola zostały wypełnione</Typography> : null}
      {cancelRoutePath && <Button
        component={Link}
        to={cancelRoutePath}
        disabled={submitting}
      >
        Anuluj
      </Button>}
      <Button
        variant="contained"
        color="primary"
        type="submit"
        disabled={disabled || pristine || invalid || submitting}
        className={classes.submitButton}
      >
        Zapisz
      </Button>
    </div>
  </div>
);

FormActions.propTypes = {
  classes: PropTypes.object.isRequired,
  cancelRoutePath: PropTypes.string,
  children: PropTypes.node,
  pristine: PropTypes.bool,
  invalid: PropTypes.bool,
  submitting: PropTypes.bool,
  disabled: PropTypes.bool,
  displayValidationError: PropTypes.bool,
};

export default compose(withStyles(styles))(FormActions);
