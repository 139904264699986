import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions  from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import TopUpForm from './TopUpForm';

const TopUpDialog = ({ open, onSave, onCancel, dispatch, submitting }) => (
  <Dialog open={open}>
    <DialogTitle>Doładowanie portfela użytkownika</DialogTitle>
    <DialogContent>
      <p>W celu doładowania konta zostaniesz przekierowany na stronę płatności Bluemedia.</p>
      <TopUpForm onSubmit={onSave} />
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel} disabled={submitting}>
        Anuluj
      </Button>
      <Button onClick={() => dispatch(submit('topUp'))} disabled={submitting} color="secondary">
        Doładuj
      </Button>
    </DialogActions>
  </Dialog>
);

TopUpDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  dispatch: PropTypes.func,
  submitting: PropTypes.bool.isRequired,
};

export default connect()(TopUpDialog);
