import initialState from './initialState';
import Immutable from 'seamless-immutable';
import { types } from '../actions/operatorsActions';
import { OperatorListItemModel } from '../models/operators/OperatorListItemModel';
import { OperatorEditModel } from '../models/operators/OperatorEditModel';

export default function operatorsReducer(state = Immutable(initialState.operators), action) {
  switch (action.type) {
    case types.LOAD_OPERATORS_SUCCESS: {
      const { operators } = action;
      return Immutable.merge(state, {
        list: operators.map(
          o => new OperatorListItemModel(o.id, o.firstName, o.lastName, o.email, o.accountActivated, o.accountActivationDate, o.role, o.is2FAEnable))
      });
    }
    case types.START_OPERATOR_CREATION: {
      return Immutable.merge(state, { current: new OperatorEditModel() });
    }
    case types.LOAD_OPERATOR_SUCCESS: {
      const { operator } = action;

      const mutableList = Immutable.asMutable(state.list);
      mutableList[mutableList.findIndex(o => o.id === operator.id)] = new OperatorListItemModel(
        operator.id, operator.firstName, operator.lastName, operator.email, operator.accountActivated, operator.accountActivationDate, operator.role, operator.is2FAEnable);

      return Immutable.merge(
        state,
        {
          current: new OperatorEditModel(operator.id, operator.firstName, operator.lastName, operator.email, operator.role, operator.is2FAEnable),
          list: mutableList,
        });
    }
    case types.UPDATE_OPERATOR_SUCCESS: {
      const { id, operator } = action;

      const mutableList = Immutable.asMutable(state.list);
      mutableList[mutableList.findIndex(o => o.id === id)] = new OperatorListItemModel(
        id, operator.firstName, operator.lastName, operator.email, false, null, operator.role, operator.is2FAEnable);

      return Immutable.merge(
        state,
        {
          list: mutableList,
        });
    }
    case types.DELETE_OPERATOR_SUCCESS: {
      const { id } = action;
      return Immutable.merge(
        state,
        {
          list: state.list.filter(o => o.id !== id),
        });
    }
    default:
      return state;
  }
}
