import initialState from './initialState';
import Immutable from 'seamless-immutable';
import { types } from '../actions/bikeDefectsActions';
import { BikeDefectDetailsModel } from '../models/bikeDefects/BikeDefectDetailsModel';
import { DefectStatus } from '../models/bikeDefects/DefectStatus';

export default function bikeDefectDetailsReducer(state = Immutable(initialState.bikeDefects.details), action) {
  switch (action.type) {
    case types.LOAD_BIKE_DEFECT_DETAILS: {
      return Immutable.merge(state, { loading: true });
    }
    case types.LOAD_BIKE_DEFECT_DETAILS_SUCCESS: {
      return Immutable.merge(state, {
        loading: false,
        details: new BikeDefectDetailsModel(action.result),
      });
    }
    case types.RESOLVE_DEFECT_SUCCESS: {
      const newDetails = new BikeDefectDetailsModel(state.details);
      newDetails.resolvedAt = Date();
      newDetails.status = DefectStatus.resolved;
      newDetails.resolutionComments = action.result.resolutionComments;
      newDetails.operatorLocation = action.result.operatorLocation;
      newDetails.resolvedBy = { firstName: action.currentUser.firstName, lastName: action.currentUser.lastName };
      return Immutable.merge(state, {
        details: newDetails,
      });
    }
    case types.RESOLVE_DEFECT_WITH_PARTS_SUCCESS: {
      const newDetails = new BikeDefectDetailsModel(state.details);
      newDetails.resolvedAt = Date();
      newDetails.status = DefectStatus.resolvedWithParts;
      newDetails.resolutionComments = action.result.resolutionComments;
      newDetails.operatorLocation = action.result.operatorLocation;
      newDetails.partsList = action.result.partsList;
      newDetails.resolvedBy = { firstName: action.currentUser.firstName, lastName: action.currentUser.lastName };
      return Immutable.merge(state, {
        details: newDetails,
      });
    }
    case types.REJECT_DEFECT_SUCCESS: {
      const newDetails = new BikeDefectDetailsModel(state.details);
      newDetails.resolvedAt = Date();
      newDetails.status = DefectStatus.rejected;
      newDetails.operatorLocation = action.result.operatorLocation;
      newDetails.resolvedBy = { firstName: action.currentUser.firstName, lastName: action.currentUser.lastName };
      return Immutable.merge(state, {
        details: newDetails,
      });
    }
    default:
      return state;
  }
}
