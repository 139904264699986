import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import DenyListDialog from './DenyListDialog';

class AddToDenyListButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = { dialogVisible: false, submitting: false };
  }

  handleDialogCancel = () => this.setState({ dialogVisible: false });

  handleOpenDialogClick = () => this.setState({ dialogVisible: true });

  renderOpenDialogButton() {
    return (
      <Button
        onClick={this.handleOpenDialogClick}
        variant="outlined"
        size="small">
        Dodaj do czarnej listy
      </Button>
    );
  }

  handleSubmit = data => {
    this.setState({ dialogVisible: true, submitting: true });
    this.props.onAddToDenyList(data).finally(() => this.setState({ dialogVisible: false, submitting: false }));
  }

  render() {
    return (
      <div>
        {this.renderOpenDialogButton()}
        <DenyListDialog
          submitting={this.state.submitting}
          open={this.state.dialogVisible}
          onCancel={this.handleDialogCancel}
          onSave={this.handleSubmit}
        />
      </div>
    );
  }
}

AddToDenyListButton.propTypes = {
  userId: PropTypes.string.isRequired,
  onAddToDenyList: PropTypes.func.isRequired,
};

export default AddToDenyListButton;
