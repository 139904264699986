import initialState from './initialState';
import Immutable from 'seamless-immutable';
import _ from 'lodash';
import { types } from '../actions/zoneTypesActions';
import { ZoneTypeModel } from '../models/zoneTypes/ZoneTypeModel';

export default function zoneTypesReducer(state = Immutable(initialState.zoneTypes), action) {
  switch (action.type) {
    case types.GET_ALL_ZONETYPES_REQUEST_SUCCESS: {
      const { zoneTypes } = action;
      return Immutable.merge(state, {
        list: _.sortBy(zoneTypes, ['name']).map(bt => Immutable(new ZoneTypeModel(
          bt.id, bt.name, bt.markerIconUri)))
      });
    }
    case types.GET_ZONETYPE_REQUEST_SUCCESS: {
      const { zoneType } = action;
      return Immutable.merge(
        state,
        {
          current: Immutable(new ZoneTypeModel(
            zoneType.id, zoneType.name, zoneType.markerIconUri)),
        });
    }
    case types.START_ZONETYPE_CREATION: {
      return Immutable.merge(
        state,
        {
          current: Immutable(new ZoneTypeModel()),
          list: state.list,
        });
    }
    case types.DELETE_ZONETYPE_REQUEST_SUCCESS: {
      const { zoneTypeId } = action;
      return Immutable.merge(
        state,
        {
          list: state.list.filter(zt => zt.id !== zoneTypeId),
        });
    }
    default:
      return state;
  }
}
