import React, { useEffect } from 'react';
import { string } from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../actions/bikesManagementActions';
import { Button, CircularProgress, makeStyles, Typography } from '@material-ui/core';
import moment from 'moment';
import BikeServiceConfirmationDialog from './BikeServiceConfirmationDialog';
import { permissions, useHasPermission } from '../../utils/authorization';

const useStyles = makeStyles({
  table: {
    minWidth: 500,
  },
});

const BikeServiceHistoryView = ({ bikeId }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  useEffect(() => { dispatch(actions.loadBikeServiceHistory(bikeId)) }, [bikeId]);
  const loading = useSelector(s => s.bikeServiceHistory.fetching);
  const history = useSelector(s => s.bikeServiceHistory.data);
  const hasConfirmPermission = useHasPermission(permissions.bikes.service.confirm);

  if (loading) return (<div style={{ width: '100%', textAlign: 'center' }}>
    <CircularProgress size={50} />
  </div>);

  return (
    <>
      <div style={{ textAlign: 'right', marginBottom: 16 }}>
        {
          hasConfirmPermission &&
          <BikeServiceConfirmationDialog bikeId={bikeId}>
            <Button variant='outlined' color='secondary'>Potwierdź wykonanie serwisu</Button>
          </BikeServiceConfirmationDialog>
        }
      </div>
      <Typography variant="h5" gutterBottom>Historia serwisu</Typography>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Data</TableCell>
            <TableCell>Notatka</TableCell>
            <TableCell>Operator</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {history.map((row, i) => (
            <TableRow key={i}>
              <TableCell>{moment(row.time).local().format("DD.MM.YYYY")}</TableCell>
              <TableCell>{row.note}</TableCell>
              <TableCell>{row.operatorName}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

BikeServiceHistoryView.propTypes = {
  bikeId: string.isRequired,
}

export default BikeServiceHistoryView;
