import { LocationModel } from "./LocationModel";
import bikeAvailableIcon from "../assets/bikeAvailableIcon.png";
import bikeRentedIcon from "../assets/bikeRentedIcon.png";

export const BikeHistoryItemType = {
  serviceConfirmed: {
    value: "serviceConfirmed",
    name: "Potwierdzenie serwisu",
  },
  bikeOutOfService: { value: "bikeOutOfService", name: "Wyłączenie z użytku" },
  bikeInService: { value: "bikeInService", name: "Przywrócenie do użytku" },
  defectResolved: { value: "defectResolved", name: "Zamknięcie usterki" },
  defectRejected: { value: "defectRejected", name: "Odrzucenie usterki" },
  defectResolvedWithParts: {
    value: "defectResolvedWithParts",
    name: "Zamknięcie usterki z wymianą",
  },
  bikeNoteAdded: { value: "bikeNoteAdded", name: "Dodanie notatki" },
};

export class BikeModel {
  constructor(data) {
    if (data) {
      validate(data.id, "id");
      validate(data.bikeNumber, "bikeNumber");
    }
    data = data || {};
    this.id = data.id;
    this.bikeId = data.bikeId;
    this.bikeNumber = data.bikeNumber;
    this.bikeSerialNumber = data.bikeSerialNumber;
    this.location = data.location
      ? new LocationModel(data.location.lat, data.location.lng)
      : null;
    this.lockImei = data.lockImei;
    this.lockBatteryLevel = data.lockBatteryLevel;
    this.bikeBatteryLevel =
      data.bikeBatteryLevel != null &&
      data.bikeBatteryLevel >= 0 &&
      data.bikeBatteryLevel <= 100
        ? data.bikeBatteryLevel
        : null;
    this.insideOperationsZone = data.insideOperationsZone;
    this.lockTimestamp = data.lockTimestamp;
    this.gpsTimestamp = data.gpsTimestamp;
    this.satellites = data.satellites;
    this.isAvailableForRental = data.isAvailableForRental;
    this.isRentalRestricted = data.isRentalRestricted;
    this.rentalRestrictionReason = data.rentalRestrictionReason;
    this.isReserved = data.isReserved;
    this.isRented = data.isRented;
    this.hasAlarmEnabled = data.hasAlarmEnabled;
    this.isOrientedUpright = data.isOrientedUpright;
    this.bikeIcon = data.icon
      ? data.icon
      : this.isAvailableForRental
        ? bikeAvailableIcon
        : bikeRentedIcon;
    this.internalUseOnly = data.internalUseOnly;
    this.ongoingPause = data.ongoingPause;
    this.ongoingPauseDate = data.ongoingPauseDate;
    this.lastRideId = data.lastRideId;
    this.lockPhoneNumber = data.lockPhoneNumber;
    const lockState = data.lockState
      ? data.lockState === "closed"
        ? "0"
        : "1"
      : "-";
    const shackleState = data.shackleState
      ? data.shackleState === "closed"
        ? "0"
        : "1"
      : "-";
    this.lockShackleState = `${lockState}${shackleState}`;
    this.openDefectsCount = data.openDefectsCount;
    this.alerts = data.alerts || [];
    this.lastServiceTime = data.lastServiceTime || null;
    this.nextServiceTime = data.nextServiceTime;
    this.serviceDue = data.serviceDue;
    this.lockDeviceType = data.lockDeviceType;
    this.bikeTypeName = data.typeName;
  }

  static getBikeStatusDescription(bike) {
    const statuses = [];
    if (bike.isAvailableForRental) statuses.push("dostępny");
    if (bike.isRented) statuses.push("wypożyczony");
    if (bike.ongoingPause) statuses.push("pauza");
    if (bike.isReserved) statuses.push("zarezerwowany");
    if (bike.isRentalRestricted)
      statuses.push("chwilowo wycofany z użytkowania");
    return statuses.join(", ");
  }
}

const validate = (value, name, model = "BikeModel", isInvalid = (v) => !v) => {
  if (isInvalid(value)) {
    /*eslint no-console:0*/
    console.error(`${model} requires ${name}`);
  }
};
