import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import numberFormatter from '../../../utils/numberFormatter';
import durationFormatter from '../../../utils/durationFormatter';
import './styles.scss';
import RefreshIcon from "@material-ui/icons/Refresh";
import Button from "@material-ui/core/Button";
import {CircularProgress, Tooltip} from "@material-ui/core";


const SummaryPanel = ({ summary, showPaymentsSummaries, refreshHandler, isLoading, isSummaryOldData }) => {
  return (
    <Paper className="summary-panel">
      <div className="flex-row flex-wrap">
        <div className="flex-column justify-space-between margin-right-large">
          <Typography color="textSecondary">Łączna liczba przejazdów</Typography>
          <Typography className={isSummaryOldData ? 'text-disabled' : null}>{summary.ridesCount}</Typography>
        </div>
        <div className="flex-column justify-space-between margin-right-large">
          <Typography color="textSecondary">Średnia ocen</Typography>
          <Typography className={isSummaryOldData ? 'text-disabled' : null}>{summary.averageRating}</Typography>
        </div>
        <div className="flex-column justify-space-between margin-right-large">
          <Typography color="textSecondary">Łączny czas</Typography>
          <Typography
            className={isSummaryOldData ? 'text-disabled' : null}>{durationFormatter.format(summary.rideDuration)}</Typography>
        </div>
        <div className="flex-column justify-space-between margin-right-large">
          <Typography color="textSecondary">Łączny dystans</Typography>
          <Typography
            className={isSummaryOldData ? 'text-disabled' : null}>{numberFormatter.format(summary.traceLength)} km</Typography>
        </div>
        <div className="flex-column justify-space-between margin-right-large">
          <Typography color="textSecondary">Średnia prędkość</Typography>
          <Typography
            className={isSummaryOldData ? 'text-disabled' : null}>{numberFormatter.format(summary.averageSpeed)} km/h</Typography>
        </div>
        {showPaymentsSummaries &&
          <React.Fragment>
            < div className="flex-column justify-space-between margin-right-large">
              <Typography color="textSecondary">Suma rachunków</Typography>
              <Typography
                className={isSummaryOldData ? 'text-disabled' : null}>{numberFormatter.format(summary.finalFare)} zł</Typography>
            </div>
            <div className="flex-column justify-space-between margin-right-large">
              <Typography color="textSecondary">Suma pozostałych do zapłaty</Typography>
              <Typography
                className={isSummaryOldData ? 'text-disabled' : null}>{numberFormatter.format(summary.outstandingPayment)} zł</Typography>
            </div>
          </React.Fragment>
        }
        <div className="flex-column">
          <Tooltip title="Odśwież statystyki">
            <Button
              variant="contained"
              color="primary"
              onClick={refreshHandler}
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={25} color="black"/> : <RefreshIcon/>}
            </Button>
          </Tooltip>
        </div>
      </div>
    </Paper>
  );
}

SummaryPanel.propTypes = {
  summary: PropTypes.object,
  showPaymentsSummaries: PropTypes.bool.isRequired,
  refreshHandler: () => {
  },
  isLoading: PropTypes.bool,
  isSummaryOldData: PropTypes.bool.isRequired
};

SummaryPanel.defaultProps = {
  isLoading: false,
}

export default SummaryPanel;
