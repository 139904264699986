import initialState from './initialState';
import Immutable from 'seamless-immutable';
import { types } from '../actions/bikesActions';
import { types as managementTypes } from '../actions/bikesManagementActions';
import { BikeModel } from '../models/BikeModel';

export default function bikesReducer(state = Immutable(initialState.bikes), action) {
  switch (action.type) {
    case types.GET_ALL_BIKES_REQUEST_SUCCESS: {
      const { bikes } = action;
      return [...bikes.map(b => new BikeModel(b))];
    }
    case managementTypes.REGISTER_NEW_BIKE_REQUEST_SUCCESS: {
      const { bike } = action;
      return [...state, bike];
    }
    case managementTypes.DECOMMISSION_BIKE_REQUEST_SUCCESS: {
      const { bikeId } = action;
      return [...state.filter(b => b.id !== bikeId)];
    }
    case managementTypes.PUT_BIKE_OUT_OF_SERVICE_SUCCESS: {
      const { bikeId, restrictionReason } = action;

      const bikeIndex = state.findIndex(b => b.id === bikeId);
      const bikeData = state[bikeIndex];

      return [
        ...state.slice(0, bikeIndex),
        new BikeModel(Object.assign({}, bikeData, {
          isAvailableForRental: false,
          isRentalRestricted: true,
          rentalRestrictionReason: restrictionReason,
        })),
        ...state.slice(bikeIndex + 1, state.length)];
    }
    case managementTypes.CONFIRM_BIKE_SERVICE_SUCCESS: {
      const { bikeId, time, nextTime } = action;

      const bikeIndex = state.findIndex(b => b.id === bikeId);
      const bikeData = state[bikeIndex];

      return [
        ...state.slice(0, bikeIndex),
        new BikeModel(Object.assign({}, bikeData, {
          lastServiceTime: time,
          nextServiceTime: nextTime,
          serviceDue: false,
        })),
        ...state.slice(bikeIndex + 1, state.length)];
    }
    case managementTypes.PUT_BIKE_IN_SERVICE_SUCCESS: {
      const { bikeId } = action;

      const bikeIndex = state.findIndex(b => b.id === bikeId);
      const bikeData = state[bikeIndex];

      return [
        ...state.slice(0, bikeIndex),
        new BikeModel(Object.assign({}, bikeData, {
          isAvailableForRental: !bikeData.isRented && !bikeData.isReserved,
          isRentalRestricted: false,
          rentalRestrictionReason: null,
        })),
        ...state.slice(bikeIndex + 1, state.length)];
    }
    case managementTypes.DEACTIVATE_ALARM_SUCCESS: {
      const { bikeId } = action;

      const bikeIndex = state.findIndex(b => b.id === bikeId);
      const bikeData = state[bikeIndex];

      return [
        ...state.slice(0, bikeIndex),
        new BikeModel(Object.assign({}, bikeData, {
          hasAlarmEnabled: false,
        })),
        ...state.slice(bikeIndex + 1, state.length)];
    }
    default:
      return state;
  }
}
