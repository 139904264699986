
import React from 'react';
import TextFilter from '../../../common/reportTable/filters/TextFilter';
import moment from 'moment';
import AccessTimeIcon from '@material-ui/icons/FindInPage';
import IconButton from '@material-ui/core/IconButton';
import { Link } from 'react-router-dom';
import routePaths from '../../../../routePaths';

const styles = {
  deleted: {
    color: '#ccc',
  },
  locked: {
    color: '#ff8f00',
  },
};


/* eslint react/display-name: 0 */
/* eslint react/prop-types: 0 */
/* eslint react/no-multi-comp: 0 */
export const columns = [
  {
    Header: '',
    id: 'show_details',
    Cell: row => (
      <Link to={`${routePaths.clientsReport}/${row.original.userId}/history`}>
        <IconButton style={{ height: 24, width: 24, margin: "-3px 3px" }}>
          <AccessTimeIcon />
        </IconButton>
      </Link>),
    width: 50,
    sortable: false,
    Filter: () => null,
  },
  {
    Header: 'Imię',
    id: 'firstName',
    accessor: a => <span style={styles[a.userStatus]}>{a.firstName}</span>,
    width: 180,
    Filter: ({ filter, onChange }) => <TextFilter onChange={onChange} filter={filter} label="filtruj..." />,
  },
  {
    Header: 'Nazwisko',
    id: 'lastName',
    accessor: a => <span style={styles[a.userStatus]}>{a.lastName}</span>,
    width: 180,
    Filter: ({ filter, onChange }) => <TextFilter onChange={onChange} filter={filter} label="filtruj..." />,
  },
  {
    Header: 'Nr telefonu',
    id: 'phoneNumber',
    accessor: a => <span style={styles[a.userStatus]}>{a.phoneNumber}</span>,
    width: 150,
    Filter: ({ filter, onChange }) => <TextFilter onChange={onChange} filter={filter} label="filtruj..." />,
  },
  {
    Header: 'Email',
    id: 'email',
    accessor: a => <span style={styles[a.userStatus]}>{a.email}</span>,
    width: 180,
    Filter: ({ filter, onChange }) => <TextFilter onChange={onChange} filter={filter} label="filtruj..." />,
  },
  {
    Header: 'Data rejestracji',
    id: 'registeredAt',
    accessor: d => <span style={styles[d.userStatus]}>{d.registeredAt ? moment(d.registeredAt).local().format('DD.MM.YYYY HH:mm') : null}</span>,
    width: 150,
    Filter: () => null,
  },
  {
    Header: 'Data dodania',
    id: 'accessDeniedInfo.deniedAt',
    accessor: d => <span style={styles[d.userStatus]}>{d.accessDeniedInfo ? moment(d.accessDeniedInfo.deniedAt).local().format('DD.MM.YYYY HH:mm') : null}</span>,
    width: 150,
    sortable: false,
    filterable: false,
  },
  {
    Header: 'Powód',
    id: 'accessDeniedInfo.reason',
    accessor: d => <span style={styles[d.userStatus]}>{d.accessDeniedInfo ? d.accessDeniedInfo.reason : null}</span>,
    width: 150,
    sortable: false,
    filterable: false,
  },
  {
    Header: 'Dodał',
    id: 'accessDeniedInfo.operator.lastName',
    accessor: d => <span style={styles[d.userStatus]}>{d.accessDeniedInfo ? `${d.accessDeniedInfo.operator.firstName} ${d.accessDeniedInfo.operator.lastName}` : null}</span>,
    width: 150,
    sortable: false,
    filterable: false,
  },
];
