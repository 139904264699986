import React from 'react';
import PropTypes from 'prop-types';
import { KeyboardDatePicker } from '@material-ui/pickers';

const FormDateField = ({
  input,
  label,
  disableFuture,
  meta: { touched, error },
  minDate,
}) => (
    <KeyboardDatePicker
      label={label}
      error={touched && error ? true : false}
      helperText={touched && error}
      margin="dense"
      minDate={minDate}
      disableFuture={disableFuture}
      InputLabelProps={{
        shrink: true,
      }}
      autoOk
      okLabel="OK"
      cancelLabel="Anuluj"
      clearLabel="Wyczyść"
      invalidLabel="Wybierz ..."
      onChange={value => input.onChange(value)}
      format="DD.MM.YYYY"
      value={input.value ? input.value.toDate() : undefined}
    />
  );

FormDateField.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  meta: PropTypes.object,
  minDate: PropTypes.object,
  disableFuture: PropTypes.bool,
};

export default FormDateField;
