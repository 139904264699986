import { ReportTableModel } from '../ReportTableModel';
import { DefectStatus } from './DefectStatus';

export class BikeDefectRecordModel {
  constructor(data) {
    this.id = data.id;
    this.reportedBy = data.reportedBy;
    this.bikeId = data.bikeId;
    this.bikeNumber = data.bikeNumber;
    this.brokenParts = data.brokenParts || [];
    this.rideId = data.rideId;
    this.reportedAtLocation = data.reportedAtLocation;
    this.bikeLocation = data.bikeLocation;
    this.reportedAt = data.reportedAt;
    this.resolvedAt = data.resolvedAt;
    this.resolvedAfter = data.resolvedAfter;
    this.resolvedBy = data.resolvedBy;
    this.resolutionComments = data.resolutionComments;
    this.status = DefectStatus[data.status];
    this.timeLeft = data.timeLeft;
  }
}

export class BikeDefectsModel {
  constructor() {
    this.loading = false;
    this.pagedData = new ReportTableModel({ pageNumber: 1, pageSize: 100 },  [{ id: 'reportedAt', value: 'desc' }], [{ id: 'status', value: DefectStatus.open.value }]);
  }
}
