import React from 'react';
import moment from 'moment';
import OpenIcon from '@material-ui/icons/FindInPage';
import IdentityIcon from '@material-ui/icons/PermIdentity';
import SelectFilter from '../../common/reportTable/filters/SelectFilter';
import { Link } from 'react-router-dom';
import routePaths from '../../../routePaths';
import numberFormatter from '../../../utils/numberFormatter';
import { TransactionType, Status } from '../../../reducers/reports/clientWallet/models';

/* eslint react/display-name: 0 */
/* eslint react/prop-types: 0 */
/* eslint react/no-multi-comp: 0 */
export function columns(onOpenClientData, userDeleted) {
  return [
    {
      Header: 'Data',
      id: 'date',
      accessor: x => moment(x.date).local().format('DD.MM.YYYY HH:mm:ss'),
      width: 140,
      Filter: () => null,
      sortable: false,
    },
    {
      Header: 'Kwota',
      id: 'amount',
      accessor: d => `${numberFormatter.format(d.amount || 0)} zł`,
      width: 100,
      Filter: () => null,
      sortable: false,
    },
    {
      Header: 'Typ operacji',
      id: 'type',
      accessor: x => x.accountingOnly ? TransactionType[x.type].accountingOnlyName : TransactionType[x.type].name,
      Filter: ({ filter, onChange }) => <SelectFilter onChange={onChange} filter={filter} items={TransactionType} label="filtruj..." />,
      width: 185,
      sortable: false,
    },
    {
      Header: 'Opłata za przejazd',
      id: 'paidForRideId',
      Cell: row => row.original.paidForRideId
        ? row.original.ourTenant
          ? <Link to={`${routePaths.ridesReports}/${row.original.paidForRideId}`} style={{ display: 'flex', alignItems: 'center' }}><OpenIcon style={{ fontSize: 16 }} /> Otwórz</Link>
          : `Inny operator (${row.original.toTenant})`
        : null,
      Filter: () => null,
      width: 180,
      sortable: false,
    },
    {
      Header: 'Opłata za plan',
      id: 'paidForPlanId',
      Cell: row => row.original.paidForPlanId
        ? row.original.ourTenant
          ? <Link to={`${routePaths.plans}/${row.original.paidForPlanId}`} style={{ display: 'flex', alignItems: 'center' }}><OpenIcon style={{ fontSize: 16 }} /> Otwórz</Link>
          : `Inny operator (${row.original.toTenant})`
        : null,
      Filter: () => null,
      width: 180,
      sortable: false,
    },
    {
      Header: 'Status doładowania',
      id: 'status',
      accessor: x => x.status ? Status[x.status.toLowerCase()].name : null,
      Filter: () => null,
      width: 120,
      sortable: false,
    },
    {
      Header: 'Bluemedia OrderId',
      id: 'bluemediaOrderId',
      Cell: row => row.original.bluemediaOrderId
        ? row.original.customerData != null && !userDeleted
          ? <span style={{ display: 'flex', alignItems: 'center' }}>
            <IdentityIcon onClick={() => onOpenClientData(row.original.customerData)} style={{ fontSize: 16, cursor: 'hand' }} />
            {row.original.bluemediaOrderId}
          </span>
          : <span>{row.original.bluemediaOrderId}</span>
        : null,
      Filter: () => null,
      width: 230,
      sortable: false,
    },
    {
      Header: 'Operator',
      id: 'operator',
      Cell: row => row.original.createdByOperator ||
          (row.original.withdrawnByOperator && row.original.withdrawnByOperator.id !== '00000000-0000-0000-0000-000000000000')
        ? <Link
          to={`${routePaths.operators}/${row.original.createdByOperator ? row.original.createdByOperator.id : row.original.withdrawnByOperator.id}`}
          style={{ display: 'flex', alignItems: 'center' }}>
          <OpenIcon style={{ fontSize: 16 }} /> Otwórz
        </Link>
        : row.original.withdrawnByOperator && row.original.withdrawnByOperator.id === '00000000-0000-0000-0000-000000000000' ?
            'Wypłata automatyczna' : null,
      Filter: () => null,
      width: 230,
      sortable: false,
    },
  ];
}
