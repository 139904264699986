import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import FormTextField from '../form/redux-form/FormTextField';
import FormFileUpload from '../form/redux-form/FormFileUpload';
import validate from 'validate.js';

export const formValidation = values => {
  const constraints = {
    name: {
      presence: { message: 'Nazwa jest wymagana' },
    },
    kmlFile: {
      presence: { message: 'Plik ze strefą jest wymagany' },
    }
  };

  return validate(values, constraints, { fullMessages: false });
};


let ImportZoneForm = ({ handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit}>
      <div>
        <Field
          name="name"
          type="text"
          component={FormTextField}
          label="Nazwa strefy"
          fullWidth
        />
        <Field
          type="file"
          name="kmlFile"
          component={FormFileUpload}
          mimeFilter={'.kml,application/vnd.google-earth.kml+xml'}
          label="Plik KML ze współrzędnymi"
        />
      </div>
    </form>
  );
};

ImportZoneForm.propTypes = {
  zoneTypes: PropTypes.array,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  zoneType: PropTypes.string,
};

ImportZoneForm = reduxForm({
  form: 'zoneKmlImport',
  validate: formValidation,
})(ImportZoneForm);

export default ImportZoneForm;
