import { IconButton, ListItemIcon, Menu, MenuItem, Typography } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ServiceIcon from '@material-ui/icons/Build';
import { actions } from '../../../actions/bikesManagementActions';
import { hasPermission, permissions } from '../../../utils/authorization';
import LockCommandsButton from '../../bikesManagement/LockCommandsButton';
import ConfirmationDialog from '../../common/ConfirmationDialog';
import EnableBikeIcon from '@material-ui/icons/CheckCircleOutline';
import DisableBikeIcon from '@material-ui/icons/HighlightOff';
import HistoryIcon from '@material-ui/icons/History';
import PutBikeOutOfServiceDialog from '../../bikesManagement/PutBikeOutOfServiceDialog';
import BikeServiceConfirmationDialog from '../../bikesManagement/BikeServiceConfirmationDialog';
import RefreshIcon from '@material-ui/icons/Refresh';
import WarningIcon from '@material-ui/icons/Warning';
import { instanceOf } from 'prop-types';
import { BikeModel } from '../../../models/BikeModel';
import { Link } from 'react-router-dom';
import routePaths from '../../../routePaths';

const BikeListActionsMenu = ({ bike }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const userPermissions = useSelector(s => s.auth.permissions);
  const dispatch = useDispatch();
  const [putBikeOutOfServiceDialog, setPutBikeOutOfServiceDialog] = useState({ visible: false, submitting: false });

  const handleShowPutBikeOutOfServiceDialog = () => {
    setAnchorEl(null);
    setPutBikeOutOfServiceDialog({ visible: true, submitting: false });
  }
  const handlePutBikeOutOfService = data => dispatch(actions.putBikeOutOfService(bike.id, data.reason))
    .then(() => setPutBikeOutOfServiceDialog({ visible: false, submitting: false }));
  const handlePutBikeOutOfServiceCancel = () => setPutBikeOutOfServiceDialog({ visible: false, submitting: false });
  const handlePutBikeInService = () => dispatch(actions.putBikeInService(bike.id));

  const handleSendCustomCommand = (command) => dispatch(actions.sendCustomLockCommand(bike.id, command, bike.lockDeviceType)).then(() => { setAnchorEl(null); });
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const handleReportLockStatus = () => {
    setAnchorEl(null);
    dispatch(actions.sendReportLockStatusCommand(bike.id));
  }

  const renderPutBikeOutOfServiceDialog = () => (
    <PutBikeOutOfServiceDialog
      open={putBikeOutOfServiceDialog.visible}
      onSave={handlePutBikeOutOfService}
      onCancel={handlePutBikeOutOfServiceCancel}
      submitting={putBikeOutOfServiceDialog.submitting}
    />
  );

  return (
    <>
      <div>
        <IconButton aria-controls="actions-menu" aria-haspopup="true" onClick={handleClick}>
          <MenuIcon />
        </IconButton>
        <Menu
          id="actions-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {
            hasPermission(userPermissions, permissions.bikes.sendLockComands)
              ? <LockCommandsButton onBeforeShow={handleClose} onSelect={command => handleSendCustomCommand(command)} lockDeviceType={bike.lockDeviceType} />
              : null
          }

          {bike.isRentalRestricted
            ? <ConfirmationDialog
              onBeforeShow={handleClose}
              confirmationTitle="Przywróć rower do użytkowania"
              confirmationText={`Czy na pewno chcesz przywrócić rower ${bike.bikeNumber} do użytkowania?`}
              onConfirm={handlePutBikeInService}
              onCancel={() => { }}
            >
              <MenuItem>
                <ListItemIcon>
                  <EnableBikeIcon />
                </ListItemIcon>
                <Typography variant="inherit">Przywróć rower do użytkowania</Typography>
              </MenuItem>
            </ConfirmationDialog>
            : <MenuItem onClick={handleShowPutBikeOutOfServiceDialog}>
              <ListItemIcon>
                <DisableBikeIcon />
              </ListItemIcon>
              <Typography variant="inherit">Tymczasowo wyłącz rower z użytkowania</Typography>
            </MenuItem>
          }
          {
            hasPermission(userPermissions, permissions.bikes.requestTelemetry)
              ? <MenuItem onClick={handleReportLockStatus}>
                <ListItemIcon>
                  <RefreshIcon />
                </ListItemIcon>
                <Typography variant="inherit">Wyślij żądanie odświeżenia danych</Typography>
              </MenuItem>
              : null}
          {
            hasPermission(userPermissions, permissions.bikes.alerts) && bike.alerts.length > 0 &&
            <Link to={`${routePaths.bikes}/${bike.id}#alerts`}>
              <MenuItem>
                <ListItemIcon>
                  <WarningIcon />
                </ListItemIcon>
                <Typography variant="inherit">Alerty</Typography>
              </MenuItem>
            </Link>
          }
          {
            hasPermission(userPermissions, permissions.bikes.service.confirm) &&
            <BikeServiceConfirmationDialog bikeId={bike.id} onBeforeShow={handleClose}>
              <MenuItem>
                <ListItemIcon>
                  <ServiceIcon />
                </ListItemIcon>
                <Typography variant="inherit">Potwierdź wykonanie serwisu</Typography>
              </MenuItem>
            </BikeServiceConfirmationDialog>
          }
          {
            hasPermission(userPermissions, permissions.bikes.log) &&
            <Link to={`${routePaths.bikes}/${bike.id}#log`}>
              <MenuItem>
                <ListItemIcon>
                  <HistoryIcon />
                </ListItemIcon>
                <Typography variant="inherit">Historia roweru</Typography>
              </MenuItem>
            </Link>
          }
        </Menu>
      </div >
      {renderPutBikeOutOfServiceDialog()}
    </>
  );
}

BikeListActionsMenu.propTypes = {
  bike: instanceOf(BikeModel).isRequired,
}

export default BikeListActionsMenu;
