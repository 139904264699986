import React, {useEffect, useMemo} from 'react';
import PricingDetailsForm from "../pricing/PricingDetailsForm";
import {hasPermission, permissions} from "../../utils/authorization";
import {actions} from "../../actions/cardsActions";
import {actions as pricingActions} from "../../actions/pricingActions";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import Paper from "@material-ui/core/Paper";


const PricingCardPage = () => {

  const params = useParams();
  const dispatch = useDispatch();

  const userPermissions = useSelector(state => state.auth.permissions);
  const cardsState = useSelector(state =>  state.cards)

  useEffect(() => {
    if(!cardsState.data && cardsState.fetching) {
      dispatch(actions.load())
    }
  }, [cardsState.data])

  const card = useMemo(() => {
    if(!cardsState.data) return null;
    if(!cardsState.data.configured) return null;
    return cardsState.data.configured.find(c => c.id === params.id)
  }, [cardsState.data, params.id]);

  const readonly = hasPermission(userPermissions, permissions.pricingConfiguration.access)
    && !hasPermission(userPermissions, permissions.pricingConfiguration.modify);

  const onSubmit = (data) => {
    dispatch(pricingActions.update(data))
  }

  if(cardsState.fetching)
    return (<div>da</div>)
  if(!card)
    return (<div className="page">
      <div style={{ margin: 40 }}>
        <Paper style={{ padding: 16, fontSize: 16 }}>
          Nie znaleziono karty
        </Paper>
      </div>
    </div>)

  return(
      <div className="page">
        <div style={{ margin: 40 }}>
          <Paper style={{ padding: 16, fontSize: 16 }}>
            Powiązanie z kartą miejską: <span style={{ fontWeight: "bold" }}>{card.cardProviderName}</span>
          </Paper>
          <PricingDetailsForm
            onSubmit={data => onSubmit(data)}
            readonly={readonly}
            isCard
            isPlan={false}
            fieldPrefix=""
            initialValues={{
              ...card.pricingConfiguration,
              cardId: params.id,
            }}
          />
        </div>
      </div>
  )

}

export default PricingCardPage;

