import reportsApi from '../../../api/reportsApi';

export const types = {
  LOAD_CLIENTS_REPORT: 'LOAD_CLIENTS_REPORT',
  LOAD_FRESH_CLIENTS_REPORT_SUCCESS: 'LOAD_FRESH_CLIENTS_REPORT_SUCCESS',
  LOAD_NEXT_CLIENTS_REPORT: 'LOAD_NEXT_CLIENTS_REPORT',
  LOAD_NEXT_CLIENTS_REPORT_SUCCESS: 'LOAD_NEXT_CLIENTS_REPORT_SUCCESS',

  LOAD_GLOBAL_CLIENTS_REPORT: 'LOAD_GLOBAL_CLIENTS_REPORT',
  CLEAR_GLOBAL_CLIENTS_REPORT: 'CLEAR_GLOBAL_CLIENTS_REPORT',
  LOAD_FRESH_GLOBAL_CLIENTS_REPORT_SUCCESS: 'LOAD_FRESH_GLOBAL_CLIENTS_REPORT_SUCCESS',
  LOAD_NEXT_GLOBAL_CLIENTS_REPORT: 'LOAD_NEXT_GLOBAL_CLIENTS_REPORT',
  LOAD_NEXT_GLOBAL_CLIENTS_REPORT_SUCCESS: 'LOAD_NEXT_GLOBAL_CLIENTS_REPORT_SUCCESS',

  LOAD_DENIED_CLIENTS_REPORT: 'LOAD_DENIED_CLIENTS_REPORT',
  LOAD_DENIED_FRESH_CLIENTS_REPORT_SUCCESS: 'LOAD_DENIED_FRESH_CLIENTS_REPORT_SUCCESS',
  LOAD_DENIED_NEXT_CLIENTS_REPORT: 'LOAD_DENIED_NEXT_CLIENTS_REPORT',
  LOAD_DENIED_NEXT_CLIENTS_REPORT_SUCCESS: 'LOAD_DENIED_NEXT_CLIENTS_REPORT_SUCCESS',
};

export const actions = {
  loadFreshClientReports: (pageNumber, pageSize, sortBy, filterBy) => (dispatch) => {
    dispatch({ type: types.LOAD_CLIENTS_REPORT, pageNumber, pageSize, sortBy, filterBy });
    return reportsApi.getClients(pageNumber, pageSize, sortBy, filterBy).then(res => {
      if (res.ok) {
        dispatch({ type: types.LOAD_FRESH_CLIENTS_REPORT_SUCCESS, result: res.data || {} });
      }
    });
  },

  loadNextClientReports: (pageNumber) => (dispatch, getState) => {
    dispatch({ type: types.LOAD_NEXT_CLIENTS_REPORT, pageNumber });
    const sortBy = getState().reports.clients.pagedData.sortedBy;
    const filterBy = getState().reports.clients.pagedData.filteredBy;
    const pageSize = getState().reports.clients.pagedData.paging.pageSize;
    return reportsApi.getClients(pageNumber, pageSize, sortBy, filterBy).then(res => {
      if (res.ok) {
        dispatch({ type: types.LOAD_NEXT_CLIENTS_REPORT_SUCCESS, result: res.data || {} });
      }
    });
  },

  loadFreshDeniedClientReports: (pageNumber, pageSize, sortBy, filterBy) => (dispatch) => {
    dispatch({ type: types.LOAD_DENIED_CLIENTS_REPORT, pageNumber, pageSize, sortBy, filterBy });
    return reportsApi.getClients(pageNumber, pageSize, sortBy, filterBy).then(res => {
      if (res.ok) {
        dispatch({ type: types.LOAD_DENIED_FRESH_CLIENTS_REPORT_SUCCESS, result: res.data || {} });
      }
    });
  },

  loadNextDeniedClientReports: (pageNumber) => (dispatch, getState) => {
    dispatch({ type: types.LOAD_DENIED_NEXT_CLIENTS_REPORT, pageNumber });
    const sortBy = getState().reports.clients.pagedData.sortedBy;
    const filterBy = getState().reports.clients.pagedData.filteredBy;
    const pageSize = getState().reports.clients.pagedData.paging.pageSize;
    return reportsApi.getClients(pageNumber, pageSize, sortBy, filterBy).then(res => {
      if (res.ok) {
        dispatch({ type: types.LOAD_DENIED_NEXT_CLIENTS_REPORT_SUCCESS, result: res.data || {} });
      }
    });
  },

  loadFreshGlobalClientReports: (pageNumber, pageSize, sortBy, filterBy) => (dispatch) => {
    dispatch({ type: types.LOAD_GLOBAL_CLIENTS_REPORT, pageNumber, pageSize, sortBy, filterBy });
    return reportsApi.getGlobalClients(pageNumber, pageSize, sortBy, filterBy).then(res => {
      if (res.ok) {
        dispatch({ type: types.LOAD_FRESH_GLOBAL_CLIENTS_REPORT_SUCCESS, result: res.data || {} });
      }
    });
  },

  clearGlobalClientReports: () =>  (dispatch) => {
    return dispatch({ type: types.CLEAR_GLOBAL_CLIENTS_REPORT});
  },

  loadNextGlobalClientReports: (pageNumber) => (dispatch, getState) => {
    dispatch({ type: types.LOAD_NEXT_GLOBAL_CLIENTS_REPORT, pageNumber });
    const sortBy = getState().reports.clients.globalClientsPageData.sortedBy;
    const filterBy = getState().reports.clients.globalClientsPageData.filteredBy;
    const pageSize = getState().reports.clients.globalClientsPageData.paging.pageSize;
    return reportsApi.getGlobalClients(pageNumber, pageSize, sortBy, filterBy).then(res => {
      if (res.ok) {
        dispatch({ type: types.LOAD_NEXT_GLOBAL_CLIENTS_REPORT_SUCCESS, result: res.data || {} });
      }
    });
  },
};
