import React from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import DocumentIcon from '@material-ui/icons/Description';
import moment from 'moment';

const ArchivedTermsAndConditionsList = ({ items, onClick }) => (
  <List>
    {items.map(item => {
      return (
        <ListItem key={item.version} button onClick={() => onClick(item.version)}>
          <Avatar style={{ marginRight: 5 }}>
            <DocumentIcon />
          </Avatar>
          <ListItemText primary={`Wersja ${item.version}`} secondary={`Zarchiwizowano ${moment(item.archivedAt).format('DD.MM.YYYY HH:mm')}`} />
        </ListItem>
      );
    })}
  </List>
);

ArchivedTermsAndConditionsList.propTypes = {
  items: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ArchivedTermsAndConditionsList;
