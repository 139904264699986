import { ReportTableModel } from '../../../models/ReportTableModel';
import {EmailStatus} from "../clientHistory/models";

export class ClientRecordModel {
  constructor(data) {
    this.userId = data.userId;
    this.clientId = data.clientId;
    this.phoneNumber = data.phoneNumber;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.email = data.email;
    this.registeredAt = data.registeredAt;
    this.userStatus = data.userStatus;
    this.accessDenied = data.accessDenied;
    this.accessDeniedInfo = data.accessDeniedInfo;
    this.activeClient = data.activeClient;
    this.lastActive = data.lastActive;
    this.rejectedDefectsCount = data.rejectedDefectsCount;
    this.dedicatedPlans = data.dedicatedPlans;
  }
}

export class GlobalClientRecordModel {
  constructor(data) {
    this.userId = data.userId;
    this.phoneNumber = data.phoneNumber;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.email = data.email;
    this.userStatus = data.status;
    this.balance = data.balance;
  }
}

export class ClientsReportModel {
  constructor() {
    this.loading = false;
    this.pagedData = new ReportTableModel({ pageNumber: 1, pageSize: 100 },  [{ id: 'registeredAt', value: 'desc' }], []);
    this.globalClientsPageData = new ReportTableModel({ pageNumber: 1, pageSize: 100 },  [], []);
  }
}

export const UserStatus = {
  active: { value: 'active', name: 'Aktywne' },
  locked: { value: 'locked', name: 'Zablokowane' },
  deleted: { value: 'deleted', name: 'Usunięte' },
};

export const ActiveInactiveOptions = {
  true: { value: 'true', name: 'Aktywny' },
  false: { value: 'false', name: 'Nieaktywny' },
};


export const emailStatusParser = (emailStatus) => {
  switch (emailStatus){
    case EmailStatus.NoRequired: return "Nie wymaga";
    case EmailStatus.Verified: return "Zweryfikowany";
    case EmailStatus.RequiredVerification: return "Wymaga weryfikacji";
    case EmailStatus.RequiredVerificationForNewUser: return "Wymaga weryfikacji";
    case EmailStatus.RequiredVerificationForOldUser: return "Wymaga weryfikacji";
    default: return "Brak Danych";
  }
}

export const CanShowResendButtonByStatus = (emailStatus) => {
  switch (emailStatus){
    case EmailStatus.NoRequired: return false;
    case EmailStatus.Verified: return false;
    case EmailStatus.RequiredVerification: return true;
    case EmailStatus.RequiredVerificationForNewUser: return true;
    case EmailStatus.RequiredVerificationForOldUser: return true;
    default: return true;
  }
}
