import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions as plansActions } from '../../../actions/plansActions';
import { actions } from '../../../reducers/reports/clients/actions';
import ReportTable from '../../common/reportTable/ReportTable';
import FiltersPanel from './FiltersPanel';
import './styles.scss';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import routePaths from '../../../routePaths';
import { hasPermission, permissions } from '../../../utils/authorization';
import {getColumns} from "./Columns";

class ClientsReportPage extends Component {
  constructor(props) {
    super(props);
    this.handleFiltersChange = this.handleFiltersChange.bind(this);
    this.handleSortChange = this.handleSortChange.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
  }

  componentDidMount() {
    const clients = this.props.clients;
    this.props.actions.loadFreshClientReports(1, clients.paging.pageSize, clients.sortedBy, [...clients.filteredBy, { id: 'accessDenied', value: false }]);
    this.props.plansActions.getAllDedicatedPlans();
  }

  handleSortChange(sortBy) {
    const clients = this.props.clients;
    this.props.actions.loadFreshClientReports(1, clients.paging.pageSize, sortBy, clients.filteredBy);
  }

  handleFiltersChange(filterBy) {
    const clients = this.props.clients;
    this.props.actions.loadFreshClientReports(1, clients.paging.pageSize, clients.sortedBy, filterBy.filter(f => f.value));
  }

  handlePageChange(nextPage) {
    this.props.actions.loadNextClientReports(nextPage);
  }

  handlePageSizeChange(nextPageSize) {
    const clients = this.props.clients;
    this.props.actions.loadFreshClientReports(1, nextPageSize, clients.sortedBy, clients.filteredBy);
  }

  render() {
    const { clients, operatorPermissions, dedicatedPlans } = this.props;
    return (
      <div className="page">
        <div style={{ margin: 40, marginBottom: 0 }}>
          <div style={{ textAlign: 'right' }}>
            {hasPermission(operatorPermissions, permissions.clientsActivityThresholdSettings) &&
              <Button component={Link} to={routePaths.activityThesholdSettings}>
                Ustawienia aktywności
              </Button>
            }
            <Button component={Link} to={routePaths.clientsDenyListReport}>
              Czarna lista użytkowników
            </Button>
          </div>
          <FiltersPanel filteredBy={clients.filteredBy} onFilteredChange={this.handleFiltersChange} dedicatedPlans={dedicatedPlans}/>
          <Paper style={{ padding: 16 }}>
            <ReportTable
              style={{ height: "calc(100vh - 200px)" }}
              columns={getColumns(dedicatedPlans)}
              data={clients.records}
              filteredBy={clients.filteredBy}
              sortedBy={clients.sortedBy}
              paging={clients.paging}
              onFilteredChange={this.handleFiltersChange}
              onPageChange={this.handlePageChange}
              onPageSizeChange={this.handlePageSizeChange}
              onSortedChange={this.handleSortChange}
              loading={clients.loading}
            />
          </Paper>
        </div>
      </div>
    );
  }
}

ClientsReportPage.propTypes = {
  actions: PropTypes.object.isRequired,
  operatorPermissions: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  clients: PropTypes.object,
  summary: PropTypes.object,
  dedicatedPlans: PropTypes.array.isRequired,
  plansActions: PropTypes.object.isRequired
};

ClientsReportPage.defaultProps = {
  loading: false,
  clients: {},
  summary: {},
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    plansActions: bindActionCreators(plansActions, dispatch)
  };
}

function mapStateToProps(state) {
  const reportState = state.reports.clients;
  return {
    clients: reportState.pagedData,
    loading: reportState.loading,
    operatorPermissions: state.auth.permissions,
    dedicatedPlans: state.plans,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientsReportPage);
