import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Button from '@material-ui/core/Button';

const styles = theme => ({
  button: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    borderRadius: 0,
    justifyContent: 'flex-start',
    textTransform: 'none',
    width: '100%',
    height: 48,
    color: theme.palette.text.secondary,
    transition: theme.transitions.create('background-color', {
      duration: theme.transitions.duration.shortest,
    }),
    '&:hover': {
      textDecoration: 'none',
    },
  },
  navItem: {
    ...theme.typography.body1,
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0,
  },
  navLink: {
    fontWeight: theme.typography.fontWeightRegular,
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  navLinkButton: {
    color: theme.palette.text.secondary,
    textIndent: 24,
    fontSize: 13,
  },
  activeButton: {
    color: theme.palette.text.secondary,
  },
});

const AppDrawerNavItem = ({ classes, title, to, onClick, icon, additionalIcon }) => (
  <ListItem className={classes.navItem} disableGutters>
    <Button
      activeClassName={classes.activeButton}
      className={classNames(classes.button)}
      component={NavLink}
      to={to}
      disableRipple
      onClick={onClick}
    >
      <ListItemIcon>
        {icon}
      </ListItemIcon>
      {title}
      {<span style={{ marginLeft: 10 }}>{additionalIcon}</span> || null}
    </Button>
  </ListItem>
);

AppDrawerNavItem.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.element.isRequired,
  additionalIcon: PropTypes.element,
};

export default withStyles(styles)(AppDrawerNavItem);
