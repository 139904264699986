import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import ImageIcon from '@material-ui/icons/Image';
import _ from 'lodash';
import './FormImageUploadStyles.scss';
import Typography from '@material-ui/core/Typography';

const provideFile = delegate => event => {
  const file = event.target.files[0];
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => delegate(reader.result);
};

const FormImageUpload = ({
  input,
  label,
  fallbackImageUri,
  meta: { error }
}) => {
  const id = _.uniqueId('fileInput');
  return (
    <div>
      <Typography variant="caption">{label}</Typography>
      <div className="columns" style={{ display: "flex" }}>
        <Avatar src={input.value ? input.value : fallbackImageUri} >
          <ImageIcon />
        </Avatar>
        <input
          accept="image/png"
          id={id}
          style={{ display: 'none' }}
          type="file"
          onChange={provideFile(input.onChange)}
          onBlur={provideFile(input.onBlur)}
        />
        <label className="column" htmlFor={id}>
          <Button component="span">Wybierz plik</Button>
        </label>
        {error && <span className="error">{error}</span>}
      </div>
    </div>
  );
};

FormImageUpload.propTypes = {
  input: PropTypes.object,
  fallbackImageUri: PropTypes.string,
  label: PropTypes.string,
  meta: PropTypes.object,
};

export default FormImageUpload;
