import Typography from "@material-ui/core/Typography";
import React from "react";
import PropTypes from "prop-types";


const RenderErrorsFromReduxFormState = ({stateReduxForm}) => {
  if(!stateReduxForm) return null;
  if(!stateReduxForm.fields)
    return null;
  const fields = Object.keys(stateReduxForm.fields)
  let errors = [];
  if(stateReduxForm.syncErrors) {
    fields.forEach(field => {
      if (stateReduxForm.fields[field] && stateReduxForm.fields[field].touched && stateReduxForm.syncErrors[field]) {
        errors = [...errors, ...stateReduxForm.syncErrors[field]]
      }
    })
  }
  errors = [...new Set(errors)]
  if (errors.length === 0) return null;
  return <Typography variant="caption" color="error">
    <ul>
      {errors.map(item => <li style={{fontSize: '14px'}} key={item}>{item}</li>)}
    </ul>
  </Typography>
}

RenderErrorsFromReduxFormState.propTypes = {
  stateReduxForm: PropTypes.object.isRequired
}
export default RenderErrorsFromReduxFormState;
