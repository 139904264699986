import React from 'react';
import PropTypes from 'prop-types';
import { withGoogleMap, GoogleMap, Polygon, Marker, Circle } from 'react-google-maps';
import { geolocated } from 'react-geolocated';
import blue from '@material-ui/core/colors/blue';
import './MapViewStyles.scss';
import withScriptjs from 'react-google-maps/lib/withScriptjs';
import { Fab } from '@material-ui/core';
import LocationIcon from '@material-ui/icons/MyLocation';
import config from '../../config';
import { ZoneAreaTypes } from '../../models/zones/ZoneModel';
import '../map/MapStyles.scss';

const shapeOptions = {
  operationsZone: {
    strokeColor: "#000",
    strokeOpacity: 0.8,
    strokeWeight: 4,
    fillColor: "#607D8B",
    fillOpacity: 0.3,
    clickable: false,
    zIndex: 1,
  },
  preferredBikeReturnZone: {
    strokeColor: "#e9e623",
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: "#e9e623",
    fillOpacity: 0.5,
    clickable: false,
    zIndex: 2,
  },
};

const userLocationIcon = {
  path: 'M12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm8.94 3c-.46-4.17-3.77-7.48-7.94-7.94V1h-2v2.06C6.83 3.52 3.52 6.83 3.06 11H1v2h2.06c.46 4.17 3.77 7.48 7.94 7.94V23h2v-2.06c4.17-.46 7.48-3.77 7.94-7.94H23v-2h-2.06zM12 19c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z',
  fillColor: blue[600],
  fillOpacity: 1,
  strokeWeight: 0.4,
  strokeColor: blue[300],
  anchor: { x: 12, y: 24 }
};

const getMarkerIcon = zoneIconUrl => {
  return {
    url: zoneIconUrl,
    /* eslint-disable no-undef */
    scaledSize: new google.maps.Size(32, 32)
  };
};

const getLocationFromCoords = coords => ({ lat: coords.latitude, lng: coords.longitude });

const MapViewRaw = withScriptjs(withGoogleMap(props => {
  const mapOptions = {
    streetViewControl: false,
    mapTypeControl: true,
    mapTypeControlOptions: { position: google.maps.ControlPosition.TOP_RIGHT },
    clickableIcons: false,
    scaleControl: true
  };
  return (
    <GoogleMap
      ref={props.onMapLoad}
      defaultZoom={props.initialZoom}
      defaultCenter={props.initialLocation}
      center={props.initialLocation}
      onClick={props.onMapClick}
      defaultOptions={mapOptions}
      onMyLocationClick={props.onMyLocationClick}
    >
      {props.markers ? props.markers.map(marker => props.markerElement(marker)) : null}
      {props.zones ? props.zones.filter(zone => zone.areaType === ZoneAreaTypes.polygon).map(zone => (
        <Polygon
          key={zone.id}
          path={zone.area}
          options={shapeOptions[zone.type]}
        />)) : null}
      {props.zones ? props.zones.filter(zone => zone.areaType === ZoneAreaTypes.circle).map(zone => (
        <Circle
          key={zone.id}
          center={zone.area.center}
          radius={zone.area.radius}
          options={shapeOptions[zone.type]}
        />)) : null}
      {props.zones ? props.zones.filter(z => z.marker).map(zone => (
        <Marker
          key={zone.id}
          icon={zone.marker.icon ? getMarkerIcon(zone.marker.icon) : null}
          position={zone.marker}
        />)) : null}
      {props.coords &&
        <React.Fragment>
          <Marker
            icon={userLocationIcon}
            position={getLocationFromCoords(props.coords)}
          />
          <Fab
            size="small"
            style={{ position: 'absolute', bottom: 24, right: 60, background: '#fafafa' }}
            onClick={() => map}>
            <LocationIcon />
          </Fab>
        </React.Fragment>
      }
      {
        props.searchResultMarker &&
        <Marker
          position={props.searchResultMarker}
        />
      }
    </GoogleMap>
  );
}));

const MapView = (props) => (<MapViewRaw googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&key=${config.GOOGLE_MAPS_API_KEY}`} {...props} />);

MapView.propTypes = {
  markerElement: PropTypes.func.isRequired,
  initialLocation: PropTypes.object.isRequired,
  initialZoom: PropTypes.number.isRequired,
  markers: PropTypes.array,
  zones: PropTypes.array,
  coords: PropTypes.object,
};

export default geolocated({
  positionOptions: {
    enableHighAccuracy: true,
    maximumAge: 0,
    timeout: Infinity,
  },
  userDecisionTimeout: 5000,
})(MapView);
