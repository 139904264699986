import React, { useEffect, useState } from 'react';
import { string } from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../actions/bikesManagementActions';
import { Button, CircularProgress, makeStyles, Typography } from '@material-ui/core';
import moment from 'moment';
import { BikeHistoryItemType } from '../../models/BikeModel';
import { Link } from 'react-router-dom';
import routePaths from '../../routePaths';
import DownloadIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 500,
  },
  typography: {
    padding: theme.spacing(2),
  },
}));

const renderActionCell = (historyItem) => {
  return (
    (historyItem.type !== BikeHistoryItemType.defectResolved.value
      && historyItem.type !== BikeHistoryItemType.defectRejected.value
      && historyItem.type !== BikeHistoryItemType.defectResolvedWithParts.value.value)
      ? <span>
        {BikeHistoryItemType[historyItem.type].name}
      </span >
      : <Link to={`${routePaths.bikeDefectDetails}${historyItem.defectId}`}>
        <span>
          {BikeHistoryItemType[historyItem.type].name}
        </span>
      </Link >
  );
}

const BikeHistoryView = ({ bikeId }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [exportInProgress, setExportInProgress] = useState(false);
  useEffect(() => { dispatch(actions.loadBikeHistory(bikeId)) }, [bikeId]);
  const loading = useSelector(s => s.bikeHistory.fetching);
  const history = useSelector(s => s.bikeHistory.data);

  const handleExport = () => {
    setExportInProgress(true);
    dispatch(actions.exportBikeHistory(bikeId))
      .finally(() => setExportInProgress(false));
  }

  if (loading) return (<div style={{ width: '100%', textAlign: 'center' }}>
    <CircularProgress size={50} />
  </div>);

  return (
    <>
      <div style={{ textAlign: 'right', marginBottom: 16 }}>
        <Button variant='outlined' color='secondary' onClick={handleExport} disabled={exportInProgress}>
          {exportInProgress && < CircularProgress size={20} color="secondary" />}
          {!exportInProgress && <DownloadIcon />}
          Eksportuj
        </Button>
      </div>
      <Typography variant="h5" gutterBottom>Historia roweru</Typography>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Data</TableCell>
            <TableCell>Zdarzenie</TableCell>
            <TableCell>Notatka</TableCell>
            <TableCell>Operator</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {history.map((row, i) => (
            <TableRow key={i}>
              <TableCell>{moment(row.time).local().format("DD.MM.YYYY HH:mm:ss")}</TableCell>
              <TableCell>{renderActionCell(row)}</TableCell>
              <TableCell>{row.note}</TableCell>
              <TableCell>{row.operatorName}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

BikeHistoryView.propTypes = {
  bikeId: string.isRequired,
}

export default BikeHistoryView;
