import React from 'react';
import PropTypes from 'prop-types';
import DropDown from '../../dropDown/DropDown';

const FormDropDown = ({
  input,
  label,
  readonly,
  meta: { touched, error },
  ...custom
}) => (
    <DropDown
      errorText={touched && error}
      defaultText={label}
      input={input}
      disabled={readonly === true}
      onChange={(event, value) => input.onChange(value)}
      {...custom}
    />
  );

FormDropDown.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  readonly: PropTypes.bool,
  meta: PropTypes.object,
};

export default FormDropDown;
