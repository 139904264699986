import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Paper,
  Input,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Switch,
  FormControlLabel,
  Typography,
} from "@material-ui/core";
import "./BikesFilterBarStyles.scss";

const defaultFilterValue = "all";
const availableFilterValue = "available";
const rentedFilterValue = "rented";
const reservedFilterValue = "reserved";
const outOfServiceFilterValue = "outOfService";
const alarmEnabledFilterValue = "alarmEnabled";

class BikesFilterBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bikeType: defaultFilterValue,
      bikeStatus: defaultFilterValue,
      lockBatteryLevel: defaultFilterValue,
      insideOperationsZone: defaultFilterValue,
      withDefects: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.runFiltering = this.runFiltering.bind(this);
    this.countBikes = this.countBikes.bind(this);
    this.handleWithDefectsChange = this.handleWithDefectsChange.bind(this);
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (this.props.bikesToFilter !== nextProps.bikesToFilter) {
      this.runFiltering(nextProps.bikesToFilter);
    }
  }

  handleChange(name) {
    return (event) => {
      this.setState({ [name]: event.target.value }, this.runFiltering);
    };
  }

  handleWithDefectsChange(_, value) {
    this.setState({ withDefects: value }, this.runFiltering);
  }

  bikeStatePredicate(bike, filter) {
    if (filter === availableFilterValue) return bike.isAvailableForRental;
    if (filter === rentedFilterValue) return bike.isRented;
    if (filter === reservedFilterValue) return bike.isReserved;
    if (filter === outOfServiceFilterValue) return bike.isRentalRestricted;
    if (filter === alarmEnabledFilterValue) return bike.hasAlarmEnabled;
    return true;
  }

  runFiltering(bikesToFilter) {
    if (this.props.onFilter) {
      const filteredBikes = (bikesToFilter || this.props.bikesToFilter)
        .filter(
          (b) =>
            this.state.insideOperationsZone === defaultFilterValue ||
            b.insideOperationsZone === this.state.insideOperationsZone,
        )
        .filter(
          (b) =>
            this.state.lockBatteryLevel === defaultFilterValue ||
            b.lockBatteryLevel <= this.state.lockBatteryLevel,
        )
        .filter(
          (b) =>
            this.state.bikeStatus === defaultFilterValue ||
            this.bikeStatePredicate(b, this.state.bikeStatus),
        )
        .filter((b) => !this.state.withDefects || b.openDefectsCount)
        .filter(
          (b) =>
            this.state.bikeType === defaultFilterValue ||
            b.bikeTypeName === this.state.bikeType,
        );
      this.props.onFilter(filteredBikes);
    }
  }

  countBikes(filter) {
    return this.props.bikesToFilter
      .filter(
        (b) =>
          this.state.insideOperationsZone === defaultFilterValue ||
          b.insideOperationsZone === this.state.insideOperationsZone,
      )
      .filter(
        (b) =>
          this.state.lockBatteryLevel === defaultFilterValue ||
          b.lockBatteryLevel <= this.state.lockBatteryLevel,
      )
      .filter((b) => !this.state.withDefects || b.openDefectsCount)
      .filter(
        (b) =>
          this.state.bikeType === defaultFilterValue ||
          b.bikeTypeName === this.state.bikeType,
      )
      .filter((b) => this.bikeStatePredicate(b, filter)).length;
  }

  render() {
    return (
      <Paper className="bikes-filter-bar">
        <FormControl className="filter-form-control">
          <InputLabel htmlFor="status-filter">Typ roweru</InputLabel>
          <Select
            value={this.state.bikeType}
            onChange={this.handleChange("bikeType")}
            input={<Input id="status-filter" />}
          >
            <MenuItem value={defaultFilterValue}>Wszystkie</MenuItem>
            {this.props.bikeTypes.map((x) => (
              <MenuItem key={x.id} value={x.name}>
                {x.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl className="filter-form-control">
          <InputLabel htmlFor="status-filter">Status</InputLabel>
          <Select
            value={this.state.bikeStatus}
            onChange={this.handleChange("bikeStatus")}
            input={<Input id="status-filter" />}
          >
            <MenuItem value={defaultFilterValue}>Wszystkie</MenuItem>
            <MenuItem value={availableFilterValue}>
              Dostępne ({this.countBikes(availableFilterValue)})
            </MenuItem>
            <MenuItem value={rentedFilterValue}>
              Wypożyczone ({this.countBikes(rentedFilterValue)})
            </MenuItem>
            <MenuItem value={reservedFilterValue}>
              Zarezerwowane ({this.countBikes(reservedFilterValue)})
            </MenuItem>
            {this.props.displayDefectsFilter && (
              <MenuItem value={outOfServiceFilterValue}>
                Wyłączone z użytku ({this.countBikes(outOfServiceFilterValue)})
              </MenuItem>
            )}
            <MenuItem value={alarmEnabledFilterValue}>
              Objęte alarmem ({this.countBikes(alarmEnabledFilterValue)})
            </MenuItem>
          </Select>
        </FormControl>

        <FormControl className="filter-form-control">
          <InputLabel htmlFor="battery-level-filter">Poziom baterii</InputLabel>
          <Select
            value={this.state.lockBatteryLevel}
            onChange={this.handleChange("lockBatteryLevel")}
            input={<Input id="battery-level-filter" />}
          >
            <MenuItem value={defaultFilterValue}>Wszystkie</MenuItem>
            <MenuItem value={50}>Poniżej 50%</MenuItem>
            <MenuItem value={25}>Poniżej 25%</MenuItem>
            <MenuItem value={0}>Rozładowane</MenuItem>
          </Select>
        </FormControl>

        <FormControl className="filter-form-control">
          <InputLabel htmlFor="zone-inclusion-filter">Lokalizacja</InputLabel>
          <Select
            value={this.state.insideOperationsZone}
            onChange={this.handleChange("insideOperationsZone")}
            input={<Input id="zone-inclusion-filter" />}
          >
            <MenuItem value={defaultFilterValue}>Wszystkie</MenuItem>
            <MenuItem value={false}>Poza strefą</MenuItem>
            <MenuItem value={true}>W strefie</MenuItem>
          </Select>
        </FormControl>

        {this.props.displayDefectsFilter && (
          <div
            className="filter-form-control"
            style={{ display: "inline-flex", flexDirection: "column" }}
          >
            <Typography variant="caption">Z usterkami</Typography>
            <FormControlLabel
              control={
                <Switch
                  checked={this.state.withDefects}
                  onChange={this.handleWithDefectsChange}
                />
              }
              label="️🚳"
            />
          </div>
        )}
      </Paper>
    );
  }
}

BikesFilterBar.propTypes = {
  bikesToFilter: PropTypes.array.isRequired,
  onFilter: PropTypes.func,
  displayDefectsFilter: PropTypes.bool,
  bikeTypes: PropTypes.array.isRequired,
};

export default BikesFilterBar;
