import { actions as tenantActions } from './tenantActions';
import { actions as bikeActions } from './bikesActions';
import { actions as zoneActions } from './zonesActions';
import { actions as authActions } from './authActions';
export const types = {
  PRELOAD_DATA: 'PRELOAD_DATA',
  PRELOAD_DATA_SUCCESS: 'PRELOAD_DATA_SUCCESS',
  PRELOAD_DATA_FAILURE: 'PRELOAD_DATA_FAILURE',
};

export const actions = {
  preloadData: () => (dispatch, getState) => {
    const auth = getState().auth;
    if (auth.signedIn) {
      dispatch({ type: types.PRELOAD_DATA });
      return dispatch(tenantActions.loadTenantConfig())
        .then(() => dispatch(authActions.getCurrentUserData()))
        .then(() => dispatch(bikeActions.loadAllBikes({hideBikeAfterLoseGPS: true})))
        .then(() => dispatch(zoneActions.load()))
        .then(() => dispatch({ type: types.PRELOAD_DATA_SUCCESS }))
        .catch(() => dispatch({ type: types.PRELOAD_DATA_FAILURE }));
    }
  }
};
