import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import moment from 'moment';
import FinishRideForm from './FinishRideForm';

const FinishRideDialog = ({ open, onSave, onCancel, dispatch, submitting, rideSummary, suggestedFinishTime, suggestedFinishLocation, zones }) => (
  <Dialog open={open}>
    <DialogTitle>Kończenie przejazdu</DialogTitle>
    <DialogContent>
      <FinishRideForm
        onSubmit={onSave}
        rideSummary={rideSummary}
        initialValues={{ finishedAt: moment(suggestedFinishTime), finishLocation: suggestedFinishLocation }}
        zones={zones}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel} disabled={submitting} >
        Anuluj
      </Button>
      <Button onClick={() => dispatch(submit('finishRide'))} disabled={submitting} color="primary">
        Zakończ przejazd
      </Button>
    </DialogActions>
  </Dialog>
);

FinishRideDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  dispatch: PropTypes.func,
  submitting: PropTypes.bool.isRequired,
  rideSummary: PropTypes.object.isRequired,
  suggestedFinishTime: PropTypes.string,
  suggestedFinishLocation: PropTypes.object,
  zones: PropTypes.array,
};

export default connect()(FinishRideDialog);
