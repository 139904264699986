import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

const FormTextField = ({
  input,
  label,
  meta: { touched, error },
  ...custom
}) => {

  const renderArrayErrors = (errors) => {
    return <ul>
      {errors.map(item => <li style={{fontSize: '14px'}} key={item}>{item}</li>)}
    </ul>
  }

  return (
    <>
      <label htmlFor={input.name}>{label}</label>
      <input className="form-control" id={input.name} placeholder={label} {...input} {...custom} />
      {touched && !custom.hiddenErrors && error ?
        (Array.isArray(error) ? <Typography variant="caption" color="error">{renderArrayErrors(error)}</Typography> :
          <Typography variant="caption" color="error">{error}</Typography>)
        : null}
    </>
  );
}

FormTextField.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  meta: PropTypes.object,
};

export default FormTextField;
