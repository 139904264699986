/* eslint-disable import/default */

import React from 'react';
import { render } from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import { actions as initActions } from './actions/initActions';
import { actions as authActions } from './actions/authActions';
import Root from './components/Root';
import moment from 'moment';
import locale from 'moment/locale/pl';
import MomentUtils from '@date-io/moment';
import momentDurationFormatSetup from 'moment-duration-format';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createBrowserHistory } from 'history';

import configureStore from './store/configureStore';
require('./favicon.ico');
import './styles/styles.scss';
import { syncApiWithStore } from './api/apiBase';
import es6promise from 'es6-promise';
es6promise.polyfill();

momentDurationFormatSetup(moment);
moment.updateLocale('pl', locale);

const history = createBrowserHistory();
const store = configureStore(history);


syncApiWithStore(store);
store
  .dispatch(authActions.checkTenant())
  .then(() => store.dispatch(initActions.preloadData()));

render(
  <MuiPickersUtilsProvider utils={MomentUtils}>
    <AppContainer>
      <Root store={store} history={history} />
    </AppContainer>
  </MuiPickersUtilsProvider>,
  document.getElementById('app')
);

if (module.hot) {
  module.hot.accept('./components/Root', () => {
    const NewRoot = require('./components/Root').default;
    render(
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <AppContainer>
          <NewRoot store={store} history={history} />
        </AppContainer>
      </MuiPickersUtilsProvider>,
      document.getElementById('app')
    );
  });
}
