import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from '../../../actions/campaignsActions';
import ReportTable from '../../common/reportTable/ReportTable';
import FiltersPanel from './FiltersPanel';
import { getColumns } from './Columns';
import './styles.scss';
import { Button } from '@material-ui/core';
import NewCampaignDialog from './NewCampaignDialog';
import routePaths from '../../../routePaths';

class CampaignsPage extends Component {
  constructor(props) {
    super(props);
    this.state = { newCampaignDialogVisible: false, newCampaignSubmitting: false };

    this.handleFiltersChange = this.handleFiltersChange.bind(this);
    this.handleSortChange = this.handleSortChange.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
    this.handleOpenNewCampaignDialog = this.handleOpenNewCampaignDialog.bind(this);
    this.handleCloseNewCampaignDialog = this.handleCloseNewCampaignDialog.bind(this);
    this.handleSubmitNewCampaign = this.handleSubmitNewCampaign.bind(this);
  }

  componentDidMount() {
    const campaigns = this.props.campaigns;
    const defaultFilter = campaigns.filteredBy;

    this.props.actions.loadCampaigns(1, campaigns.paging.pageSize, campaigns.sortedBy, defaultFilter);
  }

  handleSortChange(sortBy) {
    const campaigns = this.props.campaigns;
    this.props.actions.loadCampaigns(1, campaigns.paging.pageSize, sortBy, campaigns.filteredBy);
  }

  handleFiltersChange(filterBy) {
    const campaigns = this.props.campaigns;
    this.props.actions.loadCampaigns(1, campaigns.paging.pageSize, campaigns.sortedBy, filterBy.filter(f => f.value));
  }

  handlePageChange(nextPage) {
    this.props.actions.loadNextCampaigns(nextPage);
  }

  handlePageSizeChange(nextPageSize) {
    const campaigns = this.props.campaigns;
    this.props.actions.loadCampaigns(1, nextPageSize, campaigns.sortedBy, campaigns.filteredBy);
  }

  handleOpenNewCampaignDialog() {
    this.setState({ newCampaignDialogVisible: true });
  }

  handleCloseNewCampaignDialog() {
    this.setState({ newCampaignDialogVisible: false });
  }

  handleSubmitNewCampaign(data) {
    this.setState({ newCampaignSubmitting: true });
    this.props.actions.createCampaign(data)
      .then(id => setTimeout(() => this.props.history.push(`${routePaths.campaignDetails}${id}`), 200))
      .finally(() => this.setState({ newCampaignSubmitting: true, newCampaignDialogVisible: false }));
  }

  render() {
    const { campaigns } = this.props;
    const { newCampaignDialogVisible, newCampaignSubmitting } = this.state;
    return (
      <div className="page">
        <div style={{ margin: 40, marginBottom: 0 }}>
          <div style={{ marginBottom: 16, display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" color="primary" onClick={this.handleOpenNewCampaignDialog}>Nowa kampania</Button>
          </div>
          <NewCampaignDialog
            open={newCampaignDialogVisible}
            submitting={newCampaignSubmitting}
            onSave={this.handleSubmitNewCampaign}
            onCancel={this.handleCloseNewCampaignDialog}
          />
          <FiltersPanel filteredBy={campaigns.filteredBy} onFilteredChange={this.handleFiltersChange} />
          <Paper style={{ padding: 16 }}>
            <ReportTable
              style={{ height: "calc(100vh - 200px)" }}
              columns={getColumns()}
              data={campaigns.records}
              filteredBy={campaigns.filteredBy}
              sortedBy={campaigns.sortedBy}
              paging={campaigns.paging}
              onFilteredChange={this.handleFiltersChange}
              onPageChange={this.handlePageChange}
              onPageSizeChange={this.handlePageSizeChange}
              onSortedChange={this.handleSortChange}
              loading={campaigns.loading}
            />
          </Paper>
        </div>
      </div>
    );
  }
}


CampaignsPage.propTypes = {
  actions: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  campaigns: PropTypes.object,
  bikeNumber: PropTypes.string,
};

CampaignsPage.defaultProps = {
  loading: false,
  campaigns: {},
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

function mapStateToProps(state) {
  const campaignsState = state.campaigns.list;
  return {
    campaigns: campaignsState.pagedData,
    loading: campaignsState.loading,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CampaignsPage);
