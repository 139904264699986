import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const AddBikeNoteDialog = ({
  open,
  onSave,
  onCancel,
  submitting,
}) => {
  const [note, setNote] = useState('');
  const handleSave = () => {
    const n = note;
    onSave(n).finally(() => setNote(''));
  }
  const handleClose = () => {
    setNote('');
    onCancel();
  }
  return (
    <Dialog fullWidth open={open} onClose={handleClose}>
      <DialogTitle>Dodawanie notatki</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Wprowadzona notatka będzie widoczna w historii roweru
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Notatka"
          fullWidth
          multiline
          minRows={4}
          value={note}
          onChange={(e) => setNote(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          Anuluj
        </Button>
        <Button disabled={submitting || !note} onClick={() => handleSave(note)} color="primary" variant='contained'>
          Dodaj
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AddBikeNoteDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired
};

export default AddBikeNoteDialog;
