import initialState from './initialState';
import Immutable from 'seamless-immutable';
import { types } from '../actions/bikesManagementActions';

export default function bikeHistoryReducer(state = Immutable(initialState.bikeHistory), action) {
  switch (action.type) {
    case types.GET_BIKE_HISTORY: {
      return Object.assign({}, state, { data: [], fetching: true });
    }
    case types.GET_BIKE_HISTORY_SUCCESS: {
      return Object.assign({}, state, { data: action.data, fetching: false });
    }
    case types.GET_BIKE_HISTORY_FAILURE: {
      return Object.assign({}, state, { data: [], fetching: false });
    }
    default:
      return state;
  }
}
