import React from "react";
import PropTypes from "prop-types";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { connect } from "react-redux";
import DedicatedPlansForm from "./DedicatedPlansForm";

const DedicatedPlansDialog = ({
  open,
  onSave,
  dedicatedPlans
}) => (
    <Dialog open={open}>
      <DialogTitle>Wybierz plany dedykowane dla użytkownika</DialogTitle>
      <DialogContent>
        <DedicatedPlansForm onSubmit={onSave} dedicatedPlans={dedicatedPlans}/>
      </DialogContent>
    </Dialog>
  );

DedicatedPlansDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  dispatch: PropTypes.func,
  submitting: PropTypes.bool.isRequired,
  dedicatedPlans: PropTypes.array.isRequired,
};

export default connect()(DedicatedPlansDialog);
