import React, {useEffect} from "react";
import { useDispatch, useSelector} from "react-redux";
import { useRouteMatch} from "react-router-dom";
import {sectionsOfReport, sectionsOfReportForClientUserRole} from "../create/CreateCustomReportPage";
import CreateCustomReportForm from "../create/CreateCustomReportForm";
import { actions } from '../../../actions/reportsActions';
import {UserRoles} from "../../../models/auth/AuthModel";

const PreviewCustomReportPage = () => {
  const dispatch = useDispatch();
  const isFetching = useSelector(s => s.reports.list.loadingCustomReport, null);
  const report = useSelector(s => s.reports.list.customReport, null);
  const userRole = useSelector(x => x.auth.currentUserData.userRole, null);
  const match = useRouteMatch();
  useEffect(() => {
    dispatch(actions.getCustomReport(match.params.id))
  }, [])


  return(
    <div className="page" >
      <div style={{ margin: 40 }}>
        {!isFetching && <CreateCustomReportForm
          isPreview
          onSubmit={() => {}}
          reportTemplate={report}
          sectionsOfReport={userRole === UserRoles.client.value ? sectionsOfReportForClientUserRole : sectionsOfReport}
          submitting={false}
        />}
      </div>
    </div>
  )

}

export default PreviewCustomReportPage
