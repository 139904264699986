import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions as authActions } from '../../../actions/authActions';
import PasswordRecoveryForm from './PasswordRecoveryForm';
import AnonymousContentPanel from '../AnonymousContentPanel';

const PasswordRecoveryPage = ({ authActions }) => (
  <AnonymousContentPanel>
    <p>
      Wprowadź adres email, który wykorzystujesz do logowania.
    Prześlemy na niego odnośnik, który umożliwi Ci zresetowanie hasła.
    </p>
    <PasswordRecoveryForm
      onSubmit={data => authActions.recoverPassword(data)}
    />
  </AnonymousContentPanel>
);

PasswordRecoveryPage.propTypes = {
  authActions: PropTypes.object.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    authActions: bindActionCreators(authActions, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(PasswordRecoveryPage);
