import React from 'react';
import moment from 'moment';
import AccessTimeIcon from '@material-ui/icons/FindInPage';
import TextFilter from '../../common/reportTable/filters/TextFilter';
import DateTimeFilter from '../../common/reportTable/filters/DateTimeFilter';
import SelectFilter from '../../common/reportTable/filters/SelectFilter';
import { EventTypes } from '../../../reducers/reports/clientHistory/models';
import { Link } from 'react-router-dom';
import routePaths from '../../../routePaths';

/* eslint react/display-name: 0 */
/* eslint react/prop-types: 0 */
/* eslint react/no-multi-comp: 0 */
export const columns = [
  {
    Header: 'Data',
    id: 'occurredAt',
    width: 150,
    accessor: x => moment(x.occurredAt).local().format('DD.MM.YYYY HH:mm:ss.SSS'),
    Filter: ({ filter, onChange }) => <DateTimeFilter onChange={onChange} filter={filter} label="filtruj od..." />,
  },
  {
    Header: 'Zdarzenie',
    id: 'type',
    width: 185,
    accessor: x => x.type.name,
    Filter: ({ filter, onChange }) => <SelectFilter onChange={onChange} filter={filter} items={EventTypes} label="filtruj..." />,
  },
  {
    Header: 'Rower',
    id: 'bikeNumber',
    width: 90,
    accessor: x => x.bikeNumber,
    Filter: ({ filter, onChange }) => <TextFilter onChange={onChange} filter={filter} label="filtruj..." />,
  },
  {
    Header: 'Przejazd',
    id: 'rideId',
    width: 90,
    Cell: row => row.original.rideId
      ? <Link to={`${routePaths.ridesReports}/${row.original.rideId}`} style={{ display: 'flex', alignItems: 'center' }}><AccessTimeIcon style={{ fontSize: 16 }} /> Otwórz</Link>
      : null,
    Filter: ({ filter, onChange }) => <TextFilter onChange={onChange} filter={filter} label="filtruj..." />,
  },
  {
    Header: 'Plan',
    id: 'planId',
    width: 100,
    Cell: row => <Link to={`${routePaths.plans}/${row.original.planId}`}>{row.original.planId}</Link>,
    Filter: () => null,
  },
  {
    Header: 'Wersja regulaminu',
    id: 'version',
    width: 120,
    accessor: x => x.version,
    Filter: () => null,
  },
  {
    Header: 'Poprzedni numer',
    id: 'previousPhoneNumber',
    width: 110,
    accessor: x => x.previousPhoneNumber,
    Filter: ({ filter, onChange }) => <TextFilter onChange={onChange} filter={filter} label="filtruj..." />,
  },
  {
    Header: 'Nowy numer',
    id: 'newPhoneNumber',
    width: 110,
    accessor: x => x.newPhoneNumber,
    Filter: ({ filter, onChange }) => <TextFilter onChange={onChange} filter={filter} label="filtruj..." />,
  },
  {
    Header: 'Karta miejska',
    id: 'card',
    width: 220,
    accessor: x => x.cardProviderId ? `${x.cardProviderId} - ${x.cardNumber}` : null,
    filterable: false,
  },
];
