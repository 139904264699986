import { push } from 'connected-react-router';
import pricingPublicApi from '../api/pricingPublicApi';
import routePaths from '../routePaths';
import { PricingPublicModel } from '../models/configuration/PricingPublicModel';
import { actions as notificationActions } from './notificationActions';
import * as logger from '../utils/logger';

export const types = {
  UPDATE_PRICING_PUBLIC_SUBMIT: 'UPDATE_PRICING_PUBLIC_SUBMIT',
  UPDATE_PRICING_PUBLIC_SUCCESS: 'UPDATE_PRICING_PUBLIC_SUCCESS',
  UPDATE_PRICING_PUBLIC_FAILURE: 'UPDATE_PRICING_PUBLIC_FAILURE',
};

export const actions = {
  update: pricing => dispatch => {
    dispatch({ type: types.UPDATE_PRICING_PUBLIC_SUBMIT });

    const pricingToUpdate = Object.assign(new PricingPublicModel(), pricing);

    return pricingPublicApi
      .update(pricingToUpdate)
      .then(response => {
        if (response.ok && response.data.success !== false) {
          dispatch({ type: types.UPDATE_PRICING_PUBLIC_SUCCESS, pricing: pricingToUpdate });
          dispatch(notificationActions.showSuccess('Zaktualizowano cennik'));
          return dispatch(push(routePaths.pricing));
        } else {
          dispatch({type: types.UPDATE_PRICING_PUBLIC_FAILURE});
          dispatch(notificationActions.showError('Zapis nie powiódł się'));

          return logger.error(response.data);
        }
      });
  },
};
