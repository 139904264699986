import campaignsApi from '../api/campaignsApi';
import { actions as notificationActions } from './notificationActions';
import * as logger from '../utils/logger';

export const types = {
  LOAD_CAMPAIGNS: 'LOAD_CAMPAIGNS',
  LOAD_CAMPAIGNS_SUCCESS: 'LOAD_CAMPAIGNS_SUCCESS',
  LOAD_CAMPAIGNS_FAILURE: 'LOAD_CAMPAIGNS_FAILURE',
  LOAD_NEXT_CAMPAIGNS: 'LOAD_NEXT_CAMPAIGNS',
  LOAD_NEXT_CAMPAIGNS_SUCCESS: 'LOAD_NEXT_CAMPAIGNS_SUCCESS',
  LOAD_NEXT_CAMPAIGNS_FAILURE: 'LOAD_NEXT_CAMPAIGNS_FAILURE',
  CREATE_CAMPAIGN: 'CREATE_CAMPAIGN',
  CREATE_CAMPAIGN_SUCCESS: 'CREATE_CAMPAIGN_SUCCESS',
  CREATE_CAMPAIGN_FAILURE: 'CREATE_CAMPAIGN_FAILURE',
  LOAD_CAMPAIGN: 'LOAD_CAMPAIGN',
  LOAD_CAMPAIGN_SUCCESS: 'LOAD_CAMPAIGN_SUCCESS',
  LOAD_CAMPAIGN_FAILURE: 'LOAD_CAMPAIGN_FAILURE',
  UPDATE_CAMPAIGN: 'UPDATE_CAMPAIGN',
  UPDATE_CAMPAIGN_SUCCESS: 'UPDATE_CAMPAIGN_SUCCESS',
  UPDATE_CAMPAIGN_FAILURE: 'UPDATE_CAMPAIGN_FAILURE',
  EXECUTE_CAMPAIGN: 'EXECUTE_CAMPAIGN',
  EXECUTE_CAMPAIGN_SUCCESS: 'EXECUTE_CAMPAIGN_SUCCESS',
  EXECUTE_CAMPAIGN_FAILURE: 'EXECUTE_CAMPAIGN_FAILURE',
};

export const actions = {
  loadCampaigns: (pageNumber, pageSize, sortBy, filterBy) => (dispatch) => {
    dispatch({ type: types.LOAD_CAMPAIGNS, pageNumber, pageSize, sortBy, filterBy });
    return campaignsApi.getCampaigns(pageNumber, pageSize, sortBy, filterBy).then(res => {
      if (res.ok) {
        dispatch({ type: types.LOAD_CAMPAIGNS_SUCCESS, result: res.data || {} });
      } else {
        dispatch({ type: types.LOAD_CAMPAIGNS_FAILURE });
        dispatch(notificationActions.showError('Nie udało się pobrać listy kampanii'));
        return logger.error(res.data);
      }
    });
  },

  loadNextCampaigns: (pageNumber) => (dispatch, getState) => {
    dispatch({ type: types.LOAD_NEXT_CAMPAIGNS, pageNumber });
    const sortBy = getState().campaigns.list.pagedData.sortedBy;
    const filterBy = getState().campaigns.list.pagedData.filteredBy;
    const pageSize = getState().campaigns.list.pagedData.paging.pageSize;
    return campaignsApi.getCampaigns(pageNumber, pageSize, sortBy, filterBy).then(res => {
      if (res.ok) {
        dispatch({ type: types.LOAD_NEXT_CAMPAIGNS_SUCCESS, result: res.data || {} });
      } else {
        dispatch({ type: types.LOAD_NEXT_CAMPAIGNS_FAILURE });
        dispatch(notificationActions.showError('Nie udało się pobrać listy kampanii'));
        return logger.error(res.data);
      }
    });
  },

  createCampaign: ({ name }) => (dispatch) => {
    dispatch({ type: types.CREATE_CAMPAIGN, name });
    return campaignsApi.createCampaign({ name }).then(res => {
      if (res.ok) {
        dispatch({ type: types.CREATE_CAMPAIGN_SUCCESS, id: res.data });
        dispatch(notificationActions.showSuccess('Utworzono kampanię'));
        return res.data;
      } else {
        dispatch({ type: types.CREATE_CAMPAIGN_FAILURE });
        dispatch(notificationActions.showError('Nie udało się utworzyć kampanii'));
        return logger.error(res.data);
      }
    });
  },

  loadCampaign: (id) => (dispatch) => {
    dispatch({ type: types.LOAD_CAMPAIGN, id });
    return campaignsApi.getCampaign(id).then(res => {
      if (res.ok) {
        dispatch({ type: types.LOAD_CAMPAIGN_SUCCESS, id, data: res.data });
        return res.data;
      } else {
        dispatch({ type: types.LOAD_CAMPAIGN_FAILURE, id });
        dispatch(notificationActions.showError('Nie udało się pobrać kampanii'));
        return logger.error(res.data);
      }
    });
  },

  updateCampaign: (id, data) => (dispatch) => {
    dispatch({ type: types.UPDATE_CAMPAIGN, id });
    return campaignsApi.updateCampaign(id, data).then(res => {
      if (res.ok) {
        dispatch({ type: types.UPDATE_CAMPAIGN_SUCCESS, id, data });
        dispatch(notificationActions.showSuccess('Zaktualizowano kampanię'));
        return true;
      } else {
        dispatch({ type: types.UPDATE_CAMPAIGN_FAILURE, id });
        dispatch(notificationActions.showError('Nie udało się zaktualizować kampanii'));
        return logger.error(res.data);
      }
    });
  },

  executeCampaign: (id) => (dispatch) => {
    dispatch({ type: types.EXECUTE_CAMPAIGN, id });
    return campaignsApi.executeCampaign(id).then(res => {
      if (res.ok) {
        dispatch({ type: types.EXECUTE_CAMPAIGN_SUCCESS, id });
        dispatch(notificationActions.showSuccess('Uruchomiono kampanię'));
        return true;
      } else {
        dispatch({ type: types.EXECUTE_CAMPAIGN_FAILURE, id });
        dispatch(notificationActions.showError('Nie udało się uruchomić kampanii'));
        return logger.error(res.data);
      }
    });
  },
};
