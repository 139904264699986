import React from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hasPermission } from '../../utils/authorization';
import routePaths from '../../routePaths';
import { Redirect } from 'react-router-dom';

export function requireAuthorization(Component, permission) {
  class RestrictedComponent extends React.Component {
    renderNotAuthorized() {
      return (
        <div className="page">
          <div style={{ margin: 40 }}>
            <Typography variant="h3">
              Brak uprawnień
            </Typography>
          </div>
        </div>
      );
    }
    render() {
      if (!this.props.auth.signedIn) {
        return <Redirect to={routePaths.signIn} />
      }

      const allowed = permission == null || hasPermission(this.props.auth.permissions, permission);
      return (
        <div>
          {allowed ? <Component {...this.props} /> : this.renderNotAuthorized()}
        </div>
      );
    }
  }

  RestrictedComponent.propTypes = {
    auth: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  const mapStateToProps = (state) => ({
    auth: state.auth,
  });

  return connect(mapStateToProps)(RestrictedComponent);
}
