import React from 'react';
import PropTypes from 'prop-types';
import OperatorEditForm from './OperatorEditForm';
import routePaths from '../../routePaths';
import { bindActionCreators } from 'redux';
import { actions } from '../../actions/operatorsActions';
import { connect } from 'react-redux';
import { hasPermission, permissions } from '../../utils/authorization';
import { Button, CircularProgress } from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/GetApp';

class OperatorEditPage extends React.Component {

  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
  }

  state = { auditLogInProgress: false };

  componentDidMount() {
    this.props.actions.startOperatorCreation();
    if (this.props.match.params.operatorId) {
      this.props.actions.load(this.props.match.params.operatorId);
    }
  }

  submitForm(data) {
    const submitAction = data.id
      ? this.props.actions.update
      : this.props.actions.registerNew;
    return submitAction(data)
      .then(success => {
        if (success) this.props.history.push(routePaths.operators);
      });
  }

  handleGetAuditLog = () => {
    this.setState({ auditLogInProgress: true });
    this.props.actions
      .getOperatorAuditLog(this.props.match.params.operatorId)
      .finally(() => this.setState({ auditLogInProgress: false }));
  }

  render() {
    const { auditLogInProgress } = this.state;
    const { match, userPermissions } = this.props;
    return (
      <div className="page" >
        <div style={{ margin: 40 }}>
          <div style={{ textAlign: 'right', marginBottom: 16 }}>
            {hasPermission(userPermissions, permissions.auditLog) && match.params.operatorId != null &&
              <Button onClick={this.handleGetAuditLog} disabled={auditLogInProgress}>
                {auditLogInProgress && < CircularProgress size={20} color="secondary" />}
                {!auditLogInProgress && <DownloadIcon />}
                Pobierz dziennik zdarzeń
              </Button>
            }
          </div>
          <OperatorEditForm
            onSubmit={this.submitForm}
            isEdit={match.params.operatorId != null}
            showRooveeRole={hasPermission(userPermissions, permissions.operatorRooveeAccounts)}
          />
        </div>
      </div >
    );
  }
}

OperatorEditPage.propTypes = {
  actions: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  operator: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  userPermissions: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  return {
    operator: state.operators.current,
    userPermissions: state.auth.permissions,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OperatorEditPage);
