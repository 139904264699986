import Typography from "@material-ui/core/Typography";
import React from "react";
import PropTypes from "prop-types";


const RenderErrorsFromFinalReduxFormState = ({errors, touched}) => {
  if(!errors) return null;
  const fields = Object.keys(errors)
  let errorsArray = [];

  fields.forEach(field => {
    if (errors[field] && touched[field] && errors[field]) {
      errorsArray = [...errorsArray, ...errors[field]]
    }
  })

  errorsArray = [...new Set(errorsArray)]
  if (errorsArray.length === 0) return null;
  return <Typography variant="caption" color="error">
    <ul>
      {errorsArray.map(item => <li style={{fontSize: '14px'}} key={item}>{item}</li>)}
    </ul>
  </Typography>
}

RenderErrorsFromFinalReduxFormState.propTypes = {
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired

}
export default RenderErrorsFromFinalReduxFormState;
