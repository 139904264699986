
import apiBase from './apiBase';

class CampaignsApi {
  static getCampaigns(pageNumber, pageSize, sortBy, filterBy) {
    const payload = {
      paging: {
        pageNumber,
        pageSize
      },
      filterBy: Object.assign({}, ...filterBy.map(x => ({ [x.id]: x.value }))),
      sortBy: Object.assign({}, ...sortBy.map(x => ({ [x.id]: x.value }))),
    };
    return apiBase.create().post(`/operations/campaigns/list`, payload);
  }

  static getCampaign(campaignId) {
    return apiBase.create().get(`/operations/campaigns/${campaignId}`);
  }

  static createCampaign(data) {
    return apiBase.create().post(`/operations/campaigns`, data);
  }

  static updateCampaign(id, data) {
    return apiBase.create().put(`/operations/campaigns/${id}`, data);
  }

  static executeCampaign(id) {
    return apiBase.create().post(`/operations/campaigns/${id}/execute`);
  }
}

export default CampaignsApi;
