import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import FormTextField from '../form/redux-form/FormTextField';
import FormImageUpload from '../form/redux-form/FormImageUpload';
import { Link } from 'react-router-dom';
import routePaths from '../../routePaths';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { validateSync, validateAsync } from '../../models/zoneTypes/zoneTypeModelValidation';

let ZoneTypeEditForm = ({
  initialValues,
  handleSubmit,
  isEdit,
  valid,
  submitting,
  pristine }) => {
  return (
    <form onSubmit={handleSubmit}>
      <Typography variant="h5" gutterBottom>{isEdit ? 'Edycja typu strefy' : 'Nowy typ strefy'}</Typography>
      <Paper className="config-section" elevation={3}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Field
            name="name"
            component={FormTextField}
            label="Nazwa typu"
          />
          <Typography variant="body2" style={{ marginTop: 16 }}>Wymagane ikony w formacie png, z przeźroczystym tłem o rozmiarze 80x92</Typography>
          <Field
            type="file"
            name="markerIconDataUri"
            component={FormImageUpload}
            label="Ikona strefy"
            fallbackImageUri={initialValues.markerIconUri}
          />
        </div>

      </Paper>
      <br />
      <div className="actions">
        <Button style={{ marginRight: 8 }} component={Link} to={routePaths.zoneTypes} disabled={submitting}>
          Anuluj
        </Button>
        <Button variant="contained" color="primary" type="submit" disabled={!valid || submitting || pristine}>
          {submitting ? 'Zapisywanie...' : 'Zapisz'}
        </Button>
      </div>
    </form>
  );
};

ZoneTypeEditForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
  initialValues: PropTypes.object,
  valid: PropTypes.bool,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
};

ZoneTypeEditForm = reduxForm({
  form: 'zoneTypeEditForm',
  validate: validateSync,
  asyncValidate: validateAsync,
  enableReinitialize: true
})(ZoneTypeEditForm);

ZoneTypeEditForm = connect(
  state => ({
    initialValues: state.zoneTypes.current,
  })
)(ZoneTypeEditForm);

export default ZoneTypeEditForm;
