import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import FormTextField from "../../../form/redux-form/FormTextField";
import FormCheckBox from "../../../form/redux-form/FormCheckBox";
import FormSelect from "../../../form/redux-form/FormSelect";
import numberFormatter from "../../../../utils/numberFormatter";
import { fareAdjustmentFormValidation } from "../../../../models/fareAdjustments/FareAdjustmentValidation";
import FareAdjustmentInfo from "./FareAdjustmentInfo";
import {
  CorrectionReasonType,
  PaymentType,
} from "../../../../models/configuration/PricingModel";

let FareAdjustmentForm = ({
  totalAmount,
  totalRefund,
  currentAmount,
  handleSubmit,
  change,
  zones,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <div>
        {currentAmount > 0 && (
          <FareAdjustmentInfo
            message={`Opłata zostanie podwyższona o ${numberFormatter.format(currentAmount)} zł`}
          />
        )}
        {-currentAmount > totalAmount && (
          <FareAdjustmentInfo
            message={`Klient otrzyma bonus w wysokości ${numberFormatter.format(-(totalAmount + currentAmount))} zł`}
          />
        )}
      </div>
      <div>
        <Field
          name="amount"
          type="number"
          component={FormTextField}
          label="Kwota"
          parse={(val) => (val ? Number.parseFloat(val) : "")}
          disabled={totalRefund}
          endAdornmentText="zł"
        />
        <span style={{ marginLeft: 32 }}>
          <Field
            name="totalRefund"
            component={FormCheckBox}
            label="Zwrot całej opłaty"
            onChange={() => {
              change("amount", -totalAmount);
            }}
            parse={Boolean}
          />
        </span>
      </div>
      <div>
        <Field
          name="paymentType"
          component={FormSelect}
          values={PaymentType}
          label="Rodzaj opłaty"
          fullWidth
        />
      </div>
      <div>
        <Field
          name="correctionReasonType"
          component={FormSelect}
          values={CorrectionReasonType}
          label="Powód korekty"
          fullWidth
        />
      </div>
      <div>
        <Field
          name="zone"
          component={FormSelect}
          values={zones}
          label="Strefa parkowania "
          fullWidth
        />
      </div>
      <div>
        <Field
          name="comment"
          type="text"
          component={FormTextField}
          label="Komentarz (widoczny tylko dla operatorów)"
          fullWidth
        />
      </div>
    </form>
  );
};

FareAdjustmentForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  totalAmount: PropTypes.number.isRequired,
  change: PropTypes.func.isRequired,
  totalRefund: PropTypes.bool,
  currentAmount: PropTypes.number,
  zones: PropTypes.object.isRequired,
};

FareAdjustmentForm = reduxForm({
  form: "fareAdjustment",
  validate: fareAdjustmentFormValidation,
})(FareAdjustmentForm);

const selector = formValueSelector("fareAdjustment");
FareAdjustmentForm = connect((state) => ({
  totalRefund: selector(state, "totalRefund"),
  currentAmount: selector(state, "amount"),
}))(FareAdjustmentForm);

export default FareAdjustmentForm;
