import initialState from './initialState';
import Immutable from 'seamless-immutable';
import { types } from '../actions/bikeDefectsActions';

export default function bikeServicePartsReducer(state = Immutable(initialState.bikeDefects.serviceParts), action) {
  switch (action.type) {
    case types.GET_SERVICE_PARTS_LIST_SUCCESS: {
      return Immutable.merge(action.result);
    }
    default:
      return state;
  }
}
