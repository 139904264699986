import React from "react";
import PropTypes from "prop-types";
import MapView from "./map/MapView";
import BikeMarker from "./map/BikeMarker";
import BikesFilterBar from "./map/BikesFilterBar";
import BikeDetailsCard from "./map/BikeDetailsCard";
import BikesSidebarList from "./bikesList/BikesSidebarList";
import { actions as bikesActions } from "../actions/bikesActions";
import { actions as bikeDetailsActions } from "../actions/bikeDetailsActions";
import { actions as zonesActions } from "../actions/zonesActions";
import { actions as twoFAActions } from "../actions/twoFAActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { RegionModel } from "../models/RegionModel";
import windowDimensions from "react-window-dimensions";
import { permissions, hasPermission } from "../utils/authorization";
import { actions as bikeTypesActions } from "../actions/bikeTypesActions";
import NotEnabled2FADialog from "./account/TOPT2FA/NotEnabled2FADialog";
import routePaths from "../routePaths";

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bikes: props.bikes,
      searchBiasBounds: null,
      searchResultMarker: null,
    };
    this.handleBikeSelectFromList = this.handleBikeSelectFromList.bind(this);
    this.handleBikeSelectFromMap = this.handleBikeSelectFromMap.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleMyLocationClick = this.handleMyLocationClick.bind(this);
    this.handleMapBoundsChanged = this.handleMapBoundsChanged.bind(this);
    this.handleMapSearchCompleted = this.handleMapSearchCompleted.bind(this);
  }

  componentDidMount() {
    this.props.bikeTypesActions.loadBikeTypes();
    this.props.bikesActions.loadAllBikes({ hideBikeAfterLoseGPS: true });
    this.props.zonesActions.load();
    this.loadBikesTimer = setInterval(
      () =>
        this.props.bikesActions.loadAllBikes({ hideBikeAfterLoseGPS: true }),
      10000,
    );
  }

  componentWillUnmount() {
    clearInterval(this.loadBikesTimer);
  }

  handleFilterChange(filteredBikes) {
    this.setState({ bikes: filteredBikes });
  }

  handleBikeSelectFromList(bike) {
    this.mapRef.fitBounds(new RegionModel(bike.location).toLatLngBounds());
    this.props.bikeDetailsActions.selectBike(bike);
  }

  handleBikeSelectFromMap(bike) {
    this.props.bikeDetailsActions.selectBike(bike);
  }

  handleMyLocationClick(coords) {
    if (!coords) return;
    this.mapRef.fitBounds(new RegionModel(coords).toLatLngBounds());
  }

  handleMapBoundsChanged() {
    this.setState({ searchBiasBounds: this.mapRef.getBounds() });
  }

  handleMapSearchCompleted(searchResults) {
    if (!searchResults || !searchResults.length) return;
    const result = searchResults[0];
    this.setState({ searchResultMarker: result.geometry.location });
    this.mapRef.fitBounds(result.geometry.viewport);
  }

  render() {
    const listWidth = this.props.width < 768 ? 0 : "auto";
    const accessDefectsDataPermission = hasPermission(
      this.props.userPermissions,
      permissions.bikes.accessDefectsData,
    );

    return (
      <div className="page flex-row relative" style={{ overflow: "hidden" }}>
        <BikesFilterBar
          bikeTypes={this.props.bikeTypes}
          bikesToFilter={this.props.bikes}
          onFilter={this.handleFilterChange}
          displayDefectsFilter={accessDefectsDataPermission}
        />
        <div className="relative" style={{ width: "100%" }}>
          <MapView
            loadingElement={<div style={{ height: "100%", width: 500 }} />}
            containerElement={
              <div style={{ width: "100%", height: this.props.height - 64 }} />
            }
            mapElement={
              <div style={{ width: "100%", height: this.props.height - 64 }} />
            }
            onMapLoad={(ref) => (this.mapRef = ref)}
            onMapClick={() => this.setState({ searchResultMarker: null })}
            markers={this.state.bikes}
            onMarkerRightClick={() => {}}
            markerElement={(bike) => (
              <BikeMarker
                bike={bike}
                key={bike.id}
                isSelected={bike.id === this.props.bikeDetails.id}
                onSelect={this.handleBikeSelectFromMap}
                displayDefectsInfo={accessDefectsDataPermission}
              />
            )}
            initialLocation={this.props.initialLocation}
            initialZoom={this.props.initialZoom}
            zones={this.props.zones}
            location={this.state.location}
            onMyLocationClick={this.handleMyLocationClick}
            onBoundsChanged={this.handleMapBoundsChanged}
            searchBiasBounds={this.state.searchBiasBounds}
            onSearchCompleted={this.handleMapSearchCompleted}
            searchResultMarker={this.state.searchResultMarker}
          />
          <BikeDetailsCard
            bikeDetails={this.props.bikeDetails}
            displayDefectsInfo={accessDefectsDataPermission}
          />
        </div>
        <BikesSidebarList
          style={{
            width: listWidth,
            height: this.props.height - 64,
            padding: 0,
            overflowY: "auto",
          }}
          bikes={this.state.bikes}
          onBikeSelect={this.handleBikeSelectFromList}
          selectedBike={this.props.bikeDetails}
        />
        <NotEnabled2FADialog
          open={
            this.props.currentUserData &&
            this.props.currentUserData.showTOPT2FAInfoDialog
          }
          onClose={() => {
            this.props.twoFAActions.hide2FAInfoDialog();
          }}
          onAccept={() => {
            this.props.twoFAActions.hide2FAInfoDialog();
            this.props.history.push(routePaths.account.twoFA);
          }}
        />
      </div>
    );
  }
}

HomePage.propTypes = {
  bikes: PropTypes.array.isRequired,
  zones: PropTypes.array.isRequired,
  bikeDetails: PropTypes.object,
  bikesActions: PropTypes.object.isRequired,
  twoFAActions: PropTypes.object.isRequired,
  bikeDetailsActions: PropTypes.object.isRequired,
  bikeTypesActions: PropTypes.object.isRequired,
  zonesActions: PropTypes.object.isRequired,
  initialLocation: PropTypes.object.isRequired,
  initialZoom: PropTypes.number.isRequired,
  currentUserData: PropTypes.object.isRequired,
  height: PropTypes.number,
  width: PropTypes.number,
  userPermissions: PropTypes.array.isRequired,
  bikeTypes: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    zones: state.zones,
    bikes: state.bikes.filter((b) => b.location),
    bikeDetails: state.bikeDetails,
    initialLocation: state.configuration.initialLocation,
    initialZoom: state.configuration.initialZoom,
    userPermissions: state.auth.permissions,
    bikeTypes: state.bikeTypes.list,
    currentUserData: state.auth.currentUserData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    bikesActions: bindActionCreators(bikesActions, dispatch),
    bikeDetailsActions: bindActionCreators(bikeDetailsActions, dispatch),
    zonesActions: bindActionCreators(zonesActions, dispatch),
    bikeTypesActions: bindActionCreators(bikeTypesActions, dispatch),
    twoFAActions: bindActionCreators(twoFAActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(windowDimensions()(HomePage));
