import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { Field, Form } from 'react-final-form';
import FormTextField from "../../form/redux-form/FormTextField";
import routePaths from "../../../routePaths";
import React from "react";
import PropTypes from "prop-types";
import FormActions from "../../form/final-form/FormActions";
import FormCheckBox from "../../form/final-form/FormCheckBox";


const validation = (values) => {
  const errors = {}
  if (!values.name) {
    errors.name = 'Podanie nazwy jest wymagane';
  }
  if (values.sections){
    const filtered = Object.keys(values.sections).reduce((filtered, key) => {
        if (values.sections[key]) {
          filtered[key] = values.sections[key];
        }
      return filtered;
    }, {});
    if(Object.keys(filtered).length === 0){
      errors.sectionReport = 'Wybranie sekcji do raportu jest wymagane';
    }
  }
  return errors;
};


const CreateCustomReportForm = ({ onSubmit, isPreview, sectionsOfReport, submitting, reportTemplate }) => {
  const required = value => (value ? undefined : 'Wymagane')

  return (
    <>
      <Typography variant="h5" gutterBottom>{isPreview ? 'Podgląd szablonu raportu' : 'Nowy szablon raportu'}</Typography>
      <Form
        onSubmit={onSubmit}
        validate={validation}
        initialValues={reportTemplate}
        render={({handleSubmit, pristine, invalid, values, errors }) => (
          <form onSubmit={handleSubmit}>
            <Paper className="config-section" elevation={3}>
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <Field
                  name="name"
                  validate={required}
                  component={FormTextField}
                  readonly={isPreview}
                  label="Nazwa typu"
                />
                {sectionsOfReport.map(
                  item => {
                   return <Field
                      key={`sections-${item.id}`}
                      name={`sections.${item.id}`}
                      type="checkbox"
                      component={FormCheckBox}
                      checked={values.sections ? values.sections[item.id] : false}
                      label={item.name}
                      isError={errors.sectionReport}
                      readonly={isPreview}
                    />
                  }
                ) }
                {errors.sectionReport && <div
                  style={{ color: '#f44336'}}
                >
                  {errors.sectionReport}
                </div>}
              </div>
            </Paper>
            <br/>
            {!isPreview && <FormActions pristine={pristine} invalid={invalid} submitting={submitting}
                          cancelRoutePath={routePaths.service.map}/>
            }
            <br/>
          </form>
        )}
      />
    </>
  );
}

CreateCustomReportForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isPreview: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  initialValues: PropTypes.object,
  sectionsOfReport: PropTypes.arrayOf(PropTypes.object).isRequired,
  reportTemplate: PropTypes.object,
};

export default CreateCustomReportForm;
