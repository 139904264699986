import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Paper, List, ListItem, ListItemText, ListItemSecondaryAction } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { actions } from '../../actions/pricingActions';
import { actions as bikeTypesActions } from '../../actions/bikeTypesActions';
import { actions as cardsActions } from '../../actions/cardsActions';
import routePaths from '../../routePaths';
import ConfirmationIconButton from '../common/ConfirmationIconButton';
import { permissions, hasPermission } from '../../utils/authorization';

class PricingsPage extends Component {
  constructor(props) {
    super(props);

    this.state = { deleting: false };

    this.renderListItem = this.renderListItem.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidMount() {
    this.props.bikeTypeActions.loadBikeTypes();
    this.props.actions.loadAll();
    this.props.cardsActions.load();
  }

  navigateToDetails(id, cardId) {
    if(cardId){
      this.props.history.push(routePaths.cardDetailsPricing.replace(':id', cardId));
    } else {
      this.props.history.push(`${routePaths.pricingDetails}${id}`);
    }
  }

  handleDelete(id) {
    this.setState({ deleting: true });
    this.props.actions.delete(id)
      .finally(() => this.setState({ deleting: false }))
  }

  getSecondaryText(bikeType, card){
    if(!card && !bikeType) return null;
    if(bikeType) return `Typ roweru: ${bikeType ? bikeType.name : 'usunięty'}`
    if(card) return `Karta: ${card ? card.cardProviderName : 'usunięty'}`
    return null;
  }

  renderListItem(pricing, index, readonly) {
    const bikeType = pricing.bikeTypeId ? this.props.bikeTypes.find(bt => bt.id === pricing.bikeTypeId) : null;
    const card = pricing.cardId && this.props.cards.data ? this.props.cards.data.configured.find(card => card.id === pricing.cardId) : null;

    return (
      <ListItem
        key={index}
        button
        onClick={() => this.navigateToDetails(pricing.id, pricing.cardId)}>
        <ListItemText
          primary={pricing.bikeTypeId == null && pricing.cardId == null ? 'Główny' : 'Dodatkowy'}
          secondary={pricing.bikeTypeId == null && pricing.cardId == null ? '' : this.getSecondaryText(bikeType, card)}
        />
        {!readonly && (pricing.bikeTypeId || pricing.cardId) &&
          <ListItemSecondaryAction>
            <ConfirmationIconButton
              isDisabled={this.state.deleting}
              confirmationTitle="Usuwanie cennika"
              confirmationText={`Czy na pewno chcesz usunąć cennik dla ${pricing.bikeTypeId ? `typu roweru ${bikeType ? bikeType.name : pricing.bikeTypeId}` : `karty miejskiej ${card ? card.cardProviderName : pricing.cardId}`}?`}
              onConfirm={() => { this.handleDelete(pricing.id) }}
              onCancel={() => { }}
              tooltip="Usuń cennik"
              aria-label="Usuń cennik"
            >
              <DeleteIcon />
            </ConfirmationIconButton>
          </ListItemSecondaryAction>}
      </ListItem>
    );
  }

  render() {
    const { pricings, userPermissions } = this.props;
    const readonly = hasPermission(userPermissions, permissions.pricingConfiguration.access)
      && !hasPermission(userPermissions, permissions.pricingConfiguration.modify);
    const canConfigureMaxRentals = hasPermission(userPermissions, permissions.adjustMaximumRentalsPerUser)
    const hasDefaultPricing = pricings.find(p => (p.bikeTypeId == null && p.cardId == null)) != null;

    return (
      <div className="page">
        <div style={{ margin: 40 }}>
          <div style={{ textAlign: 'right', marginBottom: 16 }}>
            <Button disabled={!canConfigureMaxRentals} component={Link} to={routePaths.maximumRentalsPerUser}>Maksymalna liczba przejazdów</Button>
            <Button disabled={!hasDefaultPricing} component={Link} to={routePaths.pricingPublic}>Cennik publiczny</Button>
            {!readonly && <Button variant="contained" style={{ marginLeft: 8 }} component={Link} color="primary" to={routePaths.createPricing}>Dodaj cennik</Button>}
          </div>
          <Paper elevation={3} className="list-panel">
            {!pricings.length && <p style={{ textAlign: 'center' }}>Nie zdefiniowano jeszcze żadnego cennika</p>}
            <List>
              {pricings.map((item, index) => this.renderListItem(item, index, readonly))}
            </List>
          </Paper>
        </div>
      </div>
    );
  }
}

PricingsPage.propTypes = {
  history: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  cardsActions: PropTypes.object.isRequired,
  bikeTypeActions: PropTypes.object.isRequired,
  pricings: PropTypes.array.isRequired,
  bikeTypes: PropTypes.array.isRequired,
  userPermissions: PropTypes.array.isRequired,
  cards: PropTypes.array.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    bikeTypeActions: bindActionCreators(bikeTypesActions, dispatch),
    cardsActions: bindActionCreators(cardsActions, dispatch),

  };
}

function mapStateToProps(state) {
  return {
    pricings: state.pricing.list,
    bikeTypes: state.bikeTypes.list,
    cards: state.cards,
    userPermissions: state.auth.permissions,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PricingsPage);
