import moment from 'moment';
import { ReportTableModel } from '../../../models/ReportTableModel';

export class RideRecordModel {
  constructor(data) {
    if (data) {
      this.rideId = data.rideId;
      this.sequentialId = data.sequentialId;
      this.startedAt = data.startedAt;
      this.finishedAt = data.finishedAt;
      this.userMadeReservation = data.userMadeReservation;
      this.startedAtLocation = data.startedAtLocation;
      this.averageSpeed = data.averageSpeed;
      this.traceLength = (data.traceLengthInMeters || 0) / 1000;
      this.rideDuration = data.rideDuration;

      this.bikeNumber = data.bikeNumber;
      this.bikeId = data.bikeId;
      this.bikeLockImei = data.bikeLockImei;

      this.userId = data.userId;
      this.userFullName = data.userFullName;
      this.userPhoneNumber = data.userPhoneNumber;
      this.userEmail = data.userEmail;
      this.userIsTester = data.userIsTester;
      this.userIsDeleted = data.userIsDeleted;

      this.finalFare = data.finalFare;
      this.outstandingPayment = data.outstandingPayment;

      this.pricingCalculation = data.pricingCalculation;
      this.calculatedFare = data.calculatedFare;
      this.fareAdjustments = data.fareAdjustments || [];

      this.operatorId = data.operatorId;
      this.operatorFullName = data.operatorFullName;
      this.attemptLocation = data.attemptLocation;
      this.fareIssueStatus = data.fareIssueStatus;
      this.fareIssueResult = data.fareIssueResult;
      this.fareIssue = data.fareIssue;
      this.ongoingPause = data.ongoingPause;
      this.ongoingPauseDate = data.ongoingPauseDate;
      this.suggestedFinishTime = data.suggestedFinishTime;
      this.suggestedFinishLocation = data.suggestedFinishLocation;
      this.manualFinishInfo = data.manualFinishInfo;
      this.manualRestartInfo = data.manualRestartInfo;
      this.requestedWithSms = data.requestedWithSms;
      this.wasFinishedByOperator = data.wasFinishedByOperator;
      this.nfcCardId = data.nfcCardId;
      this.cardProviderId = data.cardProviderId;
      this.rating = data.rating;
      this.bikeType = data.bikeType;
      this.startedZoneId = data.startedZoneId;
      this.finishedZoneId = data.finishedZoneId;
      this.bikePhotoUrl = data.bikePhotoUrl;
      this.bikePhotoLocation = data.bikePhotoLocation;
    }
  }
}

export class RideReportsSummaryModel {
  constructor(data) {
    data = data || {};
    this.ridesCount = data.ridesCount || 0;
    this.averageSpeed = data.averageSpeed || 0;
    this.averageRating = data.averageRating || 0;
    this.traceLength = (data.traceLengthInMeters || 0) / 1000;
    this.rideDuration = data.rideDuration || 0;
    this.finalFare = data.finalFare || 0;
    this.outstandingPayment = data.outstandingPayment || 0;
  }
}

export class RideReportsModel {
  constructor() {
    this.loading = false;
    this.loadingSummary = false;
    this.summary = new RideReportsSummaryModel();
    this.isSummaryOldData = true;
    this.pagedData = new ReportTableModel(
      { pageNumber: 1, pageSize: 100 },
      [{ id: 'finishedAt', value: 'desc' }],
      [
        { id: 'dateFrom', value: moment().subtract(5, 'days').format() },
        { id: 'userIsTester', value: YesNoOptions.false.value }
      ],
    );
  }
}

export const YesNoOptions = {
  true: { value: 'true', name: 'TAK' },
  false: { value: 'false', name: 'NIE' },
};

export const FareIssueStatus = {
  opened: { value: 'opened', name: 'Otwarta' },
  resolved: { value: 'resolved', name: 'Zamknięta' },
};

export const FareIssueResult = {
  acknowledged: { value: 'acknowledged', name: 'Uznana' },
  rejected: { value: 'rejected', name: 'Odrzucona' },
};

export const ParseBikeTypesToSelect = (bikeTypes) => {
  const options = {}
  bikeTypes.forEach(x => {
    options[x.id] = {value: x.id, name: x.name}
  })
  return options;
}

export const ParseZonesToSelect = (zones) => {
  const options = {}
  const emptyGuid = "00000000-0000-0000-0000-000000000000"
  options[emptyGuid] = {value: emptyGuid, name: "Poza strefą"}
  if(!zones) return;
  zones.filter(x => x.type === 'preferredBikeReturnZone').forEach(x => {
    options[x.id] = {value: x.id, name: x.name}
  })
  return options;
}

export const DaysOfWeek = {
  monday: { value: 1, name: 'Poniedziałek'},
  tuesday: { value: 2, name: 'Wtorek'},
  wednesday: { value: 3, name: 'Środa'},
  thursday: { value: 4, name: 'Czwartek'},
  friday: { value: 5, name: 'Piątek'},
  saturday: { value: 6, name: 'Sobota'},
  sunday: { value: 0, name: 'Niedziela'},
}
