import apiBase from "./apiBase";

class BikesApi {
  static getAllBikes(params) {
    return apiBase.create().get("/bikes", params);
  }

  static registerNewBike(data) {
    return apiBase.create().post(`/bikes`, data);
  }

  static getBikesDetails(bikeId) {
    return apiBase.create().get(`/bikes/${bikeId}`);
  }

  static getBikeAlerts(bikeId) {
    return apiBase.create().get(`/bikes/${bikeId}/alerts`);
  }

  static getBikeHistory(bikeId) {
    return apiBase.create().get(`/bikes/${bikeId}/history`);
  }

  static getBikeHistoryReport(bikeId) {
    return apiBase.create().get(`/bikes/${bikeId}/history/export`, null, { responseType: 'blob', timeout: 120000 });
  }

  static getBikeServiceHistory(bikeId) {
    return apiBase.create().get(`/bikes/${bikeId}/service`);
  }

  static updateBikeDetails(bikeId, data) {
    return apiBase.create().put(`/bike/${bikeId}`, data);
  }

  static decommissionBike(bikeId) {
    return apiBase.create().post(`/bike/${bikeId}/decommission`);
  }

  static confirmService(bikeId, note) {
    return apiBase.create().post(`/bikes/${bikeId}/confirm-service`, { note });
  }

  static sendLockClosedCommand(data) {
    return apiBase.create().post("/operations/lock/close", data);
  }

  static sendReportLockStatusCommand(data) {
    return apiBase.create().post("/operations/lock/reportStatus", data);
  }

  static sendCustomLockCommand(data) {
    return apiBase.create().post("/operations/lock/sendCommand", data);
  }

  static putOutOfService(bikeId, restrictionReason) {
    return apiBase
      .create()
      .post(`/bikes/${bikeId}/restrictRentals`, { restrictionReason });
  }

  static addNote(bikeId, note) {
    return apiBase
      .create()
      .post(`/bikes/${bikeId}/note`, { note });
  }

  static putInService(bikeId) {
    return apiBase
      .create()
      .post(`/bikes/${bikeId}/allowRentals`, {});
  }

  static deactivateAlarm(bikeId) {
    return apiBase
      .create()
      .post(`/bikes/${bikeId}/deactivateAlarm`);
  }

  static getBikesListReport() {
    return apiBase.create().get("/bikes/report/export", null,{ responseType: 'blob' });
  }

}

export default BikesApi;
