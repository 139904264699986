import { Field, Form } from 'react-final-form';
import React from "react";
import PropTypes from "prop-types";
import FormSelect from "../../form/redux-form/FormSelect";
import FormDateField from "../../form/final-form/FormDateField";
import moment from "moment/moment";

const hourOptionsSelect = [
  {
    name: '00:00-02:00',
    value: 0,
  },
  {
    name: '02:00-04:00',
    value: 1,
  },
  {
    name: '04:00-06:00',
    value: 2,
  },
  {
    name: '06:00-08:00',
    value: 3,
  },
  {
    name: '08:00-10:00',
    value: 4,
  },
  {
    name: '10:00-12:00',
    value: 5,
  },
  {
    name: '12:00-14:00',
    value: 6,
  },
  {
    name: '14:00-16:00',
    value: 7,
  },
  {
    name: '16:00-18:00',
    value: 8,
  },
  {
    name: '18:00-20:00',
    value: 9,
  },
  {
    name: '20:00-22:00',
    value: 10,
  },
  {
    name: '22:00-00:00',
    value: 11,
  },
]

const validation = (values) => {
  const errors = {}

  if (!values.date) {
    errors.date = 'Pole jest wymagane';
  } else {
    const isSameOrBefore = !moment().isBefore(values.date, 'day')
    if(!isSameOrBefore) {
      errors.date = 'Data z przyszłości';
    }
  }

  if (values.timeRange === undefined || values.timeRange === null) {
    errors.timeRange = 'Pole jest wymagane';
  } else {
    if (values.date) {
      const isBefore = !moment().isAfter(values.date, 'day')
      if (isBefore) {
        const dateTimeRange = (new Date().getHours()) / 2
        if (values.timeRange > dateTimeRange) {
          errors.timeRange = 'Godzina z przyszłości';
        }
      }
    }
  }

  return errors;
};


const RelocationHistoryReportForm = ({ onSubmit }) => {

  return (
    <>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          date: moment()
        }}
        validate={validation}
        render={({handleSubmit}) => (
          <form onSubmit={handleSubmit} id="relocationHistoryReportGenerateForm">
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <Field
                  name="date"
                  component={FormDateField}
                  label="Data"
                  disableFuture
                />
                <Field
                  name="timeRange"
                  component={FormSelect}
                  values={hourOptionsSelect}
                  label="Godzina"
                />
              </div>
          </form>
        )}
      />
    </>
  );
}

RelocationHistoryReportForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default RelocationHistoryReportForm;
