import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Button from '@material-ui/core/Button';
import ConfirmationIconButton from '../common/ConfirmationIconButton';
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { actions } from '../../actions/bikeTypesActions';
import { connect } from 'react-redux';
import routePaths from '../../routePaths';
import EmptyState from '../common/EmptyState';
import './BikeTypesListPageStyles.scss';

class BikeTypesListPage extends React.Component {
  constructor(props) {
    super(props);
    this.renderBikeListItem = this.renderBikeListItem.bind(this);
  }

  componentDidMount() {
    this.props.actions.loadBikeTypes();
  }

  handleNavigateToDetails(id) {
    this.props.history.push(`${routePaths.bikeTypes}/${id}`);
  }

  handleDeleteConfirmed(id) {
    this.props.actions.deleteBikeType(id);
  }

  renderBikeListItem(bikeType, index) {
    return (
      <ListItem
        key={index}
        button
        onClick={() => this.handleNavigateToDetails(bikeType.id)}>
        <ListItemText primary={bikeType.name} />
        <ListItemSecondaryAction>
          <ConfirmationIconButton
            confirmationTitle="Usuwanie typu roweru"
            confirmationText={`Czy na pewno chcesz usunąć typ roweru ${bikeType.name}?`}
            onConfirm={() => this.handleDeleteConfirmed(bikeType.id)}
            onCancel={() => { }}
            tooltip="Usuń typ rowerów"
            aria-label="Usuń typ rowerów"
          >
            <DeleteIcon />
          </ConfirmationIconButton>
        </ListItemSecondaryAction>
      </ListItem>
    );
  }

  render() {
    const { bikeTypes } = this.props;
    return (
      <div className="page">
        <div style={{ margin: 40 }}>
          <div style={{ textAlign: 'right', marginBottom: 16 }}>
            <Button variant="contained" component={Link} color="primary" to={routePaths.createBikeType}>Dodaj typ rowerów</Button>
          </div>
          <Paper elevation={3} className="list-panel">
            <List>
              {bikeTypes.length
                ? bikeTypes.map(this.renderBikeListItem)
                : <EmptyState message={'Brak danych'} />}
            </List>
          </Paper>
        </div>
      </div>
    );
  }
}

BikeTypesListPage.propTypes = {
  actions: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  bikeTypes: PropTypes.array.isRequired,
};


function mapStateToProps(state) {
  return {
    bikeTypes: state.bikeTypes.list,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BikeTypesListPage);
