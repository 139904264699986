import React from 'react';
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles({
    container: {
      padding: "10px 15px",
      minWidth: 200,
    },
});

const AccountSectionDetailsView = ({children, flex, style}) => {
  const classes = useStyles()
  return (
    <div className={classes.container} style={{flex: flex, ...style}}>
      {children}
    </div>
  );

}

AccountSectionDetailsView.defaultProps = {
  style: {},
}

AccountSectionDetailsView.propTypes = {
  children: PropTypes.node.isRequired,
  flex: PropTypes.number.isRequired,
  style: PropTypes.object
};


export default AccountSectionDetailsView;
