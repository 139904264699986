import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from '../../../reducers/reports/rides/actions';
import { actions as bikeTypesActions } from '../../../actions/bikeTypesActions';
import { actions as zonesActions } from '../../../actions/zonesActions';
import ReportTable from '../../common/reportTable/ReportTable';
import FiltersPanel from './FiltersPanel';
import SummaryPanel from './SummaryPanel';
import { getColumns } from './Columns';
import './styles.scss';
import Heatmap from './Heatmap';
import CloseIcon from '@material-ui/icons/Close';
import { Button, Dialog, DialogContent, AppBar, Toolbar, IconButton, Typography } from '@material-ui/core';
import { permissions, hasPermission } from '../../../utils/authorization';
import AdditionalFilters from "./AdditionalFilters";

class RidesDetailsReportsPage extends Component {
  constructor(props) {
    super(props);
    this.state = { heatmapOpened: false };
    this.handleFiltersChange = this.handleFiltersChange.bind(this);
    this.handleSortChange = this.handleSortChange.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
    this.handleExportToSpreadsheet = this.handleExportToSpreadsheet.bind(this);
    this.refreshSummaryHandler = this.refreshSummaryHandler.bind(this);
  }

  componentDidMount() {
    const rides = this.props.rides;
    this.props.actions.loadFreshRideReports(1, rides.paging.pageSize, rides.sortedBy, rides.filteredBy);
    this.props.bikeTypesActions.loadBikeTypes();
    this.props.zonesActions.load()
  }

  handleSortChange(sortBy) {
    const rides = this.props.rides;
    this.props.actions.loadFreshRideReports(1, rides.paging.pageSize, sortBy, rides.filteredBy);
  }

  handleFiltersChange(filterBy) {
    const rides = this.props.rides;
    this.props.actions.loadFreshRideReports(1, rides.paging.pageSize, rides.sortedBy, filterBy);
  }

  handleExportToSpreadsheet() {
    const rides = this.props.rides;
    return this.props.actions.requestExcelExport(rides.sortedBy, rides.filteredBy);
  }

  handlePageChange(nextPage) {
    const rides = this.props.rides;
    this.props.actions.loadNextRideReports(nextPage,rides.filteredBy);
  }

  handlePageSizeChange(nextPageSize) {
    const rides = this.props.rides;
    this.props.actions.loadFreshRideReports(1, nextPageSize, rides.sortedBy, rides.filteredBy);
  }

  renderHeatmapDialog() {
    const { rides } = this.props;
    return (
      <Dialog open={this.state.heatmapOpened} fullScreen>
        <AppBar style={{ position: 'relative' }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={() => this.setState({ heatmapOpened: false })} aria-label="Close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" style={{ flex: 1 }}>
              Mapa ciepła
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ marginTop: 16 }}>
          <Heatmap dataFilter={rides.filteredBy} />
        </DialogContent>
      </Dialog >
    );
  }

  refreshSummaryHandler() {
    const rides = this.props.rides;
    this.props.actions.loadFreshRideSummaryReports(rides.filteredBy);
}

  render() {
    const { rides, operatorPermissions, bikeTypes, zones } = this.props;
    return (
      <div className="page">
        <div style={{ margin: 40, marginBottom: 0 }}>
          {this.state.heatmapOpened && this.renderHeatmapDialog()}
          <FiltersPanel filteredBy={rides.filteredBy} onFilteredChange={this.handleFiltersChange} bikeTypes={bikeTypes} zones={zones} />
          <SummaryPanel
            summary={this.props.summary}
            showPaymentsSummaries={hasPermission(operatorPermissions, permissions.accessPaymentsSummaries)}
            refreshHandler={this.refreshSummaryHandler}
            isLoading={this.props.loadingSummary}
            isSummaryOldData={this.props.isSummaryOldData}
          />
          <AdditionalFilters onFilteredChange={this.handleFiltersChange} filteredBy={rides.filteredBy}/>
          <Paper style={{ padding: 16 }}>
            <ReportTable
              style={{ height: "calc(100vh - 300px)" }}
              columns={getColumns(hasPermission(this.props.operatorPermissions, permissions.clients), bikeTypes, zones)}
              data={rides.records}
              filteredBy={rides.filteredBy}
              sortedBy={rides.sortedBy}
              paging={rides.paging}
              onFilteredChange={this.handleFiltersChange}
              onPageChange={this.handlePageChange}
              onPageSizeChange={this.handlePageSizeChange}
              onSortedChange={this.handleSortChange}
              showExportToSpreadsheet
              onExportToSpreadsheet={this.handleExportToSpreadsheet}
              additionalToolbarButtons={[<Button key={"openHeatmapButton"} onClick={() => { this.setState({ heatmapOpened: true }); }} >Mapa ciepła</Button>]}
              loading={rides.loading}
              bikeTypes={bikeTypes}
            />
          </Paper>
        </div >
      </div >
    );
  }
}

RidesDetailsReportsPage.propTypes = {
  actions: PropTypes.object.isRequired,
  bikeTypesActions: PropTypes.object.isRequired,
  zonesActions: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  operatorPermissions: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  loadingSummary: PropTypes.bool,
  isSummaryOldData: PropTypes.bool,
  summary: PropTypes.object,
  rides: PropTypes.object,
  zones: PropTypes.array.isRequired,
  bikeTypes: PropTypes.array.isRequired,
};

RidesDetailsReportsPage.defaultProps = {
  loading: false,
  loadingSummary: false,
  isSummaryOldData: true,
  rides: {},
  summary: {},
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    bikeTypesActions: bindActionCreators(bikeTypesActions, dispatch),
    zonesActions: bindActionCreators(zonesActions, dispatch)
  };
}

function mapStateToProps(state) {
  const reportState = state.reports.rides;
  return {
    loading: reportState.loading,
    loadingSummary: reportState.loadingSummary,
    isSummaryOldData: reportState.isSummaryOldData,
    summary: reportState.summary,
    rides: reportState.pagedData,
    operatorPermissions: state.auth.permissions,
    bikeTypes: state.bikeTypes.list,
    zones: state.zones,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RidesDetailsReportsPage);
