import React from 'react';
import PropTypes from 'prop-types';
import TextFilter from '../../common/reportTable/filters/TextFilter';
import './styles.scss';

class FiltersPanel extends React.Component {
  constructor(props) {
    super(props);
    this.handleDelete = this.handleDelete.bind(this);
  }

  getFilterValue(id) {
    return this.props.filteredBy.find(f => f.id === id);
  }

  handleDelete(deleteFunction) {
    this.props.onFilteredChange(deleteFunction(this.props.filteredBy));
  }

  render() {
    return (
      <div className="filters-panel flex-row flex-wrap" >
        <TextFilter filter={this.getFilterValue("name")} onDelete={this.handleDelete} readonly label="Nazwa" />
      </div>
    );
  }
}

FiltersPanel.propTypes = {
  onFilteredChange: PropTypes.func,
  filteredBy: PropTypes.array
};

export default FiltersPanel;
