export class BikeTypeModel {
  constructor(id, name, globalBikeTypeId, availableBikeIconUri, occupiedBikeIconUri, internalUseOnly) {
    this.id = id;
    this.name = name;
    this.globalBikeTypeId = globalBikeTypeId;
    this.availableBikeIconUri = availableBikeIconUri;
    this.occupiedBikeIconUri = occupiedBikeIconUri;
    this.availableBikeIconDataUri = null;
    this.occupiedBikeIconDataUri = null;
    this.internalUseOnly = internalUseOnly || false;
  }
}
