import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import FormTextField from '../form/redux-form/FormTextField';
import FormSelect from '../form/redux-form/FormSelect';
import { Link } from 'react-router-dom';
import routePaths from '../../routePaths';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { operatorEditModelValidation } from '../../models/operators/OperatorEditModelValidation';

let OperatorEditForm = ({
  handleSubmit,
  isEdit,
  submitting,
  pristine,
  showRooveeRole
}) => {

  const operatorRole = [
    { value: "client", name: "Zamawiający" },
    { value: "mechanic", name: "Serwisant" },
    { value: "operator", name: "Operator" },
    { value: 'coordinator', name: 'Koordynator' },
    { value: 'operatorAdministrator', name: 'Administrator' },
  ];

  const operatorRooveeRole = [
    { value: "operatorRoovee", name: "Operator - ROOVEE" },
    { value: 'operatorAdministratorRoovee', name: 'Administrator - ROOVEE' }
  ];

  const role = useMemo(() => {
    if (showRooveeRole) return operatorRole.concat(operatorRooveeRole);
    return {...operatorRole};
  }, [showRooveeRole]);

  return (
    <form onSubmit={handleSubmit}>
      <Typography variant="h5" gutterBottom>{isEdit ? 'Edycja danych operatora' : 'Nowy operator'}</Typography>
      <Paper className="config-section" elevation={3}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Field
            name="firstName"
            component={FormTextField}
            label="Imię"
          />
          <Field
            name="lastName"
            component={FormTextField}
            label="Nazwisko"
          />
          <Field
            name="email"
            component={FormTextField}
            label="Email"
          />
          <Field
            name={'role'}
            component={FormSelect}
            label="Rola"
            style={{ width: 200 }}
            values={role}
          />
        </div>
      </Paper>
      <div className="actions">
        <Button style={{ marginRight: 8 }} component={Link} to={routePaths.operators} disabled={submitting}>
          Anuluj
        </Button>
        <Button variant="contained" color="primary" type="submit" disabled={submitting || pristine}>
          {submitting ? 'Zapisywanie...' : 'Zapisz'}
        </Button>
      </div>
    </form>
  );
};

OperatorEditForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  showRooveeRole: PropTypes.bool.isRequired,
};

OperatorEditForm = reduxForm({
  form: 'operatorEditForm',
  validate: operatorEditModelValidation,
  enableReinitialize: true
})(OperatorEditForm);

OperatorEditForm = connect(
  state => ({
    initialValues: state.operators.current,
  })
)(OperatorEditForm);

export default OperatorEditForm;
