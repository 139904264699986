import React from 'react';
import PropTypes from 'prop-types';
import ReactQuill, { Quill } from 'react-quill';
import './contentEditor.scss';

const Link = Quill.import('formats/link');
Quill.register(Quill.import('attributors/style/align'), true);

Link.sanitize = (url) => {
  if (url.startsWith('http')) return url;
  return `http://${url}`;
};

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ align: '' }, { align: 'center' }, { align: 'right' }],
    ['link'],
    ['clean']
  ]
};

const formats = [
  'bold', 'italic', 'underline',
  'list', 'bullet', 'align',
  'link'
];

const ContentEditor = ({ value, onChange, onBlur, readonly }) => (
  <div className="rich-text-editor">
    <ReactQuill
      theme="snow"
      readOnly={readonly}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      modules={modules}
      formats={formats}
    />
  </div>
);

ContentEditor.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  readonly: PropTypes.bool,
};

export default ContentEditor;
