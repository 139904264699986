import React from "react";
import { Paper } from "@material-ui/core";
import {useSelector} from "react-redux";
import TwoFAView from "./TwoFAView";

const AccountPage = () => {

  const currentUserDataState = useSelector(x => x.auth.currentUserData)
  return (
    <div className="page">
      <div style={{ margin: 16, display: 'flex', justifyContent: 'center' }}>
        <Paper style={{ maxWidth: 1200 }}>
          <TwoFAView
            isTOPT2FAEnable={currentUserDataState.isTOPT2FAEnable}
            isLoading={currentUserDataState.isLoading}
          />
        </Paper>
      </div>
    </div>
  );

}

export default AccountPage;
