import React from 'react';
import PropTypes from 'prop-types';
import { Marker } from 'react-google-maps';
import { BikeModel } from '../../models/BikeModel';

const BikeMarker = ({ bike, isSelected, onSelect, displayDefectsInfo, ...other }) => {
  const getIcon = bikeIconUrl => {
    return {
      url: bikeIconUrl,
      /* eslint-disable no-undef */
      scaledSize: new google.maps.Size(32, 32),
    };
  };

  return (
    <Marker
      icon={getIcon(bike.bikeIcon)}
      position={bike.location}
      title={`Rower: ${bike.bikeNumber}`}
      {...other}
      onClick={() => onSelect(bike)}
      animation={isSelected && window.google.maps.Animation.BOUNCE}
      opacity={bike.internalUseOnly || bike.isRentalRestricted ? 0.5 : 1}
      label={displayDefectsInfo && bike.openDefectsCount > 0 ? { text: '🚳', fontSize: "15px" } : null}
      labelStyle={{ opacity: 1 }}
    />
  );
};

BikeMarker.propTypes = {
  bike: PropTypes.instanceOf(BikeModel).isRequired,
  isSelected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  displayDefectsInfo: PropTypes.bool,
};

export default BikeMarker;
