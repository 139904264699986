import React from 'react';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import {Field, formValueSelector, reduxForm} from 'redux-form';
import { connect } from 'react-redux';
import moment from 'moment';
import FormSelect from '../../form/redux-form/FormSelect';
import FormDateTimeField from '../../form/redux-form/FormDateTimeField';
import {
  ReportTypeForDialog,
  ReportTypeClientUserRoleForDialog,
  ReportTypeForOperatorRooveeDialog
} from "../../../models/reports/ReportTypeForDialog";
import {hasPermission, permissions} from "../../../utils/authorization";
import {ReportDataFor} from "../../../models/reports/ReportDataFor";
import {UserRoles} from "../../../models/auth/AuthModel";

export const formValidation = values => {
  const constraints = {
    type: {
      presence: { message: 'Pole jest wymagane' },
    },
  };

  const errors = validate(values, constraints, { fullMessages: false }) || {};
  if (!values.startDate) errors.startDate = 'Data jest wymagana';
  if (!values.endDate) errors.endDate = 'Data jest wymagana';


  if (values.startDate && values.endDate && values.endDate.isBefore(values.startDate)) {
    errors.endDate = 'Data musi być późniejsza';
  }

  return errors;
};




let GenerateReportForm = ({ handleSubmit, customReportList, sourceReportType, reportType,
                            canGenerateReportWithChosenData, userRole }) => {

  const sourceTypeList = [
    {name: 'Standardowy', value: 0},
    {name: 'Własny', value: 1}
  ]

  const AccountingReportIds = [ReportTypeForDialog.dayByDayAccountingReport.value, ReportTypeForDialog.monthlyAccountingReport.value,
    ReportTypeForDialog.rentalByRentalAccountingReport.value];
  if (ReportTypeForDialog.specialMonthlyAccountingReport) {
    AccountingReportIds.push(ReportTypeForDialog.specialMonthlyAccountingReport.value)
  }

  const getReportForDialogByUserRole = (userRole) => {
    switch (userRole){
      case UserRoles.client.value: return ReportTypeClientUserRoleForDialog
      case UserRoles.operatorRoovee.value:
      case UserRoles.operatorAdministratorRoovee.value:
      case UserRoles.operatorAdministrator.value: return {...ReportTypeForDialog, ...ReportTypeForOperatorRooveeDialog}
      default: return ReportTypeForDialog;
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <Field
          name="sourceReportType"
          component={FormSelect}
          label="Typ raportu"
          values={sourceTypeList}
          fullWidth
          disableFuture
        />
      </div>
      <div>
        {sourceReportType === 0 && <Field
          name="type"
          component={FormSelect}
          label="Raport"
          values={getReportForDialogByUserRole(userRole)}
          fullWidth
          disableFuture
        />}
        {canGenerateReportWithChosenData &&
          <Field
            name="generatedFor"
            component={FormSelect}
            label="Dane dla"
            values={ReportDataFor}
            fullWidth
            disableFuture
            readonly={!(AccountingReportIds.includes(reportType))}
          />
        }
        {sourceReportType === 1 && <Field
          name="templateId"
          component={FormSelect}
          label="Szablon"
          values={customReportList}
          fullWidth
          disableFuture
        />}
      </div>
      <div>
        <Field
          name="startDate"
          component={FormDateTimeField}
          label="Od"
          disableFuture
        />
      </div>
      <div>
        <Field
          name="endDate"
          component={FormDateTimeField}
          label="Do"
        />
      </div>
    </form>
  );
};

GenerateReportForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  customReportList: PropTypes.arrayOf(PropTypes.object).isRequired,
  sourceReportType: PropTypes.number,
  reportType: PropTypes.number,
  userRole: PropTypes.string,
  canGenerateReportWithChosenData: PropTypes.bool,
};

GenerateReportForm = reduxForm({
  form: 'generateReport',
  validate: formValidation,
})(GenerateReportForm);

const selector = formValueSelector('generateReport')
GenerateReportForm = connect(
  state => {
    const  sourceReportType = selector(state, 'sourceReportType')
    const reportType = selector(state, 'type')
    return {
      sourceReportType: sourceReportType,
      reportType,
      userRole: state.auth.currentUserData.userRole,
      initialValues: {
        startDate: moment().local().add(-1, 'month'),
        endDate: moment().local(),
        sourceReportType: 0,
        generatedFor: (hasPermission(state.auth.permissions, permissions.reports.generateWithChosenData) ?
          ReportDataFor.all.value : ReportDataFor.operator.value),
      },
    };
  }
)(GenerateReportForm);

function mapStateToProps(state) {
  return {
    canGenerateReportWithChosenData: hasPermission(state.auth.permissions, permissions.reports.generateWithChosenData),
  };
}

export default connect(mapStateToProps)(GenerateReportForm);
