import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles({
    textField: {
      display: 'flex',
      maxWidth: 400,
    },
    submitButton: {
      marginTop: 10,
    }
});

export default useStyles;
