
import React from 'react';
import TextFilter from '../../common/reportTable/filters/TextFilter';
import DateTimeFilter from '../../common/reportTable/filters/DateTimeFilter';
import SelectFilter from '../../common/reportTable/filters/SelectFilter';
import moment from 'moment';
import AccessTimeIcon from '@material-ui/icons/FindInPage';
import CheckedIcon from '@material-ui/icons/Done';
import IconButton from '@material-ui/core/IconButton';
import { Link } from 'react-router-dom';
import routePaths from '../../../routePaths';
import numberFormatter from '../../../utils/numberFormatter';
import {
  YesNoOptions,
  FareIssueStatus,
  ParseBikeTypesToSelect,
} from '../../../reducers/reports/rides/models';

/* eslint react/display-name: 0 */
/* eslint react/prop-types: 0 */
/* eslint react/no-multi-comp: 0 */

export const getColumns = (hasClientsDataPermission, bikeTypes) => {
  let columns = [
    {
      Header: '',
      id: 'show_details',
      Cell: row => (
        <Link to={`${routePaths.ridesReports}/${row.original.rideId}`}>
          <IconButton style={{ height: 24, width: 24, margin: "-3px 3px" }}>
            <AccessTimeIcon />
          </IconButton>
        </Link>),
      width: 50,
      sortable: false,
      Filter: () => null,
    },
    {
      Header: 'Rower',
      id: 'bikeNumber',
      accessor: 'bikeNumber',
      width: 100,
      Filter: ({ filter, onChange }) => <TextFilter multi onChange={onChange} filter={filter} label="filtruj..." />,
    },
    {
      Header: 'Typ Roweru',
      id: 'bikeType',
      accessor:  (ride) => {
        const bikeType = bikeTypes.find(x => x.id === ride.bikeType);
        return bikeType ? bikeType.name : "Nie znaleziono typu"
      },
      width: 100,
      Filter: ({ filter, onChange }) => <SelectFilter onChange={onChange} filter={filter} items={ParseBikeTypesToSelect(bikeTypes)} label="filtruj..." />,
    }];

  if (hasClientsDataPermission) {
    columns = [...columns,
    {
      Header: 'Użytkownik',
      id: 'userFullName',
      accessor: 'userFullName',
      width: 260,
      Filter: ({ filter, onChange }) => <TextFilter onChange={onChange} filter={filter} label="filtruj..." />,
    },
    {
      Header: 'Nr telefonu',
      id: 'userPhoneNumber',
      accessor: 'userPhoneNumber',
      width: 120,
      Filter: ({ filter, onChange }) => <TextFilter onChange={onChange} filter={filter} label="filtruj..." />,
    },
    {
      Header: 'Email',
      id: 'userEmail',
      accessor: 'userEmail',
      width: 150,
      Filter: ({ filter, onChange }) => <TextFilter onChange={onChange} filter={filter} label="filtruj..." />,
    }];
  }
  columns = [...columns,
  {
    Header: 'Zamek',
    id: 'bikeLockImei',
    accessor: 'bikeLockImei',
    width: 130,
    Filter: ({ filter, onChange }) => <TextFilter multi onChange={onChange} filter={filter} label="filtruj..." />,
  },
  {
    Header: 'Rozpoczęto',
    id: 'startedAt',
    accessor: d => d.startedAt ? moment(d.startedAt).local().format('DD.MM.YYYY HH:mm') : null,
    width: 150,
    filterId: 'dateFrom',
    Filter: ({ filter, onChange }) => <DateTimeFilter onChange={onChange} filter={filter} label="filtruj od..." />,
  },
  {
    Header: 'Zakończono',
    id: 'finishedAt',
    accessor: d => d.finishedAt ? moment(d.finishedAt).local().format('DD.MM.YYYY HH:mm') : null,
    width: 150,
    filterId: 'dateTo',
    Filter: ({ filter, onChange }) => <DateTimeFilter onChange={onChange} filter={filter} label="filtruj do..." />,
  },
  {
    Header: 'Rachunek',
    id: 'finalFare',
    accessor: d => `${numberFormatter.format(d.finalFare || 0)} zł`,
    width: 100,
    Filter: () => null,
  },
  {
    Header: 'Do zapłaty',
    id: 'outstandingPayment',
    accessor: d => `${numberFormatter.format(d.outstandingPayment || 0)} zł`,
    width: 100,
    Filter: () => null,
  },
  {
    Header: 'Operator',
    id: 'operatorFullName',
    accessor: 'operatorFullName',
    width: 200,
    Filter: ({ filter, onChange }) => <TextFilter onChange={onChange} filter={filter} label="filtruj..." />,
  },
  {
    Header: 'Przejazd serwisowy',
    id: 'userIsTester',
    accessor: d => d.userIsTester ? <CheckedIcon style={{ height: 24, width: 24, margin: "-3px 3px" }} /> : null,
    width: 120,
    Filter: ({ filter, onChange }) => <SelectFilter onChange={onChange} filter={filter} items={YesNoOptions} label="filtruj..." />,
  },
  {
    Header: 'Reklamacja',
    id: 'fareIssueStatus',
    sortable: false,
    accessor: d => d.fareIssueStatus === FareIssueStatus.opened.value
      ? FareIssueStatus.opened.name
      : d.fareIssueStatus === FareIssueStatus.resolved.value
        ? FareIssueStatus.resolved.name
        : null,
    width: 120,
    Filter: ({ filter, onChange }) => <SelectFilter onChange={onChange} filter={filter} items={FareIssueStatus} label="filtruj..." />,
  },
  {
    Header: 'Zakończony przez operatora',
    id: 'wasFinishedByOperator',
    accessor: d => d.wasFinishedByOperator ? <CheckedIcon style={{ height: 24, width: 24, margin: "-3px 3px" }} /> : null,
    width: 140,
    Filter: ({ filter, onChange }) => <SelectFilter onChange={onChange} filter={filter} items={YesNoOptions} label="filtruj..." />,
  }];

  return columns;
}
