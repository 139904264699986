import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import FormTextField from '../../form/redux-form/FormTextField';
import validate from 'validate.js';

const validation = values => validate(
  values,
  { reason: { presence: { message: 'Wprowadzenie powodu jest wymagane' } } },
  { fullMessages: false });

let DenyListForm = ({ handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit}>
      <div>
        <Field
          name="reason"
          component={FormTextField}
          label="Powód"
          fullWidth
          multiline
          rows={8}
        />
      </div>
    </form>
  );
};

DenyListForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool
};

DenyListForm = reduxForm({
  form: 'addToDenyList',
  validate: validation,
})(DenyListForm);

export default DenyListForm;
