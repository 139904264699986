import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const FormCheckBox = ({
  input,
  label,
  readonly,
  isError,
}) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          style={isError ? { color: '#f44336' } : null}
          checked={input.checked}
          disabled={readonly === true}
          onChange={(e, value) => {
            input.onChange(value);
          }}
          value={input.name}
        />
      }
      label={label}
      style={isError ? { color: '#f44336' } : null}
    />
  );
}

FormCheckBox.propTypes = {
  input: PropTypes.object,
  readonly: PropTypes.bool,
  label: PropTypes.string,
  isError: PropTypes.bool,
};

export default FormCheckBox;
