import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions as authActions } from '../../../actions/authActions';
import PasswordRequireChangeForm from './PasswordRequireChangeForm';
import AnonymousContentPanel from '../AnonymousContentPanel';
import queryString from 'query-string';

const PasswordRequireChangePage = ({ authActions }) => (
  <AnonymousContentPanel>
    <p>Nastąpiła zmiana polityki haseł. W celu dalszego korzystania z panelu operatora, konieczne jest zmienienie hasła</p>
    <PasswordRequireChangeForm
      onSubmit={data => authActions.changePassword(data)}
    />
  </AnonymousContentPanel>
);

PasswordRequireChangePage.propTypes = {
  authActions: PropTypes.object.isRequired,
  token: PropTypes.string,
  submitting: PropTypes.bool,
};

function mapDispatchToProps(dispatch) {
  return {
    authActions: bindActionCreators(authActions, dispatch)
  };
}

function mapStateToProps(state, ownProps) {
  return {
    token: queryString.parse(ownProps.location.search).token,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordRequireChangePage);
