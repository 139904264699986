import React, {useState} from 'react';
import {Paper, Tab, Tabs, Tooltip, Typography} from "@material-ui/core";
import {SupportedLanguageCodes} from "../../models/termsAndConditions/TermsAndConditionsModel";
import FormTextField from "../form/final-form/FormTextField";
import {Field} from "react-final-form";

const MessageForUser = () => {
  const [chooseLang, setChooseLang] = useState(SupportedLanguageCodes.pl.value);
  return(
    <div>
      <Typography variant="h6" gutterBottom>Komunikat dla użytkownika  wyświetlany podczas jazdy</Typography>
      <Paper elevation={3}>
        <Tabs
          value={chooseLang}
          indicatorColor="secondary"
          textColor="secondary"
          onChange={(event, language) => setChooseLang(language) }
        >
          <Tab value={SupportedLanguageCodes.pl.value} label={<Tooltip title="polski"><span>PL</span></Tooltip>} />
          <Tab value={SupportedLanguageCodes.en.value} label={<Tooltip title="angielski"><span>EN</span></Tooltip>} />
          <Tab value={SupportedLanguageCodes.de.value} label={<Tooltip title="niemiecki"><span>DE</span></Tooltip>} />
          <Tab value={SupportedLanguageCodes.ru.value} label={<Tooltip title="rosyjski"><span>RU</span></Tooltip>} />
          <Tab value={SupportedLanguageCodes.cs.value} label={<Tooltip title="czeski"><span>CS</span></Tooltip>} />
          <Tab value={SupportedLanguageCodes.lt.value} label={<Tooltip title="litewski"><span>LT</span></Tooltip>} />
          <Tab value={SupportedLanguageCodes.uk.value} label={<Tooltip title="ukraiński"><span>UK</span></Tooltip>} />
        </Tabs>
        <div style={{ padding: 16 }}>
          <Field
            name={`messageForUser.${chooseLang.toLowerCase()}`}
            type="string"
            component={FormTextField}
            fullWidth
            variant="outlined"

          />
        </div>
      </Paper>
    </div>
  )
}

export default MessageForUser;
