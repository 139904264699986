import initialState from './initialState';
import Immutable from 'seamless-immutable';
import { types } from '../actions/bikeDefectsActions';
import { BikeDefectRecordModel } from '../models/bikeDefects/BikeDefectsModel';
import { ReportTableModel } from '../models/ReportTableModel';

export default function bikeDefectsReducer(state = Immutable(initialState.bikeDefects.list), action) {
  switch (action.type) {
    case types.LOAD_BIKE_DEFECTS: {
      return Immutable.merge(state, {
        loading: true,
        pagedData: ReportTableModel.pageRequested(state.pagedData, action.pageNumber, action.pageSize, action.filterBy, action.sortBy),
      });
    }
    case types.LOAD_FRESH_BIKE_DEFECTS_SUCCESS: {
      const result = action.result;
      return Immutable.merge(state, {
        loading: false,
        pagedData: ReportTableModel.pageReady(state.pagedData, result.totalDataCount, result.data.map(r => new BikeDefectRecordModel(r))),
      });
    }
    case types.LOAD_NEXT_BIKE_DEFECTS: {
      return Immutable.merge(state, {
        pagedData: ReportTableModel.nextPageRequested(state.pagedData, action.pageNumber, action.pageSize),
      });
    }
    case types.LOAD_NEXT_BIKE_DEFECTS_SUCCESS: {
      const result = action.result;
      return Immutable.merge(state, {
        pagedData: ReportTableModel.pageReady(state.pagedData, result.totalDataCount, result.data.map(r => new BikeDefectRecordModel(r))),
      });
    }
    default:
      return state;
  }
}
