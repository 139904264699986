import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import IdleTimer from 'react-idle-timer';
import { useDispatch } from 'react-redux';
import { actions } from '../../actions/authActions';
import { Typography } from '@material-ui/core';
import { number } from 'prop-types';

const IdleCountdown = ({ autoLogoutTimeout }) => {
  const idleTimeout = 4; //seconds
  const countdownIntervalRef = useRef(null);
  const idleTimerRef = useRef(null);
  const dispatch = useDispatch();
  const handleLogout = () => dispatch(actions.signOut());

  useEffect(() => {
    return () => {
      clearInterval(countdownIntervalRef.current);
    };
  }, [autoLogoutTimeout]);

  const [secondsLeft, setSecondsLeft] = useState(null);

  const handleCountdownInterval = () => {
    const idleStart = moment(idleTimerRef.current.getLastIdleTime());
    const now = moment();
    const left = Math.max(autoLogoutTimeout - now.diff(idleStart, 'seconds'), 0);
    setSecondsLeft(left);
    if (left === 0) {
      clearInterval(countdownIntervalRef.current);
      handleLogout();
    }
  };

  const handleOnActive = () => {
    idleTimerRef.current.reset();
    clearInterval(countdownIntervalRef.current);
    setSecondsLeft(null);
  };

  const handleOnIdle = () => {
    countdownIntervalRef.current = setInterval(handleCountdownInterval, 1000);
  };

  return (
    <>
      <IdleTimer
        ref={ref => { idleTimerRef.current = ref }}
        timeout={idleTimeout * 1000}
        onIdle={handleOnIdle}
        onActive={handleOnActive}
        debounce={200}
        events={['keydown', 'mousedown']}
        crossTab={{ type: 'localStorage', emitOnAllTabs: true }}
      />
      {secondsLeft != null &&
        <>
          <div style={{ marginRight: 5 }}>
            <AccessTimeIcon />
          </div>
          <div style={{ marginRight: 10 }}>
            <Typography variant="body2" display="block">Wylogowanie za: </Typography>
            <Typography variant="caption" display="block">{moment.duration(secondsLeft, 'seconds').format('mm:ss', { trim: false })}</Typography>
          </div>
        </>
      }
    </>
  );
}

IdleCountdown.propTypes = {
  autoLogoutTimeout: number.isRequired,
};

export default IdleCountdown;
