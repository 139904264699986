import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from '../../actions/zonesActions';
import { actions as zoneTypeActions } from '../../actions/zoneTypesActions';
import { actions as bikesActions } from '../../actions/bikesActions';
import config from '../../config';
import ZoneDetails from './ZoneDetails';
import ZonesList from './ZonesList';
import { ZoneModel, ZoneTypes, ZoneAreaTypes } from '../../models/zones/ZoneModel';
import windowDimensions from 'react-window-dimensions';
import { withGoogleMap, GoogleMap, InfoWindow, Polygon, Marker, Circle } from 'react-google-maps';
import DrawingManager from 'react-google-maps/lib/components/drawing/DrawingManager';
import withScriptjs from 'react-google-maps/lib/withScriptjs';
import ZoneMarkerDetails from './ZoneMarkerDetails';
import ZoneEditAreaDetails from './ZoneEditAreaDetails';
import BikeMarker from '../map/BikeMarker';
import { Paper, FormControlLabel, Switch } from '@material-ui/core';
import '../map/MapStyles.scss';
import './ZonePageStyles.scss';

const shapeOptions = {
  operationsZone: {
    strokeColor: "#000",
    strokeOpacity: 0.8,
    strokeWeight: 4,
    fillColor: "#607D8B",
    fillOpacity: 0.3,
    clickable: true,
    zIndex: 1,
  },
  preferredBikeReturnZone: {
    strokeColor: "#e9e623",
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: "#e9e623",
    fillOpacity: 0.5,
    clickable: true,
    zIndex: 2,
  },
};

const drawingModes = {
  none: null,
  polygon: 'polygon',
  circle: 'circle',
};

const getMarkerIcon = zoneIconUrl => {
  return {
    url: zoneIconUrl,
    /* eslint-disable no-undef */
    scaledSize: new google.maps.Size(32, 32)
  };
};

const convertPathToArea = polygon => {
  const coordinates = polygon
    .getPath()
    .getArray()
    .map(cr => ({ lat: cr.lat(), lng: cr.lng() }));

  coordinates.push(coordinates[0]);

  return coordinates;
};

const convertCircleToArea = (circle, newRadius) => {
  return {
    radius: newRadius,
    center: {
      lat: circle.getCenter().lat(),
      lng: circle.getCenter().lng(),
    }
  };
};

const _shapes = new Map();
let _mapRef = null;
/* eslint-disable no-undef */
const MapViewRaw = withScriptjs(withGoogleMap(props => {
  const mapOptions = { streetViewControl: false, mapTypeControl: true, clickableIcons: false, mapTypeControlOptions: { position: google.maps.ControlPosition.TOP_RIGHT } };
  return (
    <GoogleMap
      defaultZoom={12}
      defaultCenter={props.initialLocation}
      center={props.initialLocation}
      defaultOptions={mapOptions}
      onClick={props.onClick}
      onBoundsChanged={props.onBoundsChanged}
      ref={ref => _mapRef = ref}
    >
      <DrawingManager
        drawingMode={props.drawingMode === drawingModes.none
          ? null
          : (props.drawingMode === drawingModes.polygon ? google.maps.drawing.OverlayType.POLYGON : google.maps.drawing.OverlayType.CIRCLE)}
        onPolygonComplete={props.onPolygonComplete}
        onCircleComplete={props.onCircleComplete}
        defaultDrawingMode={google.maps.drawing.OverlayType.POLYGON}
        defaultOptions={{
          drawingControl: false,
          drawingControlOptions: {
            position: google.maps.ControlPosition.TOP_CENTER,
            drawingModes: [
              google.maps.drawing.OverlayType.POLYGON,
              google.maps.drawing.OverlayType.CIRCLE,
            ],
          },
          polygonOptions: {
            fillColor: '#FFB74D',
            zIndex: 3,
            fillOpacity: 0.3,
            strokeWeight: 2,
            clickable: true,
          },
          circleOptions: {
            fillColor: '#FFB74D',
            zIndex: 3,
            fillOpacity: 0.3,
            strokeWeight: 2,
            clickable: true,
          },
        }}
      />
      {
        (props.selectedZone && !props.editMarkerLocationMode && !props.editZoneAreaMode) ?
          (<InfoWindow position={props.selectedZone.areaCenter} onCloseClick={props.onInfoCloseClick}>
            <ZoneDetails
              zone={props.selectedZone}
              onSave={props.onSave}
              onDelete={props.onDelete}
              onEdit={props.onEdit}
              onImport={props.onImport}
              onAddMarkerLocation={props.onAddMarkerLocation}
            />
          </InfoWindow>) : null
      }
      {
        props.zones.filter(zone => zone.areaType === ZoneAreaTypes.polygon).map(zone => {
            const path = []
            zone.area.forEach(x => {
              path.push({"lng": x.lng, "lat": x.lat})
            })
            return (
              <Polygon
                key={zone.id}
                path={path}
                options={{
                  ...shapeOptions[zone.type],
                  editable: props.editZoneAreaMode && props.selectedZone && zone.id === props.selectedZone.id
                }}
                ref={c => _shapes.set(zone.id, c)}
                onClick={arg => props.editMarkerLocationMode ? props.onClick(arg) : props.onSelect(zone)}
              />)
          }
        )
      }
      {
        props.zones.filter(zone => zone.areaType === ZoneAreaTypes.circle).map(zone => (
          <Circle
            key={zone.id}
            center={zone.area.center}
            radius={zone.area.radius}
            options={{ ...shapeOptions[zone.type], editable: props.editZoneAreaMode && props.selectedZone && zone.id === props.selectedZone.id }}
            ref={c => _shapes.set(zone.id, c)}
            onClick={arg => props.editMarkerLocationMode ? props.onClick(arg) : props.onSelect(zone)}
            onRadiusChanged={props.onRadiusChanged}
          />))
      }
      {
        props.zones.filter(z => z.marker && (!props.selectedZone || z.id !== props.selectedZone.id)).map(zone => (
          <Marker
            key={`marker${zone.id}`}
            icon={zone.marker.icon ? getMarkerIcon(zone.marker.icon) : null}
            position={zone.marker}
          />))
      }
      {
        props.showBikes
          ? props.bikes.map(b => <BikeMarker key={b.id} bike={b} isSelected={false} onSelect={() => { }} />)
          : null
      }
      {
        (props.editMarkerLocationMode && props.selectedZone.marker) &&
        <Marker
          position={props.selectedZone.marker}
        />
      }
      {
        props.searchResultMarker &&
        <Marker
          position={props.searchResultMarker}
        />
      }
    </GoogleMap >
  );
}));
/* eslint-enable no-undef */

class ZonesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drawingMode: drawingModes.none,
      selectedZone: null,
      editMarkerLocationMode: false,
      editZoneAreaMode: false,
      showBikes: false,
      searchBiasBounds: null,
      searchResultMarker: null,
    };
    this.mapRef = null;
    this.handlePolygonZoneCreated = this.handlePolygonZoneCreated.bind(this);
    this.handleCircleZoneCreated = this.handleCircleZoneCreated.bind(this);
    this.handleCreateZone = this.handleCreateZone.bind(this);
    this.handleImportZone = this.handleImportZone.bind(this);
    this.handleSaveZone = this.handleSaveZone.bind(this);
    this.handleDeleteZone = this.handleDeleteZone.bind(this);
    this.handleZoneSelected = this.handleZoneSelected.bind(this);
    this.handleDeselectZone = this.handleDeselectZone.bind(this);
    this.handleAddMarkerLocation = this.handleAddMarkerLocation.bind(this);
    this.handleMapClick = this.handleMapClick.bind(this);
    this.handleSaveZoneMarker = this.handleSaveZoneMarker.bind(this);
    this.handleEditZoneMarkerCancel = this.handleEditZoneMarkerCancel.bind(this);
    this.handleEditZoneArea = this.handleEditZoneArea.bind(this);
    this.handleEditZoneAreaCancel = this.handleEditZoneAreaCancel.bind(this);
    this.handleUpdateZoneArea = this.handleUpdateZoneArea.bind(this);
    this.handleRadiusChanged = this.handleRadiusChanged.bind(this);
    this.handleBikesSwitchChange = this.handleBikesSwitchChange.bind(this);
    this.handleMapBoundsChanged = this.handleMapBoundsChanged.bind(this);
    this.handleMapSearchCompleted = this.handleMapSearchCompleted.bind(this);
  }

  componentDidMount() {
    this.props.actions.load();
    this.props.zoneTypeActions.loadZoneTypes();
  }

  handleCreateZone(drawingMode) {
    this.setState({ drawingMode, selectedZone: null });
  }

  handleImportZone(name, kmlFile) {
    return this.props.actions.import(name, kmlFile);
  }

  handleSaveZone(data) {
    let selectedZone = Object.assign({}, this.state.selectedZone);
    if (selectedZone.areaType === ZoneAreaTypes.circle) {
      selectedZone.area = Object.assign({}, { center: selectedZone.area.center, radius: data.radius });
      this.setState({ selectedZone });
    }
    this.props.actions.save(new ZoneModel(null, data.name, selectedZone.type, selectedZone.area, selectedZone.areaType, null, data.bikesReturnZoneTypeId, null));
    this.handleDeselectZone();
  }

  handleDeleteZone() {
    this.props.actions.delete(this.state.selectedZone.id);
    this.handleDeselectZone();
  }

  handleZoneSelected(zone) {
    this.setState({ searchResultMarker: null });
    if (!this.state.editMarkerLocationMode && !this.state.editZoneAreaMode) this.setState({ selectedZone: zone, editZoneAreaMode: false });
  }

  handleDeselectZone() {
    if (this.state.selectedZone.polygon) {
      this.state.selectedZone.polygon.setMap(null);
    }
    if (this.state.selectedZone.circle) {
      this.state.selectedZone.circle.setMap(null);
    }
    this.setState({ selectedZone: null });
  }

  handlePolygonZoneCreated(polygon) {
    const mainZoneExists = this.props.zones.some(z => z.type === ZoneTypes.operationsZone.value);
    const area = convertPathToArea(polygon);
    const newZone = {
      area,
      areaType: ZoneAreaTypes.polygon,
      areaCenter: area[0],
      polygon,
      type: mainZoneExists ? ZoneTypes.preferredBikeReturnZone.value : ZoneTypes.operationsZone.value,
    };

    this.setState({ drawingMode: drawingModes.none, selectedZone: newZone });
  }

  handleCircleZoneCreated(circle) {
    const area = { center: { lat: circle.center.lat(), lng: circle.center.lng() }, radius: circle.radius };
    const mainZoneExists = this.props.zones.some(z => z.type === ZoneTypes.operationsZone.value);

    const newZone = {
      area,
      areaType: ZoneAreaTypes.circle,
      areaCenter: area.center,
      circle,
      type: mainZoneExists ? ZoneTypes.preferredBikeReturnZone.value : ZoneTypes.operationsZone.value,
    };

    this.setState({ drawingMode: drawingModes.none, selectedZone: newZone });
  }

  handleAddMarkerLocation() {
    this.setState({ editMarkerLocationMode: true });
  }

  handleEditZoneArea() {
    this.setState({ editZoneAreaMode: true });
  }

  handleEditZoneAreaCancel() {
    this.setState({ selectedZone: null, editZoneAreaMode: false });
    location.reload(); // hack :(
  }

  handleUpdateZoneArea(newRadius) {
    const shape = _shapes.get(this.state.selectedZone.id);
    this.props.actions.updateArea(
      new ZoneModel(
        this.state.selectedZone.id,
        this.state.selectedZone.name,
        this.state.selectedZone.type,
        this.state.selectedZone.areaType === ZoneAreaTypes.polygon ? convertPathToArea(shape) : convertCircleToArea(shape, newRadius),
        this.state.selectedZone.areaType,
        null,
        this.state.bikesReturnZoneTypeId,
        null)
    );
    this.setState({ selectedZone: null, editZoneAreaMode: false });
  }

  handleRadiusChanged() {
    const shape = _shapes.get(this.state.selectedZone.id);
    const selectedZone = Object.assign({}, this.state.selectedZone);
    selectedZone.area = Object.assign({}, { center: selectedZone.area.center, radius: shape.getRadius() });
    this.setState({ selectedZone });
  }

  handleMapClick(location) {
    this.setState({ searchResultMarker: null });
    if (!this.state.editMarkerLocationMode && !this.state.editZoneAreaMode) return;

    const zone = Object.assign({}, this.state.selectedZone);
    zone.marker = {
      lat: location.latLng.lat(),
      lng: location.latLng.lng(),
    };
    this.setState({ selectedZone: zone });
  }

  handleSaveZoneMarker() {
    if (this.state.selectedZone.marker) {
      this.props.actions.setMarkerForZone(this.state.selectedZone.id, this.state.selectedZone.marker);
      this.setState({ selectedZone: null, editMarkerLocationMode: false });
    }
  }

  handleEditZoneMarkerCancel() {
    this.setState({ selectedZone: null, editMarkerLocationMode: false });
  }

  handleBikesSwitchChange(event) {
    if (event.target.checked) this.props.bikesActions.loadAllBikes();
    this.setState({ showBikes: event.target.checked });
  }

  handleMapBoundsChanged() {
    this.setState({ searchBiasBounds: _mapRef.getBounds() });
  }

  handleMapSearchCompleted(searchResults) {
    if (!searchResults || !searchResults.length) return;
    const result = searchResults[0];
    this.setState({ searchResultMarker: result.geometry.location });
    _mapRef.fitBounds(result.geometry.viewport);
  }

  renderBikesSwitch() {
    return (<Paper className="bikes-switch">
      <FormControlLabel
        control={
          <Switch
            checked={this.state.showBikes}
            onChange={this.handleBikesSwitchChange}
          />
        }
        label="Rowery"
      />
    </Paper>);
  }

  render() {
    return (
      <div className="page flex-row" style={{ overflow: 'hidden', position: 'relative' }} >
        {(this.state.selectedZone && this.state.editMarkerLocationMode) &&
          <ZoneMarkerDetails
            zone={this.state.selectedZone}
            onSave={this.handleSaveZoneMarker}
            onCancel={this.handleEditZoneMarkerCancel}
          />
        }
        {
          (this.state.selectedZone && this.state.editZoneAreaMode) &&
          <ZoneEditAreaDetails
            zone={this.state.selectedZone}
            onSave={this.handleUpdateZoneArea}
            onCancel={this.handleEditZoneAreaCancel}
          />
        }

        {this.renderBikesSwitch()}

        <MapViewRaw
          googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&key=${config.GOOGLE_MAPS_API_KEY}&libraries=geometry,drawing`}
          loadingElement={<div style={{ height: '100%', width: 500, background: 'white' }} />}
          containerElement={<div style={{ width: '100%', height: this.props.height - 64 }} />}
          mapElement={<div style={{ width: '100%', height: this.props.height - 64 }} />}
          initialLocation={this.props.initialLocation}
          drawingMode={this.state.drawingMode}
          selectedZone={this.state.selectedZone}
          editMarkerLocationMode={this.state.editMarkerLocationMode}
          editZoneAreaMode={this.state.editZoneAreaMode}
          onPolygonComplete={this.handlePolygonZoneCreated}
          onCircleComplete={this.handleCircleZoneCreated}
          onInfoCloseClick={this.handleDeselectZone}
          onSave={this.handleSaveZone}
          onDelete={this.handleDeleteZone}
          onSelect={this.handleZoneSelected}
          zones={this.props.zones}
          onAddMarkerLocation={this.handleAddMarkerLocation}
          onEdit={this.handleEditZoneArea}
          onClick={this.handleMapClick}
          onImport={this.handleImportZone}
          onRadiusChanged={this.handleRadiusChanged}
          onSuggestionSelected={this.handleSuggestionSelected}
          showBikes={this.state.showBikes}
          bikes={this.props.bikes}
          onBoundsChanged={this.handleMapBoundsChanged}
          searchBiasBounds={this.state.searchBiasBounds}
          onSearchCompleted={this.handleMapSearchCompleted}
          searchResultMarker={this.state.searchResultMarker}
        />
        <ZonesList
          style={{ width: 300, height: this.props.height - 64, padding: 0, overflowY: 'auto' }}
          operationsZones={this.props.zones.filter(z => z.type === ZoneTypes.operationsZone.value)}
          bikeReturnZones={this.props.zones.filter(z => z.type === ZoneTypes.preferredBikeReturnZone.value)}
          onZoneSelect={this.handleZoneSelected}
          onZoneCreate={() => this.handleCreateZone(drawingModes.polygon)}
          onZoneCreateCircle={() => this.handleCreateZone(drawingModes.circle)}
          onZoneImport={this.handleImportZone}
        />
      </div >
    );
  }
}

ZonesPage.propTypes = {
  zones: PropTypes.array.isRequired,
  bikes: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
  bikesActions: PropTypes.object.isRequired,
  zoneTypeActions: PropTypes.object.isRequired,
  initialLocation: PropTypes.object.isRequired,
  height: PropTypes.number,
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    zoneTypeActions: bindActionCreators(zoneTypeActions, dispatch),
    bikesActions: bindActionCreators(bikesActions, dispatch)
  };
}

function mapStateToProps(state) {
  return {
    zones: state.zones,
    bikes: state.bikes.filter(b => b.location),
    initialLocation: state.configuration.initialLocation,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(windowDimensions()(ZonesPage));
