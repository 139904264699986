const constraints = {
  name: { min: 1, max: 50 },
  optionalName: { max: 50 },
  email: { min: 1, max: 70 },
  content: { min: 1, max: 100000 },
  optionalArbitrary: { max: 75 },
  optionalArbitraryLonger: { max: 200 },
  arbitrary: { min: 1, max: 75 },
  arbitraryLonger: { min: 1, max: 200 },
};

module.exports = Object.freeze(constraints);
