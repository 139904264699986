import React, { useEffect } from 'react';
import { actions } from '../../../actions/campaignsActions';
import { actions as cardActions } from '../../../actions/cardsActions';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, FormControl, FormGroup, FormHelperText, Paper, Tooltip, Typography } from '@material-ui/core';
import { useRouteMatch } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import FormTextField from '../../form/final-form/FormTextField';
import FormSwitch from '../../form/final-form/FormSwitch';
import FormActions from '../../form/final-form/FormActions';
import FormSelect from '../../form/final-form/FormSelect';
import { CampaignTarget, campaignValidator } from '../../../models/campaigns/CampaignsModel';
import FormRichTextField from '../../form/final-form/FormRichTextField';
import ConfirmationButton from '../../common/ConfirmationButton';

const buildCardsMap = cards => {
  if (cards == null || cards.configured == null) return {};
  return cards.configured.reduce((acc, card) => {
    let { cardProviderId, cardProviderName } = card;
    return { ...acc, [cardProviderId]: { value: cardProviderId, name: cardProviderName } };
  }, {});
}

const CampaignDetailsPage = () => {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  useEffect(() => {
    dispatch(cardActions.load())
      .then(() => dispatch(actions.loadCampaign(match.params.id)));
  }, [])
  const loading = useSelector(x => x.campaigns.details.fetching);
  const campaign = useSelector(x => x.campaigns.details.data);
  const cards = useSelector(x => x.cards.data);
  const cardsMap = buildCardsMap(cards);
  const readonly = campaign && campaign.executed;

  const handleSaveForm = (data) => {
    return dispatch(actions.updateCampaign(match.params.id, data));
  }

  const handleExecute = () => {
    return dispatch(actions.executeCampaign(match.params.id));
  }

  return (
    <div className="page">
      <div style={{ margin: 40 }}>
        {loading ? <div style={{ width: '100%', textAlign: 'center' }}><CircularProgress size={50} /></div> :
          campaign ?
            <div>
              <Paper style={{ padding: 16 }}>
                <Form
                  onSubmit={handleSaveForm}
                  initialValues={campaign}
                  validate={campaignValidator}
                  render={({ handleSubmit, values, submitting, errors, touched, hasValidationErrors, dirty }) => (
                    <form onSubmit={handleSubmit} id="campaignForm">
                      <div>
                        <div>
                          <Field
                            name="name"
                            readonly={readonly}
                            component={FormTextField}
                            label="Nazwa"
                            fullWidth
                          />
                        </div>
                        <div>
                          <Field
                            name="target"
                            readonly={readonly}
                            component={FormSelect}
                            label="Grupa docelowa"
                            values={CampaignTarget}
                            fullWidth
                          />
                        </div>
                        {
                          values.target === CampaignTarget.cardHolders.value &&
                          <div>
                            <Field
                              name="targetCardProviderId"
                              component={FormSelect}
                              readonly={readonly}
                              label="Karta"
                              values={cardsMap}
                              fullWidth
                            />
                          </div>
                        }
                        <div>
                          <FormControl component="fieldset">
                            <Typography variant="overline" color="textPrimary">Metody wysyłki</Typography>
                            <FormGroup>
                              <Field
                                name="deliveryMethods.email"
                                readonly={readonly}
                                component={FormSwitch}
                                type="checkbox"
                                label="Email"
                                fullWidth
                              />
                              {campaign.emailRecipients != null && <Typography>
                                <strong>Wysłane emaile: {campaign.emailRecipients}</strong>
                              </Typography>}
                              <Field
                                name="deliveryMethods.push"
                                readonly={readonly}
                                component={FormSwitch}
                                type="checkbox"
                                label="Push"
                                fullWidth
                              />
                              {campaign.pushRecipients != null && <Typography>
                                <strong>Wysłane powiadomienia push: {campaign.pushRecipients}</strong>
                              </Typography>}
                              <Field
                                name="deliveryMethods.sms"
                                readonly={readonly || !values.smsAllowed}
                                component={FormSwitch}
                                type="checkbox"
                                label="SMS"
                                fullWidth
                              />
                              {campaign.smsRecipients != null && <Typography>
                                <strong>Wysłane SMS: {campaign.smsRecipients}</strong>
                              </Typography>}
                            </FormGroup>
                            {errors && errors.deliveryMethods && touched
                              && <FormHelperText error>{errors.deliveryMethods._error}</FormHelperText>}
                          </FormControl>
                        </div>
                        <div>
                          <Field
                            name="messageTitle"
                            readonly={readonly}
                            component={FormTextField}
                            label="Tytuł wiadomości"
                            fullWidth
                          />
                        </div>
                        {
                          (values.deliveryMethods.push || values.deliveryMethods.sms) &&
                          <div>
                            <Typography variant="overline" color="textPrimary">Treść push/sms</Typography>
                            <Field
                              name="messagePlain"
                              readonly={readonly}
                              component={FormTextField}
                              label="Treść wiadomości (PUSH/sms)"
                              multiline
                              fullWidth
                            />
                          </div>
                        }
                        {
                          values.deliveryMethods.email &&
                          <div>
                            <Typography variant="overline" color="textPrimary">Treść email</Typography>
                            <Field
                              name="messageFormatted"
                              readonly={readonly}
                              component={FormRichTextField}
                              label="Treść wiadomości"
                              fullWidth
                            />
                          </div>
                        }
                      </div>
                      <FormActions disabled={readonly}>
                        <Tooltip title={hasValidationErrors || dirty ? 'Należy zapisać kampanię przed uruchomieniem' : ""}>
                          <div>
                            <ConfirmationButton
                              variant="contained"
                              color="secondary"
                              confirmationTitle="Uruchomienie kampani"
                              confirmationText="Po zatwierdzeniu tej akcji, wiadomość zostanie wysłana do odbiorców. Kampania zostanie zarchiwizowana i jej edytowanie nie będzie już możliwe"
                              disabled={readonly || submitting || hasValidationErrors || dirty}
                              onConfirm={handleExecute}
                            >
                              Uruchom kampanię
                            </ConfirmationButton>
                          </div>
                        </Tooltip>
                      </FormActions>
                    </form>
                  )}
                />
              </Paper>
            </div>
            : null}
      </div>
    </div >
  );
}


export default CampaignDetailsPage;
