/* eslint-disable react/no-danger */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Paper, Typography, Tabs, Tab, Tooltip } from '@material-ui/core';
import './TermsAndConditionsPage.scss';
import moment from 'moment';
import { SupportedLanguageCodes } from '../../models/termsAndConditions/TermsAndConditionsModel';
import { bindActionCreators } from 'redux';
import { actions } from '../../actions/termsAndConditionsActions';

class TermsAndConditionsDetailsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: SupportedLanguageCodes.pl.value,
    };

    this.handleLanguageChange = this.handleLanguageChange.bind(this);
  }

  componentDidMount() {
    if (this.props.details == null) {
      this.props.actions.load();
    }
  }

  handleLanguageChange(event, language) {
    this.setState({ language });
  }

  render() {
    if (this.props.details == null) return null;
    const { version, publishedAt, contents, additionalImages } = this.props.details;
    return (
      <div className="page padder">
        <Typography variant="h5" gutterBottom>Wersja {version}</Typography>
        <p>Opublikowany {moment(publishedAt).format('DD.MM.YYYY HH:mm')}</p>

        <Paper elevation={3}>
          <Tabs
            value={this.state.language}
            indicatorColor="secondary"
            textColor="secondary"
            onChange={this.handleLanguageChange}
          >
            <Tab value={SupportedLanguageCodes.pl.value} label={<Tooltip title="polski"><span>PL</span></Tooltip>} />
            <Tab value={SupportedLanguageCodes.en.value} label={<Tooltip title="angielski"><span>EN</span></Tooltip>} />
            <Tab value={SupportedLanguageCodes.de.value} label={<Tooltip title="niemiecki"><span>DE</span></Tooltip>} />
            <Tab value={SupportedLanguageCodes.ru.value} label={<Tooltip title="rosyjski"><span>RU</span></Tooltip>} />
            <Tab value={SupportedLanguageCodes.cs.value} label={<Tooltip title="czeski"><span>CS</span></Tooltip>} />
            <Tab value={SupportedLanguageCodes.lt.value} label={<Tooltip title="litewski"><span>LT</span></Tooltip>} />
            <Tab value={SupportedLanguageCodes.uk.value} label={<Tooltip title="ukraiński"><span>UK</span></Tooltip>} />
          </Tabs>
          <div style={{ padding: 16 }}>
            <Typography variant="subtitle1">Treść regulaminu</Typography>
            <div dangerouslySetInnerHTML={{ __html: contents[this.state.language] }} />
            <div style={{ marginTop: 16 }}>
              <Typography variant="subtitle1">Dodatkowa grafika</Typography>
              {
                additionalImages[this.state.language] != null
                  ? <a href={additionalImages[this.state.language]} target="_blank" rel="noopener noreferrer">
                    <img
                      style={{ width: 150, height: 150, objectFit: 'contain' }}
                      src={additionalImages[this.state.language]} />
                  </a>
                  : <Typography variant="caption">Brak</Typography>
              }
            </div>
          </div>
        </Paper>
      </div>
    );
  }
}

TermsAndConditionsDetailsPage.propTypes = {
  details: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    details: state.termsAndConditions.versions.find(t => t.version.toString() === ownProps.match.params.version),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TermsAndConditionsDetailsPage);
