import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { connect } from 'react-redux';
import FormTextField from '../../form/final-form/FormTextField';
import { Field, Form } from 'react-final-form';
import { validate } from 'validate.js';

const validation = values => validate(
  values,
  { name: { presence: { message: 'Wprowadzenie nazwy jest wymagane' } } },
  { fullMessages: false });

const NewCampaignDialog = ({ open, onSave, onCancel, submitting }) => (
  <Dialog open={open}>
    <DialogTitle>Nowa kampania</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Wprowadź nazwę nowej kampanii
      </DialogContentText>
      <Form
        onSubmit={onSave}
        initialValues={{}}
        validate={validation}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} id="newCampaignForm">
            <div>
              <Field
                name="name"
                component={FormTextField}
                label="Nazwa"
                fullWidth
              />
            </div>
          </form>
        )}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel} disabled={submitting} >
        Anuluj
      </Button>
      <Button type="submit" form="newCampaignForm" disabled={submitting} variant='contained' color="primary">
        Utwórz
      </Button>
    </DialogActions>
  </Dialog>
);

NewCampaignDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  availableFunds: PropTypes.number,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default connect()(NewCampaignDialog);
