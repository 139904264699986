import React from 'react';
import PropTypes from "prop-types";
import {ListItem, ListItemSecondaryAction, ListItemText} from "@material-ui/core";
import ConfirmationIconButton from "../../common/ConfirmationIconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import routePaths from "../../../routePaths";
import {useHistory} from "react-router-dom";

const CustomReportItemList = ({report, onRemoveHandler}) => {
  const history = useHistory();

  const handleNavigateToDetails = (id) => {
    history.push(`${routePaths.reportCustomDetails}/${id}`);
  }
  return (
    <ListItem
      key={report.i}
      button
      onClick={() => handleNavigateToDetails(report.id)}>
      <ListItemText primary={`${report.name} ${report.createdBy !== null ? `(${report.createdBy})` : ''}`} />
      <ListItemSecondaryAction>
        <ConfirmationIconButton
          confirmationTitle="Usuwanie szablonu raportu"
          confirmationText={`Czy na pewno chcesz usunąć szablon raportu ${report.name}?`}
          onConfirm={() => onRemoveHandler(report.id)}
          onCancel={() => { }}
          tooltip="Usuń szablon raportu"
          aria-label="Usuń szablon raportu"
        >
          <DeleteIcon />
        </ConfirmationIconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );

}

CustomReportItemList.propTypes = {
  report: PropTypes.object.isRequired,
  onRemoveHandler: PropTypes.func.isRequired,
};

export default CustomReportItemList;
