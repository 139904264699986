import apiBase from "./apiBase";

class CardsApi {
  static getAll() {
    return apiBase.create().get("/operations/cards");
  }

  static create(data) {
    return apiBase.create().post(`/operations/cards`, data);
  }

  static update(cardId, data) {
    return apiBase.create().put(`/operations/cards/${cardId}`, data);
  }

  static removeClientCard(cardId) {
    return apiBase.create().delete(`/operations/cards/clients/${cardId}`);
  }

  static assignCardToClient(userId, cardNumber, cardProviderId) {
    return apiBase
      .create()
      .post(`/operations/cards/clients`, { cardHolderUserId: userId, cardNumber, cardProviderId });
  }

  static getCardClients(cardId, pageNumber, pageSize, sortBy, filterBy) {
    const payload = {
      paging: {
        pageNumber,
        pageSize,
      },
      filterBy: Object.assign(
        {},
        ...filterBy.map((x) => ({ [x.id]: x.value }))
      ),
      sortBy: Object.assign({}, ...sortBy.map((x) => ({ [x.id]: x.value }))),
    };
    return apiBase
      .create()
      .post(`/operations/cards/${cardId}/clients`, payload);
  }

  static getClientCards(userId, pageNumber, pageSize, sortBy, filterBy) {
    const payload = {
      paging: {
        pageNumber,
        pageSize,
      },
      filterBy: Object.assign(
        {},
        ...filterBy.map((x) => ({ [x.id]: x.value }))
      ),
      sortBy: Object.assign({}, ...sortBy.map((x) => ({ [x.id]: x.value }))),
    };
    return apiBase
      .create()
      .post(`/operations/clients/${userId}/cards`, payload);
  }
}

export default CardsApi;
