import apiBase from './apiBase';
import dataUriToBlob from '../utils/dataUriToBlob';

class ZonesApi {
  static getAll() {
    return apiBase.create().get('/operations/zones');
  }

  static importBikeRentalOperationsZone(name, kmlFile) {
    const config = { headers: { 'content-type': 'multipart/form-data' } };
    const formData = new FormData();
    formData.append('name', name);
    formData.append('kmlFile', dataUriToBlob(kmlFile));
    return apiBase.create().post('/operations/zones/bikeRentalOperationsFromKml', formData, config);
  }

  static createBikeRentalOperationsZone(zone) {
    return apiBase.create().post('/operations/zones/bikeRentalOperations', zone);
  }

  static createPreferredBikeReturnZone(zone) {
    return apiBase.create().post('/operations/zones/preferredBikesReturn', zone);
  }

  static delete(zoneId) {
    return apiBase.create().delete(`/operations/zones/${zoneId}`);
  }

  static setMarkerForZone(id, marker) {
    return apiBase.create().put(`/operations/zones/${id}/marker`, marker);
  }

  static updateArea(zone) {
    return apiBase.create().put(`/operations/zones/${zone.id}/area`, zone);
  }
}

export default ZonesApi;
