import React, {useState} from 'react'
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import PropTypes from "prop-types";
import { Form } from 'react-final-form'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {Button, DialogContentText, makeStyles, Typography} from "@material-ui/core";

const useStyles = makeStyles({
  buttonActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 15
  },
  bold: {
    fontWeight: 'bold'
  }
});

const ResendVerificationEmailDialog = ({onSave, onCancel, open, email, result}) => {
  const classes = useStyles()
  const [isCopied, setIsCopied] = useState(false)
  return (
    <Dialog open={open}>
      <DialogTitle>Ponowne wysłanie emaila</DialogTitle>
      <DialogContent>
        {!result.success && <DialogContentText>
          Czy chcesz wysłać ponownie na adres email <span className={classes.bold}>{email}</span> link aktywacyjny?
        </DialogContentText>}
        {result.success && <DialogContentText>
          <CopyToClipboard
            text={result.activationLink}
            onCopy={() => setIsCopied(true)}>
            <Button  variant="outlined" size="small">Skopiuj link aktywacyjny do schowka</Button>
          </CopyToClipboard>
          {isCopied && <Typography>Skopiowano</Typography>}
        </DialogContentText>}
        <Form
          onSubmit={onSave}
          render={({handleSubmit, submitting}) => (
            <>
              <form onSubmit={handleSubmit}>
                <div className={classes.buttonActions}>
                  {!result.success && <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={submitting}
                  >
                    Wyślij
                  </Button>}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onCancel}
                    disabled={submitting}
                  >
                    {result.success ? "Zamknij" : "Anuluj"}
                  </Button>
                </div>
              </form>
            </>
          )}
        />
      </DialogContent>
    </Dialog>
  );
}

ResendVerificationEmailDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  dispatch: PropTypes.func,
  email: PropTypes.string.isRequired,
  result: PropTypes.object.isRequired,
};

export default ResendVerificationEmailDialog;
