import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import PropTypes from "prop-types";
import { Button, DialogContentText, Typography } from "@material-ui/core";
import ChangeUserPhoneNumberForm from "./ChangeUserPhoneNumberForm";
import { CopyToClipboard } from "react-copy-to-clipboard";

let ChangeUserPhoneNumberDialog = ({ onSave, onCancel, open, url }) => {
  const [isCopied, setIsCopied] = useState(false);

  return (
    <Dialog open={open}>
      <DialogTitle>Zmień numer telefonu</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <div>
            Podaj numer telefonu, który chcesz ustawić Użytkownikowi w formacie
            PPXXXXXXXXX, gdzie &ldquo;P&ldquo; to numer prefix, a
            &ldquo;X&ldquo; to numer telefonu, np. &ldquo;48123456789&ldquo;.
          </div>
          <div>
            Liczba cyfr w prefixie uzależniona jest od kraju i może wynosić od 1
            do 3.
          </div>
          {url && (
            <DialogContentText>
              <CopyToClipboard text={url} onCopy={() => setIsCopied(true)}>
                <Button variant="outlined" size="small">
                  Skopiuj link potwierdzający do schowka
                </Button>
              </CopyToClipboard>
              {isCopied && <Typography>Skopiowano</Typography>}
            </DialogContentText>
          )}
        </DialogContentText>
        <ChangeUserPhoneNumberForm
          disabled={!!url}
          onCancel={onCancel}
          onSubmit={onSave}
        />
      </DialogContent>
    </Dialog>
  );
};

ChangeUserPhoneNumberDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  dispatch: PropTypes.func,
  url: PropTypes.string,
};

export default ChangeUserPhoneNumberDialog;
