import validate from 'validate.js';

const genericRequiredMessage = 'Pole jest wymagane';

export const bikeDetailsFormValidation = values => {
  const constraints = {
    bikeNumber: {
      presence: { message: genericRequiredMessage },
    },
    lockImei: {
      presence: { message: genericRequiredMessage },
    },
    lockPhoneNumber: function() {
      return {
        presence: { message: genericRequiredMessage },
        format: {
          pattern: /^\d{11}$/,
          message: "Numer telefonu musi składać się z 11-tu cyfr (MSIDN) na przykład 48333444555."
        }
      };
    },
    lockSimSerialNumber: {
      presence: { message: genericRequiredMessage },
    },
    lockType: {
      presence: { message: genericRequiredMessage },
    },
  };

  return validate(values, constraints, { fullMessages: false });
};
