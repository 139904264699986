import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from '../../../../reducers/reports/clients/actions';
import ReportTable from '../../../common/reportTable/ReportTable';
import FiltersPanel from './FiltersPanel';
import { columns } from './Columns';
import './styles.scss';

class ClientsDenyListReportPage extends Component {
  constructor(props) {
    super(props);
    this.handleFiltersChange = this.handleFiltersChange.bind(this);
    this.handleSortChange = this.handleSortChange.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
  }

  componentDidMount() {
    const clients = this.props.clients;
    this.props.actions.loadFreshDeniedClientReports(1, clients.paging.pageSize, clients.sortedBy, [...clients.filteredBy, { id: 'accessDenied', value: true }]);
  }

  handleSortChange(sortBy) {
    const clients = this.props.clients;
    this.props.actions.loadFreshDeniedClientReports(1, clients.paging.pageSize, sortBy, clients.filteredBy);
  }

  handleFiltersChange(filterBy) {
    const clients = this.props.clients;
    this.props.actions.loadFreshDeniedClientReports(1, clients.paging.pageSize, clients.sortedBy, filterBy.filter(f => f.value));
  }

  handlePageChange(nextPage) {
    this.props.actions.loadNextDeniedClientReports(nextPage);
  }

  handlePageSizeChange(nextPageSize) {
    const clients = this.props.clients;
    this.props.actions.loadFreshDeniedClientReports(1, nextPageSize, clients.sortedBy, clients.filteredBy);
  }

  render() {
    const clients = this.props.clients;
    return (
      <div className="page">
        <div style={{ margin: 40, marginBottom: 0 }}>
          <FiltersPanel filteredBy={clients.filteredBy} onFilteredChange={this.handleFiltersChange} />
          <Paper style={{ padding: 16 }}>
            <ReportTable
              style={{ height: "calc(100vh - 200px)" }}
              columns={columns}
              data={clients.records}
              filteredBy={clients.filteredBy}
              sortedBy={clients.sortedBy}
              paging={clients.paging}
              onFilteredChange={this.handleFiltersChange}
              onPageChange={this.handlePageChange}
              onPageSizeChange={this.handlePageSizeChange}
              onSortedChange={this.handleSortChange}
              loading={clients.loading}
            />
          </Paper>
        </div>
      </div>
    );
  }
}

ClientsDenyListReportPage.propTypes = {
  actions: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  clients: PropTypes.object,
  summary: PropTypes.object,
};

ClientsDenyListReportPage.defaultProps = {
  loading: false,
  clients: {},
  summary: {},
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

function mapStateToProps(state) {
  const reportState = state.reports.clientsDenyList;
  return {
    clients: reportState.pagedData,
    loading: reportState.loading,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientsDenyListReportPage);
