import { makeStyles, TableCell, TableSortLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { AutoSizer, Column, Table } from 'react-virtualized';
import clsx from 'clsx';
import _ from 'lodash';

/* eslint react/prop-types: 0 */
const useStyles = makeStyles((theme) => ({
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  table: {
    // temporary right-to-left patch, waiting for
    // https://github.com/bvaughn/react-virtualized/issues/454
    '& .ReactVirtualized__Table__headerRow': {
      flip: false,
      paddingRight: theme.direction === 'rtl' ? '0 !important' : undefined,
    },
  },
  tableRow: {
    cursor: 'pointer',
  },
  tableRowHover: {
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  tableCell: {
    flex: 1,
  },
  noClick: {
    cursor: 'initial',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));


const VirtualizedTable = ({ columns, rowHeight, onRowClick, headerHeight, onRequestSort, order, orderBy, renderCellContent, ...tableProps }) => {
  const classes = useStyles();

  const getRowClassName = ({ index }) => {
    return clsx(classes.tableRow, classes.flexContainer, {
      [classes.tableRowHover]: index !== -1 && onRowClick != null,
    });
  };

  const cellRenderer = ({ columnIndex, rowData, dataKey }) => {
    const column = columns[columnIndex];
    return (
      <TableCell
        component="div"
        className={clsx(classes.tableCell, classes.flexContainer, { [classes.noClick]: onRowClick == null })}
        variant="body"
        style={{ height: rowHeight }}
        align={column.numeric || false ? 'right' : 'left'}
        padding={column.disablePadding ? 'none' : 'normal'}
      >
        {renderCellContent(rowData, dataKey)}
      </TableCell>
    );
  }

  const headerRenderer = ({ columnIndex }) => {
    const column = columns[columnIndex];
    return (
      <TableCell
        component="div"
        className={clsx(classes.tableCell, classes.flexContainer, classes.noClick)}
        variant="head"
        style={{ height: headerHeight }}
        align={columns[columnIndex].numeric || false ? 'right' : 'left'}
        padding="checkbox"
        sortDirection={orderBy === column.id ? order : false}
      >
        <TableSortLabel
          active={orderBy === column.id}
          direction={orderBy === column.id ? order : 'asc'}
          onClick={createSortHandler(column.id)}
        >
          {column.label}
          {orderBy === column.id ? (
            <span className={classes.visuallyHidden}>
              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
            </span>
          ) : null}
        </TableSortLabel>
      </TableCell>
    );
  }

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const width = useMemo(() => _.sum(columns.map(c => c.width)), [columns]);

  return (
    <AutoSizer>
      {({ height }) => (
        <Table
          height={height - 50}
          width={width}
          rowHeight={rowHeight}
          gridStyle={{ direction: 'inherit' }}
          headerHeight={headerHeight}
          className={classes.table}
          {...tableProps}
          rowClassName={getRowClassName}
        >
          {columns.map(({ id, ...other }, index) => {
            return (
              <Column
                key={id}
                headerRenderer={(headerProps) =>
                  headerRenderer({
                    ...headerProps,
                    columnIndex: index,
                  })
                }
                className={classes.flexContainer}
                cellRenderer={cellRenderer}
                dataKey={id}
                {...other}
              />
            );
          })}
        </Table>
      )}
    </AutoSizer>
  )
}

VirtualizedTable.defaultProps = {
  headerHeight: 48,
  rowHeight: 48,
}

VirtualizedTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      numeric: PropTypes.bool,
      width: PropTypes.number.isRequired,
      disablePadding: PropTypes.bool,
    }),
  ).isRequired,
  headerHeight: PropTypes.number,
  rowHeight: PropTypes.number,
  onRowClick: PropTypes.func,
  onRequestSort: PropTypes.func,
  renderCellContent: PropTypes.func,
  order: PropTypes.string,
  orderBy: PropTypes.string,
};

export default VirtualizedTable;
