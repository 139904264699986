import React from 'react';
import PropTypes from 'prop-types';
import { Switch, FormControlLabel, Typography } from '@material-ui/core';

const FormSwitch = ({
  input,
  label,
  subtitle,
  readonly,
  ...custom
}) => (
  <FormControlLabel
    control={
      <Switch
        checked={input.checked}
        onChange={e => input.onChange(e.target.checked)}
        name={input.name}
        disabled={readonly === true}
      />
    }
    label={subtitle
      ? <>
        <Typography>{label}</Typography>
        <Typography variant="caption" color="textSecondary">{subtitle}</Typography>
      </>
      : label}
    {...custom}
  />
);

FormSwitch.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  subtitle: PropTypes.string,
  readonly: PropTypes.bool,
};

export default FormSwitch;
