import initialState from './initialState';
import Immutable from 'seamless-immutable';
import { types } from '../actions/bikesManagementActions';
import { BikeDetailsModel } from '../models/bikesManagement/BikeDetailsModel';

export default function bikesManagementdetailsReducer(state = Immutable(initialState.bikeManagementDetails), action) {
  switch (action.type) {
    case types.START_BIKE_CREATION: {
      return Immutable(new BikeDetailsModel());
    }
    case types.GET_BIKE_DETAILS_REQUEST_SUCCESS: {
      const { bikeManagementDetails } = action;
      return state.merge(new BikeDetailsModel(
        bikeManagementDetails.id,
        bikeManagementDetails.bikeNumber,
        bikeManagementDetails.bikeSerialNumber,
        bikeManagementDetails.bikeTypeId,
        bikeManagementDetails.icon,
        bikeManagementDetails.lock,
        bikeManagementDetails.bikeId,
      ));
    }
    default:
      return state;
  }
}
