import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

const ZoneMarkerDetails = ({ zone, onSave, onCancel }) => (
  <div style={{position: 'absolute', zIndex: 4, marginTop: 10, marginLeft: 'auto', marginRight: 'auto', left: 0, right: 300, width: 200}}>
    <Paper style={{padding: 10}}>
      Wskaż miejsce znacznika dla strefy <strong>{zone.name}</strong>
      <div className="actions">
        <Button size="medium" onClick={onCancel}>Anuluj</Button>
        <Button color="primary" size="medium" onClick={onSave}>Zapisz</Button>
      </div>
    </Paper>
  </div>
);

ZoneMarkerDetails.propTypes = {
  zone: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ZoneMarkerDetails;