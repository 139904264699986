import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import FormTextField from '../form/redux-form/FormTextField';
import { FeeTypes } from '../../models/configuration/PricingModel';

const PriceFee = ({ field, fields, index, readonly }) => {
  const type = fields.get(index).fee ? fields.get(index).fee.type : '';

  switch (type) {
    case FeeTypes.free.value:
      return null;
    case FeeTypes.fixed.value:
      return (<div>
        <Field
          name={`${field}.fee.rate`}
          type="number"
          component={FormTextField}
          readonly={readonly}
          label="Kwota"
          endAdornmentText="zł"
        />
      </div>);
    case FeeTypes.perInterval.value:
      return (<div>
        <Field
          name={`${field}.fee.rate`}
          type="number"
          component={FormTextField}
          readonly={readonly}
          label="Kwota"
          endAdornmentText="zł"
        />
        <Field
          name={`${field}.fee.interval`}
          type="number"
          component={FormTextField}
          readonly={readonly}
          label="Za każde rozpoczęte"
          style={{ marginLeft: 16 }}
          endAdornmentText="min."
        />
      </div>);
    default:
      return null;
  }
};

PriceFee.propTypes = {
  fields: PropTypes.object,
  field: PropTypes.string,
  index: PropTypes.number,
  readonly: PropTypes.bool,
};

export default PriceFee;
