import initialState from './initialState';
import Immutable from 'seamless-immutable';
import { types } from '../actions/tenantActions';
import { types as pricingTypes } from '../actions/pricingPublicActions';
import TenantModel from '../models/TenantModel';

export default function tenantReducer(state = Immutable(initialState.tenant), action) {
  switch (action.type) {
    case types.GET_TENANT_CONFIG_SUCCESS: {
      const { tenantConfig } = action;
      return Immutable(new TenantModel(tenantConfig));
    }
    case types.RESTRICT_RENTALS_SUCCESS: {
      return state.merge({ areBikeRentalsRestricted: true });
    }
    case types.ALLOW_RENTALS_SUCCESS: {
      return state.merge({ areBikeRentalsRestricted: false });
    }
    case types.UPDATE_AUTO_LOGOUT_SUCCESS: {
      const { timeout } = action;
      return state.merge({ operatorAutoLogoutTimeoutSeconds: timeout });
    }
    case types.UPDATE_MAXIMUM_RENTALS_PER_USER_SUCCESS: {
      const { maximumRentalsPerUser, maximumRentalsPerTester } = action;
      return state.merge({ maximumRentalsPerUser, maximumRentalsPerTester });
    }
    case types.UPDATE_SERVICE_SETTINGS_SUCCESS: {
      const { batteryLevelAlertThresholdPercentage,
        lockDelayAlertThresholdInMinutes,
        maximumTimeBetweenServiceDays,
        longRidePushIntervalInHours,
        outOfZoneAlertThresholdInMinutes,
        automaticBikeRestrictRentalsByDefectQuantity,
        minimumDayToHideBikeAfterLoseGPS,
        messageForUser} = action;
      return state.merge({
        batteryLevelAlertThresholdPercentage,
        lockDelayAlertThresholdInMinutes,
        maximumTimeBetweenServiceDays,
        longRidePushIntervalInHours,
        outOfZoneAlertThresholdInMinutes,
        automaticBikeRestrictRentalsByDefectQuantity,
        minimumDayToHideBikeAfterLoseGPS,
        messageForUser
      });
    }
    case types.UPDATE_ACTIVITY_THRESHOLD_SUCCESS: {
      const { clientsActivityThresholdDays } = action;
      return state.merge({ clientsActivityThresholdDays });
    }
    case pricingTypes.UPDATE_PRICING_PUBLIC_SUCCESS: {
      const { pricing } = action;
      return state.merge({ pricings: { ...state.pricings, [pricing.languageCode]: pricing.priceList } });
    }
    default:
      return state;
  }
}
