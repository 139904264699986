import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import _ from 'lodash';
import './FormImageUploadStyles.scss';
import Typography from '@material-ui/core/Typography';

const provideFile = delegate => event => {
  const file = event.target.files[0];
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => delegate({
    name: file.name,
    content: reader.result
  });
};

const FormFileUpload = ({
  input,
  label,
  mimeFilter,
  meta: { error },
}) => {
  const id = _.uniqueId('fileInput');
  return (
    <div>
      <Typography variant="caption">{label}</Typography>
      <div className="columns">
        <input
          accept={mimeFilter}
          id={id}
          type="file"
          style={{ display: 'none' }}
          onChange={provideFile(input.onChange)}
          onBlur={provideFile(input.onBlur)}
        />
        <label className="column" htmlFor={id}>
          <Button component="span">Wybierz plik</Button>
        </label>
        {input.value && <span className="column">{input.value.name}</span>}
        {error && <span className="error">{error}</span>}
      </div>
    </div>
  );
};

FormFileUpload.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  mimeFilter: PropTypes.string,
  meta: PropTypes.object,
};

export default FormFileUpload;
