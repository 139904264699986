import React from 'react';
import PropTypes from 'prop-types';
import TextFilter from '../common/reportTable/filters/TextFilter';

class CardClientsFiltersPanel extends React.Component {

  constructor(props) {
    super(props);
    this.handleDelete = this.handleDelete.bind(this);
  }

  getFilterValue(id) {
    return this.props.filteredBy.find(f => f.id === id);
  }

  handleDelete(deleteFunction) {
    this.props.onFilteredChange(deleteFunction(this.props.filteredBy));
  }

  render() {
    return (
      <div className="filters-panel flex-row flex-wrap" >
        <TextFilter filter={this.getFilterValue("cardNumber")} onDelete={this.handleDelete} readonly label="Numer karty" />
        <TextFilter filter={this.getFilterValue("firstName")} onDelete={this.handleDelete} readonly label="Imię" />
        <TextFilter filter={this.getFilterValue("lastName")} onDelete={this.handleDelete} readonly label="Nazwisko" />
      </div>
    );
  }
}

CardClientsFiltersPanel.propTypes = {
  onFilteredChange: PropTypes.func,
  filteredBy: PropTypes.array
};

export default CardClientsFiltersPanel;
