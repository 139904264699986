import reportsApi from "../../../api/reportsApi";
import clientsApi from "../../../api/clientsApi";
import { actions as notificationActions } from "../../../actions/notificationActions";

export const types = {
  LOAD_CLIENT_HISTORY_REPORT: "LOAD_CLIENT_HISTORY_REPORT",
  LOAD_CLIENT_HISTORY_REPORT_SUCCESS: "LOAD_CLIENT_HISTORY_REPORT_SUCCESS",
  LOAD_NEXT_CLIENT_HISTORY_EVENTS: "LOAD_NEXT_CLIENT_HISTORY_EVENTS",
  LOAD_NEXT_CLIENT_HISTORY_EVENTS_SUCCESS:
    "LOAD_NEXT_CLIENT_HISTORY_EVENTS_SUCCESS",

  UPLOAD_PARENTAL_CONSENT_FOR_JUVENILE: "UPLOAD_PARENTAL_CONSENT_FOR_JUVENILE",
  UPLOAD_PARENTAL_CONSENT_FOR_JUVENILE_SUCCESS:
    "UPLOAD_PARENTAL_CONSENT_FOR_JUVENILE_SUCCESS",
  REMOVE_PARENTAL_CONSENT_FOR_JUVENILE: "REMOVE_PARENTAL_CONSENT_FOR_JUVENILE",
  REMOVE_PARENTAL_CONSENT_FOR_JUVENILE_SUCCESS:
    "REMOVE_PARENTAL_CONSENT_FOR_JUVENILE_SUCCESS",

  CHANGE_UNDERAGE_STATUS_SUCCESS: "CHANGE_UNDERAGE_STATUS_SUCCESS",

  CHANGE_SUBSCRIBE_CAMPAIGN_SUCCESS: "CHANGE_SUBSCRIBE_CAMPAIGN_SUCCESS",

  ADD_TO_DENY_LIST: "ADD_TO_DENY_LIST",
  ADD_TO_DENY_LIST_SUCCESS: "ADD_TO_DENY_LIST_SUCCESS",
  ADD_TO_DENY_LIST_FAILURE: "ADD_TO_DENY_LIST_FAILURE",

  REMOVE_FROM_DENY_LIST: "REMOVE_FROM_DENY_LIST",
  REMOVE_FROM_DENY_LIST_SUCCESS: "REMOVE_FROM_DENY_LIST_SUCCESS",
  REMOVE_FROM_DENY_LIST_FAILURE: "REMOVE_FROM_DENY_LIST_FAILURE",

  LOAD_GLOBAL_CLIENT_HISTORY_REPORT: "LOAD_GLOBAL_CLIENT_HISTORY_REPORT",
  LOAD_GLOBAL_CLIENT_HISTORY_REPORT_SUCCESS:
    "LOAD_GLOBAL_CLIENT_HISTORY_REPORT_SUCCESS",

  CONFIRM_ASSIGN_PHONE_NUMBER: "CONFIRM_ASSIGN_PHONE_NUMBER",
  CONFIRM_ASSIGN_PHONE_NUMBER_SUCCESS: "CONFIRM_ASSIGN_PHONE_NUMBER_SUCCESS",
  CONFIRM_ASSIGN_PHONE_NUMBER_FAILURE: "CONFIRM_ASSIGN_PHONE_NUMBER_FAILURE",

  CHANGE_USER_PHONE_NUMBER: "CHANGE_USER_PHONE_NUMBER",
  CHANGE_USER_PHONE_NUMBER_SUCCESS: "CHANGE_USER_PHONE_NUMBER_SUCCESS",
  CHANGE_USER_PHONE_NUMBER_FAILURE: "CHANGE_USER_PHONE_NUMBER_FAILURE",

  RESEND_VERIFICATION_EMAIL: "RESEND_VERIFICATION_EMAIL",
  RESEND_VERIFICATION_EMAIL_SUCCESS: "RESEND_VERIFICATION_EMAIL_SUCCESS",
  RESEND_VERIFICATION_EMAIL_FAILURE: "RESEND_VERIFICATION_EMAIL_FAILURE",

  DEACTIVATE_USER_ACCOUNT: "DEACTIVATE_USER_ACCOUNT",
  DEACTIVATE_USER_ACCOUNT_SUCCESS: "DEACTIVATE_USER_ACCOUNT_SUCCESS",
  DEACTIVATE_USER_ACCOUNT_FAILURE: "DEACTIVATE_USER_ACCOUNT_FAILURE",
};

const errorCodesToText = {
  "errorCode.userManagement.mobileLogin.phoneNumberIsAssigned":
    "Numer telefonu jest już przypisany do innego konta",
  "errorCode.userManagement.mobileLogin.phoneNumberIsSet":
    "Numer telefonu został już przypisany do tego konta",
  "errorCode.userManagement.mobileLogin.temporaryPhoneNumberNotExist":
    "Numer telefonu, który ma zostać potwierdzony nie został ustawiony",
  "errorCode.userManagement.mobileLogin.notSamePhoneNumber":
    "Numer telefonu do przypisania nie zgadza się z numerem telefonu wyświetlonym w panelu admina",
};

export const actions = {
  loadClientHistoryReport:
    (clientId, pageNumber, pageSize, sortBy, filterBy) => (dispatch) => {
      dispatch({
        type: types.LOAD_CLIENT_HISTORY_REPORT,
        pageNumber,
        pageSize,
        sortBy,
        filterBy,
      });
      return reportsApi
        .getClientHistory(clientId, pageNumber, pageSize, sortBy, filterBy)
        .then((res) => {
          if (res.ok) {
            dispatch({
              type: types.LOAD_CLIENT_HISTORY_REPORT_SUCCESS,
              result: res.data || {},
            });
          }
        });
    },

  loadGlobalClientHistoryReport: (clientId) => (dispatch) => {
    dispatch({ type: types.LOAD_GLOBAL_CLIENT_HISTORY_REPORT });
    return reportsApi.getGlobalClientHistory(clientId).then((res) => {
      if (res.ok) {
        dispatch({
          type: types.LOAD_GLOBAL_CLIENT_HISTORY_REPORT_SUCCESS,
          result: res.data || {},
        });
      }
    });
  },

  confirmAssignPhoneNumber: (clientId, phoneNumber) => (dispatch) => {
    dispatch({ type: types.CONFIRM_ASSIGN_PHONE_NUMBER });
    return reportsApi
      .confirmAssignPhoneNumber(clientId, { phoneNumber })
      .then((response) => {
        if (response.ok && response.data.success !== false) {
          dispatch({
            type: types.CONFIRM_ASSIGN_PHONE_NUMBER_SUCCESS,
            phoneNumber,
          });
          dispatch(
            notificationActions.showSuccess(
              "Numer telefonu został potwierdzony",
            ),
          );
          return { success: true };
        } else if (response.ok && response.data.success === false) {
          dispatch({
            type: types.CONFIRM_ASSIGN_PHONE_NUMBER_FAILURE,
            result: response.data || {},
          });
          if (response.data.failure && response.data.failure.errorCode) {
            const text = errorCodesToText[response.data.failure.errorCode];
            if (text) {
              dispatch(notificationActions.showError(text));
              return { success: false };
            }
          }
        }
        dispatch(
          notificationActions.showError(
            "Wystąpił błąd podczas potwierdzenia numeru telefonu ",
          ),
        );
        return { success: false };
      });
  },

  changeUserPhoneNumber: (clientId, phoneNumber) => (dispatch) => {
    dispatch({ type: types.CHANGE_USER_PHONE_NUMBER });
    return reportsApi
      .changeUserPhoneNumber(clientId, { phoneNumber })
      .then((response) => {
        if (response.ok && response.data.success !== false) {
          dispatch({
            type: types.CHANGE_USER_PHONE_NUMBER_SUCCESS,
          });
          dispatch(
            notificationActions.showSuccess(
              "Wysłano do użytkownika potwierdzenia zmiany numeru telefonu",
            ),
          );
          return { success: true, url: response.data.url };
        } else if (response.ok && response.data.success === false) {
          dispatch({
            type: types.CHANGE_USER_PHONE_NUMBER_FAILURE,
            result: response.data || {},
          });
          if (response.data.failure && response.data.failure.errorCode) {
            const text = errorCodesToText[response.data.failure.errorCode];
            if (text) {
              dispatch(notificationActions.showError(text));
              return { success: false };
            }
          }
        }
        dispatch(
          notificationActions.showError(
            "Wystąpił błąd podczas zmiany numeru telefonu ",
          ),
        );
        return { success: false };
      });
  },

  resendVerificationEmail: (clientId) => (dispatch) => {
    dispatch({ type: types.RESEND_VERIFICATION_EMAIL });
    return reportsApi.resendVerificationEmail(clientId).then((response) => {
      if (response.ok && response.data.success !== false) {
        dispatch({ type: types.RESEND_VERIFICATION_EMAIL_SUCCESS });
        dispatch(notificationActions.showSuccess("Wysłano link aktywacyjny"));
        return { success: true, activationLink: response.data.activationLink };
      } else if (response.ok && response.data.success === false) {
        dispatch({
          type: types.RESEND_VERIFICATION_EMAIL_FAILURE,
          result: response.data || {},
        });
        if (response.data.failure && response.data.failure.errorCode) {
          const text = errorCodesToText[response.data.failure.errorCode];
          if (text) {
            dispatch(notificationActions.showError(text));
            return { success: false };
          }
        }
      }
      dispatch(
        notificationActions.showError(
          "Wystąpił błąd podczas wysyłki linku aktywacyjnego",
        ),
      );
      return { success: false };
    });
  },

  deactivateUserAccountHandler: (clientId) => (dispatch) => {
    dispatch({ type: types.DEACTIVATE_USER_ACCOUNT });
    return reportsApi.deactivateUserAccount(clientId).then((response) => {
      if (response.ok && response.data.success !== false) {
        dispatch({ type: types.DEACTIVATE_USER_ACCOUNT_SUCCESS });
        dispatch(notificationActions.showSuccess("Deaktywowano użytkownika"));
        return { success: true };
      } else if (response.ok && response.data.success === false) {
        dispatch({
          type: types.DEACTIVATE_USER_ACCOUNT_FAILURE,
          result: response.data || {},
        });
        if (response.data.failure && response.data.failure.errorCode) {
          const text = errorCodesToText[response.data.failure.errorCode];
          if (text) {
            dispatch(notificationActions.showError(text));
            return { success: false };
          }
        }
      }
      dispatch(
        notificationActions.showError(
          "Wystąpił błąd podczas deaktywacji użytkownika",
        ),
      );
      return { success: false };
    });
  },

  verifyClientCallCenterCode: (userId, callCenterCode) => (dispatch) =>
    clientsApi.verifyCallCenterCode(userId, callCenterCode).then((response) => {
      if (response.ok && response.data.success !== false) {
        return response.data.codeIsValid;
      } else {
        dispatch(
          notificationActions.showError(
            `Wystąpił błąd podczas sprawdzania kodu ${response.data && response.data.failure ? response.data.failure.message : ""}`,
          ),
        );
        return null;
      }
    }),

  getRideStatus: (rideId) => (dispatch) =>
    clientsApi.getRideStatus(rideId).then((response) => {
      if (response.ok && response.data.success !== false) {
        return response.data.status;
      } else {
        dispatch(
          notificationActions.showError(
            `Wystąpił błąd podczas sprawdzania kodu ${response.data && response.data.failure ? response.data.failure.message : ""}`,
          ),
        );
        return null;
      }
    }),

  remindCallCenterCode: (userId) => (dispatch) =>
    clientsApi.remindCallCenterCode(userId).then((response) => {
      if (response.ok && response.data.success !== false) {
        dispatch(notificationActions.showSuccess("Pomyślnie wysłano kod"));
      } else {
        dispatch(
          notificationActions.showError(
            "Wystąpił błąd podczas wysyłania sms z kodem",
          ),
        );
        return null;
      }
    }),

  startBikeRentalForClient: (userId, bikeNumber) => (dispatch) =>
    clientsApi.startBikeRentalForClient(userId, bikeNumber).then((response) => {
      if (response.ok) {
        return response.data;
      } else {
        dispatch(
          notificationActions.showError(
            `Wystąpił błąd podczas rozpoczynania przejazdu ${response.data && response.data.failure ? response.data.failure.message : ""}`,
          ),
        );
      }
    }),

  loadNextClientHistoryEvents:
    (clientId, pageNumber) => (dispatch, getState) => {
      dispatch({ type: types.LOAD_NEXT_CLIENT_HISTORY_EVENTS, pageNumber });
      const sortBy = getState().reports.clientHistory.pagedData.sortedBy;
      const filterBy = getState().reports.clientHistory.pagedData.filteredBy;
      const pageSize =
        getState().reports.clientHistory.pagedData.paging.pageSize;
      return reportsApi
        .getClientHistory(clientId, pageNumber, pageSize, sortBy, filterBy)
        .then((res) => {
          if (res.ok) {
            dispatch({
              type: types.LOAD_NEXT_CLIENT_HISTORY_EVENTS_SUCCESS,
              result: res.data || {},
            });
          }
        });
    },

  uploadParentalConsent: (userId, file) => (dispatch) => {
    dispatch({ type: types.UPLOAD_PARENTAL_CONSENT_FOR_JUVENILE });
    return clientsApi.uploadParentalConsent(userId, file).then((res) => {
      if (res.ok) {
        dispatch({
          type: types.UPLOAD_PARENTAL_CONSENT_FOR_JUVENILE_SUCCESS,
          parentalConsentDocumentUrl: res.data.parentalConsentDocumentUrl,
        });
      }
    });
  },

  removeParentalConsent: (userId) => (dispatch) => {
    dispatch({ type: types.REMOVE_PARENTAL_CONSENT_FOR_JUVENILE });
    return clientsApi.removeParentalConsent(userId).then((res) => {
      if (res.ok) {
        dispatch({ type: types.REMOVE_PARENTAL_CONSENT_FOR_JUVENILE_SUCCESS });
      }
    });
  },

  changeUnderageStatus: (userId, status) => (dispatch) =>
    clientsApi.changeUnderageStatus(userId, status).then((response) => {
      if (response.ok) {
        dispatch({ type: types.CHANGE_UNDERAGE_STATUS_SUCCESS, status });
        dispatch(
          notificationActions.showSuccess("Status konta został zaktualizowany"),
        );
        return response.data;
      } else {
        dispatch(
          notificationActions.showError(
            `Wystąpił błąd podczas zmiany statusu ${response.data && response.data.failure ? response.data.failure.message : ""}`,
          ),
        );
      }
    }),

  changeSubscribeCampaignNewsletter: (clientId, isSubscribe) => (dispatch) =>
    clientsApi
      .changeSubscribeCampaignNewsletter(clientId, isSubscribe)
      .then((response) => {
        if (response.ok) {
          dispatch({
            type: types.CHANGE_SUBSCRIBE_CAMPAIGN_SUCCESS,
            isSubscribe,
          });
          dispatch(
            notificationActions.showSuccess(
              "Subskrypcja newslettera została zmieniona",
            ),
          );
          return response.data;
        } else {
          dispatch(
            notificationActions.showError(
              `Wystąpił błąd podczas zmiany subskrypcji newslettera kampanii ${response.data && response.data.failure ? response.data.failure.message : ""}`,
            ),
          );
        }
      }),

  addToDenyList: (clientId, reason) => (dispatch) => {
    dispatch({ type: types.ADD_TO_DENY_LIST, clientId, reason });
    return clientsApi.addToDenyList(clientId, reason).then((res) => {
      if (res.ok && res.data.success !== false) {
        dispatch({ type: types.ADD_TO_DENY_LIST_SUCCESS, clientId, reason });
        dispatch(
          notificationActions.showSuccess("Użytkownik został zablokowany"),
        );
      } else {
        dispatch({ type: types.ADD_TO_DENY_LIST_FAILURE, clientId, reason });
        dispatch(
          notificationActions.showError(
            "Wystąpił błąd podczas blokowania użytkownika",
          ),
        );
      }
    });
  },

  removeFromDenyList: (clientId) => (dispatch) => {
    dispatch({ type: types.REMOVE_FROM_DENY_LIST, clientId });
    return clientsApi.removeFromDenyList(clientId).then((res) => {
      if (res.ok && res.data.success !== false) {
        dispatch({ type: types.REMOVE_FROM_DENY_LIST_SUCCESS, clientId });
        dispatch(
          notificationActions.showSuccess("Użytkownik został odblokowany"),
        );
      } else {
        dispatch({ type: types.REMOVE_FROM_DENY_LIST_FAILURE, clientId });
        dispatch(
          notificationActions.showError(
            "Wystąpił błąd podczas odblokowywania użytkownika",
          ),
        );
      }
    });
  },
};
