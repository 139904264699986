import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

class DropDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: undefined,
      open: false,
      selectedValue: props.input.value
    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleRequestClose = this.handleRequestClose.bind(this);
    this.handleMenuItemClick = this.handleMenuItemClick.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.input) {
      this.setState({ selectedValue: nextProps.input.value });
    }
  }

  handleOpen(event) {
    this.setState({ open: true, anchorEl: event.currentTarget });
  }

  handleRequestClose() {
    this.setState({ open: false });
  }

  handleMenuItemClick(event, value) {
    this.setState(
      { selectedValue: value, open: false },
      () => this.props.onChange(event, value)
    );
  }

  render() {
    const { values, defaultText, input, errorText, disabled } = this.props;

    return (
      <div>
        <Button
          aria-owns={this.state.open ? 'menu' : null}
          aria-haspopup="true"
          size="medium"
          disabled={disabled === true}
          onClick={this.handleOpen}
          style={{ color: errorText ? 'red' : '#4d4d4d', textTransform: 'initial', justifyContent: 'space-between', textAlign: 'left', width: '100%', fontWeight: 'normal' }}
          {...input}
        >
          {values[this.state.selectedValue] ? values[this.state.selectedValue].name : defaultText}
          <ArrowDropDownIcon />
        </Button>
        <Menu
          id="menu"
          anchorEl={this.state.anchorEl}
          open={this.state.open}
          onClose={this.handleRequestClose}
        >
          {Object.keys(values).map((key) =>
            <MenuItem
              key={values[key].value}
              selected={values[key].value === this.state.selectedValue}
              onClick={event => this.handleMenuItemClick(event, values[key].value)}
            >
              {values[key].name}
            </MenuItem>,
          )}
        </Menu>
      </div>
    );
  }
}

DropDown.propTypes = {
  input: PropTypes.object.isRequired,
  defaultText: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  errorText: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default DropDown;
