import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from '../../../actions/reportsActions';
import ReportTable from '../../common/reportTable/ReportTable';
import { columns } from './Columns';
import { Button } from '@material-ui/core';
import GenerateReportDialog from './GenerateReportDialog';
import { hasPermission, permissions } from '../../../utils/authorization';
import {Link} from "react-router-dom";
import routePaths from "../../../routePaths";

class ReportsListPage extends Component {
  state = {
    dialogVisible: false,
    submitting: false,
  };

  componentDidMount() {
    const data = this.props.data;
    this.props.actions.load(1, data.paging.pageSize, data.sortedBy, data.filteredBy);
    this.props.actions.getCustomReportList();
  }

  handleSortChange = sortBy => {
    const data = this.props.data;
    this.props.actions.load(1, data.paging.pageSize, sortBy, data.filteredBy);
  }

  handleFiltersChange = filterBy => {
    const data = this.props.data;
    this.props.actions.load(1, data.paging.pageSize, data.sortedBy, filterBy.filter(f => f.value));
  }

  handlePageChange = nextPage => {
    this.props.actions.loadNext(nextPage);
  }

  handlePageSizeChange = nextPageSize => {
    const data = this.props.data;
    this.props.actions.load(1, nextPageSize, data.sortedBy, data.filteredBy);
  }

  handleDownloadPdf = id => this.props.actions.downloadPdf(id);

  handleDownloadXlsx = id => this.props.actions.downloadXlsx(id);

  handleGenerateDialog = data => {
    this.setState({ submitting: true });
    this.props.actions.generate(data)
      .then(() => {
        const data = this.props.data;
        this.props.actions.load(1, data.paging.pageSize, data.sortedBy, data.filteredBy);
        this.setState({ submitting: false, dialogVisible: false });
      });
  }

  render() {
    const { data, userPermissions } = this.props;
    const generateEnabled = hasPermission(userPermissions, permissions.reports.generate);
    return (
      <div className="page">
        <div style={{ margin: 40, marginBottom: 0 }}>
          {generateEnabled ? <div style={{ textAlign: 'right', marginBottom: 16 }}>
            <Button component={Link} to={routePaths.reportsCustom}>Szablony raportów</Button>
            <Button variant="contained" color="primary" onClick={() => this.setState({ dialogVisible: true })}>Wygeneruj raport</Button>
          </div> : null}
          <Paper style={{ padding: 16 }}>
            <ReportTable
              style={{ height: "calc(100vh - 200px)" }}
              columns={columns(this.handleDownloadPdf, this.handleDownloadXlsx)}
              data={data.records}
              filteredBy={data.filteredBy}
              sortedBy={data.sortedBy}
              paging={data.paging}
              onFilteredChange={this.handleFiltersChange}
              onPageChange={this.handlePageChange}
              onPageSizeChange={this.handlePageSizeChange}
              onSortedChange={this.handleSortChange}
              loading={data.loading}
            />
          </Paper>
          <GenerateReportDialog
            open={this.state.dialogVisible}
            onSave={this.handleGenerateDialog}
            onCancel={() => this.setState({ dialogVisible: false })}
            submitting={this.state.submitting}
          />
        </div>
      </div>
    );
  }
}

ReportsListPage.propTypes = {
  actions: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  data: PropTypes.object,
  summary: PropTypes.object,
  userPermissions: PropTypes.array.isRequired,
};

ReportsListPage.defaultProps = {
  loading: false,
  data: {},
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

function mapStateToProps(state) {
  const reportState = state.reports.list;
  return {
    data: reportState.pagedData,
    loading: reportState.loading,
    userPermissions: state.auth.permissions,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportsListPage);
