import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Field, formValueSelector, reduxForm } from "redux-form";
import FormTextField from "../form/redux-form/FormTextField";
import FormCheckBox from "../form/redux-form/FormCheckBox";
import { Button, Typography } from "@material-ui/core";
import { cardFormValidation } from "../../models/cards/CardValidation";
import routePaths from "../../routePaths";
import { connect } from "react-redux";

let CardDetailsForm = ({
  minimumUserBalanceEnabled,
  initialUserBalanceEnabled,
  handleSubmit,
  submitting,
  initialValues,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <Typography variant="body2">Opis widoczny dla użytkownika</Typography>
      <div>
        <div>
          <Field
            name="publicName.pl"
            component={FormTextField}
            label="Nazwa (PL)"
          />
        </div>
        <div>
          <Field
            name="publicDescription.pl"
            component={FormTextField}
            label="Opis (PL)"
            fullWidth
          />
        </div>
        <div>
          <Field
            name="publicName.en"
            component={FormTextField}
            label="Nazwa (EN)"
          />
        </div>
        <div>
          <Field
            name="publicDescription.en"
            component={FormTextField}
            label="Opis (EN)"
            fullWidth
          />
        </div>
        <div>
          <Field
            name="publicName.de"
            component={FormTextField}
            label="Nazwa (DE)"
          />
        </div>
        <div>
          <Field
            name="publicDescription.de"
            component={FormTextField}
            label="Opis (DE)"
            fullWidth
          />
        </div>
        <div>
          <Field
            name="publicName.ru"
            component={FormTextField}
            label="Nazwa (RU)"
          />
        </div>
        <div>
          <Field
            name="publicDescription.ru"
            component={FormTextField}
            label="Opis (RU)"
            fullWidth
          />
        </div>
        <div>
          <Field
            name="publicName.cs"
            component={FormTextField}
            label="Nazwa (CS)"
          />
        </div>
        <div>
          <Field
            name="publicDescription.cs"
            component={FormTextField}
            label="Opis (CS)"
            fullWidth
          />
        </div>
        <div>
          <Field
            name="publicName.lt"
            component={FormTextField}
            label="Nazwa (LT)"
          />
        </div>
        <div>
          <Field
            name="publicDescription.lt"
            component={FormTextField}
            label="Opis (LT)"
            fullWidth
          />
        </div>
        <div>
          <Field
            name="publicName.uk"
            component={FormTextField}
            label="Nazwa (UK)"
          />
        </div>
        <div>
          <Field
            name="publicDescription.uk"
            component={FormTextField}
            label="Opis (UK)"
            fullWidth
          />
        </div>
      </div>
      <Typography variant="body2" style={{ marginTop: 16 }}>
        Przejazdy
      </Typography>
      <div>
        <div>
          <Field
            name="maximumRentalsPerUser"
            type="number"
            style={{ width: 400 }}
            component={FormTextField}
            label="Maksymalna liczba do rowerów do wypożyczenia"
            parse={(value) => parseInt(value, 10)}
            format={(value) => (value == null ? "" : value)}
          />
        </div>
      </div>
      <Typography variant="body2" style={{ marginTop: 16 }}>
        Minimalna kwota na koncie
      </Typography>
      <div>
        <div>
          <Field
            name="minimumUserBalanceEnabled"
            label="Wymagany minimalny stan środków"
            component={FormCheckBox}
          />
        </div>
        <div>
          <Field
            name="minimumUserBalance"
            type="number"
            component={FormTextField}
            label="Kwota"
            disabled={!minimumUserBalanceEnabled}
            endAdornmentText="zł"
          />
        </div>
      </div>
      <Typography variant="body2" style={{ marginTop: 16 }}>
        Minimalny depozyt inicjalny
      </Typography>
      <div>
        <div>
          <Field
            name="initialUserBalanceEnabled"
            label="Wymagany minimalny depozyt inicjalny"
            component={FormCheckBox}
          />
        </div>
        <div>
          <Field
            name="initialUserBalance"
            type="number"
            component={FormTextField}
            label="Kwota"
            disabled={!initialUserBalanceEnabled}
            endAdornmentText="zł"
          />
        </div>
      </div>
      <Typography variant="body2" style={{ marginTop: 16 }}>
        Benefity
      </Typography>
      <div>
        <div>
          <Field
            name="benefits.discountPercentage"
            type="number"
            component={FormTextField}
            parse={(value) => parseInt(value, 10)}
            label="Procent zniżki"
            endAdornmentText="%"
          />
        </div>
        <div>
          <Field
            name="benefits.discountPercentageForBuyPlans"
            style={{ width: 400 }}
            type="number"
            component={FormTextField}
            parse={(value) => parseInt(value, 10)}
            label="Procent zniżki na zakup planów"
            endAdornmentText="%"
          />
        </div>
        {initialValues.isNfc && (
          <div>
            <Field
              name="benefits.enabledOnlyForNfcStartedRides"
              component={FormCheckBox}
              label="Naliczaj rabat tylko podczas uruchamiania przejazdu kartą"
            />
          </div>
        )}
        <div>
          <Field
            name="enabled"
            component={FormCheckBox}
            label="Integracja aktywna"
          />
        </div>
        <div>
          <Field
            name="noRequirePhoneNumber"
            component={FormCheckBox}
            label="Nie wymagaj numeru telefonu"
          />
        </div>
      </div>
      <div className="actions">
        <Button
          style={{ marginRight: 8 }}
          component={Link}
          to={routePaths.cards}
          disabled={submitting}
        >
          Anuluj
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={submitting}
        >
          {submitting ? "Zapisywanie..." : "Zapisz"}
        </Button>
      </div>
    </form>
  );
};

CardDetailsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  initialValues: PropTypes.object,
  minimumUserBalanceEnabled: PropTypes.bool,
  initialUserBalanceEnabled: PropTypes.bool,
};

CardDetailsForm = reduxForm({
  form: "cardDetails",
  validate: cardFormValidation,
  enableReinitialize: true,
})(CardDetailsForm);

const selector = formValueSelector("cardDetails");
CardDetailsForm = connect((state) => ({
  minimumUserBalanceEnabled: selector(state, "minimumUserBalanceEnabled"),
  initialUserBalanceEnabled: selector(state, "initialUserBalanceEnabled"),
}))(CardDetailsForm);

export default CardDetailsForm;
