import { Paper, Typography } from '@material-ui/core';
import { push } from 'connected-react-router';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../../actions/tenantActions';
import routePaths from '../../../routePaths';
import ClientsActivityThresholdSettingsForm from './ClientsActivityThresholdSettingsForm';

const ClientsActivityThresholdSettingsPage = () => {
  const dispatch = useDispatch();
  const clientsActivityThresholdDays = useSelector(x => x.tenant.clientsActivityThresholdDays);
  const handleSubmit = (data) =>
    dispatch(actions.updateClientsActivityThresholdDays(data.clientsActivityThresholdDays))
      .then(r => { if (r) dispatch(push(routePaths.clientsReport)); });

  return (
    <div className="page" >
      <div style={{ margin: 40 }}>
        <Typography variant="h5" gutterBottom>Konfiguracja</Typography>
        <Paper style={{ padding: 16 }}>
          <ClientsActivityThresholdSettingsForm
            initialValues={{ clientsActivityThresholdDays }}
            onSubmit={handleSubmit}
          />
        </Paper>
      </div>
    </div>
  );
}

export default ClientsActivityThresholdSettingsPage;
