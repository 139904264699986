import { Typography } from '@material-ui/core';
import { func, object } from 'prop-types';
import React from 'react';
import { Form, Field } from 'react-final-form'
import { validate } from 'validate.js';
import routePaths from '../../../routePaths';
import FormActions from '../../form/final-form/FormActions';
import FormTextField from '../../form/final-form/FormTextField';
import MessageForUser from "../../messageForUser/MessageForUser";
import FormCheckBox from "../../form/final-form/FormCheckBox";
import Paper from "@material-ui/core/Paper";

const validation = (values) => {
  const constraints = {};
  if (values.batteryLevelAlertEnabled) {
    constraints.batteryLevelAlertThresholdPercentage = {
      presence: { message: 'Podanie wartości jest wymagane' },
      numericality: {
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 99,
        message: 'Wartość musi być większa lub równa od 0 oraz mniejsza od 100',
      },
    };
  }
  if (values.outOfZoneAlertEnabled) {
    constraints.outOfZoneAlertThresholdInMinutes = {
      presence: { message: 'Podanie wartości jest wymagane' },
      numericality: {
        greaterThanOrEqualTo: 1,
        message: 'Wartość musi być większa lub równa 1',
      },
    };
  }
  if (values.lockDelayAlertEnabled) {
    constraints.lockDelayAlertThresholdInMinutes = {
      presence: { message: 'Podanie wartości jest wymagane' },
      numericality: {
        greaterThanOrEqualTo: 1,
        message: 'Wartość musi być większa lub równa 1',
      },
    };
  }
  if (values.maximumTimeBetweenServiceEnabled) {
    constraints.maximumTimeBetweenServiceDays = {
      presence: { message: 'Podanie wartości jest wymagane' },
      numericality: {
        greaterThanOrEqualTo: 1,
        message: 'Wartość musi być większa lub równa 1',
      },
    }
  }
  if (values.longRidePushEnabled) {
    constraints.longRidePushIntervalInHours = {
      presence: { message: 'Podanie wartości jest wymagane' },
      numericality: {
        greaterThanOrEqualTo: 1,
        message: 'Wartość musi być większa lub równa 1',
      },
    }
  }

  if (values.automaticBikeRestrictRentalsByDefectQuantityEnabled) {
    constraints.automaticBikeRestrictRentalsByDefectQuantity = {
      presence: { message: 'Podanie wartości jest wymagane' },
      numericality: {
        greaterThanOrEqualTo: 1,
        message: 'Wartość musi być większa lub równa 1',
      },
    }
  }

  if (values.minimumDayToHideBikeAfterLoseGPSEnabled) {
    constraints.minimumDayToHideBikeAfterLoseGPS = {
      presence: { message: 'Podanie wartości jest wymagane' },
      numericality: {
        greaterThanOrEqualTo: 1,
        message: 'Wartość musi być większa lub równa 1',
      },
    }
  }

  return validate(values, constraints,{ fullMessages: false });
};

const ServiceSettingsForm = ({ onSubmit, initialValues }) => (
  <Form
    onSubmit={onSubmit}
    initialValues={initialValues}
    validate={validation}
    render={({ handleSubmit, pristine, submitting, invalid }) => (
      <form onSubmit={handleSubmit}>
        <Paper style={{ padding: 16, marginBottom: 15 }}>
          <Typography variant='overline' display='block'>Próg alertu naładowania baterii zamka</Typography>
          <Field
            name="batteryLevelAlertEnabled"
            label="Włączony"
            type='checkbox'
            component={FormCheckBox}
            fullWidth
            variant="outlined"
          />
          <Field
            name="batteryLevelAlertThresholdPercentage"
            component={FormTextField}
            parse={value => parseInt(value, 10)}
            format={value => value == null ? '' : value}
            endAdornmentText="%"
            fullWidth
            variant="outlined"
          />
        </Paper>
        <Paper style={{ padding: 16, marginBottom: 15 }}>
          <Typography variant='overline' display='block'>Próg czasowy alertu przebywania poza strefą</Typography>
          <Field
            name="outOfZoneAlertEnabled"
            label="Włączony"
            type="checkbox"
            component={FormCheckBox}
            fullWidth
            variant="outlined"
          />
          <Field
            name="outOfZoneAlertThresholdInMinutes"
            type="number"
            component={FormTextField}
            parse={value => parseInt(value, 10)}
            format={value => value == null ? '' : value}
            endAdornmentText="minuty"
            fullWidth
            variant="outlined"
          />
        </Paper>
        <Paper style={{ padding: 16, marginBottom: 15 }}>
          <Typography variant='overline' display='block'>Próg czasowy alertu opóźnienia komunikacji</Typography>
          <Field
            name="lockDelayAlertEnabled"
            label="Włączony"
            type="checkbox"
            component={FormCheckBox}
            fullWidth
            variant="outlined"
          />
          <Field
            name="lockDelayAlertThresholdInMinutes"
            type="number"
            component={FormTextField}
            parse={value => parseInt(value, 10)}
            format={value => value == null ? '' : value}
            endAdornmentText="minuty"
            fullWidth
            variant="outlined"
          />
        </Paper>
        <Paper style={{ padding: 16, marginBottom: 15 }}>
          <Typography variant='overline' display='block'>Maksymalny dopuszalny czas pomiędzy serwisami</Typography>
          <Field
            name="maximumTimeBetweenServiceEnabled"
            label="Włączony"
            type="checkbox"
            component={FormCheckBox}
            fullWidth
            variant="outlined"
          />
          <Field
            name="maximumTimeBetweenServiceDays"
            type="number"
            component={FormTextField}
            parse={value => parseInt(value, 10)}
            format={value => value == null ? '' : value}
            endAdornmentText="dni"
            fullWidth
            variant="outlined"
          />
        </Paper>
        <Paper style={{ padding: 16, marginBottom: 15 }}>
          <Typography variant='overline' display='block'>Push o otwartym przejeździe </Typography>
          <Field
            name="longRidePushEnabled"
            label="Włączony"
            type="checkbox"
            component={FormCheckBox}
            fullWidth
            variant="outlined"
          />
          <Field
            name="longRidePushIntervalInHours"
            type="number"
            component={FormTextField}
            parse={value => parseInt(value, 10)}
            format={value => value == null ? '' : value}
            endAdornmentText="godziny"
            fullWidth
            variant="outlined"
          />
        </Paper>
        <Paper style={{ padding: 16, marginBottom: 15 }}>
          <Typography variant='overline' display='block'>Automatyczne wyłączanie - liczba zgłoszeń</Typography>
          <Field
            name="automaticBikeRestrictRentalsByDefectQuantityEnabled"
            component={FormCheckBox}
            type="checkbox"
            label="Włączony"
          />
          <Field
            name="automaticBikeRestrictRentalsByDefectQuantity"
            type="number"
            component={FormTextField}
            parse={value => parseInt(value, 10)}
            format={value => value == null ? '' : value}
            fullWidth
            variant="outlined"
          />
        </Paper>
        <Paper style={{ padding: 16, marginBottom: 15 }}>
          <Typography variant='overline' display='block'>Przestań wyświetlać na mapie po utracie GPS</Typography>
          <Field
            name="minimumDayToHideBikeAfterLoseGPSEnabled"
            component={FormCheckBox}
            type="checkbox"
            label="Włączony"
          />
          <Field
            name="minimumDayToHideBikeAfterLoseGPS"
            type="number"
            component={FormTextField}
            parse={value => parseInt(value, 10)}
            endAdornmentText="dni"
            format={value => value == null ? '' : value}
            fullWidth
            variant="outlined"
          />
        </Paper>
        <MessageForUser />
        <FormActions pristine={pristine} invalid={invalid} submitting={submitting} cancelRoutePath={routePaths.service.map} />
      </form>
    )}
  />
)

ServiceSettingsForm.propTypes = {
  initialValues: object.isRequired,
  onSubmit: func.isRequired,
};

export default ServiceSettingsForm;
