import zoneTypesApi from '../api/zoneTypesApi';
import { actions as notificationActions } from './notificationActions';
import * as logger from '../utils/logger';

export const types = {
  DELETE_ZONETYPE_REQUEST: 'DELETE_ZONETYPE_REQUEST',
  DELETE_ZONETYPE_REQUEST_SUCCESS: 'DELETE_ZONETYPE_REQUEST_SUCCESS',
  DELETE_ZONETYPE_REQUEST_FAILURE: 'DELETE_ZONETYPE_REQUEST_FAILURE',
  CREATE_ZONETYPE_REQUEST: 'CREATE_ZONETYPE_REQUEST',
  CREATE_ZONETYPE_REQUEST_SUCCESS: 'CREATE_ZONETYPE_REQUEST_SUCCESS',
  CREATE_ZONETYPE_REQUEST_FAILURE: 'CREATE_ZONETYPE_REQUEST_FAILURE',
  UPDATE_ZONETYPE_REQUEST: 'UPDATE_ZONETYPE_REQUEST',
  UPDATE_ZONETYPE_REQUEST_SUCCESS: 'UPDATE_ZONETYPE_REQUEST_SUCCESS',
  UPDATE_ZONETYPE_REQUEST_FAILURE: 'UPDATE_ZONETYPE_REQUEST_FAILURE',
  GET_ALL_ZONETYPES_REQUEST: 'GET_ALL_ZONETYPES_REQUEST',
  GET_ALL_ZONETYPES_REQUEST_SUCCESS: 'GET_ALL_ZONETYPES_REQUEST_SUCCESS',
  GET_ALL_ZONETYPES_REQUEST_FAILURE: 'GET_ALL_ZONETYPES_REQUEST_FAILURE',
  GET_ZONETYPE_REQUEST: 'GET_ZONETYPE_REQUEST',
  GET_ZONETYPE_REQUEST_SUCCESS: 'GET_ZONETYPE_REQUEST_SUCCESS',
  GET_ZONETYPE_REQUEST_FAILURE: 'GET_ZONETYPE_REQUEST_FAILURE',
  START_ZONETYPE_CREATION: 'START_ZONETYPE_CREATION',
};

export const actions = {
  loadZoneTypes: () => dispatch => {
    dispatch({ type: types.GET_ALL_ZONETYPES_REQUEST });
    return zoneTypesApi.getAllZoneTypes().then(res => {
      if (res.ok) {
        dispatch({ type: types.GET_ALL_ZONETYPES_REQUEST_SUCCESS, zoneTypes: res.data });
      } else {
        dispatch({ type: types.GET_ALL_ZONETYPES_REQUEST_FAILURE });
        dispatch(notificationActions.showError('Nie udało się pobrać typów stref'));
        return logger.error(res.data);
      }
    });
  },

  loadZoneType: (zoneTypeId) => dispatch => {
    dispatch({ type: types.GET_ZONETYPE_REQUEST });
    return zoneTypesApi.getZoneType(zoneTypeId).then(res => {
      if (res.ok) {
        dispatch({ type: types.GET_ZONETYPE_REQUEST_SUCCESS, zoneType: res.data });
      } else {
        dispatch({ type: types.GET_ZONETYPE_REQUEST_FAILURE });
        dispatch(notificationActions.showError('Nie udało się pobrać typu strefy'));
        return logger.error(res.data);
      }
    });
  },

  startZoneTypeCreation: () => dispatch => dispatch({ type: types.START_ZONETYPE_CREATION }),

  createZoneType: (zoneType) => (dispatch) => {
    dispatch({ type: types.CREATE_ZONETYPE_REQUEST });
    return zoneTypesApi.createZoneType(zoneType).then(res => {
      if (res.ok) {
        dispatch({ type: types.CREATE_ZONETYPE_REQUEST_SUCCESS });
        return true;
      } else {
        dispatch({ type: types.CREATE_ZONETYPE_REQUEST_FAILURE });
        dispatch(notificationActions.showError('Nie udało się zapisać typu strefy'));
        logger.error(res.data);
        return false;
      }
    });
  },

  updateZoneType: (zoneType) => (dispatch) => {
    dispatch({ type: types.UPDATE_ZONETYPE_REQUEST });
    return zoneTypesApi.updateZoneType(zoneType).then(res => {
      if (res.ok) {
        dispatch({ type: types.UPDATE_ZONETYPE_REQUEST_SUCCESS });
        return true;
      } else {
        dispatch({ type: types.UPDATE_ZONETYPE_REQUEST_FAILURE });
        dispatch(notificationActions.showError('Nie udało się zapisać typu strefy'));
        logger.error(res.data);
        return false;
      }
    });
  },

  deleteZoneType: (zoneTypeId) => dispatch => {
    dispatch({ type: types.DELETE_ZONETYPE_REQUEST });
    return zoneTypesApi.deleteZoneType(zoneTypeId).then(res => {
      if (res.ok && res.data.success !== false) {
        dispatch({ type: types.DELETE_ZONETYPE_REQUEST_SUCCESS, zoneTypeId });
        return true;
      } else {
        dispatch({ type: types.DELETE_ZONETYPE_REQUEST_FAILURE });
        dispatch(notificationActions.showError(`Nie udało się usunąć typu strefy. ${res.data.failure ? res.data.failure.message : ''}`));
        logger.error(res.data);
        return false;
      }
    });
  },
};
