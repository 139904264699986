import React from "react";
import { useDispatch, useSelector } from "react-redux";
import CreateCustomReportForm from "./CreateCustomReportForm";
import { actions } from "../../../actions/reportsActions";
import { useHistory } from "react-router-dom";
import routePaths from "../../../routePaths";
import { UserRoles } from "../../../models/auth/AuthModel";

export const sectionsOfReport = [
  { name: "Raport wypożyczeń", id: "rentalsDistribution" },
  {
    name: "Rozkład czasu wypożyczeń dla systemu",
    id: "rentalsDistributionSystem",
  },
  {
    name: "Rozkład dobowy wypożyczeń/zwrotów rowerów",
    id: "rentalsAndReturnsByDay",
  },
  {
    name: "Liczba wypożyczeń/zwrotów rowerów w poszczególnych stacjach z podziałem na miesiące",
    id: "rentalsAndReturnsByZone",
  },
  {
    name: "Liczba wypożyczeń/zwrotów rowerów w poszczególnych stacjach z podziałem na doby",
    id: "rentalsAndReturnsByDayAndZone",
  },
  { name: "Wypożyczenia - dane dobowe", id: "rentalsByDay" },
  { name: "Dane szczegółowe", id: "detailData" },
  {
    name: "Dostępność strony internetowej i aplikacji",
    id: "availableAppByDay",
  },
  { name: "Liczba Użytkowników", id: "clientsCount" },
  { name: "Abonamenty", id: "subscriptionsByType" },
  { name: "Użytkownicy - dane dobowe", id: "newAndRemovedUsersByDay" },
  { name: "Depozyty - podsumowanie", id: "accountDepositByDay" },
  {
    name: "Depozyty - lista użytkowników zarejestrowanych tylko w jednym tenancie",
    id: "accountDepositByUserOnlyInOneTenant",
  },
  { name: "Środki deponowane w ramach depozytów", id: "accountIncomeByDay" },
  { name: "Rekordy", id: "userRecord" },
  { name: "Liczba skarg", id: "reportedFareIssuesCount" },
  { name: "Raport usterek ogólny", id: "overallIssues" },
  { name: "Raport usterek per strefa", id: "brokenPartsByZone" },
  { name: "Raport serwisowy", id: "issuesService" },
  {
    name: "Średni czas reakcji na zgłoszenie usterki/awarii",
    id: "issuesSummary",
  },
  { name: "Dochód generowany z wypożyczenia rowerów", id: "income" },
  { name: "Rowery liczbowo", id: "bikesAvailableByDay" },
  { name: "Statystyki typów rowerów", id: "statisticByTypeOfBike" },
  { name: "Pełen raport usterek", id: "defectBikeReport" },
  { name: "Reklamacje", id: "fareIssue" },
  {
    name: "Top 10",
    id: "top10Statistic",
  },
  {
    name: "Liczba nowych użytkowników w tenancie",
    id: "newUserInTenant",
  },
  {
    name: "Oceny przejazdów",
    id: "rideRating",
  },
];

export const sectionsOfReportForClientUserRole = [
  { name: "Raport wypożyczeń", id: "rentalsDistribution" },
  {
    name: "Rozkład czasu wypożyczeń dla systemu",
    id: "rentalsDistributionSystem",
  },
  {
    name: "Rozkład dobowy wypożyczeń/zwrotów rowerów",
    id: "rentalsAndReturnsByDay",
  },
  {
    name: "Liczba wypożyczeń/zwrotów rowerów w poszczególnych stacjach z podziałem na miesiące",
    id: "rentalsAndReturnsByZone",
  },
  {
    name: "Liczba wypożyczeń/zwrotów rowerów w poszczególnych stacjach z podziałem na doby",
    id: "rentalsAndReturnsByDayAndZone",
  },
  { name: "Wypożyczenia - dane dobowe", id: "rentalsByDay" },
  { name: "Dane szczegółowe", id: "detailData" },
  {
    name: "Dostępność strony internetowej i aplikacji",
    id: "availableAppByDay",
  },
  { name: "Liczba Użytkowników", id: "clientsCount" },
  { name: "Abonamenty", id: "subscriptionsByType" },
  { name: "Użytkownicy - dane dobowe", id: "newAndRemovedUsersByDay" },
  { name: "Depozyty - podsumowanie", id: "accountDepositByDay" },
  { name: "Środki deponowane w ramach depozytów", id: "accountIncomeByDay" },
  { name: "Rekordy", id: "userRecord" },
  { name: "Liczba skarg", id: "reportedFareIssuesCount" },
  { name: "Raport usterek ogólny", id: "overallIssues" },
  { name: "Raport usterek per strefa", id: "brokenPartsByZone" },
  { name: "Raport serwisowy", id: "issuesService" },
  {
    name: "Średni czas reakcji na zgłoszenie usterki/awarii",
    id: "issuesSummary",
  },
  { name: "Dochód generowany z wypożyczenia rowerów", id: "income" },
  { name: "Rowery liczbowo", id: "bikesAvailableByDay" },
  { name: "Statystyki typów rowerów", id: "statisticByTypeOfBike" },
  { name: "Pełen raport usterek", id: "defectBikeReport" },
  { name: "Reklamacje", id: "fareIssue" },
];

const CreateCustomReportPage = () => {
  const dispatch = useDispatch();
  const submitting = useSelector(
    (s) => s.reports.list.savingTemplateCustomReport,
    null,
  );
  const userRole = useSelector((x) => x.auth.currentUserData.userRole, null);
  const history = useHistory();
  const handleSubmit = async (e) => {
    const result = await dispatch(actions.createCustomReport(e));
    if (result) {
      history.push(routePaths.reportsCustom);
    }
  };

  return (
    <div className="page">
      <div style={{ margin: 40 }}>
        <CreateCustomReportForm
          onSubmit={handleSubmit}
          isPreview={false}
          sectionsOfReport={
            userRole === UserRoles.client.value
              ? sectionsOfReportForClientUserRole
              : sectionsOfReport
          }
          submitting={submitting}
        />
      </div>
    </div>
  );
};

export default CreateCustomReportPage;
