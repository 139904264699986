import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

class ConfirmationButton extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isOpen: false
    };
    this.handleAction = this.handleAction.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  handleAction() {
    this.setState({ isOpen: true });
  }

  handleConfirm() {
    this.setState({ isOpen: false });
    this.props.onConfirm();
  }

  handleCancel() {
    this.setState({ isOpen: false });
    this.props.onCancel && this.props.onCancel();
  }

  render() {
    const {
      className,
      confirmationTitle,
      confirmationText,
      cancelLabel,
      confirmLabel,
      children,
      onConfirm, // eslint-disable-line no-unused-vars
      onCancel, // eslint-disable-line no-unused-vars
      ...other
    } = this.props;

    return (
      <div style={{ display: 'inline' }} className={className}>
        <Button
          onClick={this.handleAction}
          {...other}
        >
          {children}
        </Button>

        <Dialog open={this.state.isOpen} onClose={this.handleCancel}>
          <DialogTitle>{confirmationTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {confirmationText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCancel}>
              {cancelLabel}
            </Button>
            <Button variant="contained" onClick={this.handleConfirm} color="primary">
              {confirmLabel}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

ConfirmationButton.propTypes = {
  children: PropTypes.node.isRequired,
  confirmLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  confirmationText: PropTypes.string.isRequired,
  confirmationTitle: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

ConfirmationButton.defaultProps = {
  confirmLabel: 'Ok',
  cancelLabel: 'Anuluj',
};

export default ConfirmationButton;
