import React from 'react';
import PropTypes from 'prop-types';
import BikeTypeEditForm from './BikeTypeEditForm';
import routePaths from '../../routePaths';
import { bindActionCreators } from 'redux';
import { actions } from '../../actions/bikeTypesActions';
import { connect } from 'react-redux';

class BikeTypeEditPage extends React.Component {

  constructor(props) {
    super(props);
    this.handleSubmitForm = this.handleSubmitForm.bind(this);
  }

  componentDidMount() {
    this.props.actions.loadGlobalBikeTypes();
    this.props.actions.startBikeTypeCreation();
    if (this.props.match.params.bikeTypeId) {
      this.props.actions.loadBikeType(this.props.match.params.bikeTypeId);
    }
  }

  handleSubmitForm(data) {
    const submitAction = data.id
      ? this.props.actions.updateBikeType
      : this.props.actions.createBikeType;
    return submitAction(data)
      .then(success => {
        if (success) this.props.history.push(routePaths.bikeTypes);
      });
  }

  render() {
    return (
      <div className="page" >
        <div style={{ margin: 40 }}>
          <BikeTypeEditForm
            onSubmit={this.handleSubmitForm}
            isEdit={this.props.match.params.bikeTypeId != null}
            globalBikeTypes={this.props.globalBikeTypes}
          />
        </div>
      </div>
    );
  }
}

BikeTypeEditPage.propTypes = {
  actions: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  bikeType: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  globalBikeTypes: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  return {
    bikeType: state.bikeTypes.current,
    globalBikeTypes: state.globalBikeTypes.data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BikeTypeEditPage);
