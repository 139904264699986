import reportsApi from '../../../api/reportsApi';
import { actions as notificationActions } from '../../../actions/notificationActions';
import moment from 'moment';

export const types = {
  LOAD_RIDES_REPORT: 'LOAD_RIDES_REPORT',
  LOAD_FRESH_RIDES_REPORT_SUCCESS: 'LOAD_FRESH_RIDES_REPORT_SUCCESS',
  LOAD_RIDES_REPORT_SUMMARY: 'LOAD_RIDES_REPORT_SUMMARY',
  LOAD_FRESH_RIDES_REPORT_SUMMARY_SUCCESS: 'LOAD_FRESH_RIDES_REPORT_SUMMARY_SUCCESS',
  LOAD_FRESH_RIDES_REPORT_SUMMARY_ERROR: 'LOAD_FRESH_RIDES_REPORT_SUMMARY_ERROR',
  EXPORT_RIDES_REPORT: 'EXPORT_RIDES',
  EXPORT_RIDES_SUCCESS: 'EXPORT_RIDES_SUCCESS',
  EXPORT_RIDES_FAILURE: 'EXPORT_RIDES_FAILURE',
  LOAD_NEXT_RIDES_REPORT: 'LOAD_NEXT_RIDES_REPORT',
  LOAD_NEXT_RIDES_REPORT_SUCCESS: 'LOAD_NEXT_RIDES_REPORT_SUCCESS',
  LOAD_NOT_START_RIDES_REPORT: 'LOAD_NOT_START_RIDES_REPORT',
  LOAD_NOT_START_FRESH_RIDES_REPORT_SUCCESS: 'LOAD_NOT_START_FRESH_RIDES_REPORT_SUCCESS',
  LOAD_NEXT_NOT_START_RIDES_REPORT: 'LOAD_NEXT_NOT_START_RIDES_REPORT',
  LOAD_NEXT_NOT_START_RIDES_REPORT_SUCCESS: 'LOAD_NEXT_NOT_START_RIDES_REPORT_SUCCESS',
};

const ExcludeFiltersForRidesArray = ['days', 'timeRangeStart', 'timeRangeEnd'];

export const excludeFiltersForRides = (filters) => {
  return filters.filter(x => !ExcludeFiltersForRidesArray.includes(x.id))
}

function storeBlobAs(blob, filename) {
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  setTimeout(() => URL.revokeObjectURL(url), 60000);
  if (typeof link.download === 'string') {
    document.body.appendChild(link);
    link.download = filename;
    link.href = url;
    link.click();
    document.body.removeChild(link);
  } else {
    location.replace(url);
  }
}

export const actions = {
  loadFreshRideReports: (pageNumber, pageSize, sortBy, filterBy) => (dispatch) => {
    dispatch({ type: types.LOAD_RIDES_REPORT, pageNumber, pageSize, sortBy, filterBy });
    return reportsApi.getRides(pageNumber, pageSize, sortBy, filterBy).then(res => {
      if (res.ok) {
        dispatch({ type: types.LOAD_FRESH_RIDES_REPORT_SUCCESS, result: res.data || {} });
      }
    });
  },

  loadFreshRideSummaryReports: (filterBy) => (dispatch) => {
    dispatch({ type: types.LOAD_RIDES_REPORT_SUMMARY, filterBy });
    return reportsApi.getRidesSummary(filterBy).then(res => {
      if (res.ok) {
        dispatch({ type: types.LOAD_FRESH_RIDES_REPORT_SUMMARY_SUCCESS, result: res.data || {} });
      }
      dispatch({ type: types.LOAD_FRESH_RIDES_REPORT_SUMMARY_ERROR});
    });
  },

  requestExcelExport: (sortBy, filterBy) => (dispatch) => {
    dispatch({ type: types.EXPORT_RIDES_REPORT, sortBy, filterBy });
    return reportsApi.exportRides(sortBy, filterBy).then(res => {
      if (res.ok && res.data.success !== false) {
        dispatch({ type: types.EXPORT_RIDES_SUCCESS });
        storeBlobAs(res.data, `Przejazdy_${moment().toISOString()}.xlsx`);
      } else {
        dispatch({ type: types.EXPORT_RIDES_FAILURE });
        dispatch(notificationActions.showError('Eksport przejazdów nie powiódł się'));
      }
    });
  },

  loadNextRideReports: (pageNumber, filterBy) => (dispatch, getState) => {
    dispatch({ type: types.LOAD_NEXT_RIDES_REPORT, pageNumber });
    const sortBy = getState().reports.rides.pagedData.sortedBy;
    const pageSize = getState().reports.rides.pagedData.paging.pageSize;
    return reportsApi.getRides(pageNumber, pageSize, sortBy, filterBy).then(res => {
      if (res.ok) {
        dispatch({ type: types.LOAD_NEXT_RIDES_REPORT_SUCCESS, result: res.data || {} });
      }
    });
  },

  loadFreshNotStartedRideReports: (pageNumber, pageSize, sortBy, filterBy) => (dispatch) => {
    dispatch({ type: types.LOAD_NOT_START_RIDES_REPORT, pageNumber, pageSize, sortBy, filterBy });
    return reportsApi.getNotStartedRides(pageNumber, pageSize, sortBy, filterBy).then(res => {
      if (res.ok) {
        dispatch({ type: types.LOAD_NOT_START_FRESH_RIDES_REPORT_SUCCESS, result: res.data || {} });
      }
    });
  },

  loadNextNotStartedRideReports: (pageNumber, filterBy) => (dispatch, getState) => {
    dispatch({ type: types.LOAD_NEXT_NOT_START_RIDES_REPORT, pageNumber });
    const sortBy = getState().reports.rides.pagedData.sortedBy;
    const pageSize = getState().reports.rides.pagedData.paging.pageSize;
    return reportsApi.getNotStartedRides(pageNumber, pageSize, sortBy, filterBy).then(res => {
      if (res.ok) {
        dispatch({ type: types.LOAD_NEXT_NOT_START_RIDES_REPORT_SUCCESS, result: res.data || {} });
      }
    });
  },
};
