import initialState from './initialState';
import Immutable from 'seamless-immutable';
import { types } from '../actions/cardsActions';
import { ReportTableModel } from '../models/ReportTableModel';

export default function clientCardsReducer(state = Immutable(initialState.reports.clientCards), action) {
  switch (action.type) {
    case types.LOAD_CLIENT_CARDS: {
      return Immutable.merge(state, {
        loading: true,
        pagedData: ReportTableModel.pageRequested(state.pagedData, action.pageNumber, action.pageSize, action.filterBy, action.sortBy),
      });
    }
    case types.LOAD_CLIENT_CARDS_SUCCESS: {
      const result = action.result;
      return Immutable.merge(state, {
        loading: false,
        pagedData: ReportTableModel.pageReady(state.pagedData, result.totalDataCount, result.data),
      });
    }
    default:
      return state;
  }
}
