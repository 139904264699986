import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CircularProgress, Typography, Paper } from '@material-ui/core';
import { actions } from '../../../actions/bikeDefectsActions';
import BikeDefectsConfigurationForm from './BikeDefectsConfigurationForm';
import { hasPermission, permissions } from '../../../utils/authorization';

class BikeDefectsConfigurationPage extends Component {
  componentDidMount() {
    this.props.actions.getDefectsConfiguration();
  }

  render() {
    const { actions, fetching, data } = this.props;
    const hasConfigurationModifyPermission = hasPermission(this.props.userPermissions, permissions.bikeDefectsConfiguration.access);
    return (
      <div className="page">
        <div style={{ margin: 40 }}>
          {fetching ? <div style={{ width: '100%', textAlign: 'center' }}><CircularProgress size={50} /></div> :
            <React.Fragment>
              <Typography variant="h5" gutterBottom>Konfiguracja</Typography>
              <Paper style={{ padding: 16 }}>
                <BikeDefectsConfigurationForm initialValues={data} onSubmit={data => actions.updateDefectsConfiguration(data)} disabled={!hasConfigurationModifyPermission} />
              </Paper>
            </React.Fragment>}
        </div>
      </div>
    );
  }
}

BikeDefectsConfigurationPage.propTypes = {
  actions: PropTypes.object.isRequired,
  fetching: PropTypes.bool,
  data: PropTypes.object,
  params: PropTypes.object,
  userPermissions: PropTypes.array.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

function mapStateToProps(state) {
  return {
    fetching: state.bikeDefectsConfiguration.fetching,
    data: state.bikeDefectsConfiguration.data,
    userPermissions: state.auth.permissions,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BikeDefectsConfigurationPage);
