import React from 'react';
import PropTypes from 'prop-types';
import { DateTimePicker } from '@material-ui/pickers';
import Chip from '@material-ui/core/Chip';
import moment from 'moment';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import DateRangeIcon from '@material-ui/icons/DateRange';
import InputAdornment from '@material-ui/core/InputAdornment';
import './styles.scss';

/* eslint react/no-multi-comp: 0 */

const createDeleteFunction = (id) => (filters) => filters.filter(f => f.id !== id);

const activeMode = (onChange, label, filter) => (
  <DateTimePicker
    placeholder={label}
    ampm={false}
    InputProps={{
      disableUnderline: true,
      startAdornment: <InputAdornment position="start"><DateRangeIcon style={{ width: 16, opacity: 0.2 }} /></InputAdornment>,
    }}
    className="date-time-filter"
    value={(filter && filter.value) || null}
    margin="none"
    cancelLabel="Anuluj"
    fullWidth
    onChange={(v) => onChange(v.format())}
  />
);

const readonlyMode = (onDelete, label, filter) => (
  <div className="filter">
    <span className="label">{label}</span>
    <Chip className="chip" onDelete={() => onDelete(createDeleteFunction(filter.id))} label={
      <span className="flex-row align-items-center">
        {moment(filter.value).format('DD.MM.YYYY')}
        <AccessTimeIcon color="disabled" style={{ width: 20, height: 16 }} />
        {moment(filter.value).format('HH:mm')}
      </span>}
    />
  </div>
);

const DateTimeFilter = ({ onChange, onDelete, readonly, label, filter }) => {
  return (
    readonly
      ? (filter ? readonlyMode(onDelete, label, filter) : null)
      : activeMode(onChange, label, filter)
  );
};

DateTimeFilter.propTypes = {
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  readonly: PropTypes.bool,
  label: PropTypes.string,
  filter: PropTypes.object,
};

export default DateTimeFilter;
