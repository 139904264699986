import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import './styles.scss';

const ClientActivationStatus = ({ inactiveReason }) =>
  <Chip
    className={["client-activation-status", "inactive"].join(' ')}
    label={`Konto nieaktywne (${inactiveReason}`}
  />;

ClientActivationStatus.propTypes = {
  inactiveReason: PropTypes.string
};

export default ClientActivationStatus;
