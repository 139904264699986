import initialState from './initialState';
import Immutable from 'seamless-immutable';
import { types } from '../actions/plansActions';
import { PlanModel } from '../models/plans/PlanModel';

export default function plansReducer(state = Immutable(initialState.plans), action) {
  switch (action.type) {
    case types.GET_PLANS_SUCCESS:
    case types.GET_DEDICATED_PLANS_SUCCESS: {
      const { plans } = action;
      return [...plans.map(b => new PlanModel(b))];
    }
    case types.DISABLE_PLAN_SUCCESS: {
      const { id } = action;
      return Immutable.merge(state.filter(o => o.id !== id));
    }
    case types.GET_USER_DEDICATED_PLANS_SUCCESS: {
      const { plans } = action;
      return [...plans];
    }
    default:
      return state;
  }
}
