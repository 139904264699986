import initialState from './initialState';
import Immutable from 'seamless-immutable';
import { types } from '../actions/bikeDefectsActions';

export default function bikeDefectsConfigurationReducer(state = Immutable(initialState.bikeDefectsConfiguration), action) {
  switch (action.type) {
    case types.GET_DEFECTS_CONFIGURATION: {
      return Immutable.merge(state, { fetching: true });
    }
    case types.GET_DEFECTS_CONFIGURATION_SUCCESS: {
      return Immutable.merge(state, {
        fetching: false,
        data: action.result,
      });
    }
    default:
      return state;
  }
}
