import {makeStyles} from "@material-ui/core";
import theme from "../../../styles/rooveeTheme";

const useStyles = makeStyles({
  text: {
    fontSize: 16,
    textAlign: 'justify'
  },
  qrCode: {
    height: "auto",
    maxWidth: "100%",

  },
  codeContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    columnGap: 30,
    rowGap: 10,
    marginTop: 10,
    marginBottom: 10,
    [theme.breakpoints.down('md')]: {
      justifyContent: "center"
    },
  }
});

export default useStyles;
