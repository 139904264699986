import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import DefectLocationMap from './DefectLocationMap';

class LocationPanel extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { userLocation, bikeLocation, operatorLocation, bikeLocationAtResolution } = this.props;
    return (
      <div>
        <Typography variant="subtitle1" gutterBottom>
          Lokalizacja roweru i użytkownika
        </Typography>
        <div>
          <DefectLocationMap
            loadingElement={<div style={{ height: '100%', width: 500, }} />}
            containerElement={<div style={{ width: '100%', height: 400 }} />}
            mapElement={<div style={{ width: '100%', height: 400 }} />}
            onMapLoad={ref => this.mapRef = ref}
            bikeLocation={bikeLocation}
            userLocation={userLocation}
            operatorLocation={operatorLocation}
            bikeLocationAtResolution={bikeLocationAtResolution}
          />
        </div>
      </div>
    );
  }
}

LocationPanel.propTypes = {
  userLocation: PropTypes.object,
  bikeLocation: PropTypes.object,
  operatorLocation: PropTypes.object,
  bikeLocationAtResolution: PropTypes.object
};

export default LocationPanel;
