import React, {useState} from 'react'
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import ResendVerificationEmailDialog from "./ResendVerificationEmailDialog";
import NoteAddIcon from "@material-ui/icons/NoteAdd";

const ResendVerificationEmailButton = ({status, email, onResendVerificationEmail}) => {

  const [showDialog, setShowDialog] = useState(false)
  const [resultData, setResultData] = useState({success: false, activationLink: null})
  const onHandleSave = async () => {
    const res = await onResendVerificationEmail();
    if (res.success) {
      setResultData(res);
      return;
    }
    setResultData({success: false, activationLink: null});
  }

  const onHandleClose = async () => {
    setResultData({success: false, activationLink: null});
    setShowDialog(false);
  }

  return (
    <>
      <Button
        onClick={() => {
          setShowDialog(true)
        }}
        variant="outlined"
        size="small"
      >
        <span>{status}</span>
        <span style={{marginLeft: 5}}><NoteAddIcon/></span>
      </Button>
      {showDialog && <ResendVerificationEmailDialog
        open={showDialog}
        email={email}
        onSave={onHandleSave}
        onCancel={onHandleClose}
        result={resultData}
      />}
    </>
  );
}

ResendVerificationEmailButton.propTypes = {
  status: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  onResendVerificationEmail: PropTypes.func.isRequired,
}

export default ResendVerificationEmailButton;
