import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Field, Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import FormTextField from '../../form/final-form/FormTextField';
import FormCheckBox from '../../form/final-form/FormCheckBox';
import { FormHelperText } from '@material-ui/core';

const validation = values => {
  const errors = { parts: {} };
  if (!values.resolutionComments) errors.resolutionComments = 'Wprowadzenie notatki jest wymagane';
  if (!values.parts || Object.values(values.parts).filter(p => p.selected).length === 0) {
    errors.parts = { _error: 'Wybranie co najmniej jednej części jest wymagane' };
  }
  if (!values.parts) {
    errors.parts._error = 'Wybranie co najmniej jednej części jest wymagane';
  } else {
    const zeroCount = Object.values(values.parts).filter(p => p.selected && p.count === 0);
    zeroCount.forEach(p => errors.parts[p.id] = { count: 'Liczba musi być większa od zera' });
  }

  return errors;
}

const ResolveDefectWithPartsDialog = ({ open, onSave, onCancel, parts, submitting }) => {
  const selectableParts = parts
    .reduce((acc, part) => {
      acc[part.id] = { id: part.id, name: part.name, selected: false, count: 1 };
      return acc;
    }, {});
  return (
    <Dialog open={open}>
      <DialogTitle>Zamykanie zgłoszenia z wymianą części</DialogTitle>
      <DialogContent>
        <Form
          onSubmit={onSave}
          mutators={{ ...arrayMutators }}
          validate={validation}
          initialValues={{ resolutionComments: '', parts: selectableParts }}
          render={({ handleSubmit, values, errors, touched }) => (
            <form onSubmit={handleSubmit} id="resolveDefectWithPartsForm">
              {
                Object.keys(values.parts).map(partId =>
                  <div key={partId}>
                    <Field
                      name={`parts.${partId}.selected`}
                      component={FormCheckBox}
                      type="checkbox"
                      label={values.parts[partId].name}
                      fullWidth
                    />
                    {
                      values.parts[partId].selected &&
                      <Field
                        name={`parts.${partId}.count`}
                        component={FormTextField}
                        type="number"
                        InputProps={{ inputProps: { min: 1 } }}
                        label={"Liczba"}
                        fullWidth
                      />
                    }
                  </div>
                )
              }
              {errors && errors.parts && errors.parts._error && touched
                && <FormHelperText error>{errors.parts._error}</FormHelperText>}
              <div>
                <Field
                  name="resolutionComments"
                  component={FormTextField}
                  label="Notatka"
                  fullWidth
                />
              </div>
            </form>
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} disabled={submitting} >
          Anuluj
        </Button>
        <Button type="submit" form="resolveDefectWithPartsForm" disabled={submitting} variant='contained' color="primary">
          Zamknij zgłoszenie
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ResolveDefectWithPartsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  dispatch: PropTypes.func,
  parts: PropTypes.array.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default ResolveDefectWithPartsDialog;
