import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import routePaths from '../../routePaths';
import NavigationIcon from '@material-ui/icons/NearMe';
import Typography from '@material-ui/core/Typography';
import { BikeModel } from '../../models/BikeModel';
import moment from 'moment';
import './BikeDetailsCardStyles.scss';

class BikeDetails extends Component {
  constructor(props) {
    super(props);
    this.setDirectionsHref = this.setDirectionsHref.bind(this);
    this.state = {
      href: null
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.bikeDetails.id && this.props.bikeDetails.id !== nextProps.bikeDetails.id) {
      this.setDirectionsHref(nextProps.bikeDetails.location);
    }
  }

  setDirectionsHref(destination) {
    const destinationString = `${destination.latitude},${destination.longitude}`;
    this.setState({ href: `https://www.google.com/maps/dir/?api=1&destination=${destinationString}&travelmode=driving` });
  }

  getBikeStatusNode(bikeDetails) {
    const statusText = BikeModel.getBikeStatusDescription(bikeDetails);
    const statusNode = <b>{statusText}</b>;
    return (bikeDetails.isRented && bikeDetails.lastRideId)
      ? <Link to={`${routePaths.ridesReports}/${bikeDetails.lastRideId}`}>{statusNode}</Link>
      : statusNode;
  }

  render() {
    const { bikeDetails, displayDefectsInfo } = this.props;
    if (!bikeDetails.id) return null;
    return (
      <div className="bike-details-card">
        <Card className="card">
          <CardContent>
            <div className="flex-row align-items-center justify-space-between">
              <Typography variant="h5" component="h2">
                <Link to={`${routePaths.bikesFiltered}${bikeDetails.id}`}>{bikeDetails.bikeNumber}</Link>
              </Typography>
              <Button size="medium" color="secondary" disabled={!this.state.href} href={this.state.href} target="_blank">
                Trasa
                <NavigationIcon style={{ marginLeft: 2, width: 16 }} />
              </Button>
            </div>
            <Typography component="p">
              Status: {this.getBikeStatusNode(bikeDetails)}<br />
              {bikeDetails.ongoingPause && <Fragment>Wstrzymano: <b>{moment(bikeDetails.ongoingPauseDate).format('DD.MM.YYYY HH:mm')}</b><br /></Fragment>}
              {bikeDetails.rentalRestrictionReason ? <span>Powód wycofania: <b>{bikeDetails.rentalRestrictionReason}</b><br /></span> : null}
              Bateria: <b>{bikeDetails.lockBatteryLevel} %</b><br />
              Pozycja: <b>{bikeDetails.isOrientedUpright ? 'pionowa' : 'przewrócony'}</b><br />
              Aktualizacja: <b>{moment(bikeDetails.lockTimestamp).format('DD.MM.YYYY HH:mm')}</b><br />
              Czas GPS: <b>{bikeDetails.gpsTimestamp ? moment(bikeDetails.gpsTimestamp).format('DD.MM.YYYY HH:mm') : '-'}</b><br />
              {displayDefectsInfo && bikeDetails.openDefectsCount > 0
                ? <React.Fragment>Usterki: <b>{bikeDetails.openDefectsCount}</b><Link to={`${routePaths.bikeDefectsFiltered}${bikeDetails.bikeNumber}`}> (przejdź)</Link></React.Fragment>
                : null}
            </Typography>
          </CardContent>
        </Card>
      </div>
    );
  }
}
BikeDetails.propTypes = {
  bikeDetails: PropTypes.object.isRequired,
  displayDefectsInfo: PropTypes.bool,
};

export default BikeDetails;
