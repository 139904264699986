import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import RideStarterDialog from './RideStarterDialog';

class RideStarterButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = { dialogVisible: false };
    this.handleOpenDialogClick = this.handleOpenDialogClick.bind(this);
    this.handleDialogCancel = this.handleDialogCancel.bind(this);
  }

  handleDialogCancel() {
    this.setState({ dialogVisible: false });
  }

  handleOpenDialogClick() {
    this.setState({ dialogVisible: true });
  }

  renderOpenDialogButton() {
    return (
      <Button disabled={this.props.disabled}
        onClick={this.handleOpenDialogClick}
        color="secondary"
        variant="outlined"
        size="small">
        Rozpocznij przejazd
      </Button>
    );
  }

  render() {
    return (
      <div>
        {this.renderOpenDialogButton()}
        <RideStarterDialog
          disabled={this.props.disabled}
          userId={this.props.userId}
          isOpened={this.state.dialogVisible}
          onCancel={this.handleDialogCancel}
        />
      </div>
    );
  }
}

RideStarterButton.propTypes = {
  userId: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default RideStarterButton;
