import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions  from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {connect, useSelector} from 'react-redux';
import { submit } from 'redux-form';
import GenerateReportForm from './GenerateReportForm';

const GenerateReportDialog = ({ open, onSave, onCancel, dispatch, submitting }) => {

  const customReportList = useSelector(s => s.reports.list.customReportList, null);

  const customReportListMap = useMemo(() => {
    if(!customReportList) return [];
    return customReportList.map(x => {
      return {
        name: `${x.name} ${x.createdBy !== null ? `(${x.createdBy})` : ''}`,
        value: x.id,
      }
    })
  }, [customReportList])

  return (
    <Dialog open={open} fullWidth maxWidth="xs">
      <DialogTitle>Generowanie raportu</DialogTitle>
      <DialogContent>
        <GenerateReportForm
          onSubmit={onSave}
          customReportList={customReportListMap}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} disabled={submitting}>
          Anuluj
        </Button>
        <Button variant="contained" onClick={() => dispatch(submit('generateReport'))} disabled={submitting}
                color="primary">
          Wygeneruj
        </Button>
      </DialogActions>
    </Dialog>
  );
}

GenerateReportDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  dispatch: PropTypes.func,
  submitting: PropTypes.bool.isRequired,
};

export default connect()(GenerateReportDialog);
