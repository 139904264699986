import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import EditIcon from "@material-ui/icons/Edit";
import ChangeUserPhoneNumberDialog from "./ChangeUserPhoneNumberDialog";

const ChangeUserPhoneNumberButton = ({ changePhoneNumber }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [url, setUrl] = useState(null);
  const onHandleSave = async (values) => {
    let phoneNumber = values.phoneNumber.replaceAll(" ", "");
    if (phoneNumber[0] !== "+") {
      phoneNumber = `+${phoneNumber}`;
    }
    const res = await changePhoneNumber(phoneNumber);
    if (res.success) {
      setUrl(res.url);
    }
  };

  return (
    <span style={{ marginLeft: 5 }}>
      <Button
        onClick={() => {
          setShowDialog(true);
        }}
        variant="outlined"
        style={{ padding: "0 0" }}
      >
        <span>
          <EditIcon />
        </span>
      </Button>
      {showDialog && (
        <ChangeUserPhoneNumberDialog
          open={showDialog}
          onSave={onHandleSave}
          onCancel={() => setShowDialog(false)}
          url={url}
        />
      )}
    </span>
  );
};

ChangeUserPhoneNumberButton.propTypes = {
  changePhoneNumber: PropTypes.func.isRequired,
};

export default ChangeUserPhoneNumberButton;
