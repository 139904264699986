export class OperatorListItemModel {
  constructor(id, firstName, lastName, email, accountActivated, accountActivationDate, role, is2FAEnable) {
    this.id = id || null;
    this.firstName = firstName || '';
    this.lastName = lastName || '';
    this.email = email || '';
    this.accountActivated = accountActivated || false;
    this.accountActivationDate = accountActivationDate || null;
    this.role = role || null;
    this.is2FAEnable = is2FAEnable || false;
  }
}
