import initialState from './initialState';
import Immutable from 'seamless-immutable';
import { types } from '../actions/campaignsActions';
import { ReportTableModel } from '../models/ReportTableModel';
import { CampaignDetailsModel, CampaignListModel } from '../models/campaigns/CampaignsModel';
import moment from 'moment';

export default function campaignsReducer(state = Immutable(initialState.campaigns), action) {
  switch (action.type) {
    case types.LOAD_CAMPAIGNS: {
      return Immutable.merge(state, {
        list: {
          loading: true,
          pagedData: ReportTableModel.pageRequested(state.list.pagedData, action.pageNumber, action.pageSize, action.filterBy, action.sortBy),
        }
      });
    }
    case types.LOAD_CAMPAIGNS_SUCCESS: {
      const result = action.result;
      return Immutable.merge(state, {
        list: {
          loading: false,
          pagedData: ReportTableModel.pageReady(state.list.pagedData, result.totalDataCount, result.data.map(r => new CampaignListModel(r))),
        }
      });
    }
    case types.LOAD_NEXT_CAMPAIGNS: {
      return Immutable.merge(state, {
        list: {
          pagedData: ReportTableModel.nextPageRequested(state.list.pagedData, action.pageNumber, action.pageSize),
        }
      });
    }
    case types.LOAD_NEXT_CAMPAIGNS_SUCCESS: {
      const result = action.result;
      return Immutable.merge(state, {
        list: {
          pagedData: ReportTableModel.pageReady(state.list.pagedData, result.totalDataCount, result.data.map(r => new CampaignListModel(r))),
        }
      });
    }
    case types.LOAD_CAMPAIGN: {
      return Immutable.merge(state, {
        details: {
          fetching: true,
          data: null,
        },
      });
    }
    case types.LOAD_CAMPAIGN_SUCCESS: {
      const data = action.data;
      return Immutable.merge(state, {
        details: {
          fetching: false,
          data: new CampaignDetailsModel(data),
        },
      });
    }
    case types.LOAD_CAMPAIGN_FAILURE: {
      return Immutable.merge(state, {
        details: {
          fetching: false,
          data: null,
        },
      });
    }
    case types.UPDATE_CAMPAIGN_SUCCESS: {
      return Immutable.merge(state, {
        details: {
          fetching: false,
          data: new CampaignDetailsModel(Object.assign({}, state.details.data, action.data)),
        },
      });
    }
    case types.EXECUTE_CAMPAIGN_SUCCESS: {
      return Immutable.merge(state, {
        details: {
          fetching: false,
          data: new CampaignDetailsModel(Object.assign({}, state.details.data, { executed: true, executedAt: moment.now() })),
        },
      });
    }
    default:
      return state;
  }
}
