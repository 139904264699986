import React from "react";
import PropTypes from "prop-types";
import { Field, Form } from "react-final-form";
import { Button, makeStyles } from "@material-ui/core";
import FormTextField from "../../form/final-form/FormTextField";

const useStyles = makeStyles({
  buttonActions: {
    display: "flex",
    justifyContent: "flex-end",
    gap: 15,
  },
  bold: {
    fontWeight: "bold",
  },
});

const isPhoneNumber = /^\+?\s?\d+(\s?\d+)*$/;

let ChangeUserPhoneNumberForm = ({ onSubmit, onCancel, disabled }) => {
  const classes = useStyles();

  const validation = (values) => {
    const errors = {};

    if (!values.phoneNumber) {
      errors.phoneNumber = "Pole wymagane";
    }
    if (values.phoneNumber) {
      if (!isPhoneNumber.test(values.phoneNumber)) {
        errors.phoneNumber = "Błędny numer telefonu";
      }
    }
    return errors;
  };

  return (
    <Form
      validate={validation}
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting, errors, touched }) => (
        <form onSubmit={handleSubmit}>
          <div>
            <Field
              id="phoneNumber"
              name="phoneNumber"
              component={FormTextField}
              label="Numer telefonu"
              helperText={touched.phoneNumber && errors.phoneNumber}
              error={touched.phoneNumber && errors.phoneNumber}
              style={{ width: "100%" }}
              disabled={disabled}
            />
          </div>
          <div className={classes.buttonActions}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={submitting || disabled}
            >
              Zapisz
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={onCancel}
              disabled={submitting}
            >
              Anuluj
            </Button>
          </div>
        </form>
      )}
    />
  );
};

ChangeUserPhoneNumberForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default ChangeUserPhoneNumberForm;
