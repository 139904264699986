import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import './styles.scss';
import SearchIcon from '@material-ui/icons/FilterList';
import InputAdornment from '@material-ui/core/InputAdornment';

/* eslint react/no-multi-comp: 0 */

const createDeleteFunction = (id) => (filters) => filters.filter(f => f.id !== id);

const activeMode = (onChange, label, filter, items) => (
  <Select
    placeholder={label}
    displayEmpty
    disableUnderline
    startAdornment={<InputAdornment position="start"><SearchIcon style={{ width: 16, opacity: 0.2 }} /></InputAdornment>}
    renderValue={v =>
      <TextField margin="none" style={{padding: 0, flex: 1}} value={v && items[v].name} placeholder={label} InputProps={{ disableUnderline: true, padding: 0 }} />}
    className="select-filter"
    value={(filter && filter.value) || ''}
    margin="none"
    fullWidth
    onChange={(e) => onChange(e.target.value)}
  >
    {Object.values(items).map(item => <MenuItem key={item.value} value={item.value}>{item.name}</MenuItem>)}
  </Select>
);

const readonlyMode = (onDelete, label, filter, items) => (
  <div className="filter">
    <span className="label">{label}</span>
    <Chip className="chip" onDelete={() => onDelete(createDeleteFunction(filter.id))} label={items[filter.value].name} />
  </div>
);

const SelectFilter = ({ onChange, onDelete, readonly, label, filter, items }) => {
  return (
    readonly
      ? (filter ? readonlyMode(onDelete, label, filter, items) : null)
      : activeMode(onChange, label, filter, items)
  );
};

SelectFilter.propTypes = {
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  readonly: PropTypes.bool,
  label: PropTypes.string,
  filter: PropTypes.object,
  items: PropTypes.object,
};

export default SelectFilter;
