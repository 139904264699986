import { Avatar, Badge, makeStyles, Paper, Tooltip } from '@material-ui/core';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { ColumnModel } from '../../../models/ColumnModel';
import WarningIcon from '@material-ui/icons/Warning';
import NotUprightIcon from '@material-ui/icons/Redo';
import PauseIcon from '@material-ui/icons/Pause';
import DisabledBikeIcon from '@material-ui/icons/HighlightOff';
import RideIcon from '@material-ui/icons/DirectionsBike';
import AlarmIcon from '@material-ui/icons/NotificationsActive';
import ReservationIcon from '@material-ui/icons/Timelapse';
import routePaths from '../../../routePaths';
import FilterBar from '../../common/FilterBar';
import queryString from 'query-string';
import BikeListActionsMenu from './BikeListActionsMenu';
import { Link, useHistory } from 'react-router-dom';
import { BikeModel } from '../../../models/BikeModel';
import { permissions, useHasPermission } from '../../../utils/authorization';
import VirtualizedTable from '../../common/virtualized-table/VirtualizedTable';
import { useWindowHeight } from '@react-hook/window-size';
import { actions as bikesActions } from '../../../actions/bikesActions';

const columns = [
  new ColumnModel('actions', false, false, '', 60),
  new ColumnModel('isAvailableForRental', false, false, 'Status', 80),
  new ColumnModel('bikeNumber', false, false, 'Numer roweru', 100),
  new ColumnModel('bikeId', false, false, 'ID roweru', 100),
  new ColumnModel('bikeSerialNumber', false, false, 'Numery seryjny', 125),
  new ColumnModel('lockBatteryLevel', false, false, 'Bateria blokady', 65),
  new ColumnModel('bikeBatteryLevel', false, false, 'Bateria roweru', 80),
  new ColumnModel('lockTimestamp', false, false, 'Aktualizacja', 120),
  new ColumnModel('gpsTimestamp', false, false, 'Czas GPS', 120),
  new ColumnModel('satellites', true, false, 'Satelity', 80),
  new ColumnModel('lockShackleState', true, false, 'Blokada', 80),
  new ColumnModel('openDefectsCount', true, false, 'Otwarte usterki', 80),
  new ColumnModel('nextServiceTime', false, false, 'Kolejny serwis', 140),
];

const renderIconDecoration = (bike, iconElement) => {
  const color = bike.hasAlarmEnabled ? 'error' : 'default';
  if (bike.ongoingPause) return (
    <Badge color={color} badgeContent={<PauseIcon style={{ width: 16, height: 16 }} />}>
      {iconElement}
    </Badge>
  );
  if (bike.isRentalRestricted) return (
    <Badge color={color} badgeContent={<DisabledBikeIcon style={{ width: 16, height: 16 }} />}>
      {iconElement}
    </Badge>
  );
  if (bike.isRented) return (
    <Badge color={color} badgeContent={<RideIcon style={{ width: 16, height: 16 }} />}>
      {iconElement}
    </Badge>
  );
  if (bike.isReserved) return (
    <Badge color={color} badgeContent={<ReservationIcon style={{ width: 16, height: 16 }} />}>
      {iconElement}
    </Badge>
  );
  if (bike.hasAlarmEnabled) return (
    <Badge color={color} badgeContent={<AlarmIcon style={{ width: 16, height: 16 }} />}>
      {iconElement}
    </Badge>
  );
  if (bike.isOrientedUpright === false) return (
    <Badge color={color} badgeContent={<NotUprightIcon style={{ width: 16, height: 16 }} />}>
      {iconElement}
    </Badge>
  );

  return iconElement;
}

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 600,
  },
  warningIconContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  warningIcon: {
    marginLeft: theme.spacing(1),
  }
}));

const sort = (bikes, orderBy, order) => {
  return _.orderBy(bikes, [orderBy], [order]);
}

const ServiceBikeList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const windowHeight = useWindowHeight();
  const [filter, setFilter] = useState('');
  useEffect(() => setFilter(queryString.parse(history.location.search).filter || ''), [history]);
  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('bikeNumber');
  const bikes = useSelector(s => s.bikes);
  const [bikesList, setBikesList] = useState([]);
  useEffect(() => { setBikesList(sort(bikes, orderBy, order)); }, [bikes]);
  const hasServicePermission = useHasPermission(permissions.bikes.service.access);
  const hasDefectsPermission = useHasPermission(permissions.bikeDefects);
  const filteredColumns = useMemo(() => hasServicePermission
    ? columns
    : columns.filter(c => c.id !== 'nextServiceTime'), [hasServicePermission]);


  useEffect(() => {
    dispatch(bikesActions.loadAllBikes({hideBikeAfterLoseGPS: false}));
  }, [])

  const handleRequestSort = (event, property) => {
    const newOrderBy = property;
    let newOrder = 'desc';
    if (orderBy === newOrderBy && order === 'desc')
      newOrder = 'asc';

    setOrder(newOrder);
    setOrderBy(newOrderBy);
    setBikesList(sort(bikesList, orderBy, order));
  }

  const renderBikeDefectsCell = (bike) => {
    if (bike.openDefectsCount === 0) return '-';
    const node = (<div className={classes.warningIconContainer}>
      <span>
        {bike.openDefectsCount}
      </span>
      <WarningIcon color='error' fontSize='small' className={classes.warningIcon} />
    </div>);

    if (!hasDefectsPermission) return node;
    return (
      <Link to={`${routePaths.bikeDefectsFiltered}${bike.bikeNumber}`}>
        {node}
      </Link>);
  }

  const renderCell = (rowData, property) => {
    switch (property) {
      case 'actions': return (
        <BikeListActionsMenu bike={rowData} />
      );
      case 'isAvailableForRental': return (
        <Tooltip
          title={BikeModel.getBikeStatusDescription(rowData)}
          placement='bottom'
          enterTouchDelay={2000}
          leaveTouchDelay={1000}
        >
          {renderIconDecoration(rowData, <Avatar src={rowData.bikeIcon} />)}
        </Tooltip>
      );
      case 'bikeNumber': return rowData[property];
      case 'bikeId': return rowData[property];
      case 'bikeSerialNumber': return rowData[property];
      case 'lockBatteryLevel': return rowData.lockBatteryLevel ? `${rowData.lockBatteryLevel}%` : '-';
      case 'bikeBatteryLevel': return rowData.bikeBatteryLevel != null ? `${rowData.bikeBatteryLevel}%` : '-';
      case 'lockTimestamp': return rowData.lockTimestamp ? moment(rowData.lockTimestamp).format('DD.MM.YYYY HH:mm') : '-';
      case 'gpsTimestamp': return rowData.gpsTimestamp ? moment(rowData.gpsTimestamp).format('DD.MM.YYYY HH:mm') : '-';
      case 'satellites': return rowData.satellites ? rowData.satellites : '-';
      case 'lockShackleState': return rowData[property];
      case 'openDefectsCount': return renderBikeDefectsCell(rowData);
      case 'nextServiceTime': return (
        <Link to={`${routePaths.bikes}/${rowData.id}#service`}>
          <div className={classes.warningIconContainer}>
            <span>
              {rowData.nextServiceTime ? moment(rowData.nextServiceTime).format('DD.MM.YYYY') : ''}
            </span>
            {rowData.serviceDue && <WarningIcon color='error' fontSize='small' className={classes.warningIcon} />}
          </div>
        </Link>
      );
      default:
        return null;
    }
  };

  return (
    <>
      <div className='page'>
        <div style={{ margin: 40, marginBottom: 0 }}>
          <Paper elevation={3} style={{ padding: 16, height: windowHeight - 184, overflowX: 'auto' }}>
            <FilterBar
              keyword={filter}
              itemsToFilter={bikes}
              onFilter={filtered => setBikesList(sort(filtered, orderBy, order))}
              filter={(bike, keyword) =>
                keyword && keyword.length === 36 && bike.id === keyword
                || bike.bikeNumber.toLowerCase().includes(keyword)
                || (bike.bikeId && bike.bikeId.toLowerCase().includes(keyword))}
            />
            <VirtualizedTable
              columns={filteredColumns}
              headerHeight={48}
              rowHeight={60}
              rowCount={bikesList.length}
              rowGetter={({ index }) => bikesList[index]}
              order={order}
              orderBy={orderBy}
              renderCellContent={renderCell}
              onRequestSort={handleRequestSort}
            />
          </Paper>
        </div>
      </div>
    </>
  );
}

export default ServiceBikeList;
