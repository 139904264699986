import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import auth from './authReducer';
import bikes from './bikesReducer';
import bikeTypes from './bikeTypesReducer';
import bikeAlerts from './bikeAlertsReducer';
import bikeServiceHistory from './bikeServiceHistoryReducer';
import bikeHistory from './bikeHistoryReducer';
import bikeManagementDetails from './bikesManagementDetailsReducer';
import bikeDetails from './bikeDetailsReducer';
import pricing from './pricingReducer';
import configuration from './configurationReducer';
import tenant from './tenantReducer';
import notifications from './notificationsReducer';
import vendorBalance from './vendorBalanceReducer';
import zones from './zonesReducer';
import zoneTypes from './zoneTypesReducer';
import operators from './operatorsReducer';
import ridesReportReducer from './reports/rides/reducer';
import rideDetailsReportReducer from './reports/rideDetails/reducer';
import termsAndConditions from './termsAndConditionsReducer';
import clientsReportReducer from './reports/clients/reducer';
import deniedClientsReducer from './reports/clients/deniedClientsReducer';
import clientHistoryReducer from './reports/clientHistory/reducer';
import clientWalletReducer from './reports/clientWallet/reducer';
import bikeDefects from './bikeDefectsReducer';
import bikeServiceParts from './bikeServicePartsReducer';
import campaigns from './campaignsReducer';
import bikeDefectDetails from './bikeDefectDetailsReducer';
import plans from './plansReducer';
import relocationReducer from './relocationReducer';
import relocationConfiguration from './relocationConfigurationReducer';
import cards from './cardsReducer';
import clientCards from './clientCardsReducer';
import cardClients from './cardClientsReducer';
import globalBikeTypes from './globalBikeTypesReducer';
import bikeDefectsConfiguration from './bikeDefectsConfigurationReducer';
import reportsList from './reportsListReducer';
import twoFA from './twoFAReducer';
import { types as authTypes } from '../actions/authActions';
import { reducer as formReducer } from 'redux-form';

const reportsReducers = combineReducers({
  rides: ridesReportReducer,
  rideDetails: rideDetailsReportReducer,
  clients: clientsReportReducer,
  clientsDenyList: deniedClientsReducer,
  clientHistory: clientHistoryReducer,
  clientWallet: clientWalletReducer,
  relocation: relocationReducer,
  clientCards,
  cardClients,
  list: reportsList,
});

const bikeDefectsReducers = combineReducers({
  list: bikeDefects,
  details: bikeDefectDetails,
  serviceParts: bikeServiceParts,
});

const rootReducer = history => {
  const appReducer = combineReducers({
    router: connectRouter(history),
    auth,
    bikes,
    bikeTypes,
    bikeDetails,
    bikeAlerts,
    bikeHistory,
    bikeServiceHistory,
    bikeManagementDetails,
    pricing,
    plans,
    configuration,
    tenant,
    notifications,
    vendorBalance,
    zones,
    zoneTypes,
    operators,
    termsAndConditions,
    relocationConfiguration,
    twoFA,
    form: formReducer,
    reports: reportsReducers,
    bikeDefects: bikeDefectsReducers,
    campaigns,
    cards,
    globalBikeTypes,
    bikeDefectsConfiguration,
  });
  return (state, action) => {
    if (action.type === authTypes.SIGNED_OUT) {
      state = undefined;
    }

    return appReducer(state, action);
  };
};


export default rootReducer;

