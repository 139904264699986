import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { connect } from "react-redux";
import { submit } from "redux-form";
import AssignRFIDCardForm from "./AssignRFIDCardForm";

const AssignRFIDCardDialog = ({
  open,
  onSave,
  onCancel,
  dispatch,
  submitting,
}) => (
  <Dialog open={open}>
    <DialogTitle>Przypisywanie karty RFID do użytkownika</DialogTitle>
    <DialogContent>
      <AssignRFIDCardForm onSubmit={onSave} />
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel} disabled={submitting}>
        Anuluj
      </Button>
      <Button
        onClick={() => dispatch(submit("assignRFIDCard"))}
        disabled={submitting}
        color="secondary"
      >
        Przypisz
      </Button>
    </DialogActions>
  </Dialog>
);

AssignRFIDCardDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  dispatch: PropTypes.func,
  submitting: PropTypes.bool.isRequired,
};

export default connect()(AssignRFIDCardDialog);
