import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { func, node, string } from 'prop-types';
import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { validate } from 'validate.js';
import { actions } from '../../actions/bikesManagementActions';
import FormTextField from '../form/final-form/FormTextField';

const validation = values => validate(
  values,
  { note: { presence: { message: 'Wprowadzenie notatki jest wymagane' } } },
  { fullMessages: false });

const BikeServiceConfirmationDialog = ({ children, bikeId, onBeforeShow }) => {
  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();
  const handleClick = () => {
    if (onBeforeShow) onBeforeShow();
    setOpen(true);
  }

  const handleCancel = () => setOpen(false);
  const handleConfirm = (data) => {
    setSubmitting(true);
    dispatch(actions.confirmService(bikeId, data.note)).then(() => {
      setSubmitting(false);
      setOpen(false);
    })
  }

  return (
    <>
      {React.cloneElement(children, { onClick: handleClick })}

      <Dialog open={open} fullWidth>
        <DialogTitle>Potwierdzenie serwisu</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Wprowadź notatkę
          </DialogContentText>
          <Form
            onSubmit={handleConfirm}
            initialValues={{}}
            validate={validation}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit} id="confirmServiceForm">
                <div>
                  <Field
                    autoFocus
                    name="note"
                    component={FormTextField}
                    multiline
                    label="Notatka"
                    fullWidth
                  />
                </div>
              </form>
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} disabled={submitting} >
            Anuluj
          </Button>
          <Button type="submit" form="confirmServiceForm" disabled={submitting} variant='contained' color="primary">
            Utwórz
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

BikeServiceConfirmationDialog.propTypes = {
  children: node.isRequired,
  bikeId: string.isRequired,
  onBeforeShow: func,
};

export default BikeServiceConfirmationDialog;
