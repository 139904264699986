import React from 'react';
import moment from 'moment';
import { CardStatuses } from '../../../models/cards/CardModel';

/* eslint react/display-name: 0 */
/* eslint react/prop-types: 0 */
/* eslint react/no-multi-comp: 0 */
export const columns = [
  {
    Header: 'Karta',
    id: 'cardProviderId',
    accessor: 'cardProviderId',
    width: 140,
    Filter: () => null,
  },
  {
    Header: 'Numer karty',
    id: 'cardNumber',
    accessor: 'cardNumber',
    width: 180,
    Filter: () => null,
  },
  {
    Header: 'Status',
    id: 'status',
    Cell: row => (<div style={{color: CardStatuses[row.original.status].color}}>{CardStatuses[row.original.status].name}</div>),
    Filter: () => null,
    width: 180,
  },
  {
    Header: 'Data podpięcia',
    id: 'addedAt',
    accessor: x => moment(x.addedAt).local().format('DD.MM.YYYY HH:mm:ss'),
    width: 140,
    Filter: () => null,
  },
  {
    Header: 'Data aktywacji',
    id: 'activatedAt',
    accessor: x => x.activatedAt ? moment(x.activatedAt).local().format('DD.MM.YYYY HH:mm:ss') : null,
    width: 140,
    Filter: () => null,
  },
  {
    Header: 'Data deaktywacji',
    id: 'deactivatedAt',
    accessor: x => x.deactivatedAt  ? moment(x.deactivatedAt).local().format('DD.MM.YYYY HH:mm:ss') : null,
    width: 140,
    Filter: () => null,
  },
];
