import React, { Component } from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "moment-duration-format";
import { Tabs, Tab, CircularProgress } from "@material-ui/core";
import ReportTable from "../../common/reportTable/ReportTable";
import { actions } from "../../../reducers/reports/clientHistory/actions";
import { actions as plansActions } from "../../../actions/plansActions";
import { UserStatus } from "../../../reducers/reports/clients/models";
import { columns } from "./Columns";
import FiltersPanel from "./FiltersPanel";
import SummaryPanel from "./SummaryPanel";
import { permissions, hasPermission } from "../../../utils/authorization";
import ClientWalletDetails from "./ClientWalletDetails";
import ClientCardsDetails from "./ClientCardsDetails";

const tabs = {
  history: "history",
  wallet: "wallet",
  cards: "cards",
};

class ClientHistoryReportPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: tabs.history,
    };

    this.handleFiltersChange = this.handleFiltersChange.bind(this);
    this.handleSortChange = this.handleSortChange.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.setDedicatedPlans = this.setDedicatedPlans.bind(this);
  }

  componentDidMount() {
    const events = this.props.events;
    this.props.actions.loadClientHistoryReport(
      this.props.userId,
      1,
      events.paging.pageSize,
      events.sortedBy,
      events.filteredBy,
    );
    this.props.plansActions.getDedicatedPlansForUser(this.props.userId);
  }

  setDedicatedPlans(clientId, plans) {
    return this.props.plansActions
      .setDedicatedPlans(clientId, plans)
      .then((res) => {
        if (res) {
          this.props.plansActions.getDedicatedPlansForUser(this.props.userId);
        }
      });
  }

  handleSortChange(sortBy) {
    const events = this.props.events;
    this.props.actions.loadClientHistoryReport(
      this.props.userId,
      1,
      events.paging.pageSize,
      sortBy,
      events.filteredBy,
    );
  }

  handleFiltersChange(filterBy) {
    const events = this.props.events;
    this.props.actions.loadClientHistoryReport(
      this.props.userId,
      1,
      events.paging.pageSize,
      events.sortedBy,
      filterBy.filter((f) => f.value),
    );
  }

  handlePageChange(nextPage) {
    this.props.actions.loadNextClientHistoryEvents(this.props.userId, nextPage);
  }

  handlePageSizeChange(nextPageSize) {
    const events = this.props.events;
    this.props.actions.loadClientHistoryReport(
      this.props.userId,
      1,
      nextPageSize,
      events.sortedBy,
      events.filteredBy,
    );
  }

  handleTabChange(event, tab) {
    this.setState({ tab });
  }

  renderContent(tab, events) {
    switch (tab) {
      case tabs.history:
        return (
          <div style={{ padding: 16 }}>
            <ReportTable
              style={{ height: "calc(100vh - 400px)" }}
              columns={columns}
              data={events.records}
              filteredBy={events.filteredBy}
              sortable={false}
              sortedBy={events.sortedBy}
              paging={events.paging}
              onFilteredChange={this.handleFiltersChange}
              onPageChange={this.handlePageChange}
              onPageSizeChange={this.handlePageSizeChange}
              onSortedChange={this.handleSortChange}
              loading={events.loading}
            />
          </div>
        );
      case tabs.wallet:
        return (
          <div style={{ padding: 16 }}>
            <ClientWalletDetails
              userId={this.props.summary.userId}
              userDeleted={
                this.props.summary.userStatus === UserStatus.deleted.value
              }
            />
          </div>
        );
      case tabs.cards:
        return (
          <div style={{ padding: 16 }}>
            <ClientCardsDetails
              userId={this.props.summary.userId}
              userDeleted={
                this.props.summary.userStatus === UserStatus.deleted.value
              }
            />
          </div>
        );
      default:
        return null;
    }
  }

  render() {
    const { events, operatorPermissions, summary, plans, requirePeselNumber } =
      this.props;
    return (
      <div className="page">
        {this.props.loading ? (
          <div className="progress">
            <CircularProgress size={50} />
          </div>
        ) : (
          <div style={{ margin: 40 }}>
            <Paper style={{ padding: 16, marginBottom: 16 }}>
              {this.props.summary && (
                <SummaryPanel
                  summary={summary}
                  uploadParentalConsent={(file) =>
                    this.props.actions.uploadParentalConsent(
                      summary.userId,
                      file,
                    )
                  }
                  removeParentalConsent={() =>
                    this.props.actions.removeParentalConsent(summary.userId)
                  }
                  operatorPermissions={this.props.operatorPermissions}
                  addToDenyList={(data) =>
                    this.props.actions.addToDenyList(summary.clientId, data)
                  }
                  removeFromDenyList={() =>
                    this.props.actions.removeFromDenyList(summary.clientId)
                  }
                  changeUserPhoneNumber={(phoneNumber) =>
                    this.props.actions.changeUserPhoneNumber(
                      summary.userId,
                      phoneNumber,
                    )
                  }
                  confirmAssignPhoneNumber={(phoneNumber) =>
                    this.props.actions.confirmAssignPhoneNumber(
                      summary.userId,
                      phoneNumber,
                    )
                  }
                  onResendVerificationEmail={() =>
                    this.props.actions.resendVerificationEmail(summary.userId)
                  }
                  setDedicatedPlans={this.setDedicatedPlans}
                  dedicatedPlans={plans}
                  requirePeselNumber={requirePeselNumber}
                />
              )}
            </Paper>
            <FiltersPanel
              filteredBy={events.filteredBy}
              onFilteredChange={this.handleFiltersChange}
            />
            <Paper>
              <Tabs
                value={this.state.tab}
                indicatorColor="secondary"
                textColor="secondary"
                onChange={this.handleTabChange}
              >
                <Tab value={tabs.history} label="Historia zdarzeń" />
                {hasPermission(
                  operatorPermissions,
                  permissions.accessWalletData,
                ) && <Tab value={tabs.wallet} label="Portfel" />}
                {hasPermission(
                  operatorPermissions,
                  permissions.manageCards,
                ) && <Tab value={tabs.cards} label="Karty miejskie" />}
              </Tabs>
              {this.renderContent(this.state.tab, events)}
            </Paper>
          </div>
        )}
      </div>
    );
  }
}

ClientHistoryReportPage.propTypes = {
  actions: PropTypes.object.isRequired,
  plansActions: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  summary: PropTypes.object,
  events: PropTypes.object,
  userId: PropTypes.string.isRequired,
  operatorPermissions: PropTypes.array.isRequired,
  plans: PropTypes.array.isRequired,
  requirePeselNumber: PropTypes.bool,
};

ClientHistoryReportPage.defaultProps = {
  loading: false,
  summary: null,
  events: {},
  requirePeselNumber: false,
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    plansActions: bindActionCreators(plansActions, dispatch),
  };
}

function mapStateToProps(state, ownProps) {
  const reportState = state.reports.clientHistory;
  const tenantState = state.tenant;
  return {
    userId: ownProps.match.params.id,
    summary: reportState.summary,
    events: reportState.pagedData,
    loading: reportState.loading,
    operatorPermissions: state.auth.permissions,
    plans: state.plans,
    requirePeselNumber: tenantState.requirePeselNumber,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClientHistoryReportPage);
