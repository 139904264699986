import { Button, Typography } from '@material-ui/core';
import { func, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

const ImagePicker = ({ onFilePicked, fallbackImage }) => {
  const [file, setFile] = useState();
  useEffect(() => () => {
    if (file != null) {
      URL.revokeObjectURL(file.preview);
    }
  }, [file]);

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: 'image/*',
    onDrop: acceptedFiles => {
      const file = acceptedFiles[0];
      Object.assign(file, { preview: URL.createObjectURL(file) });
      setFile(file);
      //read file to memery
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => onFilePicked({
        name: file.name,
        data: reader.result,
      });
    },
  });

  const handleClear = () => {
    setFile(null);
    onFilePicked(null);
  };

  const renderDropzone = () => {
    if (file) return <img style={{ width: 150, height: 150, objectFit: 'contain' }} src={file.preview}></img>;
    if (fallbackImage) return (
      <a href={fallbackImage} target="_blank" rel="noopener noreferrer">
        <img style={{ width: 150, height: 150, objectFit: 'contain' }} src={fallbackImage} />
      </a>
    );
    return <div style={{ background: '#ccc', minWidth: 150, width: 150, height: 150, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Typography variant='caption' style={{ flex: '0 0 120px' }}>
        Przeciągnij plik tutaj
      </Typography>
    </div >;
  }

  return (
    <div className="container">
      <div {...getRootProps({ className: 'dropzone' })}>
        <div>
          <input {...getInputProps()} />
          {renderDropzone()}
        </div>
        <div style={{ paddingTop: 4 }}>
          <Button color="secondary" onClick={open}>
            Wybierz plik
          </Button>
          <Button onClick={handleClear}>
            Wyczyść
          </Button>
        </div>
      </div>
    </div >
  );
};

ImagePicker.propTypes = {
  onFilePicked: func.isRequired,
  fallbackImage: string,
};

export default ImagePicker;
