export class RegionModel {
  constructor({ latitude, longitude, latitudeDelta = 0.005, longitudeDelta = 0.005 }) {
    if (arguments.length != 0) {
      validate(latitude, "latitude");
      validate(longitude, "longitude");
      validate(latitudeDelta, "latitudeDelta");
      validate(longitudeDelta, "longitudeDelta");
    }

    this.latitude = latitude;
    this.longitude = longitude;
    this.latitudeDelta = latitudeDelta;
    this.longitudeDelta = longitudeDelta;
  }

  toLatLngBounds() {
    return {
      north: this.latitude + this.latitudeDelta,
      south: this.latitude - this.latitudeDelta,
      east: this.longitude + this.longitudeDelta,
      west: this.longitude - this.longitudeDelta,
    };
  }
}

const validate = (value, name, model = 'LocationModel', isInvalid = v => v == null) => {
  if (isInvalid(value)) {
    /*eslint no-console:0*/
    console.error(`${model} requires ${name}`);
  }
};
