import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import routePaths from '../../../../routePaths';
import FormTextField from '../../../form/redux-form/FormTextField';

const nonNegative = value => Number(value) < 0  ? 'Wartość nie może być ujemna' : undefined;

let ZoneRowFields = ({ fields }) => {
  return (
    <Fragment>
      <Grid container spacing={2}>
        {fields.map((zoneRow, i) => (
          <Grid key={i} item container spacing={1}>
            <Grid key={i} xs={12} item>
              <Typography variant="caption">{fields.get(i).zoneName}</Typography>
            </Grid>
            <Grid container spacing={1} item xs={12}>
              <Grid item>
                <Field
                  name={`${zoneRow}.desiredBikesCount`}
                  label="100%"
                  type="number"
                  validate={nonNegative}
                  component={FormTextField}
                  parse={value => value == null || value === "" ? null : Number(value)}
                  margin="dense"
                />
              </Grid>
              <Grid item>
                <Field
                  name={`${zoneRow}.minThreshold`}
                  label="Minimum"
                  type="number"
                  component={FormTextField}
                  validate={nonNegative}
                  parse={value => Number(value)}
                  margin="dense"
                  endAdornmentText="%"
                />
              </Grid>
              <Grid item>
                <Field
                  name={`${zoneRow}.maxThreshold`}
                  label="Maksimum"
                  type="number"
                  component={FormTextField}
                  validate={nonNegative}
                  parse={value => Number(value)}
                  margin="dense"
                  endAdornmentText="%"
                />
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Fragment>
  );
};

ZoneRowFields.propTypes = {
  fields: PropTypes.object.isRequired,
};

let RelocationConfigurationForm = ({
  handleSubmit,
  submitting,
  disabled,
  invalid,
 }) => {
  return (
    <form onSubmit={handleSubmit}>
      <Typography variant="subtitle2" gutterBottom style={{ marginTop: 30 }}>Liczba rowerów dla stref</Typography>
      <FieldArray name="data" component={ZoneRowFields} />

      <div className="actions">
        <Button style={{ marginRight: 8 }} component={Link} to={routePaths.relocationReport} disabled={submitting}>
          Anuluj
        </Button>
        <Button variant="contained" color="primary" type="submit" disabled={disabled || submitting || invalid}>
          {submitting ? 'Zapisywanie...' : 'Zapisz'}
        </Button>
      </div>
    </form >
  );
};

RelocationConfigurationForm.propTypes = {
  configuration: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  initialValues: PropTypes.object,
  invalid: PropTypes.bool,
  disabled: PropTypes.bool,
};

RelocationConfigurationForm = reduxForm({
  form: 'relocationConfiguration',
  enableReinitialize: true
})(RelocationConfigurationForm);

RelocationConfigurationForm = connect(
  (state, ownProps) => ({
    initialValues: { data: ownProps.configuration },
  })
)(RelocationConfigurationForm);

export default RelocationConfigurationForm;

