import React, {useEffect, useState} from 'react';
import {DaysOfWeek} from "../../../reducers/reports/rides/models";
import PropTypes from "prop-types";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import {FormControl, makeStyles} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import DateRangeIcon from "@material-ui/icons/DateRange";
import {TimePicker} from "@material-ui/pickers";
import {excludeFiltersForRides} from "../../../reducers/reports/rides/actions";


const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  }
}));

const AdditionalFilters = ({ onFilteredChange, filteredBy }) => {
  const classes = useStyles();

  const [filterValues, setFilterValues] = useState({
    days: [],
    timeRangeStart: null,
    timeRangeEnd: null,
  });

  useEffect(() => {
    const filteredByWithAdditional = [...excludeFiltersForRides(filteredBy)];
    if (filterValues.days.length !== 0){
      filteredByWithAdditional.push({id: 'days', value: filterValues.days})
    }
    if (filterValues.timeRangeStart){
      filteredByWithAdditional.push({id: 'timeRangeStart', value: filterValues.timeRangeStart})
    }
    if (filterValues.timeRangeEnd){
      filteredByWithAdditional.push({id: 'timeRangeEnd', value: filterValues.timeRangeEnd})
    }
    onFilteredChange(filteredByWithAdditional)
  }, [filterValues])

  return (
    <div>
      <h3>Dodatkowe filtry</h3>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="dropdown">Dni tygodnia</InputLabel>
          <Select
            label="Dni tygodnia"
            value={filterValues.days}
            multiple={true}
            onChange={event => setFilterValues({...filterValues, days: event.target.value})}
            input={<Input id={`dropdown`} />}
          >
            {Object.keys(DaysOfWeek).map(key => (
              <MenuItem key={DaysOfWeek[key].value} value={DaysOfWeek[key].value}>{DaysOfWeek[key].name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <div style={{ margin: '5px 0 10px 0'}}>
          <div style={{ whiteSpace: 'nowrap'}}><h4>Zakres czasowy:</h4></div>
          <div style={{ display: 'flex'}}>
            <div style={{width: 150, margin: '0 10px'}}>
              <TimePicker
                placeholder={"Początkowy"}
                ampm={false}
                InputProps={{
                  disableUnderline: true,
                  startAdornment: <InputAdornment position="start"><DateRangeIcon style={{ width: 16 }} /></InputAdornment>,
                }}
                className="date-time-filter"
                value={filterValues.timeRangeStart}
                margin="none"
                cancelLabel="Anuluj"
                clearable={true}
                clearLabel="Wyczyść"
                fullWidth
                onChange={(v) => setFilterValues({...filterValues, timeRangeStart: v ? v.format() : null})}
              />
            </div>
            <div style={{width: 150}}>
              <TimePicker
                placeholder={"Końcowy"}
                ampm={false}
                InputProps={{
                  disableUnderline: true,
                  startAdornment: <InputAdornment position="start"><DateRangeIcon style={{ width: 16 }} /></InputAdornment>,
                }}
                className="date-time-filter"
                value={filterValues.timeRangeEnd}
                margin="none"
                cancelLabel="Anuluj"
                clearable={true}
                clearLabel="Wyczyść"
                fullWidth
                onChange={(v) => setFilterValues({...filterValues, timeRangeEnd: v ? v.format() : null})}
              />
            </div>
          </div>
        </div>
    </div>
  )
}

AdditionalFilters.propTypes = {
  onFilteredChange: PropTypes.func.isRequired,
  filteredBy: PropTypes.array.isRequired,
};

export default AdditionalFilters;
