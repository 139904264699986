import initialState from './initialState';
import Immutable from 'seamless-immutable';
import { types } from '../actions/relocationActions';
import { RelocationReportModel } from '../models/relocation/RelocationReportModel';

export default function relocationReducer(state = Immutable(initialState.reports.relocation), action) {
  switch (action.type) {
    case types.GET_RELOCATION_DATA_SUCCESS: {
      return Object.assign(new RelocationReportModel(), action.data);
    }
    default:
      return state;
  }
}
