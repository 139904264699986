import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import PropTypes from "prop-types";

const NotEnabled2FADialog = ({ open, onClose, onAccept }) => {
  return (
    <Dialog open={open}>
      <DialogTitle>
        Pamiętaj aby aktywować uwierzytelnianie dwuskładnikowe
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{ textAlign: "justify" }}>
          Włączenie uwierzytelniania dwuskładnikowego (2FA) w systemie ROOVEE to
          skuteczny sposób na zwiększenie bezpieczeństwa Twojego konta. Dzięki
          2FA, oprócz hasła, będziesz potrzebować dodatkowego kodu
          weryfikacyjnego generowanego na Twoim urządzeniu mobilnym. To znacząco
          utrudnia nieuprawniony dostęp do konta, chroniąc Twoje dane przed
          potencjalnymi zagrożeniami.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Zamknij</Button>
        <Button variant="contained" onClick={onAccept} color="primary">
          Przejdź do konfiguracji
        </Button>
      </DialogActions>
    </Dialog>
  );
};

NotEnabled2FADialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
};

export default NotEnabled2FADialog;
