import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import FormMapView from './FormMapView';

class FormMapField extends React.Component {
  constructor(props) {
    super(props);

    this.handleMapClick = this.handleMapClick.bind(this);
  }

  handleMapClick(location) {
    const newLocation = {
      lat: location.latLng.lat(),
      lng: location.latLng.lng(),
    };
    this.props.input.onChange(newLocation);
  }

  render() {
    const { input, label, zones } = this.props;
    return (
      <div style={{ marginTop: 10 }}>
        <Typography variant="caption">{label}</Typography>
        <FormMapView
          loadingElement={<div style={{ height: '100%', width: 300, }} />}
          containerElement={<div style={{ width: '100%', height: 300 }} />}
          mapElement={<div style={{ width: '100%', height: 300 }} />}
          onMapLoad={ref => this.mapRef = ref}
          onMapClick={this.handleMapClick}
          marker={input.value || { lat: 0, lng: 0 }}
          onMarkerRightClick={() => { }}
          initialLocation={input.value || { lat: 0, lng: 0 }}
          initialZoom={7}
          zones={zones}
        />
      </div>
    );
  }
}
FormMapField.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  zones: PropTypes.array,
};

export default FormMapField;
