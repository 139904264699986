import apiBase from './apiBase';

class OperatorsApi {
  static getAllOperators() {
    return apiBase.create().get('/operations/operators');
  }

  static getOperator(id) {
    return apiBase.create().get(`/operations/operators/${id}`);
  }

  static registerOperator(operatorData) {
    return apiBase.create().post('/operations/operators', operatorData);
  }

  static updateOperator(id, operatorData) {
    return apiBase.create().put(`/operations/operators/${id}`, operatorData);
  }

  static deleteOperator(id) {
    return apiBase.create().delete(`/operations/operators/${id}`);
  }

  static getAuditLog(id) {
    return apiBase.create().get(`/operations/operators/${id}/audit-log`, null, { responseType: 'blob', timeout: 120000 });
  }
}

export default OperatorsApi;
