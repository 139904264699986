import Immutable from 'seamless-immutable';
import initialState from './initialState';
import { types } from '../actions/reportsActions';
import { ReportTableModel } from '../models/ReportTableModel';

export default function reportsListReducer(state = Immutable(initialState.reports.list), action) {
  switch (action.type) {
    case types.LOAD_REPORTS: {
      return Immutable.merge(state, {
        loading: true,
        pagedData: ReportTableModel.pageRequested(state.pagedData, action.pageNumber, action.pageSize, action.filterBy, action.sortBy),
      });
    }
    case types.LOAD_REPORTS_SUCCESS: {
      const result = action.result;
      return Immutable.merge(state, {
        loading: false,
        pagedData: ReportTableModel.pageReady(state.pagedData, result.totalDataCount, result.data),
      });
    }
    case types.LOAD_NEXT_CLIENTS_REPORT: {
      return Immutable.merge(state, {
        pagedData: ReportTableModel.nextPageRequested(state.pagedData, action.pageNumber, action.pageSize),
      });
    }
    case types.LOAD_NEXT_REPORTS_SUCCESS: {
      const result = action.result;
      const paging= {
          pageNumber: result.pageNumber,
          pageSize: result.pageSize,
          totalPages: Math.ceil(result.totalDataCount / result.pageSize) || 1,
          totalSize: result.totalDataCount,
        }
      return Immutable.merge(state, {
        pagedData: ReportTableModel.pageReady(state.pagedData, result.totalDataCount, result.data, paging),
      });
    }
    case types.GET_CUSTOM_REPORT_LIST: {
      return Immutable.merge(state, {
        loadingCustomReportList: true,
        customReportList: [],
      });
    }
    case types.GET_CUSTOM_REPORT_LIST_SUCCESS: {
      const result = action.result;
      return Immutable.merge(state, {
        loadingCustomReportList: false,
        customReportList: result,
      });
    }
    case types.GET_CUSTOM_REPORT_LIST_FAILURE: {
      return Immutable.merge(state, {
        loadingCustomReportList: false,
      });
    }
    case types.CREATE_CUSTOM_REPORT: {
      return Immutable.merge(state, {
        savingTemplateCustomReport: true
      });
    }
    case types.CREATE_CUSTOM_REPORT_SUCCESS: {
      return Immutable.merge(state, {
        savingTemplateCustomReport: false
      });
    }
    case types.CREATE_CUSTOM_REPORT_FAILURE: {
      return Immutable.merge(state, {
        savingTemplateCustomReport: false
      });
    }
    case types.GET_CUSTOM_REPORT: {
      return Immutable.merge(state, {
        loadingCustomReport: true,
        customReport: null,
      });
    }
    case types.GET_CUSTOM_REPORT_SUCCESS: {
      const result = action.result;
      return Immutable.merge(state, {
        loadingCustomReport: false,
        customReport: result,
      });
    }
    case types.GET_CUSTOM_REPORT_FAILURE: {
      return Immutable.merge(state, {
        loadingCustomReport: false,
      });
    }
    default:
      return state;
  }
}

