import React from 'react';
import PropTypes from 'prop-types';
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import withScriptjs from 'react-google-maps/lib/withScriptjs';
import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';
import config from '../../../config';
import bikeAvailableIcon from '../../../assets/bikeAvailableIcon.png';
import bikeRentedIcon from '../../../assets/bikeRentedIcon.png';


export const userLocationIcon = {
  path: 'M19 2H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h4l3 3 3-3h4c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-7 3.3c1.49 0 2.7 1.21 2.7 2.7 0 1.49-1.21 2.7-2.7 2.7-1.49 0-2.7-1.21-2.7-2.7 0-1.49 1.21-2.7 2.7-2.7zM18 16H6v-.9c0-2 4-3.1 6-3.1s6 1.1 6 3.1v.9z',
  fillColor: red[700],
  fillOpacity: 1,
  strokeWeight: 0,
  anchor: { x: 12, y: 24 }
};

export const operatorLocationIcon = {
  path: 'M19 2H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h4l3 3 3-3h4c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-7 3.3c1.49 0 2.7 1.21 2.7 2.7 0 1.49-1.21 2.7-2.7 2.7-1.49 0-2.7-1.21-2.7-2.7 0-1.49 1.21-2.7 2.7-2.7zM18 16H6v-.9c0-2 4-3.1 6-3.1s6 1.1 6 3.1v.9z',
  fillColor: blue[700],
  fillOpacity: 1,
  strokeWeight: 0,
  anchor: { x: 12, y: 24 }
};

const noPoi = [
  {
    featureType: "poi.business",
    stylers: [
      { visibility: "off" }
    ]
  }
];
const mapOptions = { streetViewControl: false, mapTypeControl: true, clickableIcons: false, styles: noPoi };
const MapViewRaw = withScriptjs(withGoogleMap(props => (
  <GoogleMap
    ref={props.onMapLoad}
    defaultZoom={17}
    defaultCenter={{ lat: props.bikeLocation.lat, lng: props.bikeLocation.lng }}
    defaultOptions={mapOptions}
  >
    <Marker
      title='Lokalizacja roweru w chwili zgłaszania'
      icon={bikeAvailableIcon}
      opacity={0.8}
      position={{ lat: props.bikeLocation.lat, lng: props.bikeLocation.lng }}
    />
    {props.userLocation &&
      <Marker
        title='Lokalizacja zgłaszającego'
        icon={userLocationIcon}
        position={{ lat: props.userLocation.lat, lng: props.userLocation.lng }}
      />
    }
    {props.bikeLocationAtResolution &&
      <Marker
        title='Lokalizacja roweru w momencie zamykania zgłoszenia'
        icon={bikeRentedIcon}
        opacity={0.8}
        position={{ lat: props.bikeLocationAtResolution.lat, lng: props.bikeLocationAtResolution.lng }}
      />
    }
    {props.operatorLocation &&
      <Marker
        title='Lokalizacja operatora zamykającego zgłoszenie'
        icon={operatorLocationIcon}
        position={{ lat: props.operatorLocation.lat, lng: props.operatorLocation.lng }}
      />
    }
  </GoogleMap>
)));

const DefectLocationMap = (props) => (<MapViewRaw googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&key=${config.GOOGLE_MAPS_API_KEY}`} {...props} />);

DefectLocationMap.propTypes = {
  bikeLocation: PropTypes.object.isRequired,
  userLocation: PropTypes.object,
  bikeLocationAtResolution: PropTypes.object,
  operatorLocation: PropTypes.object,
};

export default DefectLocationMap;
