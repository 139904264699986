import dataUriToBlob from '../utils/dataUriToBlob';
import apiBase from './apiBase';

class TermsAndConditionsApi {
  static get() {
    return apiBase.create().get('/operations/terms');
  }

  static createVersion(data) {
    return apiBase.create().post('/operations/terms/newVersion', data);
  }

  static update(id, content, additionalImage, additionalImageDelete, languageCode) {
    const formData = new FormData();
    formData.append('languageCode', languageCode);
    formData.append('content', content);
    formData.append('additionalImageDelete', additionalImageDelete);
    if (additionalImage != null) {
      formData.append('additionalImage', dataUriToBlob(additionalImage.data), additionalImage.name);
    }

    return apiBase.create().put(`/operations/terms/${id}`, formData);
  }

  static updateClause(id, content, languageCode) {
    return apiBase.create().put(`/operations/terms/${id}/personalDataProcessingClause`, { content, languageCode });
  }

  static publish(id) {
    return apiBase.create().post(`/operations/terms/${id}/publish`);
  }
}

export default TermsAndConditionsApi;
