import React, { useEffect } from 'react';
import { CircularProgress, Paper, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../../../actions/relocationActions';
import RelocationConfigurationForm from './RelocationConfigurationForm';

const handleSubmit = dispatch => data => {
  const payload = {};
  data.data.forEach(d => {
    const { desiredBikesCount, minThreshold, maxThreshold, zoneId } = d;
    payload[zoneId] = { desiredBikesCount, minThreshold, maxThreshold };
  });

  return dispatch(actions.updateConfiguration(payload));
};

const RelocationConfigurationPage = () => {
  const dispatch = useDispatch();
  useEffect(() => { dispatch(actions.loadConfiguration()) }, []);
  const loading = useSelector(x => x.relocationConfiguration.loading);
  const configuration = useSelector(x => x.relocationConfiguration.data);
  const onSubmit = handleSubmit(dispatch);

  return (
    <div className="page">
      {loading
        ?
        <div className="progress">
          <CircularProgress size={50} />
        </div>
        :
        <div style={{ margin: 40 }}>
          <Typography variant="subtitle1" gutterBottom>Konfiguracja relokacji</Typography>
          <Paper style={{ padding: 16, marginBottom: 16 }}>
            <RelocationConfigurationForm configuration={configuration} onSubmit={onSubmit} />
          </Paper>
        </div>
      }
    </div>
  );
};

export default RelocationConfigurationPage;

