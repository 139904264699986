import React, { Component } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { withStyles } from '@material-ui/core/styles';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import MyProfileMenu from './appBar/MyProfileMenu';
import AppDrawer from './AppDrawer';
import './AppStyles.scss';
import IdleCountdown from './appBar/IdleCountdown';

const styles = theme => ({
  appBar: {
    transition: theme.transitions.create('width'),
  },
  [theme.breakpoints.up('lg')]: {
    appBarShift: {
      width: 'calc(100% - 250px)',
    },
    appContainer: {
      marginLeft: 250
    },
    navIconHide: {
      display: 'none',
    },
  },
});

class AppFrame extends Component {
  constructor(props) {
    super(props);

    this.state = {
      drawerOpen: false,
    };

    this.handleDrawerClose = this.handleDrawerClose.bind(this);
    this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
  }

  handleDrawerClose() {
    this.setState({ drawerOpen: false });
  }

  handleDrawerToggle() {
    this.setState({ drawerOpen: !this.state.drawerOpen });
  }

  render() {
    const { classes, autoLogoutTimeout, width, auth, children, showAppDrawer } = this.props;
    const drawerDocked = isWidthUp('lg', width);
    const appBarClassName = `${classes.appBar} ${showAppDrawer  ? classes.appBarShift : null}`;
    const navIconClassName = `${classes.navIconHide}`;

    return (
      <div className="root">
        <AppBar className={appBarClassName} position="fixed">
          <Toolbar>
            <IconButton color="default" aria-label="Menu" onClick={this.handleDrawerToggle} className={navIconClassName}>
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" />
            <div className="grow" />
            <Typography color="inherit">{auth.firstName} {auth.lastName}</Typography>
            <MyProfileMenu />
            {autoLogoutTimeout != null && <IdleCountdown autoLogoutTimeout={autoLogoutTimeout} />}
          </Toolbar>
        </AppBar>
        {showAppDrawer && <AppDrawer
          docked={drawerDocked}
          onRequestClose={this.handleDrawerClose}
          open={drawerDocked || this.state.drawerOpen}
        />}
        <div className={showAppDrawer ? classes.appContainer : null}>
          {children}
        </div>
      </div>
    );
  }
}

AppFrame.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  width: PropTypes.string,
  auth: PropTypes.object.isRequired,
  autoLogoutTimeout: PropTypes.number,
  showAppDrawer: PropTypes.bool
};
AppFrame.defaultProps = {
  showAppDrawer: true
}

export default compose(
  withStyles(styles, {
    name: 'AppFrame'
  }),
  withWidth(),
)(AppFrame);
