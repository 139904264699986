import validate from 'validate.js';
import dataUriToBlob from '../../utils/dataUriToBlob';

const genericRequiredMessage = 'Pole jest wymagane';

export const validateSync = values => {
  const constraints = {
    name: {
      presence: { message: genericRequiredMessage },
    },
  };

  return validate(values, constraints, { fullMessages: false });
};

const validateImageSize = (fieldName, dataUri) => new Promise((resolve, reject) => {
  const blob = dataUriToBlob(dataUri);
  if (blob.type !== 'image/png') {
    const error = {};
    error[fieldName] = 'Wskazany plik musi być obrazem w formacie PNG.';
    return reject(error);
  }
  const image = new Image();
  image.onload = () => {
    const error = {};
    if (image.naturalWidth !== 80 || image.naturalHeight !== 92)
      error[fieldName] = 'Obraz musi mieć wymiary 80x92 pikseli (szerokość x wysokość).';
    if (error[fieldName]) reject(error);
    else resolve();
  };
  image.src = dataUri;
});


export const validateAsync = (values, dispatch, props, field) => {
  if (field === 'availableBikeIconDataUri' && values.availableBikeIconDataUri != null)
    return validateImageSize('availableBikeIconDataUri', values.availableBikeIconDataUri);
  if (field === 'occupiedBikeIconDataUri' && values.occupiedBikeIconDataUri != null)
    return validateImageSize('occupiedBikeIconDataUri', values.occupiedBikeIconDataUri);
  return Promise.resolve();
};
