import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { Button, Grid, Typography } from '@material-ui/core';
import FormSelect from '../../form/redux-form/FormSelect';
import routePaths from '../../../routePaths';
import { BikePart } from '../../../models/bikeDefects/BikePart';
import { DefectSeverity } from '../../../models/bikeDefects/DefectSeverity';
import { connect } from 'react-redux';
import BikeParts from '../details/BikeParts';
import FormTextField from '../../form/redux-form/FormTextField';

const capitalize = s => {
  return s.charAt(0).toUpperCase() + s.slice(1)
}

let BikeDefectsConfigurationForm = ({
  handleSubmit,
  submitting,
  disabled }) => {
  return (
    <form onSubmit={handleSubmit}>
      <Typography variant="subtitle2" gutterBottom style={{ marginTop: 30 }}>Czasy serwisowe</Typography>
      {Object.keys(DefectSeverity).map(severity => (
        <div key={severity}>
          <Field
            name={`responseTimes.${capitalize(DefectSeverity[severity].value)}`}
            component={FormTextField}
            label={DefectSeverity[severity].name}
            endAdornmentText="min."
            type="number"
            readonly={disabled}
          />
        </div>
      ))}

      <Typography variant="subtitle2" gutterBottom>Kategorie usterek</Typography>
      <Grid container spacing={1}>
        {Object.keys(BikePart).map(part => (
          <React.Fragment key={part}>
            <Grid item xs={2} sm={1} style={{ textAlign: 'center' }}>
              <BikeParts bikeParts={[part]} style={{}} />
            </Grid>
            <Grid item xs={10} sm={11}>
              <Field
                name={`severities.${capitalize(BikePart[part].value)}`}
                component={FormSelect}
                style={{ width: 200 }}
                values={DefectSeverity}
                readonly={disabled}
              />
            </Grid>
          </React.Fragment>
        ))}
      </Grid>

      <div className="actions">
        <Button style={{ marginRight: 8 }} component={Link} to={routePaths.bikeDefects} disabled={submitting}>
          Anuluj
        </Button>
        <Button variant="contained" color="primary" type="submit" disabled={disabled || submitting}>
          {submitting ? 'Zapisywanie...' : 'Zapisz'}
        </Button>
      </div>
    </form>
  );
};

BikeDefectsConfigurationForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  initialValues: PropTypes.object,
  disabled: PropTypes.bool,
};

BikeDefectsConfigurationForm = reduxForm({
  form: 'bikeDefectsConfiguration',
  enableReinitialize: true
})(BikeDefectsConfigurationForm);

BikeDefectsConfigurationForm = connect(
  (state, ownProps) => ({
    initialValues: ownProps.initialValues,
  })
)(BikeDefectsConfigurationForm);

export default BikeDefectsConfigurationForm;
