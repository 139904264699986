import React from 'react';
import TextFilter from '../../common/reportTable/filters/TextFilter';
import AccessTimeIcon from '@material-ui/icons/FindInPage';
import IconButton from '@material-ui/core/IconButton';
import { Link } from 'react-router-dom';
import routePaths from '../../../routePaths';

const styles = {
  deleted: {
    color: '#ccc',
  },
  locked: {
    color: '#ff8f00',
  },
};


/* eslint react/display-name: 0 */
/* eslint react/prop-types: 0 */
/* eslint react/no-multi-comp: 0 */
export const getColumns = () => {
  return [
    {
      Header: '',
      id: 'show_details',
      Cell: row => (
        <Link to={`${routePaths.globalClientsReport}/${row.original.userId}`}>
          <IconButton style={{height: 24, width: 24, margin: "-3px 3px"}}>
            <AccessTimeIcon/>
          </IconButton>
        </Link>),
      width: 50,
      sortable: false,
      Filter: () => null,
    },
    {
      Header: 'Imię',
      id: 'firstName',
      accessor: a => <span style={styles[a.userStatus]}>{a.firstName}</span>,
      width: 160,
      Filter: ({filter, onChange}) => <TextFilter onChange={onChange} filter={filter} label="filtruj..."/>,
    },
    {
      Header: 'Nazwisko',
      id: 'lastName',
      accessor: a => <span style={styles[a.userStatus]}>{a.lastName}</span>,
      width: 160,
      Filter: ({filter, onChange}) => <TextFilter onChange={onChange} filter={filter} label="filtruj..."/>,
    },
    {
      Header: 'Nr telefonu',
      id: 'phoneNumber',
      accessor: a => <span style={styles[a.userStatus]}>{a.phoneNumber}</span>,
      width: 125,
      Filter: ({filter, onChange}) => <TextFilter onChange={onChange} filter={filter} label="filtruj..."/>,
    },
    {
      Header: 'Email',
      id: 'email',
      accessor: a => <span style={styles[a.userStatus]}>{a.email}</span>,
      width: 180,
      Filter: ({filter, onChange}) => <TextFilter onChange={onChange} filter={filter} label="filtruj..."/>,
    }
  ];
}
