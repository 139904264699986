import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AppFrame from './AppFrame';

/*eslint import/no-unresolved:0*/
//have to figure out how to force webpack to provide imports for eslint to not declare it twice
import rooveeTheme from '../styles/rooveeTheme';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import NotificationsHandler from './notifications/NotificationsHandler';
import './AppStyles.scss';
import routePaths from '../routePaths';

if (!String.prototype.startsWith) {
  String.prototype.startsWith = function (searchString, position) {
    position = position || 0;
    return this.indexOf(searchString, position) === position;
  };
}

class App extends React.Component {
  constructor() {
    super();
  }

  renderUnauthorizedContent() {
    return (
      <div className="root">
        {this.props.children}
        <NotificationsHandler />
      </div>
    );
  }

  renderAppContent(showAppDrawer = true) {
    const { auth, children, tenant } = this.props;
    return (
      <AppFrame
        auth={auth}
        autoLogoutTimeout={tenant.operatorAutoLogoutTimeoutSeconds}
        showAppDrawer={showAppDrawer}
      >
        {children}
        <NotificationsHandler />
      </AppFrame>
    );
  }

  render() {
    const { auth, pathname } = this.props;
    return (
      <MuiThemeProvider theme={createTheme(rooveeTheme)}>
        {auth.signedIn
          && pathname != routePaths.passwordReset
          && pathname != routePaths.passwordRecovery
          && pathname != routePaths.accountActivation
          && pathname != routePaths.signIn
          && pathname != routePaths.twoFactorAuth
          ? pathname !== routePaths.passwordRequireChange ? this.renderAppContent(true) : this.renderAppContent(false)
          : this.renderUnauthorizedContent()}
      </MuiThemeProvider>
    );
  }
}

App.propTypes = {
  children: PropTypes.element,
  pathname: PropTypes.string.isRequired,
  auth: PropTypes.object.isRequired,
  tenant: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    tenant: state.tenant,
    pathname: state.router.location.pathname,
  };
}

export default connect(mapStateToProps)(App);
