import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { connect } from "react-redux";
import { submit } from "redux-form";
import PutBikeOutOfServiceForm from "./PutBikeOutOfServiceForm";

const PutBikeOutOfServiceDialog = ({
  open,
  onSave,
  onCancel,
  dispatch,
  submitting
}) => (
  <Dialog open={open}>
    <DialogTitle>Tymczasowe wyłączenie roweru z użytkowania</DialogTitle>
    <DialogContent>
      <PutBikeOutOfServiceForm onSubmit={onSave} />
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel} disabled={submitting}>
        Anuluj
      </Button>
      <Button
        onClick={() => dispatch(submit("putBikeOutOfService"))}
        disabled={submitting}
        variant="contained"
        color="primary"
      >
        Potwierdź
      </Button>
    </DialogActions>
  </Dialog>
);

PutBikeOutOfServiceDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  dispatch: PropTypes.func,
  submitting: PropTypes.bool.isRequired
};

export default connect()(PutBikeOutOfServiceDialog);
