import React from 'react'
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import PropTypes from "prop-types";
import { Form } from 'react-final-form'
import {Button, DialogContentText, makeStyles} from "@material-ui/core";

const useStyles = makeStyles({
  buttonActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 15
  },
  bold: {
    fontWeight: 'bold'
  }
});

const DeactivateUserAccountDialog = ({onSave, onCancel, open}) => {
  const classes = useStyles()
  return (
    <Dialog open={open}>
      <DialogTitle>Deaktywacja konta</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <div>
            Czy chcesz deaktywować konto użytkownika?
          </div>
          <div style={{ color: 'red' }}>
            Operacja jest nieodwracalna
          </div>
        </DialogContentText>
        <Form
          onSubmit={onSave}
          render={({handleSubmit, submitting}) => (
            <>
              <form onSubmit={handleSubmit}>
                <div className={classes.buttonActions}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={submitting}
                  >
                    Deaktywuj
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onCancel}
                    disabled={submitting}
                  >
                    Anuluj
                  </Button>
                </div>
              </form>
            </>
          )}
        />
      </DialogContent>
    </Dialog>
  );
}

DeactivateUserAccountDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  dispatch: PropTypes.func,
};

export default DeactivateUserAccountDialog;
