import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import RelocationHistoryReportForm from "./RelocationHistoryReportForm";
import {useDispatch} from "react-redux";
import { actions } from '../../../actions/relocationActions'

const RelocationHistoryReportDialog = ({ open, onCancel }) => {

  const dispatch = useDispatch();

  const onSubmit = (value) => {
    dispatch(actions.requestExcelHistoryExport(value))
  }

  return (
    <Dialog open={open} fullWidth maxWidth="xs">
      <DialogTitle>Historia</DialogTitle>
      <DialogContent>
        <RelocationHistoryReportForm
          onSubmit={onSubmit}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>
          Anuluj
        </Button>
        <Button variant="contained" type={"submit"} color="primary" form="relocationHistoryReportGenerateForm">
          Wygeneruj
        </Button>
      </DialogActions>
    </Dialog>
  );
}

RelocationHistoryReportDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default RelocationHistoryReportDialog;
