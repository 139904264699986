import React, {useEffect} from 'react';
import Paper from '@material-ui/core/Paper';
import { useDispatch, useSelector} from 'react-redux';
import { actions } from '../../../reducers/reports/clients/actions';
import ReportTable from '../../common/reportTable/ReportTable';
import FiltersPanel from "./FiltersPanel";
import {getColumns} from "./Columns";
import {hasPermission, permissions} from "../../../utils/authorization";
import {useHistory} from "react-router-dom";
import routePaths from "../../../routePaths";

const GlobalClientsReportPage = () => {
  const reportState = useSelector(state => state.reports.clients);
  const stateAuth = useSelector(state => state.auth);

  const dispatch = useDispatch();
  const history = useHistory();

  const handleFiltersChange = (filterBy) => {
    if(filterBy && filterBy.length === 0 || !filterBy){
      dispatch(actions.clearGlobalClientReports());
      return;
    }
    const clients = reportState.globalClientsPageData;
    dispatch(actions.loadFreshGlobalClientReports(1, clients.paging.pageSize, clients.sortedBy,
      filterBy.filter(f => f.value)));
  }

  const handlePageChange = (nextPage) => {
    dispatch(actions.loadNextGlobalClientReports(nextPage));
  }

  const handlePageSizeChange = (nextPageSize) => {
    const clients = reportState.globalClientsPageData;
    if(clients.filterBy && clients.filterBy.length === 0 || !clients.filterBy){
      dispatch(actions.clearGlobalClientReports());
      return;
    }
    dispatch(actions.loadFreshGlobalClientReports(1, nextPageSize, clients.sortedBy, clients.filteredBy));
  }

  const handleSortChange = (sortBy) => {
    const clients = reportState.globalClientsPageData;
    if(clients.filterBy && clients.filterBy.length === 0 || !clients.filterBy){
      dispatch(actions.clearGlobalClientReports());
      return;
    }
    dispatch(actions.loadFreshGlobalClientReports(1, clients.paging.pageSize, sortBy, clients.filteredBy));
  }

  useEffect(() => {
    const allowAccess = hasPermission(stateAuth.permissions, permissions.globalClients);
    if(!allowAccess) {
      history.replace(routePaths.home)
    }
  }, [])

  return (
    <div className="page">
      <div style={{ margin: 40, marginBottom: 0 }}>
        <div style={{ fontSize: 16, fontWeight: 'bold' }}>Uzupełnij poniższy filtr aby wyświetlić dane</div>
        <FiltersPanel filteredBy={reportState.globalClientsPageData.filteredBy} onFilteredChange={handleFiltersChange}/>
        <Paper style={{ padding: 16 }}>
          <ReportTable
            style={{ height: "calc(100vh - 200px)" }}
            columns={getColumns()}
            data={reportState.globalClientsPageData.records}
            filteredBy={reportState.globalClientsPageData.filteredBy}
            sortedBy={reportState.globalClientsPageData.sortedBy}
            paging={reportState.globalClientsPageData.paging}
            onFilteredChange={handleFiltersChange}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            onSortedChange={handleSortChange}
            loading={reportState.globalClientsPageData.loading}
          />
        </Paper>
      </div>
    </div>
  );
}

export default GlobalClientsReportPage;
