import validate from 'validate.js';

const genericRequiredMessage = 'Pole jest wymagane';

export const operatorEditModelValidation = values => {
  const constraints = {
    firstName: {
      presence: { message: genericRequiredMessage },
    },
    lastName: {
      presence: { message: genericRequiredMessage },
    },
    role: {
      presence: { message: genericRequiredMessage },
    },
    email: {
      email: { message: 'Niepoprawny adres email' },
    },
  };

  return validate(values, constraints, { fullMessages: false });
};
