import { Paper, Typography } from '@material-ui/core';
import { push } from 'connected-react-router';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../../actions/tenantActions';
import routePaths from '../../../routePaths';
import MaxRentalsPerUserSettingsForm from './MaxRentalsPerUserSettingsForm';

const MaxRentalsPerUserSettingsPage = () => {
  const dispatch = useDispatch();
  const tenantState = useSelector(x => x.tenant);
  const handleSubmit = (data) => {
    dispatch(actions.updateMaximumRentalsPerUser(data.maximumRentalsPerUser, data.maximumRentalsPerTester))
      .then(r => {
        if (r) dispatch(push(routePaths.pricings));
      });
  };

  return (
    <div className="page" >
      <div style={{ margin: 40 }}>
        <Typography variant="h5" gutterBottom>Konfiguracja</Typography>
        <Paper style={{ padding: 16 }}>
          <MaxRentalsPerUserSettingsForm
            initialValues={{
              maximumRentalsPerUser : tenantState.maximumRentalsPerUser,
              maximumRentalsPerTester: tenantState.maximumRentalsPerTester
          }}
            onSubmit={handleSubmit}
          />
        </Paper>
      </div>
    </div>
  );
}

export default MaxRentalsPerUserSettingsPage;
