import { RideRecordModel } from '../rides/models';
import { LocationModel } from '../../../models/LocationModel';

export class RideTelemetryModel {
  constructor(data) {
    this.batteryLevel = data.batteryLevel;
    this.speed = data.speed;
    this.latitude = data.latitude;
    this.longitude = data.longitude;
    this.location = new LocationModel(data.latitude, data.longitude);
    this.altitude = data.altitude;
    this.azimuth = data.azimuth;
    this.gpsFix = data.gpsFix;
    this.gpsTimestamp = data.gpsTimestamp;
    this.firmware = data.firmware;
    this.hdop = data.hdop;
    this.satellites = data.satellites;
    this.lockTimestamp = data.lockTimestamp;
    this.lockState = data.lockState;
    this.attemptLocation = data.attemptLocation;
    this.shackleState = data.shackleState;
    this.source = data.source;
    this.rideId = data.rideId;
    this.frameId = data.frameId;
    this.createdAt = data.createdAt;
    this.correlationId = data.correlationId;
  }
}

export class RideDetailsReportModel {
  constructor() {
    this.loading = false;
    this.telemetry = [];
    this.summary = new RideRecordModel({});
  }
}
