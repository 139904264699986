import initialState from './initialState';
import Immutable from 'seamless-immutable';
import { types } from '../actions/bikeDetailsActions';
import { types as bikesTypes } from '../actions/bikesActions';
import { BikeModel } from '../models/BikeModel';

export default function bikesReducer(state = Immutable(initialState.bikeDetails), action) {
  switch (action.type) {
    case types.SELECT_BIKE: {
      const { bike } = action;
      return state.merge(new BikeModel(bike));
    }
    case types.DESELECT_BIKE: {
      return state.merge(initialState.bikeDetails);
    }
    case bikesTypes.GET_ALL_BIKES_REQUEST_SUCCESS: {
      const { bikes } = action;
      const bike = bikes.find(b => b.id === state.id);
      if (!bike) return state;
      return state.merge(new BikeModel(bike));
    }
    default:
      return state;
  }
}
