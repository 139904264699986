import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import './styles.scss';
import { UserStatus } from '../../../reducers/reports/clients/models';

const ClientStatus = ({ status, style }) =>
  <Chip
    className={`client-status-${status}`}
    label={`Konto ${UserStatus[status].name}`}
    style={style}
  />;

ClientStatus.propTypes = {
  status: PropTypes.string,
  style: PropTypes.object,
};

export default ClientStatus;
