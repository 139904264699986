import initialState from './initialState';
import Immutable from 'seamless-immutable';
import { types } from '../actions/termsAndConditionsActions';
import { TermsAndConditionsModel, VersionStatus } from '../models/termsAndConditions/TermsAndConditionsModel';

export default function termsAndConditionsReducer(state = Immutable(initialState.termsAndConditions), action) {
  switch (action.type) {
    case types.LOAD_TERMS_SUCCESS: {
      const { termsAndConditions } = action;
      return new TermsAndConditionsModel(termsAndConditions.id, termsAndConditions.personalDataProcessingClauses, termsAndConditions.versions);
    }
    case types.CREATE_TERMS_VERSION_SUCCESS: {
      const { draft } = action;
      const newDraft = {
        version: draft.version,
        status: VersionStatus.draft.value,
        contents: {},
        additionalImages: {},
      };

      return new TermsAndConditionsModel(draft.id, state.personalDataProcessingClauses, [newDraft, ...state.versions]);
    }
    case types.UPDATE_TERMS_SUCCESS: {
      const { content, languageCode, additionalImageUrl } = action;
      const currentDraft = state.versions.find(v => v.status === VersionStatus.draft.value);
      const newDraft = Object.assign({}, currentDraft);
      const newContents = Object.assign({}, newDraft.contents);
      const newAddionalImages = Object.assign({}, newDraft.additionalImages);
      newContents[languageCode] = content;
      newAddionalImages[languageCode] = additionalImageUrl;
      newDraft.contents = newContents;
      newDraft.additionalImages = newAddionalImages;

      return Immutable.merge(state, {
        versions: [newDraft, ...state.versions.filter(v => v.status !== VersionStatus.draft.value)],
      });
    }
    default:
      return state;
  }
}
