import initialState from './initialState';
import Immutable from 'seamless-immutable';
import _ from 'lodash';
import { types } from '../actions/vendorBalanceActions';
import { VendorBalanceModel } from '../models/payments/VendorBalanceModel';

export default function vendorBalanceReducer(state = Immutable(initialState.vendorBalance), action) {
  switch (action.type) {
    case types.LOAD_VENDOR_BALANCE_SUCCESS: {
      const { vendorBalance } = action;
      return Object.assign(new VendorBalanceModel(), vendorBalance);
    }
    case types.PAYOUT_SUCCESS: {
      const { payout } = action;
      return Immutable.merge(state, {
        availableFunds: _.round(state.availableFunds - payout.amount, 2),
        payouts: [payout, ...state.payouts]
      });
    }
    default:
      return state;
  }
}
