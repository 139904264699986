export class PricingModel {
  constructor() {
    this.id = null;
    this.bikeTypeId = null;
    this.cardId = null;
    this.rentalPreconditions = {
      minimalBalance: {},
    };
    this.freeMinutesPerDay = null;
    this.freeMinutesPer30Day = null;
    this.priceList = [];
    this.deductions = {
      bikeLeftInZone: {},
    };
    this.penalties = {
      bikeLeftOutOfPreferredZone: {},
      bikeLeftOutOfOperatorZone: {},
    };
    this.cardsPriority = [];
  }
}

export const FareCategory = Object.freeze({
  undetermined: { value: "undetermined", name: "Nieokreślona" },
  payment: { value: "payment", name: "Opłata" },
  sanction: { value: "sanction", name: "Sankcja" },
});

export const PaymentType = Object.freeze({
  rentalTimeBelow12H: {
    value: "rentalTimeBelow12H",
    name: "Opłata za czas przejazdu poniżej 12 godzin",
  },
  bikeLeftOutOfPreferredZonePenalty: {
    value: "bikeLeftOutOfPreferredZonePenalty",
    name: "Opłata za pozostawienie roweru poza preferowaną strefą zwrotu",
  },
  reward: { value: "reward", name: "Bonus za zwrot roweru do strefy" },
  otherPayments: { value: "otherPayments", name: "Inna" },
});

export const CorrectionReasonType = Object.freeze({
  lockProblem: {
    value: "lockProblem",
    name: "Problem z blokadą",
  },
  pauseProblem: {
    value: "pauseProblem",
    name: "Problem z pauzą",
  },
  bikeReturnToZone: {
    value: "bikeReturnToZone",
    name: "Rower zwrócony do strefy",
  },
  aberrationGps: {
    value: "aberrationGps",
    name: "Aberracja GPS",
  },
  rentalCancellation: {
    value: "rentalCancellation",
    name: "Rezygnacja z wypożyczenia",
  },
  bikeLeftNearZone: {
    value: "bikeLeftNearZone",
    name: "Rower pozostawiony przy granicy strefy parkowania",
  },
  bikeLeftNearOperationZone: {
    value: "bikeLeftNearOperationZone",
    name: "Rower pozostawiony przy granicy obszaru operatora",
  },
  bikeDefect: {
    value: "bikeDefect",
    name: "Usterka roweru",
  },
  bonusCorrection: {
    value: "bonusCorrection",
    name: "Korekta bonusu",
  },
  freeRide: {
    value: "freeRide",
    name: "Darmowy przejazd (promocja)",
  },
  systemFailure: {
    value: "systemFailure",
    name: "Awaria systemu",
  },
  other: {
    value: "other",
    name: "Inne",
  },
});

export const CorrectionReasonForViolationRulesType = Object.freeze({
  usingBicycleByMoreThanOnePerson: {
    value: "usingBicycleByMoreThanOnePerson",
    name: "Korzystanie z roweru w więcej niż 1 osobę",
  },
  transportingPeopleAgainstRegulations: {
    value: "transportingPeopleAgainstRegulations",
    name: "Przewożenie osób niezgodnie z regulaminem",
  },
  usingBicycleAgainstItsPurpose: {
    value: "usingBicycleAgainstItsPurpose",
    name: "Korzystanie z roweru niezgodnie z przeznaczeniem",
  },
  leavingBicycleInNonPublicArea: {
    value: "leavingBicycleInNonPublicArea",
    name: "Pozostawienie roweru w miejscu innym niż publiczne",
  },
  stoleBike: {
    value: "stoleBike",
    name: "Kradzież roweru",
  },
  damageOrTheftOfRooveeZone: {
    value: "damageOrTheftOfRooveeZone",
    name: "Uszkodzenie lub kradzież strefy Roovee",
  },
  acceptedComplaint: {
    value: "acceptedComplaint",
    name: "Uznana reklamacja",
  },
  bonusCorrection: {
    value: "bonusCorrection",
    name: "Korekta bonusu",
  },
  systemFailure: {
    value: "systemFailure",
    name: "Awaria systemu",
  },
  other: {
    value: "other",
    name: "Inne",
  },
});

export const CorrectionReasonForBikeDamageType = Object.freeze({
  batteryWithLuggageRack: {
    value: "batteryWithLuggageRack",
    name: "Bateria z bagażnikiem",
  },
  lock: {
    value: "lock",
    name: "Blokada",
  },
  mudguard: {
    value: "mudguard",
    name: "Błotnik",
  },
  grips: {
    value: "grips",
    name: "Chwyty",
  },
  gearShiftSensor: {
    value: "gearShiftSensor",
    name: "Czujnik zmiany biegów",
  },
  dynamo: {
    value: "dynamo",
    name: "Dynamo",
  },
  bell: {
    value: "bell",
    name: "Dzwonek",
  },
  childSeat: {
    value: "childSeat",
    name: "Fotelik",
  },
  frontBrake: {
    value: "frontBrake",
    name: "Hamulec przedni",
  },
  rearRollerBrake: {
    value: "rearRollerBrake",
    name: "Hamulec tył rolkowy",
  },
  handlebar: {
    value: "handlebar",
    name: "Kierownica",
  },
  crank: {
    value: "crank",
    name: "Korba",
  },
  basket: {
    value: "basket",
    name: "Koszyk",
  },
  frontLight: {
    value: "frontLight",
    name: "Lampa przód",
  },
  rearLight: {
    value: "rearLight",
    name: "Lampa tył",
  },
  shifters: {
    value: "shifters",
    name: "Manetki",
  },
  rim: {
    value: "rim",
    name: "Obręcz",
  },
  tire: {
    value: "tire",
    name: "Opona",
  },
  pedal: {
    value: "pedal",
    name: "Pedał",
  },
  hub: {
    value: "hub",
    name: "Piasta",
  },
  kickstand: {
    value: "kickstand",
    name: "Podpórka",
  },
  powerCablesPlugs: {
    value: "powerCablesPlugs",
    name: "Przewody zasilające/wtyczki",
  },
  bikeFrame: {
    value: "bikeFrame",
    name: "Rama roweru",
  },
  motor: {
    value: "motor",
    name: "Silnik",
  },
  saddle: {
    value: "saddle",
    name: "Siodełko",
  },
  cargoBox: {
    value: "cargoBox",
    name: "Skrzynia roweru",
  },
  motorController: {
    value: "motorController",
    name: "Sterownik silnika",
  },
  spoke: {
    value: "spoke",
    name: "Szprycha",
  },
  seatPost: {
    value: "seatPost",
    name: "Sztyca",
  },
  phoneHolder: {
    value: "phoneHolder",
    name: "Uchwyt na telefon",
  },
  wiringHarness: {
    value: "wiringHarness",
    name: "Więźka przewodów",
  },
  fork: {
    value: "fork",
    name: "Widelec",
  },
  stem: {
    value: "stem",
    name: "Wspornik kierownicy",
  },
  sprocket: {
    value: "sprocket",
    name: "Zębatka",
  },
  additionalRepairWork: {
    value: "additionalRepairWork",
    name: "Dodatkowe prace naprawcze",
  },
  DestructionBike: {
    value: "DestructionBike",
    name: "Całkowite zniszczenie roweru",
  },
  acceptedComplaint: {
    value: "acceptedComplaint",
    name: "Uznana reklamacja",
  },
  other: {
    value: "other",
    name: "Inne (wymagany Komentarz)",
  },
});

export const SanctionType = Object.freeze({
  rentalTimeOver12H: {
    value: "rentalTimeOver12H",
    name: "Kara za czas przejazdu powyżej 12 godzin",
  },
  bikeLeftOutOfOperationsZonePenalty: {
    value: "bikeLeftOutOfOperationsZonePenalty",
    name: "Kara za zakończenie przejazdu poza obszarem funkcjonowania",
  },
  bikeDamage: { value: "bikeDamage", name: "Uszkodzenie roweru" },
  violationRules: { value: "violationRules", name: "Złamanie regulaminu" },
  infrastructureDamage: {
    value: "infrastructureDamage",
    name: "Uszkodzenie infrastruktury",
  },
  otherSanction: { value: "otherSanction", name: "Inna" },
});

export const FeeTypes = Object.freeze({
  free: { value: "free", name: "Darmowy przejazd" },
  fixed: { value: "fixed", name: "Stała opłata" },
  perInterval: { value: "perInterval", name: "Naliczanie minutowe" },
});

export const BonusTypes = Object.freeze({
  bikeLeftInZone: {
    value: "bikeLeftInZone",
    name: "Zwrot w preferowanej strefie zwrotu",
  },
});

export const PenaltyTypes = Object.freeze({
  bikeLeftOutOfPreferredZone: {
    value: "bikeLeftInZone",
    name: "Pozostawienie roweru poza preferowaną strefą zwrotu",
  },
  bikeLeftOutOfOperatorZone: {
    value: "bikeLeftInZone",
    name: "Pozostawienie roweru poza obszarem operatora",
  },
});
