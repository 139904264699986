import React from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import numberFormatter from "../../../../utils/numberFormatter";
import FareSanctionAdjustmentForm from "./FareSanctionAdjustmentForm";

const FareSanctionAdjustmentDialog = ({
  open,
  rideDetails,
  onSave,
  onCancel,
  submitting,
  zones,
}) => (
  <Dialog open={open}>
    <DialogTitle>Korekta</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Wprowadź kwotę o jaką zmianiasz całkowity koszt przejazdu. Kwota te
        zostanie zarejestrowana jako sankcja/kara. Aktualny koszt przejazdu{" "}
        <strong>{numberFormatter.format(rideDetails.finalFare)}</strong> zł.
      </DialogContentText>
      <FareSanctionAdjustmentForm
        onCancel={onCancel}
        zones={zones}
        onSubmit={onSave}
        submitting={submitting}
        totalAmount={rideDetails.finalFare}
      />
    </DialogContent>
  </Dialog>
);

FareSanctionAdjustmentDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  rideDetails: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  dispatch: PropTypes.func,
  submitting: PropTypes.bool.isRequired,
  zones: PropTypes.object.isRequired,
};

export default FareSanctionAdjustmentDialog;
