import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { InputAdornment } from '@material-ui/core';

const FormTextField = ({
  input,
  label,
  readonly,
  endAdornmentText,
  meta: { touched, error, submitError },
  ...custom
}) => (
  <TextField
    label={label}
    error={touched && (error || submitError) ? true : false}
    helperText={touched && (error || submitError)}
    margin="dense"
    disabled={readonly === true}
    InputProps={endAdornmentText ? { endAdornment: <InputAdornment position="end">{endAdornmentText}</InputAdornment> } : undefined}
    {...input}
    {...custom}
  />
);

FormTextField.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  meta: PropTypes.object,
  readonly: PropTypes.bool,
  endAdornmentText: PropTypes.string,
};

export default FormTextField;
