export class PlanModel {
  constructor(data) {
    data = data || {};
    this.id = data.id;
    this.name = data.name;
    this.description = data.description;
    this.price = data.price;
    this.validity = data.validity;
    this.pricingConfiguration = data.pricingConfiguration;
    this.internalUseOnly = data.internalUseOnly;
    this.dedicated = data.dedicated;
  }
}

export const PlanValidityTypes = Object.freeze({
  daily: { value: 'daily', name: 'Dzień' },
  monthly: { value: 'monthly', name: 'Miesiąc' },
  quarterly: { value: 'quarterly', name: 'Kwartał' },
  annualy: { value: 'annualy', name: 'Rok' },
});

export const ParseDedicatedPlansToSelect = (dedicatedPlans) => {
  const options = {}
  dedicatedPlans.forEach(x => {
    options[x.id] = {value: x.id, name: x.name}
  })
  return options;
}
