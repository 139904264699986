import React from 'react';
import PropTypes from 'prop-types';
import * as KeyCode from 'keycode-js';
import Badge from '@material-ui/core/Badge';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PutBikeOutOfServiceDialog from './PutBikeOutOfServiceDialog';
import NotUprightIcon from '@material-ui/icons/Redo';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ConfirmationIconButton from '../common/ConfirmationIconButton';
import FilterBar from '../common/FilterBar';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import RefreshIcon from '@material-ui/icons/Refresh';
import ServiceIcon from '@material-ui/icons/Build';
import PauseIcon from '@material-ui/icons/Pause';
import RideIcon from '@material-ui/icons/DirectionsBike';
import AlarmIcon from '@material-ui/icons/NotificationsActive';
import DeactivateAlarmIcon from '@material-ui/icons/NotificationsOff';
import ReservationIcon from '@material-ui/icons/Timelapse';
import { ColumnModel } from '../../models/ColumnModel';
import routePaths from '../../routePaths';
import { BikeModel } from '../../models/BikeModel';
import Avatar from '@material-ui/core/Avatar';
import EnhancedTableHead from '../enhencedTableHead/EnhancedTableHead';
import { actions } from '../../actions/bikesManagementActions';
import moment from 'moment';
import _ from 'lodash';
import { permissions, hasPermission } from '../../utils/authorization';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import AddBikeNoteDialog from './AddBikeNoteDialog';

class BikesManagementList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: "asc",
      orderBy: "bikeNumber",
      filteredBikes: props.bikes,
      selected: props.selected,
      confirmDeleteOpen: false,
      bikeToDelete: null,
      putBikeOutOfServiceDialog: {
        visible: false,
        submitting: false,
        bikeId: null
      },
      addNoteDialog: {
        visible: false,
        submitting: false,
        bikeId: null
      },
      deactivateAlarm: {
        submitting: false,
      },
      columnData: [
        new ColumnModel("isAvailableForRental", false, false, "Status"),
        new ColumnModel("bikeNumber", false, false, "Numer roweru"),
        new ColumnModel("lockPhoneNumber", false, false, "Numer tel zamka"),
        new ColumnModel("lockBatteryLevel", false, false, "Bateria blokady"),
        new ColumnModel("bikeBatteryLevel", false, false, "Bateria roweru"),
        new ColumnModel("lockTimestamp", false, false, "Aktualizacja"),
        new ColumnModel("gpsTimestamp", false, false, "Czas GPS"),
        new ColumnModel("actions", false, false, "")
      ]
    };
  }

  handleEdit = bikeId => this.props.history.push(`${routePaths.bikes}/${bikeId}`);

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    const data = _.orderBy(this.state.filteredBikes, [orderBy], [order]);

    this.setState({ order, orderBy, filteredBikes: data });
  }

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      this.setState({ selected: this.state.filteredBikes.map(n => n.id) });
      return;
    }
    this.setState({ selected: [] });
  }

  handleKeyDown = (event, id) => {
    if (event.code === KeyCode.KEY_SPACE) {
      this.handleClick(event, id);
    }
  }

  handleClick = (_, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    this.setState({ selected: newSelected });
  }

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  handleDecommissionBike = (_, id) => this.setState({ confirmDeleteOpen: true, bikeToDelete: id });

  handleShowPutBikeOutOfServiceDialog = bikeId =>
    this.setState({
      putBikeOutOfServiceDialog: {
        visible: true,
        submitting: false,
        bikeId
      }
    });

  handleShowAddNoteDialog = bikeId =>
    this.setState({
      addNoteDialog: {
        visible: true,
        submitting: false,
        bikeId
      }
    });

  handleFilterChange = filteredList => this.setState({ filteredBikes: filteredList });

  handleAddBikeNote = note =>
    this.props.actions
      .addBikeNote(this.state.addNoteDialog.bikeId, note)
      .then(() => this.setState({ addNoteDialog: { visible: false, submitting: false, bikeId: null } }));

  handleAddBikeNoteCancel = () =>
    this.setState({
      addNoteDialog: {
        visible: false,
        submitting: false,
        bikeId: null
      }
    });

  handlePutBikeInService = bikeId => this.props.actions.putBikeInService(bikeId);

  handlePutBikeOutOfService = data => {
    const { reason } = data;
    this.props.actions
      .putBikeOutOfService(this.state.putBikeOutOfServiceDialog.bikeId, reason)
      .then(() => this.setState({ putBikeOutOfServiceDialog: { visible: false, submitting: false, bikeId: null } }));
  }

  handlePutBikeOutOfServiceCancel = () =>
    this.setState({
      putBikeOutOfServiceDialog: {
        visible: false,
        submitting: false,
        bikeId: null
      }
    });

  handleReportLockStatus = bikeId => this.props.actions.sendReportLockStatusCommand(bikeId);

  handleDeactivateAlarm = bikeId => {
    this.setState({ deactivateAlarm: { submitting: true } });
    this.props.actions.deactivateAlarm(bikeId)
      .finally(() => this.setState({ deactivateAlarm: { submitting: false } }));
  }

  handleCancel = () => this.setState({ confirmDeleteOpen: false, bikeToDelete: null });

  handleOk = () => {
    this.props.actions.decommissionBike(this.state.bikeToDelete);
    this.setState({ confirmDeleteOpen: false, bikeToDelete: null });
  }

  renderIconDecoration = (bike, iconElement) => {
    const color = bike.hasAlarmEnabled ? 'error' : 'default';
    if (bike.ongoingPause) return (
      <Badge color={color} badgeContent={<PauseIcon style={{ width: 16, height: 16 }} />}>
        {iconElement}
      </Badge>
    );
    if (bike.isRentalRestricted) return (
      <Badge color={color} badgeContent={<ServiceIcon style={{ width: 16, height: 16 }} />}>
        {iconElement}
      </Badge>
    );
    if (bike.isRented) return (
      <Badge color={color} badgeContent={<RideIcon style={{ width: 16, height: 16 }} />}>
        {iconElement}
      </Badge>
    );
    if (bike.isReserved) return (
      <Badge color={color} badgeContent={<ReservationIcon style={{ width: 16, height: 16 }} />}>
        {iconElement}
      </Badge>
    );
    if (bike.hasAlarmEnabled) return (
      <Badge color={color} badgeContent={<AlarmIcon style={{ width: 16, height: 16 }} />}>
        {iconElement}
      </Badge>
    );
    if (bike.isOrientedUpright === false) return (
      <Badge color={color} badgeContent={<NotUprightIcon style={{ width: 16, height: 16 }} />}>
        {iconElement}
      </Badge>
    );

    return iconElement;
  }

  renderDeactivateAlarmAction = bikeId => {
    return (
      hasPermission(this.props.userPermissions, permissions.bikes.deactivateAlarm)
        ?
        <Tooltip title="Wyłącz alarm">
          <IconButton disabled={this.state.deactivateAlarm.submitting} onClick={() => this.handleDeactivateAlarm(bikeId)}>
            <DeactivateAlarmIcon />
          </IconButton>
        </Tooltip>
        : null
    );
  }

  renderPutBikeOutOfServiceAction = bikeId => {
    return (
      hasPermission(this.props.userPermissions, permissions.bikes.putOutOfService)
        ?
        <Tooltip title="Tymczasowo wyłącz rower z użytkowania">
          <IconButton onClick={() => this.handleShowPutBikeOutOfServiceDialog(bikeId)}>
            <ServiceIcon />
          </IconButton>
        </Tooltip>
        : null
    );
  }

  renderPutBikeInServiceAction = (bikeId, bikeNumber) => {
    return (
      hasPermission(this.props.userPermissions, permissions.bikes.putInService)
        ? <ConfirmationIconButton
          confirmationTitle="Przywróć rower do użytkowania"
          confirmationText={`Czy na pewno chcesz przywrócić rower ${bikeNumber} do użytkowania?`}
          onConfirm={() => this.handlePutBikeInService(bikeId)}
          onCancel={() => { }}
          aria-label="Przywróć rower do użytkowania"
          tooltip="Przywróć rower do użytkowania"
        >
          <ServiceIcon />
        </ConfirmationIconButton>
        : null
    );
  }

  renderPutBikeOutOfServiceDialog = () => {
    return (
      <PutBikeOutOfServiceDialog
        open={this.state.putBikeOutOfServiceDialog.visible}
        onSave={this.handlePutBikeOutOfService}
        onCancel={this.handlePutBikeOutOfServiceCancel}
        submitting={this.state.putBikeOutOfServiceDialog.submitting}
      />
    );
  }

  renderAddBikeNoteDialog = () => {
    return (
      <AddBikeNoteDialog
        open={this.state.addNoteDialog.visible}
        onSave={this.handleAddBikeNote}
        onCancel={this.handleAddBikeNoteCancel}
        submitting={this.state.addNoteDialog.submitting}
      />
    );
  }

  render() {
    const { selected } = this.props;
    const { filteredBikes } = this.state;
    return (
      <React.Fragment>
        <FilterBar
          keyword={this.props.filter}
          itemsToFilter={this.props.bikes}
          onFilter={this.handleFilterChange}
          filter={(bike, keyword) =>
            keyword && keyword.length === 36 && bike.id === keyword
            || bike.bikeNumber.toLowerCase().includes(keyword)
            || bike.lockImei.toLowerCase().includes(keyword)}
        />
        <Table>
          <EnhancedTableHead
            numSelected={selected.length}
            order={this.state.order}
            orderBy={this.state.orderBy}
            onRequestSort={this.handleRequestSort}
            onSelectAllClick={this.handleSelectAllClick}
            headCells={this.state.columnData}
            isSortable={false}
            isSelectable={false}
          />
          <TableBody>
            {filteredBikes.map(bike => {
              const isSelected = this.isSelected(bike.id);
              return (
                <TableRow
                  hover
                  onClick={event => this.handleClick(event, bike.id)}
                  onKeyDown={event => this.handleKeyDown(event, bike.id)}
                  role="checkbox"
                  aria-checked={isSelected}
                  tabIndex="-1"
                  key={bike.id}
                //selected={isSelected}
                >
                  <TableCell size="small">
                    <Tooltip
                      title={BikeModel.getBikeStatusDescription(bike)}
                      placement="right"
                    >
                      {this.renderIconDecoration(bike, <Avatar src={bike.bikeIcon} />)}
                    </Tooltip>
                  </TableCell>
                  <TableCell padding="none">{bike.bikeNumber}</TableCell>
                  <TableCell padding="none">{bike.lockPhoneNumber}</TableCell>
                  <TableCell padding="none">{bike.lockBatteryLevel ? `${bike.lockBatteryLevel} %` : "-"}</TableCell>
                  <TableCell padding="none">{bike.bikeBatteryLevel != null ? `${bike.bikeBatteryLevel} %` : "-"}</TableCell>
                  <TableCell padding="none">
                    {bike.lockTimestamp
                      ? moment(bike.lockTimestamp).format("DD.MM.YYYY HH:mm")
                      : "-"}
                  </TableCell>
                  <TableCell padding="none">
                    {bike.gpsTimestamp
                      ? moment(bike.gpsTimestamp).format("DD.MM.YYYY HH:mm:ss")
                      : "-"}
                  </TableCell>
                  <TableCell padding="none" style={{ textAlign: "right" }}>
                    {hasPermission(this.props.userPermissions, permissions.bikes.addNote)
                      ? <Tooltip title="Dodaj notatkę">
                        <IconButton onClick={() => this.handleShowAddNoteDialog(bike.id)}>
                          <NoteAddIcon />
                        </IconButton>
                      </Tooltip>
                      : null}
                    {bike.hasAlarmEnabled
                      ? this.renderDeactivateAlarmAction(bike.id)
                      : null}
                    {!bike.isRentalRestricted
                      ? this.renderPutBikeOutOfServiceAction(bike.id)
                      : this.renderPutBikeInServiceAction(bike.id, bike.bikeNumber)}
                    {hasPermission(this.props.userPermissions, permissions.bikes.requestTelemetry)
                      ? <Tooltip title="Wyślij żądanie odświeżenia danych">
                        <IconButton onClick={() => this.handleReportLockStatus(bike.id)}>
                          <RefreshIcon />
                        </IconButton>
                      </Tooltip>
                      : null}
                    {hasPermission(this.props.userPermissions, permissions.bikes.edit)
                      ? <Tooltip title="Edytuj">
                        <IconButton onClick={() => this.handleEdit(bike.id)}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      : null}
                    {hasPermission(this.props.userPermissions, permissions.bikes.decommision)
                      ? <Tooltip title="Wycofaj z eksploatacji">
                        <IconButton onClick={event => this.handleDecommissionBike(event, bike.id)}                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                      : null}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <Dialog
          open={this.state.confirmDeleteOpen}
          ignoreBackdropClick
          ignoreEscapeKeyUp
          maxWidth="xs"
        >
          <DialogTitle>Wycofanie roweru z eksploatacji</DialogTitle>
          <DialogContent>
            Czy na pewno chcesz wycofać z eksploatacji wybrany rower?
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCancel}>Anuluj</Button>
            <Button onClick={this.handleOk} color="primary" variant="contained">
              OK
            </Button>
          </DialogActions>
        </Dialog>
        {this.renderPutBikeOutOfServiceDialog()}
        {this.renderAddBikeNoteDialog()}
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

function mapStateToProps(state) {
  return {
    userPermissions: state.auth.permissions,
  };
}

BikesManagementList.propTypes = {
  bikes: PropTypes.array.isRequired,
  userPermissions: PropTypes.array.isRequired,
  selected: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  filter: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  BikesManagementList
);
