import React from 'react';
import {Route, Switch, useHistory} from 'react-router-dom';
import HomePage from './components/HomePage';
import SignInPage from './components/auth/signIn/SignInPage';
import AccountActivationPage from './components/auth/accountActivation/AccountActivationPage';
import PasswordRecoveryPage from './components/auth/passwordRecovery/PasswordRecoveryPage';
import PasswordResetPage from './components/auth/passwordReset/PasswordResetPage';
import PricingDetailsPage from './components/pricing/PricingDetailsPage';
import PricingPublicPage from './components/pricingPublic/PricingPublicPage';
import PlansPage from './components/plans/PlansPage';
import CreatePlanPage from './components/plans/CreatePlanPage';
import PlanDetailsPage from './components/plans/PlanDetailsPage';
import BikesManagementPage from './components/bikesManagement/BikesManagementPage';
import BikeDetailsPage from './components/bikesManagement/BikeDetailsPage';
import ZonesPage from './components/zones/ZonesPage';
import ZoneTypesListPage from './components/zoneTypes/ZoneTypesListPage';
import ZoneTypeEditPage from './components/zoneTypes/ZoneTypeEditPage';
import BikeTypesListPage from './components/bikeTypes/BikeTypesListPage';
import BikeTypeEditPage from './components/bikeTypes/BikeTypeEditPage';
import OperatorsListPage from './components/operators/OperatorsListPage';
import OperatorEditPage from './components/operators/OperatorEditPage';
import RidesReportsPage from './components/reports/rides/RidesReportsPage';
import RideDetailsReportPage from './components/reports/rideDetails/RideDetailsReportPage';
import TermsAndConditionsPage from './components/termsAndConditions/TermsAndConditionsPage';
import TermsAndConditionsDetailsPage from './components/termsAndConditions/TermsAndConditionsDetailsPage';
import TermsAndConditionsEditPage from './components/termsAndConditions/TermsAndConditionsEditPage';
import TermsAndConditionsEditClausePage from './components/termsAndConditions/TermsAndConditionsEditClausePage';
import ClientsReportPage from './components/reports/clients/ClientsReportPage';
import ClientsDenyListReport from './components/reports/clients/denyList/ClientsDenyListReportPage';
import ClientHistoryReportPage from './components/reports/clientHistory/ClientHistoryReportPage';
import BikeDefectsPage from './components/bikeDefects/list/BikeDefectsPage';
import BikeDefectDetailsPage from './components/bikeDefects/details/BikeDefectDetailsPage';
import RelocationReportPage from './components/reports/relocation/RelocationReportPage';
import CardsPage from './components/cards/CardsPage';
import CardDetailsPage from './components/cards/CardDetailsPage';
import BikeDefectsConfigurationPage from './components/bikeDefects/configuration/BikeDefectsConfigurationPage';
import ReportsListPage from './components/reports/list/ReportsListPage';
import routePaths from './routePaths';
import { requireAuthorization } from './components/auth/requireAuthorization';
import {permissions} from './utils/authorization';
import RelocationConfigurationPage from './components/reports/relocation/configuration/RelocationConfigurationPage';
import PricingsPage from './components/pricing/PricingsPage';
import AutoLogoutSettingsPage from './components/operators/settings/AutoLogoutSettingsPage';
import MaxRentalsPerUserSettingsPage from './components/operators/settings/MaxRentalsPerUserSettingsPage';
import CampaignsPage from './components/campaigns/list/CampaignsPage';
import CampaignDetailsPage from './components/campaigns/details/CampaignDetailsPage';
import ClientsActivityThresholdSettingsPage from './components/operators/settings/ClientsActivityThresholdSettingsPage';
import ServiceBikeList from './components/service/bikeList/ServiceBikeList';
import ServiceMap from './components/service/map/ServiceMap';
import ServiceSettingsPage from './components/service/settings/ServiceSettingsPage';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import PasswordRequireChangePage from "./components/auth/passwordRequireChange/PasswordRequireChangePage";
import CustomReportsListPage from "./components/reports/list/CustomReportsListPage";
import CreateCustomReportPage from "./components/reports/create/CreateCustomReportPage";
import PreviewCustomReportPage from "./components/reports/preview/PreviewCustomReportPage";
import RidesDetailsReportsPage from "./components/reports/ridesDetailsReport/RidesReportsPage";
import AccountPage from "./components/account/AccountPage";
import TOPT2FAPage from "./components/account/TOPT2FA/TOPT2FAPage";
import TwoFAPage from "./components/auth/twoFA/TwoFAPage";
import PricingCardPage from "./components/pricingCard/PricingCardPage";
import GlobalClientsReportPage from "./components/reports/globalClients/GlobalClientsReportPage";
import ClientDetailsPage from "./components/reports/globalClientHistory/ClientDetailsPage";
import NotStartRidesReportsPage from "./components/reports/notStartRidesDetailsReport/NotStartRidesReportsPage";

const GetRoutes = ({auth}) => {
  const history = useHistory();
  if (auth.signedIn && auth.currentUserData && auth.currentUserData.requireChangePassword){
    history.push(routePaths.passwordRequireChange)
    return (
    <Switch>
      <Route path={routePaths.passwordRequireChange} component={PasswordRequireChangePage} />
      <Route exact component={requireAuthorization(PasswordRequireChangePage)} />
    </Switch>)
  }
  return (
    <Switch>

      <Route path={routePaths.twoFactorAuth} component={TwoFAPage} />
      <Route path={routePaths.signIn} component={SignInPage} />
      <Route path={routePaths.accountActivation} component={AccountActivationPage} />
      <Route path={routePaths.passwordRecovery} component={PasswordRecoveryPage} />
      <Route path={routePaths.passwordRequireChange} component={PasswordRequireChangePage} />
      <Route path={routePaths.passwordReset} component={PasswordResetPage} />

      <Route path={routePaths.account.me} component={requireAuthorization(AccountPage)} />
      <Route path={routePaths.account.twoFA} component={requireAuthorization(TOPT2FAPage)} />

      <Route exact path={routePaths.pricings} component={requireAuthorization(PricingsPage, permissions.pricingConfiguration.access)} />
      <Route exact path={routePaths.createPricing} component={requireAuthorization(PricingDetailsPage, permissions.pricingConfiguration.modify)} />
      <Route exact path={`${routePaths.pricingDetails}:pricingConfigurationId`} component={requireAuthorization(PricingDetailsPage, permissions.pricingConfiguration.access)} />
      <Route exact path={routePaths.pricingPublic} component={requireAuthorization(PricingPublicPage, permissions.pricingConfiguration.access)} />

      <Route path={routePaths.createBike} component={requireAuthorization(BikeDetailsPage, permissions.bikes.edit)} />
      <Route path={`${routePaths.bikes}/:bikeId`} component={BikeDetailsPage} />
      <Route path={routePaths.bikes} component={requireAuthorization(BikesManagementPage, permissions.bikes.list)} />

      <Route path={routePaths.createBikeType} component={requireAuthorization(BikeTypeEditPage, permissions.bikes.types)} />
      <Route path={`${routePaths.bikeTypes}/:bikeTypeId`} component={requireAuthorization(BikeTypeEditPage, permissions.bikes.types)} />
      <Route path={routePaths.bikeTypes} component={requireAuthorization(BikeTypesListPage, permissions.bikes.types)} />

      {/*<Route exact path={routePaths.payout}
              component={requireAuthorization(VendorBalancePage, permissions.payoutFunds)}/>*/}
      <Route exact path={routePaths.zones} component={requireAuthorization(ZonesPage, permissions.zones)} />

      <Route exact path={routePaths.createZoneType} component={requireAuthorization(ZoneTypeEditPage, permissions.zones)} />
      <Route path={`${routePaths.zoneTypes}/:zoneTypeId`} component={requireAuthorization(ZoneTypeEditPage, permissions.zones)} />
      <Route path={routePaths.zoneTypes} component={requireAuthorization(ZoneTypesListPage, permissions.zones)} />

      <Route exact path={routePaths.createOperator} component={requireAuthorization(OperatorEditPage, permissions.operatorAccounts)} />
      <Route exact path={routePaths.autoLogoutSettings} component={requireAuthorization(AutoLogoutSettingsPage, permissions.autoLogoutSettings)} />
      <Route exact path={routePaths.activityThesholdSettings} component={requireAuthorization(ClientsActivityThresholdSettingsPage, permissions.clientsActivityThresholdSettings)} />
      <Route exact path={routePaths.maximumRentalsPerUser} component={requireAuthorization(MaxRentalsPerUserSettingsPage, permissions.adjustMaximumRentalsPerUser)} />
      <Route exact path={routePaths.operators} component={requireAuthorization(OperatorsListPage, permissions.operatorAccounts)} />
      <Route path={`${routePaths.operators}/:operatorId`} component={requireAuthorization(OperatorEditPage, permissions.operatorAccounts)} />

      <Route exact path={routePaths.ridesReports} component={requireAuthorization(RidesReportsPage, permissions.rides)} />
      <Route path={`${routePaths.ridesReports}/:id`} component={requireAuthorization(RideDetailsReportPage, permissions.rides)} />

      <Route exact path={routePaths.ridesDetailsReportsPage} component={requireAuthorization(RidesDetailsReportsPage, permissions.rides)} />
      <Route exact path={routePaths.notStartedRidesDetailsReportsPage} component={requireAuthorization(NotStartRidesReportsPage, permissions.notStaredRides)} />

      <Route exact path={routePaths.termsAndConditions} component={requireAuthorization(TermsAndConditionsPage, permissions.termsAndConditions.access)} />
      <Route path={routePaths.termsAndConditionsEdit} component={requireAuthorization(TermsAndConditionsEditPage, permissions.termsAndConditions.modify)} />
      <Route path={routePaths.termsAndConditionsEditClause} component={requireAuthorization(TermsAndConditionsEditClausePage, permissions.termsAndConditions.modify)} />
      <Route path={`${routePaths.termsAndConditionsDetails}:version`} component={requireAuthorization(TermsAndConditionsDetailsPage, permissions.termsAndConditions.access)} />



      <Route exact path={routePaths.globalClientsReport} component={requireAuthorization(GlobalClientsReportPage, permissions.clients)} />
      <Route exact path={routePaths.clientsReport} component={requireAuthorization(ClientsReportPage, permissions.clients)} />
      <Route exact path={routePaths.clientsDenyListReport} component={requireAuthorization(ClientsDenyListReport, permissions.clients)} />
      <Route path={`${routePaths.clientsReport}/:id/history`} component={requireAuthorization(ClientHistoryReportPage, permissions.clients)} />
      <Route path={`${routePaths.globalClientsReport}/:id`} component={requireAuthorization(ClientDetailsPage, permissions.clients)} />

      <Route exact path={routePaths.bikeDefectsConfiguration} component={requireAuthorization(BikeDefectsConfigurationPage, permissions.bikeDefectsConfiguration.access)} />
      <Route exact path={routePaths.bikeDefects} component={requireAuthorization(BikeDefectsPage, permissions.bikeDefects)} />
      <Route path={`${routePaths.bikeDefectDetails}:id`} component={requireAuthorization(BikeDefectDetailsPage, permissions.bikeDefects)} />

      <Route path={routePaths.service.bikeList} component={requireAuthorization(ServiceBikeList, permissions.bikes.list)} />
      <Route path={routePaths.service.map} component={requireAuthorization(ServiceMap, permissions.bikes.list)} />
      <Route path={routePaths.service.settings} component={requireAuthorization(ServiceSettingsPage, permissions.adjustServiceSettings)} />

      <Route exact path={routePaths.campaigns} component={requireAuthorization(CampaignsPage, permissions.campaigns)} />
      <Route path={`${routePaths.campaignDetails}:id`} component={requireAuthorization(CampaignDetailsPage, permissions.campaigns)} />

      <Route exact path={routePaths.plans} component={requireAuthorization(PlansPage, permissions.pricingConfiguration.access)} />
      <Route path={routePaths.createPlan} component={requireAuthorization(CreatePlanPage, permissions.pricingConfiguration.modify)} />
      <Route path={`${routePaths.planDetails}:id`} component={requireAuthorization(PlanDetailsPage, permissions.pricingConfiguration.access)} />

      <Route exact path={routePaths.relocationReport} component={requireAuthorization(RelocationReportPage, permissions.relocation)} />
      <Route exact path={routePaths.relocationConfiguration} component={requireAuthorization(RelocationConfigurationPage, permissions.relocation)} />
      <Route exact path={routePaths.reports} component={requireAuthorization(ReportsListPage, permissions.reports.access)} />
      <Route exact path={routePaths.reportsCustom} component={requireAuthorization(CustomReportsListPage, permissions.reports.access)} />
      <Route exact path={`${routePaths.reportCustomDetails}/:id`} component={requireAuthorization(PreviewCustomReportPage, permissions.reports.access)} />
      <Route exact path={routePaths.reportsCustomCreate} component={requireAuthorization(CreateCustomReportPage, permissions.reports.access)} />


      <Route exact path={routePaths.cards} component={requireAuthorization(CardsPage, permissions.manageCards)} />
      <Route path={routePaths.cardDetailsPricing} component={requireAuthorization(PricingCardPage, permissions.manageCards)} />
      <Route path={routePaths.cardDetails} component={requireAuthorization(CardDetailsPage, permissions.manageCards)} />


      <Route exact component={requireAuthorization(HomePage)} />
    </Switch>
  );
};

GetRoutes.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(GetRoutes);
