import React, { useEffect } from 'react';
import { string } from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../actions/bikesManagementActions';
import { CircularProgress, makeStyles, Typography } from '@material-ui/core';
import moment from 'moment';
import { BikeAlertTypes } from '../../models/bikesManagement/BikeAlertTypes';

const useStyles = makeStyles({
  table: {
    minWidth: 500,
  },
});

const BikeAlertsView = ({ bikeId }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  useEffect(() => { dispatch(actions.loadBikeAlerts(bikeId)) }, [bikeId]);
  const loading = useSelector(s => s.bikeAlerts.fetching);
  const alerts = useSelector(s => s.bikeAlerts.data);

  if (loading) return (<div style={{ width: '100%', textAlign: 'center' }}>
    <CircularProgress size={50} />
  </div>);

  return (
    <>
      <Typography variant="h5" gutterBottom>Aktywne alarmy</Typography>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Rodzaj alarmu</TableCell>
            <TableCell>Wystąpienie warunku</TableCell>
            <TableCell>Aktywacja alarmu</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {alerts.map((row, i) => (
            <TableRow key={i}>
              <TableCell>{BikeAlertTypes[row.bikeAlarmType].name}</TableCell>
              <TableCell>{moment(row.conditionStarted).local().format("DD.MM.YYYY HH:mm:ss")}</TableCell>
              <TableCell>{moment(row.alarmStarted).local().format("DD.MM.YYYY HH:mm:ss")}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

BikeAlertsView.propTypes = {
  bikeId: string.isRequired,
}

export default BikeAlertsView;
