import React from 'react';
import TextFilter from '../../common/reportTable/filters/TextFilter';
import DateTimeFilter from '../../common/reportTable/filters/DateTimeFilter';
import SelectFilter from '../../common/reportTable/filters/SelectFilter';
import moment from 'moment';
import AccessTimeIcon from '@material-ui/icons/FindInPage';
import CheckedIcon from '@material-ui/icons/Done';
import IconButton from '@material-ui/core/IconButton';
import { Link } from 'react-router-dom';
import routePaths from '../../../routePaths';
import {
  YesNoOptions,
  ParseBikeTypesToSelect,
  ParseZonesToSelect
} from '../../../reducers/reports/rides/models';

/* eslint react/display-name: 0 */
/* eslint react/prop-types: 0 */
/* eslint react/no-multi-comp: 0 */

export const getColumns = (hasClientsDataPermission, bikeTypes, zones) => {
  let columns = [
    {
      Header: '',
      id: 'show_details',
      Cell: row => (
        <Link to={`${routePaths.ridesReports}/${row.original.rideId}`}>
          <IconButton style={{ height: 24, width: 24, margin: "-3px 3px" }}>
            <AccessTimeIcon />
          </IconButton>
        </Link>),
      width: 50,
      sortable: false,
      Filter: () => null,
    },
    {
      Header: 'Rower',
      id: 'bikeNumber',
      accessor: 'bikeNumber',
      width: 100,
      Filter: ({ filter, onChange }) => <TextFilter multi onChange={onChange} filter={filter} label="filtruj..." />,
    },
    {
      Header: 'Typ Roweru',
      id: 'bikeTypeId',
      accessor:  (ride) => {
        const bikeType = bikeTypes.find(x => x.id === ride.bikeType);
        return bikeType ? bikeType.name : "Nie znaleziono typu"
      },
      width: 100,
      Filter: ({ filter, onChange }) => <SelectFilter onChange={onChange} filter={filter} items={ParseBikeTypesToSelect(bikeTypes)} label="filtruj..." />,
    },{
      Header: 'Strefa początkowa',
      id: 'startedZoneId',
      accessor:  (ride) => {
        const zone = zones.find(x => x.id === ride.startedZoneId);
        return  zone ? zone.name : "Poza strefą"
      },
      width: 100,
      Filter: ({ filter, onChange }) => <SelectFilter onChange={onChange} filter={filter} items={ParseZonesToSelect(zones)} label="filtruj..." />,
    }];

  columns = [...columns,
  {
    Header: 'Rozpoczęto',
    id: 'startedAt',
    accessor: d => d.startedAt ? moment(d.startedAt).local().format('DD.MM.YYYY HH:mm') : null,
    width: 150,
    filterId: 'dateFrom',
    Filter: ({ filter, onChange }) => <DateTimeFilter onChange={onChange} filter={filter} label="filtruj od..." />,
  },
  {
    Header: 'Zakończono',
    id: 'finishedAt',
    accessor: d => d.finishedAt ? moment(d.finishedAt).local().format('DD.MM.YYYY HH:mm') : null,
    width: 150,
    filterId: 'dateTo',
    Filter: ({ filter, onChange }) => <DateTimeFilter onChange={onChange} filter={filter} label="filtruj do..." />,
  },{
      Header: 'Strefa końcowa',
      id: 'finishedZoneId',
      accessor:  (ride) => {
        if (!ride.finishedAt) return null;
        const zone = zones.find(x => x.id === ride.finishedZoneId);
        return  zone ? zone.name :  "Poza strefą"
      },
      width: 100,
      Filter: ({ filter, onChange }) => <SelectFilter onChange={onChange} filter={filter} items={ParseZonesToSelect(zones)} label="filtruj..." />,
    },

    {
      Header: 'Testowy użytkownik',
      id: 'userIsTester',
      accessor: d => d.userIsTester ? <CheckedIcon style={{ height: 24, width: 24, margin: "-3px 3px" }} /> : null,
      width: 110,
      Filter: ({ filter, onChange }) => <SelectFilter onChange={onChange} filter={filter} items={YesNoOptions} label="filtruj..." />,
    },
  {
    Header: 'Przejazd SMS',
    id: 'requestedWithSms',
    accessor: d => d.requestedWithSms ? <CheckedIcon style={{ height: 24, width: 24, margin: "-3px 3px" }} /> : null,
    width: 110,
    Filter: ({ filter, onChange }) => <SelectFilter onChange={onChange} filter={filter} items={YesNoOptions} label="filtruj..." />,
  }];

  if (hasClientsDataPermission) {
    columns.push({
      Header: 'Rozpoczęty kartą',
      id: 'nfcCardId',
      accessor: d => d.nfcCardId,
      width: 110,
      Filter: ({ filter, onChange }) => <TextFilter onChange={onChange} filter={filter} label="filtruj..." />,
    });
  }

  columns.push({
    Header: 'Typ karty',
    id: 'cardProviderId',
    accessor: d => d.cardProviderId,
    width: 110,
    Filter: ({ filter, onChange }) => <TextFilter onChange={onChange} filter={filter} label="filtruj..." />,
  });

  return columns;
}
