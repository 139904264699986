import { ReportTableModel } from "../ReportTableModel";

export class CampaignsModel {
  constructor() {
    this.loading = false;
    this.pagedData = new ReportTableModel({ pageNumber: 1, pageSize: 100 }, [{ id: 'lastUpdated', value: 'desc' }], []);
  }
}

export class CampaignListModel {
  constructor({ id, name, lastUpdated, executed, executedAt }) {
    this.id = id;
    this.name = name;
    this.lastUpdated = lastUpdated;
    this.executed = executed;
    this.executedAt = executedAt;
  }
}

export class CampaignDetailsModel {
  constructor({ id, name, target, targetCardProviderId,
    lastUpdated, messageTitle, messageFormatted,
    messagePlain, deliveryMethods, executed,
    executedAt, smsAllowed, emailRecipients, pushRecipients, smsRecipients }) {
    this.id = id;
    this.name = name;
    this.target = target;
    this.targetCardProviderId = targetCardProviderId;
    this.messageTitle = messageTitle;
    this.messageFormatted = messageFormatted;
    this.messagePlain = messagePlain;
    this.lastUpdated = lastUpdated;
    this.deliveryMethods = deliveryMethods;
    this.executed = executed;
    this.executedAt = executedAt;
    this.smsAllowed = smsAllowed;
    this.emailRecipients = emailRecipients;
    this.pushRecipients = pushRecipients;
    this.smsRecipients = smsRecipients;
  }
}

export const CampaignTarget = {
  allClients: { value: 'allClients', name: 'Wszyscy klienci' },
  activeClients: { value: 'activeClients', name: 'Aktywni klienci' },
  inactiveClients: { value: 'inactiveClients', name: 'Nieaktywni klienci' },
  cardHolders: { value: 'cardHolders', name: 'Klienci z aktywną kartą' },
  negativeBalance: { value: 'negativeBalance', name: 'Klienci z ujemnym saldem' },
}

export const campaignValidator = values => {
  const errors = {};
  if (!values.name) errors.name = "Pole jest wymagane";
  if (!Object.keys(CampaignTarget).some(x => x === values.target)) errors.target = "Należy wybrać grupę docelową";
  if (!values.deliveryMethods.email && !values.deliveryMethods.push && !values.deliveryMethods.sms) {
    errors.deliveryMethods = { _error: "Należy wybrać conajmniej jeden sposób doręczenia" };
  }
  if (values.messageTitle == null || values.messageTitle.length === 0) {
    errors.messageTitle = 'Tytuł jest wymagany';
  }
  if ((values.deliveryMethods.sms || values.deliveryMethods.push) && (values.messagePlain == null || values.messagePlain.length === 0)) {
    errors.messagePlain = 'Treść wiadomości jest wymagana';
  }
  if (values.deliveryMethods.sms && values.messageTitle && values.messagePlain) {
    const smsContent = `${values.messageTitle} ${values.messagePlain}`;
    if (['ą', 'ć', 'ę', 'ł', 'ń', 'ó', 'ś', 'ź', 'ż'].some(x => smsContent.toLowerCase().includes(x)) && smsContent.length > 70) {
      errors.messagePlain = `Wiadomość SMS "${smsContent}" zawiera polskie znaki, w związku z czym jej dopuszczalna długość to 70 znaków (tytuł oraz treść). Wiadomość nie może zostać wysłana, ponieważ składa się z większej liczby znaków.`;
    }
    if (smsContent.length > 160) {
      errors.messagePlain = `Wiadomość SMS "${smsContent}" składa się z ponad 160 znaków. Wiadomość nie może zostać wysłana.`;
    }
  }

  return errors;
};
