import initialState from './initialState';
import { types } from '../actions/twoFAActions';

export default function twoFAReducer(
  state = initialState.twoFA,
  action) {
  switch (action.type) {
    case types.GET_TOTP_SECRET_CODE_SUBMIT:
      return {...state, topt: {secretKey: null, code: null, otpUrl: null, account: null, success: false, loading: true}};
    case types.GET_TOTP_SECRET_CODE_SUCCESS:
      return {...state, topt: { secretKey: action.data.key, otpUrl: action.data.otpUrl,  account:  action.data.account, success: true, loading: false }};
    case types.GET_TOTP_SECRET_CODE_FAILURE:
      return {...state, topt: { secretKey: null, code: null, otpUrl: null, account: null, success: false, loading: false }};
    default:
      return state;
  }
}
