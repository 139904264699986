import React from 'react';
import PropTypes from 'prop-types';
import SadIcon from '@material-ui/icons/SentimentDissatisfied';
import './EmptyStateStyles.scss';

const EmptyState = ({ message, noIcon, icon }) => {
  return (
    <div className="empty-state">
      {noIcon ? null : icon} {message}
    </div>
  );
};

EmptyState.defaultProps = {
  icon: <SadIcon />,
};

EmptyState.propTypes = {
  message: PropTypes.string.isRequired,
  noIcon: PropTypes.bool,
  icon: PropTypes.object,
};

export default EmptyState;
