import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from '../../actions/plansActions';
import PlanDetailsForm from './PlanDetailsForm';
import { PlanModel } from '../../models/plans/PlanModel';

class CreatePlanPage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { actions } = this.props;

    return (
      <div className="page">
        <div style={{ margin: 40 }}>
          <PlanDetailsForm onSubmit={data => actions.create(data)} initialValues={new PlanModel()} />
        </div>
      </div>
    );
  }
}

CreatePlanPage.propTypes = {
  actions: PropTypes.object.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

function mapStateToProps() {
  return {
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreatePlanPage);
