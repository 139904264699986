import vendorBalanceApi from '../api/vendorBalanceApi';
import { actions as notificationActions } from './notificationActions';
import * as logger from '../utils/logger';

export const types = {
  LOAD_VENDOR_BALANCE_SUBMIT: 'LOAD_VENDOR_BALANCE_SUBMIT',
  LOAD_VENDOR_BALANCE_SUCCESS: 'LOAD_VENDOR_BALANCE_SUCCESS',
  LOAD_VENDOR_BALANCE_FAILURE: 'LOAD_VENDOR_BALANCE_FAILURE',
  PAYOUT_SUBMIT: 'PAYOUT_SUBMIT',
  PAYOUT_SUCCESS: 'PAYOUT_SUCCESS',
  PAYOUT_FAILURE: 'PAYOUT_FAILURE',
};

export const actions = {
  load: () => dispatch => {
    dispatch({ type: types.LOAD_VENDOR_BALANCE_SUBMIT });

    return vendorBalanceApi
      .get()
      .then(response => {
        if (response.ok) {
          return dispatch({ type: types.LOAD_VENDOR_BALANCE_SUCCESS, vendorBalance: response.data });
        } else {
          dispatch({ type: types.LOAD_VENDOR_BALANCE_FAILURE });
          dispatch(notificationActions.showError('Nie udało się załadować informacji o środkach'));

          return logger.error(response.data);
        }
      });
  },
  payout: payoutData => dispatch => {
    const { amount, bankAccountNumber, receiverName } = payoutData;
    const payout = { amount, bankAccountNumber, receiverName };
    dispatch({ type: types.PAYOUT_SUBMIT, payout });

    return vendorBalanceApi
      .payout(payout)
      .then(response => {
        if (response.ok && response.data.success !== false) {
          dispatch({ type: types.PAYOUT_SUCCESS, payout: Object.assign(payout, { id: response.data.id }) });
          dispatch(notificationActions.showSuccess('Zlecono wypłatę środków'));
        } else {
          dispatch({ type: types.PAYOUT_FAILURE });
          dispatch(notificationActions.showError('Wypłata środków nie powiodła się'));
          return logger.error(response.data);
        }
      });
  },
};
