import { pricingFormValidation } from '../configuration/PricingValidation';

export const planFormValidation = values => {
  const errors = {
  };

  const isGreaterThanOrEquals = (value, greaterThan) => value >= greaterThan;
  const isLessThan = (value, lessThan) => value < lessThan;
  const maxAmount = Number.MAX_SAFE_INTEGER;
  
  if (!values.name) errors.name = 'Pole jest wymagane';
  if (!values.price) errors.price = 'Pole jest wymagane';

  if (values.price && !isGreaterThanOrEquals(values.price, 0)) errors.price = 'Wartość musi być większa lub równa 0';
  if (values.price && !isLessThan(values.price, maxAmount)) errors.price = `Wartość musi być mniejsza od ${maxAmount}`;
  if (!values.validity) errors.validity = 'Pole jest wymagane';

  errors.pricingConfiguration = pricingFormValidation(values.pricingConfiguration || {});
  
  return errors;
};
