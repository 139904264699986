import React, {useEffect} from 'react';
import Paper from "@material-ui/core/Paper";
import TOPT2FAView from "./TOPT2FAView";
import {useDispatch, useSelector} from "react-redux";
import {actions} from "../../../actions/twoFAActions";
import DisableTOPT2FAView from "./DisableTOPT2FAView";

const TOPT2FAPage = () => {
  const currentUserDataState = useSelector(x => x.auth.currentUserData);
  const twoFAState = useSelector(state => state.twoFA);
  const dispatch = useDispatch();

  const onSubmitCode = async (values) => {
    dispatch(actions.enableTOTP2FA(values.TOPTCode, values.password))
  }

  const onSubmitDisable = async (values) => {
    dispatch(actions.disableTOTP2FA(values.password))
  }

  useEffect(() => {
    if (!twoFAState.topt.isLoading && currentUserDataState.isTOPT2FAEnable === false)
    {
      dispatch(actions.getTOTPSecretCode())
    }
  }, [])

  return (
    <div className="page">
      <div style={{ margin: 16, display: 'flex', justifyContent: 'center' }}>
        <Paper style={{ maxWidth: 1200 }}>
          <TOPT2FAView
            onSubmitCode={onSubmitCode}
            secretKey={twoFAState.topt.secretKey}
            account={twoFAState.topt.account}
            otpUrl={twoFAState.topt.otpUrl}
            success={twoFAState.topt.success}
            loading={twoFAState.topt.loading}
            is2FAEnable={currentUserDataState.isTOPT2FAEnable}
          />
          {currentUserDataState.isTOPT2FAEnable && <DisableTOPT2FAView
            onSubmitDisable={onSubmitDisable}
          />}
        </Paper>
      </div>
    </div>
  );
};

export default TOPT2FAPage;
