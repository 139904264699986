import apiBase from './apiBase';

class RentalsApi {

  static restartRide(rideId) {
    return apiBase.create().post(`/operations/rentals/${rideId}/restart`, {})
  }
  static resolveFareIssue(rentalId, data) {
    return apiBase.create().post(`/operations/rentals/${rentalId}/resolveFareIssue`, data);
  }

  static finishRide(rentalId, data) {
    return apiBase.create().post(`/operations/rentals/${rentalId}/finish`, data);
  }

  static adjustRideFare(rideId, data) {
    return apiBase.create().post(`/operations/rentals/${rideId}/adjustFare`, data);
  }
}

export default RentalsApi;
