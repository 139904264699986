import React from 'react';
import PropTypes from 'prop-types';
import SelectFilter from '../../common/reportTable/filters/SelectFilter';
import { TransactionType } from '../../../reducers/reports/clientWallet/models';

class ClientWalletFiltersPanel extends React.Component {

  constructor(props) {
    super(props);
    this.handleDelete = this.handleDelete.bind(this);
  }

  getFilterValue(id) {
    return this.props.filteredBy.find(f => f.id === id);
  }

  handleDelete(deleteFunction) {
    this.props.onFilteredChange(deleteFunction(this.props.filteredBy));
  }

  render() {
    return (
      <div className="filters-panel flex-row flex-wrap" >
        <SelectFilter filter={this.getFilterValue("type")} onDelete={this.handleDelete} items={TransactionType} readonly label="Typ operacji" />
      </div>
    );
  }
}

ClientWalletFiltersPanel.propTypes = {
  onFilteredChange: PropTypes.func,
  filteredBy: PropTypes.array
};

export default ClientWalletFiltersPanel;
