import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import _ from 'lodash';
import './styles.scss';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';

/* eslint react/no-multi-comp: 0 */

const multiFilterConverter = {
  convertToString: (filter) => filter && filter.value ? filter.value.join(", ") : '',
  convertToFilter: (text) => {
    const values = text ? text.split(",") : [];
    if (!values.length) return null;
    const isLast = (i) => i === values.length - 1;
    return [...values.map((m, i) => isLast(i) ? _.trimStart(m) : _.trim(m))].filter(v => v);
  },
  createDeleteFunction: (id, valueToDelete) => (filters) => {
    const deleteValue = (filter, valueToDelete) => filter.value.length > 1 ? ({ ...filter, value: filter.value.filter(v => v !== valueToDelete) }) : null;
    return filters
      .map(f => f.id !== id ? f : deleteValue(f, valueToDelete))
      .filter(f => f !== null);
  },
  convertToReadonly: (filter) => [...filter.value],
};

const singleFilterConverter = {
  convertToString: (filter) => filter && filter.value || '',
  convertToFilter: (text) => _.trim(text, [',', ' ']),
  createDeleteFunction: (id) => (filters) => filters.filter(f => f.id !== id),
  convertToReadonly: (filter) => [filter.value],
};

const getConverter = (multi) => (multi ? multiFilterConverter : singleFilterConverter);

class ActiveMode extends React.Component {
  constructor(props) {
    super(props);
    this.converter = getConverter(props.multi);
    this.state = {
      rawValue: this.converter.convertToString(props.filter),
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ rawValue: this.converter.convertToString(nextProps.filter) });
  }

  render() {
    const { onChange, label, filter, numeric } = this.props;
    const rawValue = this.state.rawValue;
    return (
      <TextField
        placeholder={label}
        type={numeric ? "number" : "text"}
        InputProps={{
          disableUnderline: true,
          startAdornment: <InputAdornment position="start"><SearchIcon style={{ width: 16, opacity: 0.2 }} /></InputAdornment>,
        }}
        margin="none"
        fullWidth
        onChange={e => this.setState({ rawValue: e.target.value })}
        value={rawValue}
        onBlur={() => rawValue !== this.converter.convertToString(filter) && onChange(this.converter.convertToFilter(rawValue))}
        onKeyPress={(ev) => ev.key === 'Enter' && onChange(this.converter.convertToFilter(rawValue))}
      />);
  }
}

const readonlyMode = (onDelete, label, filter, multi) => (
  <div className="filter">
    <span className="label">{label}</span>
    {getConverter(multi).convertToReadonly(filter).map(v =>
      <Chip className="chip" label={v} key={v} onDelete={() => onDelete(getConverter(multi).createDeleteFunction(filter.id, v))} />
    )}
  </div>
);

const TextFilter = ({ onChange, onDelete, readonly, label, filter, multi, numeric }) => {
  return (
    readonly
      ? (filter ? readonlyMode(onDelete, label, filter, multi) : null)
      : <ActiveMode onChange={onChange} label={label} filter={filter} multi={multi} numeric={numeric} />
  );
};

TextFilter.propTypes = {
  onChange: PropTypes.func,
  readonly: PropTypes.bool,
  onDelete: PropTypes.func,
  multi: PropTypes.bool,
  label: PropTypes.string,
  filter: PropTypes.object,
  numeric: PropTypes.bool,
};

ActiveMode.propTypes = {
  onChange: PropTypes.func,
  readonly: PropTypes.bool,
  onDelete: PropTypes.func,
  multi: PropTypes.bool,
  label: PropTypes.string,
  filter: PropTypes.object,
  numeric: PropTypes.bool,
};

export default TextFilter;
