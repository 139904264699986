export const BikePart = {
  basket: {
    name: "Koszyk",
    value: "basket",
  },
  bell: {
    name: "Dzwonek",
    value: "bell",
  },
  brakes: {
    name: "Hamulec",
    value: "brakes",
  },
  chain: {
    name: "Łańcuch",
    value: "chain",
  },
  drive: {
    name: "Napęd",
    value: "drive",
  },
  handlebar: {
    name: "Kierownica",
    value: "handlebar",
  },
  lights: {
    name: "Oświetlenie",
    value: "lights",
  },
  lock: {
    name: "Blokada",
    value: "lock",
  },
  other: {
    name: "Inne",
    value: "other",
  },
  qr: {
    name: "Kod QR",
    value: "qr",
  },
  seat: {
    name: "Siodełko",
    value: "seat",
  },
  wheel: {
    name: "Koło",
    value: "wheel",
  },
  phoneHolder: {
    name: "Uchwyt na telefon",
    value: "phoneHolder",
  },
  unusable: {
    name: "Niezdatny do jazdy",
    value: "unusable",
  },
  vandalism: {
    name: "Wandalizm",
    value: "vandalism",
  },
  kickstand: {
    name: "Nóżka",
    value: "kickstand",
  },
  childSeat: {
    name: "Fotelik",
    value: "childSeat",
  },
  bikeBattery: {
    name: "Bateria (e-bike)",
    value: "bikeBattery",
  },
  bikeScreen: {
    name: "Wyświetlacz (e-bike)",
    value: "bikeScreen",
  },
  cargoBox: {
    name: "Skrzynia cargo",
    value: "cargoBox",
  },
  fender: {
    name: "Błotnik",
    value: "fender",
  },
  solar: {
    name: "Panel Solarny",
    value: "solar",
  },
};
