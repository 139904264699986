import { push } from 'connected-react-router';
import plansApi from '../api/plansApi';
import { actions as notificationActions } from './notificationActions';
import routePaths from '../routePaths';
import * as logger from '../utils/logger';

export const types = {
  GET_PLANS: 'GET_PLANS',
  GET_PLANS_SUCCESS: 'GET_PLANS_SUCCESS',
  CREATE_PLAN_SUCCESS: 'CREATE_PLAN_SUCCESS',
  CREATE_PLAN_FAILURE: 'CREATE_PLAN_FAILURE',
  UPDATE_PLAN_SUCCESS: 'UPDATE_PLAN_SUCCESS',
  UPDATE_PLAN_FAILURE: 'UPDATE_PLAN_FAILURE',
  DISABLE_PLAN_SUCCESS: 'DISABLE_PLAN_SUCCESS',
  DISABLE_PLAN_FAILURE: 'DISABLE_PLAN_FAILURE',
  GET_DEDICATED_PLANS: 'GET_DEDICATED_PLANS',
  GET_DEDICATED_PLANS_SUCCESS: 'GET_DEDICATED_PLANS_SUCCESS',
  GET_USER_DEDICATED_PLANS_SUCCESS: 'GET_USER_DEDICATED_PLANS_SUCCESS',
  SET_DEDICATED_PLANS_FOR_USER: 'SET_DEDICATED_PLANS_FOR_USER',
  SET_DEDICATED_PLANS_FOR_USER_SUCCESS: 'SET_DEDICATED_PLANS_FOR_USER_SUCCESS',
  SET_DEDICATED_PLANS_FOR_USER_FAILURE: 'SET_DEDICATED_PLANS_FOR_USER_FAILURE',
};

export const actions = {
  load: () => dispatch => {
    dispatch({ type: types.GET_PLANS });
    return plansApi.getAll().then(response => {
      if (response.ok) {
        dispatch({ type: types.GET_PLANS_SUCCESS, plans: response.data });
      }
    });
  },
  getDedicatedPlansForUser: userId => dispatch => {
    dispatch({type: types.GET_DEDICATED_PLANS});
    return plansApi.getDedicatedForUser(userId).then(res => {
      if (res.ok) {
        dispatch({type: types.GET_USER_DEDICATED_PLANS_SUCCESS, plans: res.data});
      }
    });
  },
  getAllDedicatedPlans: () => dispatch => {
    dispatch({type: types.GET_PLANS });
    return plansApi.getDedicated().then(res => {
      if (res.ok) {
        dispatch({type: types.GET_PLANS_SUCCESS, plans: res.data});
      }
    });
  },
  setDedicatedPlans: (clientId, plans) => dispatch => {
    dispatch({ type: types.SET_DEDICATED_PLANS_FOR_USER });
    return plansApi.setDedicatedPlansConfig(clientId, plans).then(res => {
      if (res.ok) {
        dispatch({ type: types.SET_DEDICATED_PLANS_FOR_USER_SUCCESS });
        dispatch(notificationActions.showSuccess('Dostępne dla użytkownika plany dedykowane zostały zapisane'));
        return true;
      } else {
        dispatch({ type: types.SET_DEDICATED_PLANS_FOR_USER_FAILURE });
        dispatch(notificationActions.showError('Wystąpił błąd poczas zapisywania planów dedykowanych dla użytkownika'));
        return false;
      }
    });
  },
  create: data => dispatch => {
    return plansApi
      .create(data)
      .then(response => {
        if (response.ok && response.data.success !== false) {
          dispatch({ type: types.CREATE_PLAN_SUCCESS, data });
          dispatch(notificationActions.showSuccess('Dodano nowy plan'));
          return dispatch(push(routePaths.plans));
        } else {
          dispatch({type: types.CREATE_PLAN_FAILURE});
          dispatch(notificationActions.showError('Zapis nie powiódł się'));

          return logger.error(response.data);
        }
      });
  },
  update: (id, data) => dispatch => {
    return plansApi
      .update(id, data)
      .then(response => {
        if (response.ok && response.data.success !== false) {
          dispatch({ type: types.UPDATE_PLAN_SUCCESS, data });
          dispatch(notificationActions.showSuccess('Zaktualizowano plan'));
          return dispatch(push(routePaths.plans));
        } else {
          dispatch({type: types.UPDATE_PLAN_FAILURE});
          dispatch(notificationActions.showError('Zapis nie powiódł się'));

          return logger.error(response.data);
        }
      });
  },
  disable: id => dispatch => {
    return plansApi
      .disable(id)
      .then(response => {
        if (response.ok && response.data.success !== false) {
          dispatch({ type: types.DISABLE_PLAN_SUCCESS, id });
          dispatch(notificationActions.showSuccess('Plan został usunięty'));
          return dispatch(push(routePaths.plans));
        } else {
          dispatch({type: types.DISABLE_PLAN_FAILURE});
          dispatch(notificationActions.showError('Zapis nie powiódł się'));

          return logger.error(response.data);
        }
      });
  }
};
