import React from 'react';
import AccessTimeIcon from '@material-ui/icons/FindInPage';
import IconButton from '@material-ui/core/IconButton';
import TextFilter from '../../common/reportTable/filters/TextFilter';
import { Link } from 'react-router-dom';
import routePaths from '../../../routePaths';
import moment from 'moment';

/* eslint react/display-name: 0 */
/* eslint react/prop-types: 0 */
/* eslint react/no-multi-comp: 0 */
export const getColumns = () => {
  let columns = [
    {
      Header: '',
      id: 'show_details',
      Cell: row => (
        <Link to={`${routePaths.campaignDetails}${row.original.id}`}>
          <IconButton style={{ height: 24, width: 24, margin: "-3px 3px" }}      >
            <AccessTimeIcon />
          </IconButton>
        </Link >),
      width: 50,
      sortable: false,
      Filter: () => null,
    },
    {
      Header: 'Nazwa',
      id: 'name',
      accessor: d => d.name,
      width: 150,
      Filter: ({ filter, onChange }) => <TextFilter onChange={onChange} filter={filter} label="filtruj..." />,
    },
    {
      Header: 'Uruchomiona',
      id: 'executed',
      accessor: d => d.executed ? 'TAK' : 'NIE',
      width: 100,
      Filter: () => null,
    },
    {
      Header: 'Data uruchomienia',
      id: 'executedAt',
      accessor: d => d.executedAt ? moment(d.executedAt).local().format('DD.MM.YYYY HH:mm:ss') : '',
      width: 150,
      Filter: () => null,
    },
    {
      Header: 'Ostatnia aktualizacja',
      id: 'lastUpdated',
      accessor: d => moment(d.lastUpdated).local().format('DD.MM.YYYY HH:mm:ss'),
      width: 150,
      Filter: () => null,
    },
  ];

  return columns;
}
