import initialState from './initialState';
import Immutable from 'seamless-immutable';
import { types } from '../actions/bikeTypesActions';

export default function globalBikeTypesReducer(state = Immutable(initialState.globalBikeTypes), action) {
  switch (action.type) {
    case types.GET_ALL_GLOBAL_BIKETYPES_REQUEST_SUCCESS: {
      const { data } = action;
      return Immutable.merge(state, {
        data
      });
    }
    default:
      return state;
  }
}
