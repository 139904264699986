import React from 'react';
import AccountSectionDetailsView from "../AccountSectionDetailsView";
import AccountSectionView from "../AccountSectionView";
import useStyles from './TOPT2FAStyle'
import DisableTOPT2FACodeForm from "./DisableTOPT2FACodeForm";
import PropTypes from "prop-types";

const DisableTOPT2FAView = ({onSubmitDisable}) => {

  const classes = useStyles();

  return (
    <AccountSectionView>
      <AccountSectionDetailsView
        flex={1}
      >
        <h2>Wyłączenie weryfikacji dwuskładnikowej</h2>
        <div className={classes.text}>
          Wyłączania weryfikację dwuskładnikowej za pomocą urządzenia uwierzytelniającego
        </div>
      </AccountSectionDetailsView>
      <AccountSectionDetailsView
        flex={2}
      >
        <DisableTOPT2FACodeForm
          onSubmit={onSubmitDisable}
        />
      </AccountSectionDetailsView>
    </AccountSectionView>
  );

};

DisableTOPT2FAView.propTypes = {
  onSubmitDisable: PropTypes.func.isRequired,
}

export default DisableTOPT2FAView;
