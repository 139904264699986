import React from "react";
import PropTypes from "prop-types";
import FormTextField from "../../../form/redux-form/FormTextField";
import FormSelect from "../../../form/redux-form/FormSelect";
import numberFormatter from "../../../../utils/numberFormatter";
import { fareSanctionAdjustmentFormValidation } from "../../../../models/fareAdjustments/FareAdjustmentValidation";
import {
  CorrectionReasonForBikeDamageType,
  CorrectionReasonForViolationRulesType,
  SanctionType,
} from "../../../../models/configuration/PricingModel";
import FareSanctionAdjustmentInfo from "./FareAdjustmentInfo";
import { Field, Form } from "react-final-form";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

let FareSanctionAdjustmentForm = ({
  totalAmount,
  onSubmit,
  zones,
  onCancel,
  submitting,
}) => {
  const getCorrectionReasonType = (sanctionType) => {
    if (sanctionType === SanctionType.bikeDamage.value)
      return CorrectionReasonForBikeDamageType;
    if (sanctionType === SanctionType.violationRules.value)
      return CorrectionReasonForViolationRulesType;
    return [];
  };

  return (
    <Form
      decorators={[
        (formApi) => {
          let before = formApi.getState();
          const unsubscribe = formApi.subscribe(
            (state) => {
              if (before.values.sanctionType !== state.values.sanctionType) {
                formApi.change("correctionReasonType", null);
              }
              before = state;
            },
            { values: true },
          );
          return unsubscribe;
        },
      ]}
      validate={fareSanctionAdjustmentFormValidation}
      onSubmit={onSubmit}
      render={({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit}>
          <div>
            {values.amount > 0 && (
              <FareSanctionAdjustmentInfo
                message={`Opłata zostanie podwyższona o ${numberFormatter.format(values.amount)} zł`}
              />
            )}
            {-values.amount > totalAmount && (
              <FareSanctionAdjustmentInfo
                message={`Klient otrzyma bonus w wysokości ${numberFormatter.format(-(totalAmount + values.amount))} zł`}
              />
            )}
          </div>
          <div>
            <Field
              name="amount"
              type="number"
              component={FormTextField}
              label="Kwota"
              parse={(val) => (val ? Number.parseFloat(val) : "")}
              disabled={values.totalRefund}
              endAdornmentText="zł"
            />
          </div>
          <div>
            <Field
              name="sanctionType"
              component={FormSelect}
              values={SanctionType}
              label="Rodzaj kary/sankcji"
              fullWidth
            />
          </div>
          <div>
            <Field
              name="correctionReasonType"
              component={FormSelect}
              values={getCorrectionReasonType(values.sanctionType)}
              label="Powód korekty"
              readonly={
                values.sanctionType !== SanctionType.bikeDamage.value &&
                values.sanctionType !== SanctionType.violationRules.value
              }
              fullWidth
            />
          </div>
          <div>
            <Field
              name="zone"
              component={FormSelect}
              values={zones}
              label="Strefa parkowania "
              fullWidth
            />
          </div>
          <div>
            <Field
              name="comment"
              type="text"
              component={FormTextField}
              label="Komentarz (widoczny tylko dla operatorów)"
              fullWidth
            />
          </div>

          <DialogActions>
            <Button onClick={onCancel} disabled={submitting}>
              Anuluj
            </Button>
            <Button
              type="submit"
              variant="contained"
              disabled={submitting}
              color="primary"
            >
              Zapisz
            </Button>
          </DialogActions>
        </form>
      )}
    />
  );
};

FareSanctionAdjustmentForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  totalAmount: PropTypes.number.isRequired,
  change: PropTypes.func.isRequired,
  zones: PropTypes.object.isRequired,
};

/*
FareSanctionAdjustmentForm = reduxForm({
  form: "fareSanctionAdjustment",
  validate: fareSanctionAdjustmentFormValidation,
})(FareSanctionAdjustmentForm);

const selector = formValueSelector("fareSanctionAdjustment");
FareSanctionAdjustmentForm = connect((state) => ({
  totalRefund: selector(state, "totalRefund"),
  currentAmount: selector(state, "amount"),
}))(FareSanctionAdjustmentForm);*/

export default FareSanctionAdjustmentForm;
