import operatorsApi from '../api/operatorsApi';
import { actions as notificationActions } from './notificationActions';
import * as logger from '../utils/logger';

export const types = {
  LOAD_OPERATORS_SUBMIT: 'LOAD_OPERATORS_SUBMIT',
  LOAD_OPERATORS_SUCCESS: 'LOAD_OPERATORS_SUCCESS',
  LOAD_OPERATORS_FAILURE: 'LOAD_OPERATORS_FAILURE',
  REGISTER_OPERATOR_SUBMIT: 'REGISTER_OPERATOR_SUBMIT',
  REGISTER_OPERATOR_SUCCESS: 'REGISTER_OPERATOR_SUCCESS',
  REGISTER_OPERATOR_FAILURE: 'REGISTER_OPERATOR_FAILURE',
  UPDATE_OPERATOR_SUBMIT: 'UPDATE_OPERATOR_SUBMIT',
  UPDATE_OPERATOR_SUCCESS: 'UPDATE_OPERATOR_SUCCESS',
  UPDATE_OPERATOR_FAILURE: 'UPDATE_OPERATOR_FAILURE',
  LOAD_OPERATOR_SUBMIT: 'LOAD_OPERATOR_SUBMIT',
  LOAD_OPERATOR_SUCCESS: 'LOAD_OPERATOR_SUCCESS',
  LOAD_OPERATOR_FAILURE: 'LOAD_OPERATOR_FAILURE',
  DELETE_OPERATOR_SUBMIT: 'DELETE_OPERATOR_SUBMIT',
  DELETE_OPERATOR_SUCCESS: 'DELETE_OPERATOR_SUCCESS',
  DELETE_OPERATOR_FAILURE: 'DELETE_OPERATOR_FAILURE',
  START_OPERATOR_CREATION: 'START_OPERATOR_CREATION',
  GET_OPERATOR_AUDIT_LOG: 'GET_OPERATOR_AUDIT_LOG',
  GET_OPERATOR_AUDIT_LOG_SUCCESS: 'GET_OPERATOR_AUDIT_LOG_SUCCESS',
  GET_OPERATOR_AUDIT_LOG_FAILURE: 'GET_OPERATOR_AUDIT_LOG_FAILURE',
};

function storeBlobAs(blob, filename) {
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  setTimeout(() => URL.revokeObjectURL(url), 60000);
  if (typeof link.download === 'string') {
    document.body.appendChild(link);
    link.download = filename;
    link.href = url;
    link.click();
    document.body.removeChild(link);
  } else {
    location.replace(url);
  }
}

export const actions = {
  loadAll: () => dispatch => {
    dispatch({ type: types.LOAD_OPERATORS_SUBMIT });

    return operatorsApi
      .getAllOperators()
      .then(response => {
        if (response.ok) {
          return dispatch({ type: types.LOAD_OPERATORS_SUCCESS, operators: response.data });
        } else {
          dispatch({ type: types.LOAD_OPERATORS_FAILURE });
          dispatch(notificationActions.showError('Nie udało się załadować kont operatorów'));
          return logger.error(response.data);
        }
      });
  },

  load: (id) => dispatch => {
    dispatch({ type: types.LOAD_OPERATOR_SUBMIT, id });

    return operatorsApi
      .getOperator(id)
      .then(response => {
        if (response.ok) {
          return dispatch({ type: types.LOAD_OPERATOR_SUCCESS, operator: response.data });
        } else {
          dispatch({ type: types.LOAD_OPERATOR_FAILURE });
          dispatch(notificationActions.showError('Nie udało się załadować konta operatora'));
          return logger.error(response.data);
        }
      });
  },

  registerNew: (data) => dispatch => {
    const operator = { email: data.email, firstName: data.firstName, lastName: data.lastName, role: data.role };
    dispatch({ type: types.REGISTER_OPERATOR_SUBMIT, operator });
    return operatorsApi
      .registerOperator(operator)
      .then(response => {
        if (response.ok) {
          if (response.data.success === false) {
            dispatch({ type: types.REGISTER_OPERATOR_FAILURE });
            dispatch(notificationActions.showError(response.data.failure.message));
            return false;
          }
          dispatch({ type: types.REGISTER_OPERATOR_SUCCESS, id: response.data.id, operator });
          return true;
        } else {
          dispatch({ type: types.REGISTER_OPERATOR_FAILURE });
          dispatch(notificationActions.showError('Nie udało się zarejestrować operatora'));
          logger.error(response.data);
          return false;
        }
      });
  },

  update: (data) => dispatch => {
    const id = data.id;
    const operator = { email: data.email, firstName: data.firstName, lastName: data.lastName, role: data.role };
    dispatch({ type: types.UPDATE_OPERATOR_SUBMIT, id, operator });
    return operatorsApi
      .updateOperator(id, operator)
      .then(response => {
        if (response.ok) {
          if (response.data.success === false) {
            dispatch({ type: types.UPDATE_OPERATOR_FAILURE });
            dispatch(notificationActions.showError(response.data.failure.message));
            return false;
          }
          dispatch({ type: types.UPDATE_OPERATOR_SUCCESS, id, operator });
          return true;
        } else {
          dispatch({ type: types.UPDATE_OPERATOR_FAILURE });
          dispatch(notificationActions.showError('Nie udało się zaktualizować konta operatora'));
          logger.error(response.data);
          return false;
        }
      });
  },

  remove: (id) => dispatch => {
    dispatch({ type: types.DELETE_OPERATOR_SUBMIT, id });
    return operatorsApi
      .deleteOperator(id)
      .then(response => {
        if (response.ok) {
          if (response.data.success === false) {
            dispatch({ type: types.DELETE_OPERATOR_FAILURE });
            dispatch(notificationActions.showError(response.data.failure.message));
            return false;
          }
          dispatch({ type: types.DELETE_OPERATOR_SUCCESS, id });
          return true;
        } else {
          dispatch({ type: types.DELETE_OPERATOR_FAILURE });
          dispatch(notificationActions.showError('Nie udało się usunąć konta operatora'));
          logger.error(response.data);
          return false;
        }
      });
  },

  getOperatorAuditLog: (id) => dispatch => {
    dispatch({ type: types.GET_OPERATOR_AUDIT_LOG, id });

    return operatorsApi
      .getAuditLog(id)
      .then(response => {
        if (response.ok && response.data.success !== false) {
          dispatch({ type: types.GET_OPERATOR_AUDIT_LOG_SUCCESS, id });
          const disposition = response.headers['content-disposition'];
          const fileName = disposition ? disposition.match(/filename="(.+)"/)[1] : 'dziennik.xlsx';
          storeBlobAs(response.data, fileName);
          return true;
        } else {
          dispatch({ type: types.GET_OPERATOR_AUDIT_LOG_FAILURE, id });
          dispatch(notificationActions.showError('Wystąpił problem podczas generowania dziennika zdarzeń'));
          logger.error(response.data);
          return false;
        }
      });
  },

  startOperatorCreation: () => dispatch => {
    dispatch({ type: types.START_OPERATOR_CREATION });
  },
};
