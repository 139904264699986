import bikeTypesApi from '../api/bikeTypesApi';
import { actions as notificationActions } from './notificationActions';
import * as logger from '../utils/logger';

export const types = {
  DELETE_BIKETYPE_REQUEST: 'DELETE_BIKETYPE_REQUEST',
  DELETE_BIKETYPE_REQUEST_SUCCESS: 'DELETE_BIKETYPE_REQUEST_SUCCESS',
  DELETE_BIKETYPE_REQUEST_FAILURE: 'DELETE_BIKETYPE_REQUEST_FAILURE',
  CREATE_BIKETYPE_REQUEST: 'CREATE_BIKETYPE_REQUEST',
  CREATE_BIKETYPE_REQUEST_SUCCESS: 'CREATE_BIKETYPE_REQUEST_SUCCESS',
  CREATE_BIKETYPE_REQUEST_FAILURE: 'CREATE_BIKETYPE_REQUEST_FAILURE',
  UPDATE_BIKETYPE_REQUEST: 'UPDATE_BIKETYPE_REQUEST',
  UPDATE_BIKETYPE_REQUEST_SUCCESS: 'UPDATE_BIKETYPE_REQUEST_SUCCESS',
  UPDATE_BIKETYPE_REQUEST_FAILURE: 'UPDATE_BIKETYPE_REQUEST_FAILURE',
  GET_ALL_BIKETYPES_REQUEST: 'GET_ALL_BIKETYPES_REQUEST',
  GET_ALL_BIKETYPES_REQUEST_SUCCESS: 'GET_ALL_BIKETYPES_REQUEST_SUCCESS',
  GET_ALL_BIKETYPES_REQUEST_FAILURE: 'GET_ALL_BIKETYPES_REQUEST_FAILURE',
  GET_BIKETYPE_REQUEST: 'GET_BIKETYPE_REQUEST',
  GET_BIKETYPE_REQUEST_SUCCESS: 'GET_BIKETYPE_REQUEST_SUCCESS',
  GET_BIKETYPE_REQUEST_FAILURE: 'GET_BIKETYPE_REQUEST_FAILURE',
  START_BIKETYPE_CREATION: 'START_BIKETYPE_CREATION',
  GET_ALL_GLOBAL_BIKETYPES_REQUEST: 'GET_ALL_GLOBAL_BIKETYPES_REQUEST',
  GET_ALL_GLOBAL_BIKETYPES_REQUEST_SUCCESS: 'GET_ALL_GLOBAL_BIKETYPES_REQUEST_SUCCESS',
  GET_ALL_GLOBAL_BIKETYPES_REQUEST_FAILURE: 'GET_ALL_GLOBAL_BIKETYPES_REQUEST_FAILURE',
};

export const actions = {
  loadBikeTypes: () => dispatch => {
    dispatch({ type: types.GET_ALL_BIKETYPES_REQUEST });
    return bikeTypesApi.getAllBikeTypes().then(res => {
      if (res.ok) {
        dispatch({ type: types.GET_ALL_BIKETYPES_REQUEST_SUCCESS, bikeTypes: res.data });
      } else {
        dispatch({ type: types.GET_ALL_BIKETYPES_REQUEST_FAILURE });
        dispatch(notificationActions.showError('Nie udało się pobrać typów rowerów'));
        return logger.error(res.data);
      }
    });
  },

  loadGlobalBikeTypes: () => dispatch => {
    dispatch({ type: types.GET_ALL_GLOBAL_BIKETYPES_REQUEST });
    return bikeTypesApi.getAllGlobalBikeTypes().then(res => {
      if (res.ok) {
        dispatch({ type: types.GET_ALL_GLOBAL_BIKETYPES_REQUEST_SUCCESS, data: res.data });
      } else {
        dispatch({ type: types.GET_ALL_GLOBAL_BIKETYPES_REQUEST_FAILURE });
        dispatch(notificationActions.showError('Nie udało się pobrać globalnych typów rowerów'));
        return logger.error(res.data);
      }
    });
  },

  loadBikeType: (bikeTypeId) => dispatch => {
    dispatch({ type: types.GET_BIKETYPE_REQUEST });
    return bikeTypesApi.getBikeType(bikeTypeId).then(res => {
      if (res.ok) {
        dispatch({ type: types.GET_BIKETYPE_REQUEST_SUCCESS, bikeType: res.data });
      } else {
        dispatch({ type: types.GET_BIKETYPE_REQUEST_FAILURE });
        dispatch(notificationActions.showError('Nie udało się pobrać typu rowerów'));
        return logger.error(res.data);
      }
    });
  },

  startBikeTypeCreation: () => dispatch => dispatch({ type: types.START_BIKETYPE_CREATION }),

  createBikeType: (bikeType) => (dispatch) => {
    dispatch({ type: types.CREATE_BIKETYPE_REQUEST });
    return bikeTypesApi.createBikeType(bikeType).then(res => {
      if (res.ok) {
        dispatch({ type: types.CREATE_BIKETYPE_REQUEST_SUCCESS });
        return true;
      } else {
        dispatch({ type: types.CREATE_BIKETYPE_REQUEST_FAILURE });
        dispatch(notificationActions.showError('Nie udało się zapisać typu rowerów'));
        logger.error(res.data);
        return false;
      }
    });
  },

  updateBikeType: (bikeType) => (dispatch) => {
    dispatch({ type: types.UPDATE_BIKETYPE_REQUEST });
    return bikeTypesApi.updateBikeType(bikeType).then(res => {
      if (res.ok) {
        dispatch({ type: types.UPDATE_BIKETYPE_REQUEST_SUCCESS });
        return true;
      } else {
        dispatch({ type: types.UPDATE_BIKETYPE_REQUEST_FAILURE });
        dispatch(notificationActions.showError('Nie udało się zapisać typu rowerów'));
        logger.error(res.data);
        return false;
      }
    });
  },

  deleteBikeType: (bikeTypeId) => dispatch => {
    dispatch({ type: types.DELETE_BIKETYPE_REQUEST });
    return bikeTypesApi.deleteBikeType(bikeTypeId).then(res => {
      if (res.ok) {
        dispatch({ type: types.DELETE_BIKETYPE_REQUEST_SUCCESS, bikeTypeId });
        return true;
      } else {
        dispatch({ type: types.DELETE_BIKETYPE_REQUEST_FAILURE });
        dispatch(notificationActions.showError('Nie udało się usunąć typu rowerów'));
        logger.error(res.data);
        return false;
      }
    });
  },
};
