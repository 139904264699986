import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from '../../actions/pricingActions';
import { actions as bikeTypesActions } from '../../actions/bikeTypesActions';
import { actions as cardsActions } from '../../actions/cardsActions';
import PricingDetailsForm from './PricingDetailsForm';
import { permissions, hasPermission } from '../../utils/authorization';

class PricingDetailsPage extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.actions.startPricingCreation();
    if (this.props.match.params.pricingConfigurationId) {
      this.props.actions.load(this.props.match.params.pricingConfigurationId);
    }
    this.props.bikeTypeActions.loadBikeTypes();
    this.props.cardsActions.load();
  }

  render() {
    const { actions, userPermissions } = this.props;
    const readonly = hasPermission(userPermissions, permissions.pricingConfiguration.access)
      && !hasPermission(userPermissions, permissions.pricingConfiguration.modify);
    return (
      <div className="page">
        <div style={{ margin: 40 }}>
          <PricingDetailsForm
            onSubmit={data => actions.update(data)}
            readonly={readonly}
            isPlan={false}
            fieldPrefix=""
          />
        </div>
      </div>
    );
  }
}

PricingDetailsPage.propTypes = {
  actions: PropTypes.object.isRequired,
  bikeTypeActions: PropTypes.object.isRequired,
  cardsActions: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  userPermissions: PropTypes.array.isRequired,
  pricing: PropTypes.object.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    bikeTypeActions: bindActionCreators(bikeTypesActions, dispatch),
    cardsActions: bindActionCreators(cardsActions, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    userPermissions: state.auth.permissions,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PricingDetailsPage);
