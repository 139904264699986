import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from '../../actions/notificationActions';
import { NotificationTypes } from '../../models/NotificationModel';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import ErrorNotification from './ErrorNotification';
import SuccessNotification from './SuccessNotification';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';
import _ from 'lodash';

function renderMessage(notification, index) {
  if (notification.type === NotificationTypes.error) return <ErrorNotification key={index} message={notification.message} />;
  if (notification.type === NotificationTypes.success) return <SuccessNotification key={index} message={notification.message} />;
  return null;
}

const NotificationsHandler = ({ actions, notifications }) => (
  <React.Fragment>
    {notifications.some(n => n.type === NotificationTypes.error) && <Snackbar
      open={notifications.length > 0}
      autoHideDuration={6000}
      onClose={actions.clear}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <MuiAlert
        icon={<ErrorIcon fontSize="inherit" />}
        elevation={3}
        variant="filled"
        severity="error"
      >
        {
          _.takeRight(notifications.filter(n => n.type === NotificationTypes.error), 3).map((e, index) => renderMessage(e, index))
        }
      </MuiAlert>
    </Snackbar>
    }
    {notifications.some(n => n.type === NotificationTypes.success) && <Snackbar
      open={notifications.length > 0}
      autoHideDuration={4000}
      onClose={actions.clear}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <MuiAlert
        icon={<CheckIcon fontSize="inherit" />}
        variant="filled"
        elevation={3}
        severity="success">
        {_.takeRight(notifications.filter(n => n.type === NotificationTypes.success), 3).map((e, index) => renderMessage(e, index))}
      </MuiAlert>
    </Snackbar>}
  </React.Fragment>
);

NotificationsHandler.propTypes = {
  notifications: PropTypes.array,
  actions: PropTypes.object.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

function mapStateToProps(state) {
  return {
    notifications: state.notifications,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsHandler);
