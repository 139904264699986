import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { connect } from 'react-redux';
import numberFormatter from "../../../../utils/numberFormatter";

const FareAdjustmentConfirmDialog = ({ open, fareData, onSave, onCancel, submitting }) => (
  <Dialog open={open}>
    <DialogTitle>Potwierdzenie nałożenia opłaty</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Czy na pewno chcesz <strong>nałożyć</strong> dodatkową opłatę w wysokości <strong>{numberFormatter.format(fareData ? fareData.amount : 0)}</strong> zł na ten przejazd?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel} disabled={submitting} >
        Anuluj
      </Button>
      <Button variant="contained" onClick={() => onSave(fareData)} disabled={submitting} color="primary">
        Potwierdź
      </Button>
    </DialogActions>
  </Dialog>
);

FareAdjustmentConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  fareData: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default connect()(FareAdjustmentConfirmDialog);
