import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { actions } from '../../../../reducers/reports/clientHistory/actions';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { bindActionCreators } from 'redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import { connect } from 'react-redux';
import _ from 'lodash';
import './styles.scss';
import { Link } from 'react-router-dom';
import routePaths from '../../../../routePaths';

class RideStarterDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getEmptyState();
    this.handleVerifyCode = this.handleVerifyCode.bind(this);
    this.handleStartBikeRental = this.handleStartBikeRental.bind(this);
    this.handlePendingRide = this.handlePendingRide.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleBikeNumberChangedDebounced = _.debounce(this.handleBikeNumberChanged.bind(this), 500);
    this.handleSendSmsCode = this.handleSendSmsCode.bind(this);
    this.timer = null;
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  getEmptyState() {
    return {
      callCenterCode: '',
      codeIsValid: null,
      bikeNumber: '',
      submitting: false,
      bikeNotFound: false,
      rideId: null,
      rideStarted: false,
      rideFailure: false,
      rideFailureMessage: '',
      ridePending: false,
    };
  }

  handleSendSmsCode() {
    this.props.actions.remindCallCenterCode(this.props.userId);
  }

  handleBikeNumberChanged() {
    this.setState({ bikeNotFound: false });
    if (_.isEmpty(this.state.bikeNumber)) return;
    const bike = this.props.bikes.find(b => b.bikeNumber === this.state.bikeNumber);
    this.setState({
      bikeNotFound: !bike,
    });
  }

  handleVerifyCode() {
    this.setState({ submitting: true, ridePending: false, rideStarted: false, rideFailure: false, rideFailureMessage: '' });
    this.props.actions
      .verifyClientCallCenterCode(this.props.userId, this.state.callCenterCode)
      .then(codeIsValid => this.setState({ codeIsValid }))
      .finally(() => this.setState({ submitting: false }));
  }

  handleStartBikeRental() {
    this.setState({ submitting: true, ridePending: false, rideStarted: false, rideFailure: false, rideFailureMessage: '', rideId: null });
    this.props.actions
      .startBikeRentalForClient(this.props.userId, this.state.bikeNumber)
      .then(data => {
        if (data.rideId) {
          this.handlePendingRide(data.rideId);
        } else {
          this.setState({ rideFailure: true, rideFailureMessage: (data.failure && data.failure.message || 'Nie udało się rozpocząć przejazdu') });
        }
      })
      .finally(() => this.setState({ submitting: false }));
  }

  handlePendingRide(rideId) {
    this.setState({ ridePending: true, rideFailure: false, rideFailureMessage: '', rideStarted: false });
    this.props.actions.getRideStatus(rideId)
      .then(rideStatus => {
        switch (rideStatus) {
          case 'reservationPending':
            this.timer = setTimeout(() => this.handlePendingRide(rideId), 1500);
            return;
          case 'started':
            return this.setState({ ridePending: false, rideStarted: true, rideId });
          case 'canceled':
            return this.setState({ ridePending: false, rideFailure: true, rideFailureMessage: 'Nie udało się rozpocząć przejazdu' });
          default:
            return this.setState({ ridePending: false, rideFailure: false });
        }
      });
  }

  handleCancel() {
    if (this.timer) clearTimeout(this.timer);
    this.timer = null;
    this.setState(this.getEmptyState());
    this.props.onCancel();
  }

  renderRideStartingProgress() {
    const { ridePending, rideStarted, rideFailure, rideFailureMessage, rideId } = this.state;
    return (
      <div className="statusContainer">
        {ridePending &&
          <div className="inProgress">
            <CircularProgress className="progressIndicator" size={32} />
            <span>Rozpoczynanie przejazdu</span>
          </div>}
        {rideStarted &&
          <div className="rideStarted">
            <CheckIcon className="rideStartedIcon" size={32} />
            <span>Rozpoczęto <Link to={`${routePaths.ridesReports}/${rideId}`}>przejazd</Link></span>
          </div>}
        {rideFailure &&
          <div className="rideFailure">
            <ErrorIcon className="rideCanceledIcon" size={32} />
            <span>{rideFailureMessage}</span>
          </div>}
      </div>
    );
  }

  renderCallCenterCodeSection() {
    const { submitting, callCenterCode, codeIsValid, ridePending } = this.state;
    return (
      <div>
        <div>
          <TextField
            disabled={submitting}
            label="Telekod"
            helperText="Wprowadź telekod podany przez użytkownika"
            margin="dense"
            onChange={e => this.setState({ callCenterCode: e.target.value })}
          />
          <Button disabled={submitting || ridePending || _.isEmpty(callCenterCode)} onClick={this.handleVerifyCode} className="dialogButton">Weryfikuj kod</Button>
        </div>
        {
          codeIsValid === false
            ? <div className="error">
              <p>Wprowadzony telekod jest niepoprawny.<br /><a onClick={this.handleSendSmsCode}>Wyślij sms z telekodem do użytkownika</a></p>
            </div>
            : null
        }
        {codeIsValid === true ? <p>Telekod poprawny. Wprowadź numer roweru</p> : null}
      </div>);
  }

  renderBikeNumberSection() {
    const { submitting, codeIsValid, bikeNumber, bikeNotFound, ridePending } = this.state;

    return (
      <div>
        <TextField
          disabled={submitting || !codeIsValid}
          label="Numer roweru"
          helperText="Wprowadź numer roweru"
          margin="dense"
          onChange={e => {
            this.setState({ bikeNumber: e.target.value });
            this.handleBikeNumberChangedDebounced();
          }}
        />
        <Button
          onClick={this.handleStartBikeRental}
          disabled={submitting || ridePending || !codeIsValid || _.isEmpty(bikeNumber)}
          className="dialogButton">
          Rozpocznij przejazd
        </Button>
        <br />
        {bikeNotFound ? <div className="error"><p>{`Nie znaleziono roweru ${bikeNumber}`}</p></div> : null}
      </div>
    );
  }

  render() {
    const { isOpened } = this.props;
    return (
      <Dialog open={isOpened}>
        <DialogTitle>Rozpoczynanie przejazdu</DialogTitle>
        <DialogContent>
          {this.renderCallCenterCodeSection()}
          {this.renderBikeNumberSection()}
          {this.renderRideStartingProgress()}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCancel}>
            Zamknij
          </Button>
        </DialogActions>
      </Dialog >
    );
  }
}

RideStarterDialog.propTypes = {
  actions: PropTypes.object.isRequired,
  isOpened: PropTypes.bool.isRequired,
  userId: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  bikes: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  return { bikes: state.bikes };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RideStarterDialog);
