import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from '../../actions/plansActions';
import PlanDetailsForm from './PlanDetailsForm';
import { permissions, hasPermission } from '../../utils/authorization';

class PlanDetailsPage extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.actions.load();
  }

  render() {
    const { actions, plan, userPermissions } = this.props;
    const readonly = hasPermission(userPermissions, permissions.pricingConfiguration.access)
      && !hasPermission(userPermissions, permissions.pricingConfiguration.modify);

    return (
      <div className="page">
        <div style={{ margin: 40 }}>
          {plan && <PlanDetailsForm readonly={readonly} initialValues={plan} onSubmit={data => actions.update(data.id, data)} />}
        </div>
      </div>
    );
  }
}

PlanDetailsPage.propTypes = {
  actions: PropTypes.object.isRequired,
  plan: PropTypes.object,
  userPermissions: PropTypes.array.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

function mapStateToProps(state, ownProps) {
  return {
    plan: state.plans.find(p => p.id === ownProps.match.params.id),
    userPermissions: state.auth.permissions,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PlanDetailsPage);
