import { Paper, Typography } from '@material-ui/core';
import { func, string } from 'prop-types';
import React from 'react';
import ImagePicker from '../common/ImagePicker';
import ContentEditorV2 from "../contentEditorV2/ContentEditorV2";

const TermsAndConditionsContentEditor = ({ content, onContentChange, onAdditionalImagePicked, additionalImageDefault }) => {
  return (
    <Paper elevation={3} style={{ padding: 16 }}>
      <Typography variant="subtitle1">Treść regulaminu</Typography>
      <ContentEditorV2 value={content} onChange={onContentChange} />
      <div style={{ marginTop: 16 }}>
        <Typography variant="subtitle1">Dodatkowa grafika</Typography>
        <ImagePicker fallbackImage={additionalImageDefault} onFilePicked={onAdditionalImagePicked} />
      </div>
    </Paper>
  );
};

TermsAndConditionsContentEditor.propTypes = {
  content: string.isRequired,
  onContentChange: func.isRequired,
  onAdditionalImagePicked: func.isRequired,
  additionalImageDefault: string,
};

export default TermsAndConditionsContentEditor;
