import React, {useState} from 'react'
import Button from "@material-ui/core/Button";
import ConfirmAssignPhoneNumberDialog from "./ConfirmAssignPhoneNumberDialog";
import PropTypes from "prop-types";

const ConfirmAssignPhoneNumberButton = ({phoneNumber, confirmAssignPhoneNumber}) => {

  const [showDialog, setShowDialog] = useState(false)
  const onHandleSave = async () => {
    const res = await confirmAssignPhoneNumber(phoneNumber)
    if(res.success){
      setShowDialog(false)
    }
  }

    return (
      <>
        <Button
          onClick={() => {setShowDialog(true)}}
          variant="outlined"
          size="small"
        >
          Zaakceptuj numer telefonu
        </Button>
        {showDialog && <ConfirmAssignPhoneNumberDialog
          open={showDialog}
          onSave={onHandleSave}
          onCancel={() => setShowDialog(false)}
          phoneNumber={phoneNumber}
        />}
      </>
    );
}

ConfirmAssignPhoneNumberButton.propTypes = {
  phoneNumber: PropTypes.string.isRequired,
  confirmAssignPhoneNumber: PropTypes.func.isRequired,
}

export default ConfirmAssignPhoneNumberButton;
