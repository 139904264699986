import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import ClientActivationStatus from "./ClientActivationStatus";
import ParentalConsentButton from "./ParentalConsentButton";
import RideStarterButton from "./rideStarter/RideStarterButton";
import { hasPermission, permissions } from "../../../utils/authorization";
import {
  CanShowResendButtonByStatus,
  emailStatusParser,
  UserStatus,
} from "../../../reducers/reports/clients/models";
import ClientStatus from "./ClientStatus";
import UnderageStatusPicker from "./UnderageStatusPicker";
import AddToDenyListButton from "./AddToDenyListButton";
import ConfirmationButton from "../../common/ConfirmationButton";
import SubscribeCampaignNewsletterStatusPicker from "./SubscribeCampaignNewsletterStatusPicker";
import DedicatedPlansButton from "./DedicatedPlansButton";
import ConfirmAssignPhoneNumberButton from "./ConfirmAssignPhoneNumberButton";
import ResendVerificationEmailButton from "./ResendVerificationEmailButton";
import ChangeUserPhoneNumberButton from "./ChangeUserPhoneNumberButton";

const SummaryPanel = ({
  summary,
  uploadParentalConsent,
  removeParentalConsent,
  operatorPermissions,
  addToDenyList,
  setDedicatedPlans,
  dedicatedPlans,
  removeFromDenyList,
  requirePeselNumber,
  confirmAssignPhoneNumber,
  onResendVerificationEmail,
  changeUserPhoneNumber,
}) => (
  <div>
    <div className="flex-row flex-wrap align-items-center">
      <h3 className="panel">{`${summary.firstName} ${summary.lastName}`}</h3>
      <ClientStatus status={summary.userStatus} style={{ marginRight: 10 }} />
      {!summary.isActive && (
        <ClientActivationStatus inactiveReason={summary.inactiveReason} />
      )}
    </div>
    <div className="flex-row flex-wrap">
      <div className="panel-right">
        <Typography color="textSecondary">Telefon</Typography>
        {!summary.toAssignPhoneNumber &&
          hasPermission(
            operatorPermissions,
            permissions.changeUserPhoneNumber,
          ) && (
            <Typography>
              {summary.phoneNumber ? summary.phoneNumber : "brak"}
              <ChangeUserPhoneNumberButton
                changePhoneNumber={changeUserPhoneNumber}
              />
            </Typography>
          )}
        {!hasPermission(
          operatorPermissions,
          permissions.changeUserPhoneNumber,
        ) &&
          summary.phoneNumber && <Typography>{summary.phoneNumber}</Typography>}
        {!hasPermission(
          operatorPermissions,
          permissions.confirmAssignPhoneNumber,
        ) &&
          !summary.phoneNumber &&
          (!summary.toAssignPhoneNumber ||
            !hasPermission(
              operatorPermissions,
              permissions.confirmAssignPhoneNumber,
            )) && <Typography>Brak</Typography>}
        {!summary.phoneNumber &&
          summary.toAssignPhoneNumber &&
          hasPermission(
            operatorPermissions,
            permissions.confirmAssignPhoneNumber,
          ) && (
            <Typography>
              <ConfirmAssignPhoneNumberButton
                phoneNumber={summary.toAssignPhoneNumber}
                confirmAssignPhoneNumber={confirmAssignPhoneNumber}
              />
            </Typography>
          )}
      </div>
      <div className="panel-right">
        <Typography color="textSecondary">Email</Typography>
        <Typography>{summary.email}</Typography>
      </div>
      {requirePeselNumber && (
        <div className="panel-right">
          <Typography color="textSecondary">Pesel</Typography>
          <Typography>
            {summary.peselNumber ? summary.peselNumber : "Brak"}
          </Typography>
        </div>
      )}
      <div className="panel-right">
        <Typography color="textSecondary">
          Status potwierdzenia emaila
        </Typography>
        <Typography>
          {(!hasPermission(
            operatorPermissions,
            permissions.resendVerificationEmail,
          ) ||
            !CanShowResendButtonByStatus(summary.emailStatus)) && (
            <span>{emailStatusParser(summary.emailStatus)}</span>
          )}
          {hasPermission(
            operatorPermissions,
            permissions.resendVerificationEmail,
          ) &&
            CanShowResendButtonByStatus(summary.emailStatus) && (
              <ResendVerificationEmailButton
                status={emailStatusParser(summary.emailStatus)}
                email={summary.email}
                onResendVerificationEmail={onResendVerificationEmail}
              />
            )}
        </Typography>
      </div>
      <div className="panel-right">
        <Typography color="textSecondary">Zarejestrowany od</Typography>
        <Typography>
          {moment(summary.registeredAt).local().format("DD.MM.YYYY HH:mm")}
        </Typography>
      </div>
      <div className="panel-right">
        <Typography color="textSecondary">Niepełnoletni</Typography>
        {summary.userStatus !== UserStatus.active.value ||
        !hasPermission(
          operatorPermissions,
          permissions.manageUnderageStatus,
        ) ? (
          <Typography>{summary.isJuvenile ? "TAK" : "NIE"}</Typography>
        ) : (
          <UnderageStatusPicker
            userId={summary.userId}
            underage={summary.isJuvenile}
          />
        )}
      </div>
      <div className="panel-right">
        <Typography color="textSecondary">Newsletter Kampanii</Typography>
        {summary.userStatus !== UserStatus.active.value ||
        !hasPermission(
          operatorPermissions,
          permissions.manageUnderageStatus,
        ) ? (
          <Typography>
            {summary.isSubscribeCampaignNewsletter ? "TAK" : "NIE"}
          </Typography>
        ) : (
          <SubscribeCampaignNewsletterStatusPicker
            userId={summary.userId}
            underage={summary.isSubscribeCampaignNewsletter}
          />
        )}
      </div>
      {summary.isJuvenile ? (
        <div className="panel-right">
          <Typography color="textSecondary">Zgoda opiekuna</Typography>
          <ParentalConsentButton
            parentalConsentDocumentUrl={summary.parentalConsentDocumentUrl}
            uploadParentalConsent={uploadParentalConsent}
            removeParentalConsent={removeParentalConsent}
            operatorPermissions={operatorPermissions}
          />
        </div>
      ) : null}
      <div className="panel-right">
        {hasPermission(operatorPermissions, permissions.startRideForUser) && (
          <RideStarterButton
            userId={summary.userId}
            disabled={
              !summary.isActive ||
              summary.userStatus !== UserStatus.active.value ||
              summary.accessDenied
            }
          />
        )}
      </div>
      <div className="panel-right">
        <Typography color="textSecondary">Ostatnie logowanie</Typography>
        <Typography>
          {summary.lastLogin
            ? moment(summary.lastLogin).local().format("DD.MM.YYYY HH:mm")
            : "Brak danych"}
        </Typography>
      </div>
      {summary.isActive && (
        <div className="panel-right">
          {!summary.accessDenied ? (
            <AddToDenyListButton
              userId={summary.userId}
              onAddToDenyList={addToDenyList}
            />
          ) : (
            <ConfirmationButton
              size="small"
              variant="outlined"
              onConfirm={removeFromDenyList}
              confirmationTitle="Odblokuj konto"
              confirmationText="Czy na pewno chcesz usunąć użytkownika z czarnej listy?"
            >
              Usuń z czarnej listy
            </ConfirmationButton>
          )}
        </div>
      )}
      <div className="panel-right">
        <DedicatedPlansButton
          userId={summary.userId}
          setDedicatedPlans={setDedicatedPlans}
          dedicatedPlans={dedicatedPlans}
        />
      </div>
    </div>
  </div>
);

SummaryPanel.propTypes = {
  summary: PropTypes.object,
  uploadParentalConsent: PropTypes.func.isRequired,
  removeParentalConsent: PropTypes.func.isRequired,
  operatorPermissions: PropTypes.array.isRequired,
  addToDenyList: PropTypes.func.isRequired,
  setDedicatedPlans: PropTypes.func.isRequired,
  dedicatedPlans: PropTypes.array.isRequired,
  removeFromDenyList: PropTypes.func.isRequired,
  requirePeselNumber: PropTypes.bool.isRequired,
  confirmAssignPhoneNumber: PropTypes.func.isRequired,
  onResendVerificationEmail: PropTypes.func.isRequired,
  changeUserPhoneNumber: PropTypes.func.isRequired,
};

export default SummaryPanel;
