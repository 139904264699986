import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { actions } from '../../../actions/bikeDefectsActions';
import ReportTable from '../../common/reportTable/ReportTable';
import FiltersPanel from './FiltersPanel';
import { getColumns } from './Columns';
import queryString from 'query-string';
import './styles.scss';
import routePaths from '../../../routePaths';
import DownloadIcon from '@material-ui/icons/GetApp';
import { hasPermission, permissions } from '../../../utils/authorization';
import moment from 'moment';
import { DateTimePicker } from '@material-ui/pickers';

class BikeDefectsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reportGenerating: false,
      reportDialogOpened: false,
      reportFrom: moment().subtract(1, 'month'),
      reportTo: moment(),
    };

    this.handleFiltersChange = this.handleFiltersChange.bind(this);
    this.handleSortChange = this.handleSortChange.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
    this.handleShowReportDialog = this.handleShowReportDialog.bind(this);
    this.handleCloseReportDialog = this.handleCloseReportDialog.bind(this);
    this.handleGenerateReport = this.handleGenerateReport.bind(this);
  }

  componentDidMount() {
    const defects = this.props.defects;
    const defaultFilter = this.props.bikeNumber
      ? [...defects.filteredBy.filter(i => i.id !== 'bikeNumber'), { id: 'bikeNumber', value: this.props.bikeNumber }]
      : defects.filteredBy;

    this.props.actions.loadFreshBikeDefects(1, defects.paging.pageSize, defects.sortedBy, defaultFilter);
  }

  handleSortChange(sortBy) {
    const defects = this.props.defects;
    this.props.actions.loadFreshBikeDefects(1, defects.paging.pageSize, sortBy, defects.filteredBy);
  }

  handleFiltersChange(filterBy) {
    const defects = this.props.defects;
    this.props.actions.loadFreshBikeDefects(1, defects.paging.pageSize, defects.sortedBy, filterBy.filter(f => f.value));
  }

  handlePageChange(nextPage) {
    this.props.actions.loadNextBikeDefects(nextPage);
  }

  handlePageSizeChange(nextPageSize) {
    const defects = this.props.defects;
    this.props.actions.loadFreshBikeDefects(1, nextPageSize, defects.sortedBy, defects.filteredBy);
  }

  handleShowReportDialog() {
    this.setState({ reportDialogOpened: true });
  }

  handleCloseReportDialog() {
    this.setState({
      reportDialogOpened: false,
      reportFrom: moment().subtract(1, 'month'),
      reportTo: moment(),
    });
  }

  async handleGenerateReport() {
    this.setState({
      reportGenerating: true,
      reportDialogOpened: false,
    });
    try {
      await this.props.actions.generateReport(this.state.reportFrom, this.state.reportTo);
    } finally {
      this.setState({ reportGenerating: false });
    }
  }

  render() {
    const defects = this.props.defects;
    const { reportGenerating } = this.state;
    const hasConfigurationAccessPermission = hasPermission(this.props.userPermissions, permissions.bikeDefectsConfiguration.modify);
    const hasClientsDataPermission = hasPermission(this.props.userPermissions, permissions.clients);
    return (
      <div className="page">
        <div style={{ margin: 40, marginBottom: 0 }}>
          {hasConfigurationAccessPermission ? <div style={{ marginBottom: 16, display: 'flex', justifyContent: 'flex-end' }}>
            <Button color='secondary' variant='outlined' component={Link} to={routePaths.bikeDefectsConfiguration}>Konfiguracja</Button>
            <Button variant='outlined' disabled={reportGenerating} style={{ marginLeft: 8 }} onClick={() => this.handleShowReportDialog()}>
              {reportGenerating && < CircularProgress size={20} color="secondary" />}
              {!reportGenerating && <DownloadIcon />}
              RAPORT
            </Button>
          </div> : null}
          <Paper style={{ padding: 16 }}>
            <FiltersPanel filteredBy={defects.filteredBy} onFilteredChange={this.handleFiltersChange} />
            <ReportTable
              style={{ height: "calc(100vh - 200px)" }}
              columns={getColumns(hasClientsDataPermission)}
              data={defects.records}
              filteredBy={defects.filteredBy}
              sortedBy={defects.sortedBy}
              paging={defects.paging}
              onFilteredChange={this.handleFiltersChange}
              onPageChange={this.handlePageChange}
              onPageSizeChange={this.handlePageSizeChange}
              onSortedChange={this.handleSortChange}
              loading={defects.loading}
            />
            <Dialog fullWidth open={this.state.reportDialogOpened} onClose={() => this.handleCloseReportDialog()}>
              <DialogTitle id="form-dialog-title">Generowanie raportu</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Wybierz zakres reportu
                </DialogContentText>
                <DateTimePicker
                  label={"Zakres od"}
                  ampm={false}
                  margin="dense"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  autoOk
                  okLabel="OK"
                  cancelLabel="Anuluj"
                  clearLabel="Wyczyść"
                  invalidLabel="Wybierz ..."
                  onChange={value => this.setState({ reportFrom: value })}
                  format="DD.MM.YYYY HH:mm:ss"
                  value={this.state.reportFrom}
                  clearable
                />
                <DateTimePicker
                  label={"Zakres do"}
                  ampm={false}
                  margin="dense"
                  minDate={this.state.reportFrom}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  autoOk
                  okLabel="OK"
                  cancelLabel="Anuluj"
                  clearLabel="Wyczyść"
                  invalidLabel="Wybierz ..."
                  onChange={value => this.setState({ reportTo: value })}
                  format="DD.MM.YYYY HH:mm:ss"
                  value={this.state.reportTo}
                  clearable
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleCloseReportDialog}>
                  Anuluj
                </Button>
                <Button disabled={reportGenerating} onClick={this.handleGenerateReport} color="primary" variant='contained'>
                  Generuj
                </Button>
              </DialogActions>
            </Dialog>

          </Paper>
        </div>
      </div>
    );
  }
}


BikeDefectsPage.propTypes = {
  actions: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  defects: PropTypes.object,
  bikeNumber: PropTypes.string,
  userPermissions: PropTypes.array.isRequired,
};

BikeDefectsPage.defaultProps = {
  loading: false,
  defects: {},
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

function mapStateToProps(state, ownProps) {
  const defectsState = state.bikeDefects.list;
  return {
    defects: defectsState.pagedData,
    loading: defectsState.loading,
    bikeNumber: queryString.parse(ownProps.location.search).bikeNumber,
    userPermissions: state.auth.permissions,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BikeDefectsPage);
