import React from 'react';
import PropTypes from 'prop-types';
import { Marker } from "react-google-maps";

export const finishTraceIcon = {
  path: 'M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2Z',
  fillColor: '#4d4d4d',
  fillOpacity: 1,
  strokeWeight: 0,
  anchor: { x: 12, y: 12 }
};

export const startTraceIcon = {
  path: 'M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,7A5,5 0 0,0 7,12A5,5 0 0,0 12,17A5,5 0 0,0 17,12A5,5 0 0,0 12,7Z',
  fillColor: '#4d4d4d',
  fillOpacity: 1,
  strokeWeight: 0,
  anchor: { x: 12, y: 12 }
};

const MapTraceMarker = ({ location, isStartMarker, ...other }) => (
  <Marker
    icon={isStartMarker ? startTraceIcon : finishTraceIcon}
    position={location}
    place={location}
    title={location ? `${location.lat}${location.lng}` : 'x'}
    {...other}
  />
);

MapTraceMarker.propTypes = {
  location: PropTypes.object.isRequired,
  isStartMarker: PropTypes.bool,
  isFinishMarker: PropTypes.bool,
};

export default MapTraceMarker;