import initialState from './initialState';
import Immutable from 'seamless-immutable';
import { types } from '../actions/bikesManagementActions';

export default function bikeAlertsReducer(state = Immutable(initialState.bikeAlerts), action) {
  switch (action.type) {
    case types.GET_BIKE_ALERTS: {
      return Object.assign({}, state, { data: [], fetching: true });
    }
    case types.GET_BIKE_ALERTS_SUCCESS: {
      return Object.assign({}, state, { data: action.data, fetching: false });
    }
    case types.GET_BIKE_ALERTS_FAILURE: {
      return Object.assign({}, state, { data: [], fetching: false });
    }
    default:
      return state;
  }
}
