import validate from 'validate.js';

export const autoLogoutSettingsValidation = values => {
  const constraints = {
    timeout: function (_, attributes) {
      if (!attributes.enabled) return null;
      return {
        presence: { message: 'Podanie wartości jest wymagane' },
        numericality: {
          greaterThanOrEqualTo: 60,
          message: `Wartość musi być równa 60 sekund lub większa`,
        },
      };
    },
  };

  return validate(values, constraints, { fullMessages: false });
};

export const maximumRentalsPerUserValidation = values => {
  const constraints = {
    maximumRentalsPerUser: {
      presence: { message: 'Podanie wartości jest wymagane' },
      numericality: {
        greaterThanOrEqualTo: 1,
        lessThanOrEqualTo: 10,
        message: 'Wartość musi być większa od 0 i nie większa od 10',
      },
    },
    maximumRentalsPerTester: {
      presence: { message: 'Podanie wartości jest wymagane' },
      numericality: {
        greaterThanOrEqualTo: 0,
        message: 'Wartość musi być większa bądź równa 0',
      },
    }
  };

  return validate(values, constraints, { fullMessages: false });
};

export const clientsActivityThresholdSettingsValidation = values => {
  const constraints = {
    clientsActivityThresholdDays: {
      presence: { message: 'Podanie wartości jest wymagane' },
      numericality: {
        greaterThanOrEqualTo: 0,
        message: 'Wartość musi być większa od lub równa 0',
      },
    }
  };

  return validate(values, constraints, { fullMessages: false });
};
