import apiBase from './apiBase';

class twoFAApi {
  static getTOTPSecretCode() {
    return apiBase.create().get('/users/totp');
  }

  static enableTOTP2FA(data) {
    return apiBase.create().post('/users/2fa/totp/enable', data);
  }

  static disableTOTP2FA(data) {
    return apiBase.create().post('/users/2fa/totp/disable', data);
  }
}

export default twoFAApi;
