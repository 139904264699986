import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { actions as authActions } from '../../../actions/authActions';
import routePaths from '../../../routePaths';
import SignInForm from './SignInForm';
import AnonymousContentPanel from '../AnonymousContentPanel';
import './SignInPageStyles.scss';

const SignInPage = ({ authActions }) => (
  <AnonymousContentPanel>
    <SignInForm
      onSubmit={data => authActions.signIn(data)}
    />
    <div className="password-recovery-link">
      <Link to={routePaths.passwordRecovery}><span>Zapomniałem hasła</span></Link>
    </div>
  </AnonymousContentPanel>
);

SignInPage.propTypes = {
  authActions: PropTypes.object.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    authActions: bindActionCreators(authActions, dispatch)
  };
}

export default connect(null, mapDispatchToProps)(SignInPage);
