import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import FormTextField from '../form/redux-form/FormTextField';
import FormSelect from '../form/redux-form/FormSelect';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { ZoneTypes, ZoneAreaTypes } from '../../models/zones/ZoneModel';

let ZoneDetailsForm = ({ handleSubmit, submitting, zoneTypes, zoneType, areaType }) => {
  return (
    <form onSubmit={handleSubmit}>
      <Typography variant="subtitle1" gutterBottom>
        Nowa strefa
      </Typography>
      <div>
        <Field
          name="name"
          type="text"
          component={FormTextField}
          label="Nazwa strefy"
          fullWidth
        />
      </div>
      <div>
        {(areaType === ZoneAreaTypes.circle)
          ? <Field
              name="radius"
              type="number"
              component={FormTextField}
              label="Promień strefy w metrach"
              parse={value => Number(value)}
              fullWidth
          />
          : null}
      </div>
      <br />
      <div>
        {(zoneTypes.length > 0 && zoneType === ZoneTypes.preferredBikeReturnZone.value)
          ? <Field
              name={'bikesReturnZoneTypeId'}
              component={FormSelect}
              label="Typ"
              style={{ width: 200 }}
              values={{ default: { value: null, name: '' }, ...zoneTypes.map(zt => ({ value: zt.id, name: zt.name })) }}
          />
          : null}
      </div>
      <br />
      <div className="actions">
        <Button size="medium" color="primary" type="submit" disabled={submitting}>
          {submitting ? 'Zapisywanie...' : 'Zapisz'}
        </Button>
      </div>
    </form>
  );
};

ZoneDetailsForm.propTypes = {
  zoneTypes: PropTypes.array,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  zoneType: PropTypes.string,
  areaType: PropTypes.string.isRequired,
  radius: PropTypes.number
};

ZoneDetailsForm = reduxForm({
  form: 'zone',
})(ZoneDetailsForm);

ZoneDetailsForm = connect(
  (state, ownProps) => {
    return {
      zoneTypes: state.zoneTypes.list,
      initialValues: { radius: ownProps.radius },
    };
  }
)(ZoneDetailsForm);

export default ZoneDetailsForm;
