import { ReportTableModel } from '../../../models/ReportTableModel';

export const EmailStatus = {
  NoRequired: 0,
  Verified: 1,
  RequiredVerification: 2,
  RequiredVerificationForOldUser: 3,
  RequiredVerificationForNewUser: 4,
};

export const EventTypes = {
  bikeReserved: { value: 'bikeReserved', name: 'rezerwacja' },
  bikeReservedAutomatically: { value: 'bikeReserved', name: 'rezerwacja (automatyczna)' },
  bikeReservedAutomaticallyByOperator: { value: 'bikeReserved', name: 'rezerwacja (operator)' },
  bikeReservationCanceled: { value: 'bikeReservationCanceled', name: 'anulowanie rezerwacji' },
  bikeReservationExpired: { value: 'bikeReservationExpired', name: 'wygaśnięcie rezerwacji' },
  bikeUnlockRequested: { value: 'bikeUnlockRequested', name: 'żądanie otwarcia zamka' },
  bikeUnlockFailed: { value: 'bikeUnlockFailed', name: 'błąd podczas otwierania zamka' },
  rideStarted: { value: 'rideStarted', name: 'rozpoczęcie przejazdu' },
  ridePaused: { value: 'ridePaused', name: 'wstrzymanie przejazdu' },
  rideResumed: { value: 'rideResumed', name: 'wznowienie przejazdu' },
  rideFinished: { value: 'rideFinished', name: 'zakończenie przejazdu' },
  rideRestarted: {value: 'rideRestarted', name: 'wznowienie zakończonego przejazdu'},
  termsAndConditionsApproved: { value: 'termsAndConditionsApproved', name: 'akceptacja regulaminu' },
  planBought: { value: 'planBought', name: 'zakup planu' },
  phoneNumberChanged: { value: 'phoneNumberChanged', name: 'zmiana numeru telefonu' },
  cardAdded: { value: 'cardAdded', name: 'podpięcie karty miejskiej' },
  cardDeleted: { value: 'cardDeleted', name: 'odpięcie karty miejskiej' },
  cardActivated: { value: 'cardActivated', name: 'aktywacja karty miejskiej' },
  cardDeactivated: { value: 'cardDeactivated', name: 'dezaktywacja karty miejskiej' },
  rideResumedBeforeDecision: { value: 'rideResumedBeforeDecision', name: 'wznowienie przejazdu' },
  nfcTokenAssigned: { value: 'nfcTokenAssigned', name: 'przypisanie tokenów NFC' }
};

export class ClientHistoryEvent {
  constructor(data) {
    const { rideId, bikeNumber, version, bikeId, planId, previousPhoneNumber, newPhoneNumber, cardNumber, cardProviderId, ...other } = data.metadata;
    this.occurredAt = data.occurredAt;
    this.type = EventTypes[data.type];
    this.rideId = rideId;
    this.bikeNumber = bikeNumber;
    this.bikeId = bikeId;
    this.planId = planId;
    this.version = version;
    this.previousPhoneNumber = previousPhoneNumber;
    this.newPhoneNumber = newPhoneNumber;
    this.cardNumber = cardNumber;
    this.cardProviderId = cardProviderId;
    this.other = other;
  }
}

export class ClientHistorySummary {
  constructor(data, isGlobal = false) {
    this.userId = data.userId;
    this.userStatus = data.userStatus;
    this.clientId = data.clientId;
    this.phoneNumber = data.phoneNumber;
    this.toAssignPhoneNumber = data.toAssignPhoneNumber;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.email = data.email;
    this.registeredAt = data.registeredAt;
    this.isJuvenile = data.isJuvenile;
    this.parentalConsentDocumentUrl = data.parentalConsentDocumentUrl;
    this.isActive = !data.isJuvenile || this.parentalConsentDocumentUrl || false;
    this.inactiveReason = !this.isActive && data.isJuvenile && "Oczekuje na zgodę opiekuna" || null;
    this.accessDenied = data.accessDenied;
    this.active = data.active;
    this.lastActive = data.lastActive;
    this.isSubscribeCampaignNewsletter = data.isSubscribeCampaignNewsletter;
    this.peselNumber = data.peselNumber;
    this.emailStatus = data.emailStatus;
    this.lastLogin = data.lastLogin;
    if (isGlobal) {
      this.balance = data.wallet.balance
      this.availableToWithdraw = data.wallet.availableToWithdraw
      this.tenants = data.tenants
      this.finishedRegisterAt = data.finishedRegisterAt
    }
  }
}

export class ClientHistoryReportModel {
  constructor(data) {
    this.loading = false;
    this.pagedData = new ReportTableModel({ pageNumber: 1, pageSize: 100 }, [], []);
    if (data) this.summary = new ClientHistorySummary(data);
  }
}
