import React from 'react';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { Field, reduxForm } from 'redux-form';
import FormTextField from '../../form/redux-form/FormTextField';

export const formValidation = values => {
  const constraints = {
    cardNumber: {
      presence: { message: 'Numer seryjny karty jest wymagany' }
    },
  };

  return validate(values, constraints, { fullMessages: false });
};

let AssignRFIDCardForm = ({ handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit}>
      <div>
        <Field
          name="cardNumber"
          component={FormTextField}
          label="Numer seryjny karty"
          autoFocus
        />
      </div>
    </form>
  );
};

AssignRFIDCardForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
};

AssignRFIDCardForm = reduxForm({
  form: 'assignRFIDCard',
  validate: formValidation,
})(AssignRFIDCardForm);

export default AssignRFIDCardForm;
