import { PageModel } from './PageModel';

export class ReportTableModel {
  constructor(paging, sortedBy, filteredBy) {
    const pageModel = new PageModel(paging);
    this.loading = false;
    this.paging = pageModel;
    this.recordsPerPage = { [pageModel.pageNumber]: [] };
    this.records = [];
    this.sortedBy = sortedBy;
    this.filteredBy = filteredBy;
  }

  static pageRequested(model, pageNumber, pageSize, filterBy, sortBy) {
    return {
      ...model,
      loading: true,
      sortedBy: sortBy,
      filteredBy: filterBy,
      paging: PageModel.nextPageRequested(model.paging, pageNumber, pageSize),
      recordsPerPage: {},
      records: [],
    };
  }

  static nextPageRequested(model, pageNumber) {
    return {
      ...model,
      loading: true,
      paging: PageModel.nextPageRequested(model.paging, pageNumber)
    };
  }

  static pageReady(model, totalSize, data, paging = null) {
    return {
      ...model,
      loading: false,
      paging: PageModel.nextPageReady(paging ? paging : model.paging, totalSize),
      recordsPerPage: {
        ...model.recordsPerPage,
        [model.paging.pageNumber]: data
      },
      records: data,
    };
  }
}
