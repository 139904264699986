export class TermsAndConditionsModel {
  constructor(id, personalDataProcessingClauses, versions) {
    this.id = id || null;
    this.personalDataProcessingClauses = personalDataProcessingClauses || {};
    this.versions = versions || [];
  }
}

export const VersionStatus = Object.freeze({
  published: { value: 'published' },
  draft: { value: 'draft' },
  archived: { value: 'archived' },
});

export const SupportedLanguageCodes = Object.freeze({
  pl: { value: 'pl' },
  en: { value: 'en' },
  de: { value: 'de' },
  ru: { value: 'ru' },
  cs: { value: 'cs' },
  lt: { value: 'lt' },
  uk: { value: 'uk' },
});

