import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { actions } from '../../../actions/bikeDefectsActions';
import SummaryPanel from './SummaryPanel';
import LocationPanel from './LocationPanel';
import OperationsPanel from './OperationsPanel';
import { hasPermission, permissions } from '../../../utils/authorization';

class BikeDefectDetailsPage extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.actions.loadBikeDefectDetails(this.props.defectId);
  }

  render() {
    const hasClientsDataPermission = hasPermission(this.props.currentUser.permissions, permissions.clients);
    return (
      <div className="page">
        <div style={{ margin: 40 }}>
          {this.props.loading ? <div style={{ width: '100%', textAlign: 'center' }}><CircularProgress size={50} /></div> :
            <div>
              <Paper style={{ padding: 16, marginBottom: 16 }}>
                <SummaryPanel summary={this.props.details} showClientData={hasClientsDataPermission} />
              </Paper>
              <Paper style={{ padding: 16, marginBottom: 16 }}>
                <OperationsPanel details={this.props.details}
                  resolveDefect={(id, data) => this.props.actions.resolveDefect(id, data, this.props.currentUser)}
                  rejectDefect={(id) => this.props.actions.rejectDefect(id, this.props.currentUser)}
                  resolveDefectWithParts={(id, data) => this.props.actions.resolveDefectWithParts(id, data, this.props.currentUser)}
                />
              </Paper>
              <Paper style={{ padding: 16, marginBottom: 16 }}>
                <LocationPanel
                  userLocation={this.props.details.reportedAtLocation}
                  bikeLocation={this.props.details.bikeLocation}
                  operatorLocation={this.props.details.operatorLocation}
                  bikeLocationAtResolution={this.props.details.bikeLocationAtResolution}
                />
              </Paper>
            </div>}
        </div>
      </div>
    );
  }
}

BikeDefectDetailsPage.propTypes = {
  actions: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  details: PropTypes.object,
  currentUser: PropTypes.object.isRequired,
  defectId: PropTypes.string.isRequired,
};

BikeDefectDetailsPage.defaultProps = {
  loading: true,
  details: {},
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

function mapStateToProps(state, ownProps) {
  const defectState = state.bikeDefects.details;
  return {
    defectId: ownProps.match.params.id,
    details: defectState.details,
    loading: defectState.loading,
    currentUser: state.auth,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BikeDefectDetailsPage);
