export const cardFormValidation = values => {
  const errors = {
    publicName: {},
    benefits: {},
  };

  const isGreaterThanOrEquals = (value, greaterThan) => value >= greaterThan;
  const isLessThanOrEquals = (value, lessThan) => value <= lessThan;
  const maxAmount = 100;

  if (!values.publicName || !values.publicName.pl) errors.publicName.pl = 'Pole jest wymagane';
  if (!values.publicName || !values.publicName.en) errors.publicName.en = 'Pole jest wymagane';
  if (!values.publicName || !values.publicName.de) errors.publicName.de = 'Pole jest wymagane';
  if (!values.publicName || !values.publicName.ru) errors.publicName.ru = 'Pole jest wymagane';
  if (!values.publicName || !values.publicName.cs) errors.publicName.cs = 'Pole jest wymagane';
  if (!values.publicName || !values.publicName.lt) errors.publicName.lt = 'Pole jest wymagane';
  if (!values.publicName || !values.publicName.uk) errors.publicName.uk = 'Pole jest wymagane';

  if (!values.benefits || values.benefits.discountPercentage == null) errors.benefits.discountPercentage = 'Pole jest wymagane';

  if (values.benefits && values.benefits.discountPercentage && !isGreaterThanOrEquals(values.benefits.discountPercentage, 0)) errors.benefits.discountPercentage = 'Wartość musi być większa lub równa 0';
  if (values.benefits && values.benefits.discountPercentage && !isLessThanOrEquals(values.benefits.discountPercentage, maxAmount)) errors.benefits.discountPercentage = `Wartość musi być mniejsza lub równa ${maxAmount}`;

  if (!values.benefits || values.benefits.discountPercentageForBuyPlans == null) errors.benefits.discountPercentageForBuyPlans = 'Pole jest wymagane';

  if (values.benefits && values.benefits.discountPercentageForBuyPlans && !isGreaterThanOrEquals(values.benefits.discountPercentageForBuyPlans, 0)) errors.benefits.discountPercentageForBuyPlans = 'Wartość musi być większa lub równa 0';
  if (values.benefits && values.benefits.discountPercentageForBuyPlans && !isLessThanOrEquals(values.benefits.discountPercentageForBuyPlans, maxAmount)) errors.benefits.discountPercentageForBuyPlans = `Wartość musi być mniejsza lub równa ${maxAmount}`;

  if(values.minimumUserBalanceEnabled) {
    if (!Number.isInteger(parseInt(values.minimumUserBalance))) errors.minimumUserBalance = 'Podanie wartości jest wymagane';
    else if (parseFloat(values.minimumUserBalance) < 0) errors.minimumUserBalance = 'Wartość musi być większa bądź równa 0 ';

  }

  if (!Number.isInteger(values.maximumRentalsPerUser)) errors.maximumRentalsPerUser = 'Podanie wartości jest wymagane';
  else if (values.maximumRentalsPerUser < 1 || values.maximumRentalsPerUser > 10) errors.maximumRentalsPerUser = 'Wartość musi być większa od 0 i nie większa od 10';

  return errors;
};
