import moment from 'moment';
import numberFormatter from '../../../utils/numberFormatter';

export const columns = [
  {
    Header: 'Timestamp',
    id: 'lockTimestamp',
    accessor: x => moment(x.lockTimestamp).local().format('YYYY-MM-DD HH:mm:ss.SSS'),
  },
  {
    Header: 'Szerokość geograficzna',
    id: 'latitude',
    accessor: 'latitude',
  },
  {
    Header: 'Długość geograficzna',
    id: 'longitude',
    accessor: 'longitude',
  },
  {
    Header: 'Stan zamka',
    id: 'lockState',
    accessor: x => x.lockState === 'opened' ? 'otwarty' : 'zamknięty',
  },
  {
    Header: 'Stan podkowy',
    id: 'shackleState',
    accessor: x => x.shackleState != null ? (x.shackleState === 'opened' ? 'otwarta' : 'zamknięta') : null,
  },
  {
    Header: 'Stan baterii',
    id: 'batteryLevel',
    accessor: x => x.batteryLevel != null ? `${x.batteryLevel}%` : null,
  },
  {
    Header: 'Prędkość',
    id: 'speed',
    accessor: x => x.speed != null ? `${numberFormatter.format(x.speed)} km/h` : null,
  },
  {
    Header: 'Czas gps',
    id: 'gpsTimestamp',
    accessor: x => x.gpsTimestamp != null ? moment(x.gpsTimestamp).local().format('YYYY-MM-DD HH:mm:ss.SSS') : null,
  },
  {
    Header: 'Satelity',
    id: 'satellites',
    accessor: x => x.satellites,
  },
  {
    Header: 'Źródło',
    id: 'source',
    accessor: 'source',
  },
  {
    Header: 'RideId',
    id: 'rideId',
    accessor: 'rideId',
  },
  {
    Header: 'FrameId',
    id: 'frameId',
    accessor: 'frameId',
  },
  {
    Header: 'Czas utworzenia',
    id: 'createdAt',
    accessor: x => moment(x.createdAt).local().format('YYYY-MM-DD HH:mm:ss.SSS'),
  },
  {
    Header: 'CorrelationId',
    id: 'correlationId',
    accessor: 'correlationId',
  }
];
