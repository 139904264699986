import React from 'react';
import PropTypes from 'prop-types';
import DateTimeFilter from '../../common/reportTable/filters/DateTimeFilter';
import TextFilter from '../../common/reportTable/filters/TextFilter';
import SelectFilter from '../../common/reportTable/filters/SelectFilter';
import {
  YesNoOptions,
  FareIssueStatus,
  ParseBikeTypesToSelect
} from '../../../reducers/reports/rides/models';
import './styles.scss';

class FiltersPanel extends React.Component {

  constructor(props) {
    super(props);
    this.handleDelete = this.handleDelete.bind(this);
  }

  getFilterValue(id) {
    return this.props.filteredBy.find(f => f.id === id);
  }

  handleDelete(deleteFunction) {
    this.props.onFilteredChange(deleteFunction(this.props.filteredBy));
  }

  render() {
    return (
      <div className="filters-panel flex-row flex-wrap" >
        <DateTimeFilter filter={this.getFilterValue("dateFrom")} onDelete={this.handleDelete} readonly label="Od" />
        <DateTimeFilter filter={this.getFilterValue("dateTo")} onDelete={this.handleDelete} readonly label="Do" />
        <TextFilter filter={this.getFilterValue("userFullName")} onDelete={this.handleDelete} readonly label="Użytkownik" />
        <TextFilter filter={this.getFilterValue("userPhoneNumber")} onDelete={this.handleDelete} readonly label="Nr telefonu" />
        <TextFilter filter={this.getFilterValue("userEmail")} onDelete={this.handleDelete} readonly label="Email" />
        <TextFilter filter={this.getFilterValue("bikeNumber")} multi onDelete={this.handleDelete} readonly label="Rower" />
        <SelectFilter filter={this.getFilterValue("userIsTester")} items={YesNoOptions} onDelete={this.handleDelete} readonly label="Przejazdy serwisowe" />
        <SelectFilter filter={this.getFilterValue("bikeTypeId")} items={ParseBikeTypesToSelect(this.props.bikeTypes)} onDelete={this.handleDelete} readonly label="Typ roweru" />
        <TextFilter filter={this.getFilterValue("bikeLockImei")} multi onDelete={this.handleDelete} readonly label="Zamek" />
        <SelectFilter filter={this.getFilterValue("fareIssueStatus")} items={FareIssueStatus} onDelete={this.handleDelete} readonly label="Reklamacja" />
        <SelectFilter filter={this.getFilterValue("wasFinishedByOperator")} items={YesNoOptions} onDelete={this.handleDelete} readonly label="Zakończone przez operatora" />
        <TextFilter filter={this.getFilterValue("rating")} onDelete={this.handleDelete} readonly label="Ocena przejazdu" />
      </div>
    );
  }
}

FiltersPanel.propTypes = {
  onFilteredChange: PropTypes.func,
  filteredBy: PropTypes.array,
  bikeTypes: PropTypes.array.isRequired,
};

export default FiltersPanel;
